import React from 'react';
import { Form, Card, Button } from 'react-bootstrap';

import { SupportingExaminationMemoView } from '../../containers/SupportingExaminationView/SupportingExaminationInMemoView';
import { GetSupportingExaminationDto } from '../../../common/spdCore/autogenerated/spdApiClient';

import { TestGivingProps } from './utilsTestGiving';

interface Props {
  nextStep: () => void;
  handleFormData: (input: string) => (e: GetSupportingExaminationDto[]) => void;
  prevStep: () => void;

  values: TestGivingProps;
}

export const AddSupportingExamination: React.FC<Props> = ({
  nextStep,
  handleFormData,
  prevStep,
  values,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const submitFormData = (e: any) => {
    e.preventDefault();
    nextStep();
  };

  const handleAddClick = (newSupports: GetSupportingExaminationDto[]) => {
    handleFormData('supportExam')(newSupports);
  };

  return (
    <>
      <Card>
        <Card.Body>
          <Form onSubmit={submitFormData}>
            <Form.Group className="mb-3">
              <Form.Label>
                <strong>Badania pomocnicze</strong>
              </Form.Label>{' '}
              <SupportingExaminationMemoView
                cuSupports={values.supportExam}
                onAdd={handleAddClick}
              />
            </Form.Group>

            <div className="d-flex justify-content-end m-3">
              <Button
                variant="outline-secondary"
                className="ms-1"
                onClick={prevStep}
              >
                Wstecz
              </Button>

              <Button variant="outline-primary" className="ms-1" type="submit">
                Dalej
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};
