import React from 'react';

import { useSpdCore } from '../../../common/hooks/useSpdCore';

import { ConsultationsSearch } from './ConsultationsSearch';

interface ConsultationsListProps {
  onRowClick?: (key: string) => Promise<void> | void | undefined;
  enableSingleSelect?: boolean;
}

export const ConsultationsList: React.FC<ConsultationsListProps> = ({
  onRowClick,
  enableSingleSelect,
}) => {
  const spd = useSpdCore();

  const handleClick = async (id: string) => {
    await spd.consultations.get(id);
  };

  return (
    <>
      <ConsultationsSearch
        getData={spd.consultations.getAllByPortion}
        getCount={spd.consultations.getAllCount}
        onRowClick={onRowClick ? onRowClick : handleClick}
        enableSingleSelect={enableSingleSelect}
      />
    </>
  );
};
