import React, { useEffect } from 'react';
import { Card, Tab } from 'react-bootstrap';

import { useCookies } from '../../../common/hooks/useCookies';
import { useSpdCore } from '../../../common/hooks/useSpdCore';
import { useNavigation } from '../../../common/navigation';
import { TabsHistory } from '../../../app/components/TabsHistory';

import { ExamsActiveView } from './ExamsActiveView';
import { ExamsInActiveView } from './ExamsInActiveView';

export const ExamsView: React.FC = () => {
  const spd = useSpdCore();
  const nav = useNavigation();
  const cookies = useCookies();

  useEffect(() => {
    if (cookies.activeMonitor !== '') {
      cookies.setActiveMonitor('');
    }
  }, [cookies]);

  const handleClick = async (id: string) => {
    nav.examTests(id);
  };

  const handleSelect = (key: string | null) => {
    cookies.setActiveTabMonitor(key as string);
  };

  return (
    <Card>
      <TabsHistory
        activeKey={
          cookies.activeTabMonitor ? cookies.activeTabMonitor : 'activeExams'
        }
        id="exams"
        className="mb-3"
        onAdditionalSelect={handleSelect}
      >
        <Tab
          eventKey="withoutJudgmentExams"
          title="Badania z orzeczeniami do wydania"
          className="m-3"
        >
          <ExamsInActiveView
            getData={(
              pageSize,
              pageNumber,
              orderBy,
              orderDirection,
              filterText,
            ) =>
              spd.exams.getWithoutJudgmentByPortion(
                pageSize,
                pageNumber,
                orderBy,
                orderDirection,
                filterText,
              )
            }
            getCount={spd.exams.getWithoutJudgmentCount}
            onRowClick={handleClick}
          />
        </Tab>
        <Tab eventKey="activeExams" title="aktywne" className="m-3">
          <ExamsActiveView
            getData={(
              pageSize,
              pageNumber,
              orderBy,
              orderDirection,
              filterText,
            ) =>
              spd.exams.getActiveByPortion(
                pageSize,
                pageNumber,
                orderBy,
                orderDirection,
                filterText,
              )
            }
            getCount={spd.exams.getActiveCount}
            onRowClick={handleClick}
          />
        </Tab>
        <Tab eventKey="examsWithExamined" title="z badanym" className="m-3">
          <ExamsInActiveView
            getData={(
              pageSize,
              pageNumber,
              orderBy,
              orderDirection,
              filterText,
            ) =>
              spd.exams.getWithExaminedByPortion(
                pageSize,
                pageNumber,
                orderBy,
                orderDirection,
                filterText,
              )
            }
            getCount={spd.exams.getWithExaminedCount}
            onRowClick={handleClick}
          />
        </Tab>
        <Tab eventKey="exams" title="Wszystkie badania" className="m-3">
          <ExamsInActiveView
            getData={(
              pageSize,
              pageNumber,
              orderBy,
              orderDirection,
              filterText,
            ) =>
              spd.exams.getAllByPortion(
                pageSize,
                pageNumber,
                orderBy,
                orderDirection,
                filterText,
              )
            }
            getCount={spd.exams.getAllCount}
            onRowClick={handleClick}
          />
        </Tab>
      </TabsHistory>
    </Card>
  );
};
