/* tslint:disable */
/* eslint-disable */
/**
 * SPD
 * Opis api SPD
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetResultDto,
    GetResultDtoFromJSON,
    GetResultDtoFromJSONTyped,
    GetResultDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreateUpdateResultDto
 */
export interface CreateUpdateResultDto {
    /**
     * 
     * @type {Date}
     * @memberof CreateUpdateResultDto
     */
    timestamp?: Date | null;
    /**
     * 
     * @type {GetResultDto}
     * @memberof CreateUpdateResultDto
     */
    result?: GetResultDto | null;
}

export function CreateUpdateResultDtoFromJSON(json: any): CreateUpdateResultDto {
    return CreateUpdateResultDtoFromJSONTyped(json, false);
}

export function CreateUpdateResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateResultDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'timestamp': !exists(json, 'timestamp') ? undefined : (json['timestamp'] === null ? null : new Date(json['timestamp'])),
        'result': !exists(json, 'result') ? undefined : GetResultDtoFromJSON(json['result']),
    };
}

export function CreateUpdateResultDtoToJSON(value?: CreateUpdateResultDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'timestamp': value.timestamp === undefined ? undefined : (value.timestamp === null ? null : value.timestamp.toISOString()),
        'result': GetResultDtoToJSON(value.result),
    };
}


