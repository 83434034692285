/* tslint:disable */
/* eslint-disable */
/**
 * SPD
 * Opis api SPD
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUpdateArticleDto
 */
export interface CreateUpdateArticleDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateArticleDto
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateArticleDto
     */
    legalBasis?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateArticleDto
     */
    reason?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateArticleDto
     */
    code?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CreateUpdateArticleDto
     */
    lp?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateArticleDto
     */
    active: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateArticleDto
     */
    pdfTemplateId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateArticleDto
     */
    mode?: CreateUpdateArticleDtoModeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateArticleDto
     */
    registerId?: string | null;
}

export function CreateUpdateArticleDtoFromJSON(json: any): CreateUpdateArticleDto {
    return CreateUpdateArticleDtoFromJSONTyped(json, false);
}

export function CreateUpdateArticleDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateArticleDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': !exists(json, 'description') ? undefined : json['description'],
        'legalBasis': !exists(json, 'legalBasis') ? undefined : json['legalBasis'],
        'reason': !exists(json, 'reason') ? undefined : json['reason'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'lp': !exists(json, 'lp') ? undefined : json['lp'],
        'active': json['active'],
        'pdfTemplateId': !exists(json, 'pdfTemplateId') ? undefined : json['pdfTemplateId'],
        'mode': !exists(json, 'mode') ? undefined : json['mode'],
        'registerId': !exists(json, 'registerId') ? undefined : json['registerId'],
    };
}

export function CreateUpdateArticleDtoToJSON(value?: CreateUpdateArticleDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
        'legalBasis': value.legalBasis,
        'reason': value.reason,
        'code': value.code,
        'lp': value.lp,
        'active': value.active,
        'pdfTemplateId': value.pdfTemplateId,
        'mode': value.mode,
        'registerId': value.registerId,
    };
}

/**
* @export
* @enum {string}
*/
export enum CreateUpdateArticleDtoModeEnum {
    Doctor = 'Doctor',
    Psychologist = 'Psychologist',
    Ophthalmologist = 'Ophthalmologist'
}


