import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card';
import { Form } from 'react-bootstrap';

import ValidationAlert from '../../../app/components/ValidationAlert';
import {
  CreateUpdateJudgmentNumberingDto,
  GetRegisterDto,
  GetUserDto,
} from '../../../common/spdCore/autogenerated/spdApiClient';
import ConfirmationButton from '../../../app/components/ConfirmationButton';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { useSpdCore } from '../../../common/hooks/useSpdCore';
import FormRow from '../../../app/components/FormRow';

interface JudgmentNumberingEditorProps {
  id?: string;
  judgmentNumbering: CreateUpdateJudgmentNumberingDto;
  handleClose?: () => void;
  show?: boolean;
  registers: GetRegisterDto[];
  users: GetUserDto[];
  onCompleted: (
    judgmentNumbering: CreateUpdateJudgmentNumberingDto,
    id?: string,
  ) => Promise<{
    saved: boolean;
    errors: string[];
  }>;
}

export const JudgmentNumberingEditor: React.FC<
  JudgmentNumberingEditorProps
> = props => {
  const [judgmentNumbering, setJudgmentNumbering] = useState(
    props.judgmentNumbering,
  );
  const [showSaveErrors, setShowSaveErrors] = useState(false);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const notifications = useNotifications();
  const spd = useSpdCore();

  useEffect(() => {
    if (props.show) {
      setSaveErrors([]);
      setShowSaveErrors(false);
      setJudgmentNumbering(props.judgmentNumbering);
    }
  }, [props.show, props.judgmentNumbering]);

  const createUpdateJudgmentNumbering = async () => {
    props
      .onCompleted(judgmentNumbering, props.id)
      .then(saveStatus => {
        if (!saveStatus.saved) {
          setSaveErrors(saveStatus.errors);
          setShowSaveErrors(true);
          return;
        }
      })
      .catch(error => {
        console.error('Error during add judgmentNumbering:', error);
      });
  };

  const deleteJudgmentNumbering = async () => {
    if (props.id) {
      notifications.onPromise(
        spd.articles.changeToInactive(props.id),
        props.handleClose,
      );
    }
  };

  const getAllRegisters = () => {
    const arr = props.registers.map(reg => {
      return {
        value: reg.id,
        label: reg.name,
      };
    });
    return arr;
  };

  const getSelectedRegister = () => {
    return getAllRegisters().filter(
      item => item.value === props.judgmentNumbering.registerId,
    );
  };

  const getAllUsers = () => {
    const arr = props.users.map(user => {
      return {
        value: user.uid,
        label: user.name,
      };
    });
    return arr;
  };

  const getSelectedUser = () => {
    return getAllUsers().filter(
      item => item.value === props.judgmentNumbering.userUID,
    );
  };

  const propertyChange = (obj: Partial<CreateUpdateJudgmentNumberingDto>) => {
    setJudgmentNumbering({ ...judgmentNumbering, ...obj });
  };

  return (
    <Modal
      onHide={props.handleClose}
      show={props.show}
      centered={true}
      keyboard={true}
      backdrop="static"
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {props.id
            ? 'Edycja numeracji rejestrów'
            : 'Dodawanie nowej numeracji rejestrów'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Card className="mb-2">
          <Card.Header>Numeracja rejestrów</Card.Header>
          <Card.Body>
            <Form className="d-grid gap-3">
              <FormRow controlId="register" label="Rejestr">
                <Select
                  options={getAllRegisters()}
                  defaultValue={getSelectedRegister()}
                  onChange={options =>
                    propertyChange({ registerId: options?.value })
                  }
                />
              </FormRow>
              <FormRow controlId="user" label="Użytkownik">
                <Select
                  options={getAllUsers()}
                  defaultValue={getSelectedUser()}
                  onChange={options =>
                    propertyChange({ userUID: options?.value })
                  }
                />
              </FormRow>

              <FormRow controlId="year" label="Rok">
                <Form.Control
                  value={judgmentNumbering?.year || ''}
                  onChange={e => propertyChange({ year: +e.target.value })}
                />
              </FormRow>
              <FormRow controlId="month" label="Miesiąc">
                <Form.Control
                  value={judgmentNumbering?.month || ''}
                  onChange={e => propertyChange({ month: +e.target.value })}
                />
              </FormRow>
              <FormRow controlId="actualNumber" label="Aktualny numer">
                <Form.Control
                  value={judgmentNumbering?.actualNumber || ''}
                  onChange={e =>
                    propertyChange({ actualNumber: +e.target.value })
                  }
                />
              </FormRow>
            </Form>
          </Card.Body>
        </Card>
        <ValidationAlert
          show={showSaveErrors}
          errors={saveErrors}
          className="mt-3"
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={props.handleClose}>
          Zamknij
        </Button>
        {props.id && (
          <ConfirmationButton
            className="mx-1"
            confirmation="Czy na pewno usunąć artykuł?"
            variant="outline-danger"
            onOK={deleteJudgmentNumbering}
          >
            Usuń
          </ConfirmationButton>
        )}
        <Button
          variant="outline-primary"
          onClick={createUpdateJudgmentNumbering}
        >
          Zapisz
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
