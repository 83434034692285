import React, { useEffect, useState } from 'react';
import { Button, Card, Form, Modal, Spinner, Tab } from 'react-bootstrap';
//import LoadingOverlay from 'react-loading-overlay-ts';

import Tabs from '../../../app/components/Tabs';
import { useSpdCore } from '../../../common/hooks/useSpdCore';
import { Emails } from '../../components/Emails/Emails';
import { useAuth } from '../../../common/hooks/useAuth';

export const EmailsView: React.FC = () => {
  const spd = useSpdCore();
  const auth = useAuth();
  const [refresh, setRefresh] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const [onlyNotSent, setOnlyNotSent] = useState<boolean>(true);
  const [result, setResult] = useState<string>('');

  const onRefresh = () => {
    setRefresh(!refresh);
  };

  useEffect(() => {
    if (loading) {
      setShowSpinner(true);
    } else {
      setShowSpinner(false);
    }
  }, [loading]);

  const propertyChange = (onlyErrors: boolean) => {
    setOnlyNotSent(onlyErrors);
  };

  const sendAllWaitingEmails = () => {
    setShowSpinner(true);
    spd.emails
      .sendAllWaitingEmails(auth.userProfile?.id || '')
      .then(result => {
        setShowSpinner(false);
        setResult(result);
        onRefresh();
      })
      .catch(error => {
        console.log(error);
        setShowSpinner(false);
        onRefresh();
      });
  };

  return (
    <>
      <Modal className="modal-transparent" show={showSpinner} centered={true}>
        <Modal.Body>
          <div className="d-flex justify-content-center">
            <Spinner animation="grow" variant="light" />
            <h2 className="text-white">Wysyłanie wiadomości e-mail...</h2>
          </div>
        </Modal.Body>
      </Modal>
      <Card>
        <Tabs defaultActiveKey="search" id="emails" className="mb-3">
          <Tab eventKey="search" title="Wiadomości e-mail" className="m-3">
            <Button
              variant="outline-primary"
              onClick={() => sendAllWaitingEmails()}
            >
              Wyślij wszystkie oczekujące wiadomości e-mail
            </Button>
            {result && (
              <div className="mt-3">
                <h5>Wynik wysyłania wiadomości e-mail:</h5>
                <p>{result}</p>
              </div>
            )}
            <br />
            <Form>
              <Form.Check
                className="mt-3"
                type="checkbox"
                defaultChecked={true}
                onChange={e => propertyChange(e.target.checked)}
                label="Pokaż tylko niewysłane wiadomości"
              />
            </Form>
            <Emails
              getData={(
                pageSize,
                pageNumber,
                orderBy,
                orderDirection,
                filterText,
              ) =>
                spd.emails.getAllByPortion(
                  pageSize,
                  pageNumber,
                  orderBy,
                  orderDirection,
                  filterText,
                  onlyNotSent,
                  onRefresh,
                  setLoading,
                )
              }
              getCount={filterText =>
                spd.emails.getAllCount(filterText, onlyNotSent)
              }
              refresh={refresh}
            />
          </Tab>
        </Tabs>
      </Card>
    </>
  );
};
