/*eslint max-lines-per-function: ["error", 310]*/
import React, { useState } from 'react';
import { Alert, Button, Card, Form, Tab, Tabs } from 'react-bootstrap';

import FormRow from '../../../app/components/FormRow';
import {
  CreateUpdateConsultationDto,
  CreateUpdateConsultationDtoTypeEnum,
  CreateUpdateConsultationDtoPdfTemplateTypeEnum,
  GetExamDto,
} from '../../../common/spdCore/autogenerated/spdApiClient';
import { useSpdCore } from '../../../common/hooks/useSpdCore';
import {
  ConsultationType,
  TemplateType,
} from '../../../common/spdCore/validation/schemas';
import { FormControlTextarea3 } from '../../../app/components/FormControlTextarea';
import { ChooseSigned } from '../Users/ChooseSigned';
import { Role } from '../../../common/spdCore/authorization/roles';
import { responseErrors } from '../../../common/spdCore/validation/responseErrors';

interface ConsultationENTEditorProps {
  exam: GetExamDto;
  consultation: CreateUpdateConsultationDto;
  id: string | undefined;
}

export const ConsultationENTEditor: React.FC<
  ConsultationENTEditorProps
> = props => {
  const spd = useSpdCore();
  const [consultation, setConsultation] = useState<CreateUpdateConsultationDto>(
    props.consultation,
  );
  const [id, setId] = useState<string | undefined>(props.id);
  const [message, setMessage] = useState<string>('');

  const createConsultation = async (
    gConsultation: CreateUpdateConsultationDto,
  ) => {
    setMessage('');
    try {
      if (id) {
        await spd.consultations.update(id, gConsultation);
        setConsultation(gConsultation);

        await spd.pdfPrinter.saveAndPrintConsultation(
          id,
          TemplateType.ENT_consultation,
        );
      } else {
        await spd.consultations
          .create({
            ...gConsultation,
            ...{
              pdfTemplateType:
                TemplateType.ENT_consultation as unknown as CreateUpdateConsultationDtoPdfTemplateTypeEnum,
              examId: props?.exam?.id,
              type: ConsultationType.ENT as unknown as CreateUpdateConsultationDtoTypeEnum,
              consultDate: new Date(),
            },
          })
          .then(async o => {
            setConsultation({
              ...o,
              ...{
                ...gConsultation,
                ...{
                  pdfTemplateType:
                    TemplateType.ENT_consultation as unknown as CreateUpdateConsultationDtoPdfTemplateTypeEnum,
                  examId: props?.exam?.id,
                  type: ConsultationType.ENT as unknown as CreateUpdateConsultationDtoTypeEnum,
                },
              },
            });
            setId(o.id);
            await spd.pdfPrinter.saveAndPrintConsultation(
              o.id,
              TemplateType.ENT_consultation,
            );
          })
          .catch(err => {
            void responseErrors(err).then(errors => {
              setMessage(
                `Wystąpił problem z zapisem konsultacji laryngologicznej:\n ${errors}`,
              );
            });
          });
      }
    } catch (response) {
      const error = await responseErrors(response as Response);
      setMessage(
        `Błąd podczas zapisywania konsultacji laryngologicznej:\n ${error}`,
      );
    }
  };

  const propertyChange = (obj: Partial<CreateUpdateConsultationDto>) =>
    setConsultation({ ...consultation, ...obj });

  const defaultValue = (text: string | null | undefined) => text || '';

  return (
    <Card className="mb-2">
      <Card.Header>{`Konsultacja laryngologiczna ${props.exam?.number}`}</Card.Header>
      <Card.Body>
        <Form className="d-grid gap-3">
          <Tabs defaultActiveKey="result" id="resultTab" className="mb-2">
            <Tab eventKey="result" title="Dane podstawowe" className="mb-2">
              <div className="d-grid gap-3">
                <FormRow controlId="pass" label="Wynik pozytywny">
                  <Form.Check
                    type="checkbox"
                    defaultChecked={
                      consultation?.pass ||
                      consultation?.pass === undefined ||
                      consultation?.pass === null
                        ? true
                        : false
                    }
                    onChange={e => propertyChange({ pass: e.target.checked })}
                  />
                </FormRow>
                <FormRow controlId="staff" label="Lekarz">
                  <ChooseSigned
                    userUID={consultation?.authorUID}
                    handleOnChange={user =>
                      setConsultation({
                        ...consultation,
                        authorUID: user?.uid,
                      })
                    }
                    roles={[Role.MedicalSpecialist]}
                  />
                </FormRow>
                <FormRow controlId="number" label="Numer">
                  <Form.Control
                    type="text"
                    defaultValue={defaultValue(consultation?.number)}
                    onChange={e =>
                      propertyChange({
                        number: e.target.value,
                      })
                    }
                  />
                </FormRow>
                <FormRow controlId="recognition" label="Rozpoznanie">
                  <FormControlTextarea3
                    defaultValue={consultation?.recognition || ''}
                    onChange={e =>
                      propertyChange({ recognition: e.target.value })
                    }
                  />
                </FormRow>
              </div>
            </Tab>
            <Tab
              eventKey="physicalExamination"
              title="Badanie przedmiotowe"
              className="mb-2"
            >
              <div className="d-grid gap-3">
                <FormRow controlId="otoscopyLeft" label="Otoskopia ucho lewe">
                  <Form.Control
                    type="text"
                    defaultValue={defaultValue(consultation?.otoscopyLeft)}
                    onChange={e =>
                      propertyChange({
                        otoscopyLeft: e.target.value,
                      })
                    }
                  />
                </FormRow>
                <FormRow controlId="otoscopyRight" label="Otoskopia ucho prawe">
                  <Form.Control
                    type="text"
                    defaultValue={defaultValue(consultation?.otoscopyRight)}
                    onChange={e =>
                      propertyChange({
                        otoscopyRight: e.target.value,
                      })
                    }
                  />
                </FormRow>
                <FormRow
                  controlId="throatAndLarynx"
                  label="Badanie gardła i krtani"
                >
                  <Form.Control
                    type="text"
                    defaultValue={defaultValue(consultation?.throatAndLarynx)}
                    onChange={e =>
                      propertyChange({
                        throatAndLarynx: e.target.value,
                      })
                    }
                  />
                </FormRow>
                <FormRow controlId="inflammatoryChanges" label="Zmiany zapalne">
                  <Form.Control
                    type="text"
                    defaultValue={defaultValue(
                      consultation?.inflammatoryChanges,
                    )}
                    onChange={e =>
                      propertyChange({
                        inflammatoryChanges: e.target.value,
                      })
                    }
                  />
                </FormRow>
                <FormRow controlId="romberg" label="Próba Romberga">
                  <Form.Check
                    type="checkbox"
                    defaultChecked={
                      consultation?.romberg ||
                      consultation?.romberg === undefined ||
                      consultation?.romberg === null
                        ? true
                        : false
                    }
                    onChange={e =>
                      propertyChange({ romberg: e.target.checked })
                    }
                  />
                </FormRow>
              </div>
            </Tab>
            <Tab
              eventKey="physicalExamination2"
              title="Badanie akumetryczne słuchu"
              className="mb-2"
            >
              <div className="d-grid gap-3">
                <FormRow controlId="audiologicalDistance" label="Odległość">
                  <Form.Check
                    inline
                    label="pon. 2m"
                    name="audiologicalDistance"
                    type="radio"
                    id="audiologicalDistanceId"
                    checked={
                      !consultation?.audiologicalDistance ||
                      consultation?.audiologicalDistance === 'pon. 2m'
                    }
                    onChange={() =>
                      propertyChange({
                        eyeAlignment: 'pon. 2m',
                      })
                    }
                  />
                  <Form.Check
                    inline
                    label="z 2m"
                    name="audiologicalDistance"
                    type="radio"
                    id="audiologicalDistanceId2"
                    checked={consultation?.audiologicalDistance === 'z 2m'}
                    onChange={() =>
                      propertyChange({
                        audiologicalDistance: 'z 2m',
                      })
                    }
                  />
                  <Form.Check
                    inline
                    label="z 3m"
                    name="audiologicalDistance"
                    type="radio"
                    id="audiologicalDistanceId3"
                    checked={consultation?.audiologicalDistance === 'z 3m'}
                    onChange={() =>
                      propertyChange({
                        audiologicalDistance: 'z 3m',
                      })
                    }
                  />
                  <Form.Check
                    inline
                    label="z 6m"
                    name="audiologicalDistance"
                    type="radio"
                    id="audiologicalDistanceId4"
                    checked={consultation?.audiologicalDistance === 'z 6m'}
                    onChange={() =>
                      propertyChange({
                        audiologicalDistance: 'z 6m',
                      })
                    }
                  />
                </FormRow>

                <FormRow controlId="audiologicalRight" label="Ucho prawe">
                  <Form.Control
                    type="text"
                    defaultValue={defaultValue(consultation?.audiologicalRight)}
                    onChange={e =>
                      propertyChange({
                        audiologicalRight: e.target.value,
                      })
                    }
                  />
                </FormRow>
                <FormRow controlId="audiologicalLeft" label="Ucho lewe">
                  <Form.Control
                    type="text"
                    defaultValue={defaultValue(consultation?.audiologicalLeft)}
                    onChange={e =>
                      propertyChange({
                        audiologicalLeft: e.target.value,
                      })
                    }
                  />
                </FormRow>
              </div>
            </Tab>
          </Tabs>
          <Button
            variant="outline-primary"
            className="ms-1"
            onClick={() => createConsultation(consultation)}
          >
            Zapisz i wydrukuj
          </Button>
          {message && <Alert variant={'warning'}>{message}</Alert>}
        </Form>
      </Card.Body>
    </Card>
  );
};
