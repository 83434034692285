/* tslint:disable */
/* eslint-disable */
/**
 * SPD
 * Opis api SPD
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUpdateSupportingExaminationDictDto
 */
export interface CreateUpdateSupportingExaminationDictDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateSupportingExaminationDictDto
     */
    name?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateSupportingExaminationDictDto
     */
    active: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateSupportingExaminationDictDto
     */
    type?: CreateUpdateSupportingExaminationDictDtoTypeEnum;
}

export function CreateUpdateSupportingExaminationDictDtoFromJSON(json: any): CreateUpdateSupportingExaminationDictDto {
    return CreateUpdateSupportingExaminationDictDtoFromJSONTyped(json, false);
}

export function CreateUpdateSupportingExaminationDictDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateSupportingExaminationDictDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'active': json['active'],
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function CreateUpdateSupportingExaminationDictDtoToJSON(value?: CreateUpdateSupportingExaminationDictDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'active': value.active,
        'type': value.type,
    };
}

/**
* @export
* @enum {string}
*/
export enum CreateUpdateSupportingExaminationDictDtoTypeEnum {
    HelpExam = 'helpExam',
    SpecialisedConsultation = 'specialisedConsultation'
}


