/* tslint:disable */
/* eslint-disable */
/**
 * SPD
 * Opis api SPD
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetCompanyDto,
    GetCompanyDtoFromJSON,
    GetCompanyDtoFromJSONTyped,
    GetCompanyDtoToJSON,
    GetHealthcareFacilityDto,
    GetHealthcareFacilityDtoFromJSON,
    GetHealthcareFacilityDtoFromJSONTyped,
    GetHealthcareFacilityDtoToJSON,
    GetUserDto,
    GetUserDtoFromJSON,
    GetUserDtoFromJSONTyped,
    GetUserDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetExamsPackageDto
 */
export interface GetExamsPackageDto {
    /**
     * 
     * @type {string}
     * @memberof GetExamsPackageDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetExamsPackageDto
     */
    description?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof GetExamsPackageDto
     */
    registrationDate?: Date | null;
    /**
     * 
     * @type {GetUserDto}
     * @memberof GetExamsPackageDto
     */
    author?: GetUserDto | null;
    /**
     * 
     * @type {string}
     * @memberof GetExamsPackageDto
     */
    type?: GetExamsPackageDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof GetExamsPackageDto
     */
    mode?: GetExamsPackageDtoModeEnum;
    /**
     * 
     * @type {number}
     * @memberof GetExamsPackageDto
     */
    number?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetExamsPackageDto
     */
    examsCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetExamsPackageDto
     */
    examsFinishCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetExamsPackageDto
     */
    examsExpectationCount?: number | null;
    /**
     * 
     * @type {GetCompanyDto}
     * @memberof GetExamsPackageDto
     */
    company?: GetCompanyDto | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetExamsPackageDto
     */
    sendCompanyEmail?: boolean | null;
    /**
     * 
     * @type {GetHealthcareFacilityDto}
     * @memberof GetExamsPackageDto
     */
    healthcareFacility?: GetHealthcareFacilityDto | null;
}

export function GetExamsPackageDtoFromJSON(json: any): GetExamsPackageDto {
    return GetExamsPackageDtoFromJSONTyped(json, false);
}

export function GetExamsPackageDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetExamsPackageDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'registrationDate': !exists(json, 'registrationDate') ? undefined : (json['registrationDate'] === null ? null : new Date(json['registrationDate'])),
        'author': !exists(json, 'author') ? undefined : GetUserDtoFromJSON(json['author']),
        'type': !exists(json, 'type') ? undefined : json['type'],
        'mode': !exists(json, 'mode') ? undefined : json['mode'],
        'number': !exists(json, 'number') ? undefined : json['number'],
        'examsCount': !exists(json, 'examsCount') ? undefined : json['examsCount'],
        'examsFinishCount': !exists(json, 'examsFinishCount') ? undefined : json['examsFinishCount'],
        'examsExpectationCount': !exists(json, 'examsExpectationCount') ? undefined : json['examsExpectationCount'],
        'company': !exists(json, 'company') ? undefined : GetCompanyDtoFromJSON(json['company']),
        'sendCompanyEmail': !exists(json, 'sendCompanyEmail') ? undefined : json['sendCompanyEmail'],
        'healthcareFacility': !exists(json, 'healthcareFacility') ? undefined : GetHealthcareFacilityDtoFromJSON(json['healthcareFacility']),
    };
}

export function GetExamsPackageDtoToJSON(value?: GetExamsPackageDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'description': value.description,
        'registrationDate': value.registrationDate === undefined ? undefined : (value.registrationDate === null ? null : value.registrationDate.toISOString()),
        'author': GetUserDtoToJSON(value.author),
        'type': value.type,
        'mode': value.mode,
        'number': value.number,
        'examsCount': value.examsCount,
        'examsFinishCount': value.examsFinishCount,
        'examsExpectationCount': value.examsExpectationCount,
        'company': GetCompanyDtoToJSON(value.company),
        'sendCompanyEmail': value.sendCompanyEmail,
        'healthcareFacility': GetHealthcareFacilityDtoToJSON(value.healthcareFacility),
    };
}

/**
* @export
* @enum {string}
*/
export enum GetExamsPackageDtoTypeEnum {
    Single = 'Single',
    Mulitiple = 'Mulitiple'
}
/**
* @export
* @enum {string}
*/
export enum GetExamsPackageDtoModeEnum {
    Exams = 'Exams',
    Normalization = 'Normalization'
}


