/* eslint-disable complexity */
/*eslint max-lines-per-function: ["error", 180]*/
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card';
import { Form } from 'react-bootstrap';

import ValidationAlert from '../../../app/components/ValidationAlert';
import FormRow from '../../../app/components/FormRow';
import { CreateUpdateHealthcareFacilityDto } from '../../../common/spdCore/autogenerated/spdApiClient';
import DutySelector from '../Selects/DutySelector';

interface HealthcareFacilityEditorProps {
  id?: string;
  healthcareFacility: CreateUpdateHealthcareFacilityDto;
  handleClose?: () => void;
  show?: boolean;
  onCompleted: (
    healthcareFacility: CreateUpdateHealthcareFacilityDto,
    id?: string,
  ) => Promise<{
    saved: boolean;
    errors: string[];
  }>;
}

export const HealthcareFacilityEditor: React.FC<
  HealthcareFacilityEditorProps
> = props => {
  const [healthcareFacility, setHealthcareFacility] = useState(
    props.healthcareFacility,
  );
  const [showSaveErrors, setShowSaveErrors] = useState(false);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);

  useEffect(() => {
    if (props.show) {
      setSaveErrors([]);
      setShowSaveErrors(false);
      setHealthcareFacility(props.healthcareFacility);
    } else {
      setHealthcareFacility({});
      setSaveErrors([]);
      setShowSaveErrors(false);
    }
  }, [props.show, props.healthcareFacility, props.id]);

  const createHealthcareFacility = async () => {
    props
      .onCompleted(healthcareFacility, props.id)
      .then(saveStatus => {
        if (!saveStatus.saved) {
          setSaveErrors(saveStatus.errors);
          setShowSaveErrors(true);
          return;
        }
      })
      .catch(error => {
        console.error('Error during add healthcareFacility:', error);
      });
  };

  const propertyChange = (obj: Partial<CreateUpdateHealthcareFacilityDto>) => {
    setHealthcareFacility({ ...healthcareFacility, ...obj });
  };

  return (
    <>
      <Modal
        onHide={props.handleClose}
        show={props.show}
        centered={true}
        keyboard={true}
        backdrop="static"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {props.id ? 'Edycja placówki' : 'Dodawanie nowej placówki'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card className="mb-2">
            <Card.Header>Placówka</Card.Header>
            <Card.Body>
              <Form className="d-grid gap-3">
                <FormRow
                  controlId="nameDoctor"
                  label="Nazwa placówki lekarskiej"
                >
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={healthcareFacility?.nameDoctor || ''}
                    onChange={e =>
                      propertyChange({ nameDoctor: e.target.value })
                    }
                  />
                </FormRow>
                <FormRow
                  controlId="namePsychological"
                  label="Nazwa placówki psychologicznej"
                >
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={healthcareFacility?.namePsychological || ''}
                    onChange={e =>
                      propertyChange({ namePsychological: e.target.value })
                    }
                  />
                </FormRow>
                <FormRow controlId="display_name" label="Wyświetlany opis">
                  <Form.Control
                    type="text"
                    value={healthcareFacility?.displayName || ''}
                    onChange={e =>
                      propertyChange({ displayName: e.target.value })
                    }
                  />
                </FormRow>

                <FormRow controlId="city" label="Miejscowość">
                  <Form.Control
                    type="text"
                    value={healthcareFacility?.city || ''}
                    onChange={e => propertyChange({ city: e.target.value })}
                  />
                </FormRow>
                <FormRow controlId="address" label="Adres">
                  <Form.Control
                    type="text"
                    value={healthcareFacility?.address || ''}
                    onChange={e => propertyChange({ address: e.target.value })}
                  />
                </FormRow>
                <FormRow controlId="nip" label="NIP/REGON">
                  <Form.Control
                    type="text"
                    value={healthcareFacility?.nip || ''}
                    onChange={e => propertyChange({ nip: e.target.value })}
                  />
                </FormRow>
                <FormRow controlId="symbol" label="Symbol">
                  <Form.Control
                    type="text"
                    value={healthcareFacility?.symbol || ''}
                    onChange={e => propertyChange({ symbol: e.target.value })}
                  />
                </FormRow>
                <FormRow
                  controlId="entryContentPsychological"
                  label="Wpis - psycholog"
                >
                  <Form.Control
                    type="text"
                    value={healthcareFacility?.entryContentPsychological || ''}
                    onChange={e =>
                      propertyChange({
                        entryContentPsychological: e.target.value,
                      })
                    }
                  />
                </FormRow>
                <FormRow controlId="entryContentDoctor" label="Wpis - lekarz">
                  <Form.Control
                    type="text"
                    value={healthcareFacility?.entryContentDoctor || ''}
                    onChange={e =>
                      propertyChange({ entryContentDoctor: e.target.value })
                    }
                  />
                </FormRow>
                <FormRow controlId="duty" label="Grupa dyżurująca">
                  <DutySelector
                    value={healthcareFacility?.dutyId || ''}
                    onChange={e => propertyChange({ dutyId: e.value })}
                  />
                </FormRow>
              </Form>
            </Card.Body>
          </Card>
          <ValidationAlert
            show={showSaveErrors}
            errors={saveErrors}
            className="mt-3"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={props.handleClose}>
            Zamknij
          </Button>
          <Button variant="outline-primary" onClick={createHealthcareFacility}>
            Zapisz
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
