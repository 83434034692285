import { GetInterpretationNormDto } from '../autogenerated/spdApiClient';
import { DefaultApi } from '../autogenerated/spdApiClient/apis/DefaultApi';

export type InterpretationNormEntity = GetInterpretationNormDto;

export interface InterpretationNormContextInterface {
  getByNorm: (normId: string) => Promise<InterpretationNormEntity[]>;
  getByNormCount: (normId: string) => Promise<number>;
}

export const InterpretationNormContext = (api: DefaultApi) => {
  return {
    getByNorm: async (normId: string) => {
      return await api.interpretationNormControllerGetByNorm(normId);
    },

    getByNormCount: (normId: string) =>
      api.interpretationNormControllerGetByNormCount(normId),
  };
};
