import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Col, Row } from 'react-bootstrap';

import ValidationAlert from '../../../app/components/ValidationAlert';
import { CreateUpdateExaminedDto } from '../../../common/spdCore/autogenerated/spdApiClient';
import {
  getBirthDateFromPesel,
  getSexFromPesel,
  isPeselValid,
} from '../../../common/spdCore/validation/schemas';
import { useSpdCore } from '../../../common/hooks/useSpdCore';
import { useNotifications } from '../../../common/hooks/useNotifications';
import ConfirmationButton from '../../../app/components/ConfirmationButton';

import { ExaminedEditorRightSide } from './ExaminedEditorRightSide';
import { ExaminedEditorLeftSide } from './ExaminedEditorLeftSide';

interface ExaminedEditorProps {
  id?: string;
  examined: CreateUpdateExaminedDto;
  handleClose?: () => void;
  show?: boolean;
  onCompleted?: (
    examined: CreateUpdateExaminedDto,
    id?: string,
  ) => Promise<{
    saved: boolean;
    errors: string[];
  }>;
  readOnly: boolean;
}

export const ExaminedEditor: React.FC<ExaminedEditorProps> = props => {
  const notifications = useNotifications();
  const [examined, setExamined] = useState(props.examined);
  const [showSaveErrors, setShowSaveErrors] = useState(false);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const [selectedDrivingCategories, setSelectedDrivingCategories] = useState<
    string[]
  >(props?.examined?.drivingLicense?.split(',') || []);

  const spdCore = useSpdCore();

  useEffect(() => {
    if (props.show) {
      setSaveErrors([]);
      setShowSaveErrors(false);
      setExamined(props.examined);
      setSelectedDrivingCategories(
        props?.examined?.drivingLicense?.split(',') || [],
      );
    }
  }, [props.show, props.examined]);

  const createExamined = async () => {
    if (selectedDrivingCategories) {
      examined.drivingLicense = selectedDrivingCategories.join(',');
    }
    if (props.onCompleted) {
      props
        .onCompleted(examined, props.id)
        .then(saveStatus => {
          if (!saveStatus.saved) {
            setSaveErrors(saveStatus.errors);
            setShowSaveErrors(true);
            return;
          }
        })
        .catch(error => {
          console.error('Error during add examined:', error);
        });
    }
  };

  const propertyChange = (obj: Partial<CreateUpdateExaminedDto>) => {
    setExamined({ ...examined, ...obj });
    if (
      Object.prototype.hasOwnProperty.call(obj, 'pesel') &&
      isPeselValid(obj.pesel) &&
      obj.pesel
    ) {
      setExamined({
        ...examined,
        dateOfBirth: getBirthDateFromPesel(obj.pesel),
        sex: getSexFromPesel(obj.pesel),
        pesel: obj.pesel,
      });
    }

    if (
      Object.prototype.hasOwnProperty.call(obj, 'postcode') &&
      obj.postcode &&
      obj.postcode.length >= 5 &&
      !examined.city
    ) {
      spdCore.examineds
        .getCityByPostCode(obj.postcode)
        .then(city => {
          if (city && city.length > 0) {
            setExamined({
              ...examined,
              city: city,
              postcode: obj.postcode,
            });
          }
        })
        .catch(error => {
          console.error('Error during take city', error);
        });
    }
  };

  const onSelectChange = (keys: string[]) => {
    setSelectedDrivingCategories(keys);
    setExamined({ ...examined, ...{ drivingLicense: keys.join(',') } });
  };

  const deleteExamined = async () => {
    if (props.id) {
      notifications.onPromise(
        spdCore.examineds.changeToInactive(props.id),
        props.handleClose,
      );
    }
  };

  const setTitle = () => {
    return props.id && props.onCompleted
      ? 'Edycja badanego'
      : props.id
      ? 'Podgląd badanego'
      : 'Dodawanie nowego badanego';
  };
  return (
    <>
      <Modal
        onHide={props.handleClose}
        show={props.show}
        centered={true}
        keyboard={true}
        backdrop="static"
        size="xl"
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onClick={(event: any) => event.stopPropagation()}
      >
        <Modal.Header closeButton>
          <Modal.Title>{setTitle()}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col className="col-lg-6 col-12 mb-1">
              <ExaminedEditorLeftSide
                propertyChange={propertyChange}
                readOnly={props.readOnly}
                show={props.show}
                examined={examined}
                onSelectChange={onSelectChange}
              />
              <ValidationAlert
                show={showSaveErrors}
                errors={saveErrors}
                className="mt-3"
              />
            </Col>
            <Col className="col-lg-6 col-12 mb-1">
              <ExaminedEditorRightSide
                propertyChange={propertyChange}
                readOnly={props.readOnly}
                show={props.show}
                examined={examined}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={props.handleClose}>
            Zamknij
          </Button>
          {props.id && !props.readOnly && (
            <ConfirmationButton
              className="mx-1"
              confirmation="Czy na pewno usunąć badanego? Badania z nim powiązane pozostaną, ale badanego nie da się wybrać do nowych badań."
              variant="outline-danger"
              onOK={deleteExamined}
            >
              Usuń
            </ConfirmationButton>
          )}
          {props.onCompleted && (
            <Button variant="outline-primary" onClick={createExamined}>
              Zapisz
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};
