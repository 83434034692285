import React, { useEffect, useState } from 'react';
import { Card, Tab } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import Tabs from '../../../app/components/Tabs';
import { useSpdCore } from '../../../common/hooks/useSpdCore';
import { useNavigation } from '../../../common/navigation';
import { ExamSearch } from '../../components/Exam/ExamSearch';
import { GetExaminedDto } from '../../../common/spdCore/autogenerated/spdApiClient';

type ParamTypes = {
  id: string;
};

export const ExamsForExaminedView: React.FC = () => {
  const spd = useSpdCore();
  const nav = useNavigation();
  const [examined, setExamined] = useState<GetExaminedDto>();

  const { id } = useParams<ParamTypes>() as {
    id: string;
  };

  useEffect(() => {
    spd.examineds
      .getExamined(id)
      .then(examined => setExamined(examined))
      .catch(error =>
        console.log(`Wystąpił problem z odświeżeniem strony. ${error}`),
      );
  }, [id, spd.examineds]);

  const handleClick = async (id: string) => {
    nav.examTests(id);
  };

  const getData = async (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
  ) => {
    return spd.exams.getForExaminedByPortion(
      pageSize,
      pageNumber,
      orderBy,
      orderDirection,
      filterText,
      id,
    );
  };

  const getCount = async (filterText?: string) => {
    return spd.exams.getForExaminedCount(filterText, id);
  };

  return (
    <Card>
      <Tabs defaultActiveKey={'examForExamined'} id="exams" className="mb-3">
        <Tab
          eventKey="examForExamined"
          title={`Badania związane z badanym ${examined?.surname} ${examined?.firstname}`}
          className="m-3"
        >
          <ExamSearch
            getData={getData}
            getCount={getCount}
            onRowClick={handleClick}
          />
        </Tab>
      </Tabs>
    </Card>
  );
};
