import { DefaultApi } from '../autogenerated/spdApiClient/apis/DefaultApi';
import {
  CreateUpdateSupportingExaminationDtoFromJSON,
  CreateUpdateSupportingExaminationDto,
  GetSupportingExaminationDto,
} from '../autogenerated/spdApiClient';
import { GridRecord } from '../../components/Grid/GridDataTypes';
import { referralsForGrid } from '../../../spd/components/Referrals/ReferralsSearch';

export type SupportingExaminationContextEntity = GetSupportingExaminationDto;

export interface SupportingExaminationContextInterface {
  get: (id: string) => Promise<SupportingExaminationContextEntity>;
  getAllByExam: (examId: string) => Promise<GetSupportingExaminationDto[]>;
  getWithPrintsByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
    setRefresh?: () => void,
  ) => Promise<GridRecord[]>;
  getWithPrintsCount: (filterText?: string) => Promise<number>;
  getWithPrintsForSigningByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
    userUid?: string,
    setRefresh?: () => void,
  ) => Promise<GridRecord[]>;
  getWithPrintsForSigningCount: (
    filterText?: string,
    userUid?: string,
  ) => Promise<number>;
  getAllForUserToSign: (
    userUid?: string,
  ) => Promise<GetSupportingExaminationDto[]>;
  update: (
    id: string,
    dto: CreateUpdateSupportingExaminationDto,
  ) => Promise<void>;
  create: (
    dto: CreateUpdateSupportingExaminationDto,
  ) => Promise<GetSupportingExaminationDto>;
  delete: (id: string) => Promise<void>;
}

export const SupportingExaminationContext = (api: DefaultApi) => {
  return {
    get: async (id: string) => {
      return await api.supportingExaminationControllerGet(id);
    },

    getAllByExam: (examId: string) =>
      api.supportingExaminationControllerGetAllByExam(examId),

    getWithPrintsByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
      setRefresh?: () => void,
    ) => {
      const data =
        await api.supportingExaminationControllerGetWithPrintsByPortion(
          pageSize,
          pageNumber,
          orderBy || '',
          orderDirection || '',
          filterText || '',
        );
      return data.map(supportingExamination =>
        referralsForGrid(supportingExamination, setRefresh),
      );
    },

    getWithPrintsCount: (filterText?: string) =>
      api.supportingExaminationControllerGetWithPrintsCount(filterText || ''),

    getWithPrintsForSigningByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
      userUid?: string,
      setRefresh?: () => void,
    ) => {
      const data =
        await api.supportingExaminationControllerGetWithPrintsForSigningByPortion(
          pageSize,
          pageNumber,
          orderBy || '',
          orderDirection || '',
          filterText || '',
          userUid,
        );
      return data.map(supportingExamination =>
        referralsForGrid(supportingExamination, setRefresh),
      );
    },

    getWithPrintsForSigningCount: (filterText?: string, userUid?: string) =>
      api.supportingExaminationControllerGetWithPrintsForSigningCount(
        filterText || '',
        userUid,
      ),
    getAllForUserToSign: (userUid?: string) =>
      api.supportingExaminationControllerGetAllForUserToSign(userUid),
    update: (id: string, dto: CreateUpdateSupportingExaminationDto) =>
      api.supportingExaminationControllerUpdate(id, dto),

    create: (dto: CreateUpdateSupportingExaminationDto) =>
      api.supportingExaminationControllerCreate(dto),

    delete: (id: string) => api.supportingExaminationControllerDelete(id),
  };
};

// export const validate = (test: CreateUpdateSupportingExaminationDto) =>
//   validateAgainst(ArticleSchema, test);

export const supportData = (
  lp: number,
  cardId?: string,
  support?: GetSupportingExaminationDto,
): CreateUpdateSupportingExaminationDto => {
  if (support) {
    return CreateUpdateSupportingExaminationDtoFromJSON({
      ...support,
      examKindDictId: support.examKindDict?.id,
      cardId: cardId,
    });
  }
  return CreateUpdateSupportingExaminationDtoFromJSON({
    ...{
      referralDate: new Date(),
      consultationDate: new Date(),
      lp: lp,
      cardId: cardId,
    },
  });
};
