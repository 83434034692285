import React from 'react';

import { Grid } from '../../../common/components/Grid/Grid';
import {
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import { GetUserDto } from '../../../common/spdCore/autogenerated/spdApiClient/models/GetUserDto';
import { RoleText } from '../../../common/spdCore/authorization/roles';

interface UserProps {
  getData: GridGetterFunction;
  getCount: GridCountFunction;
  onRowClick: (key: string) => Promise<void> | void | undefined;
  refresh?: boolean;
}

export const userForGrid = (user: GetUserDto) => {
  return {
    key: user.id,
    values: {
      'user.name': user.name,
      'user.email': user.email,
      'user.licenseNumber': user.licenseNumber,
      'user.description': user.description,
      'user.admin': user.admin ? 'TAK' : 'NIE',
      'user.active': user.active ? 'TAK' : 'NIE',
      'user.roles': user.roles
        ? user.roles.map(t => RoleText[t as keyof typeof RoleText]).join(', ')
        : '',
      'user.uid': user.uid ? 'TAK' : 'NIE',
      'company.name': user?.company?.name,
    },
  };
};

export const Users: React.FC<UserProps> = props => {
  const columns = [
    { header: 'Imię i nazwisko', property: 'user.name' },
    { header: 'Adres e-mail', property: 'user.email' },
    { header: 'Numer uprawnień', property: 'user.licenseNumber' },
    { header: 'Role', property: 'user.roles', noSorting: true },
    { header: 'Opis', property: 'user.description' },
    { header: 'Administrator', property: 'user.admin' },
    { header: 'Aktywny', property: 'user.active' },
    { header: 'Zarejestrowany', property: 'user.uid', noSorting: true },
    { header: 'Firma/filia', property: 'company.name' },
  ];

  return (
    <Grid
      getData={props.getData}
      getDataCount={props.getCount}
      columns={columns}
      showFilter={true}
      initialOrderColumn="user.name"
      availablePageSizes={[10, 20, 50]}
      onRowClick={props.onRowClick}
      emptyGridMessage="Brak użytkowników w systemie"
      refresh={props.refresh}
    />
  );
};
