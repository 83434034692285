/* tslint:disable */
/* eslint-disable */
/**
 * SPD
 * Opis api SPD
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUpdateOtherPrintDefDto
 */
export interface CreateUpdateOtherPrintDefDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateOtherPrintDefDto
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateOtherPrintDefDto
     */
    reason?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateOtherPrintDefDto
     */
    active: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateOtherPrintDefDto
     */
    pdfTemplateId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateOtherPrintDefDto
     */
    mode?: CreateUpdateOtherPrintDefDtoModeEnum;
}

export function CreateUpdateOtherPrintDefDtoFromJSON(json: any): CreateUpdateOtherPrintDefDto {
    return CreateUpdateOtherPrintDefDtoFromJSONTyped(json, false);
}

export function CreateUpdateOtherPrintDefDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateOtherPrintDefDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': !exists(json, 'description') ? undefined : json['description'],
        'reason': !exists(json, 'reason') ? undefined : json['reason'],
        'active': json['active'],
        'pdfTemplateId': !exists(json, 'pdfTemplateId') ? undefined : json['pdfTemplateId'],
        'mode': !exists(json, 'mode') ? undefined : json['mode'],
    };
}

export function CreateUpdateOtherPrintDefDtoToJSON(value?: CreateUpdateOtherPrintDefDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
        'reason': value.reason,
        'active': value.active,
        'pdfTemplateId': value.pdfTemplateId,
        'mode': value.mode,
    };
}

/**
* @export
* @enum {string}
*/
export enum CreateUpdateOtherPrintDefDtoModeEnum {
    Referral = 'Referral'
}


