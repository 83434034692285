import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';

import { useSpdCore } from '../../../common/hooks/useSpdCore';
import { CreateUpdateExternalReferralDto } from '../../../common/spdCore/autogenerated/spdApiClient';
import { ExternalReferral } from '../../components/ExternalReferral/ExternaReferral';
import { newExternalReferral } from '../../../common/spdCore/contexts/ExternalReferralContext';
import { responseErrors } from '../../../common/spdCore/validation/responseErrors';
import { useAuth } from '../../../common/hooks/useAuth';

export const GenerateExternalReferralView: React.FC = () => {
  const spd = useSpdCore();
  const auth = useAuth();
  const [title, setTitle] = useState<string | undefined>(
    `Generowanie skierowania`,
  );

  useEffect(() => {
    spd.users
      .getByUserUID(auth.currentUser?.uid)
      .then(user => {
        setTitle(`Generowanie skierowania (${user?.company?.name})`);
      })
      .catch(() => {
        setTitle('');
        console.log('Nie udało się pobrac firmy');
      });
  }, [spd.users, auth.currentUser?.uid]);

  const onSave = async (externalReferral: CreateUpdateExternalReferralDto) => {
    try {
      await spd.externalReferrals.create(externalReferral);
      return {
        saved: true,
        errors: [],
      };
    } catch (response) {
      return {
        saved: false,
        errors: await responseErrors(response as Response),
      };
    }
  };

  return (
    <Card>
      <Card.Header>{title}</Card.Header>
      <Card.Body>
        <ExternalReferral
          externalReferral={newExternalReferral()}
          onSave={onSave}
        />
      </Card.Body>
    </Card>
  );
};
