import React from 'react';

import { Grid } from '../../../common/components/Grid/Grid';
import {
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import { GetSupportingExaminationDictDto } from '../../../common/spdCore/autogenerated/spdApiClient';
import { SupportingTypeText } from '../../../common/spdCore/validation/schemas';

interface SupportingExaminationDictProps {
  getData: GridGetterFunction;
  getCount: GridCountFunction;
  refresh?: boolean;
  onRowClick: (key: string) => Promise<void> | void | undefined;
}

export const supportingExaminationDictForGrid = (
  supportingExaminationDict: GetSupportingExaminationDictDto,
) => {
  return {
    key: supportingExaminationDict.id,
    values: {
      'supportingExaminationDict.name': supportingExaminationDict.name,
      'supportingExaminationDict.active': supportingExaminationDict.active
        ? 'Tak'
        : 'Nie',
      'supportingExaminationDict.type': supportingExaminationDict.type
        ? SupportingTypeText[supportingExaminationDict.type]
        : '',
    },
  };
};

export const SupportingExaminationDictSearch: React.FC<
  SupportingExaminationDictProps
> = props => {
  const columns = [
    { header: 'Nazwa', property: 'supportingExaminationDict.name' },
    { header: 'Typ', property: 'supportingExaminationDict.type' },
    { header: 'Aktywny', property: 'supportingExaminationDict.active' },
  ];

  return (
    <Grid
      getData={props.getData}
      getDataCount={props.getCount}
      columns={columns}
      showFilter={true}
      onRowClick={props.onRowClick}
      initialOrderColumn="supportingExaminationDict.name"
      availablePageSizes={[10, 20, 50]}
      emptyGridMessage="Brak rodzajów badań pomocniczych w systemie"
      enableMultiSelect={false}
      refresh={props.refresh}
    />
  );
};
