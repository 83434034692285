import { Buffer } from 'buffer';

import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card';
import { Form } from 'react-bootstrap';
import Select from 'react-select';

import ValidationAlert from '../../../app/components/ValidationAlert';
import FormRow from '../../../app/components/FormRow';
import { CreateUpdateAttachmentDto } from '../../../common/spdCore/autogenerated/spdApiClient';
import ConfirmationButton from '../../../app/components/ConfirmationButton';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { useSpdCore } from '../../../common/hooks/useSpdCore';
import { AttachmentType } from '../../../common/spdCore/validation/schemas';

interface AttachmentEditorProps {
  id?: string;
  attachment: CreateUpdateAttachmentDto;
  handleClose?: () => void;
  show?: boolean;
  onCompleted: (
    attachment: CreateUpdateAttachmentDto,
    id?: string,
  ) => Promise<{
    saved: boolean;
    errors: string[];
  }>;
}

export const AttachmentEditor: React.FC<AttachmentEditorProps> = props => {
  const [attachment, setAttachment] = useState(props.attachment);
  const [showSaveErrors, setShowSaveErrors] = useState(false);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const notifications = useNotifications();
  const spd = useSpdCore();

  useEffect(() => {
    if (props.show) {
      setSaveErrors([]);
      setShowSaveErrors(false);
      setAttachment(props.attachment);
    }
  }, [props.show, props.attachment]);

  const createUpdateAttachment = async () => {
    props
      .onCompleted(attachment, props.id)
      .then(saveStatus => {
        if (!saveStatus.saved) {
          setSaveErrors(saveStatus.errors);
          setShowSaveErrors(true);
          return;
        }
      })
      .catch(error => {
        console.error('Error during add attachment:', error);
      });
  };

  const deleteAttachment = async () => {
    if (props.id) {
      notifications.onPromise(
        spd.attachments.delete(props.id),
        props.handleClose,
      );
    }
  };

  const propertyChange = (obj: Partial<CreateUpdateAttachmentDto>) => {
    setAttachment({ ...attachment, ...obj });
  };

  const getAllAttachmentTypes = () => {
    const arr = Object.keys(AttachmentType).map(name => {
      return {
        value: AttachmentType[name as keyof typeof AttachmentType],
        label: AttachmentType[name as keyof typeof AttachmentType],
      };
    });
    return arr;
  };

  const getSelectedAttachmentType = () => {
    return getAllAttachmentTypes().filter(
      item => item.value === props.attachment.type,
    );
  };

  const setFile = (files: FileList | null) => {
    if (!files || files.length === 0) {
      return;
    }

    const f: File = files[0];
    const reader = new FileReader();
    const name = f.name;

    reader.onload = function (e) {
      if (e.target) {
        const target: FileReader = e.target;
        if (target.result) {
          attachment.file = Buffer.from(target.result as ArrayBuffer);
          attachment.name = name;
          attachment.date = new Date();
          setAttachment(attachment);
        }
      }
    };

    reader.readAsArrayBuffer(f);
  };

  return (
    <Modal
      onHide={props.handleClose}
      show={props.show}
      centered={true}
      keyboard={true}
      backdrop="static"
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {props.id ? 'Edycja załącznika' : 'Dodawanie nowego załącznika'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Card className="mb-2">
          <Card.Header>Załącznik</Card.Header>
          <Card.Body>
            <Form className="d-grid gap-3">
              <FormRow controlId="file" label="Plik">
                <Form.Control
                  type="file"
                  onChange={event =>
                    setFile((event.currentTarget as HTMLInputElement).files)
                  }
                />
              </FormRow>
              {attachment.scan && (
                <FormRow controlId="attachment_name" label="Nazwa">
                  <Form.Control
                    as="textarea"
                    rows={2}
                    value={attachment?.name || ''}
                    onChange={e => propertyChange({ name: e.target.value })}
                  />
                </FormRow>
              )}
              <FormRow controlId="attachment_description" label="Opis">
                <Form.Control
                  as="textarea"
                  rows={2}
                  value={attachment?.description || ''}
                  onChange={e =>
                    propertyChange({ description: e.target.value })
                  }
                />
              </FormRow>
              <FormRow controlId="template" label="Typ załącznika">
                <Select
                  options={getAllAttachmentTypes()}
                  defaultValue={getSelectedAttachmentType()}
                  onChange={options => propertyChange({ type: options?.value })}
                />
              </FormRow>
            </Form>
          </Card.Body>
        </Card>
        <ValidationAlert
          show={showSaveErrors}
          errors={saveErrors}
          className="mt-3"
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={props.handleClose}>
          Zamknij
        </Button>
        {props.id && !props.attachment.examId && (
          <ConfirmationButton
            className="mx-1"
            confirmation="Czy na pewno usunąć załącznik?"
            variant="outline-danger"
            onOK={deleteAttachment}
          >
            Usuń
          </ConfirmationButton>
        )}
        <Button variant="outline-primary" onClick={createUpdateAttachment}>
          Zapisz
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
