import React, { useEffect, useState } from 'react';

import { useAuth } from '../../../common/hooks/useAuth';
import { useSzafir } from '../../../common/hooks/useSzafir';
import { useSpdCore } from '../../../common/hooks/useSpdCore';
import { responseErrors } from '../../../common/spdCore/validation/responseErrors';
import {
  CreateUpdateSavedPdfDtoPdfTemplateTypeEnum,
  GetSupportingExaminationDto,
} from '../../../common/spdCore/autogenerated/spdApiClient';
import ConfirmationButton from '../../../app/components/ConfirmationButton';
import { TemplateType } from '../../../common/spdCore/validation/schemas';

interface MultipleReferralSigningProps {
  onSignedFinished: (
    errors: string[],
    allCount: number,
    errorCount: number,
  ) => Promise<void> | void | undefined;
  registerId: string;
  userUid: string;
}

export const MultipleReferralSigning: React.FC<
  MultipleReferralSigningProps
> = props => {
  const [signingInProgress, setSigningInProgress] = useState(false);
  const [sendSummary, setSendSummary] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [allCount, setAllCount] = useState<number>(0);
  const [errorCount, setErrorCount] = useState<number>(0);
  const [progress, setProgress] = useState<string>('');
  const auth = useAuth();
  const spd = useSpdCore();
  const szafir = useSzafir();

  useEffect(() => {
    if (sendSummary) {
      void props.onSignedFinished(errors, allCount, errorCount);
      setSendSummary(false);
      setProgress('');
      setErrors([]);
      setAllCount(0);
      setErrorCount(0);
    }
  }, [errors, errorCount, allCount, props, sendSummary]);

  const onSign = async () => {
    setSigningInProgress(true);
    let referrals: GetSupportingExaminationDto[] = [];
    try {
      referrals = await spd.support.getAllForUserToSign(props.userUid);
    } catch (err) {
      const errors = await responseErrors(err as Response);
      setErrors(prevArray => [
        ...prevArray,
        `Błąd pobierania skierowania dla userUid: ${props.userUid}`,
        ...errors,
      ]);
      setSigningInProgress(false);
      setSendSummary(true);
      return;
    }

    setAllCount(referrals.length);
    for (let index = 0; index < referrals.length; index++) {
      const referral = referrals[index];
      setProgress(`podpisano ${index + 1} z ${referrals.length}`);
      await signSaveAndSend(referral, index);
    }
    setSigningInProgress(false);
    setSendSummary(true);
  };

  const signSaveAndSend = async (
    referral: GetSupportingExaminationDto,
    index: number,
  ) => {
    try {
      const taskList = await spd.szafir.getTaskListForSupportingExamination(
        referral.id,
        auth.userProfile?.id,
      );
      await szafir.signDocument(
        taskList,
        auth.userProfile?.pin || '',
        referral.id,
        '',
        false,
        saveSignedPdf,
        signedFailed,
      );
      if (index === 0) {
        await new Promise(f => setTimeout(f, 3000));
      } else {
        await new Promise(f => setTimeout(f, 2000));
      }
    } catch (err) {
      setErrorCount(prevCount => prevCount + 1);
      const errors = await responseErrors(err as Response);
      setErrors(prevArray => [
        ...prevArray,
        `Błąd podpisywania skierowania o id: ${referral.id} z karty ${referral.card?.number} - `,
        ...errors,
      ]);
    }
  };

  const saveSignedPdf = async (result: Buffer | null, documentId: string) => {
    spd.savedPdfs
      .savePdfFileForReferral({
        entityId: documentId,
        file: result,
        pdfTemplateType:
          TemplateType.ClinicReferral as unknown as CreateUpdateSavedPdfDtoPdfTemplateTypeEnum,
      })
      .then(async () => {
        await spd.emails.sendReferralByEmail(
          documentId,
          auth.userProfile?.id || '',
        );
      })
      .catch(async response => {
        setErrorCount(prevCount => prevCount + 1);
        const errors = await responseErrors(response as Response);
        setErrors(prevArray => [
          ...prevArray,
          `Błąd podpisywania dokumentu o id: ${documentId} - `,
          ...errors,
        ]);
      });
  };

  const signedFailed = (error: string, documentId: string) => {
    setErrorCount(prevCount => prevCount + 1);
    setErrors(prevArray => [
      ...prevArray,
      `Błąd podpisywania dokumentu o id: ${documentId} - `,
      error,
    ]);
  };

  return (
    <>
      <ConfirmationButton
        className="mb-3"
        confirmation="Czy na pewno rozpocząć podpisywanie wszystkich dostępnych do podpisu skierowań?"
        variant="outline-primary"
        onOK={() => onSign()}
      >
        {signingInProgress
          ? `Trwa podpisywanie...  ${progress}`
          : 'Podpisz wszystkie swoje skierowania'}
      </ConfirmationButton>
    </>
  );
};
