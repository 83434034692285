/* eslint-disable complexity */
import React from 'react';

import { Grid } from '../../../common/components/Grid/Grid';
import momencik from '../../../common/momencik';
import {
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import { ExaminedFromExamPreviewLink } from '../Examined/ExaminedFromExamPreviewLink';
import { GetSupportingExaminationDto } from '../../../common/spdCore/autogenerated/spdApiClient';

import { ReferralsPreviewLink } from './ReferralsPreviewLink';
import { ReferralSignLink } from './ReferralSignLink';

interface ReferralsSearchProps {
  getData: GridGetterFunction;
  getCount: GridCountFunction;
  onRowClick?: (key: string) => Promise<void> | void | undefined;
  refresh?: boolean;
  enableSingleSelect?: boolean;
  selectedKeys?: string[];
}

export const referralsForGrid = (
  supporting: GetSupportingExaminationDto,
  setRefresh?: () => void | undefined,
) => {
  console.log(setRefresh);
  return {
    key: supporting.id,
    values: {
      'exam.number': (
        <>
          {/* {support?.exam?.id ? (
            <ReferralsLink
              examId={support?.exam?.id}
              type={support?.type as unknown as ConsultationType}
            />
          ) : (
            <></>
          )}{' '} */}
          {supporting?.exam?.number}
        </>
      ),
      'supporting.referralDate': supporting.referralDate
        ? momencik(supporting.referralDate)
        : '',
      'exam.examined': supporting?.exam?.examined && (
        <>
          <ExaminedFromExamPreviewLink examId={supporting?.exam?.id} />{' '}
          {supporting?.exam?.examined?.surname}{' '}
          {supporting?.exam?.examined?.firstname}
        </>
      ),
      'examined.pesel':
        supporting?.exam?.examined?.pesel ||
        (supporting?.exam?.examined?.iDCardType || '') +
          ' ' +
          (supporting?.exam?.examined?.iDCardNumber || '') ||
        '',
      'examKindDict.name': supporting.examKindDict?.name || '',
      preview: <ReferralsPreviewLink supportingId={supporting.id} />,
      sign: (
        <>
          <ReferralSignLink referralId={supporting.id} />
        </>
      ),
    },
  };
};

export const ReferralsSearch: React.FC<ReferralsSearchProps> = props => {
  const columns = [
    { header: 'Numer badania', property: 'exam.number' },
    { header: 'Rodzaj', property: 'examKindDict.name' },
    { header: 'Data skierowania', property: 'supporting.referralDate' },
    { header: 'Badany', property: 'exam.examined' },
    { header: 'PESEL / dokument', property: 'examined.pesel' },
    { header: '', property: 'preview', noSorting: true },
    { header: '', property: 'sign', noSorting: true },
  ];

  return (
    <Grid
      getData={props.getData}
      getDataCount={props.getCount}
      columns={columns}
      showFilter={true}
      initialOrderColumn="supporting.referralDate"
      initialOrderDirection="DESC"
      availablePageSizes={[10, 20, 50]}
      onRowClick={props.onRowClick}
      emptyGridMessage="Brak skierowania w systemie"
      refresh={props.refresh}
      enableSingleSelect={props.enableSingleSelect}
      selectedKeys={props.selectedKeys}
    />
  );
};
