import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

import { useSpdCore } from '../../../common/hooks/useSpdCore';

export const ConsultationPreviewLink = (props: { consultationId: string }) => {
  const spd = useSpdCore();

  const handleClick = () => {
    spd.pdfPrinter
      .loadAndPrintConsultation(props.consultationId)
      .catch(error =>
        console.log(`Wystąpił problem z wydrukiem konsultacji. ${error}`),
      );
  };

  return (
    <>
      <OverlayTrigger overlay={<Tooltip>Wydruk</Tooltip>}>
        <Button
          onClick={e => {
            e.stopPropagation();
            handleClick();
          }}
          variant="btn-info"
        >
          <Icon.Printer />
        </Button>
      </OverlayTrigger>
    </>
  );
};
