import React, { useEffect, useState } from 'react';
import { Card, Tab } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import Tabs from '../../../app/components/Tabs';
import { useSpdCore } from '../../../common/hooks/useSpdCore';
import { useNavigation } from '../../../common/navigation';
import { ExamSearch } from '../../components/Exam/ExamSearch';
import { GetExamsPackageDto } from '../../../common/spdCore/autogenerated/spdApiClient';
import { ExamsPackageDeleteLink } from '../../components/ExamsPackage/ExamsPackageDeleteLink';
import { useNotifications } from '../../../common/hooks/useNotifications';

type ParamTypes = {
  id: string;
};

export const ExamsInPackageView: React.FC = () => {
  const spd = useSpdCore();
  const nav = useNavigation();
  const [examPackage, setExamPackage] = useState<GetExamsPackageDto>();

  const notifications = useNotifications();

  const { id } = useParams<ParamTypes>() as {
    id: string;
  };

  const [refresh, setRefresh] = useState(true);

  useEffect(() => {
    spd.examsPackage
      .get(id)
      .then(pac => setExamPackage(pac))
      .catch(error =>
        console.log(`Wystąpił problem z odświeżeniem strony. ${error}`),
      );
  }, [id, spd.examsPackage]);

  const handleClick = async (id: string) => {
    nav.examTests(id);
  };

  const getData = async (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
  ) => {
    return spd.exams.getForPackageByPortion(
      pageSize,
      pageNumber,
      orderBy,
      orderDirection,
      filterText,
      id,
    );
  };

  const getCount = async (filterText?: string) => {
    return spd.exams.getForPackageCount(filterText, id);
  };

  const deleteExpectationExams = () => {
    if (id) {
      notifications.onPromise(
        spd.examsPackage.deleteExpectationExams(id).then(() => {
          setRefresh(!refresh);
        }),
      );
    }
  };

  return (
    <Card>
      <Tabs defaultActiveKey={'examForPackage'} id="exams" className="mb-3">
        <Tab
          eventKey="examForPackage"
          title={`Badania związane z paczką ${
            examPackage?.number
          } wygenerowaną firmie: ${examPackage?.company?.name || ''}`}
          className="m-3"
        >
          <ExamSearch
            getData={getData}
            getCount={getCount}
            onRowClick={handleClick}
            refresh={refresh}
          />
          <ExamsPackageDeleteLink
            deleteExpectationExams={deleteExpectationExams}
          />
        </Tab>
      </Tabs>
    </Card>
  );
};
