/* tslint:disable */
/* eslint-disable */
/**
 * SPD
 * Opis api SPD
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CreateUpdateExamWithTestsDto,
    CreateUpdateExamWithTestsDtoFromJSON,
    CreateUpdateExamWithTestsDtoFromJSONTyped,
    CreateUpdateExamWithTestsDtoToJSON,
    CreateUpdateExaminedDto,
    CreateUpdateExaminedDtoFromJSON,
    CreateUpdateExaminedDtoFromJSONTyped,
    CreateUpdateExaminedDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreateUpdateExternalReferralDto
 */
export interface CreateUpdateExternalReferralDto {
    /**
     * 
     * @type {CreateUpdateExaminedDto}
     * @memberof CreateUpdateExternalReferralDto
     */
    examined?: CreateUpdateExaminedDto | null;
    /**
     * 
     * @type {CreateUpdateExamWithTestsDto}
     * @memberof CreateUpdateExternalReferralDto
     */
    examWithTests?: CreateUpdateExamWithTestsDto | null;
}

export function CreateUpdateExternalReferralDtoFromJSON(json: any): CreateUpdateExternalReferralDto {
    return CreateUpdateExternalReferralDtoFromJSONTyped(json, false);
}

export function CreateUpdateExternalReferralDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateExternalReferralDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'examined': !exists(json, 'examined') ? undefined : CreateUpdateExaminedDtoFromJSON(json['examined']),
        'examWithTests': !exists(json, 'examWithTests') ? undefined : CreateUpdateExamWithTestsDtoFromJSON(json['examWithTests']),
    };
}

export function CreateUpdateExternalReferralDtoToJSON(value?: CreateUpdateExternalReferralDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'examined': CreateUpdateExaminedDtoToJSON(value.examined),
        'examWithTests': CreateUpdateExamWithTestsDtoToJSON(value.examWithTests),
    };
}


