import React, { useEffect, useState } from 'react';
import { Form, Card, Button } from 'react-bootstrap';

import ValidationAlert from '../../../app/components/ValidationAlert';
import { useSpdCore } from '../../../common/hooks/useSpdCore';
import { ExaminedList } from '../Examined/ExaminedList';

import { TestGivingProps } from './utilsTestGiving';

interface Props {
  nextStep: () => void;
  handleFormData: (input: string) => (e: string) => void;
  values: TestGivingProps;
}

export const ChooseExamined: React.FC<Props> = ({
  nextStep,
  handleFormData,
  values,
}) => {
  const spd = useSpdCore();

  const [examined, setExamined] = useState('');
  const [selectedExamined, setSelectedExamined] = useState<string | undefined>(
    values.examinedKey,
  );
  const [showErrors, setShowErrors] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);

  useEffect(() => {
    if (values.examinedKey) {
      setSelectedExamined(values.examinedKey);
    }
  }, [values.examinedKey]);

  useEffect(() => {
    if (selectedExamined) {
      spd.examineds
        .getExamined(selectedExamined)
        .then(o => setExamined(`${o.surname} ${o.firstname}` || ''))
        .catch(error => {
          console.error('Error during getAll examined.', error);
        });
    }
  }, [spd.examineds, selectedExamined]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const submitFormData = (e: any) => {
    e.preventDefault();
    if (!selectedExamined) {
      setShowErrors(true);
      setErrors(['Proszę wskazać badanego.']);
      return;
    }
    nextStep();
  };

  const handleOnRowClick = (key: string) => {
    setShowErrors(false);
    setErrors([]);
    handleFormData('examinedKey')(key);
    setSelectedExamined(key);
  };

  return (
    <div>
      <Card>
        <Card.Body>
          <Form onSubmit={submitFormData}>
            <Form.Group className="mb-3">
              <Form.Label>Wybrany badany:</Form.Label>{' '}
              <Form.Label>
                <strong>{examined}</strong>
              </Form.Label>
              <ExaminedList
                onRowClick={handleOnRowClick}
                enableSingleSelect={true}
                keyToSelect={selectedExamined}
                setKeyToSelect={handleFormData('examinedKey')}
              />
            </Form.Group>
            <div className="d-flex justify-content-end m-3">
              <Button variant="outline-primary" type="submit">
                Dalej
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
      <ValidationAlert show={showErrors} errors={errors} className="mt-3" />
    </div>
  );
};
