import React, { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';

import { useSpdCore } from '../../../common/hooks/useSpdCore';
import { GetBatteryDto } from '../../../common/spdCore/autogenerated/spdApiClient';
import { Selector, Option } from '../Selects/Selector';

export interface BatterySelectorProps {
  readOnly?: boolean;
  value?: string;
  batteries?: GetBatteryDto[];
  placeholder?: string;
  className?: string;
  onChange?: (option: Option) => void;
}

export default (props: BatterySelectorProps) => {
  const spd = useSpdCore();
  const [batteries, setBatteries] = useState<GetBatteryDto[]>(
    props.batteries || [],
  );
  const [displayName, setDisplayName] = useState<string>();
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (props.value) {
      spd.batteries
        .getBattery(props.value || '')
        .then(battery => {
          setDisplayName(battery.name || '');
        })
        .catch(error =>
          console.log(`Wystąpił problem z pobraniem baterii testów. ${error}`),
        );
    }
  }, [props.value, spd.batteries]);

  useEffect(() => {
    let mounted = true;
    if (!props.batteries || props.batteries.length === 0) {
      spd.batteries
        .getAll()
        .then(batteries => {
          if (mounted) {
            setBatteries(batteries);
            setReady(true);
          }
        })
        .catch(error =>
          console.log(`Wystąpił problem z pobraniem baterii testów. ${error}`),
        );
    } else {
      setBatteries(props.batteries);
      setReady(true);
    }

    return () => {
      mounted = false;
    };
  }, [spd.batteries, props.batteries]);

  const provider = async (text: string) => {
    const values = batteries.filter(v => {
      const name = v.name || '';
      return name.toLocaleLowerCase().includes(text.toLocaleLowerCase());
    });

    return values.map(v => {
      return {
        label: v.name || '',
        value: v.id,
      };
    });
  };

  return ready ? (
    <Selector
      uniqueKey={'batteries-' + JSON.stringify(batteries)}
      className={props.className}
      readOnly={props.readOnly}
      creatable={false}
      placeholder={props.placeholder || 'Wskaż...'}
      provider={provider}
      value={displayName}
      onChange={props.onChange}
    />
  ) : (
    <Skeleton />
  );
};
