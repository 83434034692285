/* tslint:disable */
/* eslint-disable */
/**
 * SPD
 * Opis api SPD
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetStampDto
 */
export interface GetStampDto {
    /**
     * 
     * @type {string}
     * @memberof GetStampDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetStampDto
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof GetStampDto
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof GetStampDto
     */
    image: string;
}

export function GetStampDtoFromJSON(json: any): GetStampDto {
    return GetStampDtoFromJSONTyped(json, false);
}

export function GetStampDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetStampDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'type': json['type'],
        'userId': json['userId'],
        'image': json['image'],
    };
}

export function GetStampDtoToJSON(value?: GetStampDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': value.type,
        'userId': value.userId,
        'image': value.image,
    };
}


