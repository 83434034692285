/*eslint max-lines-per-function: ["error", 240]*/
/* eslint-disable complexity */
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Row, Tab } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import { useSpdCore } from '../../../common/hooks/useSpdCore';
import { ChooseTest } from '../../components/TestGiving/ChooseTest';
import {
  CreateUpdateExamWithTestsDto,
  CreateUpdateExamWithTestsDtoTakenCareOfEnum,
  CreateUpdateExamWithTestsDtoTypeEnum,
} from '../../../common/spdCore/autogenerated/spdApiClient';
import { useNavigation } from '../../../common/navigation';
import { AddDescription } from '../../components/TestGiving/AddDescription';
import { examData } from '../../../common/spdCore/contexts/ExamContext';
import { ChooseArticle } from '../../components/TestGiving/ChooseArticle';
import {
  ArticleMode,
  ExaminationType,
  Healthcare,
  KindOfScience,
  TakenCareOf,
} from '../../../common/spdCore/validation/schemas';
import { AddEmployer } from '../../components/TestGiving/AddEmployer';
import { AddStudent } from '../../components/TestGiving/AddStudent';
import { AddHarmfulFactory } from '../../components/TestGiving/AddHarmfulFactory';
import { AddSport } from '../../components/TestGiving/AddSport';
import { TabsHistory } from '../../../app/components/TabsHistory';
import ValidationAlert from '../../../app/components/ValidationAlert';
import { responseErrors } from '../../../common/spdCore/validation/responseErrors';

type ParamTypes = {
  id: string;
};

export const ExamEditorView: React.FC = () => {
  const spd = useSpdCore();
  const nav = useNavigation();

  const [exam, setExam] = useState<CreateUpdateExamWithTestsDto>();
  const [testIds, setTestIds] = useState<string[]>([]);
  const [articleDoctorsIds, setArticleDoctorsIds] = useState<string[]>([]);
  const [healthcareFacilityId, setHealthcareFacilityId] = useState<string>();
  const [articlePsychologistsIds, setArticlePsychologistsIds] = useState<
    string[]
  >([]);

  const [showSaveErrors, setShowSaveErrors] = useState(false);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);

  const { id } = useParams<ParamTypes>() as {
    id: string;
  };

  useEffect(() => {
    spd.exams
      .getExam(id)
      .then(exam => {
        setExam(examData(exam));
        setArticleDoctorsIds(exam.articleDoctorsIds || []);
        setArticlePsychologistsIds(exam.articlePsychologistsIds || []);
        setHealthcareFacilityId(exam.examPackage?.healthcareFacility?.id || '');
      })
      .catch(error => {
        console.error('Wystąpił problem z pobraniem badania', error);
      });
  }, [spd.exams, id]);

  useEffect(() => {
    spd.examTests
      .getByExam(id)
      .then(examT => {
        setTestIds(examT?.map(t => t.test?.id) as string[]);
      })
      .catch(error => {
        console.error(
          'Wystąpił problem z pobraniem testów związanych z badaniem',
          error,
        );
      });
  }, [spd.examTests, id]);

  const onSave = async () => {
    try {
      if (exam) {
        const ex = await spd.exams.updateWithTests(id, {
          ...exam,
          ...{
            testsId: testIds,
            articleDoctorsIds: articleDoctorsIds,
            articlePsychologistsIds: articlePsychologistsIds,
            type: exam.type as unknown as CreateUpdateExamWithTestsDtoTypeEnum,
            kindOfScience: exam.kindOfScience,
            takenCareOf:
              exam.takenCareOf as unknown as CreateUpdateExamWithTestsDtoTakenCareOfEnum,
            healthcareFacilityId: healthcareFacilityId,
            examPackageId: exam.examPackageId,
          },
        });

        setExam(examData(ex));
        setShowSaveErrors(false);
        nav.goBack();
      }
    } catch (response) {
      setSaveErrors([
        'Wystąpił problem z zapisaniem badania wraz z testami. ' +
          (await responseErrors(response as Response)),
      ]);
      setShowSaveErrors(true);
      return false;
    }
  };

  const handleOnChangeTestIds = (newTestIds: string[]) => {
    setTestIds(newTestIds);
  };

  const handleOnChangeArticlesPsIds = (newArticlesIds: string[]) => {
    setArticlePsychologistsIds(newArticlesIds);
  };

  const handleOnChangeArticlesDrIds = (newArticlesIds: string[]) => {
    setArticleDoctorsIds(newArticlesIds);
  };

  const propertyChange = (obj: Partial<CreateUpdateExamWithTestsDto>) => {
    if (obj.healthcareFacilityId) {
      setHealthcareFacilityId(obj.healthcareFacilityId);
    }
    setExam({ ...exam, ...obj });
  };

  return (
    <Card>
      <Card.Header>{`Edycja badania ${exam?.number}`}</Card.Header>
      <Card.Body>
        <TabsHistory
          activeKey="editDescription"
          id="testEditor"
          className="mb-3"
        >
          <Tab eventKey="editDescription" title="Opis" className="m-3">
            <AddDescription
              drivingLicense={exam?.drivingLicense}
              occupMedicineText={exam?.occupationalMedicineText}
              occupMedicineCategory={exam?.occupationalMedicineCategory}
              description={exam?.description}
              propertyChange={propertyChange}
              completed={exam?.completed}
              neurologist={exam?.neurologist}
              ophthalmologist={exam?.ophthalmologist}
              laryngologist={exam?.laryngologist}
              healthcareFacilityId={healthcareFacilityId}
              healthcare={
                (exam?.healthcare as unknown as Healthcare) || undefined
              }
              alarm={exam?.alarm}
            />
          </Tab>
          {exam && !exam.endDate && (
            <Tab eventKey="editExamTests" title="Testy" className="m-3">
              <Container>
                <Row>
                  <Col>
                    <ChooseTest
                      testsKeys={testIds || []}
                      onChange={handleOnChangeTestIds}
                    />
                  </Col>
                </Row>
              </Container>
            </Tab>
          )}
          <Tab
            eventKey="articlesPsychologists"
            title="Podstawy prawne - psycholog"
            className="m-3"
          >
            <Container>
              <Row>
                <Col>
                  <ChooseArticle
                    articlesKeys={articlePsychologistsIds}
                    mode={ArticleMode.Psychologist}
                    onChange={handleOnChangeArticlesPsIds}
                  />
                </Col>
              </Row>
            </Container>
          </Tab>
          <Tab
            eventKey="articlesDoctors"
            title="Podstawy prawne - lekarz"
            className="m-3"
          >
            <Container>
              <Row>
                <Col>
                  <ChooseArticle
                    articlesKeys={articleDoctorsIds}
                    mode={ArticleMode.Doctor}
                    onChange={handleOnChangeArticlesDrIds}
                  />
                </Col>
              </Row>
            </Container>
          </Tab>
          <Tab eventKey="harmfulFactory" title="Skierowanie" className="m-3">
            <AddHarmfulFactory
              referralFromEmployer={exam?.referralFromEmployer || undefined}
              areHarmfulFactors={exam?.areHarmfulFactors || undefined}
              areResultsHarmfulFactors={
                exam?.areResultsHarmfulFactors || undefined
              }
              takenCareOf={
                (exam?.takenCareOf as unknown as TakenCareOf) || undefined
              }
              harmfulFactors={exam?.harmfulFactors || ''}
              propertyChange={propertyChange}
            />
          </Tab>
          <Tab eventKey="employer" title="Pracodawca" className="m-3">
            <AddEmployer
              employmentCandidate={exam?.employmentCandidate || undefined}
              employerName={exam?.employerName || ''}
              employerAddress={exam?.employerAddress || ''}
              employmentPosition={exam?.employmentPosition || ''}
              type={(exam?.type as unknown as ExaminationType) || undefined}
              referralDate={exam?.referralDate || undefined}
              propertyChange={propertyChange}
            />
          </Tab>
          <Tab eventKey="student" title="Placówka dydaktyczna" className="m-3">
            <AddStudent
              propertyChange={propertyChange}
              schoolCandidate={exam?.schoolCandidate || undefined}
              kindOfScience={
                (exam?.kindOfScience as unknown as KindOfScience) || undefined
              }
              schoolName={exam?.schoolName || ''}
              schoolAddress={exam?.schoolAddress || ''}
              fieldOfStudy={exam?.fieldOfStudy || ''}
              endScience={exam?.endScience || undefined}
            />
          </Tab>
          <Tab eventKey="sport" title="Sport" className="m-3">
            <AddSport
              propertyChange={propertyChange}
              sportsClubName={exam?.sportsClubName || ''}
              sportsClubAddress={exam?.sportsClubAddress || ''}
              sportsDiscipline={exam?.sportsDiscipline || ''}
            />
          </Tab>
        </TabsHistory>
        <ValidationAlert
          show={showSaveErrors}
          errors={saveErrors}
          className="mt-3"
        />
        <Button
          type="submit"
          className="w-100 btn btn-lg btn-login mt-3"
          onClick={onSave}
        >
          Zapisz
        </Button>
      </Card.Body>
    </Card>
  );
};
