import { DefaultApi } from '../autogenerated/spdApiClient/apis/DefaultApi';
import { GetEmailDto } from '../autogenerated/spdApiClient';
import { GridRecord } from '../../components/Grid/GridDataTypes';
import { emailForGrid } from '../../../spd/components/Emails/Emails';

export type EmailEntity = GetEmailDto;

export interface EmailContextInterface {
  getAllByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
    onlyNotSent?: boolean,
    setRefresh?: () => void,
    setLoading?: (loading: boolean) => void,
  ) => Promise<GridRecord[]>;
  getAllCount: (filterText?: string, onlyNotSent?: boolean) => Promise<number>;
  changeToInactive: (id: string) => Promise<void>;
  sendJudgmentByEmail: (
    judgmentId: string,
    userId: string,
    english: boolean,
  ) => Promise<GetEmailDto>;
  sendReferralByEmail: (
    referralId: string,
    userId: string,
  ) => Promise<GetEmailDto>;
  resend: (id: string, userId: string) => Promise<GetEmailDto>;
  sendAllWaitingEmails: (userId: string) => Promise<string>;
  sendPsychologicalCardSummary: (id: string) => Promise<GetEmailDto>;
}

export const EmailContext = (api: DefaultApi) => {
  return {
    getAllByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
      onlyNotSent = true,
      setRefresh?: () => void,
      setLoading?: (loading: boolean) => void,
    ) => {
      const data = await api.emailControllerGetAllByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
        onlyNotSent,
      );
      return data.map(email => emailForGrid(email, setRefresh, setLoading));
    },

    changeToInactive: (id: string) => api.emailControllerChangeToInactive(id),

    getAllCount: (filterText?: string, onlyNotSent = true) =>
      api.emailControllerGetAllCount(filterText || '', onlyNotSent),

    sendJudgmentByEmail: (
      judgmentId: string,
      userId: string,
      english: boolean,
    ) => api.emailControllerSendJudgmentByEmail(judgmentId, userId, english),

    sendReferralByEmail: (referralId: string, userId: string) =>
      api.emailControllerSendReferralByEmail(referralId, userId),

    resend: (id: string, userId: string) =>
      api.emailControllerResendEmail(id, userId),

    sendAllWaitingEmails: (userId: string) =>
      api.emailControllerSendAllWaitingEmails(userId),

    sendPsychologicalCardSummary: (id: string) =>
      api.emailControllerSendPsychologicalCardSummary(id),
  };
};
