import { DefaultApi } from '../autogenerated/spdApiClient/apis/DefaultApi';
import { GetRegisterDto } from '../autogenerated/spdApiClient';
import { GridRecord } from '../../components/Grid/GridDataTypes';
import { registerForGrid } from '../../../spd/components/Register/RegisterSearch';
import { RegisterMode } from '../validation/schemas';

export interface RegisterContextInterface {
  get: (id: string) => Promise<GetRegisterDto>;
  getByMode: (registerMode: RegisterMode) => Promise<GetRegisterDto | null>;
  getAll: () => Promise<GetRegisterDto[]>;
  getAllCount: (filterText?: string) => Promise<number>;
  getAllByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
  ) => Promise<GridRecord[]>;
}

export const RegisterContext = (api: DefaultApi) => {
  return {
    get: async (id: string) => {
      return await api.registerControllerGet(id);
    },

    getByMode: async (registerMode: RegisterMode) => {
      const registers = await api.registerControllerGetByMode(registerMode);
      if (registers.length === 1) {
        return registers[0];
      }
      return null;
    },

    getAll: () => api.registerControllerGetAll(),

    getAllByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
    ) => {
      const data = await api.registerControllerGetAllByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
      );
      return data.map(registerForGrid);
    },

    getAllCount: (filterText?: string) =>
      api.registerControllerGetAllCount(filterText || ''),
  };
};
