import React from 'react';
import { Button, ButtonGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

import { useAuth } from '../../../common/hooks/useAuth';

export const ExamChatLink = (props: { examId: string; token: string }) => {
  const auth = useAuth();
  auth.canSign;
  return (
    <>
      <ButtonGroup aria-label="TestPlay">
        <OverlayTrigger overlay={<Tooltip>Podgląd badanego </Tooltip>}>
          <Button
            onClick={e => {
              e.stopPropagation();
              window.open(
                `${process.env.REACT_APP_CHAT_APP_URL}/monitor?exam=${props.examId}&token=${props.token}`,
                'blank',
              );
            }}
            variant="outline-primary"
          >
            <Icon.Binoculars />
          </Button>
        </OverlayTrigger>
        <OverlayTrigger overlay={<Tooltip>Chat z badanym</Tooltip>}>
          <Button
            onClick={e => {
              e.stopPropagation();
              window.open(
                `${process.env.REACT_APP_CHAT_APP_URL}/consultation?exam=${props.examId}&token=${props.token}`,
                'blank',
              );
            }}
            variant="outline-primary"
          >
            <Icon.ChatDots />
          </Button>
        </OverlayTrigger>
      </ButtonGroup>
    </>
  );
};
