import { DefaultApi } from '../autogenerated/spdApiClient/apis/DefaultApi';
import {
  CreateUpdateConsultationDto,
  CreateUpdateConsultationDtoFromJSON,
} from '../autogenerated/spdApiClient/models/CreateUpdateConsultationDto';
import { GetConsultationDto } from '../autogenerated/spdApiClient/models/GetConsultationDto';
//import { validateAgainst } from '../validation/validateAgainst';
import { GridRecord } from '../../components/Grid/GridDataTypes';
import { consultationForGrid } from '../../../spd/components/Consultations/ConsultationsSearch';

export type ConsultationEntity = GetConsultationDto;

export interface ConsultationContextInterface {
  get: (id: string) => Promise<ConsultationEntity>;
  getForExam: (id: string) => Promise<ConsultationEntity[]>;
  getAllByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
  ) => Promise<GridRecord[]>;
  getAllCount: (filterText?: string) => Promise<number>;
  create: (dto: CreateUpdateConsultationDto) => Promise<GetConsultationDto>;
  update: (id: string, dto: CreateUpdateConsultationDto) => Promise<void>;
}

export const ConsultationContext = (api: DefaultApi) => {
  return {
    get: async (id: string) => {
      return await api.consultationControllerGet(id);
    },

    getForExam: async (id: string) => {
      return await api.consultationControllerGetForExam(id);
    },

    getAllByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
    ) => {
      const data = await api.consultationControllerGetAllByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
      );
      return data.map(consultationForGrid);
    },

    getAllCount: (filterText?: string) =>
      api.consultationControllerGetAllCount(filterText || ''),

    create: (dto: CreateUpdateConsultationDto) =>
      api.consultationControllerCreate(dto),

    update: (id: string, dto: CreateUpdateConsultationDto) =>
      api.consultationControllerUpdate(id, dto),
  };
};

// export const validate = (consultation: CreateUpdateConsultationDto) => {
//   return validateAgainst(ConsultationSchema, consultation);
// };

export const consultationData = (
  consultation?: GetConsultationDto,
): CreateUpdateConsultationDto => {
  return CreateUpdateConsultationDtoFromJSON({
    ...consultation,
    ...{ authorUID: consultation?.author?.uid || '' },
  });
};
