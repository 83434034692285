import React from 'react';

import { Grid } from '../../../common/components/Grid/Grid';
import {
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import { GetAccessDto } from '../../../common/spdCore/autogenerated/spdApiClient';

interface AccessProps {
  getData: GridGetterFunction;
  getCount: GridCountFunction;
  refresh?: boolean;
}

export const accessForGrid = (access: GetAccessDto) => {
  return {
    key: access.id,
    values: {
      user_name: access.userName,
      accessDeviceDecode: access.accessDeviceDecode,
    },
  };
};

export const Access: React.FC<AccessProps> = props => {
  const columns = [
    { header: 'Użytkownik', property: 'user_name' },
    { header: 'Urządzenie', property: 'accessDeviceDecode', noSort: true },
  ];

  return (
    <Grid
      getData={props.getData}
      getDataCount={props.getCount}
      columns={columns}
      showFilter={true}
      initialOrderColumn="user_name"
      availablePageSizes={[10, 20, 50]}
      emptyGridMessage="Brak dostępów w systemie"
      enableMultiSelect={false}
      refresh={props.refresh}
    />
  );
};
