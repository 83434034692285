import {
  CreateUpdateJudgmentNumberingDto,
  CreateUpdateJudgmentNumberingDtoFromJSON,
  GetJudgmentNumberingDto,
} from '../autogenerated/spdApiClient';
import { DefaultApi } from '../autogenerated/spdApiClient/apis/DefaultApi';
import { JudgmentNumberingSchema } from '../validation/schemas';
import { validateAgainst } from '../validation/validateAgainst';

export interface JudgmentNumberingContextInterface {
  get: (id: string) => Promise<GetJudgmentNumberingDto>;
  getAll: () => Promise<GetJudgmentNumberingDto[]>;
  create: (
    dto: CreateUpdateJudgmentNumberingDto,
  ) => Promise<GetJudgmentNumberingDto>;
  update: (id: string, dto: CreateUpdateJudgmentNumberingDto) => Promise<void>;
}

export const JudgmentNumberingContext = (api: DefaultApi) => {
  return {
    get: async (id: string) => {
      return await api.judgmentNumberingControllerGet(id);
    },

    getAll: () => api.judgmentNumberingControllerGetAll(),

    update: (id: string, dto: CreateUpdateJudgmentNumberingDto) =>
      api.judgmentNumberingControllerUpdate(id, dto),

    create: (dto: CreateUpdateJudgmentNumberingDto) =>
      api.judgmentNumberingControllerCreate(dto),
  };
};

export const validate = (test: CreateUpdateJudgmentNumberingDto) =>
  validateAgainst(JudgmentNumberingSchema, test);

export const judgmentNumberingData = (
  judgNum?: GetJudgmentNumberingDto,
): CreateUpdateJudgmentNumberingDto => {
  return CreateUpdateJudgmentNumberingDtoFromJSON({
    ...judgNum,
    ...{ registerId: judgNum?.register?.id, userUID: judgNum?.user?.uid },
  });
};
