/* tslint:disable */
/* eslint-disable */
/**
 * SPD
 * Opis api SPD
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetAccessDto
 */
export interface GetAccessDto {
    /**
     * 
     * @type {string}
     * @memberof GetAccessDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetAccessDto
     */
    uid?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAccessDto
     */
    device?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAccessDto
     */
    accessDeviceDecode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAccessDto
     */
    userName?: string | null;
}

export function GetAccessDtoFromJSON(json: any): GetAccessDto {
    return GetAccessDtoFromJSONTyped(json, false);
}

export function GetAccessDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAccessDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'uid': !exists(json, 'uid') ? undefined : json['uid'],
        'device': !exists(json, 'device') ? undefined : json['device'],
        'accessDeviceDecode': !exists(json, 'accessDeviceDecode') ? undefined : json['accessDeviceDecode'],
        'userName': !exists(json, 'userName') ? undefined : json['userName'],
    };
}

export function GetAccessDtoToJSON(value?: GetAccessDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'uid': value.uid,
        'device': value.device,
        'accessDeviceDecode': value.accessDeviceDecode,
        'userName': value.userName,
    };
}


