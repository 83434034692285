import { examinedForGrid } from '../../../spd/components/Examined/ExaminedSearch';
import { DefaultApi } from '../autogenerated/spdApiClient/apis/DefaultApi';
import { GetExaminedDto } from '../autogenerated/spdApiClient/models/GetExaminedDto';
import {
  CreateUpdateExaminedDto,
  CreateUpdateExaminedDtoFromJSON,
} from '../autogenerated/spdApiClient';
import { ExaminedSchema } from '../validation/schemas';
import { validateAgainst } from '../validation/validateAgainst';
import { GridRecord } from '../../components/Grid/GridDataTypes';
import {
  Option,
  defaultSelectorLimit,
} from '../../../spd/components/Selects/Selector';

export type ExaminedEntity = GetExaminedDto;

export interface ExaminedContextInterface {
  getExamined: (id: string) => Promise<ExaminedEntity>;
  getAllByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
  ) => Promise<GridRecord[]>;
  getAllCount: (filterText?: string) => Promise<number>;
  update: (id: string, dto: CreateUpdateExaminedDto) => Promise<void>;
  create: (dto: CreateUpdateExaminedDto) => Promise<GetExaminedDto>;
  getCitiesByName: (name: string) => Promise<Option[]>;
  getStreetsByName: (name: string) => Promise<Option[]>;
  getCityByPostCode: (name: string) => Promise<string>;
  changeToInactive: (id: string) => Promise<void>;
  mergeExamined: (
    examinedDeleteId: string,
    examinedStayId: string,
  ) => Promise<void>;
}

export const ExaminedContext = (api: DefaultApi) => {
  return {
    getExamined: async (id: string) => {
      return await api.examinedControllerGet(id);
    },
    getAllByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
    ) => {
      const data = await api.examinedControllerGetAllByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
      );
      return data.map(examinedForGrid);
    },

    getAllCount: (filterText?: string) =>
      api.examinedControllerGetAllCount(filterText || ''),

    update: (id: string, dto: CreateUpdateExaminedDto) =>
      api.examinedControllerUpdate(id, dto),
    create: (dto: CreateUpdateExaminedDto) =>
      api.examinedControllerCreate(dto as GetExaminedDto),
    getCitiesByName: async (text: string) => {
      const data = await api.examinedControllerGetCitiesByPattern(
        text,
        defaultSelectorLimit,
      );

      return Array.from(data, city => {
        return {
          value: city,
          label: city,
        };
      });
    },
    getStreetsByName: async (text: string) => {
      const data = await api.examinedControllerGetStreetsByPattern(
        text,
        defaultSelectorLimit,
      );

      return Array.from(data, street => {
        return {
          value: street,
          label: street,
        };
      });
    },

    getCityByPostCode: async (postcode: string) => {
      return await api.examinedControllerGetCityByPostCode(postcode);
    },

    changeToInactive: (id: string) =>
      api.examinedControllerChangeToInactive(id),

    mergeExamined: (examinedDeleteId: string, examinedStayId: string) =>
      api.examinedControllerMergeExamined(examinedDeleteId, examinedStayId),
  };
};

export const validate = (examined: CreateUpdateExaminedDto) => {
  return validateAgainst(ExaminedSchema, examined);
};

export const examinedData = (
  examined?: GetExaminedDto,
): CreateUpdateExaminedDto => {
  return CreateUpdateExaminedDtoFromJSON({
    ...examined,
  });
};
