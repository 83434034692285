/* eslint-disable no-inner-declarations */
/* eslint-disable no-constant-condition */
/* eslint-disable prefer-const */
/* eslint-disable eqeqeq */
/* eslint-disable no-redeclare */
/* eslint-disable no-var */
/* eslint-disable @typescript-eslint/no-unused-vars */
/*
 * Szafir Web Module (Wersja wymaga bibliotek Szafir SDK w wersji 1.8.1 (Build 319) lub wyższej)
 * http://www.elektronicznypodpis.pl/oferta/narzedzia-programistyczne/
 *
 * Krajowa Izba Rozliczeniowa (C) Wszelkie prawa zastrzeżone
 * http://www.kir.pl/
 */

import jQuery from 'jquery';

function appendAsyncSzafirAppletResultPart(resultPart) {
  SZAFIR_SDK.SzafirAppletResultPartHandler(resultPart);
}

function setAsyncSzafirAppletResult(result) {
  SZAFIR_SDK.SzafirAppletResultHandler(result);
}

function setAsyncSzafirAppletError(error) {
  SZAFIR_SDK.SzafirAppletErrorHandler(error);
}

var SZAFIR_SDK = (function (_SZAIFR_SDK, $) {
  const _MODULE = { version: '2.0.6' };

  const _commands = {
      load: 'load',
      unload: 'unload',
      startComponentURI: 'startComponentURI',
      startComponent: 'startComponent',
      getTaskListURI: 'getTaskListURI',
      getTaskList: 'getTaskList',
      doTaskList: 'doTaskList',
      doTaskListNoWindow: 'doTaskListNoWindow',
      doTaskListNoWindow1Pass: 'doTaskListNoWindow1Pass',
      doTaskListNoWindow2Pass: 'doTaskListNoWindow2Pass',
      doTaskListURI: 'doTaskListURI',
      doTaskListNoWindowURI: 'doTaskListNoWindowURI',
      doTaskListNoWindow1PassURI: 'doTaskListNoWindow1PassURI',
      doTaskListNoWindow2PassURI: 'doTaskListNoWindow2PassURI',
      isCardInReader: 'isCardInReader',
      getSignatureInfo: 'getSignatureInfo',
      listCertificates: 'listCertificates',
      appletProcessCommand: 'processCommand',
      setSigningCertificate: 'setSigningCertificate',
      setTimeStampingCertificate: 'setTimeStampingCertificate',
      test: 'test',
      error: 'error',
      disconnected: 'disconnected',
    },
    _status = { ok: 'OK', error: 'ERROR' },
    _hostAppError = {
      notInstalled: 'NOT_INSTALLED',
      notUpdated: 'NOT_UPDATED',
    };

  const _extBrowserVer = { firefox_ver: 52, opera_ver: 34 };

  const _extLinks = {
    firefox_ext:
      'https://www.elektronicznypodpis.pl/download/webmodule/firefox/szafir_sdk_web-0.0.9-anfx.xpi',
    chrome_ext:
      'https://chrome.google.com/webstore/detail/podpis-elektroniczny-szaf/gjalhnomhafafofonpdihihjnbafkipc/',
    opera_ext:
      'https://addons.opera.com/pl/extensions/details/podpis-elektroniczny-szafir-sdk/',
  };

  //	FOR EDGE: _modeExtension = (/\bchrome/.test(_navUA) && /google/.test(navigator.vendor.toLowerCase())) || /\bedge/.test(_navUA),
  let _navUA = navigator.userAgent.toLowerCase(),
    _modeExtension =
      /\bchrome/.test(_navUA) &&
      !/\bedge/.test(_navUA) &&
      /google/.test(navigator.vendor.toLowerCase()),
    _Win64 =
      /\bWin64/.test(navigator.userAgent) ||
      /\bWOW64/.test(navigator.userAgent),
    _browserOpera = false,
    _browserFirefox = false,
    _modeApplet = !_modeExtension,
    _hostUpdateRequired = false,
    _appLinks;

  if (_modeExtension) {
    //szukamy Opery w wersji >= 34
    var m = _navUA.match(/\bopr\/(\d+)/);
    if (m != null) {
      if (parseInt(m[1]) >= _extBrowserVer.opera_ver) {
        _browserOpera = true;
      } else {
        _modeExtension = false; // applet dla Opery < 34
        _modeApplet = !_modeExtension;
      }
    }
  } else {
    //szukamy Firefoxa w wersji >= 52
    var m = _navUA.match(/\bfirefox\/(\d+)/);
    if (m != null) {
      _browserFirefox = true;
      if (parseInt(m[1]) >= _extBrowserVer.firefox_ver) {
        _modeExtension = true;
        _modeApplet = !_modeExtension;
      }
    }
  }

  let _SZAFIRSDK_APPLET,
    //_szafirsdk_mode = (_modeExtension ? "SZAFIR_SDK_EXT" : "SZAFIR_SDK_APPLET"),
    //_szafirsdk_state = "UNLOADED", // UNLOADED -> LOADED -> STARTED -> UNLOADED lub UNLOADED -> ERROR
    _szafirConfig = {
      document_base_url: window.location.href,
      debug: false,
      webmodule_location_url: 'szafirsdk_web/',
      sdk_location_url: 'szafir_build_directory/',
      versions_file: 'versions.xml',
      onError: undefined, // funkcja użytkownika o sygnaturze fun(error) uruchamiana podczas obsługi błędu
      onErrorNoExtension: undefined,
      onErrorNoHostApp: undefined,
      onDisconnected: function () {
        // funkcja użytkownika uruchamiana po utracie połączenia z aplikacją SzafirHost
        console.log('********** DEFAULT onDisconnected HANDLER!!! **********');
      },
      js_version: _MODULE.version,
    },
    _callbacksQueue = [],
    _busy = false,
    _szafirAppletMultipartBuffer = '',
    _fnSzafirAppletResultHandler,
    _fnSzafirAppletErrorHandler = function (error) {
      WND_postErrorMsg({ result: error });
    };

  /***** FUKNCJE PRYWATNE MODUłU *****/
  function debugLog(log) {
    if (_szafirConfig.debug === true) {
      console.log(log);
    }
  }

  function setCallbacks(processing, success, error) {
    debugLog(['CALLBACK:', processing]);
    debugLog(['BUSY:', _busy]);
    if (_busy) {
      debugLog('MOD.js : busy...!!!');
      return false;
    }
    _busy = true;
    debugLog(`MOD.js : busy TRUE przez: -> ${processing} `);
    const callbacksInfo = {
      proc: processing,
      successCallback: success,
      errorCallback: error,
    };
    _callbacksQueue.push(callbacksInfo);

    return true;
  }

  function callSuccessCallback() {
    const callbacksInfo = _callbacksQueue.shift();
    const successCallback = callbacksInfo.successCallback;
    debugLog(`MOD.js : busy FALSE przez: -> callSuccessCallback `);
    _busy = false;
    try {
      debugLog('MOD.js : success callback [ ' + callbacksInfo.proc + ' ]');
      successCallback.apply(this, arguments);
    } catch (err) {
      _szafirConfig.onError(
        'ERROR: callback dla funkcji ' +
          callbacksInfo.proc +
          ' nie został zdefiniowany!!!',
      );
      //callErrorCallback("ERROR: callback dla funkcji " + _processing + " nie został zdefiniowany!!!");
    }
  }

  function callErrorCallback(error) {
    const callbacksInfo = _callbacksQueue.shift();
    const errorCallback = callbacksInfo.errorCallback || _szafirConfig.onError;
    debugLog(`MOD.js : busy FALSE przez: -> callErrorCallback `);
    _busy = false;
    if (errorCallback instanceof Function) {
      errorCallback(error);
    } else {
      debugLog('ERROR: callback onError nie zdefiniowany!!!');
    }
  }

  function WND_postMsg(params) {
    const msg = $.extend(params, { type: 'SZAFIR_WND_MSG' });
    window.postMessage(JSON.stringify(msg), '*');
  }

  function WND_postErrorMsg(params) {
    const msg = $.extend(params, {
      type: 'SZAFIR_WND_MSG',
      status: _status.error,
    });
    window.postMessage(JSON.stringify(msg), '*');
  }

  function EXT_postMsg(params) {
    const msg = { type: 'SZAFIR_EXT_MSG', params: params };
    debugLog('EXTERNAL MESSAGE:');
    debugLog(msg);
    window.postMessage(JSON.stringify(msg), '*');
  }

  function handleWindowMsg(e) {
    // We only accept messages from ourselves
    if (e.source != window) {
      return;
    }

    const data = _parseMessageData(e.data);
    if (data && data.type === 'SZAFIR_WND_MSG') {
      if (data.status !== _status.error) {
        switch (data.command) {
          case _commands.load:
            onLoad(data);
            break;
          case _commands.disconnected:
            _szafirConfig.onDisconnected();
            break;
          //case _commands.unload: onUnload(data); break;
          default:
            callSuccessCallback(data.result);
            break;
        }
      } else {
        callErrorCallback(data.result);
      }

      //_busy = false;
    }

    function _parseMessageData(data) {
      try {
        return JSON.parse(data);
      } catch (e) {
        return data;
      }
    }
  }

  function handleWindowMessages() {
    if (window.addEventListener) {
      //window.removeEventListener("message", handleWindowMsg);
      window.addEventListener('message', handleWindowMsg);
    } else {
      //IE 8 or earlier
      //window.detachEvent("onmessage", handleWindowMsg);
      window.attachEvent('onmessage', handleWindowMsg);
    }
  }

  function onLoad(data) {
    switch (data.result) {
      case 'LOADED':
        callSuccessCallback();
        break;
      case 'NOHOSTAPP':
        var currentVersion = data.version_info.current || '';
        _hostUpdateRequired = currentVersion.match(/^VERSION/) != null;
        _appLinks = data.version_info.app_links;
        _appLinks.windows32 = _appLinks.windows.replace('.msi', '_x86.msi');
        var errorCode = _hostUpdateRequired
          ? _hostAppError.notUpdated
          : _hostAppError.notInstalled;
        var info = {
          current_version: data.version_info.current,
          required_version: data.version_info.required,
          link: _MODULE.getAppLink(),
          links: _MODULE.getAppLinks(),
        };
        onErrorNoHostApp(errorCode, info);
        break;
      case 'ERROR':
        debugLog(`MOD.js : busy FALSE przez: -> onLoad ERROR `);
        _busy = false;
        callErrorCallback(
          'Błąd inicjalizacji bibliotek Szafir SDK!\nSkontaktuj się z administratorem serwisu!',
        );
        break;
    }
  }

  function onErrorNoExtension(errorCode, info) {
    debugLog('MOD.js : ERROR -> Chrome Extension Not Installed');
    _callbacksQueue.length = 0;
    debugLog(`MOD.js : busy FALSE przez: -> onErrorNoExtension ${errorCode} `);
    _busy = false;
    if (_szafirConfig.onErrorNoExtension instanceof Function) {
      window.open(_MODULE.getExtLink(), '_blank');
      _szafirConfig.onErrorNoExtension(errorCode, info);
    } else {
      window.open(_MODULE.getExtLink(), '_blank');
    }
  }

  function onErrorNoHostApp(errorCode, info) {
    debugLog('MOD.js : ERROR -> Chrome Native Messaging Host Not Installed');
    _callbacksQueue.length = 0;
    debugLog(`MOD.js : busy FALSE przez: -> onErrorNoHostApp ${errorCode} `);
    _busy = false;
    if (_szafirConfig.onErrorNoHostApp instanceof Function) {
      window.open(info.link, '_blank');
      _szafirConfig.onErrorNoHostApp(errorCode, info);
    } else {
      window.open(info.link, '_blank');
    }
  }

  function processCommand(jsonCommand, successCallback, errorCallback) {
    if (setCallbacks(jsonCommand.command, successCallback, errorCallback)) {
      if (_modeApplet) {
        APL_processCommand(jsonCommand);
      } else {
        EXT_postMsg(jsonCommand);
      }
    }
  }

  /***** FUKNCJE PRYWATNE OBSłUGI APPLETU *****/
  function createObjParam(name, value) {
    const p = document.createElement('param');
    p.name = name;
    p.value = value;
    return p;
  }
  function APL_loadSzafirResultHandler(result) {
    WND_postMsg({
      command: _commands.load,
      status: _status.ok,
      result: 'LOADED',
    });
  }
  function APL_loadSzafir() {
    debugLog('MOD.js : APL_loadSzafir');
    _fnSzafirAppletResultHandler = APL_loadSzafirResultHandler;
    if (_SZAFIRSDK_APPLET !== undefined) {
      debugLog('SZAFIRSDK_APPLET_OBJECT został już załadowany!');
      $('#SZAFIRSDK_APPLET_OBJECT').remove();
      _SZAFIRSDK_APPLET = undefined;
    }

    const appletObj = document.createElement('object');
    appletObj.id = 'SZAFIRSDK_APPLET_OBJECT';
    appletObj.classid = 'clsid:8AD9C840-044E-11D1-B3E9-00805F499D93';
    appletObj.name = appletObj.id;
    appletObj.type = 'application/x-java-applet';
    appletObj.style.cssText =
      'margin:0; padding:0; width: 1px; height: 1px; float: left;';
    appletObj.appendChild(
      createObjParam('code', 'pl.com.kir.crypto.applet.CryptoApplet.class'),
    );
    appletObj.appendChild(
      createObjParam('codebase', _szafirConfig.sdk_location_url),
    );
    appletObj.appendChild(createObjParam('archive', 'cryptoapplet.jar'));
    appletObj.appendChild(
      createObjParam('Server-Library', _szafirConfig.sdk_location_url),
    );
    appletObj.appendChild(
      createObjParam('Versions-File-Name', _szafirConfig.versions_file),
    );
    appletObj.appendChild(
      createObjParam('JS-Version', _szafirConfig.js_version),
    );
    //appletObj.appendChild(createObjParam("java_arguments", "-Xmx32m"));
    if (_szafirConfig.document_dependencies_folder !== null) {
      appletObj.appendChild(
        createObjParam(
          'DocumentDependenciesFolder',
          _szafirConfig.document_dependencies_folder,
        ),
      );
    }
    if (_szafirConfig.series_authorization !== null) {
      appletObj.appendChild(
        createObjParam(
          'SeriesAuthorization',
          _szafirConfig.series_authorization,
        ),
      );
    }

    for (const key in _szafirConfig) {
      if (key.indexOf('szafirsdk.') == 0) {
        const value = _szafirConfig[key];
        appletObj.appendChild(createObjParam(key, value));
      }
    }

    debugLog('MOD.js : APL_loadSzafir: inserting object');
    //$("body").append(appletObj.outerHTML);
    document.body.appendChild(appletObj);
    //document.body.innerHTML += appletObj.outerHTML;
    debugLog('MOD.js : APL_loadSzafir: inserting done');

    _SZAFIRSDK_APPLET = document.SZAFIRSDK_APPLET_OBJECT;
  }

  function APL_unloadSzafir() {
    debugLog('MOD.js : APL_unloadSzafir');
    $('#SZAFIRSDK_APPLET_OBJECT').remove();
    _SZAFIRSDK_APPLET = undefined;
    WND_postMsg({
      command: _commands.unload,
      status: _status.ok,
      result: 'UNLOADED',
    });
  }

  function APL_startComponentResultHandler(result) {
    WND_postMsg({
      command: _commands.startComponent,
      status: _status.ok,
      result: 'STARTED',
    });
  }
  function APL_startComponentURI(settingsUri) {
    debugLog('MOD.js : APL_startComponentURI');
    _fnSzafirAppletResultHandler = APL_startComponentResultHandler;
    _SZAFIRSDK_APPLET.startComponentURI(settingsUri);
  }

  function APL_startComponent(settingsXml) {
    debugLog('MOD.js : APL_startComponent');
    _fnSzafirAppletResultHandler = APL_startComponentResultHandler;
    _SZAFIRSDK_APPLET.startComponent(settingsXml);
  }

  function APL_getTaskListResultHandler(taskListXml) {
    WND_postMsg({
      command: _commands.getTaskList,
      status: _status.ok,
      result: taskListXml,
    });
  }
  function APL_getTaskListURI(makeTaskListUri) {
    debugLog('MOD.js : APL_getTaskListURI');
    _fnSzafirAppletResultHandler = APL_getTaskListResultHandler;
    const taskListXml = _SZAFIRSDK_APPLET.getTaskListURI(makeTaskListUri);
  }

  function APL_getTaskList(makeTaskListXml) {
    debugLog('MOD.js : APL_getTaskList');
    _fnSzafirAppletResultHandler = APL_getTaskListResultHandler;
    const taskListXml = _SZAFIRSDK_APPLET.getTaskList(makeTaskListXml);
  }

  function APL_doTaskListResultHandler(resultXml) {
    WND_postMsg({
      command: _commands.doTaskList,
      status: _status.ok,
      result: resultXml,
    });
  }
  function APL_doTaskList(taskList) {
    debugLog('MOD.js : APL_doTaskList');
    _fnSzafirAppletResultHandler = APL_doTaskListResultHandler;
    _SZAFIRSDK_APPLET.doTaskList(taskList);
  }

  function APL_doTaskListNoWindowResultHandler(resultXml) {
    WND_postMsg({
      command: _commands.doTaskListNoWindow,
      status: _status.ok,
      result: resultXml,
    });
  }
  function APL_doTaskListNoWindow(taskList) {
    debugLog('MOD.js : APL_doTaskListNoWindow');
    _fnSzafirAppletResultHandler = APL_doTaskListNoWindowResultHandler;
    _SZAFIRSDK_APPLET.doTaskListNoWindow(taskList, false);
  }

  function APL_doTaskListNoWindow1Pass(taskList, sigPass) {
    debugLog('MOD.js : APL_doTaskListNoWindow1Pass');
    _fnSzafirAppletResultHandler = APL_doTaskListNoWindowResultHandler;
    _SZAFIRSDK_APPLET.doTaskListNoWindow(taskList, sigPass);
  }

  function APL_doTaskListNoWindow2Pass(taskList, sigPass, tssPass) {
    debugLog('MOD.js : APL_doTaskListNoWindow2Pass');
    _fnSzafirAppletResultHandler = APL_doTaskListNoWindowResultHandler;
    _SZAFIRSDK_APPLET.doTaskListNoWindow(taskList, sigPass, tssPass);
  }

  function APL_isCardInReader() {
    debugLog('MOD.js : APL_isCardInReader');
    const inReader = _SZAFIRSDK_APPLET.isCardInReader();
    WND_postMsg({
      command: _commands.isCardInReader,
      status: _status.ok,
      result: inReader,
    });
  }

  function APL_getSignatureInfoResultHandler(resultXml) {
    WND_postMsg({
      command: _commands.getSignatureInfo,
      status: _status.ok,
      result: resultXml,
    });
  }
  function APL_getSignatureInfo(uri, exportData) {
    debugLog('MOD.js : APL_getSignatureInfo');
    _fnSzafirAppletResultHandler = APL_getSignatureInfoResultHandler;
    do {
      const replace = uri.replace('\\', '/');
      if (replace == uri) {
        break;
      }
      uri = replace;
    } while (true);

    //uri = "file:///" + uri;
    _SZAFIRSDK_APPLET.getSignatureInfo(uri, exportData);
  }

  function APL_listCertificatesResultHandler(resultXml) {
    WND_postMsg({
      command: _commands.listCertificates,
      status: _status.ok,
      result: resultXml,
    });
  }
  function APL_listCertificates(type) {
    debugLog('MOD.js : APL_listCertificates');
    _fnSzafirAppletResultHandler = APL_listCertificatesResultHandler;
    _SZAFIRSDK_APPLET.listCertificates(type);
  }

  function APL_processCommandResultHandler(resultXml) {
    WND_postMsg({
      command: _commands.appletProcessCommand,
      status: _status.ok,
      result: resultXml,
    });
  }
  function APL_processCommand(jsonCommand) {
    debugLog('MOD.js : APL_processCommand : ' + JSON.stringify(jsonCommand));
    _fnSzafirAppletResultHandler = APL_processCommandResultHandler;
    _SZAFIRSDK_APPLET.processCommand(JSON.stringify(jsonCommand));
  }

  /***** FUKNCJE PUBLICZNE UŻYTKOWNIKA *****/
  _MODULE.isExtInitialized = function () {
    // detekcja rozszerzenia na podstawie wpisu wykonanego przez rozszerzenie
    const szafirsdkReady = document.body.getAttribute('data-szafirsdk-ready');
    return szafirsdkReady === 'EXT_INITIALIZED';
  };

  _MODULE.isModeApplet = function () {
    return _modeApplet;
  };

  _MODULE.isModeExtension = function () {
    return !_modeApplet;
  };

  _MODULE.isWin64 = function () {
    return _Win64;
  };

  _MODULE.isFirefox = function () {
    return _browserFirefox;
  };

  _MODULE.isChrome = function () {
    return _modeExtension && !_browserOpera;
  };

  _MODULE.isOpera = function () {
    return _browserOpera;
  };

  _MODULE.getAppLink = function () {
    return _Win64 ? _appLinks.windows : _appLinks.windows32;
  };

  _MODULE.getAppLinks = function () {
    return _appLinks;
  };

  _MODULE.getExtLink = function () {
    if (_MODULE.isFirefox()) {
      return _extLinks.firefox_ext;
    } else if (_MODULE.isChrome()) {
      return _extLinks.chrome_ext;
    } else if (_MODULE.isOpera()) {
      return _extLinks.opera_ext;
    } else {
      return 'error';
    }
  };

  _MODULE.config = function (config) {
    $.extend(_szafirConfig, config);
    if (!_szafirConfig.sdk_location_url.match(/\/$/)) {
      _szafirConfig.sdk_location_url += '/';
    }
    if (!_szafirConfig.webmodule_location_url.match(/\/$/)) {
      _szafirConfig.webmodule_location_url += '/';
    }

    handleWindowMessages();
  };

  _MODULE.loadSzafir = function (successCallback, errorCallback) {
    if (setCallbacks(_commands.load, successCallback, errorCallback)) {
      if (_modeApplet) {
        APL_loadSzafir();
      } else {
        const _timeout = 5000,
          _intervalMillis = 100;
        let _waitingTime = 0;

        function _waitForExtension() {
          if (_MODULE.isExtInitialized()) {
            debugLog('MOD.js : OK -> Chrome Extension Present');
            EXT_postMsg({ command: _commands.load, config: _szafirConfig });
          } else if (_waitingTime >= _timeout) {
            onErrorNoExtension(_hostAppError.notInstalled, {
              link: _MODULE.getExtLink(),
            });
          } else {
            _waitingTime += _intervalMillis;
            debugLog('MOD.js : OK -> Waiting for extension: ' + _waitingTime);
            setTimeout(_waitForExtension, _intervalMillis);
          }
        }
        _waitForExtension();
      }
    }
  };

  _MODULE.unloadSzafir = function (successCallback, errorCallback) {
    if (setCallbacks(_commands.unload, successCallback, errorCallback)) {
      if (_modeApplet) {
        APL_unloadSzafir();
      } else {
        EXT_postMsg({ command: _commands.unload });
      }
    }
  };

  _MODULE.startComponent = function (
    settingsXml,
    successCallback,
    errorCallback,
  ) {
    if (
      setCallbacks(_commands.startComponent, successCallback, errorCallback)
    ) {
      if (_modeApplet) {
        APL_startComponent(settingsXml);
      } else {
        EXT_postMsg({
          command: _commands.startComponent,
          settings_xml: settingsXml,
        });
      }
    }
  };

  _MODULE.startComponentURI = function (
    settingsUri,
    successCallback,
    errorCallback,
  ) {
    if (
      setCallbacks(_commands.startComponentURI, successCallback, errorCallback)
    ) {
      $.ajax({
        url: settingsUri,
        dataType: 'text',
        success: function (settings_cryptoproviders_xml) {
          if (_modeApplet) {
            APL_startComponent(settings_cryptoproviders_xml);
          } else {
            EXT_postMsg({
              command: _commands.startComponent,
              settings_xml: settings_cryptoproviders_xml,
            });
          }
        },
        error: function (jqXHR, textStatus, errorThrown) {
          WND_postErrorMsg({
            command: _commands.startComponentURI,
            result: errorThrown,
          });
        },
      });
    }
  };

  _MODULE.getTaskList = function (
    makeTaskListXml,
    successCallback,
    errorCallback,
  ) {
    if (setCallbacks(_commands.getTaskList, successCallback, errorCallback)) {
      if (_modeApplet) {
        APL_getTaskList(makeTaskListXml);
      } else {
        EXT_postMsg({
          command: _commands.getTaskList,
          maketasklist_xml: makeTaskListXml,
        });
      }
    }
  };

  _MODULE.getTaskListURI = function (
    makeTaskListUri,
    successCallback,
    errorCallback,
  ) {
    if (
      setCallbacks(_commands.getTaskListURI, successCallback, errorCallback)
    ) {
      $.ajax({
        url: makeTaskListUri,
        dataType: 'text',
        success: function (makeTaskListXml) {
          if (_modeApplet) {
            APL_getTaskList(makeTaskListXml);
          } else {
            EXT_postMsg({
              command: _commands.getTaskList,
              maketasklist_xml: makeTaskListXml,
            });
          }
        },
        error: function (jqXHR, textStatus, errorThrown) {
          WND_postErrorMsg({
            command: _commands.getTaskListURI,
            result: errorThrown,
          });
        },
      });
    }
  };

  _MODULE.doTaskList = function (taskListXml, successCallback, errorCallback) {
    if (setCallbacks(_commands.doTaskList, successCallback, errorCallback)) {
      if (_modeApplet) {
        APL_doTaskList(taskListXml);
      } else {
        EXT_postMsg({
          command: _commands.doTaskList,
          tasklist_xml: taskListXml,
        });
      }
    }
  };

  _MODULE.doTaskListURI = function (
    taskListUri,
    successCallback,
    errorCallback,
  ) {
    if (setCallbacks(_commands.doTaskListURI, successCallback, errorCallback)) {
      $.ajax({
        url: taskListUri,
        dataType: 'text',
        success: function (taskListXml) {
          if (_modeApplet) {
            APL_doTaskList(taskListXml);
          } else {
            EXT_postMsg({
              command: _commands.doTaskList,
              tasklist_xml: taskListXml,
            });
          }
        },
        error: function (jqXHR, textStatus, errorThrown) {
          WND_postErrorMsg({
            command: _commands.doTaskListURI,
            result: errorThrown,
          });
        },
      });
    }
  };

  _MODULE.doTaskListNoWindow = function (
    taskListXml,
    successCallback,
    errorCallback,
  ) {
    if (
      setCallbacks(_commands.doTaskListNoWindow, successCallback, errorCallback)
    ) {
      if (_modeApplet) {
        APL_doTaskListNoWindow(taskListXml);
      } else {
        EXT_postMsg({
          command: _commands.doTaskListNoWindow,
          tasklist_xml: taskListXml,
        });
      }
    }
  };

  _MODULE.doTaskListNoWindowURI = function (
    taskListUri,
    successCallback,
    errorCallback,
  ) {
    if (
      setCallbacks(
        _commands.doTaskListNoWindowURI,
        successCallback,
        errorCallback,
      )
    ) {
      $.ajax({
        url: taskListUri,
        dataType: 'text',
        success: function (taskListXml) {
          if (_modeApplet) {
            APL_doTaskListNoWindow(taskListXml);
          } else {
            EXT_postMsg({
              command: _commands.doTaskListNoWindow,
              tasklist_xml: taskListXml,
            });
          }
        },
        error: function (jqXHR, textStatus, errorThrown) {
          WND_postErrorMsg({
            command: _commands.doTaskListNoWindowURI,
            result: errorThrown,
          });
        },
      });
    }
  };

  _MODULE.doTaskListNoWindow1Pass = function (
    taskListXml,
    sigPass,
    successCallback,
    errorCallback,
  ) {
    if (
      setCallbacks(
        _commands.doTaskListNoWindow1Pass,
        successCallback,
        errorCallback,
      )
    ) {
      if (_modeApplet) {
        APL_doTaskListNoWindow1Pass(taskListXml, sigPass);
      } else {
        EXT_postMsg({
          command: _commands.doTaskListNoWindow,
          tasklist_xml: taskListXml,
          sig_pass: sigPass,
        });
      }
    }
  };

  _MODULE.doTaskListNoWindow1PassURI = function (
    taskListUri,
    sigPass,
    successCallback,
    errorCallback,
  ) {
    if (
      setCallbacks(
        _commands.doTaskListNoWindow1PassURI,
        successCallback,
        errorCallback,
      )
    ) {
      $.ajax({
        url: taskListUri,
        dataType: 'text',
        success: function (taskListXml) {
          if (_modeApplet) {
            APL_doTaskListNoWindow1Pass(taskListXml, sigPass);
          } else {
            EXT_postMsg({
              command: _commands.doTaskListNoWindow,
              tasklist_xml: taskListXml,
              sig_pass: sigPass,
            });
          }
        },
        error: function (jqXHR, textStatus, errorThrown) {
          WND_postErrorMsg({
            command: _commands.doTaskListNoWindow1PassURI,
            result: errorThrown,
          });
        },
      });
    }
  };

  _MODULE.doTaskListNoWindow2Pass = function (
    taskListXml,
    sigPass,
    tssPass,
    successCallback,
    errorCallback,
  ) {
    if (
      setCallbacks(
        _commands.doTaskListNoWindow2Pass,
        successCallback,
        errorCallback,
      )
    ) {
      if (_modeApplet) {
        APL_doTaskListNoWindow2Pass(taskListXml, sigPass, tssPass);
      } else {
        EXT_postMsg({
          command: _commands.doTaskListNoWindow,
          tasklist_xml: taskListXml,
          sig_pass: sigPass,
          tss_pass: tssPass,
        });
      }
    }
  };

  _MODULE.doTaskListNoWindow2PassURI = function (
    taskListUri,
    sigPass,
    tssPass,
    successCallback,
    errorCallback,
  ) {
    if (
      setCallbacks(
        _commands.doTaskListNoWindow2PassURI,
        successCallback,
        errorCallback,
      )
    ) {
      $.ajax({
        url: taskListUri,
        dataType: 'text',
        success: function (taskListXml) {
          if (_modeApplet) {
            APL_doTaskListNoWindow2Pass(taskListXml, sigPass, tssPass);
          } else {
            EXT_postMsg({
              command: _commands.doTaskListNoWindow,
              tasklist_xml: taskListXml,
              sig_pass: sigPass,
              tss_pass: tssPass,
            });
          }
        },
        error: function (jqXHR, textStatus, errorThrown) {
          WND_postErrorMsg({
            command: _commands.doTaskListNoWindow2PassURI,
            result: errorThrown,
          });
        },
      });
    }
  };

  _MODULE.isCardInReader = function (successCallback, errorCallback) {
    if (
      setCallbacks(_commands.isCardInReader, successCallback, errorCallback)
    ) {
      if (_modeApplet) {
        APL_isCardInReader();
      } else {
        EXT_postMsg({ command: _commands.isCardInReader });
      }
    }
  };

  _MODULE.getSignatureInfo = function (
    signatureUri,
    successCallback,
    errorCallback,
  ) {
    if (
      setCallbacks(_commands.getSignatureInfo, successCallback, errorCallback)
    ) {
      if (_modeApplet) {
        APL_getSignatureInfo(signatureUri, false);
      } else {
        EXT_postMsg({
          command: _commands.getSignatureInfo,
          signature_uri: signatureUri,
          export_data: false,
        });
      }
    }
  };

  _MODULE.getSignatureInfoExportData = function (
    signatureUri,
    successCallback,
    errorCallback,
  ) {
    if (
      setCallbacks(_commands.getSignatureInfo, successCallback, errorCallback)
    ) {
      if (_modeApplet) {
        APL_getSignatureInfo(signatureUri, true);
      } else {
        EXT_postMsg({
          command: _commands.getSignatureInfo,
          signature_uri: signatureUri,
          export_data: true,
        });
      }
    }
  };

  _MODULE.listCertificates = function (
    certType,
    successCallback,
    errorCallback,
  ) {
    if (
      setCallbacks(_commands.listCertificates, successCallback, errorCallback)
    ) {
      if (_modeApplet) {
        APL_listCertificates(certType);
      } else {
        EXT_postMsg({ command: _commands.listCertificates, type: certType });
      }
    }
  };

  _MODULE.test = function (testData, successCallback, errorCallback) {
    if (_modeExtension) {
      setCallbacks(_commands.test, successCallback, errorCallback);
      EXT_postMsg({ command: _commands.test, data: testData });
    }
  };

  _MODULE.setSigningCertificate = function (
    certificateXml,
    successCallback,
    errorCallback,
  ) {
    const procCommand = {
      command: _commands.setSigningCertificate,
      certificateXml: certificateXml,
    };
    processCommand(procCommand, successCallback, errorCallback);
  };

  _MODULE.setTimeStampingCertificate = function (
    certificateXml,
    successCallback,
    errorCallback,
  ) {
    const procCommand = {
      command: _commands.setTimeStampingCertificate,
      certificateXml: certificateXml,
    };
    processCommand(procCommand, successCallback, errorCallback);
  };

  /***** KIR HELPERS *****/
  _MODULE.isHostUpdateRequired = function () {
    return _hostUpdateRequired;
  };

  _MODULE.SzafirAppletResultPartHandler = function (resultPart) {
    _szafirAppletMultipartBuffer += resultPart || '';
  };

  _MODULE.SzafirAppletResultHandler = function (result) {
    if (_fnSzafirAppletResultHandler instanceof Function) {
      if (
        (result || '').length == 0 &&
        _szafirAppletMultipartBuffer.length > 0
      ) {
        result = _szafirAppletMultipartBuffer;
      }
      _szafirAppletMultipartBuffer = '';

      _fnSzafirAppletResultHandler(result);
    } else {
      debugLog('APL:ERROR -> _fnSzafirAppletResultHandler not set');
    }
    _fnSzafirAppletResultHandler = undefined;
  };

  _MODULE.SzafirAppletErrorHandler = function (error) {
    if (_fnSzafirAppletErrorHandler instanceof Function) {
      _fnSzafirAppletErrorHandler(error);
    } else {
      debugLog('APL:ERROR -> _fnSzafirAppletErrorHandler not set');
    }
  };

  return _SZAIFR_SDK || _MODULE;
})(SZAFIR_SDK, jQuery);

export { SZAFIR_SDK };
