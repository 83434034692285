import React from 'react';

export const Unauthorized: React.FC = () => {
  return (
    <div className="pb-5">
      <div className="main-banner">
        <h1 className=" mb-5">Brak dostępu</h1>
      </div>
    </div>
  );
};
