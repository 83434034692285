import React from 'react';

import { useAuth } from '../../../common/hooks/useAuth';
import { useSpdCore } from '../../../common/hooks/useSpdCore';
import { SavePin } from '../../components/SavePin/SavePin';

export const SavePinView: React.FC = () => {
  const spdApi = useSpdCore();
  const auth = useAuth();

  const onSavePin = async (pin: string) => {
    await spdApi.users.savePin(auth.userProfile?.id, pin).then(async () => {
      auth.updatePin(pin);
    });
  };
  return <SavePin onSavePin={onSavePin} />;
};
