import React, { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
/* eslint-disable import/named */
import { MultiValue } from 'react-select';

import { useSpdCore } from '../../../common/hooks/useSpdCore';
import MultiSelectSortable, {
  ReactSelectOption,
} from '../Selects/SortableSelect';
import { Selector, Option } from '../Selects/Selector';
import { SupportingType } from '../../../common/spdCore/validation/schemas';

export interface SupportingExaminationDictMultiSelectorProps {
  readOnly?: boolean;
  selectedSupportingExamDict?: ReactSelectOption[];
  onChangeMulti?: (options: MultiValue<ReactSelectOption>) => void;
  multi?: boolean;
  value?: string;
  onChange?: (option: Option) => void;
  type?: SupportingType;
}

export default (props: SupportingExaminationDictMultiSelectorProps) => {
  const spd = useSpdCore();
  const [supportingExamDicts, setSupportingExamDicts] = useState<
    ReactSelectOption[]
  >([]);
  const [ready, setReady] = useState(false);
  const [displayName, setDisplayName] = useState<string>();

  useEffect(() => {
    let mounted = true;
    spd.supportingExaminationDicts
      .getAll()
      .then(t => {
        if (mounted) {
          const result = t
            .filter(o => o.type === props.type)
            .map(
              a =>
                ({
                  value: a.id,
                  label: a.name,
                } as ReactSelectOption),
            );
          setSupportingExamDicts(result);
          setReady(true);
        }
      })
      .catch(error =>
        console.log(
          `Wystąpił problem z pobraniem rodzajów badań pomocniczych / konsultacji specjalistycznych. ${error}`,
        ),
      );

    return () => {
      mounted = false;
    };
  }, [spd.supportingExaminationDicts, props.type]);

  useEffect(() => {
    if (!props.multi) {
      if (props.value) {
        spd.supportingExaminationDicts
          .get(props.value)
          .then(suppExDict => {
            setDisplayName(suppExDict.name || '');
          })
          .catch(error =>
            console.log(
              `Wystąpił problem z pobraniem rodzaju badania pomocniczego / konsultacji specjalistycznej. ${error}`,
            ),
          );
      } else {
        setDisplayName('');
      }
    }
  }, [props.multi, props.value, spd.supportingExaminationDicts]);

  const provider = async (text: string) => {
    const values = supportingExamDicts.filter(v => {
      const name = v.label || '';
      return name.toLocaleLowerCase().includes(text.toLocaleLowerCase());
    });

    return values.map(v => {
      return {
        label: v.label || '',
        value: v.value,
      };
    });
  };

  return ready ? (
    props.multi ? (
      <MultiSelectSortable
        options={supportingExamDicts}
        defaultValue={props.selectedSupportingExamDict || []}
        onChange={options =>
          props.onChangeMulti && props.onChangeMulti(options)
        }
      />
    ) : (
      <Selector
        uniqueKey={
          'supportingExamDict-' +
          JSON.stringify(supportingExamDicts) +
          displayName
        }
        readOnly={props.readOnly}
        creatable={false}
        placeholder={'Wskaż...'}
        provider={provider}
        value={displayName}
        onChange={props.onChange}
        clearable={true}
      />
    )
  ) : (
    <Skeleton />
  );
};
