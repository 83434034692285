import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import ConfirmationButton from '../../../app/components/ConfirmationButton';

export const ExamsPackageDeleteLink = (props: {
  deleteExpectationExams: () => void;
}) => {
  return (
    <>
      <OverlayTrigger
        overlay={<Tooltip>Usuwanie oczekujących badań z paczki</Tooltip>}
      >
        <ConfirmationButton
          confirmation="Czy jesteś pewien, że chcesz usunąć oczekujące badania z tej paczki?"
          variant="outline-danger"
          onOK={props.deleteExpectationExams}
        >
          Zakończ oczekujące badania
        </ConfirmationButton>
      </OverlayTrigger>
    </>
  );
};
