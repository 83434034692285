import React from 'react';

import { GetKeyTestDto } from '../../../common/spdCore/autogenerated/spdApiClient/models/GetKeyTestDto';
import { Grid } from '../../../common/components/Grid/Grid';
import {
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';

interface KeyTestsProps {
  getData: GridGetterFunction;
  getCount: GridCountFunction;
  onClick: (id: string) => void;
  refresh?: boolean;
}

export const keyTestForGrid = (keyTest: GetKeyTestDto) => {
  return {
    key: keyTest.id,
    values: {
      'key_test.name': keyTest.name,
      'test.name': keyTest.test?.name,
    },
  };
};

export const KeyTest: React.FC<KeyTestsProps> = props => {
  const columns = [
    { header: 'Nazwa klucza', property: 'key_test.name' },
    { header: 'Test', property: 'test.name' },
  ];

  return (
    <Grid
      getData={props.getData}
      getDataCount={props.getCount}
      columns={columns}
      onRowClick={props.onClick}
      showFilter={true}
      initialOrderColumn="key_test.name"
      availablePageSizes={[10, 20, 50]}
      emptyGridMessage="Brak kluczy w systemie"
      enableMultiSelect={false}
      refresh={props.refresh}
    />
  );
};
