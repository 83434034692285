import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card';
import { Form } from 'react-bootstrap';

import ValidationAlert from '../../../app/components/ValidationAlert';
import FormRow from '../../../app/components/FormRow';
import { CreateUpdateDutyDto } from '../../../common/spdCore/autogenerated/spdApiClient/models/CreateUpdateDutyDto';
import { ChooseSigned } from '../Users/ChooseSigned';
import { Role } from '../../../common/spdCore/authorization/roles';

interface DutyEditorProps {
  id?: string;
  duty: CreateUpdateDutyDto;
  handleClose?: () => void;
  show?: boolean;
  onCompleted: (
    duty: CreateUpdateDutyDto,
    id?: string,
  ) => Promise<{
    saved: boolean;
    errors: string[];
  }>;
}

export const DutyEditor: React.FC<DutyEditorProps> = props => {
  const [duty, setDuty] = useState(props.duty);
  const [showSaveErrors, setShowSaveErrors] = useState(false);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);

  useEffect(() => {
    if (props.show) {
      setSaveErrors([]);
      setShowSaveErrors(false);
      setDuty(props.duty);
    }
  }, [props.show, props.duty, props.id]);

  const createDuty = async () => {
    props
      .onCompleted(duty, props.id)
      .then(saveStatus => {
        if (!saveStatus.saved) {
          setSaveErrors(saveStatus.errors);
          setShowSaveErrors(true);
          return;
        }
      })
      .catch(error => {
        setSaveErrors(error);
        setShowSaveErrors(true);
      });
  };

  const propertyChange = (obj: Partial<CreateUpdateDutyDto>) => {
    setDuty({ ...duty, ...obj });
  };

  return (
    <>
      <Modal
        onHide={props.handleClose}
        show={props.show}
        centered={true}
        keyboard={true}
        backdrop="static"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {props.id
              ? 'Edycja grupy dyżurującej'
              : 'Dodawanie nowych grup dyżurujących'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card className="mb-2">
            <Card.Header>Grupa dyżurująca</Card.Header>
            <Card.Body>
              <Form className="d-grid gap-3">
                <FormRow controlId="name" label="Nazwa">
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={duty?.name || ''}
                    onChange={e => propertyChange({ name: e.target.value })}
                  />
                </FormRow>
                <FormRow controlId="psychologist" label="Psycholog">
                  <ChooseSigned
                    userUID={duty?.psychologistUID}
                    handleOnChange={user =>
                      propertyChange({
                        psychologistUID: user?.uid,
                      })
                    }
                    roles={[
                      Role.PsychologistAssessor,
                      Role.PsychologistSpecialist,
                    ]}
                    withoutDefaultValue={true}
                  />
                </FormRow>
                <FormRow controlId="doctor" label="Lekarz">
                  <ChooseSigned
                    userUID={duty?.doctorUID}
                    handleOnChange={user =>
                      propertyChange({
                        doctorUID: user?.uid,
                      })
                    }
                    roles={[Role.MedicalAssessor, Role.MedicalSpecialist]}
                    withoutDefaultValue={true}
                  />
                </FormRow>
              </Form>
            </Card.Body>
          </Card>
          <ValidationAlert
            show={showSaveErrors}
            errors={saveErrors}
            className="mt-3"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={props.handleClose}>
            Zamknij
          </Button>
          <Button variant="outline-primary" onClick={createDuty}>
            Zapisz
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
