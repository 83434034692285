export const copyToClipboard = async (text: string) => {
  try {
    if ('clipboard' in navigator) {
      await navigator.clipboard.writeText(text || '');
    } else {
      throw new Error('Clipboard API not available');
    }
  } catch (error) {
    console.error('Failed to copy text to clipboard:', error);
  }
};
