import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Card, Form, Tab } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import { FormRow210 } from '../../../app/components/FormRow';
import Tabs from '../../../app/components/Tabs';
import { useSpdCore } from '../../../common/hooks/useSpdCore';
import {
  GetInterpretationNormDto,
  GetNormDto,
  GetPositionNormDto,
} from '../../../common/spdCore/autogenerated/spdApiClient';
import { InterpretationNorms } from '../../components/Norms/InterpretationNorms';
import { PositionNorms } from '../../components/Norms/PositionNorms';

type ParamTypes = {
  id: string;
};

export const NormView: React.FC = () => {
  const spd = useSpdCore();
  const { id } = useParams<ParamTypes>();
  const mountedRef = useRef(false);
  const [norm, setNorm] = useState<GetNormDto>();
  const [positionNorms, setPositionNorms] = useState<GetPositionNormDto[]>([]);
  const [interpretationNorms, setInterpretationNorms] = useState<
    GetInterpretationNormDto[]
  >([]);

  const safeSet = (set: () => void) => {
    if (mountedRef.current) {
      set();
    }
  };

  const refresh = useCallback(() => {
    if (!mountedRef.current || !id) {
      return;
    }
    spd.positionNorms
      .getByNorm(id)
      .then(tNorm => {
        safeSet(() => setPositionNorms(tNorm));
      })
      .catch(error => {
        console.error('Wystąpił problem z pobraniem pozycji normy:', error);
      });
    spd.interpretationNorms
      .getByNorm(id)
      .then(pNorm => {
        safeSet(() => setInterpretationNorms(pNorm));
      })
      .catch(error => {
        console.error(
          'Wystąpił problem z pobraniem interpretacji normy:',
          error,
        );
      });
  }, [spd, id]);

  useEffect(() => {
    mountedRef.current = true;
    refresh();
    return () => {
      mountedRef.current = false;
    };
  }, [id, spd.positionNorms, refresh]);

  useEffect(() => {
    if (id) {
      spd.norms
        .get(id)
        .then(tNorm => setNorm(tNorm))
        .catch(error =>
          console.log(`Wystąpił problem z pobraniem normy. ${error}`),
        );
    }
  }, [spd.norms, id]);

  return (
    <Card>
      <Card.Header>Norma</Card.Header>
      <Card.Body>
        <Form className="d-grid mb-3 gap-3">
          <FormRow210 controlId="examined" label="Nazwa normy">
            <Form.Control
              type="text"
              defaultValue={`${norm?.name || ''}`}
              readOnly
            />
          </FormRow210>
          <FormRow210 controlId="examined" label="Grupa norm">
            <Form.Control
              type="text"
              defaultValue={`${norm?.group || ''}`}
              readOnly
            />
          </FormRow210>
          <FormRow210 controlId="examined" label="Prawdopodobieństwo">
            <Form.Control
              type="text"
              defaultValue={`${
                norm?.probability ? norm?.probability + '%' : ''
              }`}
              readOnly
            />
          </FormRow210>
          <FormRow210 controlId="examined" label="Liczba przebadanych osób">
            <Form.Control
              type="text"
              defaultValue={`${norm?.examinedCount || ''}`}
              readOnly
            />
          </FormRow210>
          <FormRow210 controlId="examined" label="Opis">
            <Form.Control
              as="textarea"
              rows={2}
              defaultValue={`${norm?.description || ''}`}
              readOnly
            />
          </FormRow210>
          <Card>
            <Tabs
              defaultActiveKey="positionsNorm"
              id="positionsNorm"
              className="mb-3"
            >
              <Tab
                eventKey="positionsNorm"
                title="Pozycje normy"
                className="m-3"
              >
                <PositionNorms data={positionNorms} />
              </Tab>
              <Tab
                eventKey="interpretationsNorm"
                title="Interpretacja normy"
                className="m-3"
              >
                <InterpretationNorms data={interpretationNorms} />
              </Tab>
            </Tabs>
          </Card>
        </Form>
      </Card.Body>
    </Card>
  );
};
