//import saveAs from 'file-saver';

import { testForGrid } from '../../../spd/components/Tests/Tests';
import { DefaultApi } from '../autogenerated/spdApiClient/apis/DefaultApi';
import { GetTestDto } from '../autogenerated/spdApiClient/models/GetTestDto';
import {
  CreateUpdateTestDto,
  CreateUpdateTestDtoFromJSON,
} from '../autogenerated/spdApiClient';
import { validateAgainst } from '../validation/validateAgainst';
import { TestSchema } from '../validation/schemas';
import { GridRecord } from '../../components/Grid/GridDataTypes';

export type TestEntity = GetTestDto;

export interface TestContextInterface {
  getTest: (id: string) => Promise<TestEntity>;
  getAllByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
  ) => Promise<GridRecord[]>;
  getAllCount: (filterText?: string) => Promise<number>;
  getAll: () => Promise<GetTestDto[]>;
  getIdsByBattery: (id: string) => Promise<string[]>;
  getByIdentifier: (identifier: string) => Promise<TestEntity>;
  update: (id: string, dto: CreateUpdateTestDto) => Promise<void>;
  create: (dto: CreateUpdateTestDto) => Promise<GetTestDto>;
}

export const TestContext = (api: DefaultApi) => {
  return {
    getTest: async (id: string) => {
      return await api.testControllerGet(id);
    },

    getAllByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
    ) => {
      const data = await api.testControllerGetAllByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
      );
      return data.map(testForGrid);
    },

    getAllCount: (filterText?: string) =>
      api.testControllerGetAllCount(filterText || ''),

    getAll: () => api.testControllerGetAll(),

    getIdsByBattery: (id: string) => api.testControllerGetByBattery(id),

    getByIdentifier: (identifier: string) =>
      api.testControllerGetByIdentifier(identifier),

    update: (id: string, dto: CreateUpdateTestDto) =>
      api.testControllerUpdate(id, dto),

    create: (dto: CreateUpdateTestDto) => api.testControllerCreate(dto),
  };
};

export const validate = (test: CreateUpdateTestDto) => {
  //export const validate = (test: CreateUpdateTestDto, id?: string) => {
  // if (!id) {
  //   return validateAgainst(TestSchemaInsert, test);
  // } else {
  return validateAgainst(TestSchema, test);
  //}
};

export const testData = (test?: GetTestDto): CreateUpdateTestDto => {
  return CreateUpdateTestDtoFromJSON({
    ...test,
  });
};
