/* tslint:disable */
/* eslint-disable */
/**
 * SPD
 * Opis api SPD
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUpdateInterpretationNormDto
 */
export interface CreateUpdateInterpretationNormDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateInterpretationNormDto
     */
    normid?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CreateUpdateInterpretationNormDto
     */
    rangeFrom?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateUpdateInterpretationNormDto
     */
    rangeTo?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateUpdateInterpretationNormDto
     */
    variation?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateInterpretationNormDto
     */
    result?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateInterpretationNormDto
     */
    description?: string | null;
}

export function CreateUpdateInterpretationNormDtoFromJSON(json: any): CreateUpdateInterpretationNormDto {
    return CreateUpdateInterpretationNormDtoFromJSONTyped(json, false);
}

export function CreateUpdateInterpretationNormDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateInterpretationNormDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'normid': !exists(json, 'normid') ? undefined : json['normid'],
        'rangeFrom': !exists(json, 'rangeFrom') ? undefined : json['rangeFrom'],
        'rangeTo': !exists(json, 'rangeTo') ? undefined : json['rangeTo'],
        'variation': !exists(json, 'variation') ? undefined : json['variation'],
        'result': !exists(json, 'result') ? undefined : json['result'],
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function CreateUpdateInterpretationNormDtoToJSON(value?: CreateUpdateInterpretationNormDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'normid': value.normid,
        'rangeFrom': value.rangeFrom,
        'rangeTo': value.rangeTo,
        'variation': value.variation,
        'result': value.result,
        'description': value.description,
    };
}


