import React, { useState } from 'react';
import { Button } from 'react-bootstrap';

import {
  CreateUpdateSupportingExaminationDto,
  CreateUpdateSupportingExaminationDtoTypeEnum,
  GetSupportingExaminationDto,
  GetSupportingExaminationDtoTypeEnum,
} from '../../../common/spdCore/autogenerated/spdApiClient';
import { responseErrors } from '../../../common/spdCore/validation/responseErrors';
import { SupportingExamination } from '../../components/SupportingExamination/SupportingExamination';
import { SupportingExaminationEditor } from '../../components/SupportingExamination/SupportingExaminationEditor';
import { supportData } from '../../../common/spdCore/contexts/SupportingExaminationContext';
import { SupportingType } from '../../../common/spdCore/validation/schemas';

interface SupportingExaminationMemoProps {
  cuSupports: GetSupportingExaminationDto[] | undefined;
  onAdd: (el: GetSupportingExaminationDto[]) => void;
}

export const SupportingExaminationMemoView: React.FC<
  SupportingExaminationMemoProps
> = props => {
  const [show, setShow] = useState(false);
  const [support, setSupport] = useState<GetSupportingExaminationDto>();
  const [supports, setSupports] = useState<GetSupportingExaminationDto[]>(
    props.cuSupports || [],
  );

  const handleAdd = () => {
    setShow(true);
    setSupport(undefined);
  };

  const addEditSupport = async (
    supportCu: CreateUpdateSupportingExaminationDto,
    id?: string,
  ) => {
    supportCu.type = CreateUpdateSupportingExaminationDtoTypeEnum.HelpExam;

    try {
      const pom = supports;
      if (id) {
        pom[+id] = {
          ...supportCu,
          ...{ id: id },
        } as unknown as GetSupportingExaminationDto;
      } else {
        pom.push({
          ...supportCu,
          ...{ id: supports.length.toString() },
        } as unknown as GetSupportingExaminationDto);
      }
      props.onAdd(pom);
      setSupports(pom);
      setShow(false);

      return {
        saved: true,
        errors: [],
      };
    } catch (response) {
      return {
        saved: false,
        errors: await responseErrors(response as Response),
      };
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleClick = async (id: string) => {
    const getSupport = supports[+id];
    setSupport(getSupport);
    setShow(true);
  };

  return (
    <>
      <SupportingExamination
        data={supports.filter(
          s =>
            (s.type as unknown as SupportingType) === SupportingType.helpExam,
        )}
        onRowClick={handleClick}
        type={SupportingType.helpExam}
      />
      <Button variant="outline-primary" onClick={handleAdd}>
        {'Dodaj badanie'}
      </Button>
      <SupportingExaminationEditor
        id={support?.id}
        support={supportData(
          supports.filter(
            o =>
              o.type ===
              (SupportingType.helpExam as unknown as GetSupportingExaminationDtoTypeEnum),
          ).length + 1,
          undefined,
          support,
        )}
        onCompleted={addEditSupport}
        show={show}
        handleClose={handleClose}
        type={SupportingType.helpExam}
      />
    </>
  );
};
