import React from 'react';
import { Button } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

import { copyToClipboard } from '../../../common/copyToClipboard';
import { TestGivingProps } from '../TestGiving/utilsTestGiving';
import momencik from '../../../common/momencik';

export const ExtensionSummary: React.FC<{
  values: TestGivingProps;
  examined?: string;
  tests?: string;
}> = ({ values, examined, tests }) => {
  const onClickExtensionHandle = async () => {
    await copyToClipboard(`
    ${values.company?.name || ''} - piny z dnia ${momencik(
      values.examPackage?.registrationDate,
    )}

    Liczba utworzonych badań: ${values.countOfExams}
    
    Badany: ${examined || '-'}
    
    Zlecone testy: ${tests || ''}
    
    Opis: ${values.description || ''}
    
    PIN: ${values.pin}`);
  };

  return (
    <Button onClick={onClickExtensionHandle} variant="outline-secondary">
      Tekst wiadomości <Icon.ClipboardPlus />
    </Button>
  );
};
