export type IndicatorXls = {
  nazwa: string;
  nazwa_klucza_testu: string;
  identyfikator_testu: string;
  typ_wskaznika: string;
};

export const validateIndicators = (indicators: IndicatorXls[]) => {
  indicators.shift();
  const errors: string[] = [];
  if (!indicators[0].nazwa) {
    errors.push('Brak wypełnionego pola "nazwa"');
  }
  if (!indicators[0].nazwa_klucza_testu) {
    errors.push('Brak wypełnionego pola "nazwa_klucza_testu"');
  }
  if (!indicators[0].identyfikator_testu) {
    errors.push('Brak wypełnionego pola "identyfikator_testu"');
  }
  if (!indicators[0].typ_wskaznika) {
    errors.push('Brak wypełnionego pola "typ_wskaznika"');
  }
  if (errors.length > 0) {
    return {
      ok: false,
      errors: errors,
    };
  }
  return {
    ok: true,
    errors: [],
  };
};
