/*eslint max-lines-per-function: ["error", 200]*/
import React, { useEffect, useState } from 'react';
import { Button, Card, Tab } from 'react-bootstrap';
import { forEach } from 'lodash';

import Tabs from '../../../app/components/Tabs';
import { useSpdCore } from '../../../common/hooks/useSpdCore';
import {
  CreateUpdateBatteryDto,
  GetBatteryDto,
} from '../../../common/spdCore/autogenerated/spdApiClient';
import {
  batteryData,
  validate,
} from '../../../common/spdCore/contexts/BatteryContext';
import { responseErrors } from '../../../common/spdCore/validation/responseErrors';
import { Battery } from '../../components/Battery/Battery';
import { BatteryEditor } from '../../components/Battery/BatteryEditor';
import { ArticleMode } from '../../../common/spdCore/validation/schemas';
import { ReactSelectOption } from '../../components/Selects/SortableSelect';

export const BatteriesView: React.FC = () => {
  const spd = useSpdCore();

  const [show, setShow] = useState(false);
  const [battery, setBattery] = useState<GetBatteryDto>();
  const [allTests, setAllTests] = useState<ReactSelectOption[]>([]);
  const [allArticlesPsychological, setAllArticlesPsychological] = useState<
    ReactSelectOption[]
  >([]);
  const [allArticlesMedical, setAllArticlesMedical] = useState<
    ReactSelectOption[]
  >([]);
  const [articlesPsychological, setArticlesPsychological] = useState<
    ReactSelectOption[]
  >([]);
  const [articlesMedical, setArticlesMedical] = useState<ReactSelectOption[]>(
    [],
  );
  const [tests, setTests] = useState<ReactSelectOption[]>([]);

  const [refresh, setRefresh] = useState<boolean>(false);

  useEffect(() => {
    let mounted = true;
    spd.tests
      .getAll()
      .then(t => {
        if (mounted) {
          const result = t.map(
            a =>
              ({
                value: a.id,
                label: a.name,
              } as ReactSelectOption),
          );
          setAllTests(result);
        }
      })
      .catch(error =>
        console.log(`Wystąpił problem z pobraniem testów. ${error}`),
      );

    return () => {
      mounted = false;
    };
  }, [spd.tests]);

  useEffect(() => {
    let mounted = true;
    spd.articles
      .getAll()
      .then(t => {
        if (mounted) {
          let result = t
            .filter(
              o =>
                (o.mode as unknown as ArticleMode) === ArticleMode.Psychologist,
            )
            .map(
              a =>
                ({
                  value: a.id,
                  label: a.description,
                } as ReactSelectOption),
            );
          setAllArticlesPsychological(result);
          result = t
            .filter(
              o => (o.mode as unknown as ArticleMode) === ArticleMode.Doctor,
            )
            .map(
              a =>
                ({
                  value: a.id,
                  label: a.description,
                } as ReactSelectOption),
            );
          setAllArticlesMedical(result);
        }
      })
      .catch(error =>
        console.log(`Wystąpił problem z pobraniem podstaw prawnych. ${error}`),
      );

    return () => {
      mounted = false;
    };
  }, [spd.articles]);

  const handleAdd = () => {
    setShow(true);
    setBattery(undefined);
    setTests([]);
    setArticlesMedical([]);
    setArticlesPsychological([]);
  };

  const addEditBattery = async (
    batteryCU: CreateUpdateBatteryDto,
    id?: string,
  ) => {
    const status = await validate(batteryCU);
    if (status.valid) {
      try {
        if (id) {
          await spd.batteries.update(id, batteryCU);
        } else {
          await spd.batteries.create(batteryCU);
        }
        setShow(false);
        setRefresh(!refresh);
        return {
          saved: true,
          errors: [],
        };
      } catch (response) {
        return {
          saved: false,
          errors: await responseErrors(response as Response),
        };
      }
    } else {
      return {
        saved: false,
        errors: status.errors,
      };
    }
  };

  const handleClose = () => {
    setShow(false);
    setRefresh(!refresh);
  };

  const handleClick = async (id: string) => {
    const getBattery = await spd.batteries.getBattery(id);
    const tests: ReactSelectOption[] = [];
    forEach(getBattery.tests, value => {
      tests.push({
        value: value,
        label: allTests.find(o => o.value === value)?.label || '',
      });
    });
    setBattery(getBattery);
    setTests(tests);
    const articlesPsychological = allArticlesPsychological.filter(item =>
      getBattery.articlePsychologistsIds?.includes(item.value),
    );
    setArticlesPsychological(articlesPsychological);
    const articlesMedical = allArticlesMedical.filter(item =>
      getBattery.articleDoctorsIds?.includes(item.value),
    );
    setArticlesMedical(articlesMedical);
    setShow(true);
  };

  return (
    <Card>
      <Tabs defaultActiveKey="search" id="batteries" className="mb-3">
        <Tab eventKey="search" title="Baterie testów" className="m-3">
          <Battery
            getData={spd.batteries.getAllByPortion}
            getCount={spd.batteries.getAllCount}
            onRowClick={handleClick}
            refresh={refresh}
          />
          <Button variant="outline-primary" onClick={handleAdd}>
            Nowa bateria testów
          </Button>
          <BatteryEditor
            id={battery?.id}
            battery={batteryData(battery)}
            onCompleted={addEditBattery}
            show={show}
            handleClose={handleClose}
            allTests={allTests}
            selectedTests={tests}
            allArticlesPsychological={allArticlesPsychological}
            allArticlesMedical={allArticlesMedical}
            articlesPsychological={articlesPsychological}
            articlesMedical={articlesMedical}
          />
        </Tab>
      </Tabs>
    </Card>
  );
};
