import React, { useState } from 'react';
import { Button, Card, Tab } from 'react-bootstrap';

import Tabs from '../../../app/components/Tabs';
import { useSpdCore } from '../../../common/hooks/useSpdCore';
import { DutySearch } from '../../components/Duty/DutySearch';
import { GetDutyDto } from '../../../common/spdCore/autogenerated/spdApiClient/models/GetDutyDto';
import { responseErrors } from '../../../common/spdCore/validation/responseErrors';
import { DutyEditor } from '../../components/Duty/DutyEditor';
import {
  dutyData,
  validate,
} from '../../../common/spdCore/contexts/DutyContext';
import { CreateUpdateDutyDto } from '../../../common/spdCore/autogenerated/spdApiClient/models/CreateUpdateDutyDto';

export const DutiesView: React.FC = () => {
  const spd = useSpdCore();
  const [show, setShow] = useState(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [duty, setDuty] = useState<GetDutyDto>();

  const handleAdd = () => {
    setShow(true);
    setDuty(undefined);
  };

  const handleClose = () => {
    setShow(false);
    setRefresh(!refresh);
  };

  const addEditDuty = async (dutyCu: CreateUpdateDutyDto, id?: string) => {
    const status = await validate(dutyCu);
    if (status.valid) {
      try {
        if (id) {
          await spd.duties.update(id, dutyCu);
        } else {
          await spd.duties.create(dutyCu);
        }
        setShow(false);
        setRefresh(!refresh);
        return {
          saved: true,
          errors: [],
        };
      } catch (response) {
        return {
          saved: false,
          errors: await responseErrors(response as Response),
        };
      }
    } else {
      return {
        saved: false,
        errors: status.errors,
      };
    }
  };

  const onRowClick = async (id: string) => {
    const getHF = await spd.duties.get(id);
    setDuty(getHF);
    setShow(true);
  };

  return (
    <Card>
      <Tabs defaultActiveKey="search" id="duties" className="mb-3">
        <Tab eventKey="search" title="Dyżury" className="m-3">
          <DutySearch
            getData={spd.duties.getAllByPortion}
            getCount={spd.duties.getAllCount}
            refresh={refresh}
            onRowClick={onRowClick}
          />
          <Button variant="outline-primary" onClick={handleAdd}>
            Nowa grupa dyżurująca
          </Button>
          <DutyEditor
            id={duty?.id}
            duty={dutyData(duty)}
            onCompleted={addEditDuty}
            show={show}
            handleClose={handleClose}
          />
        </Tab>
      </Tabs>
    </Card>
  );
};
