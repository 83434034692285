import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

import { ExaminedList } from './ExaminedList';

interface ExaminedSelectFromModalProps {
  examinedDeleteId?: string;
  setExaminedDeleteId: (id: string) => void;
}

export const ExaminedSelectFromModal: React.FC<
  ExaminedSelectFromModalProps
> = props => {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  const onRowClick = (examinedId: string) => {
    props.setExaminedDeleteId(examinedId);

    setShow(false);
  };

  return (
    <>
      <div>
        <Button
          onClick={() => setShow(true)}
          variant="secondary"
          className="w-100"
        >
          Wybierz badanego do sklejenia
        </Button>
      </div>
      {show && (
        <Modal
          show={show}
          onHide={handleClose}
          centered
          keyboard={true}
          backdrop="static"
          backdropClassName="modal-on-modal-backdrop"
          className="modal-on-modal"
          size="xl"
        >
          <Modal.Header closeButton>
            <Modal.Title>Wybór badanego</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ExaminedList
              onRowClick={onRowClick}
              enableSingleSelect={true}
              keyToSelect={props.examinedDeleteId}
              setKeyToSelect={props.setExaminedDeleteId}
              withoutNewExamined={true}
            />
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};
