/* tslint:disable */
/* eslint-disable */
/**
 * SPD
 * Opis api SPD
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetExamDto,
    GetExamDtoFromJSON,
    GetExamDtoFromJSONTyped,
    GetExamDtoToJSON,
    GetExaminedDto,
    GetExaminedDtoFromJSON,
    GetExaminedDtoFromJSONTyped,
    GetExaminedDtoToJSON,
    GetTestDto,
    GetTestDtoFromJSON,
    GetTestDtoFromJSONTyped,
    GetTestDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetAttachmentDto
 */
export interface GetAttachmentDto {
    /**
     * 
     * @type {string}
     * @memberof GetAttachmentDto
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof GetAttachmentDto
     */
    date?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof GetAttachmentDto
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAttachmentDto
     */
    name?: string | null;
    /**
     * 
     * @type {GetExaminedDto}
     * @memberof GetAttachmentDto
     */
    examined?: GetExaminedDto | null;
    /**
     * 
     * @type {GetExamDto}
     * @memberof GetAttachmentDto
     */
    exam?: GetExamDto | null;
    /**
     * 
     * @type {GetTestDto}
     * @memberof GetAttachmentDto
     */
    test?: GetTestDto | null;
    /**
     * 
     * @type {string}
     * @memberof GetAttachmentDto
     */
    type?: GetAttachmentDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof GetAttachmentDto
     */
    scan?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAttachmentDto
     */
    taskNumber?: string | null;
}

export function GetAttachmentDtoFromJSON(json: any): GetAttachmentDto {
    return GetAttachmentDtoFromJSONTyped(json, false);
}

export function GetAttachmentDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAttachmentDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'date': !exists(json, 'date') ? undefined : (json['date'] === null ? null : new Date(json['date'])),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'examined': !exists(json, 'examined') ? undefined : GetExaminedDtoFromJSON(json['examined']),
        'exam': !exists(json, 'exam') ? undefined : GetExamDtoFromJSON(json['exam']),
        'test': !exists(json, 'test') ? undefined : GetTestDtoFromJSON(json['test']),
        'type': !exists(json, 'type') ? undefined : json['type'],
        'scan': !exists(json, 'scan') ? undefined : json['scan'],
        'taskNumber': !exists(json, 'taskNumber') ? undefined : json['taskNumber'],
    };
}

export function GetAttachmentDtoToJSON(value?: GetAttachmentDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'date': value.date === undefined ? undefined : (value.date === null ? null : value.date.toISOString()),
        'description': value.description,
        'name': value.name,
        'examined': GetExaminedDtoToJSON(value.examined),
        'exam': GetExamDtoToJSON(value.exam),
        'test': GetTestDtoToJSON(value.test),
        'type': value.type,
        'scan': value.scan,
        'taskNumber': value.taskNumber,
    };
}

/**
* @export
* @enum {string}
*/
export enum GetAttachmentDtoTypeEnum {
    BadanieLaboratoryjne = 'Badanie laboratoryjne',
    Skierowanie = 'Skierowanie',
    Orzeczenie = 'Orzeczenie',
    IdentyfikacjaBadanego = 'Identyfikacja badanego'
}


