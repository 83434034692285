import React from 'react';
import { Button, ButtonGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

import { GivingExamMode } from '../../../common/spdCore/validation/schemas';

export const ExamPreviewLink = (props: { examId: string }) => {
  return (
    <>
      <ButtonGroup aria-label="TestPlay">
        <OverlayTrigger
          overlay={<Tooltip>Obejrzyj oznaczone odpowiedzi </Tooltip>}
        >
          <Button
            onClick={e => {
              e.stopPropagation();
              window.open(
                `${process.env.REACT_APP_TEST_APP_URL}/?id=${props.examId}&mode=${GivingExamMode.PsychologistFast}`,
                'blank',
              );
            }}
            variant="outline-primary"
          >
            <Icon.SkipForwardFill />
          </Button>
        </OverlayTrigger>
        <OverlayTrigger
          overlay={
            <Tooltip>Obejrzyj badanie wypełnione przez badanego</Tooltip>
          }
        >
          <Button
            onClick={e => {
              e.stopPropagation();
              window.open(
                `${process.env.REACT_APP_TEST_APP_URL}/?id=${props.examId}&mode=${GivingExamMode.PsychologistFull}`,
                'blank',
              );
            }}
            variant="outline-primary"
          >
            <Icon.SkipEndFill />
          </Button>
        </OverlayTrigger>
      </ButtonGroup>
    </>
  );
};
