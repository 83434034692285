import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

import { useSpdCore } from '../../../common/hooks/useSpdCore';

export const AttachmentPreviewLink = (props: {
  attachmentId: string;
  attachmentName: string;
  scan: string | null | undefined;
}) => {
  const spd = useSpdCore();

  const handleClick = () => {
    if (props.scan) {
      const newWindow = window.open('', '_blank');
      if (newWindow) {
        newWindow.document.write(`<img src="${props.scan}" alt="Preview" />`);
      }
    } else {
      spd.attachments.saveFileInBrowser(
        props.attachmentId,
        props.attachmentName,
        'download',
      );
    }
  };

  return (
    <>
      <OverlayTrigger overlay={<Tooltip>Podgląd</Tooltip>}>
        <Button
          onClick={e => {
            e.stopPropagation();
            handleClick();
          }}
          variant="btn-info"
        >
          <Icon.Eye />
        </Button>
      </OverlayTrigger>
    </>
  );
};
