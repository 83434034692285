import React from 'react';

import { Grid } from '../../../common/components/Grid/Grid';
import { GetKeyValueDto } from '../../../common/spdCore/autogenerated/spdApiClient';

interface KeyValuesProps {
  data?: GetKeyValueDto[];
}

export const keyValuesForGrid = (keyValues: GetKeyValueDto) => {
  return {
    key: keyValues.id,
    values: {
      'key_value.taskNumber': keyValues.taskNumber,
      'key_value.answer': keyValues.answer,
      'key_value.weight': keyValues.weight,
    },
  };
};

export const KeyValues: React.FC<KeyValuesProps> = props => {
  const columns = [
    { header: 'Numer zadania', property: 'key_value.taskNumber' },
    { header: 'Odpowiedź', property: 'key_value.answer' },
    { header: 'Waga', property: 'key_value.weight' },
  ];

  return (
    <Grid
      data={props.data && props.data.map(d => keyValuesForGrid(d))}
      columns={columns}
      showFilter={false}
      initialOrderColumn="key_value.taskNumber"
      availablePageSizes={[10, 20, 50]}
      emptyGridMessage="Brak wartości klucza w systemie"
      enableMultiSelect={false}
    />
  );
};
