/* eslint-disable max-lines-per-function */
/* eslint-disable complexity */
import React, { useEffect } from 'react';
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ChooseExamined } from '../../components/TestGiving/ChooseExamined';
import { CommisionningResearch } from '../../components/TestGiving/CommisionningResearch';
import { ChooseTest } from '../../components/TestGiving/ChooseTest';
import { TestGivingProps } from '../../components/TestGiving/utilsTestGiving';
import { AddDescription } from '../../components/TestGiving/AddDescription';
import { ChooseCountOfExams } from '../../components/TestGiving/ChooseCountOfExams';
import { ChooseBattery } from '../../components/TestGiving/ChooseBattery';
import { AddStudent } from '../../components/TestGiving/AddStudent';
import { AddEmployer } from '../../components/TestGiving/AddEmployer';
import { AddSupportingExamination } from '../../components/TestGiving/AddSupportingExamination';
import { GetSupportingExaminationDto } from '../../../common/spdCore/autogenerated/spdApiClient';
import { ChooseCompany } from '../../components/TestGiving/ChooseCompany';
import { AddSport } from '../../components/TestGiving/AddSport';
import { useLocation } from '../../../common/hooks/useLocation';
import { ExaminationType } from '../../../common/spdCore/validation/schemas';

interface Props {
  modeOnlyOne: boolean;
  formData: TestGivingProps;
  setFormData: (formData: TestGivingProps) => void;
  onSave: (props: TestGivingProps) => void;
}

export type TabLocationState = {
  step?: number;
};

export const TestGiving: React.FC<Props> = props => {
  const [formData, setFormData] = useState<TestGivingProps>({
    examinedKey: props.formData.examinedKey,
    testsKeys: props.formData.testsKeys,
    countOfExams: props.formData.countOfExams,
    examsKeys: props.formData.examsKeys,
    batteryKey: props.formData.batteryKey,
    pin: '',
  });
  const location = useLocation<TabLocationState>();
  const navigate = useNavigate();
  const [step, setStep] = useState(1);

  useEffect(() => {
    setFormData(props.formData);
  }, [props.formData]);

  const nextStep = () => {
    navigate(location.pathname, {
      state: {
        step: step + 1 || 1,
      },
    });
    setStep(step + 1);
  };

  const prevStep = () => {
    navigate(location.pathname, {
      state: {
        step: step - 1 || 1,
      },
    });
    setStep(step - 1);
  };

  const handleInputData =
    (input: string) => (s: string | boolean | Date | undefined) => {
      props.setFormData({
        ...formData,
        [input]: s,
      });
      setFormData({
        ...formData,
        [input]: s,
      });
    };

  const handleInputObjData = (obj: Partial<TestGivingProps>) => {
    props.setFormData({
      ...formData,
      ...obj,
    });
    setFormData({
      ...formData,
      ...obj,
    });
  };

  const handleInputTableData =
    (input: string) =>
    (s: string[] | GetSupportingExaminationDto[] | boolean) => {
      props.setFormData({
        ...formData,
        [input]: s,
      });
      setFormData({
        ...formData,
        [input]: s,
      });
    };

  const handleOnSave = () => {
    if (!formData.pin) {
      props.onSave(formData);
    }
  };

  switch (step) {
    case 1:
      return (
        <div>
          <Container>
            <Row>
              <Col>
                {props.modeOnlyOne && (
                  <ChooseExamined
                    nextStep={nextStep}
                    handleFormData={handleInputData}
                    values={formData}
                  />
                )}
                {!props.modeOnlyOne && (
                  <ChooseCountOfExams
                    nextStep={nextStep}
                    handleFormData={handleInputData}
                    values={formData}
                  />
                )}
              </Col>
            </Row>
          </Container>
        </div>
      );
    case 2:
      return (
        <div>
          <Container>
            <Row>
              <Col>
                <ChooseCompany
                  nextStep={nextStep}
                  prevStep={prevStep}
                  handleFormData={handleInputTableData}
                  values={formData}
                />
              </Col>
            </Row>
          </Container>
        </div>
      );
    case 3:
      return (
        <div>
          <Container>
            <Row>
              <Col>
                <ChooseBattery
                  nextStep={nextStep}
                  prevStep={prevStep}
                  handleFormObjData={handleInputObjData}
                  values={formData}
                />
              </Col>
            </Row>
          </Container>
        </div>
      );
    case 4:
      return (
        <div>
          <Container>
            <Row>
              <Col>
                <AddDescription
                  nextStep={nextStep}
                  prevStep={prevStep}
                  handleFormData={handleInputData}
                  drivingLicense={formData?.drivingLicense}
                  occupMedicineText={formData?.occupMedicineText}
                  occupMedicineCategory={formData?.occupMedicineCategory?.split(
                    ',',
                  )}
                  description={formData?.description}
                  neurologist={formData?.neurologist}
                  ophthalmologist={formData?.ophthalmologist}
                  laryngologist={formData?.laryngologist}
                  healthcareFacilityId={formData?.healthcareFacilityId}
                  healthcare={formData?.healthcare}
                />
              </Col>
            </Row>
          </Container>
        </div>
      );
    case 5:
      return (
        <div>
          <Container>
            <Row>
              <Col>
                <Tabs defaultActiveKey="employer" id="data" className="mb-2">
                  <Tab eventKey="employer" title="Pracodawca" className="mb-2">
                    <AddEmployer
                      nextStep={nextStep}
                      prevStep={prevStep}
                      handleFormData={handleInputData}
                      employmentCandidate={formData?.employmentCandidate}
                      employerName={formData?.employerName}
                      employerAddress={formData?.employerAddress}
                      employmentPosition={formData?.employmentPosition}
                      type={
                        (formData?.type as unknown as ExaminationType) ||
                        undefined
                      }
                      referralDate={formData?.referralDate || undefined}
                    />
                  </Tab>
                  <Tab
                    eventKey="student"
                    title="Placówka dydaktyczna"
                    className="mb-2"
                  >
                    <AddStudent
                      nextStep={nextStep}
                      prevStep={prevStep}
                      handleFormData={handleInputData}
                      schoolCandidate={formData?.schoolCandidate}
                      kindOfScience={formData?.kindOfScience}
                      schoolName={formData?.schoolName}
                      schoolAddress={formData?.schoolAddress}
                      fieldOfStudy={formData?.fieldOfStudy}
                      endScience={formData?.endScience}
                    />
                  </Tab>
                  <Tab eventKey="sport" title="Sport" className="mb-2">
                    <AddSport
                      nextStep={nextStep}
                      prevStep={prevStep}
                      handleFormData={handleInputData}
                      sportsClubName={formData?.sportsClubName}
                      sportsClubAddress={formData?.sportsClubAddress}
                      sportsDiscipline={formData?.sportsDiscipline}
                    />
                  </Tab>
                </Tabs>
              </Col>
            </Row>
          </Container>
        </div>
      );
    case 6:
      return (
        <div>
          <Container>
            <Row>
              <Col>
                <AddSupportingExamination
                  nextStep={nextStep}
                  prevStep={prevStep}
                  handleFormData={handleInputTableData}
                  values={formData}
                />
              </Col>
            </Row>
          </Container>
        </div>
      );
    case 7:
      return (
        <div>
          <Container>
            <Row>
              <Col>
                <ChooseTest
                  nextStep={nextStep}
                  prevStep={prevStep}
                  handleFormData={handleInputTableData}
                  onSave={handleOnSave}
                  testsKeys={formData.testsKeys || []}
                />
              </Col>
            </Row>
          </Container>
        </div>
      );
    case 8:
      return (
        <div>
          <Container>
            <Row>
              <Col>
                <CommisionningResearch values={formData} />
              </Col>
            </Row>
          </Container>
        </div>
      );
    default:
      return <div className="TestGiving"></div>;
  }
};
