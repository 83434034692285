import React, { useState, useEffect } from 'react';

import { Company } from '../../../common/spdCore/contexts/CompanyContext';
import { useSpdCore } from '../../../common/hooks/useSpdCore';
import { Option } from '../Selects/Selector';
import FormRow from '../../../app/components/FormRow';
import { CreatableClearableSelector } from '../Selects/CreatableClearableSelector';

import { CompanyForm } from './CompanyForm';

interface CompanyCheckOrFormProps {
  value: Company;
  checkNow: number;
  readOnly?: boolean;
  onSelect: (organization: Company) => void;
  onDecision?: (newCompany: boolean) => void;
}

export const CompanyCheckOrForm: React.FC<CompanyCheckOrFormProps> = props => {
  const spdCore = useSpdCore();
  const [checkNow, setCheckNow] = useState(true);

  useEffect(() => {
    if (props.value.name || props.value.nip) {
      setCheckNow(false);
      return;
    }
    setCheckNow(true);
  }, [props.checkNow, props.value]);

  const select = (property: 'name' | 'nip' | 'email', chosen: Option) => {
    if (chosen.new && !chosen.label) {
      return;
    }
    setCheckNow(false);
    if (chosen.new) {
      const object = { ...props.value };
      object[property] = chosen.label;
      props.onSelect(object);
      if (chosen.label && props.onDecision) {
        props.onDecision(true);
      }
    } else {
      spdCore.companies
        .get(chosen.value)
        .then(company => {
          props.onSelect(company);
          props.onDecision && props.onDecision(false);
        })
        .catch(() => {
          console.log('Wystąpił błąd.');
        });
    }
  };

  return checkNow ? (
    <>
      <div className="d-grid gap-3 mb-3">
        <FormRow controlId="lookForByName" label="Nazwa">
          <CreatableClearableSelector
            onChange={chosen => select('name', chosen)}
            provider={spdCore.companies.getOptionsByName}
          />
        </FormRow>
        <FormRow controlId="lookForByNIP" label="NIP">
          <CreatableClearableSelector
            onChange={chosen => select('nip', chosen)}
            isValidNewOption={(inputValue, value, options) => {
              if (!inputValue || inputValue.length < 10) {
                return true;
              }
              if (options.find(opt => opt.label?.startsWith(inputValue))) {
                return false;
              }
              return true;
            }}
            provider={spdCore.companies.getOptionsByNip}
          />
        </FormRow>
        <FormRow controlId="lookForByEmail" label="Email">
          <CreatableClearableSelector
            onChange={chosen => select('email', chosen)}
            provider={spdCore.companies.getOptionsByEmail}
          />
        </FormRow>
      </div>
    </>
  ) : (
    <CompanyForm
      readOnly={props.readOnly}
      value={props.value}
      onSelect={props.onSelect}
      onDecision={props.onDecision}
    />
  );
};
