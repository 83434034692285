import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

export const Footer: React.FC = () => {
  return (
    <footer>
      <Container>
        <Row>
          <Col>
            <div className="d-flex justify-content-center nowrap">
              <a
                className="text-decoration-none"
                href="https://codifive.pl"
                target="_blank"
                rel="external noreferrer"
              >
                Powered by CodiFive Sp. z o. o.
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};
