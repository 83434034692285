import { GetPositionNormDto } from '../autogenerated/spdApiClient';
import { DefaultApi } from '../autogenerated/spdApiClient/apis/DefaultApi';

export type PositionNormEntity = GetPositionNormDto;

export interface PositionNormContextInterface {
  getByNorm: (normId: string) => Promise<PositionNormEntity[]>;
  getByNormCount: (normId: string) => Promise<number>;
}

export const PositionNormContext = (api: DefaultApi) => {
  return {
    getByNorm: async (normId: string) => {
      return await api.positionNormControllerGetByNorm(normId);
    },

    getByNormCount: (normId: string) =>
      api.positionNormControllerGetByNormCount(normId),
  };
};
