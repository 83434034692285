/*eslint max-lines-per-function: ["error", 193]*/
import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';

import { useAuth } from '../../../common/hooks/useAuth';
import { useSzafir } from '../../../common/hooks/useSzafir';
import { useSpdCore } from '../../../common/hooks/useSpdCore';
import { responseErrors } from '../../../common/spdCore/validation/responseErrors';
import {
  GetExamDto,
  GetJudgmentDto,
} from '../../../common/spdCore/autogenerated/spdApiClient';
import { judgmentData } from '../../../common/spdCore/contexts/JudgmentContext';

interface BtnProps {
  judgment: Partial<GetJudgmentDto>;
  exam: GetExamDto;
  onSave: (judg: Partial<GetJudgmentDto>) => void;
  setSavingInProgress: (value: boolean) => void;
}

export const SaveAndPrintJudgment: React.FC<BtnProps> = props => {
  const auth = useAuth();
  const szafir = useSzafir();
  const spd = useSpdCore();
  const mountedRef = useRef(false);

  const [savingInProgress, setSavingInProgress] = useState(false);
  const [message, setMessage] = useState<string>('');
  const [messageVariant, setMessageVariant] = useState<string>('');

  const onSave = () => {
    if (!props.judgment.article) {
      setMessage('Nie wybrano podstawy prawnej!');
      setMessageVariant('danger');
      return;
    }
    if (!savingInProgress) {
      setMessage('');
      setSavingInProgress(true);
      props.setSavingInProgress(true);

      createJudgment(props.judgment?.id, props.judgment)
        .then(() => {
          console.log('Zapisano orzeczenie');
        })
        .catch(async response => {
          const errors = await responseErrors(response as Response);
          setMessage(`Błąd podczas zapisywania orzeczenia:\n ${errors}`);
          setMessageVariant('danger');
          setSavingInProgress(false);
        });
    }
  };

  useEffect(() => {
    mountedRef.current = true;
    if (!savingInProgress) {
      props.setSavingInProgress(false);
    }
    return () => {
      mountedRef.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savingInProgress]);

  const createJudgment = async (
    id: string | undefined,
    judg: Omit<GetJudgmentDto, 'id'>,
  ) => {
    let pom = id;
    if (id) {
      const newJudg = judgmentData({
        ...judg,
        ...{
          id,
          examId: props?.exam?.id,
          articleId: judg.article?.id,
          psychologistUID: judg?.psychologist?.uid,
        },
      });
      await spd.judgments.update(id, newJudg);
      props.onSave({
        ...judg,
        ...{ id },
      });
    } else {
      await spd.judgments
        .create({
          ...judg,
          ...{
            examId: props?.exam?.id,
            articleId: judg.article?.id,
            psychologistUID: judg?.psychologist?.uid,
            psychologist: judg?.psychologist,
          },
        })
        .then(async o => {
          props.onSave({ ...o, ...judg });
          pom = o.id;
        })
        .catch(async response => {
          const errors = await responseErrors(response as Response);
          setMessage(`Wystąpił problem z zapisem orzeczenia:\n ${errors}`);
          setMessageVariant('danger');
          return;
        });
    }

    if (!auth.canSign || props.judgment.withoutSign) {
      pom && (await spd.pdfPrinter.saveAndPrintJudgment(pom));
      setSavingInProgress(false);
      setMessage('Orzeczenie zostało zapisane');
      setMessageVariant('success');
    } else {
      pom &&
        void spd.pdfPrinter.saveWithoutPrintJudgment(pom).then(async () => {
          if (pom) {
            const judgment = await spd.judgments.get(pom);
            await signAndPrint(pom);
            if (judgment.article?.engPdfTemplateId) {
              await new Promise(f => setTimeout(f, 4000));
              await signAndPrint(pom, true);
            }
          }
        });
    }
  };

  const signAndPrint = async (judgmentId: string, english = false) => {
    try {
      const taskList = await spd.szafir.getTaskListForJudgment(
        judgmentId,
        english,
        auth.userProfile?.id,
      );
      await szafir.signDocument(
        taskList,
        auth.userProfile?.pin || '',
        judgmentId,
        '',
        english,
        saveSignedPdf,
        signedFailed,
      );
    } catch (err) {
      const errors = await responseErrors(err as Response);
      setMessage(`Błąd pobierania listy 'TaskList':\n ${errors}`);
      setMessageVariant('danger');
      setSavingInProgress(false);
    }
  };

  const saveSignedPdf = async (
    result: Buffer | null,
    judgmentId: string,
    documentNumber: string,
    english: boolean,
  ) => {
    spd.savedPdfs
      .savePdfFile({
        entityId: judgmentId,
        file: result,
        english: english,
      })
      .then(() => {
        setMessage('Orzeczenie zostało podpisane');
        setMessageVariant('success');
      })
      .then(async () => {
        await spd.emails.sendJudgmentByEmail(
          judgmentId,
          auth.userProfile?.id || '',
          english,
        );
      })
      .catch(async response => {
        const errors = await responseErrors(response as Response);
        setMessage(`Błąd:\n ${errors}`);
        setMessageVariant('danger');
      })
      .finally(() => {
        setSavingInProgress(false);
      });
  };

  const signedFailed = (error: string) => {
    setMessage(`Błąd podczas podpisywania orzeczenia:\n ${error}`);
    setMessageVariant('danger');
    setSavingInProgress(false);
  };

  return (
    <>
      {message && <Alert variant={messageVariant || 'danger'}>{message}</Alert>}
      <Button
        variant="outline-primary"
        className="ms-1"
        onClick={() => onSave()}
        disabled={savingInProgress}
      >
        {auth.canSign && !props.judgment.withoutSign
          ? savingInProgress
            ? 'Podpisywanie'
            : 'Podpisz'
          : savingInProgress
          ? 'Zapisywanie'
          : 'Zapisz'}
      </Button>
    </>
  );
};
