import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Card, Tab } from 'react-bootstrap';

import Tabs from '../../../app/components/Tabs';
import { useSpdCore } from '../../../common/hooks/useSpdCore';
import {
  CreateUpdateArticleDto,
  GetArticleDto,
  GetPdfTemplateDto,
  GetRegisterDto,
} from '../../../common/spdCore/autogenerated/spdApiClient';
import {
  articleData,
  validate,
} from '../../../common/spdCore/contexts/ArticleContext';
import { responseErrors } from '../../../common/spdCore/validation/responseErrors';
import { ArticleEditor } from '../../components/Articles/ArticleEditor';
import { Article } from '../../components/Articles/Articles';

export const ArticlesView: React.FC = () => {
  const spd = useSpdCore();

  const [show, setShow] = useState(false);
  const [article, setArticle] = useState<GetArticleDto>();
  const [articles, setArticles] = useState<GetArticleDto[]>([]);
  const [allPdfTemplates, setAllPdfTemplates] = useState<GetPdfTemplateDto[]>(
    [],
  );
  const [allRegisters, setAllRegisters] = useState<GetRegisterDto[]>([]);

  const mountedRef = useRef(false);

  const refreshArticles = useCallback(() => {
    if (!mountedRef.current) {
      return;
    }
    spd.articles
      .getAll()
      .then(art => setArticles(art))
      .catch(error => {
        console.error('Wystąpił problem z pobraniem artykułów:', error);
      });
  }, [spd.articles]);

  useEffect(() => {
    mountedRef.current = true;
    refreshArticles();
    return () => {
      mountedRef.current = false;
    };
  }, [refreshArticles]);

  useEffect(() => {
    spd.pdfTemplates
      .getAll()
      .then(templates => setAllPdfTemplates(templates))
      .catch(error =>
        console.log(`Wystąpił problem z pobraniem szablonu. ${error}`),
      );
  }, [spd.pdfTemplates]);

  useEffect(() => {
    spd.registers
      .getAll()
      .then(registers => setAllRegisters(registers))
      .catch(error =>
        console.log(`Wystąpił problem z pobraniem rejestrów. ${error}`),
      );
  }, [spd.registers]);

  const handleAdd = () => {
    setShow(true);
    setArticle(undefined);
  };

  const addEditArticle = async (
    articleCu: CreateUpdateArticleDto,
    id?: string,
  ) => {
    const status = await validate(articleCu);
    if (status.valid) {
      try {
        if (id) {
          await spd.articles.update(id, articleCu);
        } else {
          await spd.articles.create(articleCu);
        }
        setShow(false);
        refreshArticles();
        return {
          saved: true,
          errors: [],
        };
      } catch (response) {
        return {
          saved: false,
          errors: await responseErrors(response as Response),
        };
      }
    } else {
      return {
        saved: false,
        errors: status.errors,
      };
    }
  };

  const handleClose = () => {
    setShow(false);
    refreshArticles();
  };

  const handleClick = async (id: string) => {
    const getArticle = await spd.articles.get(id);
    setArticle(getArticle);
    setShow(true);
  };

  return (
    <Card>
      <Tabs defaultActiveKey="search" id="articles" className="mb-3">
        <Tab eventKey="search" title="Artykuły" className="m-3">
          <Article data={articles} onRowClick={handleClick} />
          <Button variant="outline-primary" onClick={handleAdd}>
            Nowy artykuł
          </Button>
          <ArticleEditor
            id={article?.id}
            article={articleData(article)}
            onCompleted={addEditArticle}
            show={show}
            handleClose={handleClose}
            pdfTemplates={allPdfTemplates}
            registers={allRegisters}
          />
        </Tab>
      </Tabs>
    </Card>
  );
};
