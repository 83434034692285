/* tslint:disable */
/* eslint-disable */
/**
 * SPD
 * Opis api SPD
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUpdateAttachmentDto
 */
export interface CreateUpdateAttachmentDto {
    /**
     * 
     * @type {Date}
     * @memberof CreateUpdateAttachmentDto
     */
    date?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateAttachmentDto
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateAttachmentDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateAttachmentDto
     */
    examinedId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateAttachmentDto
     */
    examId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateAttachmentDto
     */
    testId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateAttachmentDto
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateAttachmentDto
     */
    scan?: string | null;
    /**
     * 
     * @type {object}
     * @memberof CreateUpdateAttachmentDto
     */
    file?: object | null;
}

export function CreateUpdateAttachmentDtoFromJSON(json: any): CreateUpdateAttachmentDto {
    return CreateUpdateAttachmentDtoFromJSONTyped(json, false);
}

export function CreateUpdateAttachmentDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateAttachmentDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'date': !exists(json, 'date') ? undefined : (json['date'] === null ? null : new Date(json['date'])),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'examinedId': !exists(json, 'examinedId') ? undefined : json['examinedId'],
        'examId': !exists(json, 'examId') ? undefined : json['examId'],
        'testId': !exists(json, 'testId') ? undefined : json['testId'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'scan': !exists(json, 'scan') ? undefined : json['scan'],
        'file': !exists(json, 'file') ? undefined : json['file'],
    };
}

export function CreateUpdateAttachmentDtoToJSON(value?: CreateUpdateAttachmentDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'date': value.date === undefined ? undefined : (value.date === null ? null : value.date.toISOString()),
        'description': value.description,
        'name': value.name,
        'examinedId': value.examinedId,
        'examId': value.examId,
        'testId': value.testId,
        'type': value.type,
        'scan': value.scan,
        'file': value.file,
    };
}


