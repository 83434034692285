/* tslint:disable */
/* eslint-disable */
/**
 * SPD
 * Opis api SPD
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUpdateDutyDto
 */
export interface CreateUpdateDutyDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateDutyDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateDutyDto
     */
    psychologistUID?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateDutyDto
     */
    doctorUID?: string | null;
}

export function CreateUpdateDutyDtoFromJSON(json: any): CreateUpdateDutyDto {
    return CreateUpdateDutyDtoFromJSONTyped(json, false);
}

export function CreateUpdateDutyDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateDutyDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'psychologistUID': !exists(json, 'psychologistUID') ? undefined : json['psychologistUID'],
        'doctorUID': !exists(json, 'doctorUID') ? undefined : json['doctorUID'],
    };
}

export function CreateUpdateDutyDtoToJSON(value?: CreateUpdateDutyDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'psychologistUID': value.psychologistUID,
        'doctorUID': value.doctorUID,
    };
}


