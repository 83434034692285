import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

import { useSpdCore } from '../../../common/hooks/useSpdCore';
import ConfirmationButton from '../../../app/components/ConfirmationButton';

export const EmailBinLink = (props: {
  emailId: string;
  setRefresh?: () => void;
}) => {
  const spd = useSpdCore();

  const handleClick = () => {
    spd.emails
      .changeToInactive(props.emailId)
      .then(() => {
        props.setRefresh && props.setRefresh();
      })
      .catch(error =>
        console.log(
          `Wystąpił problem z usunięciem wiadomości e-mail. ${error}`,
        ),
      );
  };

  return (
    <>
      <ConfirmationButton
        className="ms-1"
        confirmation="Czy na pewno usunąć wiadomość e-mail?"
        variant="btn-info"
        onOK={handleClick}
      >
        <OverlayTrigger
          overlay={
            <Tooltip style={{ position: 'fixed' }}>
              Usunięcie wiadomości e-mail
            </Tooltip>
          }
        >
          <Icon.Trash />
        </OverlayTrigger>
      </ConfirmationButton>
    </>
  );
};
