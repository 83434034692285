import React from 'react';
import { Button } from 'react-bootstrap';

import { useNavigation } from '../../../common/navigation';

export const TestGivingModeView: React.FC = () => {
  const nav = useNavigation();

  const handleIndividualExam = () => {
    nav.testGivingIndividual();
  };

  const handleMassExam = () => {
    nav.testGivingMass();
  };

  return (
    <>
      <div className="bg-light p-5 rounded-lg m-3 text-center">
        <h1>SPD</h1>
        <p className="lead">Zadawanie testów</p>
        <div className="text-center"></div>
        <hr className="my-4" />
        <Button
          variant="outline-primary"
          className="ms-1"
          onClick={handleIndividualExam}
        >
          Zadaj badanie indywidualnemu badanemu
        </Button>
        <Button
          variant="outline-primary"
          className="ms-1"
          onClick={handleMassExam}
        >
          Zadaj badanie zbiorcze
        </Button>
      </div>
    </>
  );
};
