import React, { useState, useEffect, useCallback, useRef } from 'react';

import { Option } from '../Selects/Selector';
import {
  Company,
  partial,
  validate,
} from '../../../common/spdCore/contexts/CompanyContext';
import { useSpdCore } from '../../../common/hooks/useSpdCore';
import ValidationAlert from '../../../app/components/ValidationAlert';
import FormRow from '../../../app/components/FormRow';
import { CreatableClearableSelector } from '../Selects/CreatableClearableSelector';

interface CompanyFormProps {
  value: Company;
  readOnly?: boolean;
  onSelect: (company: Company) => void;
  onDecision?: (newCompany: boolean) => void;
}

export const CompanyForm: React.FC<CompanyFormProps> = props => {
  const spdApi = useSpdCore();
  const [readOnly, setReadOnly] = useState(props.readOnly || false);
  const [selectedCompany, setSelectedCompany] = useState(props.value);
  const [presentedCompany, setPresentedCompany] = useState(selectedCompany);
  const [showValidationErrors, setShowValidationErrors] = useState(false);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const mountedRef = useRef(false);

  const selectedChange = (obj: Partial<Company>) => {
    const company = { ...presentedCompany, ...obj };
    setSelectedCompany(company);
    validateWithErrors(company);
    props.onSelect(company);
  };

  const selectCompany = (nameOrNip: 'nip' | 'name' | 'email') => {
    return (chosen: Option) => {
      if (chosen.new && !chosen.label) {
        return;
      }

      if (chosen.new) {
        selectedChange(partial(nameOrNip, chosen.label));
      } else {
        spdApi.companies
          .get(chosen.value)
          .then(company => {
            setSelectedCompany(company);
            setReadOnly(true);
            props.onSelect(company);
          })
          .catch(() => {
            console.log('Wystąpił błąd');
          });
      }
      if (props.onDecision) {
        props.onDecision(chosen.new || false);
      }
    };
  };

  const validateWithErrors = useCallback(
    (org: Company) => {
      validate(org)
        .then(status => {
          if (mountedRef.current) {
            if (status.valid && !('id' in org)) {
              if (mountedRef.current) {
                setShowValidationErrors(false);
              }
            } else {
              const forceValid = readOnly && 'id' in org;
              setValidationErrors(status.errors);
              setShowValidationErrors(true);
              if (forceValid) {
                setShowValidationErrors(false);
              }
            }
          }
        })
        .catch(() => {
          console.log('Wystąpił błąd');
        });
    },
    [readOnly],
  );

  useEffect(() => {
    setPresentedCompany(selectedCompany);
  }, [selectedCompany]);

  useEffect(() => {
    if (mountedRef.current) {
      setShowValidationErrors(false);
    }
  }, []);
  useEffect(() => setReadOnly(props.readOnly || false), [props.readOnly]);
  useEffect(() => {
    if (mountedRef.current) {
      setSelectedCompany(props.value);
      validateWithErrors(props.value);
    }
  }, [props.value, validateWithErrors]);

  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);

  return (
    <>
      <div className="d-grid gap-3">
        <FormRow controlId="name" label="Nazwa">
          <CreatableClearableSelector
            value={presentedCompany.name || undefined}
            onChange={selectCompany('name')}
            readOnly={readOnly}
            provider={spdApi.companies.getOptionsByName}
          />
        </FormRow>
        <FormRow controlId="nip" label="NIP">
          <CreatableClearableSelector
            value={presentedCompany.nip || undefined}
            onChange={selectCompany('nip')}
            readOnly={readOnly}
            provider={spdApi.companies.getOptionsByNip}
            isValidNewOption={(inputValue, value, options) => {
              if (!inputValue || inputValue.length < 10) {
                return true;
              }
              if (options.find(opt => opt.label?.startsWith(inputValue))) {
                return false;
              }
              return true;
            }}
          />
        </FormRow>
        <FormRow controlId="email" label="Email">
          <CreatableClearableSelector
            value={presentedCompany.email || undefined}
            onChange={selectCompany('email')}
            readOnly={readOnly}
            provider={spdApi.companies.getOptionsByEmail}
          />
        </FormRow>
      </div>
      <ValidationAlert
        show={showValidationErrors}
        errors={validationErrors}
        className="mt-3"
      />
    </>
  );
};
