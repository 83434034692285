import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

import {
  EmailStatus,
  EmailStatusText,
} from '../../../common/spdCore/validation/schemas';

export const EmailStatusIcon = (props: { status?: EmailStatus }) => {
  let icon;
  if (!props.status) {
    return <></>;
  }
  const statusTxt =
    EmailStatusText[props.status as keyof typeof EmailStatusText];
  if (props.status === EmailStatus.Sent) {
    icon = <Icon.Envelope className="icon-green mt-2" />;
  } else {
    icon = <Icon.EnvelopeExclamationFill className="icon-red mt-2" />;
  }
  return (
    <div className="text-center align-middle">
      <OverlayTrigger
        overlay={<Tooltip style={{ position: 'fixed' }}>{statusTxt}</Tooltip>}
      >
        {icon}
      </OverlayTrigger>
    </div>
  );
};
