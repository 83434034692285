import React, { useState } from 'react';
import { Button } from 'react-bootstrap';

import { useSpdCore } from '../../../common/hooks/useSpdCore';
import { responseErrors } from '../../../common/spdCore/validation/responseErrors';
import { GetCompanyDto } from '../../../common/spdCore/autogenerated/spdApiClient/models/GetCompanyDto';
import { CreateUpdateCompanyDto } from '../../../common/spdCore/autogenerated/spdApiClient/models/CreateUpdateCompanyDto';
import { companyData } from '../../../common/spdCore/contexts/CompanyContext';

import { CompanySearch } from './CompanySearch';
import { CompanyEditor } from './CompanyEditor';

interface CompanyListProps {
  onRowClick?: (key: string) => Promise<void> | void | undefined;
  enableSingleSelect?: boolean;
  selectedKeys?: string[];
}

export const CompanyList: React.FC<CompanyListProps> = ({
  onRowClick,
  enableSingleSelect,
  selectedKeys,
}) => {
  const spd = useSpdCore();

  const [show, setShow] = useState(false);
  const [company, setCompany] = useState<GetCompanyDto>();

  const [refresh, setRefresh] = useState<boolean>(false);

  const handleAdd = () => {
    setShow(true);
    setCompany(undefined);
  };

  const addEditCompany = async (
    companyCU: CreateUpdateCompanyDto,
    id?: string,
  ) => {
    try {
      if (id) {
        await spd.companies.update(id, companyCU);
      } else {
        await spd.companies.create(companyCU);
      }
      setShow(false);
      setRefresh(!refresh);
      return {
        saved: true,
        errors: [],
      };
    } catch (response) {
      return {
        saved: false,
        errors: await responseErrors(response as Response),
      };
    }
  };

  const handleClose = () => {
    setShow(false);
    setRefresh(!refresh);
  };

  const handleClick = async (id: string) => {
    const getCompany = await spd.companies.get(id);
    setCompany(getCompany);
    setShow(true);
  };

  const handleOnExport = async () => {
    await spd.pdfPrinter.printCompanies(true);
  };

  return (
    <>
      <CompanySearch
        getData={spd.companies.getAllByPortion}
        getCount={spd.companies.getAllCount}
        onRowClick={onRowClick ? onRowClick : handleClick}
        refresh={refresh}
        enableSingleSelect={enableSingleSelect}
        selectedKeys={selectedKeys}
      />
      <div className="d-flex justify-content-start">
        <Button variant="outline-primary" onClick={handleAdd}>
          Nowa firma
        </Button>
        <Button
          className="ms-1"
          variant="outline-primary"
          onClick={handleOnExport}
        >
          Eksportuj rozliczane miesięcznie
        </Button>
      </div>
      <CompanyEditor
        id={company?.id}
        company={companyData(company)}
        batteries={company?.companiesBatteries?.map(cb => cb.battery)}
        onCompleted={addEditCompany}
        show={show}
        handleClose={handleClose}
        readOnly={false}
      />
    </>
  );
};
