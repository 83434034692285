/* tslint:disable */
/* eslint-disable */
/**
 * SPD
 * Opis api SPD
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetExaminedDto,
    GetExaminedDtoFromJSON,
    GetExaminedDtoFromJSONTyped,
    GetExaminedDtoToJSON,
    GetUserDto,
    GetUserDtoFromJSON,
    GetUserDtoFromJSONTyped,
    GetUserDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetEmailDto
 */
export interface GetEmailDto {
    /**
     * 
     * @type {string}
     * @memberof GetEmailDto
     */
    id: string;
    /**
     * 
     * @type {GetExaminedDto}
     * @memberof GetEmailDto
     */
    examined: GetExaminedDto;
    /**
     * 
     * @type {GetUserDto}
     * @memberof GetEmailDto
     */
    user: GetUserDto;
    /**
     * 
     * @type {string}
     * @memberof GetEmailDto
     */
    sentTo?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof GetEmailDto
     */
    sendDate: Date;
    /**
     * 
     * @type {string}
     * @memberof GetEmailDto
     */
    status?: GetEmailDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof GetEmailDto
     */
    info?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetEmailDto
     */
    document?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetEmailDto
     */
    active?: boolean | null;
}

export function GetEmailDtoFromJSON(json: any): GetEmailDto {
    return GetEmailDtoFromJSONTyped(json, false);
}

export function GetEmailDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetEmailDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'examined': GetExaminedDtoFromJSON(json['examined']),
        'user': GetUserDtoFromJSON(json['user']),
        'sentTo': !exists(json, 'sentTo') ? undefined : json['sentTo'],
        'sendDate': (new Date(json['sendDate'])),
        'status': !exists(json, 'status') ? undefined : json['status'],
        'info': !exists(json, 'info') ? undefined : json['info'],
        'document': !exists(json, 'document') ? undefined : json['document'],
        'active': !exists(json, 'active') ? undefined : json['active'],
    };
}

export function GetEmailDtoToJSON(value?: GetEmailDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'examined': GetExaminedDtoToJSON(value.examined),
        'user': GetUserDtoToJSON(value.user),
        'sentTo': value.sentTo,
        'sendDate': (value.sendDate.toISOString()),
        'status': value.status,
        'info': value.info,
        'document': value.document,
        'active': value.active,
    };
}

/**
* @export
* @enum {string}
*/
export enum GetEmailDtoStatusEnum {
    NotSent = 'NotSent',
    Sent = 'Sent',
    Error = 'Error'
}


