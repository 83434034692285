/*eslint max-lines-per-function: ["error", 400]*/
/* eslint-disable complexity */
import React, { useEffect, useState } from 'react';
import {
  Dropdown,
  DropdownButton,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';

import ConfirmationButton from '../../../app/components/ConfirmationButton';
import { useNavigation } from '../../../common/navigation';
import { GetExamDto } from '../../../common/spdCore/autogenerated/spdApiClient';
import {
  ArticleMode,
  CardType,
  ConsultationType,
} from '../../../common/spdCore/validation/schemas';
import { useSpdCore } from '../../../common/hooks/useSpdCore';

export const ExamEndLink = (props: {
  onExamChanged: (
    exam: Pick<GetExamDto, 'id' | 'endDate' | 'startDate'>,
  ) => void;
  examId: string;
  endDate?: Date | null;
  startDate?: Date | null;
  mode?: ArticleMode;
}) => {
  const nav = useNavigation();
  const spd = useSpdCore();

  const [consultationsLabel, setConsultationsLabel] = useState<string>('');
  const [cardsLabel, setCardsLabel] = useState<string>('');
  const [judgmentsLabel, setJudgmentsLabel] = useState<string>('');

  const [cardsDriverLabel, setCardsDriverLabel] = useState<string>('');
  const [cardsPreventiveLabel, setCardsPreventiveLabel] = useState<string>('');
  const [cardsSanitaryLabel, setCardsSanitaryLabel] = useState<string>('');
  const [cardsPsychologistLabel, setCardsPsychologistLabel] =
    useState<string>('');
  const [cardsPsychologistWeaponLabel, setCardsPsychologistWeaponLabel] =
    useState<string>('');
  const [cardsWeaponLabel, setCardsWeaponLabel] = useState<string>('');
  const [cardsSportLabel, setCardsSportLabel] = useState<string>('');

  const [judgmentsPsychologistLabel, setJudgmentsPsychologistLabel] =
    useState<string>('');
  const [judgmentsDoctorLabel, setJudgmentsDoctorLabel] = useState<string>('');

  const [consultationNeurologistName, setConsultationNeurologistName] =
    useState<string>('');
  const [consultationOphthalmologistName, setConsultationOphthalmologistName] =
    useState<string>('');
  const [consultationENTName, setConsultationENTName] = useState<string>('');
  const [consultationPsychiatricName, setConsultationPsychiatricName] =
    useState<string>('');

  useEffect(() => {
    if (props.examId) {
      spd.cards
        .getAllByExam(props.examId)
        .then(o => {
          let cardCount = o.filter(
            t => (t.type as unknown as CardType) === CardType.Driver,
          ).length;
          setCardsDriverLabel(` ${cardCount > 0 ? `(${cardCount})` : ''}`);
          cardCount = o.filter(
            t => (t.type as unknown as CardType) === CardType.Preventive,
          ).length;
          setCardsPreventiveLabel(` ${cardCount > 0 ? `(${cardCount})` : ''}`);
          cardCount = o.filter(
            t => (t.type as unknown as CardType) === CardType.Sanitary,
          ).length;
          setCardsSanitaryLabel(` ${cardCount > 0 ? `(${cardCount})` : ''}`);
          cardCount = o.filter(
            t => (t.type as unknown as CardType) === CardType.Psychologist,
          ).length;
          setCardsPsychologistLabel(
            ` ${cardCount > 0 ? `(${cardCount})` : ''}`,
          );
          cardCount = o.filter(
            t =>
              (t.type as unknown as CardType) === CardType.PsychologistWeapon,
          ).length;
          setCardsPsychologistWeaponLabel(
            ` ${cardCount > 0 ? `(${cardCount})` : ''}`,
          );
          cardCount = o.filter(
            t => (t.type as unknown as CardType) === CardType.Weapon,
          ).length;
          setCardsWeaponLabel(` ${cardCount > 0 ? `(${cardCount})` : ''}`);
          cardCount = o.filter(
            t => (t.type as unknown as CardType) === CardType.Sport,
          ).length;
          setCardsSportLabel(` ${cardCount > 0 ? `(${cardCount})` : ''}`);
          setCardsLabel(`${(o.length > 0 && `(${o.length})`) || ''}`);
        })
        .catch(error => console.log(error, 'Nie udało się pobrać liczby kart'));
    }
  }, [spd.cards, props.examId]);

  useEffect(() => {
    if (props.examId) {
      spd.judgments
        .getForExam(props.examId)
        .then(o => {
          const judPsychoCount = o.filter(
            j =>
              (j?.article?.mode as unknown as ArticleMode) ===
              ArticleMode.Psychologist,
          ).length;
          const judDoctorCount = o.length - judPsychoCount;
          setJudgmentsPsychologistLabel(
            ` ${(judPsychoCount > 0 && `(${judPsychoCount})`) || ''}`,
          );
          setJudgmentsDoctorLabel(
            ` ${(judDoctorCount > 0 && `(${judDoctorCount})`) || ''}`,
          );
          setJudgmentsLabel(`${(o.length > 0 && `(${o.length})`) || ''}`);
        })
        .catch(() => console.log('Nie udało się pobrać orzeczeń'));
    }
  }, [spd.judgments, props.examId]);

  useEffect(() => {
    if (props.examId && props.mode !== ArticleMode.Psychologist) {
      spd.consultations
        .getForExam(props.examId)
        .then(o => {
          const consNeuroCount = o.filter(
            j =>
              (j?.type as unknown as ConsultationType) ===
              ConsultationType.Neurological,
          ).length;
          const consOphtalCount = o.filter(
            j =>
              (j?.type as unknown as ConsultationType) ===
              ConsultationType.Ophthalmologist,
          ).length;
          const consENTCount = o.filter(
            j =>
              (j?.type as unknown as ConsultationType) === ConsultationType.ENT,
          ).length;
          const consPsychiatricCount = o.filter(
            j =>
              (j?.type as unknown as ConsultationType) ===
              ConsultationType.Psychiatric,
          ).length;
          setConsultationNeurologistName(
            ` ${(consNeuroCount > 0 && `(${consNeuroCount})`) || ''}`,
          );
          setConsultationOphthalmologistName(
            ` ${(consOphtalCount > 0 && `(${consOphtalCount})`) || ''}`,
          );
          setConsultationENTName(
            ` ${(consENTCount > 0 && `(${consENTCount})`) || ''}`,
          );
          setConsultationPsychiatricName(
            ` ${
              (consPsychiatricCount > 0 && `(${consPsychiatricCount})`) || ''
            }`,
          );
          setConsultationsLabel(`${(o.length > 0 && `(${o.length})`) || ''}`);
        })
        .catch(() => console.log('Nie udało się pobrać konsultacji'));
    }
  }, [spd.consultations, props.examId, props.mode]);

  const endExam = async () => {
    if (props.examId) {
      props.onExamChanged({
        id: props.examId,
        endDate: new Date(),
        startDate: !props.startDate ? new Date() : props.startDate,
      });
    }
  };

  return (
    <>
      {props.examId && props.endDate && (
        <div style={{ display: 'flex' }}>
          <DropdownButton
            title={`Karty ${cardsLabel}`}
            id="cards-dropdown"
            variant="outline-primary"
            className="ms-1 mb-1"
          >
            {props.mode !== ArticleMode.Doctor && (
              <>
                <Dropdown.Item
                  onClick={e => {
                    e.stopPropagation();
                    nav.examCardsPsychologic(props.examId);
                  }}
                  eventKey="1"
                >
                  Karta psychologiczna {cardsPsychologistLabel}
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={e => {
                    e.stopPropagation();
                    nav.examCardsPsychologicWeapon(props.examId);
                  }}
                  eventKey="1"
                >
                  Karta psychologiczna na broń {cardsPsychologistWeaponLabel}
                </Dropdown.Item>
              </>
            )}
            {props.mode !== ArticleMode.Psychologist && (
              <>
                <Dropdown.Item
                  eventKey="2"
                  onClick={e => {
                    e.stopPropagation();
                    nav.examCardsPreventive(props.examId);
                  }}
                >
                  Karta profilaktyczna {cardsPreventiveLabel}
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey="3"
                  onClick={e => {
                    e.stopPropagation();
                    nav.examCardsDriver(props.examId);
                  }}
                >
                  Karta kierowcy {cardsDriverLabel}
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey="4"
                  onClick={e => {
                    e.stopPropagation();
                    nav.examCardsSanitary(props.examId);
                  }}
                >
                  Karta sanitarno-epidemiologiczna {cardsSanitaryLabel}
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey="4"
                  onClick={e => {
                    e.stopPropagation();
                    nav.examCardsSport(props.examId);
                  }}
                >
                  Karta sportowa {cardsSportLabel}
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={e => {
                    e.stopPropagation();
                    nav.examCardsWeapon(props.examId);
                  }}
                  eventKey="1"
                >
                  Karta broń {cardsWeaponLabel}
                </Dropdown.Item>
              </>
            )}
          </DropdownButton>
          <DropdownButton
            title={`Orzeczenia ${judgmentsLabel}`}
            id="judgments-dropdown"
            variant="outline-primary"
            className="ms-1 mb-1"
          >
            {props.mode !== ArticleMode.Doctor && (
              <Dropdown.Item
                eventKey="1"
                onClick={e => {
                  e.stopPropagation();
                  nav.examPsychologistJudgments(props.examId);
                }}
              >
                Orzeczenia psychologiczne {judgmentsPsychologistLabel}
              </Dropdown.Item>
            )}
            {props.mode !== ArticleMode.Psychologist && (
              <Dropdown.Item
                eventKey="2"
                onClick={e => {
                  e.stopPropagation();
                  nav.examDoctorJudgments(props.examId);
                }}
              >
                Orzeczenia lekarskie {judgmentsDoctorLabel}
              </Dropdown.Item>
            )}
          </DropdownButton>
          {props.mode !== ArticleMode.Psychologist && (
            <DropdownButton
              title={`Konsultacje ${consultationsLabel}`}
              id="consultations-dropdown"
              variant="outline-primary"
              className="ms-1 mb-1"
            >
              <Dropdown.Item
                eventKey="1"
                onClick={e => {
                  e.stopPropagation();
                  nav.consultationNeurological(props.examId);
                }}
              >
                Konsultacja neurologiczna {consultationNeurologistName}
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="2"
                onClick={e => {
                  e.stopPropagation();
                  nav.consultationOphthalmologist(props.examId);
                }}
              >
                Konsultacja okulistyczna {consultationOphthalmologistName}
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="3"
                onClick={e => {
                  e.stopPropagation();
                  nav.consultationENT(props.examId);
                }}
              >
                Konsultacja laryngologiczna {consultationENTName}
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="3"
                onClick={e => {
                  e.stopPropagation();
                  nav.consultationPsychiatric(props.examId);
                }}
              >
                Konsultacja psychiatryczna {consultationPsychiatricName}
              </Dropdown.Item>
            </DropdownButton>
          )}
        </div>
      )}

      {props.examId && !props.endDate && (
        <OverlayTrigger overlay={<Tooltip>Zakończenie badania</Tooltip>}>
          <ConfirmationButton
            confirmation="Czy na pewno zakończyć badanie? Badany nie będzie mógł uruchomić badania ponownie."
            variant="outline-danger"
            onOK={() => endExam()}
          >
            Zakończ badanie
          </ConfirmationButton>
        </OverlayTrigger>
      )}
    </>
  );
};
