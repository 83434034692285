/* eslint-disable @typescript-eslint/no-explicit-any */
import { Buffer } from 'buffer';

import { XMLParser } from 'fast-xml-parser';

import { SZAFIR_SDK } from '../szafirsdk-module';

/**
 * Klasa obsługująca podpisywanie dokumentów w systemie Szafir.
 */
export class SzafirService {
  debug = process.env.REACT_APP_SZAFIR_DEBUG ? true : false;

  errorCallback = (error: any) => {
    alert('[***** Błąd Szafir SDK *****]\n\n' + error);
  };

  activate = (canSign = false) => {
    if (canSign) {
      this.activateSzafirHost();
    }
  };

  deactivate = (canSign = false) => {
    if (canSign) {
      this.deactivateSzafirHost();
    }
  };

  activateSzafirHost = () => {
    const sdk_location_url =
      '' +
      process.env.REACT_APP_SPDAPI_URL +
      process.env.REACT_APP_SZAFIR_SDK_URL;
    SZAFIR_SDK.config({
      sdk_location_url: sdk_location_url,
      versions_file: 'versions.xml',
      document_dependencies_folder: 'somewhere',
      series_authorization: true,
      onError: this.errorCallback,
      onErrorNoExtension: function (errorCode: string, info: any) {
        alert(
          'CUSTOM onErrorNoExtension();\n\nerrorCode = ' +
            errorCode +
            '\n\ninfo = ' +
            JSON.stringify(info),
        );
      },
      onErrorNoHostApp: function (errorCode: string, info: any) {
        alert(
          'CUSTOM onErrorNoHostApp();\n\nerrorCode = ' +
            errorCode +
            '\n\ninfo = ' +
            JSON.stringify(info),
        );
      },
      debug: this.debug, //Włączenie trybu debugowania
    });

    SZAFIR_SDK.loadSzafir(
      /* successCallback */ () => {
        console.log('**aktywowano SzafirHost');
        this.startComponentURI();
      },
      /* errorCallback */ function () {
        console.log('**BŁAD aktywacji SzafirHost!!!');
      },
    );
  };

  deactivateSzafirHost = () => {
    SZAFIR_SDK.unloadSzafir(
      () => {
        console.log('**deaktywowano SzafirHost');
      },
      function () {
        console.log('**BŁAD deaktywacji SzafirHost!!!');
      },
    );
  };

  startComponentURI = () => {
    //– funkcja inicjalizująca komponenty Szafir SDK;
    const szafirSettingsUrl =
      '' +
      process.env.REACT_APP_SPDAPI_URL +
      process.env.REACT_APP_SZAFIR_SETTINGS_URL;
    SZAFIR_SDK.startComponentURI(
      szafirSettingsUrl,
      /* successCallback */ () => {
        console.log('**aktywowano Szafir SDK');
      },
      /* errorCallback */ function () {
        console.log('**BŁAD aktywacji Szafir SDK');
      },
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  signDocument = async (
    taskList: any,
    pin: string,
    documentId: string,
    documentNumber: string,
    english: boolean,
    successCallback: (
      result: Buffer | null,
      documentId: string,
      documentNumber: string,
      english: boolean,
    ) => void,
    errorCallback: (
      error: string,
      documentId: string,
      documentNumber: string,
      english: boolean,
    ) => void,
  ) => {
    SZAFIR_SDK.doTaskListNoWindow1Pass(
      taskList,
      pin,
      /* successCallback */ async (result: any) => {
        const signResult = await this.parseResult(result);
        if (signResult.error) {
          errorCallback(signResult.error, documentId, documentNumber, english);
        } else {
          successCallback(
            signResult.pdfData,
            documentId,
            documentNumber,
            english,
          );
        }
      },
      /* errorCallback */ async (result: any) => {
        const signResult = await this.parseResult(result);
        errorCallback(signResult.error, documentId, documentNumber, english);
      },
    );
  };

  parseResult = async (result: any) => {
    const options = {
      ignoreAttributes: false,
      attributeNamePrefix: '@_',
    };
    const parser = new XMLParser(options);
    const jObj = parser.parse(result);
    if (!jObj.TaskList) {
      return { error: `${result}`, pdfData: null };
    }
    const resultCode =
      jObj.TaskList.SigningTask.SigningTaskItem.SigningResult.Result['@_code'];
    if (resultCode !== '0') {
      const err2 =
        jObj.TaskList.SigningTask.SigningTaskItem.SigningResult.Description[
          '#text'
        ];
      const err =
        jObj.TaskList.SigningTask.SigningTaskItem.SigningResult.Result['#text'];
      return { error: `${err}: ${err2}`, pdfData: null };
    }
    const base64String =
      jObj.TaskList.SigningTask.SigningTaskItem.SignatureToCreate.Base64Binary;
    const pdfData = Buffer.from(base64String, 'base64');
    return { error: '', pdfData: pdfData };
  };
}
