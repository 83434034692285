/* tslint:disable */
/* eslint-disable */
/**
 * SPD
 * Opis api SPD
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExamTestDateDto
 */
export interface ExamTestDateDto {
    /**
     * 
     * @type {Date}
     * @memberof ExamTestDateDto
     */
    startDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ExamTestDateDto
     */
    endDate?: Date | null;
}

export function ExamTestDateDtoFromJSON(json: any): ExamTestDateDto {
    return ExamTestDateDtoFromJSONTyped(json, false);
}

export function ExamTestDateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExamTestDateDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'startDate': !exists(json, 'startDate') ? undefined : (json['startDate'] === null ? null : new Date(json['startDate'])),
        'endDate': !exists(json, 'endDate') ? undefined : (json['endDate'] === null ? null : new Date(json['endDate'])),
    };
}

export function ExamTestDateDtoToJSON(value?: ExamTestDateDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'startDate': value.startDate === undefined ? undefined : (value.startDate === null ? null : value.startDate.toISOString()),
        'endDate': value.endDate === undefined ? undefined : (value.endDate === null ? null : value.endDate.toISOString()),
    };
}


