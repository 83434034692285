import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useSpdCore } from '../../../common/hooks/useSpdCore';
import {
  GetArticleDto,
  GetExamDto,
} from '../../../common/spdCore/autogenerated/spdApiClient';
import { ArticleMode } from '../../../common/spdCore/validation/schemas';
import { ExamJudgmentEditor } from '../../components/ExamJudgments/ExamJudgmentEditor';
import { Role } from '../../../common/spdCore/authorization/roles';
import { useSzafir } from '../../../common/hooks/useSzafir';
import { useAuth } from '../../../common/hooks/useAuth';

type ParamTypes = {
  id: string;
};

export const ExamJudgmentsDoctorView: React.FC = () => {
  const spd = useSpdCore();
  const szafir = useSzafir();
  const auth = useAuth();
  const [exam, setExam] = useState<GetExamDto | null | undefined>();

  const { id } = useParams<ParamTypes>() as { id: string };

  const [allArticles, setAllArticles] = useState<GetArticleDto[]>([]);

  useEffect(() => {
    if (auth.canSign) {
      szafir.activate(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.canSign]);

  useEffect(() => {
    let mounted = true;
    spd.exams
      .getExam(id)
      .then(res => {
        if (mounted) {
          setExam(res);
        }
      })
      .catch(error =>
        console.log(`Wystąpił problem z odświeżeniem strony. ${error}`),
      );

    return () => {
      mounted = false;
    };
  }, [id, spd.exams]);

  useEffect(() => {
    spd.articles
      .getAll(ArticleMode.Doctor)
      .then(article => setAllArticles(article))
      .catch(error =>
        console.log(`Wystąpił problem z pobraniem szablonu. ${error}`),
      );
  }, [spd.articles]);

  return (
    <>
      {exam && (
        <ExamJudgmentEditor
          exam={exam}
          articles={allArticles}
          roles={[Role.MedicalAssessor, Role.MedicalSpecialist]}
          mode={ArticleMode.Doctor}
        />
      )}
    </>
  );
};
