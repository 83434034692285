/* tslint:disable */
/* eslint-disable */
/**
 * SPD
 * Opis api SPD
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUpdateIndicatorTestDto
 */
export interface CreateUpdateIndicatorTestDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateIndicatorTestDto
     */
    testId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateIndicatorTestDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateIndicatorTestDto
     */
    mode?: CreateUpdateIndicatorTestDtoModeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateIndicatorTestDto
     */
    keyTestId?: string | null;
}

export function CreateUpdateIndicatorTestDtoFromJSON(json: any): CreateUpdateIndicatorTestDto {
    return CreateUpdateIndicatorTestDtoFromJSONTyped(json, false);
}

export function CreateUpdateIndicatorTestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateIndicatorTestDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'testId': !exists(json, 'testId') ? undefined : json['testId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'mode': !exists(json, 'mode') ? undefined : json['mode'],
        'keyTestId': !exists(json, 'keyTestId') ? undefined : json['keyTestId'],
    };
}

export function CreateUpdateIndicatorTestDtoToJSON(value?: CreateUpdateIndicatorTestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'testId': value.testId,
        'name': value.name,
        'mode': value.mode,
        'keyTestId': value.keyTestId,
    };
}

/**
* @export
* @enum {string}
*/
export enum CreateUpdateIndicatorTestDtoModeEnum {
    NumberOfCorrectAnswers = 'NumberOfCorrectAnswers',
    SumOfPresentationTime = 'SumOfPresentationTime'
}


