import React, { PropsWithChildren, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useAuth } from '../../common/hooks/useAuth';

import Main from './Main';
import { Footer } from './Footer';
import NavMain from './NavMain/NavMain';

const Layout: React.FC<PropsWithChildren> = ({ children }) => {
  const authFirebase = useAuth();

  useEffect(() => {
    refreshToken();
    const interval = setInterval(() => refreshToken(), 295000);
    return () => {
      clearInterval(interval);
    };
  });

  const refreshToken = () => {
    void authFirebase.ensureTokenIsValid();
  };

  return (
    <div className="page-container">
      {/* <CookieConsent
        location="bottom"
        buttonText="Rozumiem"
        cookieName="myAgreeForCookie"
        expires={150}
      >
        Ta strona używa ciasteczek.
      </CookieConsent> */}

      <NavMain activePage={useLocation().pathname} />
      <Main location={useLocation().pathname}>{children}</Main>
      <Footer />
    </div>
  );
};
export default Layout;
