import React, { useEffect, useState } from 'react';
import { Card, Tab } from 'react-bootstrap';

import { useCookies } from '../../../common/hooks/useCookies';
import { useSpdCore } from '../../../common/hooks/useSpdCore';
import { ExamsPackageSearch } from '../../components/ExamsPackage/ExamsPackageSearch';
import { useNavigation } from '../../../common/navigation';
import { TabsHistory } from '../../../app/components/TabsHistory';

export const ExamsPackageView: React.FC = () => {
  const spd = useSpdCore();
  const cookies = useCookies();
  const nav = useNavigation();

  const [refresh, setRefresh] = useState(true);

  useEffect(() => {
    if (cookies.activeMonitor !== '') {
      cookies.setActiveMonitor('');
    }
  }, [cookies]);

  useEffect(() => {
    const interval = setInterval(() => setRefresh(!refresh), 300000);
    return () => {
      clearInterval(interval);
    };
  }, [refresh]);

  const handleClick = async (id: string) => {
    nav.examsInPackage(id);
  };

  const onRefresh = () => {
    setRefresh(!refresh);
  };

  return (
    <Card>
      <TabsHistory
        activeKey="examsPackageMulitiple"
        id="examsPackage"
        className="mb-3"
      >
        <Tab
          eventKey="examsPackageMulitiple"
          title="Paczki badań zbiorcze"
          className="m-3"
        >
          <ExamsPackageSearch
            getData={(
              pageSize,
              pageNumber,
              orderBy,
              orderDirection,
              filterText,
            ) =>
              spd.examsPackage.getAllMulitipleByPortion(
                pageSize,
                pageNumber,
                orderBy,
                orderDirection,
                filterText,
                onRefresh,
              )
            }
            getCount={spd.examsPackage.getAllMulitipleCount}
            onRowClick={handleClick}
            refresh={refresh}
          />
        </Tab>
        <Tab eventKey="examsPackageSingle" title="pojedyncze" className="m-3">
          <ExamsPackageSearch
            getData={(
              pageSize,
              pageNumber,
              orderBy,
              orderDirection,
              filterText,
            ) =>
              spd.examsPackage.getAllSingleByPortion(
                pageSize,
                pageNumber,
                orderBy,
                orderDirection,
                filterText,
                onRefresh,
              )
            }
            getCount={spd.examsPackage.getAllSingleCount}
            onRowClick={handleClick}
            refresh={refresh}
          />
        </Tab>
      </TabsHistory>
    </Card>
  );
};
