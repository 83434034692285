import React, { useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import { Form } from 'react-bootstrap';

import FormRow from '../../../app/components/FormRow';
import { CreateUpdateExaminedDto } from '../../../common/spdCore/autogenerated/spdApiClient';
import { CreatableClearableSelector } from '../Selects/CreatableClearableSelector';
import { useSpdCore } from '../../../common/hooks/useSpdCore';

interface ExaminedEditorRightSideProps {
  propertyChange: (obj: Partial<CreateUpdateExaminedDto>) => void;
  readOnly: boolean;
  show?: boolean;
  examined: CreateUpdateExaminedDto;
}

export const ExaminedEditorRightSide: React.FC<
  ExaminedEditorRightSideProps
> = props => {
  const [examined, setExamined] = useState(props.examined);
  const [otherPostalAddress, setOtherPostalAddress] = useState(false);
  const spdCore = useSpdCore();

  useEffect(() => {
    if (props.show) {
      setOtherPostalAddress(props.examined?.postalAddress !== undefined);
      setExamined(props.examined);
    }
  }, [props.show, props.examined]);

  function onPostalCheckChanged(): void {
    setOtherPostalAddress(!otherPostalAddress);
  }

  const propertyChange = (obj: Partial<CreateUpdateExaminedDto>) => {
    setExamined({ ...examined, ...obj });
    props.propertyChange(obj);
  };

  const textOrEmpty = (text: string | null | undefined) => text || '';

  return (
    <Card>
      <Card.Header>Dodatkowe dane</Card.Header>
      <Card.Body>
        <Form className="d-grid gap-3">
          <FormRow controlId="positive" label="Wykształcenie">
            <Form.Control
              as={Form.Select}
              value={examined?.education || ''}
              onChange={e => propertyChange({ education: e.target.value })}
              disabled={props.readOnly}
            >
              <option value=""></option>
              <option value="Wyższe">Wyższe</option>
              <option value="Średnie">Średnie</option>
              <option value="Zasadnicze">Zasadnicze</option>
              <option value="Podstawowe">Podstawowe</option>
            </Form.Control>
          </FormRow>
          <FormRow controlId="learned_profession" label="Zawód wyuczony">
            <Form.Control
              type="text"
              value={textOrEmpty(examined?.learnedProfession)}
              onChange={e =>
                propertyChange({ learnedProfession: e.target.value })
              }
              disabled={props.readOnly}
            />
          </FormRow>
          <FormRow controlId="postcode" label="Kod pocztowy">
            <Form.Control
              type="text"
              value={textOrEmpty(examined?.postcode)}
              onChange={e => propertyChange({ postcode: e.target.value })}
              disabled={props.readOnly}
            />
          </FormRow>
          <FormRow controlId="city" label="Miejscowość">
            <CreatableClearableSelector
              value={textOrEmpty(examined?.city)}
              onChange={chosen => propertyChange({ city: chosen.label })}
              provider={spdCore.examineds.getCitiesByName}
              readOnly={props.readOnly}
            />
          </FormRow>
          <FormRow controlId="street" label="Ulica">
            <CreatableClearableSelector
              value={textOrEmpty(examined?.street)}
              onChange={chosen => propertyChange({ street: chosen.label })}
              provider={spdCore.examineds.getStreetsByName}
              readOnly={props.readOnly}
            />
          </FormRow>
          <FormRow controlId="numbers" label=" Numer">
            <Form.Control
              type="text"
              value={textOrEmpty(examined?.numbers)}
              onChange={e => propertyChange({ numbers: e.target.value })}
              disabled={props.readOnly}
            />
          </FormRow>
          {!props.readOnly && (
            <FormRow controlId="postalAddressChck" label="">
              <Form.Check
                checked={otherPostalAddress}
                label="Inny adres korespondencyjny"
                onChange={() => onPostalCheckChanged()}
              />
            </FormRow>
          )}
          {otherPostalAddress && (
            <FormRow controlId="postalAddress" label="Adres korespond.">
              <Form.Control
                as="textarea"
                rows={3}
                value={textOrEmpty(examined?.postalAddress)}
                onChange={e =>
                  propertyChange({ postalAddress: e.target.value })
                }
                disabled={props.readOnly || !otherPostalAddress}
              />
            </FormRow>
          )}
          <FormRow controlId="email" label="Email">
            <Form.Control
              type="email"
              value={textOrEmpty(examined?.email)}
              onChange={e => propertyChange({ email: e.target.value })}
              disabled={props.readOnly}
            />
          </FormRow>
          <FormRow controlId="phone" label="Telefon">
            <Form.Control
              type="text"
              value={textOrEmpty(examined?.phone)}
              onChange={e => propertyChange({ phone: e.target.value })}
              disabled={props.readOnly}
            />
          </FormRow>
        </Form>
      </Card.Body>
    </Card>
  );
};
