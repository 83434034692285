/* tslint:disable */
/* eslint-disable */
/**
 * SPD
 * Opis api SPD
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetPdfTemplateDto,
    GetPdfTemplateDtoFromJSON,
    GetPdfTemplateDtoFromJSONTyped,
    GetPdfTemplateDtoToJSON,
    GetUserDto,
    GetUserDtoFromJSON,
    GetUserDtoFromJSONTyped,
    GetUserDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetSavedPdfExternalReferralDto
 */
export interface GetSavedPdfExternalReferralDto {
    /**
     * 
     * @type {string}
     * @memberof GetSavedPdfExternalReferralDto
     */
    id: string;
    /**
     * 
     * @type {GetPdfTemplateDto}
     * @memberof GetSavedPdfExternalReferralDto
     */
    pdfTemplate: GetPdfTemplateDto;
    /**
     * 
     * @type {string}
     * @memberof GetSavedPdfExternalReferralDto
     */
    examNumber: string;
    /**
     * 
     * @type {Date}
     * @memberof GetSavedPdfExternalReferralDto
     */
    creationDate: Date;
    /**
     * 
     * @type {GetUserDto}
     * @memberof GetSavedPdfExternalReferralDto
     */
    creator: GetUserDto;
}

export function GetSavedPdfExternalReferralDtoFromJSON(json: any): GetSavedPdfExternalReferralDto {
    return GetSavedPdfExternalReferralDtoFromJSONTyped(json, false);
}

export function GetSavedPdfExternalReferralDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSavedPdfExternalReferralDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'pdfTemplate': GetPdfTemplateDtoFromJSON(json['pdfTemplate']),
        'examNumber': json['examNumber'],
        'creationDate': (new Date(json['creationDate'])),
        'creator': GetUserDtoFromJSON(json['creator']),
    };
}

export function GetSavedPdfExternalReferralDtoToJSON(value?: GetSavedPdfExternalReferralDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'pdfTemplate': GetPdfTemplateDtoToJSON(value.pdfTemplate),
        'examNumber': value.examNumber,
        'creationDate': (value.creationDate.toISOString()),
        'creator': GetUserDtoToJSON(value.creator),
    };
}


