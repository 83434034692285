import React from 'react';
import { NavDropdown } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';

import { MenuItem, useNavigation } from '../../../common/navigation';

interface MainNavSectionProps {
  className: string;
  heading?: string;
  items?: MenuItem[];
  path: string;
  activePage: string;
  hr?: boolean;
}

export const MainNavSection: React.FC<MainNavSectionProps> = (
  props: MainNavSectionProps,
) => {
  const nav = useNavigation();
  const isActive = (path: string) => props.activePage.startsWith(path);
  const active = isActive(props.path);
  const firstLevelActive =
    active &&
    (!props.items ||
      props.items.filter(sub => isActive(sub.path)).length === 0);

  const firstLevelClassName = 'mainbarlink ' + props.className;

  return (
    <>
      {props.items && props.items?.length > 0 ? (
        <NavDropdown
          className={firstLevelClassName}
          title={props.heading}
          id={props.path}
        >
          {props.items.map((item, key) =>
            item.hr ? (
              <NavDropdown.Divider key={key} />
            ) : (
              <NavDropdown.Item
                key={item.path}
                eventKey={item.path}
                href={item.path + '#'}
                onClick={() => nav.menuItem(item.path)}
              >
                {item.heading}
              </NavDropdown.Item>
            ),
          )}
        </NavDropdown>
      ) : (
        <Nav.Link
          active={firstLevelActive}
          href={props.path + '#'}
          onClick={() => nav.menuItem(props.path)}
          className={firstLevelClassName}
        >
          <span className="toc-link-heading">{props.heading}</span>
        </Nav.Link>
      )}
    </>
  );
};
