import { DefaultApi } from '../autogenerated/spdApiClient/apis/DefaultApi';
import {
  CreateUpdateKeyTestDto,
  CreateUpdateKeyTestDtoFromJSON,
  GetKeyTestDto,
} from '../autogenerated/spdApiClient';
import { validateAgainst } from '../validation/validateAgainst';
import { KeyTestSchema } from '../validation/schemas';
import { keyTestForGrid } from '../../../spd/components/KeyTests/KeyTests';
import { GridRecord } from '../../components/Grid/GridDataTypes';
import { CreateUpdateKeyTestWithKeysDto } from '../autogenerated/spdApiClient';

export type KeyTestEntity = GetKeyTestDto;

export interface KeyTestContextInterface {
  get: (id: string) => Promise<KeyTestEntity>;
  getAllCount: (filterText?: string) => Promise<number>;
  getAllByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
  ) => Promise<GridRecord[]>;
  update: (id: string, dto: CreateUpdateKeyTestDto) => Promise<void>;
  create: (dto: CreateUpdateKeyTestDto) => Promise<GetKeyTestDto>;
  createWithValues: (
    dto: CreateUpdateKeyTestWithKeysDto,
  ) => Promise<GetKeyTestDto>;
}

export const KeyTestContext = (api: DefaultApi) => {
  return {
    get: async (id: string) => {
      return await api.keyTestControllerGet(id);
    },

    getAllByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
    ) => {
      const data = await api.keyTestControllerGetAllByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
      );
      return data.map(keyTestForGrid);
    },

    getAllCount: (filterText?: string) =>
      api.keyTestControllerGetAllCount(filterText || ''),

    update: (id: string, dto: CreateUpdateKeyTestDto) =>
      api.keyTestControllerUpdate(id, dto),

    create: (dto: CreateUpdateKeyTestDto) => api.keyTestControllerCreate(dto),

    createWithValues: (dto: CreateUpdateKeyTestWithKeysDto) =>
      api.keyTestControllerCreateWithValues(dto),
  };
};

export const validate = (test: CreateUpdateKeyTestDto) =>
  validateAgainst(KeyTestSchema, test);

export const keyTestData = (test?: GetKeyTestDto): CreateUpdateKeyTestDto => {
  return CreateUpdateKeyTestDtoFromJSON({
    ...test,
  });
};

export const keyTestWithValuesData = (
  test?: GetKeyTestDto,
): CreateUpdateKeyTestWithKeysDto => {
  return CreateUpdateKeyTestDtoFromJSON({
    ...test,
  });
};
