import { DefaultApi } from '../autogenerated/spdApiClient/apis/DefaultApi';
import { GridRecord } from '../../components/Grid/GridDataTypes';
import { validateAgainst } from '../validation/validateAgainst';
import {
  CreateUpdateSupportingExaminationDictDto,
  CreateUpdateSupportingExaminationDictDtoFromJSON,
} from '../autogenerated/spdApiClient/models/CreateUpdateSupportingExaminationDictDto';
import { GetSupportingExaminationDictDto } from '../autogenerated/spdApiClient/models/GetSupportingExaminationDictDto';
import { SupportingExaminationDictSchema } from '../validation/schemas';
import { supportingExaminationDictForGrid } from '../../../spd/components/SupportingExaminationDict/SupportingExaminationDictSearch';

export interface SupportingExaminationDictContextInterface {
  get: (id: string) => Promise<GetSupportingExaminationDictDto>;
  getAll: () => Promise<GetSupportingExaminationDictDto[]>;
  getAllCount: (filterText?: string) => Promise<number>;
  getAllByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
  ) => Promise<GridRecord[]>;
  update: (
    id: string,
    dto: CreateUpdateSupportingExaminationDictDto,
  ) => Promise<void>;
  create: (
    dto: CreateUpdateSupportingExaminationDictDto,
  ) => Promise<GetSupportingExaminationDictDto>;
}

export const SupportingExaminationDictContext = (api: DefaultApi) => {
  return {
    get: async (id: string) => {
      return await api.supportingExaminationDictControllerGet(id);
    },

    getAll: () => api.supportingExaminationDictControllerGetAll(),

    getAllByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
    ) => {
      const data = await api.supportingExaminationDictControllerGetAllByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
      );
      return data.map(supportingExaminationDictForGrid);
    },

    getAllCount: (filterText?: string) =>
      api.supportingExaminationDictControllerGetAllCount(filterText || ''),

    update: (id: string, dto: CreateUpdateSupportingExaminationDictDto) =>
      api.supportingExaminationDictControllerUpdate(id, dto),

    create: (dto: CreateUpdateSupportingExaminationDictDto) =>
      api.supportingExaminationDictControllerCreate(dto),
  };
};

export const validate = (
  supportingExamDict: CreateUpdateSupportingExaminationDictDto,
) => {
  return validateAgainst(SupportingExaminationDictSchema, supportingExamDict);
};

export const supportingExaminationDictData = (
  supportingExamDict?: GetSupportingExaminationDictDto,
): CreateUpdateSupportingExaminationDictDto => {
  if (!supportingExamDict) {
    return CreateUpdateSupportingExaminationDictDtoFromJSON({ active: true });
  }
  return CreateUpdateSupportingExaminationDictDtoFromJSON({
    ...supportingExamDict,
  });
};
