/* tslint:disable */
/* eslint-disable */
/**
 * SPD
 * Opis api SPD
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUpdateCompanyDto
 */
export interface CreateUpdateCompanyDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCompanyDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCompanyDto
     */
    address?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCompanyDto
     */
    nip?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCompanyDto
     */
    email?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateCompanyDto
     */
    externalReferral?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCompanyDto
     */
    branchId?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateUpdateCompanyDto
     */
    batteries?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCompanyDto
     */
    healthcareFacilityId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateCompanyDto
     */
    monthlySettlement?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateCompanyDto
     */
    active?: boolean | null;
}

export function CreateUpdateCompanyDtoFromJSON(json: any): CreateUpdateCompanyDto {
    return CreateUpdateCompanyDtoFromJSONTyped(json, false);
}

export function CreateUpdateCompanyDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateCompanyDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'nip': !exists(json, 'nip') ? undefined : json['nip'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'externalReferral': !exists(json, 'externalReferral') ? undefined : json['externalReferral'],
        'branchId': !exists(json, 'branchId') ? undefined : json['branchId'],
        'batteries': !exists(json, 'batteries') ? undefined : json['batteries'],
        'healthcareFacilityId': !exists(json, 'healthcareFacilityId') ? undefined : json['healthcareFacilityId'],
        'monthlySettlement': !exists(json, 'monthlySettlement') ? undefined : json['monthlySettlement'],
        'active': !exists(json, 'active') ? undefined : json['active'],
    };
}

export function CreateUpdateCompanyDtoToJSON(value?: CreateUpdateCompanyDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'address': value.address,
        'nip': value.nip,
        'email': value.email,
        'externalReferral': value.externalReferral,
        'branchId': value.branchId,
        'batteries': value.batteries,
        'healthcareFacilityId': value.healthcareFacilityId,
        'monthlySettlement': value.monthlySettlement,
        'active': value.active,
    };
}


