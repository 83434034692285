/* tslint:disable */
/* eslint-disable */
/**
 * SPD
 * Opis api SPD
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetReportJudgmentDto
 */
export interface GetReportJudgmentDto {
    /**
     * 
     * @type {string}
     * @memberof GetReportJudgmentDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetReportJudgmentDto
     */
    psychologistName: string;
    /**
     * 
     * @type {string}
     * @memberof GetReportJudgmentDto
     */
    psychologistId: string;
    /**
     * 
     * @type {string}
     * @memberof GetReportJudgmentDto
     */
    registerName: string;
    /**
     * 
     * @type {string}
     * @memberof GetReportJudgmentDto
     */
    registerId: string;
    /**
     * 
     * @type {string}
     * @memberof GetReportJudgmentDto
     */
    healthcareFacilityName: string;
    /**
     * 
     * @type {string}
     * @memberof GetReportJudgmentDto
     */
    healthcareFacilityId: string;
    /**
     * 
     * @type {number}
     * @memberof GetReportJudgmentDto
     */
    countJudgments: number;
}

export function GetReportJudgmentDtoFromJSON(json: any): GetReportJudgmentDto {
    return GetReportJudgmentDtoFromJSONTyped(json, false);
}

export function GetReportJudgmentDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetReportJudgmentDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'psychologistName': json['psychologistName'],
        'psychologistId': json['psychologistId'],
        'registerName': json['registerName'],
        'registerId': json['registerId'],
        'healthcareFacilityName': json['healthcareFacilityName'],
        'healthcareFacilityId': json['healthcareFacilityId'],
        'countJudgments': json['countJudgments'],
    };
}

export function GetReportJudgmentDtoToJSON(value?: GetReportJudgmentDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'psychologistName': value.psychologistName,
        'psychologistId': value.psychologistId,
        'registerName': value.registerName,
        'registerId': value.registerId,
        'healthcareFacilityName': value.healthcareFacilityName,
        'healthcareFacilityId': value.healthcareFacilityId,
        'countJudgments': value.countJudgments,
    };
}


