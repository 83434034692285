import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

import { useNavigation } from '../../../common/navigation';

export const ExamEditLink = (props: { examId: string }) => {
  const nav = useNavigation();

  const handleClick = () => {
    nav.examEditor(props.examId);
  };

  return (
    <>
      <OverlayTrigger overlay={<Tooltip>Edycja badania</Tooltip>}>
        <Button
          onClick={e => {
            e.stopPropagation();
            handleClick();
          }}
          variant="btn-info"
        >
          <Icon.Pencil />
        </Button>
      </OverlayTrigger>
    </>
  );
};
