import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';

import ValidationAlert from '../../../app/components/ValidationAlert';

export type Props = {
  onSavePin: (newPin: string) => Promise<void>;
};

export const SavePin: React.FC<Props> = ({ onSavePin }) => {
  const [newPin, setNewPin] = useState('');
  const [pinError, setPinError] = useState('');
  const [showError, setShowError] = useState(false);
  const [errorText, setErrorText] = useState('');

  const showMessage = (txt: string) => {
    setErrorText(txt);
    setShowError(true);
  };

  const handleSavePin = async () => {
    setPinError('');
    if (!newPin) {
      setPinError('Wprowadź PIN.');
      return;
    }

    onSavePin(newPin)
      .then(() => {
        showMessage('PIN został zapisany.');
      })
      .catch(err => {
        showMessage(`Nie udało się zapisać PINu. ${(err as Error).message}`);
      });
  };

  return (
    <>
      <main className="form-signin">
        <Form>
          <h3 className="fw-normal mb-3">Zapisz PIN</h3>
          <h5 className="fw-normal">
            Wprowadź PIN do podpisu elektronicznego:
          </h5>
          <Form.Group className="form-floating mt-3" controlId="newPin">
            <Form.Control
              type="text"
              placeholder="Pin"
              onChange={e => setNewPin(e.target.value)}
            />
            <Form.Label>Podaj PIN</Form.Label>
          </Form.Group>
          <span className="input-form-error">{pinError}</span>
        </Form>
        <Button
          type="submit"
          className="w-100 btn btn-lg btn-login mt-3"
          onClick={handleSavePin}
        >
          Zapisz PIN
        </Button>
      </main>
      <ValidationAlert
        heading={'Wprowadzanie PIN'}
        variant={'warning'}
        show={showError}
        modal={true}
        onHide={() => {
          setShowError(false);
        }}
        errors={[errorText]}
      />
    </>
  );
};
