/* tslint:disable */
/* eslint-disable */
/**
 * SPD
 * Opis api SPD
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetBatteryDto
 */
export interface GetBatteryDto {
    /**
     * 
     * @type {string}
     * @memberof GetBatteryDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetBatteryDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetBatteryDto
     */
    description?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetBatteryDto
     */
    tests?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetBatteryDto
     */
    articlePsychologistsIds?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetBatteryDto
     */
    articleDoctorsIds?: Array<string> | null;
}

export function GetBatteryDtoFromJSON(json: any): GetBatteryDto {
    return GetBatteryDtoFromJSONTyped(json, false);
}

export function GetBatteryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetBatteryDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'tests': !exists(json, 'tests') ? undefined : json['tests'],
        'articlePsychologistsIds': !exists(json, 'articlePsychologistsIds') ? undefined : json['articlePsychologistsIds'],
        'articleDoctorsIds': !exists(json, 'articleDoctorsIds') ? undefined : json['articleDoctorsIds'],
    };
}

export function GetBatteryDtoToJSON(value?: GetBatteryDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'tests': value.tests,
        'articlePsychologistsIds': value.articlePsychologistsIds,
        'articleDoctorsIds': value.articleDoctorsIds,
    };
}


