import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';

import { useSpdCore } from '../../../common/hooks/useSpdCore';
import { GetExaminedDto } from '../../../common/spdCore/autogenerated/spdApiClient';
import { examinedData } from '../../../common/spdCore/contexts/ExaminedContext';

import { ExaminedEditor } from './ExaminedEditor';

export const ExaminedPreviewButton = (props: { examinedId: string }) => {
  const spd = useSpdCore();
  const [show, setShow] = useState(false);
  const [examined, setExamined] = useState<GetExaminedDto | null | undefined>();

  useEffect(() => {
    spd.examineds
      .getExamined(props.examinedId)
      .then(o => {
        setExamined(o);
      })
      .catch(() => {
        console.error('Error during get examined.');
      });
  }, [spd.examineds, props.examinedId]);

  const handleClick = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  return (
    <>
      <div className="d-flex justify-content-end">
        <Button
          onClick={() => {
            handleClick();
          }}
          variant="outline-primary"
        >
          Podgląd badanego
        </Button>
      </div>
      {examined && (
        <ExaminedEditor
          id={examined?.id}
          examined={examinedData(examined)}
          show={show}
          handleClose={handleClose}
          readOnly={true}
        />
      )}
    </>
  );
};
