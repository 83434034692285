import React, { PropsWithChildren } from 'react';

import { SzafirService } from './szafirService';

export const SzafirServiceContext = React.createContext(new SzafirService());

export const SzafirProvider: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <SzafirServiceContext.Provider value={new SzafirService()}>
      {children}
    </SzafirServiceContext.Provider>
  );
};
