/*eslint max-lines-per-function: ["error", 395]*/
/* eslint-disable complexity */
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card';
import { Form } from 'react-bootstrap';

import ValidationAlert from '../../../app/components/ValidationAlert';
import FormRow from '../../../app/components/FormRow';
import {
  CreateUpdateSavedPdfDtoPdfTemplateTypeEnum,
  CreateUpdateSupportingExaminationDto,
  CreateUpdateSupportingExaminationDtoPdfTemplateTypeEnum,
} from '../../../common/spdCore/autogenerated/spdApiClient';
import ConfirmationButton from '../../../app/components/ConfirmationButton';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { useSpdCore } from '../../../common/hooks/useSpdCore';
import momencik from '../../../common/momencik';
import {
  SupportingType,
  TemplateType,
} from '../../../common/spdCore/validation/schemas';
import SupportingExaminationDictMultiSelector from '../SupportingExaminationDict/SupportingExaminationDictMultiSelector';
import { useAuth } from '../../../common/hooks/useAuth';
import { useSzafir } from '../../../common/hooks/useSzafir';
import { responseErrors } from '../../../common/spdCore/validation/responseErrors';

interface SupportingExaminationEditorProps {
  id?: string;
  cardId?: string;
  support: CreateUpdateSupportingExaminationDto;
  handleClose?: () => void;
  show?: boolean;
  onCompleted: (
    support: CreateUpdateSupportingExaminationDto,
    id?: string,
  ) => Promise<{
    id?: string;
    saved: boolean;
    errors: string[];
  }>;
  type: SupportingType;
}

export const SupportingExaminationEditor: React.FC<
  SupportingExaminationEditorProps
> = props => {
  const [supportingExamination, setSupportingExamination] = useState(
    props.support,
  );
  const [showSaveErrors, setShowSaveErrors] = useState(false);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const notifications = useNotifications();
  const spd = useSpdCore();
  const [savingInProgress, setSavingInProgress] = useState(false);
  const auth = useAuth();
  const szafir = useSzafir();

  useEffect(() => {
    if (props.show) {
      setSaveErrors([]);
      setShowSaveErrors(false);
      setSupportingExamination(props.support);
    }
  }, [props.show, props.support]);

  const createUpdateSupportingExamination = async () => {
    setSavingInProgress(true);
    props
      .onCompleted(supportingExamination, props.id)
      .then(async saveStatus => {
        if (!saveStatus.saved) {
          setSaveErrors(saveStatus.errors);
          setShowSaveErrors(true);
          return;
        }
        if (supportingExamination.printReferral) {
          if (!auth.canSign || props.support.withoutSign) {
            if (saveStatus?.id) {
              await spd.pdfPrinter.saveAndPrintSupportingExamination(
                saveStatus?.id,
              );
            }
            setSavingInProgress(false);
          } else {
            saveStatus?.id &&
              void spd.pdfPrinter
                .saveWithoutPrintSupportingExamination(saveStatus?.id)
                .then(async () => {
                  if (saveStatus?.id) {
                    await signAndPrint(saveStatus?.id);
                  }
                });
          }
        }
      })
      .catch(error => {
        setSavingInProgress(false);
        setSaveErrors(error.errors);
        setShowSaveErrors(true);
      });
  };

  const signAndPrint = async (supportingId: string) => {
    try {
      const taskList = await spd.szafir.getTaskListForSupportingExamination(
        supportingId,
        auth.userProfile?.id,
      );
      await szafir.signDocument(
        taskList,
        auth.userProfile?.pin || '',
        supportingId,
        '',
        false,
        saveSignedPdf,
        signedFailed,
      );
    } catch (err) {
      const errors = await responseErrors(err as Response);
      setSaveErrors([`Błąd pobierania listy 'TaskList':\n ${errors}`]);
      setShowSaveErrors(true);
      setSavingInProgress(false);
    }
  };

  const saveSignedPdf = async (result: Buffer | null, supportingId: string) => {
    spd.savedPdfs
      .savePdfFileForReferral({
        entityId: supportingId,
        file: result,
        pdfTemplateType:
          TemplateType.ClinicReferral as unknown as CreateUpdateSavedPdfDtoPdfTemplateTypeEnum,
      })
      .then(async () => {
        await spd.emails.sendReferralByEmail(
          supportingId,
          auth.userProfile?.id || '',
        );
      })
      .catch(async response => {
        const errors = await responseErrors(response as Response);
        setSaveErrors([`Błąd:\n ${errors}`]);
        setShowSaveErrors(true);
      })
      .finally(() => {
        setSavingInProgress(false);
      });
  };

  const signedFailed = (error: string) => {
    setSaveErrors([`Błąd podczas podpisywania orzeczenia:\n ${error}`]);
    setShowSaveErrors(true);
    setSavingInProgress(false);
  };

  const deleteSupportingExamination = async () => {
    if (props.id) {
      notifications.onPromise(spd.support.delete(props.id), props.handleClose);
    }
  };

  const propertyChange = (
    obj: Partial<CreateUpdateSupportingExaminationDto>,
  ) => {
    if (obj.printReferral) {
      obj.pdfTemplateType =
        TemplateType.ClinicReferral as unknown as CreateUpdateSupportingExaminationDtoPdfTemplateTypeEnum;
    } else if (obj.printReferral === false) {
      obj.pdfTemplateType = undefined;
    }
    setSupportingExamination({ ...supportingExamination, ...obj });
  };

  const viewDocument = () => {
    if (!props.id) {
      return;
    }
    spd.pdfPrinter.loadAndPrintSupportingExamination(props.id).catch(error => {
      setSaveErrors(error.errors || ['Błąd podczas drukowania dokumentu']);
      setShowSaveErrors(true);
    });
  };

  return (
    <Modal
      onHide={props.handleClose}
      show={props.show}
      centered={true}
      keyboard={true}
      backdrop="static"
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {props.id
            ? props.type === SupportingType.helpExam
              ? 'Edycja badania pomocniczego'
              : 'Edycja konsultacji specjalistycznych'
            : props.type === SupportingType.helpExam
            ? 'Dodawanie badania pomocniczego'
            : 'Dodawanie konsultacji specjalistycznych'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Card className="mb-2">
          <Card.Header>
            {props.type === SupportingType.helpExam
              ? 'Badanie pomocnicze'
              : 'Konsultacja specjalistyczna'}
          </Card.Header>
          <Card.Body>
            <Form className="d-grid gap-3">
              <FormRow controlId="supportingExamination_lp" label="Lp">
                <Form.Control
                  type="number"
                  value={supportingExamination?.lp || 0}
                  onChange={e => propertyChange({ lp: +e.target.value })}
                />
              </FormRow>
              <FormRow
                controlId="supportingExamDict"
                label={
                  props.type === SupportingType.helpExam
                    ? 'Rodzaj badania'
                    : 'Skierowanie do specjalisty'
                }
              >
                <SupportingExaminationDictMultiSelector
                  multi={false}
                  value={supportingExamination?.examKindDictId || ''}
                  onChange={option =>
                    propertyChange({ examKindDictId: option.value })
                  }
                  type={props.type}
                />
              </FormRow>
              <FormRow
                controlId="supportingExamination_examKind"
                label="Dodatkowy opis"
              >
                <Form.Control
                  as="textarea"
                  rows={1}
                  value={supportingExamination?.examKind || ''}
                  onChange={e => propertyChange({ examKind: e.target.value })}
                />
              </FormRow>
              <FormRow controlId="referralDate" label="Data skierowania">
                <Form.Control
                  type="date"
                  name="datepic"
                  placeholder="DateRange"
                  value={momencik(
                    supportingExamination?.referralDate,
                    'YYYY-MM-DD',
                  )}
                  onChange={e =>
                    propertyChange({
                      referralDate: new Date(e.target.value),
                    })
                  }
                />
              </FormRow>
              {props.cardId && (
                <FormRow controlId="printReferral" label="Wydruk skierowania">
                  <Form.Check
                    type="checkbox"
                    checked={
                      supportingExamination?.printReferral ? true : false
                    }
                    onChange={e =>
                      propertyChange({
                        printReferral: e.target.checked,
                      })
                    }
                  />
                </FormRow>
              )}
              {supportingExamination?.printReferral && (
                <>
                  <FormRow
                    controlId="withoutSign"
                    label="Bez podpisu elektronicznego"
                  >
                    <Form.Check
                      type="checkbox"
                      checked={
                        supportingExamination?.withoutSign ? true : false
                      }
                      onChange={e =>
                        propertyChange({
                          withoutSign: e.target.checked,
                        })
                      }
                    />
                  </FormRow>
                  <FormRow
                    controlId="supportingExamination_diagnosis"
                    label="Rozpoznanie"
                  >
                    <Form.Control
                      as="textarea"
                      rows={1}
                      value={supportingExamination?.diagnosis || ''}
                      onChange={e =>
                        propertyChange({ diagnosis: e.target.value })
                      }
                    />
                  </FormRow>
                  <FormRow
                    controlId="supportingExamination_codeICD"
                    label="Kod (ICD 10)"
                  >
                    <Form.Control
                      as="textarea"
                      rows={1}
                      value={supportingExamination?.codeICD || ''}
                      onChange={e =>
                        propertyChange({ codeICD: e.target.value })
                      }
                    />
                  </FormRow>
                  <FormRow
                    controlId="supportingExamination_purpose"
                    label="Cel badania (uzasadnienie)"
                  >
                    <Form.Control
                      as="textarea"
                      rows={1}
                      value={supportingExamination?.purpose || ''}
                      onChange={e =>
                        propertyChange({ purpose: e.target.value })
                      }
                    />
                  </FormRow>
                </>
              )}
              <FormRow
                controlId="consultationDate"
                label={
                  props.type === SupportingType.helpExam
                    ? 'Data wykonania'
                    : 'Data konsultacji'
                }
              >
                <Form.Control
                  type="date"
                  name="datepic"
                  placeholder="DateRange"
                  value={momencik(
                    supportingExamination?.consultationDate,
                    'YYYY-MM-DD',
                  )}
                  onChange={e =>
                    propertyChange({
                      consultationDate: new Date(e.target.value),
                    })
                  }
                />
              </FormRow>
              <FormRow
                controlId="supportingExamination_result"
                label={
                  props.type === SupportingType.helpExam
                    ? 'Wynik badania'
                    : 'Wynik konsultacji'
                }
              >
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={supportingExamination?.result || ''}
                  onChange={e => propertyChange({ result: e.target.value })}
                />
                <div className="d-flex justify-content-end p-2">
                  <Button
                    variant="outline-success"
                    onClick={() =>
                      propertyChange({
                        result: `${
                          supportingExamination.result || ''
                        } W wyniku konsultacji specjalistycznej nie stwierdzono przeciwwskazań do pracy przy komputerze powyżej 4 godzin dziennie.`,
                      })
                    }
                  >
                    Wynik (komputer)
                  </Button>
                </div>
              </FormRow>
            </Form>
          </Card.Body>
        </Card>
        <ValidationAlert
          show={showSaveErrors}
          errors={saveErrors}
          className="mt-3"
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={props.handleClose}>
          Zamknij
        </Button>
        {props.id && (
          <ConfirmationButton
            className="mx-1"
            confirmation={
              props.type === SupportingType.helpExam
                ? 'Czy na pewno usunąć badanie pomocnicze'
                : 'Czy na pewno usunąć konsultację specjalistyczną'
            }
            variant="outline-danger"
            onOK={deleteSupportingExamination}
          >
            Usuń
          </ConfirmationButton>
        )}
        <Button
          variant="outline-primary"
          onClick={createUpdateSupportingExamination}
        >
          Zapisz
        </Button>
        {props.id &&
          supportingExamination.printReferral &&
          !savingInProgress && (
            <Button
              variant="outline-primary"
              className="ms-1"
              onClick={() => viewDocument()}
            >
              Pokaż skierowanie
            </Button>
          )}
      </Modal.Footer>
    </Modal>
  );
};
