import { DefaultApi } from '../autogenerated/spdApiClient/apis/DefaultApi';
import {
  CreateUpdateHealthcareFacilityDto,
  CreateUpdateHealthcareFacilityDtoFromJSON,
  GetHealthcareFacilityDto,
} from '../autogenerated/spdApiClient';
import { healthcareFacilityForGrid } from '../../../spd/components/HealthcareFacility/HealthcareFacilitySearch';
import { GridRecord } from '../../components/Grid/GridDataTypes';
import { validateAgainst } from '../validation/validateAgainst';
import { HealthcareFacilitySchema } from '../validation/schemas';

export interface HealthcareFacilityContextInterface {
  get: (id: string) => Promise<GetHealthcareFacilityDto>;
  getDefault: () => Promise<GetHealthcareFacilityDto>;
  getAll: () => Promise<GetHealthcareFacilityDto[]>;
  getAllCount: (filterText?: string) => Promise<number>;
  getAllByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
  ) => Promise<GridRecord[]>;
  update: (id: string, dto: CreateUpdateHealthcareFacilityDto) => Promise<void>;
  create: (
    dto: CreateUpdateHealthcareFacilityDto,
  ) => Promise<GetHealthcareFacilityDto>;
}

export const HealthcareFacilityContext = (api: DefaultApi) => {
  return {
    get: async (id: string) => {
      return await api.healthcareFacilityControllerGet(id);
    },
    getDefault: async () => {
      return await api.healthcareFacilityControllerGetDefault();
    },
    getAll: async () => {
      return await api.healthcareFacilityControllerGetAll();
    },
    getAllByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
    ) => {
      const data = await api.healthcareFacilityControllerGetAllByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
      );
      return data.map(healthcareFacilityForGrid) as GridRecord[];
    },

    getAllCount: (filterText?: string) =>
      api.healthcareFacilityControllerGetAllCount(filterText || ''),

    update: (id: string, dto: CreateUpdateHealthcareFacilityDto) =>
      api.healthcareFacilityControllerUpdate(id, dto),

    create: (dto: CreateUpdateHealthcareFacilityDto) =>
      api.healthcareFacilityControllerCreate(dto),
  };
};

export const validate = (
  healthcareFacility: CreateUpdateHealthcareFacilityDto,
) => {
  return validateAgainst(HealthcareFacilitySchema, healthcareFacility);
};

export const healthcareFacilityData = (
  healthcareFacility?: GetHealthcareFacilityDto,
): CreateUpdateHealthcareFacilityDto => {
  return CreateUpdateHealthcareFacilityDtoFromJSON({
    ...healthcareFacility,
    dutyId: healthcareFacility?.duty?.id,
  });
};
