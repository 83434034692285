/* tslint:disable */
/* eslint-disable */
/**
 * SPD
 * Opis api SPD
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetReportConsultationDto
 */
export interface GetReportConsultationDto {
    /**
     * 
     * @type {string}
     * @memberof GetReportConsultationDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetReportConsultationDto
     */
    userName: string;
    /**
     * 
     * @type {string}
     * @memberof GetReportConsultationDto
     */
    type?: GetReportConsultationDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof GetReportConsultationDto
     */
    healthcareFacilityName: string;
    /**
     * 
     * @type {number}
     * @memberof GetReportConsultationDto
     */
    countConsultations: number;
}

export function GetReportConsultationDtoFromJSON(json: any): GetReportConsultationDto {
    return GetReportConsultationDtoFromJSONTyped(json, false);
}

export function GetReportConsultationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetReportConsultationDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'userName': json['userName'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'healthcareFacilityName': json['healthcareFacilityName'],
        'countConsultations': json['countConsultations'],
    };
}

export function GetReportConsultationDtoToJSON(value?: GetReportConsultationDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'userName': value.userName,
        'type': value.type,
        'healthcareFacilityName': value.healthcareFacilityName,
        'countConsultations': value.countConsultations,
    };
}

/**
* @export
* @enum {string}
*/
export enum GetReportConsultationDtoTypeEnum {
    Neurological = 'Neurological',
    Ophthalmologist = 'Ophthalmologist',
    ENT = 'ENT',
    Psychiatric = 'Psychiatric'
}


