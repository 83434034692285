/*eslint max-lines-per-function: ["error", 500]*/
/* eslint-disable complexity */
import React, { useState } from 'react';
import { Alert, Button, Card, Form, Tab, Tabs } from 'react-bootstrap';

import FormRow from '../../../app/components/FormRow';
import {
  CreateUpdateConsultationDto,
  CreateUpdateConsultationDtoTypeEnum,
  CreateUpdateConsultationDtoPdfTemplateTypeEnum,
  GetExamDto,
} from '../../../common/spdCore/autogenerated/spdApiClient';
import { useSpdCore } from '../../../common/hooks/useSpdCore';
import {
  ConsultationType,
  TemplateType,
} from '../../../common/spdCore/validation/schemas';
import { FormControlTextarea3 } from '../../../app/components/FormControlTextarea';
import { ChooseSigned } from '../Users/ChooseSigned';
import { Role } from '../../../common/spdCore/authorization/roles';
import { responseErrors } from '../../../common/spdCore/validation/responseErrors';

interface ConsultationOphthalmologistEditorProps {
  exam: GetExamDto;
  consultation: CreateUpdateConsultationDto;
  id: string | undefined;
}

export const ConsultationOphthalmologistEditor: React.FC<
  ConsultationOphthalmologistEditorProps
> = props => {
  const spd = useSpdCore();
  const [consultation, setConsultation] = useState<CreateUpdateConsultationDto>(
    props.consultation,
  );
  const [id, setId] = useState<string | undefined>(props.id);
  const [message, setMessage] = useState<string>('');

  const createConsultation = async (
    gConsultation: CreateUpdateConsultationDto,
  ) => {
    setMessage('');
    try {
      if (id) {
        await spd.consultations.update(id, gConsultation);
        setConsultation(gConsultation);

        await spd.pdfPrinter.saveAndPrintConsultation(
          id,
          TemplateType.Ophthalmologist_consultation,
        );
      } else {
        await spd.consultations
          .create({
            ...gConsultation,
            ...{
              pdfTemplateType:
                TemplateType.Ophthalmologist_consultation as unknown as CreateUpdateConsultationDtoPdfTemplateTypeEnum,
              examId: props?.exam?.id,
              type: ConsultationType.Ophthalmologist as unknown as CreateUpdateConsultationDtoTypeEnum,
              consultDate: new Date(),
            },
          })
          .then(async o => {
            setConsultation({
              ...o,
              ...{
                ...gConsultation,
                ...{
                  pdfTemplateType:
                    TemplateType.Ophthalmologist_consultation as unknown as CreateUpdateConsultationDtoPdfTemplateTypeEnum,
                  examId: props?.exam?.id,
                  type: ConsultationType.Ophthalmologist as unknown as CreateUpdateConsultationDtoTypeEnum,
                },
              },
            });
            setId(o.id);
            await spd.pdfPrinter.saveAndPrintConsultation(
              o.id,
              TemplateType.Ophthalmologist_consultation,
            );
          })
          .catch(err => {
            void responseErrors(err).then(errors => {
              setMessage(
                `Wystąpił problem z zapisem konsultacji okulistycznej:\n ${errors}`,
              );
            });
          });
      }
    } catch (response) {
      const error = await responseErrors(response as Response);
      setMessage(
        `Błąd podczas zapisywania konsultacji okulistycznej:\n ${error}`,
      );
    }
  };

  const propertyChange = (obj: Partial<CreateUpdateConsultationDto>) =>
    setConsultation({ ...consultation, ...obj });

  const defaultValue = (text: string | null | undefined) => text || '';

  return (
    <Card className="mb-2">
      <Card.Header>{`Konsultacja okulistyczna ${props.exam?.number}`}</Card.Header>
      <Card.Body>
        <Form className="d-grid gap-3">
          <Tabs defaultActiveKey="result" id="resultTab" className="mb-2">
            <Tab eventKey="result" title="Dane podstawowe" className="mb-2">
              <div className="d-grid gap-3">
                <FormRow controlId="pass" label="Wynik pozytywny">
                  <Form.Check
                    type="checkbox"
                    defaultChecked={
                      consultation?.pass ||
                      consultation?.pass === undefined ||
                      consultation?.pass === null
                        ? true
                        : false
                    }
                    onChange={e => propertyChange({ pass: e.target.checked })}
                  />
                </FormRow>
                <FormRow controlId="staff" label="Lekarz">
                  <ChooseSigned
                    userUID={consultation?.authorUID}
                    handleOnChange={user =>
                      setConsultation({
                        ...consultation,
                        authorUID: user?.uid,
                      })
                    }
                    roles={[Role.MedicalSpecialist]}
                  />
                </FormRow>
                <FormRow controlId="number" label="Numer">
                  <Form.Control
                    type="text"
                    defaultValue={defaultValue(consultation?.number)}
                    onChange={e =>
                      propertyChange({
                        number: e.target.value,
                      })
                    }
                  />
                </FormRow>
                <FormRow controlId="recognition" label="Rozpoznania">
                  <FormControlTextarea3
                    defaultValue={consultation?.recognition || ''}
                    onChange={e =>
                      propertyChange({ recognition: e.target.value })
                    }
                  />
                </FormRow>
                <FormRow
                  controlId="contraindications"
                  label="Okulistyczne przeciwskazania do pracy"
                >
                  <FormControlTextarea3
                    defaultValue={consultation?.contraindications || ''}
                    onChange={e =>
                      propertyChange({ contraindications: e.target.value })
                    }
                  />
                </FormRow>
              </div>
            </Tab>
            <Tab
              eventKey="physicalExamination"
              title="Badanie przedmiotowe #1"
              className="mb-2"
            >
              <FormRow
                controlId="acuityDistanceOd"
                label="Ostrość wzroku do dali bez korekcji i z korekcją własną. V od"
              >
                <Form.Control
                  type="text"
                  defaultValue={defaultValue(consultation?.acuityDistanceOd)}
                  onChange={e =>
                    propertyChange({
                      acuityDistanceOd: e.target.value,
                    })
                  }
                />
              </FormRow>
              <FormRow
                controlId="acuityDistanceOs"
                label="Ostrość wzroku do dali bez korekcji i z korekcją własną. V do"
              >
                <Form.Control
                  type="text"
                  defaultValue={defaultValue(consultation?.acuityDistanceOs)}
                  onChange={e =>
                    propertyChange({
                      acuityDistanceOs: e.target.value,
                    })
                  }
                />
              </FormRow>
              <FormRow
                controlId="acuityNearNod"
                label="Ostrość wzroku do bliży bez korekcji i z korekcją własną. S nod"
              >
                <Form.Control
                  type="text"
                  defaultValue={defaultValue(consultation?.acuityNearNod)}
                  onChange={e =>
                    propertyChange({
                      acuityNearNod: e.target.value,
                    })
                  }
                />
              </FormRow>
              <FormRow
                controlId="acuityNearNos"
                label="Ostrość wzroku do bliży bez korekcji i z korekcją własną. S nos"
              >
                <Form.Control
                  type="text"
                  defaultValue={defaultValue(consultation?.acuityNearNos)}
                  onChange={e =>
                    propertyChange({
                      acuityNearNos: e.target.value,
                    })
                  }
                />
              </FormRow>
              <FormRow
                controlId="pseudoisochromaticPlates"
                label="Tablice pseudoizochromatyczne"
              >
                <Form.Control
                  type="text"
                  defaultValue={defaultValue(
                    consultation?.pseudoisochromaticPlates,
                  )}
                  onChange={e =>
                    propertyChange({
                      pseudoisochromaticPlates: e.target.value,
                    })
                  }
                />
              </FormRow>
              <FormRow controlId="slitLamp" label="Lampa Wilczka:">
                <Form.Control
                  type="text"
                  defaultValue={defaultValue(consultation?.slitLamp)}
                  onChange={e =>
                    propertyChange({
                      slitLamp: e.target.value,
                    })
                  }
                />
              </FormRow>
            </Tab>
            <Tab eventKey="physicalExamination2" title=".. #2" className="mb-2">
              <FormRow controlId="spatialVision" label="Widzenie przestrzenne">
                <Form.Check
                  type="checkbox"
                  defaultChecked={consultation?.spatialVision ? true : false}
                  onChange={e =>
                    propertyChange({ spatialVision: e.target.checked })
                  }
                />
              </FormRow>
              <FormRow controlId="fieldOfVisionRight" label="Pole widzenia OP">
                <Form.Check
                  type="checkbox"
                  defaultChecked={
                    consultation?.fieldOfVisionRight ? true : false
                  }
                  onChange={e =>
                    propertyChange({ fieldOfVisionRight: e.target.checked })
                  }
                />
              </FormRow>
              <FormRow controlId="fieldOfVisionLeft" label="Pole widzenia OL">
                <Form.Check
                  type="checkbox"
                  defaultChecked={
                    consultation?.fieldOfVisionLeft ? true : false
                  }
                  onChange={e =>
                    propertyChange({ fieldOfVisionLeft: e.target.checked })
                  }
                />
              </FormRow>
              <FormRow
                controlId="eyeAlignment"
                label="Równowaga mięśniowa: Ustawienie gałek"
              >
                <Form.Check
                  inline
                  label="proste"
                  name="eyeAlignment"
                  type="radio"
                  id="eyeAlignmentId"
                  checked={
                    !consultation?.eyeAlignment ||
                    consultation?.eyeAlignment === 'proste'
                  }
                  onChange={() =>
                    propertyChange({
                      eyeAlignment: 'proste',
                    })
                  }
                />
                <Form.Check
                  inline
                  label="zbieżne"
                  name="eyeAlignment"
                  type="radio"
                  id="eyeAlignmentId2"
                  checked={consultation?.eyeAlignment === 'zbieżne'}
                  onChange={() =>
                    propertyChange({
                      eyeAlignment: 'zbieżne',
                    })
                  }
                />
                <Form.Check
                  inline
                  label="rozbieżne"
                  name="eyeAlignment"
                  type="radio"
                  id="eyeAlignmentId3"
                  checked={consultation?.eyeAlignment === 'rozbieżne'}
                  onChange={() =>
                    propertyChange({
                      eyeAlignment: 'rozbieżne',
                    })
                  }
                />
              </FormRow>
              <FormRow
                controlId="alternateCover"
                label="Równowaga mięśniowa: Test naprzemiennego zasłaniania"
              >
                <Form.Control
                  type="text"
                  defaultValue={defaultValue(consultation?.alternateCover)}
                  onChange={e =>
                    propertyChange({
                      alternateCover: e.target.value,
                    })
                  }
                />
              </FormRow>
              <FormRow
                controlId="convergence"
                label="Równowaga mięśniowa: Konwergencja"
              >
                <Form.Control
                  type="text"
                  defaultValue={defaultValue(consultation?.convergence)}
                  onChange={e =>
                    propertyChange({
                      convergence: e.target.value,
                    })
                  }
                />
              </FormRow>
            </Tab>
            <Tab eventKey="physicalExamination3" title=".. #3" className="mb-2">
              <div className="d-grid gap-3">
                <FormRow
                  controlId="acuityWithCorrectionOd"
                  label="Ostrość wzroku po korekcji wady: V od"
                >
                  <Form.Control
                    type="text"
                    defaultValue={defaultValue(
                      consultation?.acuityWithCorrectionOd,
                    )}
                    onChange={e =>
                      propertyChange({
                        acuityWithCorrectionOd: e.target.value,
                      })
                    }
                  />
                </FormRow>
                <FormRow
                  controlId="acuityWithCorrectionOs"
                  label="Ostrość wzroku po korekcji wady: V os"
                >
                  <Form.Control
                    type="text"
                    defaultValue={defaultValue(
                      consultation?.acuityWithCorrectionOs,
                    )}
                    onChange={e =>
                      propertyChange({
                        acuityWithCorrectionOs: e.target.value,
                      })
                    }
                  />
                </FormRow>
                <FormRow
                  controlId="protectiveDeviceConjunctiva"
                  label="Aparat ochronny spojówki"
                >
                  <Form.Control
                    type="text"
                    defaultValue={defaultValue(
                      consultation?.protectiveDeviceConjunctiva,
                    )}
                    onChange={e =>
                      propertyChange({
                        protectiveDeviceConjunctiva: e.target.value,
                      })
                    }
                  />
                </FormRow>
                <FormRow
                  controlId="protectiveDeviceEyelids"
                  label="Aparat ochronny powieki"
                >
                  <Form.Control
                    type="text"
                    defaultValue={defaultValue(
                      consultation?.protectiveDeviceEyelids,
                    )}
                    onChange={e =>
                      propertyChange({
                        protectiveDeviceEyelids: e.target.value,
                      })
                    }
                  />
                </FormRow>
                <FormRow controlId="anteriorSegment" label="Odcinek przedni">
                  <Form.Control
                    type="text"
                    defaultValue={defaultValue(consultation?.anteriorSegment)}
                    onChange={e =>
                      propertyChange({
                        anteriorSegment: e.target.value,
                      })
                    }
                  />
                </FormRow>
                <FormRow controlId="fundusOfEye" label="Dno oczu">
                  <Form.Control
                    type="text"
                    defaultValue={defaultValue(consultation?.fundusOfEye)}
                    onChange={e =>
                      propertyChange({
                        fundusOfEye: e.target.value,
                      })
                    }
                  />
                </FormRow>
              </div>
            </Tab>
          </Tabs>
          <Button
            variant="outline-primary"
            className="ms-1"
            onClick={() => createConsultation(consultation)}
          >
            Zapisz i wydrukuj
          </Button>
          {message && <Alert variant={'warning'}>{message}</Alert>}
        </Form>
      </Card.Body>
    </Card>
  );
};
