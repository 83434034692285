import React, { useState } from 'react';
import { Card } from 'react-bootstrap';

import { useSpdCore } from '../../../common/hooks/useSpdCore';
import { JudgmentsSearch } from '../../components/Judgments/JudgmentsSearch';
import { Filter } from '../RegistersView/Filter';
import { Role } from '../../../common/spdCore/authorization/roles';

export const JudgmentsWithoutElectronicSignView: React.FC = () => {
  const spd = useSpdCore();
  const [dateFrom, setDateFrom] = useState<Date | undefined>(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1, 0, 0, 0, 0),
  );
  const [dateTo, setDateTo] = useState<Date | undefined>();
  const [userUid, setUserUid] = useState<string | undefined>();
  const [refresh, setRefresh] = useState<boolean>(false);

  const onRefresh = () => {
    setRefresh(!refresh);
  };

  const handleOnExport = async (
    dateFrom: Date | undefined,
    dateTo: Date | undefined,
    userUid: string | undefined,
  ) => {
    setDateFrom(dateFrom);
    setDateTo(dateTo);
    setUserUid(userUid);
    await spd.pdfPrinter.printMultiplePdf(userUid, dateFrom, dateTo);
  };

  return (
    <>
      <Card>
        <Card.Header>{'Orzeczenia bez podpisu elektronicznego'}</Card.Header>
        <Card.Body>
          <Filter
            headerName={'Orzeczenia bez podpisu elektronicznego'}
            roles={[
              Role.MedicalAssessor,
              Role.MedicalSpecialist,
              Role.PsychologistAssessor,
              Role.PsychologistSpecialist,
            ]}
            withUser={true}
            withDate={true}
            withHealthcareFacility={false}
            userName={'Psycholog / Lekarz'}
            userUid={userUid}
            onChangeDateFrom={setDateFrom}
            onChangeDateTo={setDateTo}
            onChangeUserUid={setUserUid}
            onExport={handleOnExport}
          />
          <JudgmentsSearch
            getData={(
              pageSize,
              pageNumber,
              orderBy,
              orderDirection,
              filterText,
            ) =>
              spd.judgments.getWithoutElectronicSigningByPortion(
                pageSize,
                pageNumber,
                orderBy,
                orderDirection,
                filterText,
                userUid,
                dateFrom,
                dateTo,
                onRefresh,
              )
            }
            getCount={filterText =>
              spd.judgments.getWithoutElectronicSigningCount(
                filterText,
                userUid,
                dateFrom,
                dateTo,
              )
            }
            refresh={refresh}
          />
        </Card.Body>
      </Card>
    </>
  );
};
