import React from 'react';

import { Grid } from '../../../common/components/Grid/Grid';
import {
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import { GetCompanyDto } from '../../../common/spdCore/autogenerated/spdApiClient/models/GetCompanyDto';

interface CompanySearchProps {
  getData: GridGetterFunction;
  getCount: GridCountFunction;
  onRowClick: (key: string) => Promise<void> | void | undefined;
  refresh?: boolean;
  enableSingleSelect?: boolean;
  selectedKeys?: string[];
}

export const companyForGrid = (company: GetCompanyDto) => {
  return {
    key: company.id,
    values: {
      'company.name': company.name,
      'company.nip': company.nip,
      'company.email': company.email,
      'company.monthlySettlement': company.monthlySettlement ? 'Tak' : '',
      'company.externalReferral': company.externalReferral ? 'Tak' : 'Nie',
      'branch.name': company?.branch?.name,
      'healthcareFacility.name': company?.healthcareFacility?.displayName,
      'battery.name': company?.companiesBatteries
        ?.map(o => o?.battery?.name)
        .join(', '),
      'company.active': company.active ? 'Tak' : 'Nie',
    },
  };
};

export const CompanySearch: React.FC<CompanySearchProps> = props => {
  const columns = [
    { header: 'Nazwa', property: 'company.name' },
    { header: 'NIP', property: 'company.nip' },
    { header: 'Email', property: 'company.email' },
    { header: 'Rozliczana miesięcznie', property: 'company.monthlySettlement' },
    { header: 'Skierowania w systemie', property: 'company.externalReferral' },
    { header: 'Firma nadrzędna', property: 'branch.name' },
    { header: 'Placówka', property: 'healthcareFacility.name' },
    {
      header: 'Przypisane baterie',
      property: 'battery.name',
      noSorting: false,
    },
    {
      header: 'Aktywna',
      property: 'company.active',
      noSorting: false,
    },
  ];

  return (
    <Grid
      getData={props.getData}
      getDataCount={props.getCount}
      columns={columns}
      showFilter={true}
      initialOrderColumn="company.name"
      initialOrderDirection="ASC"
      availablePageSizes={[10, 20, 50]}
      onRowClick={props.onRowClick}
      emptyGridMessage="Brak firm w systemie"
      refresh={props.refresh}
      enableSingleSelect={props.enableSingleSelect}
      selectedKeys={props.selectedKeys}
    />
  );
};
