import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';

import { useNavigation } from '../../../common/navigation';
import { ExamSearch } from '../../components/Exam/ExamSearch';
import {
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import { useCookies } from '../../../common/hooks/useCookies';

interface ExamSearchProps {
  getData: GridGetterFunction;
  getCount: GridCountFunction;
  onRowClick: (key: string) => Promise<void> | void | undefined;
}

export const ExamsActiveView: React.FC<ExamSearchProps> = props => {
  const nav = useNavigation();
  const cookies = useCookies();

  const [refresh, setRefresh] = useState(true);

  useEffect(() => {
    if (cookies.activeTabMonitor === 'activeExams') {
      const interval = setInterval(() => setRefresh(!refresh), 30000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [refresh, cookies.activeTabMonitor]);

  return (
    <>
      <ExamSearch
        getData={props.getData}
        getCount={props.getCount}
        onRowClick={props.onRowClick}
        refresh={refresh}
      />
      {process.env.REACT_APP_CHAT_APP_URL && (
        <Button
          variant="outline-secondary"
          className="ms-1"
          onClick={nav.chats}
        >
          Podgląd badanych
        </Button>
      )}
    </>
  );
};
