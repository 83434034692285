/* tslint:disable */
/* eslint-disable */
/**
 * SPD
 * Opis api SPD
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetReactionDto,
    GetReactionDtoFromJSON,
    GetReactionDtoFromJSONTyped,
    GetReactionDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetResultForTestsDto
 */
export interface GetResultForTestsDto {
    /**
     * 
     * @type {string}
     * @memberof GetResultForTestsDto
     */
    taskNumber?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetResultForTestsDto
     */
    taskTime?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetResultForTestsDto
     */
    taskText?: string | null;
    /**
     * 
     * @type {Array<GetReactionDto>}
     * @memberof GetResultForTestsDto
     */
    reactions: Array<GetReactionDto>;
}

export function GetResultForTestsDtoFromJSON(json: any): GetResultForTestsDto {
    return GetResultForTestsDtoFromJSONTyped(json, false);
}

export function GetResultForTestsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetResultForTestsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'taskNumber': !exists(json, 'taskNumber') ? undefined : json['taskNumber'],
        'taskTime': !exists(json, 'taskTime') ? undefined : json['taskTime'],
        'taskText': !exists(json, 'taskText') ? undefined : json['taskText'],
        'reactions': ((json['reactions'] as Array<any>).map(GetReactionDtoFromJSON)),
    };
}

export function GetResultForTestsDtoToJSON(value?: GetResultForTestsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'taskNumber': value.taskNumber,
        'taskTime': value.taskTime,
        'taskText': value.taskText,
        'reactions': ((value.reactions as Array<any>).map(GetReactionDtoToJSON)),
    };
}


