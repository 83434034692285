import React from 'react';
import { Card, Tab } from 'react-bootstrap';

import Tabs from '../../../app/components/Tabs';
import { useSpdCore } from '../../../common/hooks/useSpdCore';
import { HealthcareFacilityList } from '../../components/HealthcareFacility/HealthcareFacilityList';

export const HealthcareFacilitiesView: React.FC = () => {
  const spd = useSpdCore();

  return (
    <Card>
      <Tabs defaultActiveKey="search" id="healthcareFacility" className="mb-3">
        <Tab eventKey="search" title="Placówki" className="m-3">
          <HealthcareFacilityList
            getData={spd.healthCareFacilities.getAllByPortion}
            getCount={spd.healthCareFacilities.getAllCount}
          />
        </Tab>
      </Tabs>
    </Card>
  );
};
