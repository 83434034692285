import { DefaultApi } from '../autogenerated/spdApiClient/apis/DefaultApi';

export interface SzafirContextInterface {
  getTaskListForJudgment: (
    documentId: string,
    english: boolean,
    userId?: string,
  ) => Promise<string>;
  getTaskListForSupportingExamination: (
    documentId: string,
    userId?: string,
  ) => Promise<string>;
}

export const SzafirContext = (api: DefaultApi) => {
  return {
    getTaskListForJudgment: (
      documentId: string,
      english: boolean,
      userId?: string,
    ) =>
      api.szafirControllerGetTaskListForJudgment(documentId, english, userId),
    getTaskListForSupportingExamination: (
      documentId: string,
      userId?: string,
    ) =>
      api.szafirControllerGetTaskListForSupportingExamination(
        documentId,
        userId,
      ),
  };
};
