/*eslint max-lines-per-function: ["error", 180]*/
import React, { useEffect, useRef, useState } from 'react';
import { Alert, Card } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import { useSpdCore } from '../../../common/hooks/useSpdCore';
import { JudgmentsSearch } from '../../components/Judgments/JudgmentsSearch';
import { RegisterMode } from '../../../common/spdCore/validation/schemas';
import { GetRegisterDto } from '../../../common/spdCore/autogenerated/spdApiClient';
import { Role } from '../../../common/spdCore/authorization/roles';
import { useSzafir } from '../../../common/hooks/useSzafir';
import { useAuth } from '../../../common/hooks/useAuth';
import { MultipleJudgmentSigning } from '../../components/Szafir/MultipleJudgmentSigning';

import { Filter } from './Filter';

type ParamTypes = {
  registerMode: RegisterMode;
};

export const JudgmentsForRegisterView: React.FC = () => {
  const spd = useSpdCore();
  const [register, setRegister] = useState<GetRegisterDto | null>();
  const [dateFrom, setDateFrom] = useState<Date | undefined>(
    new Date(new Date().getFullYear(), 0, 1, 0, 0, 0, 0),
  );
  const [dateTo, setDateTo] = useState<Date | undefined>();
  const [userUid, setUserUid] = useState<string | undefined>();
  const [healthcareFacilityId, setHealthcareFacilityId] = useState<
    string | undefined
  >();
  const [roles, setRoles] = useState<Role[]>([]);
  const [userName, setUserName] = useState<string>('Psycholog');
  const [message, setMessage] = useState<string>('');
  const [alertVariant, setAlertVariant] = useState<string>('');
  const [refresh, setRefresh] = useState<boolean>(false);

  const mountedRef = useRef(false);
  const auth = useAuth();
  const szafir = useSzafir();

  const { registerMode } = useParams<ParamTypes>() as {
    registerMode: RegisterMode;
  };

  useEffect(() => {
    if (auth.canSign) {
      szafir.activate(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.canSign, register]);

  useEffect(() => {
    mountedRef.current = true;
    spd.registers
      .getByMode(registerMode)
      .then(reg => {
        setRegister(reg);
        setUserUid(undefined);
      })
      .catch(error => {
        console.error('Wystąpił problem z pobraniem rejestru:', error);
      });
    return () => {
      mountedRef.current = false;
    };
  }, [spd.registers, registerMode]);

  useEffect(() => {
    setMessage('');
    switch (registerMode) {
      case 'OK':
      case 'KMP':
      case 'BR':
        setRoles([Role.PsychologistAssessor, Role.PsychologistSpecialist]);
        setUserName('Psycholog');
        return;
      default:
        setRoles([Role.MedicalAssessor]);
        setUserName('Lekarz');
        break;
    }
  }, [registerMode]);

  const onSignedFinished = (
    errors: string[],
    allCount: number,
    errorCount: number,
  ) => {
    setRefresh(!refresh);
    errors.length === 0
      ? setAlertVariant('success')
      : setAlertVariant('danger');
    if (allCount === 0 && errors.length === 0) {
      setMessage('Brak orzeczeń do podpisania.');
      return;
    }
    const errorList = errorCount > 0 ? errors.join('\n') : '';
    setMessage(
      `Zakończono podpisywanie ${allCount} orzeczeń. Liczba poprawnie podpisanych dokumentów: ${
        allCount - errorCount
      }. ${errorList} `,
    );
    return;
  };

  const onRefresh = () => {
    setRefresh(!refresh);
  };

  const handleOnExport = async (
    dateFrom: Date | undefined,
    dateTo: Date | undefined,
    userUid: string | undefined,
    healthcareFacilityId: string | undefined,
  ) => {
    setDateFrom(dateFrom);
    setDateTo(dateTo);
    setUserUid(userUid);
    setHealthcareFacilityId(healthcareFacilityId);
    if (register?.id) {
      await spd.pdfPrinter.printRegisterJudgment(
        register.id,
        dateFrom || new Date(),
        dateTo,
        userUid,
        healthcareFacilityId,
      );
    }
  };

  return (
    <>
      <Card>
        <Card.Header>{register?.name || 'Nieznany rejestr'}</Card.Header>
        <Card.Body>
          <MultipleJudgmentSigning
            registerId={register?.id || ''}
            userUid={auth.currentUser?.uid || ''}
            onSignedFinished={onSignedFinished}
          />
          {message && <Alert variant={alertVariant}>{message}</Alert>}
          <Filter
            headerName={register?.name || 'Nieznany rejestr'}
            roles={roles}
            withUser={register?.withUser || false}
            withDate={true}
            withHealthcareFacility={true}
            userName={userName}
            userUid={userUid}
            onChangeDateFrom={setDateFrom}
            onChangeDateTo={setDateTo}
            onChangeUserUid={setUserUid}
            onChangeHealthcareFacilityId={setHealthcareFacilityId}
            onExport={handleOnExport}
          />
          {register?.id && (
            <JudgmentsSearch
              getData={(
                pageSize,
                pageNumber,
                orderBy,
                orderDirection,
                filterText,
              ) =>
                spd.judgments.getForRegisterByPortion(
                  pageSize,
                  pageNumber,
                  orderBy,
                  orderDirection,
                  filterText,
                  register.id,
                  dateFrom,
                  dateTo,
                  userUid,
                  healthcareFacilityId,
                  onRefresh,
                )
              }
              getCount={filterText =>
                spd.judgments.getForRegisterCount(
                  filterText,
                  register.id,
                  dateFrom,
                  dateTo,
                  userUid,
                )
              }
              refresh={refresh}
            />
          )}
        </Card.Body>
      </Card>
    </>
  );
};
