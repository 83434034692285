import React from 'react';

import { GetAttachmentDto } from '../../../common/spdCore/autogenerated/spdApiClient/models/GetAttachmentDto';
import { Grid } from '../../../common/components/Grid/Grid';
import momencik from '../../../common/momencik';

import { AttachmentPreviewLink } from './AttachmentPreviewLink';

interface AttachmentProps {
  data?: GetAttachmentDto[];
  onRowClick?: (key: string) => Promise<void> | void | undefined;
  refresh?: boolean;
  enableMultiSelect?: boolean;
  selectedKeys?: string[];
}

export const attachmentForGrid = (attachment: GetAttachmentDto) => {
  return {
    key: attachment.id,
    values: {
      'attachment.name': attachment.name,
      'attachment.date': momencik(attachment.date),
      'attachment.description': attachment.description,
      'attachment.exam.number': attachment.exam?.number,
      'attachment.taskNumber': attachment.taskNumber || '',
      'attachment.type': attachment.type,
      preview: (
        <AttachmentPreviewLink
          attachmentId={attachment.id}
          attachmentName={attachment.name || ''}
          scan={attachment?.scan}
        />
      ),
    },
  };
};

export const Attachment: React.FC<AttachmentProps> = props => {
  const columns = [
    { header: 'Nazwa', property: 'attachment.name' },
    { header: 'Data', property: 'attachment.date' },
    { header: 'Opis', property: 'attachment.description' },
    { header: 'Numer badania', property: 'attachment.exam.number' },
    { header: 'Numer zadania', property: 'attachment.taskNumber' },
    { header: 'Typ', property: 'attachment.type' },
    { header: '', property: 'preview', noSorting: true },
  ];

  return (
    <Grid
      data={props.data && props.data.map(d => attachmentForGrid(d))}
      columns={columns}
      showFilter={true}
      initialOrderColumn="attachment.date"
      initialOrderDirection="DESC"
      availablePageSizes={[10, 20, 50]}
      onRowClick={props.onRowClick}
      emptyGridMessage="Brak załączników badanego w systemie"
      refresh={props.refresh}
      // enableMultiSelect={props.enableMultiSelect}
      selectedKeys={props.selectedKeys}
    />
  );
};
