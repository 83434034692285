/* eslint-disable complexity */
import React, { useEffect, useRef } from 'react';
import { Alert } from 'react-bootstrap';

import { Waiting } from '../../common/components/Waiting/Waiting';
import { useAuth } from '../../common/hooks/useAuth';
import { useNotifications } from '../../common/hooks/useNotifications';
import { getCurrentMenuItem } from '../../common/navigation';

interface MainProps {
  children: React.ReactNode;
  location: string;
}

const noAction = () => {
  // to be set later
};

const Main: React.FC<MainProps> = ({ children, location }: MainProps) => {
  const auth = useAuth();
  const notifications = useNotifications();
  const actionCallback = useRef(noAction);

  const items = getCurrentMenuItem(location);
  let authorized: boolean | undefined = true;

  for (const item of items) {
    const authItem = !item.requiredRole || auth.check(item.requiredRole);
    authorized = authorized && authItem;
  }

  if (
    !sessionStorage.getItem('user') &&
    !auth.currentUser &&
    authorized === undefined
  ) {
    authorized = false;
  }

  useEffect(() => {
    notifications.onPromise = (
      promise: Promise<void>,
      action?: () => void,
      actionIfFailed?: () => void,
    ) => {
      promise
        .then(() => {
          action && action();
        })
        .catch(async () => {
          const a = actionIfFailed || action;
          if (a) {
            actionCallback.current = a;
          }
        });
    };
  }, [notifications]);

  return (
    <div className="page-content">
      {authorized && children}
      {!authorized && authorized !== undefined && (
        <Alert variant="danger">
          <Alert.Heading>Brak wymaganych uprawnień</Alert.Heading>
          <hr />
          <p>
            W celu podwyższenia uprawnień prosimy o kontakt z administracją.
          </p>
        </Alert>
      )}
      {authorized === undefined && <Waiting />}
    </div>
  );
};

export default Main;
