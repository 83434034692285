import React, { PropsWithChildren, useState } from 'react';

import {
  ExaminedContext,
  ExaminedContextInterface,
} from './contexts/ExaminedContext';
import { UserContext, UserContextInterface } from './contexts/UserContext';
import {
  SignInContext,
  SignInContextInterface,
} from './contexts/SignInContext';
import {
  Configuration,
  DefaultApi,
  RequestOpts,
} from './autogenerated/spdApiClient';
import { TestContext, TestContextInterface } from './contexts/TestContext';
import { ExamContext, ExamContextInterface } from './contexts/ExamContext';
import {
  ExamTestContext,
  ExamTestContextInterface,
} from './contexts/ExamTestContext';
import {
  ArticleContext,
  ArticleContextInterface,
} from './contexts/ArticleContext';
import {
  BatteriesContext,
  BatteriesContextInterface,
} from './contexts/BatteryContext';
import {
  ResultContext,
  ResultContextInterface,
} from './contexts/ResultContext';
import {
  PdfTemplateContext,
  PdfTemplateContextInterface,
} from './contexts/PdfTemplatesContext';
import {
  JudgmentContext,
  JudgmentContextInterface,
} from './contexts/JudgmentContext';
import {
  PdfPrinterContext,
  PdfPrinterContextInterface,
} from './contexts/PdfPrinterContext';
import { CardContext, CardContextInterface } from './contexts/CardContext';
import { NormContext, NormContextInterface } from './contexts/NormContext';
import {
  IndicatorTestContext,
  IndicatorTestContextInterface,
} from './contexts/IndicatorTestContext';
import {
  KeyTestContext,
  KeyTestContextInterface,
} from './contexts/KeyTestContext';
import {
  KeyValueContext,
  KeyValueContextInterface,
} from './contexts/KeyValueContext';
import {
  PositionNormContext,
  PositionNormContextInterface,
} from './contexts/PositionNormContext';
import {
  InterpretationNormContext,
  InterpretationNormContextInterface,
} from './contexts/InterpretationNormContext';
import {
  SupportingExaminationContext,
  SupportingExaminationContextInterface,
} from './contexts/SupportingExaminationContext';
import {
  ConsultationContext,
  ConsultationContextInterface,
} from './contexts/ConsultationContext';
import {
  AttachmentContext,
  AttachmentContextInterface,
} from './contexts/AttachmentContext';
import {
  ExportContext,
  ExportContextInterface,
} from './contexts/ExportContext';
import {
  ExamsPackageContext,
  ExamsPackageContextInterface,
} from './contexts/ExamsPackageContext';
import {
  CompanyContext,
  CompanyContextInterface,
} from './contexts/CompanyContext';
import { StampContext, StampContextInterface } from './contexts/StampContext';
import {
  SavedPdfContext,
  SavedPdfContextInterface,
} from './contexts/SavedPdfContext';
import {
  SzafirContext,
  SzafirContextInterface,
} from './contexts/SzafirContext';
import {
  RegisterContext,
  RegisterContextInterface,
} from './contexts/RegisterContext';
import {
  JudgmentNumberingContext,
  JudgmentNumberingContextInterface,
} from './contexts/JudgmentNumberingContext';
import { EmailContext, EmailContextInterface } from './contexts/EmailContext';
import {
  HealthcareFacilityContext,
  HealthcareFacilityContextInterface,
} from './contexts/HealthcareFacilityContext';
import {
  DocumentationContext,
  DocumentationContextInterface,
} from './contexts/DocumentationContext';
import {
  AccessContext,
  AccessContextInterface,
} from './contexts/AccessContext';
import {
  ExternalReferralContext,
  ExternalReferralContextInterface,
} from './contexts/ExternalReferralContext';
import {
  HarmfulFactorContext,
  HarmfulFactorContextInterface,
} from './contexts/HarmfulFactorContext';
import { DutyContext, DutyContextInterface } from './contexts/DutyContext';
import {
  ReportContext,
  ReportContextInterface,
} from './contexts/ReportContext';
import {
  SupportingExaminationDictContext,
  SupportingExaminationDictContextInterface,
} from './contexts/SupportingExaminationDictContext';

export interface SpdCoreContextType {
  users: UserContextInterface;
  signIn: SignInContextInterface;
  examineds: ExaminedContextInterface;
  tests: TestContextInterface;
  exams: ExamContextInterface;
  examsPackage: ExamsPackageContextInterface;
  examTests: ExamTestContextInterface;
  articles: ArticleContextInterface;
  results: ResultContextInterface;
  batteries: BatteriesContextInterface;
  pdfTemplates: PdfTemplateContextInterface;
  pdfPrinter: PdfPrinterContextInterface;
  judgments: JudgmentContextInterface;
  cards: CardContextInterface;
  norms: NormContextInterface;
  healthCareFacilities: HealthcareFacilityContextInterface;
  positionNorms: PositionNormContextInterface;
  interpretationNorms: InterpretationNormContextInterface;
  indicatorTests: IndicatorTestContextInterface;
  judgmentNumbering: JudgmentNumberingContextInterface;
  keyTests: KeyTestContextInterface;
  keyValues: KeyValueContextInterface;
  support: SupportingExaminationContextInterface;
  consultations: ConsultationContextInterface;
  attachments: AttachmentContextInterface;
  export: ExportContextInterface;
  companies: CompanyContextInterface;
  stamps: StampContextInterface;
  savedPdfs: SavedPdfContextInterface;
  szafir: SzafirContextInterface;
  registers: RegisterContextInterface;
  emails: EmailContextInterface;
  docs: DocumentationContextInterface;
  accesses: AccessContextInterface;
  externalReferrals: ExternalReferralContextInterface;
  harmfulFactors: HarmfulFactorContextInterface;
  reports: ReportContextInterface;
  duties: DutyContextInterface;
  supportingExaminationDicts: SupportingExaminationDictContextInterface;
}

const getContext: (api: DefaultApi) => SpdCoreContextType = api => {
  return {
    users: UserContext(api),
    docs: DocumentationContext(api),
    signIn: SignInContext(),
    examineds: ExaminedContext(api),
    tests: TestContext(api),
    exams: ExamContext(api),
    examsPackage: ExamsPackageContext(api),
    examTests: ExamTestContext(api),
    articles: ArticleContext(api),
    results: ResultContext(api),
    batteries: BatteriesContext(api),
    pdfTemplates: PdfTemplateContext(api),
    pdfPrinter: PdfPrinterContext(api),
    judgments: JudgmentContext(api),
    cards: CardContext(api),
    norms: NormContext(api),
    positionNorms: PositionNormContext(api),
    healthCareFacilities: HealthcareFacilityContext(api),
    interpretationNorms: InterpretationNormContext(api),
    indicatorTests: IndicatorTestContext(api),
    judgmentNumbering: JudgmentNumberingContext(api),
    keyTests: KeyTestContext(api),
    keyValues: KeyValueContext(api),
    support: SupportingExaminationContext(api),
    consultations: ConsultationContext(api),
    attachments: AttachmentContext(api),
    export: ExportContext(api),
    companies: CompanyContext(api),
    stamps: StampContext(api),
    savedPdfs: SavedPdfContext(api),
    szafir: SzafirContext(api),
    registers: RegisterContext(api),
    emails: EmailContext(api),
    accesses: AccessContext(api),
    harmfulFactors: HarmfulFactorContext(api),
    reports: ReportContext(api),
    duties: DutyContext(api),
    externalReferrals: ExternalReferralContext(api),
    supportingExaminationDicts: SupportingExaminationDictContext(api),
  };
};

class APIWithHandler extends DefaultApi {
  constructor(
    configuration: Configuration,
    // private responseExceptionHandler: (response: Response) => Response,
    // private runExclusive: (callback: () => Promise<void>) => Promise<void>,
  ) {
    super(configuration);
  }
  protected async request(context: RequestOpts): Promise<Response> {
    // let response = new Response();

    const token = localStorage.getItem('mzt-token') || undefined;
    context.headers['Authorization'] = 'Bearer ' + token;
    return await super.request(context);
  }
}

export const SpdCoreContext = React.createContext(
  getContext(
    new APIWithHandler(
      new Configuration({
        basePath: process.env.REACT_APP_SPDAPI_URL,
      }),
    ),
  ),
);

export const SpdCoreProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [context, setDefaultContext] = useState(
    getContext(
      new APIWithHandler(
        new Configuration({
          basePath: process.env.REACT_APP_SPDAPI_URL,
        }),
      ),
    ),
  );
  const value = React.useMemo(
    () => ({
      ...context,
      setDefaultContext,
    }),
    [context],
  );

  return (
    <SpdCoreContext.Provider value={value}>{children}</SpdCoreContext.Provider>
  );
};
