import React, { useState } from 'react';
import { Pagination, OverlayTrigger, Popover, Spinner } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';

import { useLocation } from '../../hooks/useLocation';

import { GridLocationState } from './GridLocationState';

interface GridPaginationProps {
  pageNo: number;
  totalPages?: number | undefined;
  setPage: (pageNo: number) => void;
  allRowsCount: () => Promise<number>;
}

export const GridPagination: React.FC<GridPaginationProps> = props => {
  const [rowCount, setRowCount] = useState<number>();

  const firstPage = () => props.setPage(1);
  const navigate = useNavigate();
  const location = useLocation<GridLocationState & unknown>();

  const setPage = (no: number) => {
    return () => {
      navigate(location.pathname, {
        state: {
          ...location.state,
          page: no,
        },
      });
      props.setPage(no);
    };
  };
  const lastPage = () => {
    navigate(location.pathname, {
      state: {
        ...location.state,
        page: props.totalPages || props.pageNo,
      },
    });
    props.setPage(props.totalPages || props.pageNo);
  };

  const totalPages = props.totalPages || 999999999;

  const getCount = () => {
    setRowCount(undefined);
    props
      .allRowsCount()
      .then(count => {
        setRowCount(count);
      })
      .catch(err => console.log(err));
  };

  const action = (handler: () => void) => {
    return {
      onClick: handler,
      onKeyPress: (event: { key: string }) => {
        if (event.key === ' ' || event.key === 'Enter') {
          handler();
        }
      },
    };
  };

  return (
    <Pagination className="float-end">
      {props.pageNo > 1 && <Pagination.First {...action(firstPage)} />}
      {props.pageNo > 2 && (
        <Pagination.Ellipsis {...action(setPage(props.pageNo - 2))} />
      )}
      {props.pageNo > 1 && (
        <Pagination.Item {...action(setPage(props.pageNo - 1))}>
          {props.pageNo - 1}
        </Pagination.Item>
      )}
      <Pagination.Item active>{props.pageNo}</Pagination.Item>
      {props.pageNo < totalPages - 1 && (
        <>
          <Pagination.Item {...action(setPage(props.pageNo + 1))}>
            {props.pageNo + 1}
          </Pagination.Item>
          <Pagination.Ellipsis {...action(setPage(props.pageNo + 1))} />
        </>
      )}
      {props.totalPages && props.totalPages > props.pageNo && (
        <>
          <Pagination.Item {...action(lastPage)}>
            {props.totalPages}
          </Pagination.Item>
        </>
      )}
      <OverlayTrigger
        onEnter={getCount}
        overlay={
          <Popover id="popover-basic">
            <Popover.Header as="h3">Liczba wierszy</Popover.Header>
            <Popover.Body className="text-center">
              {rowCount || <Spinner animation="border" variant="secondary" />}
            </Popover.Body>
          </Popover>
        }
      >
        <Pagination.Item>
          <Icon.Question />
        </Pagination.Item>
      </OverlayTrigger>
    </Pagination>
  );
};
