import { isNumber } from '../../../common/spdCore/validation/schemas';

export type NormXls = {
  nazwa_normy: string;
  grupa_norm: string;
  prawdopodobienstwo: string;
  liczba_osob: string;
  opis: string;
  nazwa_wskaznika: string;
  typ_wskaznika: string;
  nazwa_klucza: string;
  rodzaj: string;
  wynik_surowy_od: string;
  wynik_surowy_do: string;
  wynik_przeliczony: string;
};

export type InterpretationXls = {
  interpretacja_wynik: string;
  interpretacja_zakres_od: string;
  interpretacja_zakres_do: string;
  interpretacja_opis: string;
};

export const validateNorms = (norms: NormXls[]) => {
  norms.shift();
  const errors: string[] = [];
  if (!norms[0].nazwa_normy) {
    errors.push('Brak wypełnionego pola "nazwa_normy"');
  }
  if (!norms[0].nazwa_wskaznika) {
    errors.push('Brak wypełnionego pola "nazwa_wskaznika"');
  }
  if (!norms[0].typ_wskaznika) {
    errors.push('Brak wypełnionego pola "typ_wskaznika"');
  }
  if (!norms[0].nazwa_klucza) {
    errors.push('Brak wypełnionego pola "nazwa_klucza"');
  }
  if (
    !norms[0].rodzaj ||
    !isNumber(norms[0].wynik_surowy_od) ||
    !isNumber(norms[0].wynik_surowy_do) ||
    !isNumber(norms[0].wynik_przeliczony)
  ) {
    errors.push('Brak wypełnionych odpowiednich pól w pozycjach norm');
  }
  if (errors.length > 0) {
    return {
      ok: false,
      errors: errors,
    };
  }
  return {
    ok: true,
    errors: [],
  };
};

export const validateInterpretations = (
  interpretations: InterpretationXls[],
) => {
  interpretations.shift();
  const errors: string[] = [];
  if (!interpretations[0].interpretacja_wynik) {
    errors.push('Brak wypełnionego pola "interpretacja_wynik"');
  }
  if (
    interpretations[0].interpretacja_zakres_od === null ||
    interpretations[0].interpretacja_zakres_od === undefined
  ) {
    errors.push('Brak wypełnionego pola "interpretacja_zakres_od"');
  }
  if (
    interpretations[0].interpretacja_zakres_do === null ||
    interpretations[0].interpretacja_zakres_do === undefined
  ) {
    errors.push('Brak wypełnionego pola "interpretacja_zakres_do"');
  }
  if (!interpretations[0].interpretacja_opis) {
    errors.push('Brak wypełnionego pola "interpretacja_opis"');
  }
  if (errors.length > 0) {
    return {
      ok: false,
      errors: errors,
    };
  }
  return {
    ok: true,
    errors: [],
  };
};
