/* tslint:disable */
/* eslint-disable */
/**
 * SPD
 * Opis api SPD
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetExamDto,
    GetExamDtoFromJSON,
    GetExamDtoFromJSONTyped,
    GetExamDtoToJSON,
    GetOtherPrintDefDto,
    GetOtherPrintDefDtoFromJSON,
    GetOtherPrintDefDtoFromJSONTyped,
    GetOtherPrintDefDtoToJSON,
    GetUserDto,
    GetUserDtoFromJSON,
    GetUserDtoFromJSONTyped,
    GetUserDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetOtherPrintDto
 */
export interface GetOtherPrintDto {
    /**
     * 
     * @type {string}
     * @memberof GetOtherPrintDto
     */
    id: string;
    /**
     * 
     * @type {GetExamDto}
     * @memberof GetOtherPrintDto
     */
    exam?: GetExamDto | null;
    /**
     * 
     * @type {Date}
     * @memberof GetOtherPrintDto
     */
    date?: Date | null;
    /**
     * 
     * @type {GetOtherPrintDefDto}
     * @memberof GetOtherPrintDto
     */
    otherPrintDef?: GetOtherPrintDefDto | null;
    /**
     * 
     * @type {GetUserDto}
     * @memberof GetOtherPrintDto
     */
    user?: GetUserDto | null;
    /**
     * 
     * @type {string}
     * @memberof GetOtherPrintDto
     */
    comments?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetOtherPrintDto
     */
    objective?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetOtherPrintDto
     */
    directsTo?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetOtherPrintDto
     */
    active?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetOtherPrintDto
     */
    withoutSign?: boolean | null;
}

export function GetOtherPrintDtoFromJSON(json: any): GetOtherPrintDto {
    return GetOtherPrintDtoFromJSONTyped(json, false);
}

export function GetOtherPrintDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetOtherPrintDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'exam': !exists(json, 'exam') ? undefined : GetExamDtoFromJSON(json['exam']),
        'date': !exists(json, 'date') ? undefined : (json['date'] === null ? null : new Date(json['date'])),
        'otherPrintDef': !exists(json, 'otherPrintDef') ? undefined : GetOtherPrintDefDtoFromJSON(json['otherPrintDef']),
        'user': !exists(json, 'user') ? undefined : GetUserDtoFromJSON(json['user']),
        'comments': !exists(json, 'comments') ? undefined : json['comments'],
        'objective': !exists(json, 'objective') ? undefined : json['objective'],
        'directsTo': !exists(json, 'directsTo') ? undefined : json['directsTo'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'withoutSign': !exists(json, 'withoutSign') ? undefined : json['withoutSign'],
    };
}

export function GetOtherPrintDtoToJSON(value?: GetOtherPrintDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'exam': GetExamDtoToJSON(value.exam),
        'date': value.date === undefined ? undefined : (value.date === null ? null : value.date.toISOString()),
        'otherPrintDef': GetOtherPrintDefDtoToJSON(value.otherPrintDef),
        'user': GetUserDtoToJSON(value.user),
        'comments': value.comments,
        'objective': value.objective,
        'directsTo': value.directsTo,
        'active': value.active,
        'withoutSign': value.withoutSign,
    };
}


