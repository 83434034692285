import { DefaultApi } from '../autogenerated/spdApiClient/apis/DefaultApi';
import { GetNormDto } from '../autogenerated/spdApiClient';
import { normForGrid } from '../../../spd/components/Norms/Norms';
import { GridRecord } from '../../components/Grid/GridDataTypes';
import { CreateUpdateNormWithValuesDto } from '../autogenerated/spdApiClient';

export type NormEntity = GetNormDto;

export interface NormContextInterface {
  get: (id: string) => Promise<NormEntity>;
  createWithValues: (dto: CreateUpdateNormWithValuesDto) => Promise<GetNormDto>;
  getAllCount: (filterText?: string) => Promise<number>;
  getAllByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
  ) => Promise<GridRecord[]>;
}

export const NormContext = (api: DefaultApi) => {
  return {
    get: async (id: string) => {
      return await api.normControllerGet(id);
    },

    getAllByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
    ) => {
      const data = await api.normControllerGetAllByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
      );
      return data.map(normForGrid);
    },

    getAllCount: (filterText?: string) =>
      api.normControllerGetAllCount(filterText || ''),

    createWithValues: (dto: CreateUpdateNormWithValuesDto) =>
      api.normControllerCreateWithValues(dto),
  };
};
