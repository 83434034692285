import React from 'react';

import { GetExaminedDto } from '../../../common/spdCore/autogenerated/spdApiClient/models/GetExaminedDto';
import { Grid } from '../../../common/components/Grid/Grid';
import momencik from '../../../common/momencik';
import {
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import { Gender } from '../../../common/spdCore/validation/schemas';

import { ExaminedLinkAttachments } from './ExaminedLinkAttachments';
import { ExaminedLinkExams } from './ExaminedLinkExams';
import { MergingExaminedLink } from './MergingExaminedLink';

interface ExaminedSearchProps {
  getData: GridGetterFunction;
  getCount: GridCountFunction;
  onRowClick: (key: string) => Promise<void> | void | undefined;
  refresh?: boolean;
  enableSingleSelect?: boolean;
  selectedKeys?: string[];
  withAttachments?: boolean;
}

export const examinedForGrid = (examined: GetExaminedDto) => {
  return {
    key: examined.id,
    values: {
      'examined.firstname': examined.firstname,
      'examined.surname': examined.surname,
      'examined.nationality': examined.nationality,
      'examined.pesel': examined.pesel
        ? examined.pesel
        : examined.iDCardNumber
        ? examined.iDCardType + ' ' + examined.iDCardNumber
        : '',
      'examined.sex':
        examined.sex === Gender.Male
          ? 'Mężczyzna'
          : examined.sex === Gender.Female
          ? 'Kobieta'
          : '',
      'examined.dateOfBirth': momencik(examined.dateOfBirth),
      'examined.education': examined.education,
      'examined.attachments': (
        <>
          <ExaminedLinkAttachments examinedId={examined.id} />
          <ExaminedLinkExams examinedId={examined.id} />
          <MergingExaminedLink examinedId={examined.id} />
        </>
      ),
    },
  };
};

export const ExaminedSearch: React.FC<ExaminedSearchProps> = props => {
  const columns = [
    { header: 'Imię', property: 'examined.firstname' },
    { header: 'Nazwisko', property: 'examined.surname' },
    { header: 'Narodowość', property: 'examined.nationality' },
    { header: 'PESEL / dokument', property: 'examined.pesel' },
    { header: 'Płeć', property: 'examined.sex' },
    { header: 'Data urodzenia', property: 'examined.dateOfBirth' },
    { header: 'Wykształcenie', property: 'examined.education' },
  ];

  if (props.withAttachments) {
    columns.push({
      header: '',
      property: 'examined.attachments',
    });
  }

  return (
    <Grid
      getData={props.getData}
      getDataCount={props.getCount}
      columns={columns}
      showFilter={true}
      initialOrderColumn="examined.surname"
      initialOrderDirection="ASC"
      availablePageSizes={[10, 20, 50]}
      onRowClick={props.onRowClick}
      emptyGridMessage="Brak badanych w systemie"
      refresh={props.refresh}
      enableSingleSelect={props.enableSingleSelect}
      selectedKeys={props.selectedKeys}
    />
  );
};
