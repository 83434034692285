/* tslint:disable */
/* eslint-disable */
/**
 * SPD
 * Opis api SPD
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUpdateSavedPdfDto
 */
export interface CreateUpdateSavedPdfDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateSavedPdfDto
     */
    tags?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateSavedPdfDto
     */
    pdfTemplateId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateSavedPdfDto
     */
    entityId: string;
    /**
     * 
     * @type {object}
     * @memberof CreateUpdateSavedPdfDto
     */
    file?: object | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateSavedPdfDto
     */
    english?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateSavedPdfDto
     */
    pdfTemplateType?: CreateUpdateSavedPdfDtoPdfTemplateTypeEnum;
}

export function CreateUpdateSavedPdfDtoFromJSON(json: any): CreateUpdateSavedPdfDto {
    return CreateUpdateSavedPdfDtoFromJSONTyped(json, false);
}

export function CreateUpdateSavedPdfDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateSavedPdfDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tags': !exists(json, 'tags') ? undefined : json['tags'],
        'pdfTemplateId': !exists(json, 'pdfTemplateId') ? undefined : json['pdfTemplateId'],
        'entityId': json['entityId'],
        'file': !exists(json, 'file') ? undefined : json['file'],
        'english': !exists(json, 'english') ? undefined : json['english'],
        'pdfTemplateType': !exists(json, 'pdfTemplateType') ? undefined : json['pdfTemplateType'],
    };
}

export function CreateUpdateSavedPdfDtoToJSON(value?: CreateUpdateSavedPdfDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tags': value.tags,
        'pdfTemplateId': value.pdfTemplateId,
        'entityId': value.entityId,
        'file': value.file,
        'english': value.english,
        'pdfTemplateType': value.pdfTemplateType,
    };
}

/**
* @export
* @enum {string}
*/
export enum CreateUpdateSavedPdfDtoPdfTemplateTypeEnum {
    Art82Ust1 = 'art_82_ust_1',
    Art82Uprzywilejowany = 'art_82_uprzywilejowany',
    Art82InstruktorEgzaminator = 'art_82_instruktor_egzaminator',
    Art39TransportDrogowy = 'art_39_transport_drogowy',
    MedycynaPracy = 'medycyna_pracy',
    KartaBadaniaPsychologicznego = 'karta_badania_psychologicznego',
    KartaBroni = 'karta_broni',
    BronOrzeczenieLekarskie = 'bron_orzeczenie_lekarskie',
    BronOrzeczeniePsychologiczne = 'bron_orzeczenie_psychologiczne',
    BronKartaBadaniaPsychologicznego = 'bron_karta_badania_psychologicznego',
    KartaBadaniaProfilaktycznego = 'karta_badania_profilaktycznego',
    MpOrzeczenieLekarskie43 = 'mp_orzeczenie_lekarskie_43',
    MpOrzeczenieLekarskie43Ang = 'mp_orzeczenie_lekarskie_43_ang',
    MpOrzeczenieLekarskie43Transport = 'mp_orzeczenie_lekarskie_43_transport',
    KierowcaOrzeczenieLekarskieArt75Uprzywilejowany = 'kierowca_orzeczenie_lekarskie_art_75_uprzywilejowany',
    KierowcaOrzeczenieLekarskieArt75Reszta = 'kierowca_orzeczenie_lekarskie_art_75_reszta',
    MpZaswiadczenieLekarskieStudent = 'mp_zaswiadczenie_lekarskie_student',
    OrzeczenieSanitarnoEpidemiologiczne = 'orzeczenie_sanitarno_epidemiologiczne',
    KartaBadaniaKierowcy = 'karta_badania_kierowcy',
    KonsultacjaNeurologiczna = 'konsultacja_neurologiczna',
    KonsultacjaLaryngologiczna = 'konsultacja_laryngologiczna',
    KonsultacjaOkulistyczna = 'konsultacja_okulistyczna',
    KonsultacjaPsychiatryczna = 'konsultacja_psychiatryczna',
    KartaOdpowiedzi = 'karta_odpowiedzi',
    KartaBadaniaSanitarnoEpidemiologicznego = 'karta_badania_sanitarno_epidemiologicznego',
    ZaswiadczenieLekarskieZdolnosciDoPracy = 'zaswiadczenie_lekarskie_zdolnosci_do_pracy',
    ZaswiadczenieSportowe = 'zaswiadczenie_sportowe',
    KartaBadaniaSportowego = 'karta_badania_sportowego',
    OrzeczenieStanZdrowiaLekarza = 'orzeczenie_stan_zdrowia_lekarza',
    ZaswiadczenieStanZdrowiaPielegniarka = 'zaswiadczenie_stan_zdrowia_pielegniarka',
    ZaswiadczenieStanZdrowiaPielegniarkaArt31 = 'zaswiadczenie_stan_zdrowia_pielegniarka_art31',
    RejestrOrzeczen = 'rejestrOrzeczen',
    SkierowanieUczen = 'skierowanie_uczen',
    SkierowaniePracownik = 'skierowanie_pracownik',
    WynikWidzeniaZmierzchowego = 'wynik_widzenia_zmierzchowego',
    WybuchCywilneOrzeczenieLekarskie = 'wybuch_cywilne_orzeczenie_lekarskie',
    WybuchCywilneOrzeczeniePsychologiczne = 'wybuch_cywilne_orzeczenie_psychologiczne',
    WybuchWojskoOrzeczenieLekarskie = 'wybuch_wojsko_orzeczenie_lekarskie',
    WybuchWojskoOrzeczeniePsychologiczne = 'wybuch_wojsko_orzeczenie_psychologiczne',
    DetektywOrzeczenieLekarskie = 'detektyw_orzeczenie_lekarskie',
    DetektywOrzeczeniePsychologiczne = 'detektyw_orzeczenie_psychologiczne',
    ZestawienieOrzeczen = 'zestawienie_orzeczen',
    ListaFirm = 'lista_firm',
    SkierowanieDoPoradni = 'skierowanie_do_poradni'
}


