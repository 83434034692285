import React, { useEffect, useState } from 'react';
import { Button, Form, NavDropdown } from 'react-bootstrap';

import { AuthState } from '../../common/auth';
import { useAuth } from '../../common/hooks/useAuth';
import {
  currentProfileMenuItems,
  useNavigation,
} from '../../common/navigation';

import AlertModal from './AlertModal';
import ValidationAlert from './ValidationAlert';

interface ProfileButtonProps {
  activePage: string;
}

const ProfileButton: React.FC<ProfileButtonProps> = ({ activePage }) => {
  const nav = useNavigation();
  const auth = useAuth();

  const [currentProfileLinks, setCurrentProfileLinks] = useState(
    currentProfileMenuItems(activePage),
  );
  const [showError, setShowError] = useState(false);
  const [errorText, setErrorText] = useState('');

  useEffect(() => {
    setCurrentProfileLinks(currentProfileMenuItems(activePage, auth?.roles));
  }, [activePage, auth?.roles]);

  const handleLogout = () => {
    auth
      .logout()
      .then(() => {
        nav.startPage();
      })
      .catch(() => {
        setErrorText('Błąd podczas wylogowywania.');
        setShowError(true);
      });
  };

  const handleLogin = () => {
    nav.login();
  };

  return (
    <>
      <div className="menu-profile">
        <ValidationAlert
          show={showError}
          modal={true}
          onHide={() => {
            setShowError(false);
          }}
          errors={[errorText]}
        />
        <AlertModal variant="danger">{}</AlertModal>
        {auth.state === AuthState.LoggedOut && (
          <Form>
            <Button variant="my-outline-opposed-btn" onClick={handleLogin}>
              Zaloguj się
            </Button>
          </Form>
        )}
        {auth.state === AuthState.LoggedIn && (
          <NavDropdown
            title={`${auth.userProfile?.name || ''} (${
              auth.currentUser?.email || ''
            })`}
            id="nav-profile-dropdown"
            align={'end'}
          >
            {currentProfileLinks.map(({ path, heading }) => (
              <NavDropdown.Item
                key={path}
                onClick={() => nav.menuItem(path)}
                className="d-flex"
              >
                {heading}
              </NavDropdown.Item>
            ))}

            <NavDropdown.Item eventKey="logout" onClick={handleLogout}>
              Wyloguj
            </NavDropdown.Item>
          </NavDropdown>
        )}
      </div>
    </>
  );
};

export default ProfileButton;
