import React from 'react';

import { Grid } from '../../../common/components/Grid/Grid';
import { GetJudgmentNumberingDto } from '../../../common/spdCore/autogenerated/spdApiClient';

interface JudgmentsNumberingProps {
  data?: GetJudgmentNumberingDto[];
  onRowClick?: (key: string) => Promise<void> | void | undefined;
  refresh?: boolean;
  enableMultiSelect?: boolean;
  selectedKeys?: string[];
}

export const judgmentsNumberingForGrid = (
  judgmentNumbering: GetJudgmentNumberingDto,
) => {
  return {
    key: judgmentNumbering.id,
    values: {
      'register.name': judgmentNumbering.register?.name,
      'user.name': judgmentNumbering?.user?.name,
      'judgmentNumbering.symbol': judgmentNumbering?.symbol,
      'judgmentNumbering.year': judgmentNumbering?.year,
      'judgmentNumbering.month': judgmentNumbering?.month,
      'judgmentNumbering.actualNumber': judgmentNumbering?.actualNumber,
    },
  };
};

export const JudgmentsNumbering: React.FC<JudgmentsNumberingProps> = props => {
  const columns = [
    { header: 'Rejestr', property: 'register.name' },
    { header: 'Użytkownik', property: 'user.name' },
    { header: 'Symbol', property: 'judgmentNumbering.symbol' },
    { header: 'Rok', property: 'judgmentNumbering.year' },
    { header: 'Miesiąc', property: 'judgmentNumbering.month' },
    {
      header: 'Aktualny numer',
      property: 'judgmentNumbering.actualNumber',
    },
  ];

  return (
    <Grid
      data={props.data && props.data.map(d => judgmentsNumberingForGrid(d))}
      columns={columns}
      showFilter={true}
      initialOrderColumn="register.name"
      availablePageSizes={[10, 20, 50]}
      onRowClick={props.onRowClick}
      emptyGridMessage="Brak numeracji orzeczeń w systemie"
      refresh={props.refresh}
      enableMultiSelect={props.enableMultiSelect}
      selectedKeys={props.selectedKeys}
    />
  );
};
