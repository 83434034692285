/* tslint:disable */
/* eslint-disable */
/**
 * SPD
 * Opis api SPD
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetIndicatorTestDto,
    GetIndicatorTestDtoFromJSON,
    GetIndicatorTestDtoFromJSONTyped,
    GetIndicatorTestDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetNormDto
 */
export interface GetNormDto {
    /**
     * 
     * @type {string}
     * @memberof GetNormDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetNormDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetNormDto
     */
    condition?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetNormDto
     */
    confidenceInterval?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetNormDto
     */
    probability?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetNormDto
     */
    criticalValue?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetNormDto
     */
    group?: string | null;
    /**
     * 
     * @type {GetIndicatorTestDto}
     * @memberof GetNormDto
     */
    indicatorTest?: GetIndicatorTestDto | null;
    /**
     * 
     * @type {number}
     * @memberof GetNormDto
     */
    examinedCount?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetNormDto
     */
    isDefault?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof GetNormDto
     */
    description?: string | null;
}

export function GetNormDtoFromJSON(json: any): GetNormDto {
    return GetNormDtoFromJSONTyped(json, false);
}

export function GetNormDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetNormDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'condition': !exists(json, 'condition') ? undefined : json['condition'],
        'confidenceInterval': !exists(json, 'confidenceInterval') ? undefined : json['confidenceInterval'],
        'probability': !exists(json, 'probability') ? undefined : json['probability'],
        'criticalValue': !exists(json, 'criticalValue') ? undefined : json['criticalValue'],
        'group': !exists(json, 'group') ? undefined : json['group'],
        'indicatorTest': !exists(json, 'indicatorTest') ? undefined : GetIndicatorTestDtoFromJSON(json['indicatorTest']),
        'examinedCount': !exists(json, 'examinedCount') ? undefined : json['examinedCount'],
        'isDefault': !exists(json, 'isDefault') ? undefined : json['isDefault'],
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function GetNormDtoToJSON(value?: GetNormDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'condition': value.condition,
        'confidenceInterval': value.confidenceInterval,
        'probability': value.probability,
        'criticalValue': value.criticalValue,
        'group': value.group,
        'indicatorTest': GetIndicatorTestDtoToJSON(value.indicatorTest),
        'examinedCount': value.examinedCount,
        'isDefault': value.isDefault,
        'description': value.description,
    };
}


