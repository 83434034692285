import React from 'react';

import { SignInDialog } from '../components/SignIn/SignInDialog';
import { useSpdCore } from '../../common/hooks/useSpdCore';

export const SignInView: React.FC = () => {
  const spdApi = useSpdCore();

  const onSignIn = async (username: string, password: string) => {
    return await spdApi.signIn.signIn(username, password);
  };

  return <SignInDialog onSignIn={onSignIn} open={true} />;
};
