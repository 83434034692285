export const responseErrors = async (response: Response) => {
  if ('message' in response) {
    if ((response as unknown as Error).message === 'Failed to fetch') {
      return ['Błąd połączenia z serwerem'];
    }
    return [(response as unknown as Error).message];
  }
  const errors: string[] = [];
  try {
    const validationError = await response.json();
    errors.push(
      ...(validationError.messages || [validationError.message].flat()),
    );
  } catch (err) {
    errors.push('Błąd wewnętrzny ' + err);
  }
  return errors;
};
