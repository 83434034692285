import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

import { useNavigation } from '../../../common/navigation';
import { CardType } from '../../../common/spdCore/validation/schemas';

export const CardsLink: React.FC<{
  examId: string;
  type: CardType;
}> = props => {
  const nav = useNavigation();

  return (
    <OverlayTrigger overlay={<Tooltip>Edycja karty do badania</Tooltip>}>
      <Button
        onClick={e => {
          e.stopPropagation();
          switch (props.type) {
            case CardType.Psychologist:
              nav.examCardsPsychologic(props.examId);
              break;
            case CardType.Preventive:
              nav.examCardsPreventive(props.examId);
              break;
            case CardType.Driver:
              nav.examCardsDriver(props.examId);
              break;
          }
        }}
        variant="btn-info"
      >
        <Icon.CardText />
      </Button>
    </OverlayTrigger>
  );
};
