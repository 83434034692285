import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

import { useNavigation } from '../../../common/navigation';
import { ArticleMode } from '../../../common/spdCore/validation/schemas';

export const JudgmentsLink: React.FC<{
  examId: string;
  articleMode: ArticleMode;
}> = props => {
  const nav = useNavigation();

  const handleOnClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (props.articleMode === ArticleMode.Psychologist) {
      nav.examPsychologistJudgments(props.examId);
    }
    if (props.articleMode === ArticleMode.Doctor) {
      nav.examDoctorJudgments(props.examId);
    }
  };

  return (
    <>
      {(props.articleMode === ArticleMode.Doctor ||
        props.articleMode === ArticleMode.Psychologist) && (
        <OverlayTrigger overlay={<Tooltip>Edycja orzeczeń do badania</Tooltip>}>
          <Button onClick={handleOnClick} variant="btn-info">
            <Icon.Clipboard2Pulse />
          </Button>
        </OverlayTrigger>
      )}
    </>
  );
};
