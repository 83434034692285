import React, { useState } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

import { useAuth } from '../../../common/hooks/useAuth';
import { useSzafir } from '../../../common/hooks/useSzafir';
import { useSpdCore } from '../../../common/hooks/useSpdCore';
import { SignatureStatus } from '../../../common/spdCore/validation/schemas';
import LoadingSpinner from '../../../common/components/Waiting/LoadingSpinner';
import { responseErrors } from '../../../common/spdCore/validation/responseErrors';

export const JudgmentSignLink = (props: { judgmentId: string }) => {
  const auth = useAuth();
  const szafir = useSzafir();
  const spd = useSpdCore();
  const [signStatus, setSignStatus] = useState<string>('');
  const [signingInProgress, setSigningInProgress] = useState<boolean>(false);

  spd.savedPdfs
    .getSignatureStatusForJudgment(props.judgmentId)
    .then(result => {
      setSignStatus(result.status);
    })
    .catch(err => {
      console.log('Błąd pobierania informacji o podpisie dokumentu:', err);
    });

  const handleClick = async () => {
    if (!auth.userProfile?.pin) {
      alert('Brak zdefiniowanego PINu. Przejdź i zdefiniuj PIN.');
      return;
    }
    setSigningInProgress(true);
    const judgment = await spd.judgments.get(props.judgmentId);
    await signSaveAndSend();
    if (judgment?.article?.engPdfTemplateId) {
      await new Promise(f => setTimeout(f, 6000));
      await signSaveAndSend(true);
    }
  };

  const signSaveAndSend = async (english = false) => {
    spd.szafir
      .getTaskListForJudgment(props.judgmentId, english, auth.userProfile?.id)
      .then(async taskList => {
        await szafir.signDocument(
          taskList,
          auth.userProfile?.pin || '',
          props.judgmentId,
          '',
          english,
          saveSignedPdf,
          signedFailed,
        );
      })
      .catch(async response => {
        const errors = await responseErrors(response as Response);
        alert('Błąd pobierania listy TaskList:\n ' + errors);
        setSigningInProgress(false);
      });
  };

  const saveSignedPdf = async (
    result: Buffer | null,
    documentId: string,
    documentNumber: string,
    english: boolean,
  ) => {
    spd.savedPdfs
      .savePdfFile({
        entityId: props.judgmentId,
        file: result,
        english: english,
      })
      .then(() => {
        setSignStatus(SignatureStatus.Signed);
        setSigningInProgress(false);
        openSignedDocument(result);
      })
      .then(async () => {
        await spd.emails.sendJudgmentByEmail(
          props.judgmentId,
          auth.userProfile?.id || '',
          english,
        );
      })
      .catch(async response => {
        const errors = await responseErrors(response as Response);
        alert('Błąd zapisu PDF do bazy danych:\n ' + errors);
        setSigningInProgress(false);
      });
  };

  const signedFailed = (error: string) => {
    alert('Błąd:\n' + error);
    setSigningInProgress(false);
  };

  const openSignedDocument = (pdfData: Buffer | null) => {
    if (!pdfData) {
      return;
    }
    const blob = new Blob([pdfData], { type: 'application/pdf' });
    const blobURL = URL.createObjectURL(blob);
    window.open(blobURL);
  };

  return (
    <div className="text-center">
      {signingInProgress && <LoadingSpinner />}
      {signStatus === SignatureStatus.ToSign && (
        <OverlayTrigger
          overlay={<Tooltip style={{ position: 'fixed' }}>Podpisz</Tooltip>}
        >
          <Button
            onClick={e => {
              e.stopPropagation();
              void handleClick();
            }}
            variant="btn-info"
          >
            <Icon.PencilSquare className="icon-red" />
          </Button>
        </OverlayTrigger>
      )}
      {signStatus === SignatureStatus.Signed && (
        <OverlayTrigger
          overlay={<Tooltip style={{ position: 'fixed' }}>Podpisane</Tooltip>}
        >
          <Icon.CheckSquare className="icon-green mt-2" />
        </OverlayTrigger>
      )}
    </div>
  );
};
