/* tslint:disable */
/* eslint-disable */
/**
 * SPD
 * Opis api SPD
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetRegisterDto,
    GetRegisterDtoFromJSON,
    GetRegisterDtoFromJSONTyped,
    GetRegisterDtoToJSON,
    GetUserDto,
    GetUserDtoFromJSON,
    GetUserDtoFromJSONTyped,
    GetUserDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetJudgmentNumberingDto
 */
export interface GetJudgmentNumberingDto {
    /**
     * 
     * @type {string}
     * @memberof GetJudgmentNumberingDto
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof GetJudgmentNumberingDto
     */
    year?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetJudgmentNumberingDto
     */
    actualNumber?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetJudgmentNumberingDto
     */
    month?: number | null;
    /**
     * 
     * @type {GetRegisterDto}
     * @memberof GetJudgmentNumberingDto
     */
    register?: GetRegisterDto | null;
    /**
     * 
     * @type {GetUserDto}
     * @memberof GetJudgmentNumberingDto
     */
    user?: GetUserDto | null;
    /**
     * 
     * @type {string}
     * @memberof GetJudgmentNumberingDto
     */
    symbol?: string | null;
}

export function GetJudgmentNumberingDtoFromJSON(json: any): GetJudgmentNumberingDto {
    return GetJudgmentNumberingDtoFromJSONTyped(json, false);
}

export function GetJudgmentNumberingDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetJudgmentNumberingDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'year': !exists(json, 'year') ? undefined : json['year'],
        'actualNumber': !exists(json, 'actualNumber') ? undefined : json['actualNumber'],
        'month': !exists(json, 'month') ? undefined : json['month'],
        'register': !exists(json, 'register') ? undefined : GetRegisterDtoFromJSON(json['register']),
        'user': !exists(json, 'user') ? undefined : GetUserDtoFromJSON(json['user']),
        'symbol': !exists(json, 'symbol') ? undefined : json['symbol'],
    };
}

export function GetJudgmentNumberingDtoToJSON(value?: GetJudgmentNumberingDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'year': value.year,
        'actualNumber': value.actualNumber,
        'month': value.month,
        'register': GetRegisterDtoToJSON(value.register),
        'user': GetUserDtoToJSON(value.user),
        'symbol': value.symbol,
    };
}


