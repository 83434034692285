import {
  CreateUpdateExternalReferralDto,
  CreateUpdateExternalReferralDtoFromJSON,
} from '../autogenerated/spdApiClient';
import { DefaultApi } from '../autogenerated/spdApiClient/apis/DefaultApi';
import { ExternalReferralSchema } from '../validation/schemas';
import { validateAgainst } from '../validation/validateAgainst';

export interface ExternalReferralContextInterface {
  create: (dto: CreateUpdateExternalReferralDto) => Promise<void>;
}

export const ExternalReferralContext = (api: DefaultApi) => {
  return {
    create: (dto: CreateUpdateExternalReferralDto) =>
      api.externalReferralControllerCreate(dto),
  };
};

export const validate = (externalReferral: CreateUpdateExternalReferralDto) =>
  validateAgainst(ExternalReferralSchema, externalReferral);

export const newExternalReferral = (): CreateUpdateExternalReferralDto => {
  return CreateUpdateExternalReferralDtoFromJSON({
    exam: { external: true },
  });
};
