/* tslint:disable */
/* eslint-disable */
/**
 * SPD
 * Opis api SPD
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetCompanyBatteryDto,
    GetCompanyBatteryDtoFromJSON,
    GetCompanyBatteryDtoFromJSONTyped,
    GetCompanyBatteryDtoToJSON,
    GetHealthcareFacilityDto,
    GetHealthcareFacilityDtoFromJSON,
    GetHealthcareFacilityDtoFromJSONTyped,
    GetHealthcareFacilityDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetCompanyDto
 */
export interface GetCompanyDto {
    /**
     * 
     * @type {string}
     * @memberof GetCompanyDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetCompanyDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCompanyDto
     */
    address?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCompanyDto
     */
    nip?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCompanyDto
     */
    email?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetCompanyDto
     */
    externalReferral?: boolean | null;
    /**
     * 
     * @type {GetCompanyDto}
     * @memberof GetCompanyDto
     */
    branch?: GetCompanyDto | null;
    /**
     * 
     * @type {Array<GetCompanyBatteryDto>}
     * @memberof GetCompanyDto
     */
    companiesBatteries?: Array<GetCompanyBatteryDto> | null;
    /**
     * 
     * @type {GetHealthcareFacilityDto}
     * @memberof GetCompanyDto
     */
    healthcareFacility?: GetHealthcareFacilityDto | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetCompanyDto
     */
    monthlySettlement: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetCompanyDto
     */
    active: boolean;
}

export function GetCompanyDtoFromJSON(json: any): GetCompanyDto {
    return GetCompanyDtoFromJSONTyped(json, false);
}

export function GetCompanyDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetCompanyDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'nip': !exists(json, 'nip') ? undefined : json['nip'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'externalReferral': !exists(json, 'externalReferral') ? undefined : json['externalReferral'],
        'branch': !exists(json, 'branch') ? undefined : GetCompanyDtoFromJSON(json['branch']),
        'companiesBatteries': !exists(json, 'companiesBatteries') ? undefined : (json['companiesBatteries'] === null ? null : (json['companiesBatteries'] as Array<any>).map(GetCompanyBatteryDtoFromJSON)),
        'healthcareFacility': !exists(json, 'healthcareFacility') ? undefined : GetHealthcareFacilityDtoFromJSON(json['healthcareFacility']),
        'monthlySettlement': json['monthlySettlement'],
        'active': json['active'],
    };
}

export function GetCompanyDtoToJSON(value?: GetCompanyDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'address': value.address,
        'nip': value.nip,
        'email': value.email,
        'externalReferral': value.externalReferral,
        'branch': GetCompanyDtoToJSON(value.branch),
        'companiesBatteries': value.companiesBatteries === undefined ? undefined : (value.companiesBatteries === null ? null : (value.companiesBatteries as Array<any>).map(GetCompanyBatteryDtoToJSON)),
        'healthcareFacility': GetHealthcareFacilityDtoToJSON(value.healthcareFacility),
        'monthlySettlement': value.monthlySettlement,
        'active': value.active,
    };
}


