import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

import { useSpdCore } from '../../../common/hooks/useSpdCore';

export const JudgmentPreviewLink = (props: {
  judgmentId: string;
  engPdfTemplateId?: string | null;
}) => {
  const spd = useSpdCore();

  const handleClick = () => {
    spd.pdfPrinter
      .loadAndPrintJudgment(props.judgmentId)
      .catch(error =>
        console.log(`Wystąpił problem z wydrukiem orzeczenia. ${error}`),
      );
  };

  const handleClickEng = () => {
    spd.pdfPrinter
      .loadAndPrintJudgmentInEnglish(props.judgmentId)
      .catch(error =>
        console.log(
          `Wystąpił problem z wydrukiem orzeczenia w j. angielskim. ${error}`,
        ),
      );
  };

  return (
    <>
      <OverlayTrigger
        overlay={
          <Tooltip style={{ position: 'fixed' }}>Wydruk orzeczenia</Tooltip>
        }
      >
        <Button
          onClick={e => {
            e.stopPropagation();
            handleClick();
          }}
          variant="btn-info"
        >
          <Icon.PrinterFill />
        </Button>
      </OverlayTrigger>
      {props.engPdfTemplateId && (
        <OverlayTrigger
          overlay={<Tooltip style={{ position: 'fixed' }}>Wersja ang.</Tooltip>}
        >
          <Button
            onClick={e => {
              e.stopPropagation();
              handleClickEng();
            }}
            variant="btn-info"
          >
            <Icon.PrinterFill />
          </Button>
        </OverlayTrigger>
      )}
    </>
  );
};
