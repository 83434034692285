import React, { useState } from 'react';
import { Button, Form, Image } from 'react-bootstrap';

import { useNavigation } from '../../../common/navigation';

export type Props = {
  onRegister: (usr: string, pwd: string) => void;
};

export const Register: React.FC<Props> = ({ onRegister }) => {
  const nav = useNavigation();

  const [usr, setUsr] = useState('');
  const [pwd, setPwd] = useState('');
  const [repeatPwd, setRepeatPwd] = useState('');
  const [userError, setUserError] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const handleRegister = async () => {
    setUserError('');
    setPasswordError('');
    if (!usr) {
      setUserError('Wprowadź adres e-mail.');
      return;
    }
    if (!pwd) {
      setPasswordError('Wprowadź hasło.');
      return;
    }
    if (pwd !== repeatPwd) {
      setPasswordError('Hasła nie zgadzają się.');
      return;
    }

    await onRegister(usr, pwd);
  };

  return (
    <main className="form-signin">
      <Form>
        <Image className="img-logo-spd mb-3 mt-5" alt="Logo Spd" />
        <h3 className="fw-normal">Zarejestruj się</h3>
        <Form.Group className="form-floating mt-3" controlId="email">
          <Form.Control
            type="email"
            placeholder="Adres e-mail"
            defaultValue={usr}
            onChange={e => setUsr(e.target.value)}
          />
          <Form.Label>Adres e-mail</Form.Label>
        </Form.Group>
        <span className="input-form-error">{userError}</span>
        <Form.Group className="form-floating mt-3" controlId="password">
          <Form.Control
            type="password"
            placeholder="Hasło"
            value={pwd}
            onChange={e => setPwd(e.target.value)}
          />
          <Form.Label>Hasło</Form.Label>
        </Form.Group>
        <span className="input-form-error">{passwordError}</span>
        <Form.Group className="form-floating mt-3" controlId="repeatPassword">
          <Form.Control
            type="password"
            placeholder="Powtórzone hasło"
            value={repeatPwd}
            onChange={e => setRepeatPwd(e.target.value)}
          />
          <Form.Label>Powtórzone hasło</Form.Label>
        </Form.Group>
      </Form>
      <Button
        type="submit"
        className="w-100 btn btn-lg btn-login mt-3"
        onClick={handleRegister}
      >
        Zarejestruj się
      </Button>
      <br />
      <br />
      <br />
      <br />
      <p className="h4">Masz konto? Wróc do logowania</p>
      <Button className="w-100 btn btn-lg btn-register" onClick={nav.login}>
        Wróć do logowania
      </Button>
    </main>
  );
};
