import React from 'react';

import { GetDutyDto } from '../../../common/spdCore/autogenerated/spdApiClient/models/GetDutyDto';
import { Grid } from '../../../common/components/Grid/Grid';
import {
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';

interface DutySearchProps {
  getData: GridGetterFunction;
  getCount: GridCountFunction;
  refresh?: boolean;
  onRowClick: (key: string) => Promise<void> | void | undefined;
}

export const dutyForGrid = (duty: GetDutyDto) => {
  return {
    key: duty.id,
    values: {
      'duty.name': duty.name,
      'psychologist.name': duty?.psychologist?.name,
      'doctor.name': duty?.doctor?.name,
    },
  };
};

export const DutySearch: React.FC<DutySearchProps> = props => {
  const columns = [
    { header: 'Nazwa', property: 'duty.name' },
    { header: 'Psycholog', property: 'psychologist.name' },
    { header: 'Lekarz', property: 'doctor.name' },
  ];

  return (
    <Grid
      getData={props.getData}
      getDataCount={props.getCount}
      columns={columns}
      showFilter={true}
      initialOrderColumn="duty.name"
      availablePageSizes={[10, 20, 50]}
      emptyGridMessage="Brak dyżurów w systemie"
      enableMultiSelect={false}
      refresh={props.refresh}
      onRowClick={props.onRowClick}
    />
  );
};
