import React, { useEffect, useState } from 'react';
import { Button, Card, Tab } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import Tabs from '../../../app/components/Tabs';
import { useSpdCore } from '../../../common/hooks/useSpdCore';
import { useNavigation } from '../../../common/navigation';
import {
  GetExamDto,
  GetResultDto,
} from '../../../common/spdCore/autogenerated/spdApiClient';
import { Results } from '../../components/Results/Results';

type ParamTypes = {
  id: string;
};

export const ResultsView: React.FC = () => {
  const spd = useSpdCore();
  const nav = useNavigation();
  const [results, setResults] = useState<GetResultDto[]>([]);
  const [exam, setExam] = useState<GetExamDto | null | undefined>();

  const { id } = useParams<ParamTypes>() as { id: string };

  useEffect(() => {
    let mounted = true;
    spd.results
      .getByExamTestId(id)
      .then(res => {
        if (mounted) {
          setResults(res);
        }
      })
      .catch(error =>
        console.log(`Wystąpił problem z odświeżeniem strony. ${error}`),
      );

    spd.examTests
      .get(id)
      .then(res => {
        if (mounted) {
          setExam(res?.exam);
        }
      })
      .catch(error =>
        console.log(`Wystąpił problem z odświeżeniem strony. ${error}`),
      );

    return () => {
      mounted = false;
    };
  }, [id, spd.results, spd.examTests]);

  return (
    <Card>
      <Tabs defaultActiveKey="results" id="examTests" className="mb-3">
        <Tab
          eventKey="results"
          title={`Wyniki - Badanie ${exam?.number || ''}, ${
            exam?.examined?.firstname || ''
          } ${exam?.examined?.surname || ''}`}
          className="m-3"
        >
          <Results data={results} />
          {exam?.id && (
            <Button
              variant="outline-secondary"
              className="ms-1"
              onClick={() => nav.examTests(exam?.id)}
            >
              Testy badania
            </Button>
          )}
          {exam?.id && (
            <Button
              variant="outline-secondary"
              className="ms-1"
              onClick={() => nav.indicatorTestsInExam(id)}
            >
              Wskaźniki badania
            </Button>
          )}
        </Tab>
      </Tabs>
    </Card>
  );
};
