import React, { useState } from 'react';
import { Form, Card, Button } from 'react-bootstrap';

import FormRow from '../../../app/components/FormRow';

import { TestGivingProps } from './utilsTestGiving';

interface Props {
  nextStep: () => void;
  handleFormData: (input: string) => (e: string) => void;
  values: TestGivingProps;
}

export const ChooseCountOfExams: React.FC<Props> = ({
  nextStep,
  handleFormData,
  values,
}) => {
  const [countOfExams, setCountOfExams] = useState(1);

  const handleOnNext = () => {
    nextStep();
    handleFormData('countOfExams')(countOfExams.toString());
  };

  const propertyChange = (count: string) => {
    setCountOfExams(+count);
    handleFormData('countOfExams')(count);
  };

  return (
    <>
      <Card>
        <Card.Body>
          <Form>
            <Form.Group className="mb-3">
              <FormRow controlId="countOfExams" label="Liczba badań">
                <Form.Control
                  type="number"
                  value={values.countOfExams}
                  onChange={e => propertyChange(e.target.value)}
                />
              </FormRow>
            </Form.Group>

            <div className="d-flex justify-content-end m-3">
              <Button
                variant="outline-primary"
                className="ms-1"
                onClick={handleOnNext}
              >
                Dalej
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};
