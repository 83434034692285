import React from 'react';
import { Card, Tab } from 'react-bootstrap';

import Tabs from '../../../app/components/Tabs';
import { CardsList } from '../../components/Cards/CardsList';

export const CardsView: React.FC = () => {
  return (
    <Card>
      <Tabs defaultActiveKey="search" id="cards" className="mb-3">
        <Tab eventKey="search" title="Karty" className="m-3">
          <CardsList />
        </Tab>
      </Tabs>
    </Card>
  );
};
