import React from 'react';
import { Form, Card, Button } from 'react-bootstrap';

import FormRow from '../../../app/components/FormRow';
import { CreateUpdateExamWithTestsDto } from '../../../common/spdCore/autogenerated/spdApiClient';
import momencik from '../../../common/momencik';
import { ExaminationType } from '../../../common/spdCore/validation/schemas';

interface Props {
  prevStep?: () => void;
  nextStep?: () => void;
  handleFormData?: (
    input: string,
  ) => (e: string | boolean | Date | undefined) => void;
  employmentCandidate?: boolean;
  employerName?: string;
  employerAddress?: string;
  employmentPosition?: string;
  referralDate?: Date;
  type?: ExaminationType;
  propertyChange?: (obj: Partial<CreateUpdateExamWithTestsDto>) => void;
}

export const AddEmployer: React.FC<Props> = ({
  prevStep,
  nextStep,
  handleFormData,
  employmentCandidate,
  employerName,
  employerAddress,
  employmentPosition,
  referralDate,
  type,
  propertyChange,
}) => {
  const handleOnNext = () => {
    nextStep && nextStep();
  };

  const handleOnPrev = () => {
    prevStep && prevStep();
  };

  const propertyChangeWithHandleFormData = (
    field: string,
    checked: boolean | string | Date | undefined,
  ) => {
    handleFormData && handleFormData(field)(checked);
    propertyChange && propertyChange({ [field]: checked });
  };

  return (
    <>
      <Card>
        <Card.Body>
          <Card.Subtitle className="mb-4">Pracodawca</Card.Subtitle>
          <Form className="d-grid gap-3">
            <FormRow controlId="employment_candidate" label="Kandydat">
              <Form.Check
                type="checkbox"
                checked={employmentCandidate ? true : false}
                onChange={e =>
                  propertyChangeWithHandleFormData(
                    'employmentCandidate',
                    e.target.checked,
                  )
                }
              />
            </FormRow>
            <FormRow controlId="employerName" label="Nazwa pracodawcy">
              <Form.Control
                as="textarea"
                rows={3}
                value={employerName || ''}
                onChange={e =>
                  propertyChangeWithHandleFormData(
                    'employerName',
                    e.target.value,
                  )
                }
              />
            </FormRow>
            <FormRow controlId="employerAddress" label="Adres pracodawcy">
              <Form.Control
                as="textarea"
                rows={3}
                value={employerAddress || ''}
                onChange={e =>
                  propertyChangeWithHandleFormData(
                    'employerAddress',
                    e.target.value,
                  )
                }
              />
            </FormRow>
            <FormRow controlId="employmentPosition" label="Stanowisko">
              <Form.Control
                as="textarea"
                rows={3}
                value={employmentPosition || ''}
                onChange={e =>
                  propertyChangeWithHandleFormData(
                    'employmentPosition',
                    e.target.value,
                  )
                }
              />
            </FormRow>
            <FormRow controlId="referralDate" label="Data skierowania">
              <Form.Control
                type="date"
                name="referralDate"
                placeholder="DateRange"
                value={momencik(referralDate, 'YYYY-MM-DD')}
                onChange={e =>
                  propertyChangeWithHandleFormData(
                    'referralDate',
                    new Date(e.target.value),
                  )
                }
              />
            </FormRow>
            <FormRow controlId="type" label="Rodzaj badania lekarskiego">
              <Form.Control
                as={Form.Select}
                value={type || ''}
                onChange={e =>
                  propertyChangeWithHandleFormData(
                    'type',
                    !e.target.value ? undefined : e.target.value,
                  )
                }
              >
                <option value=""></option>
                <option value="Initial">wstępne</option>
                <option value="Periodic">okresowe</option>
                <option value="Control">kontrolne</option>
                <option value="PeriodicControl">okresowo-kontrolne</option>
              </Form.Control>
            </FormRow>

            {prevStep && nextStep && (
              <div className="d-flex justify-content-end m-3">
                <Button
                  variant="outline-secondary"
                  className="ms-1"
                  onClick={handleOnPrev}
                >
                  Wstecz
                </Button>

                <Button
                  variant="outline-primary"
                  className="ms-1"
                  onClick={handleOnNext}
                >
                  Dalej
                </Button>
              </div>
            )}
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};
