import React, { useState } from 'react';
import { Button } from 'react-bootstrap';

import { useSpdCore } from '../../../common/hooks/useSpdCore';
import {
  CreateUpdateHealthcareFacilityDto,
  GetHealthcareFacilityDto,
} from '../../../common/spdCore/autogenerated/spdApiClient';
import { responseErrors } from '../../../common/spdCore/validation/responseErrors';
import {
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import {
  healthcareFacilityData,
  validate,
} from '../../../common/spdCore/contexts/HealthcareFacilityContext';

import { HealthcareFacilitySearch } from './HealthcareFacilitySearch';
import { HealthcareFacilityEditor } from './HealthcareFacilityEditor';

interface HealthcareFacilityListProps {
  getData: GridGetterFunction;
  getCount: GridCountFunction;
  onRowClick?: (key: string) => Promise<void> | void | undefined;
}

export const HealthcareFacilityList: React.FC<HealthcareFacilityListProps> = ({
  getData,
  getCount,
  onRowClick,
}) => {
  const spd = useSpdCore();

  const [show, setShow] = useState(false);
  const [healthcareFacility, setHealthcareFacility] =
    useState<GetHealthcareFacilityDto>();

  const [refresh, setRefresh] = useState<boolean>(false);

  const handleAdd = () => {
    setShow(true);
    setHealthcareFacility(undefined);
  };

  const addEditHF = async (
    hfCu: CreateUpdateHealthcareFacilityDto,
    id?: string,
  ) => {
    const status = await validate(hfCu);
    if (status.valid) {
      try {
        if (id) {
          await spd.healthCareFacilities.update(id, hfCu);
        } else {
          await spd.healthCareFacilities.create(hfCu);
        }
        setShow(false);
        setHealthcareFacility(undefined);
        setRefresh(!refresh);
        return {
          saved: true,
          errors: [],
        };
      } catch (response) {
        return {
          saved: false,
          errors: await responseErrors(response as Response),
        };
      }
    } else {
      return {
        saved: false,
        errors: status.errors,
      };
    }
  };

  const handleClose = () => {
    setShow(false);
    setHealthcareFacility(undefined);
    setRefresh(!refresh);
  };

  const handleClick = async (id: string) => {
    const getHF = await spd.healthCareFacilities.get(id);
    setHealthcareFacility(getHF);
    setShow(true);
  };

  return (
    <>
      <HealthcareFacilitySearch
        getData={getData}
        getCount={getCount}
        onRowClick={onRowClick ? onRowClick : handleClick}
        refresh={refresh}
      />
      <Button variant="outline-primary" onClick={handleAdd}>
        Nowa placówka
      </Button>
      <HealthcareFacilityEditor
        id={healthcareFacility?.id}
        healthcareFacility={healthcareFacilityData(healthcareFacility)}
        onCompleted={addEditHF}
        show={show}
        handleClose={handleClose}
      />
    </>
  );
};
