import React, { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import { useSpdCore } from '../../../common/hooks/useSpdCore';
import {
  CreateUpdateConsultationDto,
  GetArticleDto,
  GetConsultationDto,
  GetExamDto,
} from '../../../common/spdCore/autogenerated/spdApiClient';
import {
  ArticleMode,
  ConsultationTypeText,
} from '../../../common/spdCore/validation/schemas';
import { ExamInfo } from '../../components/ExamJudgments/ExamInfo';
import { ConsultationNeurologistEditor } from '../../components/ExamConsultations/ConsultationNeurologistEditor';
import { consultationData } from '../../../common/spdCore/contexts/ConsultationContext';
import { ConsultationOphthalmologistEditor } from '../../components/ExamConsultations/ConsultationOphthalmologistEditor';
import { ConsultationENTEditor } from '../../components/ExamConsultations/ConsultationENTEditor';
import { ConsultationPsychiatricEditor } from '../../components/ExamConsultations/ConsultationPsychiatricEditor';

type ParamTypes = {
  type: ConsultationTypeText;
  id: string;
};

export const ExamConsultationsView: React.FC = () => {
  const spd = useSpdCore();
  const mountedRef = useRef(false);

  const [exam, setExam] = useState<GetExamDto | null | undefined>();
  const [cons, setCons] = useState<GetConsultationDto | undefined>();
  const [newCons, setNewCons] = useState<
    CreateUpdateConsultationDto | undefined
  >();
  const [allArticles, setAllArticles] = useState<GetArticleDto[]>([]);

  const { type, id } = useParams<ParamTypes>() as {
    type: ConsultationTypeText;
    id: string;
  };

  useEffect(() => {
    mountedRef.current = true;

    spd.consultations
      .getForExam(id)
      .then(res => {
        if (mountedRef.current) {
          const pom = res.filter(
            c => c.type && ConsultationTypeText[c.type] === type,
          );
          if (pom.length === 1) {
            setCons(pom[0]);
            setExam(pom[0].exam);
            setNewCons(consultationData(pom[0]));
          } else {
            setCons(undefined);
            spd.exams
              .getExam(id)
              .then(res => {
                if (mountedRef.current) {
                  setExam(res);
                  setCons(undefined);
                  setNewCons(consultationData(undefined));
                }
              })
              .catch(error =>
                console.log(`Wystąpił problem z odświeżeniem strony. ${error}`),
              );
          }
        }
      })
      .catch(error => {
        console.log(`Wystąpił problem z odświeżeniem strony. ${error}`);
      });

    return () => {
      mountedRef.current = false;
    };
  }, [id, spd.consultations, spd.exams, type]);

  useEffect(() => {
    spd.articles
      .getAll()
      .then(article => setAllArticles(article))
      .catch(error =>
        console.log(`Wystąpił problem z pobraniem podstaw prawnych. ${error}`),
      );
  }, [spd.articles]);

  if (!newCons) {
    return null;
  }
  return (
    <>
      {exam && (
        <Row>
          <Col className="col-lg-4 col-12 mb-1">
            <ExamInfo
              exam={exam}
              articles={allArticles}
              mode={ArticleMode.Doctor}
            />
          </Col>
          <Col className="col-lg-8 col-12 mb-1">
            {type === ConsultationTypeText.Neurological && (
              <ConsultationNeurologistEditor
                exam={exam}
                consultation={newCons}
                id={cons?.id}
              />
            )}
            {type === ConsultationTypeText.Ophthalmologist && (
              <ConsultationOphthalmologistEditor
                exam={exam}
                consultation={newCons}
                id={cons?.id}
              />
            )}
            {type === ConsultationTypeText.ENT && (
              <ConsultationENTEditor
                exam={exam}
                consultation={newCons}
                id={cons?.id}
              />
            )}
            {type === ConsultationTypeText.Psychiatric && (
              <ConsultationPsychiatricEditor
                exam={exam}
                consultation={newCons}
                id={cons?.id}
              />
            )}
          </Col>
        </Row>
      )}
    </>
  );
};
