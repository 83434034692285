/* tslint:disable */
/* eslint-disable */
/**
 * SPD
 * Opis api SPD
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetTestDto,
    GetTestDtoFromJSON,
    GetTestDtoFromJSONTyped,
    GetTestDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetKeyTestDto
 */
export interface GetKeyTestDto {
    /**
     * 
     * @type {string}
     * @memberof GetKeyTestDto
     */
    id: string;
    /**
     * 
     * @type {GetTestDto}
     * @memberof GetKeyTestDto
     */
    test?: GetTestDto | null;
    /**
     * 
     * @type {string}
     * @memberof GetKeyTestDto
     */
    name?: string | null;
}

export function GetKeyTestDtoFromJSON(json: any): GetKeyTestDto {
    return GetKeyTestDtoFromJSONTyped(json, false);
}

export function GetKeyTestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetKeyTestDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'test': !exists(json, 'test') ? undefined : GetTestDtoFromJSON(json['test']),
        'name': !exists(json, 'name') ? undefined : json['name'],
    };
}

export function GetKeyTestDtoToJSON(value?: GetKeyTestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'test': GetTestDtoToJSON(value.test),
        'name': value.name,
    };
}


