/* tslint:disable */
/* eslint-disable */
/**
 * SPD
 * Opis api SPD
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUpdateExamTestDto
 */
export interface CreateUpdateExamTestDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateExamTestDto
     */
    examId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateExamTestDto
     */
    testId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CreateUpdateExamTestDto
     */
    lp?: number | null;
}

export function CreateUpdateExamTestDtoFromJSON(json: any): CreateUpdateExamTestDto {
    return CreateUpdateExamTestDtoFromJSONTyped(json, false);
}

export function CreateUpdateExamTestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateExamTestDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'examId': !exists(json, 'examId') ? undefined : json['examId'],
        'testId': !exists(json, 'testId') ? undefined : json['testId'],
        'lp': !exists(json, 'lp') ? undefined : json['lp'],
    };
}

export function CreateUpdateExamTestDtoToJSON(value?: CreateUpdateExamTestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'examId': value.examId,
        'testId': value.testId,
        'lp': value.lp,
    };
}


