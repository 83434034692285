import React, { useState } from 'react';
import { Button, Card, Tab } from 'react-bootstrap';

import Tabs from '../../../app/components/Tabs';
import { useSpdCore } from '../../../common/hooks/useSpdCore';
import { responseErrors } from '../../../common/spdCore/validation/responseErrors';
import { GetSupportingExaminationDictDto } from '../../../common/spdCore/autogenerated/spdApiClient';
import { CreateUpdateSupportingExaminationDictDto } from '../../../common/spdCore/autogenerated/spdApiClient/models/CreateUpdateSupportingExaminationDictDto';
import { SupportingExaminationDictSearch } from '../../components/SupportingExaminationDict/SupportingExaminationDictSearch';
import { SupportingExaminationDictEditor } from '../../components/SupportingExaminationDict/SupportingExaminationDictEditor';
import {
  supportingExaminationDictData,
  validate,
} from '../../../common/spdCore/contexts/SupportingExaminationDictContext';

export const SupportingExaminationDictView: React.FC = () => {
  const spd = useSpdCore();
  const [show, setShow] = useState(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [supportingExamDict, setSupportingExamDict] =
    useState<GetSupportingExaminationDictDto>();

  const handleAdd = () => {
    setShow(true);
    setSupportingExamDict(undefined);
  };

  const handleClose = () => {
    setShow(false);
    setRefresh(!refresh);
  };

  const addEditSupportingExamDict = async (
    hfCu: CreateUpdateSupportingExaminationDictDto,
    id?: string,
  ) => {
    const status = await validate(hfCu);
    if (status.valid) {
      try {
        if (id) {
          await spd.supportingExaminationDicts.update(id, hfCu);
        } else {
          await spd.supportingExaminationDicts.create(hfCu);
        }
        setShow(false);
        setRefresh(!refresh);
        return {
          saved: true,
          errors: [],
        };
      } catch (response) {
        return {
          saved: false,
          errors: await responseErrors(response as Response),
        };
      }
    } else {
      return {
        saved: false,
        errors: status.errors,
      };
    }
  };

  const onRowClick = async (id: string) => {
    const getHF = await spd.supportingExaminationDicts.get(id);
    setSupportingExamDict(getHF);
    setShow(true);
  };

  return (
    <Card>
      <Tabs defaultActiveKey="search" id="supportingExamDict" className="mb-3">
        <Tab
          eventKey="search"
          title="Rodzaje badań pomocniczych"
          className="m-3"
        >
          <SupportingExaminationDictSearch
            getData={spd.supportingExaminationDicts.getAllByPortion}
            getCount={spd.supportingExaminationDicts.getAllCount}
            refresh={refresh}
            onRowClick={onRowClick}
          />
          <Button variant="outline-primary" onClick={handleAdd}>
            Nowy rodzaj badania pomocniczego
          </Button>
          <SupportingExaminationDictEditor
            id={supportingExamDict?.id}
            supportingExamDict={supportingExaminationDictData(
              supportingExamDict,
            )}
            onCompleted={addEditSupportingExamDict}
            show={show}
            handleClose={handleClose}
          />
        </Tab>
      </Tabs>
    </Card>
  );
};
