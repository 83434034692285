/* tslint:disable */
/* eslint-disable */
/**
 * SPD
 * Opis api SPD
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetHarmfulFactorDto
 */
export interface GetHarmfulFactorDto {
    /**
     * 
     * @type {string}
     * @memberof GetHarmfulFactorDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetHarmfulFactorDto
     */
    name?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetHarmfulFactorDto
     */
    active: boolean;
}

export function GetHarmfulFactorDtoFromJSON(json: any): GetHarmfulFactorDto {
    return GetHarmfulFactorDtoFromJSONTyped(json, false);
}

export function GetHarmfulFactorDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetHarmfulFactorDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'active': json['active'],
    };
}

export function GetHarmfulFactorDtoToJSON(value?: GetHarmfulFactorDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'active': value.active,
    };
}


