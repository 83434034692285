import React from 'react';

import { Grid } from '../../../common/components/Grid/Grid';
import {
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import {
  GetBatteryWithTestsDto,
  GetTestDto,
} from '../../../common/spdCore/autogenerated/spdApiClient';

interface BatteryProps {
  getData: GridGetterFunction;
  getCount: GridCountFunction;
  onRowClick: (key: string) => Promise<void> | void | undefined;
  refresh?: boolean;
  enableSingleSelect?: boolean;
  selectedKeys?: string[];
}

export const batteryForGrid = (battery: GetBatteryWithTestsDto) => {
  return {
    key: battery.id,
    values: {
      'battery.name': battery.name,
      'battery.description': battery.description,
      'battery.count': battery.tests ? battery.tests.length : 0,
      'battery.tests': battery.tests
        ? battery.tests.map(t => (t as unknown as GetTestDto).name).join(', ')
        : '',
    },
  };
};

export const Battery: React.FC<BatteryProps> = props => {
  const columns = [
    { header: 'Nazwa', property: 'battery.name' },
    { header: 'Opis', property: 'battery.description' },
    { header: 'Liczba testów', property: 'battery.count', noSorting: true },
    { header: 'Wybrane testy', property: 'battery.tests', noSorting: true },
  ];

  return (
    <Grid
      getData={props.getData}
      getDataCount={props.getCount}
      columns={columns}
      showFilter={true}
      initialOrderColumn="battery.name"
      availablePageSizes={[10, 20, 50]}
      onRowClick={props.onRowClick}
      emptyGridMessage="Brak baterii testów w systemie"
      refresh={props.refresh}
      enableSingleSelect={props.enableSingleSelect}
      selectedKeys={props.selectedKeys}
    />
  );
};
