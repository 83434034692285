/* tslint:disable */
/* eslint-disable */
/**
 * SPD
 * Opis api SPD
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetRegisterDto
 */
export interface GetRegisterDto {
    /**
     * 
     * @type {string}
     * @memberof GetRegisterDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetRegisterDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetRegisterDto
     */
    pattern?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetRegisterDto
     */
    withUser?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof GetRegisterDto
     */
    mode?: GetRegisterDtoModeEnum;
}

export function GetRegisterDtoFromJSON(json: any): GetRegisterDto {
    return GetRegisterDtoFromJSONTyped(json, false);
}

export function GetRegisterDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetRegisterDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'pattern': !exists(json, 'pattern') ? undefined : json['pattern'],
        'withUser': !exists(json, 'withUser') ? undefined : json['withUser'],
        'mode': !exists(json, 'mode') ? undefined : json['mode'],
    };
}

export function GetRegisterDtoToJSON(value?: GetRegisterDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'pattern': value.pattern,
        'withUser': value.withUser,
        'mode': value.mode,
    };
}

/**
* @export
* @enum {string}
*/
export enum GetRegisterDtoModeEnum {
    OK = 'OK',
    KMP = 'KMP',
    BR = 'BR',
    K75 = 'K75',
    K43 = 'K43',
    KS = 'KS',
    KU = 'KU',
    LBR = 'LBR'
}


