/* tslint:disable */
/* eslint-disable */
/**
 * SPD
 * Opis api SPD
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetCardDto,
    GetCardDtoFromJSON,
    GetCardDtoFromJSONTyped,
    GetCardDtoToJSON,
    GetExamDto,
    GetExamDtoFromJSON,
    GetExamDtoFromJSONTyped,
    GetExamDtoToJSON,
    GetSupportingExaminationDictDto,
    GetSupportingExaminationDictDtoFromJSON,
    GetSupportingExaminationDictDtoFromJSONTyped,
    GetSupportingExaminationDictDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetSupportingExaminationDto
 */
export interface GetSupportingExaminationDto {
    /**
     * 
     * @type {string}
     * @memberof GetSupportingExaminationDto
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof GetSupportingExaminationDto
     */
    lp?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetSupportingExaminationDto
     */
    type?: GetSupportingExaminationDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof GetSupportingExaminationDto
     */
    examKind?: string | null;
    /**
     * 
     * @type {GetSupportingExaminationDictDto}
     * @memberof GetSupportingExaminationDto
     */
    examKindDict?: GetSupportingExaminationDictDto | null;
    /**
     * 
     * @type {Date}
     * @memberof GetSupportingExaminationDto
     */
    referralDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof GetSupportingExaminationDto
     */
    consultationDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof GetSupportingExaminationDto
     */
    result?: string | null;
    /**
     * 
     * @type {GetExamDto}
     * @memberof GetSupportingExaminationDto
     */
    exam?: GetExamDto | null;
    /**
     * 
     * @type {string}
     * @memberof GetSupportingExaminationDto
     */
    diagnosis?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetSupportingExaminationDto
     */
    purpose?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetSupportingExaminationDto
     */
    codeICD?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetSupportingExaminationDto
     */
    printReferral: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetSupportingExaminationDto
     */
    withoutSign: boolean;
    /**
     * 
     * @type {GetCardDto}
     * @memberof GetSupportingExaminationDto
     */
    card?: GetCardDto | null;
    /**
     * 
     * @type {string}
     * @memberof GetSupportingExaminationDto
     */
    pdfTemplateType?: GetSupportingExaminationDtoPdfTemplateTypeEnum;
}

export function GetSupportingExaminationDtoFromJSON(json: any): GetSupportingExaminationDto {
    return GetSupportingExaminationDtoFromJSONTyped(json, false);
}

export function GetSupportingExaminationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSupportingExaminationDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'lp': !exists(json, 'lp') ? undefined : json['lp'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'examKind': !exists(json, 'examKind') ? undefined : json['examKind'],
        'examKindDict': !exists(json, 'examKindDict') ? undefined : GetSupportingExaminationDictDtoFromJSON(json['examKindDict']),
        'referralDate': !exists(json, 'referralDate') ? undefined : (json['referralDate'] === null ? null : new Date(json['referralDate'])),
        'consultationDate': !exists(json, 'consultationDate') ? undefined : (json['consultationDate'] === null ? null : new Date(json['consultationDate'])),
        'result': !exists(json, 'result') ? undefined : json['result'],
        'exam': !exists(json, 'exam') ? undefined : GetExamDtoFromJSON(json['exam']),
        'diagnosis': !exists(json, 'diagnosis') ? undefined : json['diagnosis'],
        'purpose': !exists(json, 'purpose') ? undefined : json['purpose'],
        'codeICD': !exists(json, 'codeICD') ? undefined : json['codeICD'],
        'printReferral': json['printReferral'],
        'withoutSign': json['withoutSign'],
        'card': !exists(json, 'card') ? undefined : GetCardDtoFromJSON(json['card']),
        'pdfTemplateType': !exists(json, 'pdfTemplateType') ? undefined : json['pdfTemplateType'],
    };
}

export function GetSupportingExaminationDtoToJSON(value?: GetSupportingExaminationDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'lp': value.lp,
        'type': value.type,
        'examKind': value.examKind,
        'examKindDict': GetSupportingExaminationDictDtoToJSON(value.examKindDict),
        'referralDate': value.referralDate === undefined ? undefined : (value.referralDate === null ? null : value.referralDate.toISOString()),
        'consultationDate': value.consultationDate === undefined ? undefined : (value.consultationDate === null ? null : value.consultationDate.toISOString()),
        'result': value.result,
        'exam': GetExamDtoToJSON(value.exam),
        'diagnosis': value.diagnosis,
        'purpose': value.purpose,
        'codeICD': value.codeICD,
        'printReferral': value.printReferral,
        'withoutSign': value.withoutSign,
        'card': GetCardDtoToJSON(value.card),
        'pdfTemplateType': value.pdfTemplateType,
    };
}

/**
* @export
* @enum {string}
*/
export enum GetSupportingExaminationDtoTypeEnum {
    HelpExam = 'helpExam',
    SpecialisedConsultation = 'specialisedConsultation'
}
/**
* @export
* @enum {string}
*/
export enum GetSupportingExaminationDtoPdfTemplateTypeEnum {
    Art82Ust1 = 'art_82_ust_1',
    Art82Uprzywilejowany = 'art_82_uprzywilejowany',
    Art82InstruktorEgzaminator = 'art_82_instruktor_egzaminator',
    Art39TransportDrogowy = 'art_39_transport_drogowy',
    MedycynaPracy = 'medycyna_pracy',
    KartaBadaniaPsychologicznego = 'karta_badania_psychologicznego',
    KartaBroni = 'karta_broni',
    BronOrzeczenieLekarskie = 'bron_orzeczenie_lekarskie',
    BronOrzeczeniePsychologiczne = 'bron_orzeczenie_psychologiczne',
    BronKartaBadaniaPsychologicznego = 'bron_karta_badania_psychologicznego',
    KartaBadaniaProfilaktycznego = 'karta_badania_profilaktycznego',
    MpOrzeczenieLekarskie43 = 'mp_orzeczenie_lekarskie_43',
    MpOrzeczenieLekarskie43Ang = 'mp_orzeczenie_lekarskie_43_ang',
    MpOrzeczenieLekarskie43Transport = 'mp_orzeczenie_lekarskie_43_transport',
    KierowcaOrzeczenieLekarskieArt75Uprzywilejowany = 'kierowca_orzeczenie_lekarskie_art_75_uprzywilejowany',
    KierowcaOrzeczenieLekarskieArt75Reszta = 'kierowca_orzeczenie_lekarskie_art_75_reszta',
    MpZaswiadczenieLekarskieStudent = 'mp_zaswiadczenie_lekarskie_student',
    OrzeczenieSanitarnoEpidemiologiczne = 'orzeczenie_sanitarno_epidemiologiczne',
    KartaBadaniaKierowcy = 'karta_badania_kierowcy',
    KonsultacjaNeurologiczna = 'konsultacja_neurologiczna',
    KonsultacjaLaryngologiczna = 'konsultacja_laryngologiczna',
    KonsultacjaOkulistyczna = 'konsultacja_okulistyczna',
    KonsultacjaPsychiatryczna = 'konsultacja_psychiatryczna',
    KartaOdpowiedzi = 'karta_odpowiedzi',
    KartaBadaniaSanitarnoEpidemiologicznego = 'karta_badania_sanitarno_epidemiologicznego',
    ZaswiadczenieLekarskieZdolnosciDoPracy = 'zaswiadczenie_lekarskie_zdolnosci_do_pracy',
    ZaswiadczenieSportowe = 'zaswiadczenie_sportowe',
    KartaBadaniaSportowego = 'karta_badania_sportowego',
    OrzeczenieStanZdrowiaLekarza = 'orzeczenie_stan_zdrowia_lekarza',
    ZaswiadczenieStanZdrowiaPielegniarka = 'zaswiadczenie_stan_zdrowia_pielegniarka',
    ZaswiadczenieStanZdrowiaPielegniarkaArt31 = 'zaswiadczenie_stan_zdrowia_pielegniarka_art31',
    RejestrOrzeczen = 'rejestrOrzeczen',
    SkierowanieUczen = 'skierowanie_uczen',
    SkierowaniePracownik = 'skierowanie_pracownik',
    WynikWidzeniaZmierzchowego = 'wynik_widzenia_zmierzchowego',
    WybuchCywilneOrzeczenieLekarskie = 'wybuch_cywilne_orzeczenie_lekarskie',
    WybuchCywilneOrzeczeniePsychologiczne = 'wybuch_cywilne_orzeczenie_psychologiczne',
    WybuchWojskoOrzeczenieLekarskie = 'wybuch_wojsko_orzeczenie_lekarskie',
    WybuchWojskoOrzeczeniePsychologiczne = 'wybuch_wojsko_orzeczenie_psychologiczne',
    DetektywOrzeczenieLekarskie = 'detektyw_orzeczenie_lekarskie',
    DetektywOrzeczeniePsychologiczne = 'detektyw_orzeczenie_psychologiczne',
    ZestawienieOrzeczen = 'zestawienie_orzeczen',
    ListaFirm = 'lista_firm',
    SkierowanieDoPoradni = 'skierowanie_do_poradni'
}


