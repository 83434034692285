export type KeyXls = {
  nazwa_klucza: string;
  identyfikator_testu: string;
  nr_zadania: string;
  prawidlowa_odpowiedz: string;
  waga: string;
};

export const validateKeys = (keys: KeyXls[]) => {
  keys.shift();
  const errors: string[] = [];
  if (!keys[0].nazwa_klucza) {
    errors.push('Brak wypełnionego pola "nazwa_klucza"');
  }
  if (!keys[0].identyfikator_testu) {
    errors.push('Brak wypełnionego pola "identyfikator_testu"');
  }
  if (!keys[0].nr_zadania) {
    errors.push('Brak wypełnionego pola "nr_zadania"');
  }
  if (!keys[0].waga) {
    errors.push('Brak wypełnionego pola "waga"');
  }
  if (errors.length > 0) {
    return {
      ok: false,
      errors: errors,
    };
  }
  return {
    ok: true,
    errors: [],
  };
};
