/* tslint:disable */
/* eslint-disable */
/**
 * SPD
 * Opis api SPD
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetUserDto,
    GetUserDtoFromJSON,
    GetUserDtoFromJSONTyped,
    GetUserDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetDutyDto
 */
export interface GetDutyDto {
    /**
     * 
     * @type {string}
     * @memberof GetDutyDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetDutyDto
     */
    name?: string | null;
    /**
     * 
     * @type {GetUserDto}
     * @memberof GetDutyDto
     */
    psychologist?: GetUserDto | null;
    /**
     * 
     * @type {GetUserDto}
     * @memberof GetDutyDto
     */
    doctor?: GetUserDto | null;
}

export function GetDutyDtoFromJSON(json: any): GetDutyDto {
    return GetDutyDtoFromJSONTyped(json, false);
}

export function GetDutyDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetDutyDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'psychologist': !exists(json, 'psychologist') ? undefined : GetUserDtoFromJSON(json['psychologist']),
        'doctor': !exists(json, 'doctor') ? undefined : GetUserDtoFromJSON(json['doctor']),
    };
}

export function GetDutyDtoToJSON(value?: GetDutyDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'psychologist': GetUserDtoToJSON(value.psychologist),
        'doctor': GetUserDtoToJSON(value.doctor),
    };
}


