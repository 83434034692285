import React, { useEffect } from 'react';
import { Button, Card, Tab } from 'react-bootstrap';

import { useCookies } from '../../../common/hooks/useCookies';
import { useSpdCore } from '../../../common/hooks/useSpdCore';
import { useNavigation } from '../../../common/navigation';
import { ExamSearch } from '../../components/Exam/ExamSearch';
import { TabsHistory } from '../../../app/components/TabsHistory';
import { ArticleMode } from '../../../common/spdCore/validation/schemas';

import { ExamsActiveView } from './ExamsActiveView';

export const ExamsDoctorView: React.FC = () => {
  const spd = useSpdCore();
  const nav = useNavigation();
  const cookies = useCookies();

  useEffect(() => {
    if (cookies.activeMonitor !== 'doctor') {
      cookies.setActiveMonitor('doctor');
    }
  }, [cookies]);

  const handleClick = async (id: string) => {
    nav.examTestsDoctor(id);
  };

  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
  const handleSelect = (key: any) => {
    cookies.setActiveTabMonitor(key as string);
  };

  return (
    <Card>
      <TabsHistory
        activeKey={
          cookies.activeTabMonitor ? cookies.activeTabMonitor : 'activeExams'
        }
        id="exams"
        className="mb-3"
        onAdditionalSelect={handleSelect}
      >
        <Tab
          eventKey="withoutJudgmentExams"
          title="Badania lekarskie z orzeczeniami do wydania"
          className="m-3"
        >
          <ExamSearch
            getData={(
              pageSize,
              pageNumber,
              orderBy,
              orderDirection,
              filterText,
            ) =>
              spd.exams.getWithoutJudgmentByPortion(
                pageSize,
                pageNumber,
                orderBy,
                orderDirection,
                filterText,
                ArticleMode.Doctor,
              )
            }
            getCount={filterText =>
              spd.exams.getWithoutJudgmentCount(filterText, ArticleMode.Doctor)
            }
            onRowClick={handleClick}
          />
        </Tab>
        <Tab eventKey="activeExams" title="aktywne" className="m-3">
          <ExamsActiveView
            getData={(
              pageSize,
              pageNumber,
              orderBy,
              orderDirection,
              filterText,
            ) =>
              spd.exams.getActiveByPortion(
                pageSize,
                pageNumber,
                orderBy,
                orderDirection,
                filterText,
                ArticleMode.Doctor,
              )
            }
            getCount={filterText =>
              spd.exams.getActiveCount(filterText, ArticleMode.Doctor)
            }
            onRowClick={handleClick}
          />
          {process.env.REACT_APP_CHAT_APP_URL && (
            <Button
              variant="outline-secondary"
              className="ms-1"
              onClick={nav.chats}
            >
              Podgląd badanych
            </Button>
          )}
        </Tab>
        <Tab eventKey="examsWithExamined" title="z badanym" className="m-3">
          <ExamSearch
            getData={(
              pageSize,
              pageNumber,
              orderBy,
              orderDirection,
              filterText,
            ) =>
              spd.exams.getWithExaminedByPortion(
                pageSize,
                pageNumber,
                orderBy,
                orderDirection,
                filterText,
                ArticleMode.Doctor,
              )
            }
            getCount={filterText =>
              spd.exams.getWithExaminedCount(filterText, ArticleMode.Doctor)
            }
            onRowClick={handleClick}
          />
        </Tab>
        <Tab
          eventKey="exams"
          title="Wszystkie badania lekarskie"
          className="m-3"
        >
          <ExamSearch
            getData={(
              pageSize,
              pageNumber,
              orderBy,
              orderDirection,
              filterText,
            ) =>
              spd.exams.getAllByPortion(
                pageSize,
                pageNumber,
                orderBy,
                orderDirection,
                filterText,
                ArticleMode.Doctor,
              )
            }
            getCount={filterText =>
              spd.exams.getAllCount(filterText, ArticleMode.Doctor)
            }
            onRowClick={handleClick}
          />
        </Tab>
      </TabsHistory>
    </Card>
  );
};
