import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import Card from 'react-bootstrap/Card';
import { Form } from 'react-bootstrap';

import FormRow from '../../../app/components/FormRow';
import { CreateUpdateExaminedDto } from '../../../common/spdCore/autogenerated/spdApiClient';
import {
  DrivingLicenseCategory,
  Gender,
} from '../../../common/spdCore/validation/schemas';
import momencik from '../../../common/momencik';

interface ExaminedEditorLeftSideProps {
  propertyChange: (obj: Partial<CreateUpdateExaminedDto>) => void;
  readOnly: boolean;
  show?: boolean;
  examined: CreateUpdateExaminedDto;
  onSelectChange: (keys: string[]) => void;
}

export const ExaminedEditorLeftSide: React.FC<
  ExaminedEditorLeftSideProps
> = props => {
  const [examined, setExamined] = useState(props.examined);

  useEffect(() => {
    if (props.show) {
      setExamined(props.examined);
    }
  }, [props.show, props.examined]);

  const getAllDrivingCategories = () => {
    const arr = Object.keys(DrivingLicenseCategory).map(name => {
      return {
        value: name,
        label:
          DrivingLicenseCategory[name as keyof typeof DrivingLicenseCategory],
      };
    });
    return arr;
  };

  const propertyChange = (obj: Partial<CreateUpdateExaminedDto>) => {
    setExamined({ ...examined, ...obj });
    props.propertyChange(obj);
  };

  const getSelectedDrivingCategories = () => {
    return getAllDrivingCategories().filter(item =>
      props.examined.drivingLicense?.split(',').includes(item.value),
    );
  };

  return (
    <Card className="mb-2">
      <Card.Header>Badany</Card.Header>
      <Card.Body>
        <Form className="d-grid gap-3">
          <FormRow controlId="examined" label="Imię">
            <Form.Control
              type="text"
              value={examined?.firstname || ''}
              onChange={e => propertyChange({ firstname: e.target.value })}
              disabled={props.readOnly}
            />
          </FormRow>
          <FormRow controlId="examined" label="Nazwisko">
            <Form.Control
              type="text"
              value={examined?.surname || ''}
              onChange={e => propertyChange({ surname: e.target.value })}
              disabled={props.readOnly}
            />
          </FormRow>
          <FormRow controlId="examined" label="PESEL">
            <Form.Control
              type="text"
              value={examined?.pesel || ''}
              onChange={e => propertyChange({ pesel: e.target.value })}
              disabled={props.readOnly}
            />
          </FormRow>
          <FormRow controlId="iDCardType" label="Nazwa dokumentu">
            <Form.Control
              type="text"
              value={examined?.iDCardType || ''}
              onChange={e => propertyChange({ iDCardType: e.target.value })}
              disabled={props.readOnly}
            />
          </FormRow>
          <FormRow controlId="iDCardNumber" label="Nr dokumentu">
            <Form.Control
              type="text"
              value={examined?.iDCardNumber || ''}
              onChange={e => propertyChange({ iDCardNumber: e.target.value })}
              disabled={props.readOnly}
            />
          </FormRow>
          <FormRow controlId="sex" label="Płeć">
            <Form.Control
              as={Form.Select}
              value={examined?.sex || ''}
              onChange={e =>
                propertyChange({
                  sex: e.target.value,
                })
              }
              disabled={props.readOnly}
            >
              <option value=""></option>
              <option value={Gender.Female}>Kobieta</option>
              <option value={Gender.Male}>Mężczyzna</option>
            </Form.Control>
          </FormRow>
          <FormRow controlId="date" label="Data urodzenia">
            <Form.Control
              type="date"
              name="datepic"
              placeholder="DateRange"
              value={momencik(examined?.dateOfBirth, 'YYYY-MM-DD')}
              onChange={e =>
                propertyChange({
                  dateOfBirth: new Date(e.target.value),
                })
              }
              disabled={props.readOnly}
            />
          </FormRow>
          <FormRow controlId="nationality" label="Narodowość">
            <Form.Control
              type="text"
              value={examined?.nationality || ''}
              onChange={e => propertyChange({ nationality: e.target.value })}
              disabled={props.readOnly}
            />
          </FormRow>
          <FormRow controlId="examined" label="Kategoria prawa jazdy">
            <Select
              options={getAllDrivingCategories()}
              value={getSelectedDrivingCategories()}
              isMulti
              className="test-multi-select"
              classNamePrefix="driving-select"
              onChange={options =>
                props.onSelectChange(options.map(o => o.value))
              }
              isDisabled={props.readOnly}
            />
          </FormRow>
          <FormRow
            controlId="drivingLicenseIssuer"
            label="Organ wydający uprawnienia"
          >
            <Form.Control
              type="text"
              value={examined?.drivingLicenseIssuer || ''}
              onChange={e =>
                propertyChange({ drivingLicenseIssuer: e.target.value })
              }
              disabled={props.readOnly}
            />
          </FormRow>
          <FormRow
            controlId="drivingLicenseYear"
            label="Rok uzyskania uprawnień"
          >
            <Form.Control
              type="number"
              value={examined?.drivingLicenseYear || ''}
              onChange={e =>
                propertyChange({ drivingLicenseYear: +e.target.value })
              }
            />
          </FormRow>
        </Form>
      </Card.Body>
    </Card>
  );
};
