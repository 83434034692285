/* tslint:disable */
/* eslint-disable */
/**
 * SPD
 * Opis api SPD
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetPdfTemplateDto,
    GetPdfTemplateDtoFromJSON,
    GetPdfTemplateDtoFromJSONTyped,
    GetPdfTemplateDtoToJSON,
    GetRegisterDto,
    GetRegisterDtoFromJSON,
    GetRegisterDtoFromJSONTyped,
    GetRegisterDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetArticleDto
 */
export interface GetArticleDto {
    /**
     * 
     * @type {string}
     * @memberof GetArticleDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetArticleDto
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetArticleDto
     */
    legalBasis?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetArticleDto
     */
    reason?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetArticleDto
     */
    code?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetArticleDto
     */
    lp?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetArticleDto
     */
    active: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetArticleDto
     */
    pdfTemplateId?: string | null;
    /**
     * 
     * @type {GetPdfTemplateDto}
     * @memberof GetArticleDto
     */
    pdfTemplate?: GetPdfTemplateDto | null;
    /**
     * 
     * @type {string}
     * @memberof GetArticleDto
     */
    mode?: GetArticleDtoModeEnum;
    /**
     * 
     * @type {GetRegisterDto}
     * @memberof GetArticleDto
     */
    register?: GetRegisterDto | null;
    /**
     * 
     * @type {string}
     * @memberof GetArticleDto
     */
    engPdfTemplateId?: string | null;
}

export function GetArticleDtoFromJSON(json: any): GetArticleDto {
    return GetArticleDtoFromJSONTyped(json, false);
}

export function GetArticleDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetArticleDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'legalBasis': !exists(json, 'legalBasis') ? undefined : json['legalBasis'],
        'reason': !exists(json, 'reason') ? undefined : json['reason'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'lp': !exists(json, 'lp') ? undefined : json['lp'],
        'active': json['active'],
        'pdfTemplateId': !exists(json, 'pdfTemplateId') ? undefined : json['pdfTemplateId'],
        'pdfTemplate': !exists(json, 'pdfTemplate') ? undefined : GetPdfTemplateDtoFromJSON(json['pdfTemplate']),
        'mode': !exists(json, 'mode') ? undefined : json['mode'],
        'register': !exists(json, 'register') ? undefined : GetRegisterDtoFromJSON(json['register']),
        'engPdfTemplateId': !exists(json, 'engPdfTemplateId') ? undefined : json['engPdfTemplateId'],
    };
}

export function GetArticleDtoToJSON(value?: GetArticleDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'description': value.description,
        'legalBasis': value.legalBasis,
        'reason': value.reason,
        'code': value.code,
        'lp': value.lp,
        'active': value.active,
        'pdfTemplateId': value.pdfTemplateId,
        'pdfTemplate': GetPdfTemplateDtoToJSON(value.pdfTemplate),
        'mode': value.mode,
        'register': GetRegisterDtoToJSON(value.register),
        'engPdfTemplateId': value.engPdfTemplateId,
    };
}

/**
* @export
* @enum {string}
*/
export enum GetArticleDtoModeEnum {
    Doctor = 'Doctor',
    Psychologist = 'Psychologist',
    Ophthalmologist = 'Ophthalmologist'
}


