import React from 'react';

import { useSpdCore } from '../../../common/hooks/useSpdCore';
import { ChangePassword } from '../../components/ChangePassword/ChangePassword';

export const ChangePasswordView: React.FC = () => {
  const spdApi = useSpdCore();

  const onChangePassword = async (password: string, oldPassword: string) => {
    return await spdApi.signIn.updatePassword(password, oldPassword);
  };
  return <ChangePassword onChangePassword={onChangePassword} />;
};
