import React, { useState } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

import { useSpdCore } from '../../../common/hooks/useSpdCore';
import { copyToClipboard } from '../../../common/copyToClipboard';

export const ExamsPackagePinsLink = (props: { examsPackageId: string }) => {
  const spdApi = useSpdCore();
  const [pins, setPins] = useState('');

  const copyPins = async () => {
    if (props.examsPackageId) {
      const newPins = await spdApi.examsPackage.getExpectationPins(
        props.examsPackageId,
      );
      setPins(newPins);

      await copyToClipboard(newPins);
    }
  };

  return (
    <>
      <OverlayTrigger
        overlay={
          <Tooltip>
            {pins
              ? `Skopiowano oczekujące piny do schowka: ${pins}`
              : 'Skopiuj oczekujące piny do schowka'}
          </Tooltip>
        }
      >
        <Button
          variant="btn-info"
          onClick={async e => {
            e.stopPropagation();
            await copyPins();
          }}
        >
          #
        </Button>
      </OverlayTrigger>
    </>
  );
};
