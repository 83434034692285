import React from 'react';

import { ExamSearch } from '../../components/Exam/ExamSearch';
import {
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';

interface ExamSearchProps {
  getData: GridGetterFunction;
  getCount: GridCountFunction;
  onRowClick: (key: string) => Promise<void> | void | undefined;
}

export const ExamsInActiveView: React.FC<ExamSearchProps> = props => {
  return (
    <>
      <ExamSearch
        getData={props.getData}
        getCount={props.getCount}
        onRowClick={props.onRowClick}
      />
    </>
  );
};
