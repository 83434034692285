/*eslint max-lines-per-function: ["error", 500]*/
/* eslint-disable complexity */
import React, { useEffect, useState } from 'react';
import { Form, Card } from 'react-bootstrap';

import FormRow from '../../../app/components/FormRow';
import {
  CreateUpdateExamWithTestsDto,
  CreateUpdateExamWithTestsDtoTypeEnum,
  CreateUpdateExamWithTestsDtoWhoExaminedEnum,
  CreateUpdateExaminedDto,
  CreateUpdateExternalReferralDto,
  GetBatteryDto,
} from '../../../common/spdCore/autogenerated/spdApiClient';
import { CreatableClearableSelector } from '../Selects/CreatableClearableSelector';
import {
  ExamWhoExamined,
  Gender,
  getBirthDateFromPesel,
  getSexFromPesel,
  isPeselValid,
} from '../../../common/spdCore/validation/schemas';
import { useSpdCore } from '../../../common/hooks/useSpdCore';
import ValidationAlert from '../../../app/components/ValidationAlert';
import { validate } from '../../../common/spdCore/contexts/ExternalReferralContext';
import ConfirmationButton from '../../../app/components/ConfirmationButton';
import { useNavigation } from '../../../common/navigation';
import momencik from '../../../common/momencik';
import BatterySelector from '../Battery/BatterySelector';
import { useAuth } from '../../../common/hooks/useAuth';
import HarmfulFactorMultiSelector from '../HarmfulFactor/HarmfulFactorMultiSelector';

interface Props {
  externalReferral: CreateUpdateExternalReferralDto;
  onSave?: (externalReferral: CreateUpdateExternalReferralDto) => Promise<{
    saved: boolean;
    errors: string[];
  }>;
}

export const ExternalReferral: React.FC<Props> = props => {
  const spdCore = useSpdCore();
  const nav = useNavigation();
  const auth = useAuth();
  const [showSaveErrors, setShowSaveErrors] = useState(false);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const [waiting, setWaiting] = useState(false);
  const [kindOfExam, setKindOfExam] = useState<ExamWhoExamined>();
  const [batteries, setBatteries] = useState<GetBatteryDto[]>([]);
  const [externalReferral, setExternalReferral] =
    useState<CreateUpdateExternalReferralDto>(props.externalReferral);

  useEffect(() => {
    setExternalReferral(props.externalReferral);
  }, [props.externalReferral]);

  useEffect(() => {
    if (auth.currentUser?.uid) {
      spdCore.companies
        .getByUserUid(auth.currentUser?.uid)
        .then(company => {
          const batt = company?.companiesBatteries?.map(o => o.battery) || [];
          if (!batt || batt.length === 0) {
            setSaveErrors([
              'Brak baterii testów. Zgłoś problem administratorowi.',
            ]);
            setShowSaveErrors(true);
          }
          setBatteries(batt);
        })
        .catch(error => {
          setSaveErrors([
            'Brak baterii testów. Zgłoś problem administratorowi. ' + error,
          ]);
          setShowSaveErrors(true);
        });
    }
  }, [spdCore.companies, auth.currentUser]);

  const propertyChangeExam = (obj: Partial<CreateUpdateExamWithTestsDto>) => {
    setExternalReferral({
      ...externalReferral,
      ...{ examWithTests: { ...externalReferral.examWithTests, ...obj } },
    });
  };

  const propertyChangeExamined = (obj: Partial<CreateUpdateExaminedDto>) => {
    if (
      Object.prototype.hasOwnProperty.call(obj, 'pesel') &&
      isPeselValid(obj.pesel) &&
      obj.pesel
    ) {
      setExternalReferral({
        ...externalReferral,
        ...{
          examined: {
            ...externalReferral.examined,
            dateOfBirth: getBirthDateFromPesel(obj.pesel),
            sex: getSexFromPesel(obj.pesel),
            pesel: obj.pesel,
          },
        },
      });
    } else {
      setExternalReferral({
        ...externalReferral,
        ...{ examined: { ...externalReferral.examined, ...obj } },
      });
    }
  };

  const textOrEmpty = (text: string | null | undefined) => text || '';

  const onSave = async (
    externalReferral: CreateUpdateExternalReferralDto,
  ): Promise<boolean> => {
    try {
      const status = await validate(externalReferral);
      if (!kindOfExam) {
        setSaveErrors(['Proszę wskazać typ badanego.']);
        setShowSaveErrors(true);
        setWaiting(false);
        return false;
      }
      if (!externalReferral?.examWithTests?.batteryId) {
        setSaveErrors([
          'Proszę wskazać, które badanie ma zostać wykonane badanemu.',
        ]);
        setShowSaveErrors(true);
        setWaiting(false);
        return false;
      }
      if (status.valid && props.onSave) {
        setWaiting(true);
        const saveStatus = await props.onSave(externalReferral);
        if (!saveStatus.saved) {
          setSaveErrors(saveStatus.errors);
          setShowSaveErrors(true);
          setWaiting(false);
          return false;
        }
        setShowSaveErrors(false);
        setWaiting(false);
        return true;
      } else {
        setSaveErrors(status.errors);
        setShowSaveErrors(true);
        setWaiting(false);
        return false;
      }
    } catch (error) {
      setSaveErrors([
        'Wystąpił błąd podczas dodawania skierowania zewnętrznego.',
      ]);
      setShowSaveErrors(true);
      setWaiting(false);
      return false;
    }
  };

  return (
    <Card>
      <Card.Body>
        <Card.Subtitle className="mb-4">Dane ogólne</Card.Subtitle>

        <Form className="d-grid gap-3">
          <FormRow controlId="kind" label="Typ badanego">
            <Form.Control
              as={Form.Select}
              onChange={e => {
                setKindOfExam(e.target.value as ExamWhoExamined);
                propertyChangeExam({
                  whoExamined: e.target
                    .value as CreateUpdateExamWithTestsDtoWhoExaminedEnum,
                });
              }}
            >
              <option value=""></option>
              <option value={ExamWhoExamined.Student}>Student / uczeń</option>
              <option value={ExamWhoExamined.Employee}>
                Pracownik / kandydat do pracy
              </option>
            </Form.Control>
          </FormRow>
          <FormRow controlId="battery" label="Badanie">
            <BatterySelector
              value={externalReferral?.examWithTests?.batteryId || undefined}
              onChange={e =>
                propertyChangeExam({
                  batteryId: e.value,
                })
              }
              batteries={batteries}
            />
          </FormRow>
          <FormRow controlId="type" label="Rodzaj skierowania">
            <Form.Control
              as={Form.Select}
              value={externalReferral?.examWithTests?.type}
              onChange={e =>
                propertyChangeExam({
                  type: e.target.value as CreateUpdateExamWithTestsDtoTypeEnum,
                })
              }
            >
              <option value=""></option>
              <option value={CreateUpdateExamWithTestsDtoTypeEnum.Initial}>
                wstępne
              </option>
              <option value={CreateUpdateExamWithTestsDtoTypeEnum.Periodic}>
                okresowe
              </option>
              <option value={CreateUpdateExamWithTestsDtoTypeEnum.Control}>
                kontrolne
              </option>
              <option
                value={CreateUpdateExamWithTestsDtoTypeEnum.PeriodicControl}
              >
                okresowo-kontrolne
              </option>
            </Form.Control>
          </FormRow>
          {kindOfExam && (
            <>
              <Card.Subtitle className="mb-4 mt-4">
                {kindOfExam === ExamWhoExamined.Employee
                  ? 'Dane pracownika/kandydata do pracy'
                  : 'Dane studenta/ucznia'}
              </Card.Subtitle>
              <FormRow controlId="examined" label="Imię">
                <Form.Control
                  type="text"
                  value={externalReferral?.examined?.firstname || ''}
                  onChange={e =>
                    propertyChangeExamined({ firstname: e.target.value })
                  }
                />
              </FormRow>
              <FormRow controlId="examined" label="Nazwisko">
                <Form.Control
                  type="text"
                  value={externalReferral?.examined?.surname || ''}
                  onChange={e =>
                    propertyChangeExamined({ surname: e.target.value })
                  }
                />
              </FormRow>
              <FormRow controlId="examined" label="PESEL">
                <Form.Control
                  type="text"
                  value={externalReferral?.examined?.pesel || ''}
                  onChange={e =>
                    propertyChangeExamined({ pesel: e.target.value })
                  }
                />
              </FormRow>
              <FormRow controlId="sex" label="Płeć">
                <Form.Control
                  as={Form.Select}
                  value={externalReferral?.examined?.sex || ''}
                  onChange={e =>
                    propertyChangeExamined({
                      sex: e.target.value,
                    })
                  }
                >
                  <option value=""></option>
                  <option value={Gender.Female}>Kobieta</option>
                  <option value={Gender.Male}>Mężczyzna</option>
                </Form.Control>
              </FormRow>
              <FormRow controlId="date" label="Data urodzenia">
                <Form.Control
                  type="date"
                  name="datepic"
                  placeholder="DateRange"
                  value={momencik(
                    externalReferral?.examined?.dateOfBirth,
                    'YYYY-MM-DD',
                  )}
                  onChange={e =>
                    propertyChangeExamined({
                      dateOfBirth: new Date(e.target.value),
                    })
                  }
                />
              </FormRow>
              <FormRow controlId="iDCardType" label="Nazwa dokumentu">
                <Form.Control
                  type="text"
                  value={externalReferral?.examined?.iDCardType || ''}
                  onChange={e =>
                    propertyChangeExamined({ iDCardType: e.target.value })
                  }
                />
              </FormRow>
              <FormRow controlId="iDCardNumber" label="Nr dokumentu">
                <Form.Control
                  type="text"
                  value={externalReferral?.examined?.iDCardNumber || ''}
                  onChange={e =>
                    propertyChangeExamined({ iDCardNumber: e.target.value })
                  }
                />
              </FormRow>
              <FormRow controlId="email" label="Email">
                <Form.Control
                  type="email"
                  value={textOrEmpty(externalReferral?.examined?.email)}
                  onChange={e =>
                    propertyChangeExamined({ email: e.target.value })
                  }
                />
              </FormRow>
              <FormRow controlId="phone" label="Telefon">
                <Form.Control
                  type="text"
                  value={textOrEmpty(externalReferral?.examined?.phone)}
                  onChange={e =>
                    propertyChangeExamined({ phone: e.target.value })
                  }
                />
              </FormRow>
              <Card.Subtitle className="mb-4 mt-4">
                Adres zamieszkania
              </Card.Subtitle>
              <FormRow controlId="postcode" label="Kod pocztowy">
                <Form.Control
                  type="text"
                  value={textOrEmpty(externalReferral?.examined?.postcode)}
                  onChange={e =>
                    propertyChangeExamined({ postcode: e.target.value })
                  }
                />
              </FormRow>
              <FormRow controlId="city" label="Miejscowość">
                <CreatableClearableSelector
                  value={textOrEmpty(externalReferral?.examined?.city)}
                  onChange={chosen =>
                    propertyChangeExamined({ city: chosen.label })
                  }
                  provider={spdCore.examineds.getCitiesByName}
                />
              </FormRow>
              <FormRow controlId="street" label="Ulica">
                <CreatableClearableSelector
                  value={textOrEmpty(externalReferral?.examined?.street)}
                  onChange={chosen =>
                    propertyChangeExamined({ street: chosen.label })
                  }
                  provider={spdCore.examineds.getStreetsByName}
                />
              </FormRow>
              <FormRow controlId="numbers" label=" Numer">
                <Form.Control
                  type="text"
                  value={textOrEmpty(externalReferral?.examined?.numbers)}
                  onChange={e =>
                    propertyChangeExamined({ numbers: e.target.value })
                  }
                />
              </FormRow>
            </>
          )}
          {kindOfExam === ExamWhoExamined.Student && (
            <>
              <Card.Subtitle className="mb-4 mt-4">
                Placówka dydaktyczna
              </Card.Subtitle>
              <FormRow
                controlId="schoolName"
                label="Nazwa placówki dydaktycznej"
              >
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={textOrEmpty(
                    externalReferral?.examWithTests?.schoolName,
                  )}
                  onChange={e =>
                    propertyChangeExam({ schoolName: e.target.value })
                  }
                />
              </FormRow>
              <FormRow
                controlId="fieldOfStudy"
                label="Kierunek praktycznej nauki zawodu lub kształcenia"
              >
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={textOrEmpty(
                    externalReferral?.examWithTests?.fieldOfStudy,
                  )}
                  onChange={e =>
                    propertyChangeExam({ fieldOfStudy: e.target.value })
                  }
                />
              </FormRow>
              <FormRow controlId="endDateScience" label="Data końca nauki">
                <Form.Control
                  type="date"
                  name="endScience"
                  placeholder="DateRange"
                  value={momencik(
                    externalReferral?.examWithTests?.endScience,
                    'YYYY-MM-DD',
                  )}
                  onChange={e =>
                    propertyChangeExam({
                      endScience: new Date(e.target.value),
                    })
                  }
                />
              </FormRow>
            </>
          )}
          {kindOfExam === ExamWhoExamined.Employee && (
            <>
              <Card.Subtitle className="mb-4 mt-4">Pracodawca</Card.Subtitle>
              <FormRow controlId="employerName" label="Nazwa pracodawcy">
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={textOrEmpty(
                    externalReferral?.examWithTests?.employerName,
                  )}
                  onChange={e =>
                    propertyChangeExam({ employerName: e.target.value })
                  }
                />
              </FormRow>
              <FormRow controlId="employmentPosition" label="Stanowisko">
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={textOrEmpty(
                    externalReferral?.examWithTests?.employmentPosition,
                  )}
                  onChange={e =>
                    propertyChangeExam({ employmentPosition: e.target.value })
                  }
                />
              </FormRow>
            </>
          )}
          <Card.Subtitle className="mb-4 mt-4">Inne</Card.Subtitle>
          <FormRow controlId="harmfulFactories" label="Czynniki szkodliwe">
            <HarmfulFactorMultiSelector
              selectedHarmfulFactor={externalReferral?.examWithTests?.harmfulFactorsIds?.map(
                b => ({ value: b, label: b || '' }),
              )}
              onChange={options =>
                propertyChangeExam({
                  harmfulFactorsIds: (options || [])?.map(o => o?.value),
                })
              }
            />
          </FormRow>
          <ValidationAlert
            show={showSaveErrors}
            errors={saveErrors}
            className="mt-3"
          />
          <ConfirmationButton
            className="mx-1"
            disabled={waiting}
            confirmation="Skierowanie zostało wygenerowane. Czy przejść do wprowadzania kolejnych skierowań?"
            variant="outline-primary"
            onOK={nav.reload}
            onNoOK={nav.startPage}
            onValidation={() => onSave(externalReferral)}
          >
            Zapisz
          </ConfirmationButton>
        </Form>
      </Card.Body>
    </Card>
  );
};
