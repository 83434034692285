/* tslint:disable */
/* eslint-disable */
/**
 * SPD
 * Opis api SPD
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetKeyTestDto,
    GetKeyTestDtoFromJSON,
    GetKeyTestDtoFromJSONTyped,
    GetKeyTestDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetKeyValueDto
 */
export interface GetKeyValueDto {
    /**
     * 
     * @type {string}
     * @memberof GetKeyValueDto
     */
    id: string;
    /**
     * 
     * @type {GetKeyTestDto}
     * @memberof GetKeyValueDto
     */
    keyTest?: GetKeyTestDto | null;
    /**
     * 
     * @type {string}
     * @memberof GetKeyValueDto
     */
    taskNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetKeyValueDto
     */
    taskIdent?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetKeyValueDto
     */
    answer?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetKeyValueDto
     */
    weight?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetKeyValueDto
     */
    param1?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetKeyValueDto
     */
    param2?: number | null;
}

export function GetKeyValueDtoFromJSON(json: any): GetKeyValueDto {
    return GetKeyValueDtoFromJSONTyped(json, false);
}

export function GetKeyValueDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetKeyValueDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'keyTest': !exists(json, 'keyTest') ? undefined : GetKeyTestDtoFromJSON(json['keyTest']),
        'taskNumber': !exists(json, 'taskNumber') ? undefined : json['taskNumber'],
        'taskIdent': !exists(json, 'taskIdent') ? undefined : json['taskIdent'],
        'answer': !exists(json, 'answer') ? undefined : json['answer'],
        'weight': !exists(json, 'weight') ? undefined : json['weight'],
        'param1': !exists(json, 'param1') ? undefined : json['param1'],
        'param2': !exists(json, 'param2') ? undefined : json['param2'],
    };
}

export function GetKeyValueDtoToJSON(value?: GetKeyValueDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'keyTest': GetKeyTestDtoToJSON(value.keyTest),
        'taskNumber': value.taskNumber,
        'taskIdent': value.taskIdent,
        'answer': value.answer,
        'weight': value.weight,
        'param1': value.param1,
        'param2': value.param2,
    };
}


