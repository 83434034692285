import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';

import { useSpdCore } from '../../../common/hooks/useSpdCore';
import {
  CreateUpdateExaminedDto,
  GetExaminedDto,
} from '../../../common/spdCore/autogenerated/spdApiClient';
import {
  examinedData,
  validate,
} from '../../../common/spdCore/contexts/ExaminedContext';
import { responseErrors } from '../../../common/spdCore/validation/responseErrors';
import { ExaminedEditor } from '../../components/Examined/ExaminedEditor';
import { ExaminedSearch } from '../../components/Examined/ExaminedSearch';

interface ExaminedListProps {
  onRowClick?: (key: string) => Promise<void> | void | undefined;
  enableSingleSelect?: boolean;
  keyToSelect?: string;
  withAttachments?: boolean;
  setKeyToSelect?: (key: string) => void;
  withoutNewExamined?: boolean;
}

export const ExaminedList: React.FC<ExaminedListProps> = ({
  onRowClick,
  enableSingleSelect,
  keyToSelect,
  withAttachments,
  setKeyToSelect,
  withoutNewExamined,
}) => {
  const spd = useSpdCore();

  const [show, setShow] = useState(false);
  const [examined, setExamined] = useState<GetExaminedDto>();
  const [selectedKey, setSelectedKey] = useState<string | undefined>(
    keyToSelect,
  );

  const [refresh, setRefresh] = useState<boolean>(false);

  useEffect(() => {
    setSelectedKey(keyToSelect);
  }, [keyToSelect]);

  const handleAdd = () => {
    setShow(true);
    setExamined(undefined);
  };

  const addEditExamined = async (
    examinedCU: CreateUpdateExaminedDto,
    id?: string,
  ) => {
    const status = await validate(examinedCU);
    if (status.valid) {
      try {
        if (id) {
          await spd.examineds.update(id, examinedCU);
          setSelectedKey(id);
          setKeyToSelect && setKeyToSelect(id);
          //notifications.saveCompleted();
        } else {
          const exCU = await spd.examineds.create(examinedCU);
          setSelectedKey(exCU.id);
          setKeyToSelect && setKeyToSelect(exCU.id);
          //notifications.saveCompleted();
        }
        setShow(false);
        setRefresh(!refresh);
        return {
          saved: true,
          errors: [],
        };
      } catch (response) {
        return {
          saved: false,
          errors: await responseErrors(response as Response),
        };
      }
    } else {
      return {
        saved: false,
        errors: status.errors,
      };
    }
  };

  const handleClose = () => {
    setShow(false);
    setRefresh(!refresh);
  };

  const handleClick = async (id: string) => {
    const getExamined = await spd.examineds.getExamined(id);
    setExamined(getExamined);
    setShow(true);
  };

  return (
    <>
      <ExaminedSearch
        getData={spd.examineds.getAllByPortion}
        getCount={spd.examineds.getAllCount}
        onRowClick={onRowClick ? onRowClick : handleClick}
        refresh={refresh}
        enableSingleSelect={enableSingleSelect}
        selectedKeys={[selectedKey || '']}
        withAttachments={withAttachments}
      />
      {!withoutNewExamined && (
        <>
          <Button variant="outline-primary" onClick={handleAdd}>
            Nowy badany
          </Button>
          <ExaminedEditor
            id={examined?.id}
            examined={examinedData(examined)}
            onCompleted={addEditExamined}
            show={show}
            handleClose={handleClose}
            readOnly={false}
          />
        </>
      )}
    </>
  );
};
