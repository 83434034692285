import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

import { useSpdCore } from '../../../common/hooks/useSpdCore';

export const SavedPdfPreviewLink = (props: {
  savedPdfId: string;
  savedPdfName: string;
}) => {
  const spd = useSpdCore();

  const handleClick = () => {
    spd.savedPdfs.saveFileInBrowser(props.savedPdfId).catch(() => {
      console.log('Wystąpił błąd podczas zapisywania skierowania');
    });
  };

  return (
    <>
      <OverlayTrigger overlay={<Tooltip>Podgląd</Tooltip>}>
        <Button
          onClick={e => {
            e.stopPropagation();
            handleClick();
          }}
          variant="btn-info"
        >
          <Icon.Eye />
        </Button>
      </OverlayTrigger>
    </>
  );
};
