/* eslint-disable complexity */
/*eslint max-lines-per-function: ["error", 250]*/
import React, { useEffect, useState } from 'react';
import { Alert, Button, Card, Col, Row, Tab } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import Tabs from '../../../app/components/Tabs';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { useSpdCore } from '../../../common/hooks/useSpdCore';
import { useNavigation } from '../../../common/navigation';
import {
  CreateUpdateExaminedDto,
  GetExamDto,
} from '../../../common/spdCore/autogenerated/spdApiClient';
import { ExamEntity } from '../../../common/spdCore/contexts/ExamContext';
import { ExamLink } from '../../components/Exam/ExamLink';
import { ExamEndLink } from '../../components/Exam/ExamEndLink';
import { ExamTests } from '../../components/ExamTests/ExamTests';
import { ArticleMode } from '../../../common/spdCore/validation/schemas';
import { ExamOphthalmologistEndedLink } from '../../components/Exam/ExamOphthalmologistEndedLink';
import { ExaminedEditor } from '../../components/Examined/ExaminedEditor';
import {
  examinedData,
  validate,
} from '../../../common/spdCore/contexts/ExaminedContext';
import { responseErrors } from '../../../common/spdCore/validation/responseErrors';

type ParamTypes = {
  id: string;
  mode?: ArticleMode;
};

export const ExamTestsView: React.FC = () => {
  const spd = useSpdCore();
  const nav = useNavigation();
  const [exam, setExam] = useState<ExamEntity>();
  const notifications = useNotifications();
  const [show, setShow] = useState(false);

  const { id, mode } = useParams<ParamTypes>() as {
    id: string;
    mode?: ArticleMode;
  };

  useEffect(() => {
    spd.exams
      .getExam(id)
      .then(ex => setExam(ex))
      .catch(error =>
        console.log(`Wystąpił problem z odświeżeniem strony. ${error}`),
      );
  }, [id, spd.exams]);

  const handleClick = async (id: string) => {
    nav.indicatorTestsInExam(id);
  };

  const onExamChanged = async (
    exam: Pick<GetExamDto, 'id' | 'endDate' | 'startDate'>,
  ) => {
    await notifications.onPromise(
      spd.exams
        .changeDates(exam.id, exam.endDate as Date, exam.startDate as Date)
        .then(() =>
          spd.exams.getExam(id).then(ex => {
            setExam(ex);
          }),
        ),
    );
  };

  const getData = async (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
  ) => {
    return spd.examTests.getForExamByPortion(
      pageSize,
      pageNumber,
      orderBy,
      orderDirection,
      filterText,
      id,
    );
  };

  const getCount = async (filterText?: string) => {
    return spd.examTests.getForExamCount(filterText, id);
  };

  const handleClickExamined = () => {
    setShow(true);
  };

  const handleCloseExamined = () => {
    setShow(false);
  };

  const editExamined = async (
    examinedCU: CreateUpdateExaminedDto,
    id?: string,
  ) => {
    const status = await validate(examinedCU);
    if (status.valid) {
      try {
        if (id) {
          await spd.examineds.update(id, examinedCU);
        }
        setShow(false);
        return {
          saved: true,
          errors: [],
        };
      } catch (response) {
        return {
          saved: false,
          errors: await responseErrors(response as Response),
        };
      }
    } else {
      return {
        saved: false,
        errors: status.errors,
      };
    }
  };

  return (
    <Card>
      {exam?.examined && exam?.examined?.id && (
        <Alert variant="success">
          <Row>
            <Col>
              Badany:{' '}
              <strong>
                {exam?.examined?.firstname || ''}{' '}
                {exam?.examined?.surname || ''}
              </strong>{' '}
              {exam?.examined?.pesel
                ? ' PESEL: ' + exam?.examined?.pesel
                : exam?.examined?.iDCardNumber
                ? ' dokument: ' +
                  exam?.examined?.iDCardType +
                  ' ' +
                  exam?.examined?.iDCardNumber
                : ''}
            </Col>
            <Col>
              <div className="d-flex justify-content-end">
                <Button
                  variant="outline-dark"
                  className="ms-1 mb-1"
                  onClick={handleClickExamined}
                >
                  Edycja badanego
                </Button>
                <ExaminedEditor
                  id={exam?.examined?.id}
                  examined={examinedData(exam?.examined)}
                  show={show}
                  handleClose={handleCloseExamined}
                  readOnly={false}
                  onCompleted={editExamined}
                />
                <Button
                  variant="outline-dark"
                  className="ms-1 mb-1"
                  onClick={() => nav.examsExamined(exam?.examined?.id || '')}
                >
                  Badania związane z badanym
                </Button>
                <Button
                  variant="outline-dark"
                  className="ms-1 mb-1"
                  onClick={() => nav.attachments(exam?.examined?.id || '')}
                >
                  Załączniki badanego
                </Button>
              </div>
            </Col>
          </Row>
        </Alert>
      )}
      <Tabs defaultActiveKey="examTests" id="examTests" className="mb-3">
        <Tab
          eventKey="examTests"
          title={`Testy - Badanie ${exam?.number || ''}`}
          className="m-3"
        >
          {exam?.criticalPoint && (
            <Alert variant="danger">
              UWAGA!!! Wystąpiły punkty krytyczne:
              <strong> {exam?.criticalPoint}</strong>
            </Alert>
          )}

          <ExamTests
            getData={getData}
            getCount={getCount}
            onRowClick={handleClick}
          />
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {mode !== ArticleMode.Ophthalmologist && (
              <div style={{ display: 'flex' }}>
                <ExamLink examId={exam?.id} examNumber={exam?.number} />
                <Button
                  className="ms-1 mb-1"
                  onClick={() => nav.examEditor(exam?.id || '')}
                  variant="outline-secondary"
                >
                  Edycja badania
                </Button>
              </div>
            )}
            {mode === ArticleMode.Ophthalmologist && (
              <ExamOphthalmologistEndedLink
                examId={exam?.id}
                examNumber={exam?.number}
                ophthalmologistEnded={exam?.ophthalmologistEnded}
              />
            )}
            <ExamEndLink
              onExamChanged={onExamChanged}
              examId={exam?.id || ''}
              endDate={exam?.endDate}
              startDate={exam?.startDate}
              mode={mode}
            />
          </div>
        </Tab>
      </Tabs>
    </Card>
  );
};
