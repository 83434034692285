import React from 'react';

import { GetTestDto } from '../../../common/spdCore/autogenerated/spdApiClient/models/GetTestDto';
import { Grid } from '../../../common/components/Grid/Grid';
import {
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';

import { TestDownloadLink } from './TestDownloadLink';

interface TestsProps {
  getData: GridGetterFunction;
  getCount: GridCountFunction;
  onRowClick: (key: string) => Promise<void> | void | undefined;
  refresh?: boolean;
  enableMultiSelect?: boolean;
  selectedKeys?: string[];
  withoutDownload?: boolean;
}

export const testForGrid = (test: GetTestDto) => {
  return {
    key: test.id,
    values: {
      'test.lp': test.lp,
      'test.name': test.name,
      'test.description': test.description,
      'test.fileName': test.fileName,
      'test.identifier': test.identifier,
      preview: <TestDownloadLink testIdent={test.identifier} />,
    },
  };
};

export const Test: React.FC<TestsProps> = props => {
  const columns = [
    { header: 'Lp.', property: 'test.lp' },
    { header: 'Nazwa', property: 'test.name' },
    { header: 'Identyfikator', property: 'test.identifier' },
    { header: 'Nazwa pliku', property: 'test.fileName' },
    { header: 'Opis', property: 'test.description', noSorting: false },
  ];
  if (!props.withoutDownload) {
    columns.push({
      header: 'Pobierz',
      property: 'preview',
      noSorting: true,
    });
  }

  return (
    <Grid
      getData={props.getData}
      getDataCount={props.getCount}
      columns={columns}
      showFilter={true}
      initialOrderColumn="test.lp"
      availablePageSizes={[10, 20, 50, 100]}
      onRowClick={props.onRowClick}
      emptyGridMessage="Brak testów w systemie"
      refresh={props.refresh}
      enableMultiSelect={props.enableMultiSelect}
      selectedKeys={props.selectedKeys}
    />
  );
};
