import React, { useState } from 'react';
import { Card, Tab } from 'react-bootstrap';
import { useParams } from 'react-router';

import Tabs from '../../../app/components/Tabs';
import { useAuth } from '../../../common/hooks/useAuth';
import { useSpdCore } from '../../../common/hooks/useSpdCore';
import {
  CreateUpdateExamWithTestsDtoHealthcareEnum,
  CreateUpdateExamWithTestsDtoTakenCareOfEnum,
  CreateUpdateExamWithTestsDtoTypeEnum,
  CreateUpdateSupportingExaminationDto,
} from '../../../common/spdCore/autogenerated/spdApiClient';
import { TestGivingProps } from '../../components/TestGiving/utilsTestGiving';

import { TestGiving } from './TestGiving';

type ParamTypes = {
  modeOnlyOne: string;
};

export const TestGivingView: React.FC = () => {
  const { modeOnlyOne } = useParams<ParamTypes>();

  const spd = useSpdCore();
  const auth = useAuth();

  const [formData, setFormData] = useState<TestGivingProps>({
    testsKeys: [],
    countOfExams: 1,
  });

  const onSave = (props: TestGivingProps) => {
    spd.exams
      .createWithTests({
        ...props,
        ...{
          examinedId: props.examinedKey,
          psychologistUID: auth.currentUser?.uid,
          testsId: props.testsKeys,
          articlePsychologistsIds: props.articlePsychologistsKeys,
          articleDoctorsIds: props.articleDoctorsKeys,
          countOfExams: +(props.countOfExams || 0),
          occupationalMedicineCategory: props.occupMedicineCategory?.split(','),
          occupationalMedicineText: props.occupMedicineText,
          type: props.type as unknown as CreateUpdateExamWithTestsDtoTypeEnum,
          kindOfScience: props.kindOfScience,
          takenCareOf:
            props.takenCareOf as unknown as CreateUpdateExamWithTestsDtoTakenCareOfEnum,
          supportingExamination:
            props.supportExam as unknown as CreateUpdateSupportingExaminationDto[],
          companyId:
            props.company && 'id' in props.company
              ? props.company.id
              : undefined,
          name: props.company?.name,
          nip: props.company?.nip,
          email: props.company?.email,
          sendCompanyEmail: props.sendCompanyEmail,
          healthcare:
            props.healthcare as unknown as CreateUpdateExamWithTestsDtoHealthcareEnum,
          healthcareFacilityId: props.healthcareFacilityId,
        },
      })
      .then(exam => {
        if (exam) {
          setFormData({
            ...props,
            pin: exam.map(e => e.pin).join(', '),
            examsNumber: exam.map(e => e.number).join(', '),
            countOfExams: exam.length,
            examPackage: (exam && exam[0].examPackage) || undefined,
          });
        }
      })
      .catch(error => {
        console.error('Nie udało się zapisać badania', error);
      });
  };

  const handleSetFormData = (newFormData: TestGivingProps) => {
    setFormData(newFormData);
  };

  return (
    <Card>
      <Tabs defaultActiveKey="testGiving" id="testGiving" className="mb-3">
        <Tab eventKey="testGiving" title="Zadawanie testów" className="m-3">
          <TestGiving
            modeOnlyOne={modeOnlyOne === 'true' ? true : false}
            formData={formData}
            setFormData={handleSetFormData}
            onSave={onSave}
          />
        </Tab>
      </Tabs>
    </Card>
  );
};
