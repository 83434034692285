import React from 'react';

import { Grid } from '../../../common/components/Grid/Grid';
import {
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import { GetEmailDto } from '../../../common/spdCore/autogenerated/spdApiClient';
import momencik from '../../../common/momencik';
import {
  EmailStatus,
  EmailStatusText,
} from '../../../common/spdCore/validation/schemas';

import { EmailBinLink } from './EmailBinLink';
import { EmailResendLink } from './EmailResendLink';
import { EmailStatusIcon } from './EmailStatusIcon';

interface EmailsProps {
  getData: GridGetterFunction;
  getCount: GridCountFunction;
  refresh?: boolean;
}

export const emailForGrid = (
  email: GetEmailDto,
  setRefresh?: () => void | undefined,
  setLoading?: (loading: boolean) => void,
) => ({
  key: email.id,
  values: {
    'email.document': email.document || ' ',
    'email.examined': email.examined.firstname + ' ' + email.examined.surname,
    'email.info': email.info,
    'email.sentTo': email.sentTo,
    'email.sendDate': momencik(email.sendDate, 'YYYY.MM.DD HH:mm:ss'),
    'email.status':
      EmailStatusText[email.status as keyof typeof EmailStatusText],
    'email.sender': email.user?.name || 'system',
    resend: (
      <EmailResendLink
        emailId={email.id}
        setRefresh={setRefresh}
        setLoading={setLoading}
      />
    ),
    bin: (
      <>
        {email?.active && (
          <EmailBinLink emailId={email.id} setRefresh={setRefresh} />
        )}
      </>
    ),
    status: <EmailStatusIcon status={email.status as unknown as EmailStatus} />,
  },
});

export const Emails: React.FC<EmailsProps> = props => {
  const columns = [
    { header: 'Dokument', property: 'email.document' },
    { header: 'Badany', property: 'email.examined' },
    { header: 'Kto wysyłał', property: 'email.sender', noSorting: true },
    { header: 'Informacja', property: 'email.info' },
    { header: 'Wysłany do', property: 'email.sentTo' },
    { header: 'Data wysłania', property: 'email.sendDate' },
    { header: 'Status', property: 'status', noSorting: true },
    { header: 'Ponowienie', property: 'resend', noSorting: true },
    { header: '', property: 'bin', noSorting: true },
  ];

  return (
    <Grid
      getData={props.getData}
      getDataCount={props.getCount}
      columns={columns}
      showFilter={true}
      initialOrderDirection="DESC"
      initialOrderColumn="email.sendDate"
      availablePageSizes={[10, 20, 50]}
      emptyGridMessage="Brak wiadomości e-mail w systemie"
      refresh={props.refresh}
    />
  );
};
