import React from 'react';

import ConfirmationButton from '../../../app/components/ConfirmationButton';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { useSpdCore } from '../../../common/hooks/useSpdCore';

export const ExamOphthalmologistEndedLink = (props: {
  examId: string | undefined;
  examNumber: number | undefined;
  ophthalmologistEnded: boolean | null | undefined;
}) => {
  const notifications = useNotifications();
  const spd = useSpdCore();
  const [ophthalmologistEnded, setOphthalmologistEnded] = React.useState(
    props.ophthalmologistEnded,
  );

  const handleOnEnded = async () => {
    if (props.examId) {
      notifications.onPromise(
        spd.exams
          .update(props.examId, { ophthalmologistEnded: true })
          .then(() => {
            setOphthalmologistEnded(true);
          }),
      );
    }
  };
  const handleOnStart = async () => {
    if (props.examId) {
      notifications.onPromise(
        spd.exams
          .update(props.examId, { ophthalmologistEnded: false })
          .then(() => {
            setOphthalmologistEnded(false);
          }),
      );
    }
  };

  return (
    <div>
      {!ophthalmologistEnded && (
        <ConfirmationButton
          className="ms-1"
          confirmation={`Czy na pewno oznaczyć badanie ${
            props.examNumber || ''
          } jako zakończone od strony okulisty?`}
          variant="outline-danger"
          onOK={handleOnEnded}
        >
          Zakończ badanie okulistyczne
        </ConfirmationButton>
      )}
      {ophthalmologistEnded && (
        <ConfirmationButton
          className="ms-1"
          confirmation={`Czy na pewno oznaczyć badanie ${
            props.examNumber || ''
          } jako potrzebujące obsługi od strony okulisty?`}
          variant="outline-primary"
          onOK={handleOnStart}
        >
          Wznów badanie okulistyczne
        </ConfirmationButton>
      )}
    </div>
  );
};
