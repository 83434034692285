/* tslint:disable */
/* eslint-disable */
/**
 * SPD
 * Opis api SPD
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetExamDto,
    GetExamDtoFromJSON,
    GetExamDtoFromJSONTyped,
    GetExamDtoToJSON,
    GetTestDto,
    GetTestDtoFromJSON,
    GetTestDtoFromJSONTyped,
    GetTestDtoToJSON,
    GetUserDto,
    GetUserDtoFromJSON,
    GetUserDtoFromJSONTyped,
    GetUserDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetExamTestDto
 */
export interface GetExamTestDto {
    /**
     * 
     * @type {string}
     * @memberof GetExamTestDto
     */
    id: string;
    /**
     * 
     * @type {GetExamDto}
     * @memberof GetExamTestDto
     */
    exam?: GetExamDto | null;
    /**
     * 
     * @type {GetTestDto}
     * @memberof GetExamTestDto
     */
    test?: GetTestDto | null;
    /**
     * 
     * @type {number}
     * @memberof GetExamTestDto
     */
    lp?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof GetExamTestDto
     */
    startDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof GetExamTestDto
     */
    endDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof GetExamTestDto
     */
    criticalPoint?: string | null;
    /**
     * 
     * @type {GetUserDto}
     * @memberof GetExamTestDto
     */
    owner?: GetUserDto | null;
}

export function GetExamTestDtoFromJSON(json: any): GetExamTestDto {
    return GetExamTestDtoFromJSONTyped(json, false);
}

export function GetExamTestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetExamTestDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'exam': !exists(json, 'exam') ? undefined : GetExamDtoFromJSON(json['exam']),
        'test': !exists(json, 'test') ? undefined : GetTestDtoFromJSON(json['test']),
        'lp': !exists(json, 'lp') ? undefined : json['lp'],
        'startDate': !exists(json, 'startDate') ? undefined : (json['startDate'] === null ? null : new Date(json['startDate'])),
        'endDate': !exists(json, 'endDate') ? undefined : (json['endDate'] === null ? null : new Date(json['endDate'])),
        'criticalPoint': !exists(json, 'criticalPoint') ? undefined : json['criticalPoint'],
        'owner': !exists(json, 'owner') ? undefined : GetUserDtoFromJSON(json['owner']),
    };
}

export function GetExamTestDtoToJSON(value?: GetExamTestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'exam': GetExamDtoToJSON(value.exam),
        'test': GetTestDtoToJSON(value.test),
        'lp': value.lp,
        'startDate': value.startDate === undefined ? undefined : (value.startDate === null ? null : value.startDate.toISOString()),
        'endDate': value.endDate === undefined ? undefined : (value.endDate === null ? null : value.endDate.toISOString()),
        'criticalPoint': value.criticalPoint,
        'owner': GetUserDtoToJSON(value.owner),
    };
}


