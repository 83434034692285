import React, { PropsWithChildren } from 'react';

export interface NotificationsProviderType {
  onPromise: (
    promise: Promise<void>,
    action?: () => void,
    actionIfFailed?: () => void,
  ) => void;
  unauthorized: () => void;
  noBackend: () => void;
  online: () => void;
  offline: () => void;
}

const emptyHandler = () => {
  // implemented elsewhere
};

const emptyState = {
  onPromise: emptyHandler,
  unauthorized: emptyHandler,
  noBackend: emptyHandler,
  online: emptyHandler,
  offline: emptyHandler,
};

export const NotificationsContext =
  React.createContext<NotificationsProviderType>(emptyState);

export const NotificationsProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  return (
    <NotificationsContext.Provider value={emptyState}>
      {children}
    </NotificationsContext.Provider>
  );
};
