import { DefaultApi } from '../autogenerated/spdApiClient/apis/DefaultApi';
import { GetKeyValueDto } from '../autogenerated/spdApiClient';

export type KeyValueEntity = GetKeyValueDto;

export interface KeyValueContextInterface {
  getByKey: (keyTestId: string) => Promise<GetKeyValueDto[]>;
  getByKeyCount: (keyTestId: string) => Promise<number>;
}

export const KeyValueContext = (api: DefaultApi) => {
  return {
    getByKey: async (keyTestId: string) => {
      return await api.keyValueControllerGetByKey(keyTestId);
    },

    getByKeyCount: (keyTestId: string) =>
      api.keyValueControllerGetByKeyCount(keyTestId),
  };
};
