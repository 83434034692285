import React, { useEffect, useState } from 'react';
import * as Icon from 'react-bootstrap-icons';
import { Button, Card } from 'react-bootstrap';

import { useSpdCore } from '../../../common/hooks/useSpdCore';
import { DrivingLicenseCategory } from '../../../common/spdCore/validation/schemas';
import { copyToClipboard } from '../../../common/copyToClipboard';
import { ExtensionSummary } from '../ExamsPackage/ExtensionSummary';

import { TestGivingProps } from './utilsTestGiving';

interface Props {
  values: TestGivingProps;
}

export const CommisionningResearch: React.FC<Props> = ({ values }) => {
  const spd = useSpdCore();

  const [examined, setExamined] = useState('');
  const [dataTests, setDataTests] = useState('');
  const [selectedDrivingCat, setSelectedDrivingCat] = useState<string[]>([]);

  useEffect(() => {
    if (values.examinedKey) {
      spd.examineds
        .getExamined(values.examinedKey)
        .then(o => setExamined(`${o.surname} ${o.firstname}` || ''))
        .catch(error => {
          console.error('Error during getAll examined.', error);
        });
    }
  }, [spd.examineds, values]);

  useEffect(() => {
    spd.tests
      .getAll()
      .then(allTests => {
        const helpTests = [];
        if (values.testsKeys) {
          for (const testId of values.testsKeys) {
            helpTests.push(...allTests.filter(p => p.id === testId));
          }
        }
        setDataTests(helpTests.map(o => o.name || '').join(', '));
      })
      .catch(error => {
        console.error('Error during getAll tests.', error);
      });
  }, [spd.tests, values]);

  useEffect(() => {
    setSelectedDrivingCat(
      Object.keys(DrivingLicenseCategory)
        .filter(item => values.drivingLicense?.split(',')?.includes(item))
        .map(
          cat =>
            DrivingLicenseCategory[
              cat as keyof typeof DrivingLicenseCategory
            ] || '',
        ),
    );
  }, [values.drivingLicense]);

  const onClickHandle = async () => {
    await copyToClipboard(values.pin || '');
  };

  return (
    <>
      <Card style={{ textAlign: 'left' }}>
        <Card.Body>
          <p>
            Liczba utworzonych badań: <strong>{values.countOfExams}</strong>
          </p>
          <p>
            Numery utworzonych badań: <strong>{values.examsNumber}</strong>
          </p>
          <p>
            Badany: <strong>{examined}</strong>
          </p>
          <p>
            Zlecone testy: <strong>{dataTests}</strong>
          </p>
          <p>
            Dotyczy kategorii: <strong>{selectedDrivingCat.join(', ')}</strong>
          </p>
          <p>
            <strong>PIN: {values.pin} </strong>
            <Button onClick={onClickHandle} variant="outline-primary">
              <Icon.ClipboardPlus />
            </Button>
          </p>
          <ExtensionSummary
            values={values}
            examined={examined}
            tests={dataTests}
          />
        </Card.Body>
      </Card>
    </>
  );
};
