import React from 'react';

import { GetRegisterDto } from '../../../common/spdCore/autogenerated/spdApiClient/models/GetRegisterDto';
import { Grid } from '../../../common/components/Grid/Grid';
import {
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import { RegisterMode } from '../../../common/spdCore/validation/schemas';

interface RegistersSearchProps {
  getData: GridGetterFunction;
  getCount: GridCountFunction;
  refresh?: boolean;
}

export const registerForGrid = (register: GetRegisterDto) => {
  return {
    key: register.id,
    values: {
      'register.name': register.name,
      'register.pattern': register.pattern,
      'register.withUser': register.withUser ? 'TAK' : 'NIE',
      'register.mode': RegisterMode[register.mode as keyof typeof RegisterMode],
    },
  };
};

export const RegisterSearch: React.FC<RegistersSearchProps> = props => {
  const columns = [
    { header: 'Nazwa', property: 'register.name' },
    { header: 'Dotyczy użytkownika', property: 'register.withUser' },
    { header: 'Numeracja', property: 'register.pattern' },
  ];

  return (
    <Grid
      getData={props.getData}
      getDataCount={props.getCount}
      columns={columns}
      showFilter={true}
      initialOrderColumn="register.name"
      availablePageSizes={[10, 20, 50]}
      emptyGridMessage="Brak rejestrów w systemie"
      enableMultiSelect={false}
      refresh={props.refresh}
    />
  );
};
