import { DefaultApi } from '../autogenerated/spdApiClient/apis/DefaultApi';
import {
  CreateUpdateArticleDto,
  CreateUpdateArticleDtoFromJSON,
  GetArticleDto,
} from '../autogenerated/spdApiClient';
import { validateAgainst } from '../validation/validateAgainst';
import { ArticleMode, ArticleSchema } from '../validation/schemas';

export type ArticleEntity = GetArticleDto;

export interface ArticleContextInterface {
  get: (id: string) => Promise<ArticleEntity>;
  getAll: (mode?: ArticleMode) => Promise<GetArticleDto[]>;
  update: (id: string, dto: CreateUpdateArticleDto) => Promise<void>;
  create: (dto: CreateUpdateArticleDto) => Promise<GetArticleDto>;
  changeToInactive: (id: string) => Promise<void>;
}

export const ArticleContext = (api: DefaultApi) => {
  return {
    get: async (id: string) => {
      return await api.articleControllerGet(id);
    },

    getAll: (mode?: ArticleMode) =>
      api.articleControllerGetAll(mode?.toString()),

    update: (id: string, dto: CreateUpdateArticleDto) =>
      api.articleControllerUpdate(id, dto),

    create: (dto: CreateUpdateArticleDto) => api.articleControllerCreate(dto),

    changeToInactive: (id: string) => api.articleControllerChangeToInactive(id),
  };
};

export const validate = (test: CreateUpdateArticleDto) =>
  validateAgainst(ArticleSchema, test);

export const articleData = (
  article?: GetArticleDto,
): CreateUpdateArticleDto => {
  return CreateUpdateArticleDtoFromJSON({
    ...article,
    ...{ registerId: article?.register?.id },
  });
};
