import React, { useEffect, useState } from 'react';
import { Form, Card, Button } from 'react-bootstrap';

import ValidationAlert from '../../../app/components/ValidationAlert';
import { useSpdCore } from '../../../common/hooks/useSpdCore';
import { GetTestDto } from '../../../common/spdCore/autogenerated/spdApiClient';
import { Test } from '../Tests/Tests';

interface Props {
  testsKeys: string[];
  onSave?: () => void;
  handleFormData?: (input: string) => (e: string[]) => void;
  nextStep?: () => void;
  prevStep?: () => void;
  onChange?: (testsKeys: string[]) => void;
}

export const ChooseTest: React.FC<Props> = ({
  handleFormData,
  onSave,
  testsKeys,
  nextStep,
  prevStep,
  onChange,
}) => {
  const spd = useSpdCore();

  const [tests, setTests] = useState('');
  const [dataTests, setDataTests] = useState<GetTestDto[]>([]);
  const [selectedTests, setSelectedTests] = useState<string[]>(testsKeys);
  const [showErrors, setShowErrors] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);

  useEffect(() => {
    spd.tests
      .getAll()
      .then(o => setDataTests(o))
      .catch(error => {
        console.error('Error during getAll tests.', error);
      });
  }, [spd.tests]);

  useEffect(() => {
    setSelectedTests(testsKeys);
  }, [testsKeys]);

  useEffect(() => {
    const helpTests = [];
    if (selectedTests) {
      for (const testId of selectedTests) {
        helpTests.push(...dataTests.filter(o => o.id === testId));
      }
    }
    setTests(helpTests.map(o => o.name || '').join(', '));
  }, [dataTests, selectedTests]);

  const handleOnRowClick = (key: string) => {
    let tk = [];

    if (!selectedTests.includes(key)) {
      tk = [...selectedTests, key];
    } else {
      tk = selectedTests.filter(o => o !== key);
    }

    setSelectedTests(tk);
    handleFormData && handleFormData('testsKeys')(tk);
    onChange && onChange(tk);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const submitFormData = (e: any) => {
    e.preventDefault();
    if (selectedTests.length === 0) {
      setShowErrors(true);
      setErrors(['Proszę wybrać co najmniej jeden test.']);
      return;
    }
    onSave && onSave();
    nextStep && nextStep();
  };

  return (
    <>
      <Card>
        <Card.Body>
          <Form onSubmit={submitFormData}>
            <Form.Group className="mb-3">
              <Form.Label>Wybrane testy:</Form.Label>{' '}
              <Form.Label>
                <strong>{tests}</strong>
              </Form.Label>
              <Test
                getData={spd.tests.getAllByPortion}
                getCount={spd.tests.getAllCount}
                onRowClick={handleOnRowClick}
                enableMultiSelect={true}
                selectedKeys={selectedTests}
                withoutDownload={true}
              />
            </Form.Group>

            {prevStep && (
              <div className="d-flex justify-content-end m-3">
                <Button
                  variant="outline-secondary"
                  className="ms-1"
                  onClick={prevStep}
                >
                  Wstecz
                </Button>

                <Button
                  variant="outline-primary"
                  className="ms-1"
                  type="submit"
                >
                  Zakończ
                </Button>
              </div>
            )}
          </Form>
        </Card.Body>
      </Card>
      <ValidationAlert show={showErrors} errors={errors} className="mt-3" />
    </>
  );
};
