export enum Role {
  Admin = 'Admin',
  Inactive = 'Inactive',
  Examined = 'Examined',
  PsychologistAssessor = 'PsychologistAssessor',
  PsychologistSpecialist = 'PsychologistSpecialist',
  MedicalAssessor = 'MedicalAssessor',
  MedicalSpecialist = 'MedicalSpecialist',
  MedicalRegistration = 'MedicalRegistration',
  ExternalRegistration = 'ExternalRegistration',
  Public = 'Public',
}

export enum RoleText {
  Examined = 'Badany',
  PsychologistAssessor = 'Psycholog orzecznik',
  PsychologistSpecialist = 'Psycholog specjalista',
  MedicalAssessor = 'Lekarz orzecznik',
  MedicalSpecialist = 'Lekarz specjalista',
  MedicalRegistration = 'Rejestracja medyczna',
  ExternalRegistration = 'Rejestracja zewnętrzna',
  Public = 'Użytkownik publiczny',
}
