/**
 * This file is shared between spd-core & spd-web
 * If you have to change it then do it in spd-core and run yarn get:validation-schemas @spd-web
 */
import * as yup from 'yup';

export enum Healthcare {
  Doctor = 'Doctor',
  Dentist = 'Dentist',
  Midwife = 'Midwife',
  Nurse = 'Nurse',
}

export enum HealthcareText {
  Doctor = 'Lekarz/Lekarka',
  Dentist = 'Stomatolog',
  Midwife = 'Położny/Położna',
  Nurse = 'Pielęgniarz/Pielęgniarka',
}

export enum CardType {
  Preventive = 'Preventive',
  Psychologist = 'Psychologist',
  PsychologistWeapon = 'PsychologistWeapon',
  Weapon = 'Weapon',
  Driver = 'Driver',
  Sanitary = 'Sanitary',
  Sport = 'Sport',
}

export enum CardTypeText {
  Preventive = 'Profilaktyczna',
  Psychologist = 'Psychologiczna',
  PsychologistWeapon = 'Psychologiczna na broń',
  Weapon = 'Broń/Zawody specjalne',
  Driver = 'Kierowcy',
  Sanitary = 'Sanitarno-epidemiologiczna',
  Sport = 'Sportowa',
}

export enum ConsultationType {
  Neurological = 'Neurological',
  Ophthalmologist = 'Ophthalmologist',
  ENT = 'ENT',
  Psychiatric = 'Psychiatric',
}

export enum ConsultationTypeText {
  Neurological = 'neurologiczna',
  Ophthalmologist = 'okulistyczna',
  ENT = 'laryngologiczna',
  Psychiatric = 'psychiatryczna',
}

export enum Gender {
  Male = 'male',
  Female = 'female',
}

export enum GivingExamMode {
  Examined = 'examined',
  PsychologistFast = 'psychologistFast',
  PsychologistFull = 'psychologistFull',
}

export enum IndicatorTestMode {
  NumberOfCorrectAnswers = 'NumberOfCorrectAnswers',
  SumOfPresentationTime = 'SumOfPresentationTime',
  WeightedSum = 'WeightedSum',
}

export enum IndicatorTestModeText {
  NumberOfCorrectAnswers = 'Liczba poprawnych odpowiedzi',
  SumOfPresentationTime = 'Czas wykonania',
  WeightedSum = 'Suma ważona',
}

export enum ArticleMode {
  Doctor = 'Doctor',
  Psychologist = 'Psychologist',
  Ophthalmologist = 'Ophthalmologist',
}

export enum ArticleModeText {
  Doctor = 'Lekarz',
  Psychologist = 'Psycholog',
  Ophthalmologist = 'Okulista',
}

export enum PrintDefMode {
  Referral = 'Referral',
}

export enum PrintDefModeText {
  Referral = 'Skierowanie',
}

export enum ExamPackageType {
  Single = 'Single',
  Mulitiple = 'Mulitiple',
}

export enum ExamPackageMode {
  Exams = 'Exams',
  Normalization = 'Normalization',
}

export enum ExamPackageModeText {
  Exams = 'Badania',
  Normalization = 'Normalizacja',
}

export enum ExaminationType {
  Initial = 'Initial',
  Periodic = 'Periodic',
  Control = 'Control',
  PeriodicControl = 'PeriodicControl',
}

export enum ExaminationTypeText {
  Initial = 'wstępne',
  Periodic = 'okresowe',
  Control = 'kontrolne',
  PeriodicControl = 'okresowo-kontrolne',
}

export enum KindOfScience {
  Apprenticeship = 'Apprenticeship',
  VocationalCourse = 'VocationalCourse',
  Studies = 'Studies',
  DoctoralStudies = 'DoctoralStudies',
}

export enum KindOfScienceText {
  Apprenticeship = 'Praktyczna nauka zawodu',
  VocationalCourse = 'Kwalifikacyjny kurs zawodowy',
  Studies = 'Studia',
  DoctoralStudies = 'Studia doktoranckie',
}

export enum SupportingType {
  helpExam = 'helpExam',
  specialisedConsultation = 'specialisedConsultation',
}

export enum SupportingTypeText {
  helpExam = 'Badanie pomocnicze',
  specialisedConsultation = 'Konsultacja specjalistyczna',
}

export enum ExamWhoExamined {
  Student = 'Student',
  Employee = 'Employee',
}

export enum ExamWhoExaminedText {
  Student = 'Student / uczeń',
  Employee = 'Pracownik / kandydat do pracy',
}

export enum TakenCareOf {
  Employee = 'Employee',
  Outwork = 'Outwork',
  Student = 'Student',
  OwnRequest = 'OwnRequest',
}

export enum TakenCareOfText {
  Employee = 'Pracownik',
  Outwork = 'Wykonujący pracę nakładczą',
  Student = 'Pobierający naukę',
  OwnRequest = 'Na własny wniosek',
}

export enum PositionNormMode {
  Centile = 'centyl',
  Decile = 'ten',
  Percentile = 'sten',
  Stanine = 'stanin',
  Standardized = 'przeliczony',
}

export enum StampType {
  Big = 'Big',
  Small = 'Small',
  Double = 'Double',
  Signature = 'Signature',
}

export enum SignatureStatus {
  ToSign = 'ToSign',
  Signed = 'Signed',
  Unnecessary = 'Unnecessary',
}

export enum RegisterMode {
  OK = 'OK', //Psycholodzy - Orzeczenia kierowców
  KMP = 'KMP', //Psycholodzy - Konsultacje medycyny pracy
  BR = 'BR', //Psycholodzy - Badania dot. broni
  K75 = 'K75', //Lekarze - Kierowcy z art. 75
  K43 = 'K43', //Lekarze - Medycyna pracy art. 43
  KS = 'KS', //Lekarze - Sanitarny
  KU = 'KU', //Lekarze - Uczniowie i studenci
  LBR = 'LBR', //Lekarze - Badania dot. broni
}

export enum DrivingLicenseCategory {
  A = 'A',
  A1 = 'A1',
  A2 = 'A2',
  AM = 'AM',
  B = 'B',
  B_E = 'B+E',
  B1 = 'B1',
  C = 'C',
  C_E = 'C+E',
  C1 = 'C1',
  C1_E = 'C1+E',
  D = 'D',
  D_E = 'D+E',
  D1 = 'D1',
  D1_E = 'D1+E',
  T = 'T',
  Tramway = 'Tramwaj',
}

export enum TemplateType {
  Popular_art_82_ust_1 = 'art_82_ust_1',
  Emergency_art_82 = 'art_82_uprzywilejowany',
  Instructor_art_82 = 'art_82_instruktor_egzaminator',
  Road_transport_art_39 = 'art_39_transport_drogowy',
  Occupational_health = 'medycyna_pracy',
  Psychological_Exam_card = 'karta_badania_psychologicznego',
  Weapon_card = 'karta_broni',
  Weapon_medical_judgment = 'bron_orzeczenie_lekarskie',
  Weapon_psychological_judgment = 'bron_orzeczenie_psychologiczne',
  Weapon_psychological_exam_card = 'bron_karta_badania_psychologicznego',
  Preventive_Exam_card = 'karta_badania_profilaktycznego',
  Medical_judgment_43 = 'mp_orzeczenie_lekarskie_43',
  Medical_judgment_43_eng = 'mp_orzeczenie_lekarskie_43_ang',
  Medical_judgment_43_transport = 'mp_orzeczenie_lekarskie_43_transport',
  Medical_judgement_driver_75_6 = 'kierowca_orzeczenie_lekarskie_art_75_uprzywilejowany',
  Medical_judgement_driver_75_all = 'kierowca_orzeczenie_lekarskie_art_75_reszta',
  Medical_judgment_student = 'mp_zaswiadczenie_lekarskie_student',
  Sanitary_judgment = 'orzeczenie_sanitarno_epidemiologiczne',
  Driver_Exam_card = 'karta_badania_kierowcy',
  Neurological_consultation = 'konsultacja_neurologiczna',
  ENT_consultation = 'konsultacja_laryngologiczna',
  Ophthalmologist_consultation = 'konsultacja_okulistyczna',
  Psychiatric_consultation = 'konsultacja_psychiatryczna',
  Answers_card = 'karta_odpowiedzi',
  Sanitary_Exam_card = 'karta_badania_sanitarno_epidemiologicznego',
  Medical_judgment_ability_to_work = 'zaswiadczenie_lekarskie_zdolnosci_do_pracy',
  Sport_certificate = 'zaswiadczenie_sportowe',
  Sport_card = 'karta_badania_sportowego',
  Doctor_ability_to_work = 'orzeczenie_stan_zdrowia_lekarza',
  Nurse_ability_to_work = 'zaswiadczenie_stan_zdrowia_pielegniarka',
  Nurse_ability_to_work_art_31 = 'zaswiadczenie_stan_zdrowia_pielegniarka_art31',
  RegisterJudgment = 'rejestrOrzeczen',
  Referral_student = 'skierowanie_uczen',
  Referral_employee = 'skierowanie_pracownik',
  Twilight_vision = 'wynik_widzenia_zmierzchowego',
  Explosives_civil_medical_judgment = 'wybuch_cywilne_orzeczenie_lekarskie',
  Explosives_civil_psychological_judgment = 'wybuch_cywilne_orzeczenie_psychologiczne',
  Explosives_army_medical_judgment = 'wybuch_wojsko_orzeczenie_lekarskie',
  Explosives_army_psychological_judgment = 'wybuch_wojsko_orzeczenie_psychologiczne',
  Detective_medical_judgment = 'detektyw_orzeczenie_lekarskie',
  Detective_psychological_judgment = 'detektyw_orzeczenie_psychologiczne',
  ReportJudgments = 'zestawienie_orzeczen',
  Companies = 'lista_firm',
  ClinicReferral = 'skierowanie_do_poradni',
}

export enum OccupationalMedicineCategory {
  CatA = 'kierowca pojazdu służbowego kat A',
  CatB = 'kierowca pojazdu służbowego kat B',
  Forklift = 'operator wózka widłowego',
  ReachTrack = 'operator wózka wysokiego składowania',
  HDSCrane = 'operator dźwigu HDS',
  Crane = 'operator żurawia',
  ConstructionMachinery = 'operator maszyn budowalnych ( koparka, ładowarka, zgarniarka, inne)',
  BasketLift = 'operator podnośnika koszowego',
  Gantry = 'operator suwnicy',
  PsychomotorSkills = 'praca wymagająca szczególnej sprawności psychoruchowej',
  Others = 'inne',
  DarkRoom = 'pomiar wrażliwości na olśnienie i widzenia zmierzchowego (ciemnia)',
  Stereoscopic = 'pomiar widzenia stereoskopowego',
}

export enum AttachmentType {
  LaboratoryExamination = 'Badanie laboratoryjne',
  Referral = 'Skierowanie',
  Judgment = 'Orzeczenie',
  FaceIdentity = 'Identyfikacja badanego',
}

export enum EmailStatus {
  NotSent = 'NotSent',
  Sent = 'Sent',
  Error = 'Error',
}

export enum EmailStatusText {
  NotSent = 'Niewysłany',
  Sent = 'Wysłany',
  Error = 'Błąd',
}

const digits = /^[0-9]+$/;

function digitsOnly(text: string | null | undefined) {
  if (!text) {
    return true;
  }

  if (!text.match(digits)) {
    return false;
  }
  return true;
}

// function validNip(nip: string | null | undefined) {
//   if (!nip) {
//     return true;
//   }

//   if (typeof nip !== 'string') {
//     return false;
//   }

//   nip = nip.replace(/[ -]/gi, '');
//   if (nip.length !== 10) {
//     return false;
//   }
//   const weight = [6, 5, 7, 2, 3, 4, 5, 6, 7];
//   let sum = 0;
//   const controlNumber = parseInt(nip.substring(9, 10));
//   const weightCount = weight.length;
//   for (let i = 0; i < weightCount; i++) {
//     sum += parseInt(nip.substr(i, 1)) * weight[i];
//   }
//   return sum % 11 === controlNumber;
// }

export const getBirthDateFromPesel = (pesel: string) => {
  let year = parseInt(pesel.substring(0, 2), 10);
  let month = parseInt(pesel.substring(2, 4), 10) - 1;
  const day = parseInt(pesel.substring(4, 6), 10);

  if (month >= 80) {
    year += 1800;
    month = month - 80;
  } else if (month >= 60) {
    year += 2200;
    month = month - 60;
  } else if (month >= 40) {
    year += 2100;
    month = month - 40;
  } else if (month >= 20) {
    year += 2000;
    month = month - 20;
  } else {
    year += 1900;
  }

  const birthDate = new Date();
  birthDate.setFullYear(year, month, day);
  birthDate.setUTCHours(0, 0, 0, 0);
  return birthDate;
};

export const getSexFromPesel = (pesel: string) => {
  const sexDigit = parseInt(pesel.charAt(9), 10);

  if (sexDigit % 2 === 0) {
    return 'female';
  } else {
    return 'male';
  }
};

export const isPeselAndBirthDateValid = (
  pesel?: string | null,
  birthDate?: Date | null,
) => {
  if (pesel && birthDate && pesel.length === 11) {
    birthDate.setUTCHours(0, 0, 0, 0);
    return (
      getBirthDateFromPesel(pesel).toISOString() === birthDate.toISOString()
    );
  } else {
    return true;
  }
};

export const isPeselValid = (pesel?: string | null) => {
  if (!pesel) {
    return true;
  }

  if (typeof pesel !== 'string') {
    return false;
  }

  pesel = pesel.replace(/[ -]/gi, '');
  if (pesel.length !== 11) {
    return false;
  }

  const weight = [9, 7, 3, 1, 9, 7, 3, 1, 9, 7];
  let sum = 0;

  for (let i = 0; i < weight.length; i++) {
    sum += parseInt(pesel.substring(i, i + 1), 10) * weight[i];
  }

  sum = sum % 10;

  const controlDigit = parseInt(pesel.substring(10, 11), 10);
  return sum === controlDigit;
};

export const getGenderFromPesel = (pesel: string) => {
  let gender = Gender.Female;
  if (parseInt(pesel.substring(9, 10), 10) % 2 === 1) {
    gender = Gender.Male;
  }
  return gender;
};

const dateNotFuture = (value: Date | null | undefined) => {
  if (!value) {
    return true;
  }

  const validating = new Date(value);
  validating.setHours(0, 0, 0, 0);

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  return validating <= today;
};

export const isNumber = (value: string | number): boolean => {
  return (
    value !== null &&
    value !== undefined &&
    value !== '' &&
    !isNaN(Number(value.toString()))
  );
};

const yearAccurate = (value: number | null | undefined) => {
  if (!value) {
    return true;
  }

  const todayYear = new Date().getFullYear();
  return value <= todayYear + 2 && value >= todayYear - 2;
};

export const symbolRequiredWhenNotMain = (
  symbol?: string | null,
  main?: boolean | null,
) => {
  if (!main && !symbol) {
    return false;
  } else {
    return true;
  }
};

export const HarmfulFactorSchema = yup.object().shape({
  name: yup
    .string()
    .nullable()
    .required('Proszę podać nazwę czynnika szkodliwego.'),
});

export const SupportingExaminationDictSchema = yup.object().shape({
  name: yup
    .string()
    .nullable()
    .required('Proszę podać nazwę badania pomocniczego.'),
  type: yup
    .string()
    .nullable()
    .required('Proszę wybrać typ badania pomocniczego.'),
});

export const DutySchema = yup.object().shape({
  name: yup
    .string()
    .nullable()
    .required('Proszę podać nazwę grupy dyżurującej.'),
});

export const ExaminedSchema = yup.object().shape(
  {
    firstname: yup.string().nullable().required('Proszę podać imię badanego.'),
    surname: yup
      .string()
      .nullable()
      .required('Proszę podać nazwisko badanego.'),
    dateOfBirth: yup
      .date()
      .transform(value => (isNaN(value) ? undefined : value))
      .required('Proszę podać datę urodzenia.')
      .test('not future', 'Data urodzin nie może być przyszła.', dateNotFuture),
    email: yup.string().test({
      name: 'emails',
      test: function (value) {
        const firstInvalidEmail =
          value &&
          value
            .split(',')
            .map(email => email.trim())
            .filter(v => v.length > 0)
            .find(v => !isEmailSchema.isValidSync(v));

        return !firstInvalidEmail
          ? true
          : this.createError({
              message: `Adres email: '${firstInvalidEmail}' jest niepoprawny. Kilka adresów proszę rozdzielić przecinkiem. `,
            });
      },
    }),
    pesel: yup
      .string()
      .nullable()
      .test(
        'only digits in pesel',
        'Numer PESEL powinien się składać wyłącznie z cyfr.',
        digitsOnly,
      )
      .test('pesel not valid', 'Podany PESEL jest niepoprawny.', isPeselValid)
      .test(
        'pesel and birthday not match',
        'Data urodzenia niezgodna z numerem PESEL.',
        function (item) {
          return isPeselAndBirthDateValid(item, this.parent.dateOfBirth);
        },
      )
      .when(['iDCardNumber', 'iDCardType'], {
        is: (iDCardNumber: string, iDCardType: string) =>
          (!iDCardNumber || iDCardNumber.length === 0) &&
          (!iDCardType || iDCardType.length === 0),
        then: yup
          .string()
          .required(
            'Proszę podać numer PESEL lub nazwę i numer dokumentu stwierdzającego tożsamość.',
          ),
      }),
    iDCardNumber: yup
      .string()
      .nullable()
      .when('iDCardType', {
        is: (iDCardType: string) => iDCardType && iDCardType.length > 0,
        then: yup
          .string()
          .required('Proszę podać numer dokumentu stwierdzającego tożsamość.'),
      }),
    iDCardType: yup
      .string()
      .nullable()
      .when('iDCardNumber', {
        is: (iDCardNumber: string) => iDCardNumber && iDCardNumber.length > 0,
        then: yup
          .string()
          .required('Proszę podać nazwę dokumentu stwierdzającego tożsamość.'),
      }),
  },
  [['iDCardNumber', 'iDCardType']],
);

const isEmailSchema = yup.string().email();

export const TestSchema = yup.object().shape({
  name: yup.string().nullable().required('Proszę podać nazwę testu.'),
  description: yup.string().nullable().required('Proszę podać opis testu.'),
  identifier: yup
    .string()
    .nullable()
    .required('Proszę podać unikalny identyfikator testu.'),
  fileName: yup
    .string()
    .nullable()
    .matches(/^.*\.(json)$/, 'Nazwa pliku musi mieć rozszerzenie .json')
    .required('Proszę podać nazwę pliku z testem.'),
});

export const ArticleSchema = yup.object().shape({
  lp: yup.number().nullable().required('Proszę podać lp podstawy prawnej.'),
  description: yup
    .string()
    .nullable()
    .required('Proszę podać opis podstawy prawnej.'),
  legalBasis: yup.string().nullable().required('Proszę podać podstawę prawną.'),
  reason: yup.string().nullable().required('Proszę podać powód.'),
  pdfTemplateId: yup
    .string()
    .nullable()
    .required('Proszę wybrać szablon wydruku.'),
});

export const JudgmentNumberingSchema = yup.object().shape({
  registerId: yup
    .string()
    .nullable()
    .required('Proszę podać rejestr, którego dotyczy numeracja orzeczeń.'),
  actualNumber: yup
    .string()
    .nullable()
    .required('Proszę podać aktualny numer orzeczeń.'),
  year: yup
    .number()
    .nullable()
    .required('Proszę podać rok, którego dotyczy numeracja orzeczeń.')
    .test(
      'year accurate',
      'Wprowadzony rok jest za bardzo z przyszłości lub przeszłości. Proszę wprowadzić poprawny rok numeracji.',
      yearAccurate,
    ),
});

export const UserSchema = yup.object().shape({
  name: yup
    .string()
    .nullable()
    .required('Proszę podać imię i nazwisko użytkownika.'),
  email: yup
    .string()
    .email('Niepoprawny adres e-mail')
    .nullable()
    .required('Proszę podać adres e-mail.'),
});

export const BatterySchema = yup.object().shape({
  name: yup.string().nullable().required('Proszę podać nazwę.'),
  tests: yup.array().min(1, 'Proszę przypisać co najmniej jeden test.'),
});

export const NormSchema = yup.object().shape({
  description: yup.string().nullable().required('Proszę podać nazwę normy.'),
});

export const IndicatorTestSchema = yup.object().shape({
  description: yup
    .string()
    .nullable()
    .required('Proszę podać nazwę wskaźnika.'),
});

export const KeyTestSchema = yup.object().shape({
  description: yup.string().nullable().required('Proszę podać nazwę klucza.'),
});

export const AttachmentSchema = yup.object().shape({
  name: yup.string().nullable().required('Proszę wskazać plik załącznika.'),
});

export const CompanySchema = yup.object().shape({
  name: yup.string().nullable().required('Proszę podać nazwę firmy.'),
  // nip: yup
  //   .string()
  //   .nullable()
  //   .test('valid nip', 'Podany NIP jest niepoprawny.', validNip)
  //   .test(
  //     'only digits in nip',
  //     'Numer NIP powinien się składać wyłącznie z cyfr.',
  //     digitsOnly,
  //   ),
  email: yup.string().when('externalReferral', {
    is: (val: boolean) => val === true,
    then: schema =>
      schema
        .nullable()
        .required(
          'Proszę wskazać email, na który będą wysyłane dodatkowo skierowania tworzone przez pracowników tej firmy.',
        ),
    otherwise: schema => schema.nullable(),
  }),
});

export const HealthcareFacilitySchema = yup.object().shape({
  nameDoctor: yup
    .string()
    .nullable()
    .required('Proszę podać nazwę placówki lekarskiej.'),
  namePsychological: yup
    .string()
    .nullable()
    .required('Proszę podać nazwę placówki psychologicznej.'),
  displayName: yup
    .string()
    .nullable()
    .required('Proszę podać wyświetlaną w systemie nazwę placówki.'),
  city: yup.string().nullable().required('Proszę podać miejscowość.'),
  address: yup.string().nullable().required('Proszę podać adres.'),
  nip: yup.string().nullable().required('Proszę podać nip/regon.'),
  entryContentPsychological: yup
    .string()
    .nullable()
    .required('Proszę podać wpis drukowany na orzeczeniach psychologicznych.'),
  entryContentDoctor: yup
    .string()
    .nullable()
    .required('Proszę podać wpis drukowany na orzeczeniach lekarskich.'),
  symbol: yup
    .string()
    .nullable()
    .test(
      'symbol required when not main',
      'Proszę podać symbol wykorzystywany w numeracji orzeczeń.',
      function (item) {
        return symbolRequiredWhenNotMain(item, this.parent.main);
      },
    ),
});

export const ExamForExternalReferralSchema = yup.object().shape({
  employmentPosition: yup.string().when('whoExamined', {
    is: (val: string) => val === 'Employee',
    then: schema =>
      schema.nullable().required('Proszę podać stanowisko pracownika.'),
    otherwise: schema => schema.nullable(),
  }),
  employerName: yup.string().when('whoExamined', {
    is: (val: string) => val === 'Employee',
    then: schema =>
      schema.nullable().required('Proszę podać nazwę pracodawcy.'),
    otherwise: schema => schema.nullable(),
  }),
  schoolName: yup.string().when('whoExamined', {
    is: (val: string) => val === 'Student',
    then: schema =>
      schema.nullable().required('Proszę podać nazwę placówki dydaktycznej.'),
    otherwise: schema => schema.nullable(),
  }),
  fieldOfStudy: yup.string().when('whoExamined', {
    is: (val: string) => val === 'Student',
    then: schema =>
      schema
        .nullable()
        .required(
          'Proszę podać kierunek praktycznej nauki zawodu lub kształcenia.',
        ),
    otherwise: schema => schema.nullable(),
  }),
});

export const ExternalReferralSchema = yup.object().shape({
  examined: ExaminedSchema,
  examWithTests: ExamForExternalReferralSchema,
});
