import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';

import ValidationAlert from '../../../app/components/ValidationAlert';
import { useNavigation } from '../../../common/navigation';

export type Props = {
  onChangePassword: (newPwd: string, currPwd: string) => Promise<void>;
};

export const ChangePassword: React.FC<Props> = ({ onChangePassword }) => {
  const nav = useNavigation();

  const [newPassword, setNewPassword] = useState('');
  const [repeatNewPassword, setNewRepeatPassword] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [currentPasswordError, setCurrentPasswordError] = useState('');
  const [showError, setShowError] = useState(false);
  const [errorText, setErrorText] = useState('');

  const showMessage = (txt: string) => {
    setErrorText(txt);
    setShowError(true);
  };

  const handleChangePassword = async () => {
    setPasswordError('');
    setCurrentPasswordError('');
    if (!currentPassword) {
      setCurrentPasswordError('Wprowadź stare hasło.');
      return;
    }
    if (!newPassword) {
      setPasswordError('Wprowadź nowe hasło.');
      return;
    }
    if (newPassword !== repeatNewPassword) {
      setPasswordError('Nowe i powtórzone hasło nie zgadzają się.');
      return;
    }

    onChangePassword(newPassword, currentPassword)
      .then(() => {
        showMessage('Hasło zmienione.');
        nav.startPage();
      })
      .catch(err => {
        showMessage(`Nie udała się zmiana hasła. ${(err as Error).message}`);
      });
  };

  return (
    <>
      <main className="form-signin">
        <Form>
          <h3 className="fw-normal">Zmień hasło</h3>
          <Form.Group className="form-floating mt-3" controlId="oldPassword">
            <Form.Control
              type="password"
              placeholder="Stare hasło"
              onChange={e => setCurrentPassword(e.target.value)}
            />
            <Form.Label>Stare hasło</Form.Label>
          </Form.Group>
          <span className="input-form-error">{currentPasswordError}</span>
          <Form.Group className="form-floating mt-3" controlId="password">
            <Form.Control
              type="password"
              placeholder="Hasło"
              onChange={e => setNewPassword(e.target.value)}
            />
            <Form.Label>Nowe hasło</Form.Label>
          </Form.Group>
          <span className="input-form-error">{passwordError}</span>
          <Form.Group className="form-floating mt-3" controlId="repeatPassword">
            <Form.Control
              type="password"
              placeholder="Powtórzone hasło"
              onChange={e => setNewRepeatPassword(e.target.value)}
            />
            <Form.Label>Powtórzone hasło</Form.Label>
          </Form.Group>
        </Form>
        <Button
          type="submit"
          className="w-100 btn btn-lg btn-login mt-3"
          onClick={handleChangePassword}
        >
          Zmień hasło
        </Button>
      </main>
      <ValidationAlert
        heading={'Zmiana hasła'}
        variant={'warning'}
        show={showError}
        modal={true}
        onHide={() => {
          setShowError(false);
        }}
        errors={[errorText]}
      />
    </>
  );
};
