import { ApiResponse } from '../autogenerated/spdApiClient';
import { DefaultApi } from '../autogenerated/spdApiClient/apis/DefaultApi';
import { TemplateType } from '../validation/schemas';

export interface PdfPrinterContextInterface {
  saveAndPrintJudgment: (id: string) => Promise<void>;
  saveWithoutPrintJudgment: (id: string) => Promise<ApiResponse<void>>;
  loadAndPrintJudgment: (id: string) => Promise<void>;
  loadAndPrintJudgmentInEnglish: (id: string) => Promise<void>;
  saveAndPrintCard: (id: string) => Promise<void>;
  loadAndPrintCard: (id: string) => Promise<void>;
  saveAndPrintConsultation: (id: string, type: TemplateType) => Promise<void>;
  loadAndPrintConsultation: (id: string) => Promise<void>;
  saveAndPrintSupportingExamination: (id: string) => Promise<void>;
  saveWithoutPrintSupportingExamination: (
    id: string,
  ) => Promise<ApiResponse<void>>;
  loadAndPrintSupportingExamination: (id: string) => Promise<void>;
  printAnswers: (testId: string, examId: string) => Promise<void>;
  printReportJudgments: (
    filterText?: string,
    psychologistId?: string,
    companyId?: string,
    registerId?: string,
    healthcareFacilityId?: string,
    dateFrom?: Date,
    dateTo?: Date,
    onlyMonthlySettlement?: string,
  ) => Promise<void>;
  getSavedJudgment: (id: string) => Promise<ApiResponse<void>>;
  printRegisterJudgment: (
    registerId: string,
    dateFrom: Date,
    dateTo?: Date,
    userUid?: string,
    healthcareFacilityId?: string,
  ) => Promise<void>;
  printCompanies: (monthlySettlement: boolean) => Promise<void>;
  printMultiplePdf: (
    userUid?: string,
    dateFrom?: Date,
    dateTo?: Date,
  ) => Promise<void>;
}

export const PdfPrinterContext = (api: DefaultApi) => {
  const handleResponse = async (response: ApiResponse<void>) => {
    const file = await response.raw.blob();
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  };

  return {
    saveAndPrintJudgment: async (id: string) =>
      api
        .pdfPrinterControllerSaveAndPrintJudgmentRaw({ id })
        .then(handleResponse),

    saveWithoutPrintJudgment: async (id: string) =>
      api.pdfPrinterControllerSaveAndPrintJudgmentRaw({ id }),

    loadAndPrintJudgment: async (id: string) =>
      api
        .pdfPrinterControllerLoadAndPrintJudgmentRaw({ id })
        .then(handleResponse),

    loadAndPrintJudgmentInEnglish: async (id: string) =>
      api
        .pdfPrinterControllerLoadAndPrintJudgmentInEnglishRaw({ id })
        .then(handleResponse),

    saveAndPrintCard: async (id: string) =>
      api.pdfPrinterControllerSaveAndPrintCardRaw({ id }).then(handleResponse),

    loadAndPrintCard: async (id: string) =>
      api.pdfPrinterControllerLoadAndPrintCardRaw({ id }).then(handleResponse),

    saveAndPrintSupportingExamination: async (id: string) =>
      api
        .pdfPrinterControllerSaveAndPrintSupportingExaminationRaw({ id })
        .then(handleResponse),

    saveWithoutPrintSupportingExamination: async (id: string) =>
      api.pdfPrinterControllerSaveAndPrintSupportingExaminationRaw({ id }),

    loadAndPrintSupportingExamination: async (id: string) =>
      api
        .pdfPrinterControllerLoadAndPrintSupportingExaminationRaw({ id })
        .then(handleResponse),

    getSavedJudgment: async (id: string) =>
      api.pdfPrinterControllerLoadAndPrintJudgmentRaw({ id }),

    saveAndPrintConsultation: async (id: string, type: TemplateType) =>
      api
        .pdfPrinterControllerSaveAndPrintConsultationRaw({ id, type })
        .then(handleResponse),

    loadAndPrintConsultation: async (id: string) =>
      api
        .pdfPrinterControllerLoadAndPrintConsultationRaw({ id })
        .then(handleResponse),

    printAnswers: async (testId: string, examId: string) =>
      api
        .pdfPrinterControllerPrintAnswersRaw({ testId, examId })
        .then(handleResponse),

    printReportJudgments: async (
      filterText?: string,
      psychologistId?: string,
      companyId?: string,
      registerId?: string,
      healthcareFacilityId?: string,
      dateFrom?: Date,
      dateTo?: Date,
      onlyMonthlySettlement?: string,
    ) =>
      api
        .pdfPrinterControllerPrintReportJudgmentsRaw({
          filterText: filterText || '',
          psychologistId,
          companyId,
          registerId,
          healthcareFacilityId,
          dateFrom,
          dateTo,
          onlyMonthlySettlement,
        })
        .then(handleResponse),

    printRegisterJudgment: async (
      registerId: string,
      dateFrom: Date,
      dateTo?: Date,
      userUid?: string,
      healthcareFacilityId?: string,
    ) => {
      return api
        .pdfPrinterControllerPrintRegisterJudgmentRaw({
          registerId,
          dateFrom,
          dateTo,
          userUid,
          healthcareFacilityId,
        })
        .then(handleResponse);
    },
    printCompanies: async (monthlySettlement: boolean) =>
      api
        .pdfPrinterControllerPrintCompaniesRaw({
          monthlySettlement,
        })
        .then(handleResponse),
    printMultiplePdf: async (
      userUid?: string,
      dateFrom?: Date,
      dateTo?: Date,
    ) =>
      api
        .pdfPrinterControllerPrintMultiplePdfRaw({
          userUid,
          dateFrom,
          dateTo,
        })
        .then(handleResponse),
  };
};
