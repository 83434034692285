/*eslint max-lines-per-function: ["error", 300]*/
import React, { useState } from 'react';
import { Card, Tab } from 'react-bootstrap';

import { useSpdCore } from '../../../common/hooks/useSpdCore';
import { Role } from '../../../common/spdCore/authorization/roles';
import { Filter } from '../RegistersView/Filter';
import { TabsHistory } from '../../../app/components/TabsHistory';
import { ReportCompaniesSearch } from '../../components/Reports/ReportCompaniesSearch';

enum ReportCompaniesTabs {
  WITH_HEALTHCARE_REGISTER = 'Wg firmy, placówki i rejestru',
  WITH_HEALTHCARE = 'Wg firmy i placówki',
  WITH_REGISTER = 'Wg firmy i rejestru',
  WITH_COMPANY = 'Wg firmy',
}

export const ReportCompaniesView: React.FC = () => {
  const spd = useSpdCore();
  const [onlyMonthlySettlement, setOnlyMonthlySettlement] =
    useState<boolean>(false);
  const [companyId, setCompanyId] = useState<string | undefined>();
  const [dateFrom, setDateFrom] = useState<Date | undefined>(
    new Date(new Date().getFullYear(), 0, 1, 0, 0, 0, 0),
  );
  const [dateTo, setDateTo] = useState<Date | undefined>();
  const [healthcareFacilityId, setHealthcareFacilityId] = useState<
    string | undefined
  >();

  return (
    <>
      <Card>
        <Card.Header>{'Zestawienie firm'}</Card.Header>
        <Card.Body>
          <Filter
            headerName={'Zestawienie firm'}
            roles={[
              Role.MedicalAssessor,
              Role.MedicalSpecialist,
              Role.PsychologistAssessor,
              Role.PsychologistSpecialist,
            ]}
            withUser={false}
            withDate={true}
            withHealthcareFacility={true}
            withCompany={true}
            withOnlyMonthlySettlement={true}
            onChangeCompanyId={setCompanyId}
            onChangeDateFrom={setDateFrom}
            onChangeDateTo={setDateTo}
            onChangeOnlyMonthlySettlement={setOnlyMonthlySettlement}
            onChangeHealthcareFacilityId={setHealthcareFacilityId}
            canClean={true}
            emptyOnStart={true}
          />
          <TabsHistory
            activeKey={ReportCompaniesTabs.WITH_HEALTHCARE_REGISTER}
            id="product"
            className="mb-3"
            mountOnEnter
            unmountOnExit
          >
            <Tab
              eventKey={ReportCompaniesTabs.WITH_HEALTHCARE_REGISTER}
              title={ReportCompaniesTabs.WITH_HEALTHCARE_REGISTER}
              className="m-3"
            >
              <ReportCompaniesSearch
                getData={(
                  pageSize,
                  pageNumber,
                  orderBy,
                  orderDirection,
                  filterText,
                ) =>
                  spd.reports.getCompaniesByPortion(
                    pageSize,
                    pageNumber,
                    orderBy,
                    orderDirection,
                    filterText,
                    dateFrom,
                    dateTo,
                    onlyMonthlySettlement,
                    companyId,
                    healthcareFacilityId,
                    true,
                    true,
                  )
                }
                getCount={filterText =>
                  spd.reports.getCompaniesCount(
                    filterText,
                    dateFrom,
                    dateTo,
                    onlyMonthlySettlement,
                    companyId,
                    healthcareFacilityId,
                    true,
                    true,
                  )
                }
                withHealthcareFacility={true}
                withRegister={true}
              />
            </Tab>
            <Tab
              eventKey={ReportCompaniesTabs.WITH_HEALTHCARE}
              title={ReportCompaniesTabs.WITH_HEALTHCARE}
              className="m-3"
            >
              <ReportCompaniesSearch
                getData={(
                  pageSize,
                  pageNumber,
                  orderBy,
                  orderDirection,
                  filterText,
                ) =>
                  spd.reports.getCompaniesByPortion(
                    pageSize,
                    pageNumber,
                    orderBy,
                    orderDirection,
                    filterText,
                    dateFrom,
                    dateTo,
                    onlyMonthlySettlement,
                    companyId,
                    healthcareFacilityId,
                    true,
                    false,
                  )
                }
                getCount={filterText =>
                  spd.reports.getCompaniesCount(
                    filterText,
                    dateFrom,
                    dateTo,
                    onlyMonthlySettlement,
                    companyId,
                    healthcareFacilityId,
                    true,
                    false,
                  )
                }
                withHealthcareFacility={true}
                withRegister={false}
              />
            </Tab>
            <Tab
              eventKey={ReportCompaniesTabs.WITH_REGISTER}
              title={ReportCompaniesTabs.WITH_REGISTER}
              className="m-3"
            >
              <ReportCompaniesSearch
                getData={(
                  pageSize,
                  pageNumber,
                  orderBy,
                  orderDirection,
                  filterText,
                ) =>
                  spd.reports.getCompaniesByPortion(
                    pageSize,
                    pageNumber,
                    orderBy,
                    orderDirection,
                    filterText,
                    dateFrom,
                    dateTo,
                    onlyMonthlySettlement,
                    companyId,
                    healthcareFacilityId,
                    false,
                    true,
                  )
                }
                getCount={filterText =>
                  spd.reports.getCompaniesCount(
                    filterText,
                    dateFrom,
                    dateTo,
                    onlyMonthlySettlement,
                    companyId,
                    healthcareFacilityId,
                    false,
                    true,
                  )
                }
                withHealthcareFacility={false}
                withRegister={true}
              />
            </Tab>
            <Tab
              eventKey={ReportCompaniesTabs.WITH_COMPANY}
              title={ReportCompaniesTabs.WITH_COMPANY}
              className="m-3"
            >
              <ReportCompaniesSearch
                getData={(
                  pageSize,
                  pageNumber,
                  orderBy,
                  orderDirection,
                  filterText,
                ) =>
                  spd.reports.getCompaniesByPortion(
                    pageSize,
                    pageNumber,
                    orderBy,
                    orderDirection,
                    filterText,
                    dateFrom,
                    dateTo,
                    onlyMonthlySettlement,
                    companyId,
                    healthcareFacilityId,
                    false,
                    false,
                  )
                }
                getCount={filterText =>
                  spd.reports.getCompaniesCount(
                    filterText,
                    dateFrom,
                    dateTo,
                    onlyMonthlySettlement,
                    companyId,
                    healthcareFacilityId,
                    false,
                    false,
                  )
                }
                withHealthcareFacility={false}
                withRegister={false}
              />
            </Tab>
          </TabsHistory>
        </Card.Body>
      </Card>
    </>
  );
};
