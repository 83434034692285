import React, { useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

import { useSpdCore } from '../../../common/hooks/useSpdCore';
import ConfirmationButton from '../../../app/components/ConfirmationButton';
import { useAuth } from '../../../common/hooks/useAuth';
import { responseErrors } from '../../../common/spdCore/validation/responseErrors';
import { GetEmailDtoStatusEnum } from '../../../common/spdCore/autogenerated/spdApiClient';
import { SignatureStatus } from '../../../common/spdCore/validation/schemas';

export const EmailSendLink = (props: {
  judgmentId: string;
  setRefresh?: () => void;
}) => {
  const [signStatus, setSignStatus] = useState<string>('');
  const spd = useSpdCore();
  const auth = useAuth();

  spd.savedPdfs
    .getSignatureStatusForJudgment(props.judgmentId)
    .then(result => {
      setSignStatus(result.status);
    })
    .catch(err => {
      console.log('Błąd pobierania informacji o podpisie dokumentu:', err);
    });

  const handleClick = () => {
    spd.emails
      .sendJudgmentByEmail(props.judgmentId, auth.userProfile?.id || '', false)
      .then(response => {
        if (response.status !== GetEmailDtoStatusEnum.Sent) {
          alert(`Wystąpił problem z wysłaniem orzeczenia: ${response.info}`);
        }

        props.setRefresh && props.setRefresh();
      })
      .catch(async response => {
        const errors = await responseErrors(response as Response);
        alert(`Wystąpił problem z wysłaniem orzeczenia: ${errors}`);
      });
  };

  return (
    <>
      {signStatus === SignatureStatus.Signed && (
        <ConfirmationButton
          className="ms-1"
          confirmation="Czy na pewno wysłać dokument pocztą e-mail?"
          variant="btn-info"
          onOK={handleClick}
        >
          <OverlayTrigger
            overlay={
              <Tooltip style={{ position: 'fixed' }}>
                Wyślij pocztą e-mail
              </Tooltip>
            }
          >
            <Icon.SendFill />
          </OverlayTrigger>
        </ConfirmationButton>
      )}
    </>
  );
};
