import React, { useState } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

import {
  CreateUpdateExamsPackageDto,
  GetExamsPackageDto,
} from '../../../common/spdCore/autogenerated/spdApiClient';
import { useSpdCore } from '../../../common/hooks/useSpdCore';
import { responseErrors } from '../../../common/spdCore/validation/responseErrors';
import { examsPackageData } from '../../../common/spdCore/contexts/ExamsPackageContext';

import { ExamPackageEditor } from './ExamPackageEditor';

export const ExamsPackageEditLink = (props: {
  id: string;
  onRefresh?: () => void | undefined;
}) => {
  const spd = useSpdCore();

  const [show, setShow] = useState(false);
  const [examsPackage, setExamsPackage] = useState<GetExamsPackageDto>();

  const [refresh, setRefresh] = useState<boolean>(false);

  const handleClick = async (id: string) => {
    const getExamsPackage = await spd.examsPackage.get(id);
    setExamsPackage(getExamsPackage);
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setRefresh(!refresh);
  };

  const addEditExamsPackage = async (
    examsPackageCU: CreateUpdateExamsPackageDto,
    id?: string,
  ) => {
    try {
      if (id) {
        await spd.examsPackage.update(id, examsPackageCU);
      }

      setShow(false);
      setRefresh(!refresh);
      props.onRefresh && props.onRefresh();
      return {
        saved: true,
        errors: [],
      };
    } catch (response) {
      return {
        saved: false,
        errors: await responseErrors(response as Response),
      };
    }
  };

  return (
    <>
      <OverlayTrigger
        overlay={<Tooltip>Edycja opisu i wysyłki maili w paczce badań</Tooltip>}
      >
        <Button
          onClick={async e => {
            e.stopPropagation();
            await handleClick(props.id);
          }}
          variant="btn-info"
        >
          <Icon.Pencil />
        </Button>
      </OverlayTrigger>
      {examsPackage?.id && (
        <ExamPackageEditor
          id={examsPackage.id}
          examsPackage={examsPackageData(examsPackage)}
          onCompleted={addEditExamsPackage}
          show={show}
          handleClose={handleClose}
          readOnly={false}
        />
      )}
    </>
  );
};
