import React from 'react';

import { useSpdCore } from '../../../common/hooks/useSpdCore';

import { CardsSearch } from './CardsSearch';

export const CardsList: React.FC = () => {
  const spd = useSpdCore();

  const handleClick = async (id: string) => {
    await spd.cards.get(id);
  };

  return (
    <>
      <CardsSearch
        getData={spd.cards.getAllByPortion}
        getCount={spd.cards.getAllCount}
        onRowClick={handleClick}
      />
    </>
  );
};
