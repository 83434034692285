import React from 'react';
import { Card, Tab } from 'react-bootstrap';

import Tabs from '../../../app/components/Tabs';
import { CompanyList } from '../../components/Company/CompanyList';

export const CompaniesView: React.FC = () => {
  return (
    <Card>
      <Tabs defaultActiveKey="search" id="companies" className="mb-3">
        <Tab eventKey="search" title="Firmy" className="m-3">
          <CompanyList />
        </Tab>
      </Tabs>
    </Card>
  );
};
