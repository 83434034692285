/* tslint:disable */
/* eslint-disable */
/**
 * SPD
 * Opis api SPD
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetExamDto,
    GetExamDtoFromJSON,
    GetExamDtoFromJSONTyped,
    GetExamDtoToJSON,
    GetReactionDto,
    GetReactionDtoFromJSON,
    GetReactionDtoFromJSONTyped,
    GetReactionDtoToJSON,
    GetTestDto,
    GetTestDtoFromJSON,
    GetTestDtoFromJSONTyped,
    GetTestDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetResultDto
 */
export interface GetResultDto {
    /**
     * 
     * @type {string}
     * @memberof GetResultDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetResultDto
     */
    taskNumber?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetResultDto
     */
    taskTime?: number | null;
    /**
     * 
     * @type {Array<GetReactionDto>}
     * @memberof GetResultDto
     */
    reactions?: Array<GetReactionDto> | null;
    /**
     * 
     * @type {GetExamDto}
     * @memberof GetResultDto
     */
    exam?: GetExamDto | null;
    /**
     * 
     * @type {GetTestDto}
     * @memberof GetResultDto
     */
    test?: GetTestDto | null;
    /**
     * 
     * @type {string}
     * @memberof GetResultDto
     */
    taskText?: string | null;
}

export function GetResultDtoFromJSON(json: any): GetResultDto {
    return GetResultDtoFromJSONTyped(json, false);
}

export function GetResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetResultDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'taskNumber': !exists(json, 'taskNumber') ? undefined : json['taskNumber'],
        'taskTime': !exists(json, 'taskTime') ? undefined : json['taskTime'],
        'reactions': !exists(json, 'reactions') ? undefined : (json['reactions'] === null ? null : (json['reactions'] as Array<any>).map(GetReactionDtoFromJSON)),
        'exam': !exists(json, 'exam') ? undefined : GetExamDtoFromJSON(json['exam']),
        'test': !exists(json, 'test') ? undefined : GetTestDtoFromJSON(json['test']),
        'taskText': !exists(json, 'taskText') ? undefined : json['taskText'],
    };
}

export function GetResultDtoToJSON(value?: GetResultDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'taskNumber': value.taskNumber,
        'taskTime': value.taskTime,
        'reactions': value.reactions === undefined ? undefined : (value.reactions === null ? null : (value.reactions as Array<any>).map(GetReactionDtoToJSON)),
        'exam': GetExamDtoToJSON(value.exam),
        'test': GetTestDtoToJSON(value.test),
        'taskText': value.taskText,
    };
}


