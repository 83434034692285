import React from 'react';

import ConfirmationButton from '../../../app/components/ConfirmationButton';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { useSpdCore } from '../../../common/hooks/useSpdCore';
import { useNavigation } from '../../../common/navigation';

export const ExamLink = (props: {
  examId: string | undefined;
  examNumber: number | undefined;
}) => {
  const notifications = useNotifications();
  const spd = useSpdCore();
  const nav = useNavigation();

  const handleOnDelete = async () => {
    if (props.examId) {
      notifications.onPromise(spd.exams.changeToInactive(props.examId));
      nav.exams();
    }
  };

  return (
    <div>
      <ConfirmationButton
        className="ms-1"
        confirmation={`Czy na pewno usunąć badanie ${
          props.examNumber || ''
        } razem z wynikami i wydrukami?`}
        variant="outline-danger"
        onOK={handleOnDelete}
      >
        Usuń badanie
      </ConfirmationButton>
    </div>
  );
};
