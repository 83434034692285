import React from 'react';

import { GetIndicatorTestDto } from '../../../common/spdCore/autogenerated/spdApiClient/models/GetIndicatorTestDto';
import { Grid } from '../../../common/components/Grid/Grid';
import {
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import { IndicatorTestModeText } from '../../../common/spdCore/validation/schemas';

interface IndicatorTestsProps {
  getData: GridGetterFunction;
  getCount: GridCountFunction;
  refresh?: boolean;
}

export const indicatorTestForGrid = (indicatorTest: GetIndicatorTestDto) => {
  return {
    key: indicatorTest.id,
    values: {
      'indicator_test.name': indicatorTest.name,
      'key_test.name': indicatorTest.keyTest?.name,
      'test.name': indicatorTest.test?.name,
      'indicator_test.mode':
        IndicatorTestModeText[
          indicatorTest.mode as keyof typeof IndicatorTestModeText
        ],
    },
  };
};

export const IndicatorTest: React.FC<IndicatorTestsProps> = props => {
  const columns = [
    { header: 'Nazwa', property: 'indicator_test.name' },
    { header: 'Klucz testu', property: 'key_test.name' },
    { header: 'Test', property: 'test.name' },
    { header: 'Typ wskaźnika', property: 'indicator_test.mode' },
  ];

  return (
    <Grid
      getData={props.getData}
      getDataCount={props.getCount}
      columns={columns}
      showFilter={true}
      initialOrderColumn="indicator_test.name"
      availablePageSizes={[10, 20, 50]}
      emptyGridMessage="Brak wskaźników w systemie"
      enableMultiSelect={false}
      refresh={props.refresh}
    />
  );
};
