import React from 'react';

import { GetNormDto } from '../../../common/spdCore/autogenerated/spdApiClient/models/GetNormDto';
import { Grid } from '../../../common/components/Grid/Grid';
import {
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';

interface NormsProps {
  getData: GridGetterFunction;
  getCount: GridCountFunction;
  onClick: (id: string) => void;
  refresh: boolean;
}

export const normForGrid = (norm: GetNormDto) => {
  return {
    key: norm.id,
    values: {
      'norm.name': norm.name,
      //'norm.condition': norm.condition,
      //'norm.group': norm.group,
      'indicator_test.name': norm.indicatorTest?.name,
      'test.name': norm.indicatorTest?.test?.name,
    },
  };
};

export const Norm: React.FC<NormsProps> = props => {
  const columns = [
    { header: 'Nazwa', property: 'norm.name' },
    //{ header: 'Warunek', property: 'norm.condition' },
    //{ header: 'Grupa', property: 'norm.group' },
    { header: 'Wskaźnik', property: 'indicator_test.name' },
    { header: 'Test', property: 'test.name' },
  ];

  return (
    <Grid
      getData={props.getData}
      getDataCount={props.getCount}
      columns={columns}
      onRowClick={props.onClick}
      showFilter={true}
      initialOrderColumn="norm.name"
      availablePageSizes={[10, 20, 50]}
      emptyGridMessage="Brak norm w systemie"
      enableMultiSelect={false}
      refresh={props.refresh}
    />
  );
};
