import React from 'react';

import { Grid } from '../../../common/components/Grid/Grid';
import {
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import { GetHarmfulFactorDto } from '../../../common/spdCore/autogenerated/spdApiClient';

interface HarmfulFactorProps {
  getData: GridGetterFunction;
  getCount: GridCountFunction;
  refresh?: boolean;
  onRowClick: (key: string) => Promise<void> | void | undefined;
}

export const harmfulFactorForGrid = (harmfulFactor: GetHarmfulFactorDto) => {
  return {
    key: harmfulFactor.id,
    values: {
      'harmfulFactor.name': harmfulFactor.name,
      'harmfulFactor.active': harmfulFactor.active ? 'Tak' : 'Nie',
    },
  };
};

export const HarmfulFactorSearch: React.FC<HarmfulFactorProps> = props => {
  const columns = [
    { header: 'Nazwa', property: 'harmfulFactor.name' },
    { header: 'Aktywny', property: 'harmfulFactor.active' },
  ];

  return (
    <Grid
      getData={props.getData}
      getDataCount={props.getCount}
      columns={columns}
      showFilter={true}
      onRowClick={props.onRowClick}
      initialOrderColumn="harmfulFactor.name"
      availablePageSizes={[10, 20, 50]}
      emptyGridMessage="Brak czynników szkodliwych w systemie"
      enableMultiSelect={false}
      refresh={props.refresh}
    />
  );
};
