import React from 'react';
import { Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';

import { Grid } from '../../../common/components/Grid/Grid';
import { GetResultDto } from '../../../common/spdCore/autogenerated/spdApiClient';
import { GivingExamMode } from '../../../common/spdCore/validation/schemas';

interface ResultsProps {
  data: GetResultDto[];
}

export const Results: React.FC<ResultsProps> = props => {
  const columns = [
    { header: 'Numer zadania', property: 'taskNumber' },
    { header: 'Całkowity czas prezentacji', property: 'taskTime' },
    { header: 'Czas latencji (ostatni)', property: 'latencyTime' },
    { header: 'Czas reakcji (ostatni)', property: 'reactionTime' },
    { header: 'Odpowiedź (ostatnia)', property: 'codeAnswer' },
    { header: 'Pytanie', property: 'taskText' },
    { header: 'Zdekodowana odpowiedź (ostatnia)', property: 'value' },
    { header: 'Liczba odpowiedzi', property: 'reactionsCount' },
    { header: '', property: 'psychologistEdit' },
  ];

  const data = props.data.map(result => {
    if (result.reactions && result.reactions?.length > 0) {
      const lastReaction = result.reactions.slice(-1)[0];
      return {
        key: result.id,
        values: {
          taskNumber: result.taskNumber,
          taskText: result.taskText,
          taskTime: result.taskTime,
          latencyTime: historicalView(
            result.reactions?.length,
            result.reactions.map(r => r?.latencyTime).join('; '),
            lastReaction.latencyTime,
          ),
          reactionTime: historicalView(
            result.reactions?.length,
            result.reactions.map(r => r?.reactionTime).join('; '),
            lastReaction.reactionTime,
          ),
          codeAnswer: historicalView(
            result.reactions?.length,
            result.reactions
              .map(r => r?.answers?.map(o => o.codeAnswer).join(', '))
              .join('; '),
            lastReaction?.answers?.map(o => o.codeAnswer).join(', '),
          ),
          value: historicalView(
            result.reactions?.length,
            result.reactions
              .map(r => r?.answers?.map(o => o.value).join(', '))
              .join('; '),
            lastReaction?.answers?.map(o => o.value).join(', '),
            false,
            lastReaction?.answers
              ?.filter(o => o.criticalType)
              .map(o => o.criticalType)
              .join(', '),
          ),
          reactionsCount: result.reactions?.length,
          psychologistEdit: (lastReaction.modeSurvey ===
            GivingExamMode.PsychologistFast ||
            lastReaction.modeSurvey === GivingExamMode.PsychologistFull) && (
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip>Dane zmienione przez psychologa</Tooltip>}
            >
              <Badge bg="success" className="me-1">
                *
              </Badge>
            </OverlayTrigger>
          ),
        },
        orderByValues: {
          taskNumber: result.taskNumber || '',
        },
      };
    }

    return {
      key: result.id,
      values: {
        taskNumber: result.taskNumber,
        taskTime: result.taskTime,
        latencyTime: '',
        reactionTime: '',
        codeAnswer: '',
        value: '',
        reactionsCount: result.reactions?.length,
        psychologistEdit: '',
      },
      orderByValues: {
        taskNumber: result.taskNumber || '',
      },
    };
  });

  return (
    <Grid
      data={data}
      columns={columns}
      showFilter={true}
      initialOrderColumn="result.taskNumber"
      initialOrderDirection="ASC"
      availablePageSizes={[10, 20, 50]}
      emptyGridMessage="Brak wyników w teście w badaniu"
    />
  );
};
function historicalView(
  length: number,
  paramTooltip: string,
  paramBadge: string | number | null | undefined,
  withBadge = true,
  criticalType = '',
): string | number | JSX.Element | null | undefined {
  const paramBadgeString = (paramBadge || '')?.toString().includes(';base64')
    ? 'obraz/video'
    : paramBadge;
  return (
    <>
      {length <= 1 ? (
        <>
          {criticalType && (
            <Badge
              bg="danger"
              className="me-1"
              onClick={e => e.stopPropagation()}
            >
              !!!
            </Badge>
          )}
          {paramBadgeString}
        </>
      ) : (
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip>{paramTooltip}</Tooltip>}
        >
          <div>
            {criticalType && (
              <Badge
                bg="danger"
                className="me-1"
                onClick={e => e.stopPropagation()}
              >
                !!!
              </Badge>
            )}
            {withBadge && (
              <Badge
                bg="success"
                className="me-1"
                onClick={e => e.stopPropagation()}
              >
                {paramBadge}
              </Badge>
            )}
            {!withBadge && paramBadgeString}
          </div>
        </OverlayTrigger>
      )}
    </>
  );
}
