import React, { useState } from 'react';
import { Button, Card, Tab } from 'react-bootstrap';

import Tabs from '../../../app/components/Tabs';
import { useSpdCore } from '../../../common/hooks/useSpdCore';
import { IndicatorTest } from '../../components/IndicatorTests/IndicatorTests';
import { ImportIndicators } from '../../components/IndicatorTests/ImportIndicators';
import { IndicatorXls } from '../../components/IndicatorTests/ImportIndicatorsValidator';

export const IndicatorTestsView: React.FC = () => {
  const [show, setShow] = useState(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const spd = useSpdCore();

  const handleClose = () => {
    setShow(false);
    setRefresh(!refresh);
  };

  const addTestKeys = async (indicatorsJson: IndicatorXls[]) => {
    for (const indicator of indicatorsJson) {
      await spd.indicatorTests.createImported({
        name: indicator.nazwa,
        mode: indicator.typ_wskaznika,
        keyTestName: indicator.nazwa_klucza_testu,
        testIdentifier: indicator.identyfikator_testu,
      });
    }
    setRefresh(!refresh);
    setShow(false);
    return {
      saved: true,
      error: '',
    };
  };

  return (
    <Card>
      <Tabs defaultActiveKey="search" id="indicatorTests" className="mb-3">
        <Tab eventKey="search" title="Wskaźniki" className="m-3">
          <IndicatorTest
            getData={spd.indicatorTests.getAllByPortion}
            getCount={spd.indicatorTests.getAllCount}
            refresh={refresh}
          />
          <Button variant="outline-primary" onClick={() => setShow(true)}>
            Importuj wskaźniki
          </Button>
          <ImportIndicators
            onImport={addTestKeys}
            show={show}
            handleClose={handleClose}
          />
        </Tab>
      </Tabs>
    </Card>
  );
};
