import React, { useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import { Accordion, Button, Col, Row } from 'react-bootstrap';

import {
  GetArticleDto,
  GetExamDto,
} from '../../../common/spdCore/autogenerated/spdApiClient';
import { useSpdCore } from '../../../common/hooks/useSpdCore';
import { GetJudgmentDto } from '../../../common/spdCore/autogenerated/spdApiClient/models/GetJudgmentDto';
import { ArticleMode } from '../../../common/spdCore/validation/schemas';
import { Role } from '../../../common/spdCore/authorization/roles';

import { ExamInfo } from './ExamInfo';
import { JudgmentEditor } from './JudgmentEditor';

interface ExamJudgmentEditorProps {
  exam: GetExamDto;
  judgmentId?: string;
  articles: GetArticleDto[];
  roles: Role[];
  mode: ArticleMode;
}

export const ExamJudgmentEditor: React.FC<ExamJudgmentEditorProps> = props => {
  const spd = useSpdCore();

  const [judgments, setJudgments] = useState<Partial<GetJudgmentDto>[]>([]);
  const [activeItem, setActiveItem] = useState<string>();

  useEffect(() => {
    spd.judgments
      .getForExam(props.exam.id)
      .then(juds =>
        setJudgments(
          juds.filter(
            o =>
              (o.article?.mode as unknown as ArticleMode | undefined) ===
              props.mode,
          ),
        ),
      )
      .catch(error => {
        console.error(
          'Wystąpił problem z pobraniem orzeczeń związanych z badaniem',
          error,
        );
      });
  }, [spd.judgments, props.exam.id, props.mode]);

  const nextExamDate = () => {
    const endDay = new Date();
    let endDayShort = new Date(endDay);
    if (!props.exam?.examined?.dateOfBirth) {
      return null;
    }

    endDay.setFullYear(endDay.getFullYear() + 5);
    let age = new Date(props.exam?.examined?.dateOfBirth);
    age.setFullYear(age.getFullYear() + 62);
    age = new Date(age.setMonth(age.getMonth() + 6));

    if (age < endDay) {
      endDayShort = new Date(endDayShort.setMonth(endDayShort.getMonth() + 30));
      if (age > endDayShort) {
        return age;
      }
      return endDayShort;
    } else {
      return endDay;
    }
  };

  const handleOnClick = (idx: string) => {
    setActiveItem(idx === activeItem ? undefined : idx);
  };

  return (
    <>
      <Row>
        <Col className="col-lg-6 col-12 mb-1">
          <ExamInfo
            exam={props.exam}
            articles={props.articles}
            mode={props.mode}
          />
        </Col>
        <Col className="col-lg-6 col-12 mb-1">
          <Card className="mb-2">
            <Card.Header>
              {`Orzeczenia ${
                props.mode === ArticleMode.Psychologist
                  ? 'psychologiczne'
                  : 'lekarskie'
              } do badania ${props.exam?.number}`}
            </Card.Header>
            <Card.Body>
              <Accordion
                className="mb-3"
                activeKey={activeItem}
                defaultActiveKey={activeItem}
              >
                {judgments.map((judg, idx) => (
                  <div key={idx} className="d-flex mb-1">
                    <JudgmentEditor
                      exam={props.exam}
                      judg={judg}
                      articles={props.articles}
                      roles={props.roles}
                      handleOnClick={() => handleOnClick(idx.toString())}
                      idx={idx.toString()}
                      mode={props.mode}
                    />
                  </div>
                ))}
              </Accordion>
              <Button
                variant="outline-primary"
                className="ms-1"
                onClick={async () => {
                  setActiveItem(judgments.length.toString());

                  setJudgments([
                    ...judgments,
                    {
                      judgmentDate: new Date(),
                      nextExamDate:
                        props.mode === ArticleMode.Psychologist
                          ? nextExamDate()
                          : null,
                      pass: true,
                      drivingLicense: props?.exam?.drivingLicense,
                    },
                  ]);
                }}
              >
                Dodaj nowe orzeczenie
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};
