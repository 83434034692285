import { DefaultApi } from '../autogenerated/spdApiClient/apis/DefaultApi';
import { GetAccessDto } from '../autogenerated/spdApiClient';
import { GridRecord } from '../../components/Grid/GridDataTypes';
import { accessForGrid } from '../../../spd/components/Access/Access';

export interface AccessContextInterface {
  get: (id: string) => Promise<GetAccessDto>;
  getAllCount: (filterText?: string) => Promise<number>;
  getAllByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
  ) => Promise<GridRecord[]>;
  //   update: (id: string, dto: CreateUpdateAccessDto) => Promise<void>;
}

export const AccessContext = (api: DefaultApi) => {
  return {
    get: async (id: string) => {
      return await api.accessControllerGet(id);
    },

    getAllByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
    ) => {
      const data = await api.accessControllerGetAllByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
      );
      return data.map(accessForGrid);
    },

    getAllCount: (filterText?: string) =>
      api.accessControllerGetAllCount(filterText || ''),
    // update: (id: string, dto: CreateUpdateAccessDto) =>
    //   api.accessControllerUpdate(id, dto),
  };
};
