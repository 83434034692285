import React, { useEffect, useState } from 'react';

import { useAuth } from '../../../common/hooks/useAuth';
import { useSzafir } from '../../../common/hooks/useSzafir';
import { useSpdCore } from '../../../common/hooks/useSpdCore';
import { responseErrors } from '../../../common/spdCore/validation/responseErrors';
import { GetJudgmentDto } from '../../../common/spdCore/autogenerated/spdApiClient';
import ConfirmationButton from '../../../app/components/ConfirmationButton';

interface MultipleJudgmentSigningProps {
  onSignedFinished: (
    errors: string[],
    allCount: number,
    errorCount: number,
  ) => Promise<void> | void | undefined;
  registerId: string;
  userUid: string;
}

export const MultipleJudgmentSigning: React.FC<
  MultipleJudgmentSigningProps
> = props => {
  const [signingInProgress, setSigningInProgress] = useState(false);
  const [sendSummary, setSendSummary] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [allCount, setAllCount] = useState<number>(0);
  const [errorCount, setErrorCount] = useState<number>(0);
  const [progress, setProgress] = useState<string>('');
  const auth = useAuth();
  const spd = useSpdCore();
  const szafir = useSzafir();

  useEffect(() => {
    if (sendSummary) {
      void props.onSignedFinished(errors, allCount, errorCount);
      setSendSummary(false);
      setProgress('');
      setErrors([]);
      setAllCount(0);
      setErrorCount(0);
    }
  }, [errors, errorCount, allCount, props, sendSummary]);

  const onSign = async () => {
    setSigningInProgress(true);
    let judgments: GetJudgmentDto[] = [];
    try {
      judgments = await spd.judgments.getAllForUserRegisterToSign(
        props.userUid,
        props.registerId,
      );
    } catch (err) {
      const errors = await responseErrors(err as Response);
      setErrors(prevArray => [
        ...prevArray,
        `Błąd pobierania orzeczenia dla registerId: ${props.registerId} i userUid: ${props.userUid}`,
        ...errors,
      ]);
      setSigningInProgress(false);
      setSendSummary(true);
      return;
    }

    setAllCount(judgments.length);
    for (let index = 0; index < judgments.length; index++) {
      const judgment = judgments[index];
      setProgress(`podpisano ${index + 1} z ${judgments.length}`);
      await signSaveAndSend(judgment, index);
      if (judgment.article?.engPdfTemplateId) {
        await signSaveAndSend(judgment, index, true);
      }
    }
    setSigningInProgress(false);
    setSendSummary(true);
  };

  const signSaveAndSend = async (
    judgment: GetJudgmentDto,
    index: number,
    english = false,
  ) => {
    try {
      const taskList = await spd.szafir.getTaskListForJudgment(
        judgment.id,
        english,
        auth.userProfile?.id,
      );
      await szafir.signDocument(
        taskList,
        auth.userProfile?.pin || '',
        judgment.id,
        judgment.number || '',
        english,
        saveSignedPdf,
        signedFailed,
      );
      if (index === 0) {
        await new Promise(f => setTimeout(f, 3000));
      } else {
        await new Promise(f => setTimeout(f, 2000));
      }
    } catch (err) {
      setErrorCount(prevCount => prevCount + 1);
      const errors = await responseErrors(err as Response);
      setErrors(prevArray => [
        ...prevArray,
        `Błąd podpisywania dokumentu: ${judgment.number} - `,
        ...errors,
      ]);
    }
  };

  const saveSignedPdf = async (
    result: Buffer | null,
    documentId: string,
    documentNumber: string,
    english: boolean,
  ) => {
    spd.savedPdfs
      .savePdfFile({
        entityId: documentId,
        file: result,
        english: english,
      })
      .then(async () => {
        await spd.emails.sendJudgmentByEmail(
          documentId,
          auth.userProfile?.id || '',
          english,
        );
      })
      .catch(async response => {
        setErrorCount(prevCount => prevCount + 1);
        const errors = await responseErrors(response as Response);
        setErrors(prevArray => [
          ...prevArray,
          `Błąd: ${documentNumber} - `,
          ...errors,
        ]);
      });
  };

  const signedFailed = (
    error: string,
    documentId: string,
    documentNumber: string,
  ) => {
    setErrorCount(prevCount => prevCount + 1);
    setErrors(prevArray => [
      ...prevArray,
      `Błąd podpisywania dokumentu: ${documentNumber} - `,
      error,
    ]);
  };

  return (
    <>
      <ConfirmationButton
        className="mb-3"
        confirmation="Czy na pewno rozpocząć podpisywanie wszystkich dostępnych do podpisu orzeczeń?"
        variant="outline-primary"
        onOK={() => onSign()}
      >
        {signingInProgress
          ? `Trwa podpisywanie...  ${progress}`
          : 'Podpisz wszystkie swoje orzeczenia'}
      </ConfirmationButton>
    </>
  );
};
