import React from 'react';

import { Grid } from '../../../common/components/Grid/Grid';
import { GetInterpretationNormDto } from '../../../common/spdCore/autogenerated/spdApiClient';

interface InterpretationNormsProps {
  data?: GetInterpretationNormDto[];
}

export const interpretationNormsForGrid = (
  interpretation: GetInterpretationNormDto,
) => {
  return {
    key: interpretation.id,
    values: {
      'interpretation.mode': interpretation.result,
      'interpretation.rangeFrom': interpretation.rangeFrom,
      'interpretation.rangeTo': interpretation.rangeTo,
      'interpretation.description': interpretation.description,
    },
  };
};

export const InterpretationNorms: React.FC<
  InterpretationNormsProps
> = props => {
  const columns = [
    { header: 'Wynik', property: 'interpretation.mode' },
    { header: 'Zakres od', property: 'interpretation.rangeFrom' },
    { header: 'Zakres do', property: 'interpretation.rangeTo' },
    { header: 'Opis', property: 'interpretation.description' },
  ];

  return (
    <Grid
      data={props.data && props.data.map(d => interpretationNormsForGrid(d))}
      columns={columns}
      showFilter={false}
      initialOrderColumn="interpretation.rangeFrom"
      availablePageSizes={[10, 20, 50]}
      emptyGridMessage="Brak pozycji normy w systemie"
      enableMultiSelect={false}
    />
  );
};
