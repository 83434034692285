import React, { useEffect, useState } from 'react';
import { Alert, Card, Tab, Tabs } from 'react-bootstrap';

import { useSpdCore } from '../../../common/hooks/useSpdCore';
import { JudgmentsSearch } from '../../components/Judgments/JudgmentsSearch';
import { Role } from '../../../common/spdCore/authorization/roles';
import { useSzafir } from '../../../common/hooks/useSzafir';
import { useAuth } from '../../../common/hooks/useAuth';
import { MultipleJudgmentSigning } from '../../components/Szafir/MultipleJudgmentSigning';
import { Filter } from '../RegistersView/Filter';
import { ReferralsSearch } from '../../components/Referrals/ReferralsSearch';
import { MultipleReferralSigning } from '../../components/Szafir/MultipleReferralSigning';

export const JudgmentsForSigningView: React.FC = () => {
  const spd = useSpdCore();
  const [userUid, setUserUid] = useState<string | undefined>();
  const [message, setMessage] = useState<string>('');
  const [alertVariant, setAlertVariant] = useState<string>('');
  const [refresh, setRefresh] = useState<boolean>(false);

  const auth = useAuth();
  const szafir = useSzafir();

  useEffect(() => {
    const interval = setInterval(() => setRefresh(!refresh), 30000);
    return () => {
      clearInterval(interval);
    };
  }, [refresh]);

  useEffect(() => {
    if (auth.canSign) {
      szafir.activate(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.canSign]);

  const onSignedFinished = (
    errors: string[],
    allCount: number,
    errorCount: number,
  ) => {
    setRefresh(!refresh);
    errors.length === 0
      ? setAlertVariant('success')
      : setAlertVariant('danger');
    if (allCount === 0 && errors.length === 0) {
      setMessage('Brak dokumentów do podpisania.');
      return;
    }
    const errorList = errorCount > 0 ? errors.join('\n') : '';
    setMessage(
      `Zakończono podpisywanie ${allCount} dokumentów. Liczba poprawnie podpisanych dokumentów: ${
        allCount - errorCount
      }. ${errorList} `,
    );
    return;
  };

  const onRefresh = () => {
    setRefresh(!refresh);
  };

  return (
    <>
      <Card>
        <Card.Header>{'Dokumenty oczekujące na podpis'}</Card.Header>
        <Card.Body>
          <MultipleJudgmentSigning
            registerId={''}
            userUid={auth.currentUser?.uid || ''}
            onSignedFinished={onSignedFinished}
          />
          &nbsp;
          <MultipleReferralSigning
            registerId={''}
            userUid={auth.currentUser?.uid || ''}
            onSignedFinished={onSignedFinished}
          />
          {message && <Alert variant={alertVariant}>{message}</Alert>}
          <Filter
            headerName={'Dokumenty oczekujące na podpis'}
            roles={[
              Role.MedicalAssessor,
              Role.MedicalSpecialist,
              Role.PsychologistAssessor,
              Role.PsychologistSpecialist,
            ]}
            withUser={true}
            withDate={false}
            withHealthcareFacility={false}
            userName={'Psycholog / Lekarz'}
            userUid={userUid}
            onChangeUserUid={setUserUid}
          />{' '}
          <Tabs defaultActiveKey="judgments" id="tabs" className="mb-2">
            <Tab eventKey="judgments" title="Orzeczenia oczekujące na podpis">
              {userUid && (
                <JudgmentsSearch
                  getData={(
                    pageSize,
                    pageNumber,
                    orderBy,
                    orderDirection,
                    filterText,
                  ) =>
                    spd.judgments.getForSigningByPortion(
                      pageSize,
                      pageNumber,
                      orderBy,
                      orderDirection,
                      filterText,
                      userUid,
                      onRefresh,
                    )
                  }
                  getCount={filterText =>
                    spd.judgments.getForSigningCount(filterText, userUid)
                  }
                  refresh={refresh}
                />
              )}
            </Tab>
            <Tab eventKey="referalls" title="Skierowania oczekujące na podpis">
              {userUid && (
                <ReferralsSearch
                  getData={(
                    pageSize,
                    pageNumber,
                    orderBy,
                    orderDirection,
                    filterText,
                  ) =>
                    spd.support.getWithPrintsForSigningByPortion(
                      pageSize,
                      pageNumber,
                      orderBy,
                      orderDirection,
                      filterText,
                      userUid,
                      onRefresh,
                    )
                  }
                  getCount={filterText =>
                    spd.support.getWithPrintsForSigningCount(
                      filterText,
                      userUid,
                    )
                  }
                  refresh={refresh}
                />
              )}
            </Tab>
          </Tabs>
        </Card.Body>
      </Card>
    </>
  );
};
