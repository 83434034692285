import React, { useEffect, useState, useRef, PropsWithChildren } from 'react';
import Cookies from 'js-cookie';

export interface CookiesProviderType {
  activeTabMonitor?: string;
  setActiveTabMonitor: (value: string) => void;

  activeMonitor?: string;
  setActiveMonitor: (value: string) => void;
}

const emptyHandler = () => {
  // typing purpose
};

export const CookiesContext = React.createContext({
  activeTabMonitor: undefined,
  setActiveTabMonitor: emptyHandler,
  activeMonitor: undefined,
  setActiveMonitor: emptyHandler,
} as CookiesProviderType);

const ActiveTabMonitor = 'active-tab-monitor';
const ActiveMonitor = 'active-monitor';

export const onLogout = () => {
  Cookies.remove(ActiveTabMonitor);
  Cookies.remove(ActiveMonitor);
};

export const CookiesProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [cookiesState, setCookiesState] = useState(
    new Map<string, string | undefined>(),
  );
  const loadingRef = useRef(false);

  const rawset = (name: string, value: string) => {
    Cookies.set(name, value, {
      sameSite: 'None',
      secure: true,
      path: '/',
    });
  };

  const set = (name: string, value: string) => {
    rawset(name, value);
    const newState = new Map(cookiesState);
    newState.set(name, value);
    setCookiesState(newState);
  };

  useEffect(() => {
    if (loadingRef.current) {
      return;
    }

    const map = new Map<string, string | undefined>();
    map.set(ActiveTabMonitor, Cookies.get(ActiveTabMonitor));
    map.set(ActiveMonitor, Cookies.get(ActiveMonitor));
    setCookiesState(map);
  }, []);

  return (
    <CookiesContext.Provider
      value={{
        activeTabMonitor: cookiesState.get(ActiveTabMonitor),
        setActiveTabMonitor: (value: string) => set(ActiveTabMonitor, value),
        activeMonitor: cookiesState.get(ActiveMonitor),
        setActiveMonitor: (value: string) => set(ActiveMonitor, value),
      }}
    >
      {children}
    </CookiesContext.Provider>
  );
};
