/*eslint max-lines-per-function: ["error", 350]*/
/* eslint-disable complexity */
import Select from 'react-select';
import React, { useEffect, useState } from 'react';
import { Form, Card, Button } from 'react-bootstrap';

import FormRow from '../../../app/components/FormRow';
import {
  DrivingLicenseCategory,
  Healthcare,
  OccupationalMedicineCategory,
} from '../../../common/spdCore/validation/schemas';
import { CreateUpdateExamWithTestsDto } from '../../../common/spdCore/autogenerated/spdApiClient';
import HealthcareFacilitySelector from '../Selects/HealthcareFacilitySelector';

interface Props {
  prevStep?: () => void;
  nextStep?: () => void;
  handleFormData?: (input: string) => (s: string | boolean | Date) => void;
  drivingLicense: string | null | undefined;
  occupMedicineText: string | null | undefined;
  occupMedicineCategory: string[] | null | undefined;
  description: string | null | undefined;
  completed?: boolean | null | undefined;
  neurologist?: boolean | null | undefined;
  ophthalmologist?: boolean | null | undefined;
  laryngologist?: boolean | null | undefined;
  healthcareFacilityId?: string | null | undefined;
  healthcare?: Healthcare;
  alarm?: boolean | null | undefined;
  onChange?: (testsKeys: string[]) => void;
  propertyChange?: (obj: Partial<CreateUpdateExamWithTestsDto>) => void;
}

export const AddDescription: React.FC<Props> = ({
  prevStep,
  nextStep,
  handleFormData,
  drivingLicense,
  occupMedicineText,
  occupMedicineCategory,
  description,
  completed,
  neurologist,
  ophthalmologist,
  laryngologist,
  healthcareFacilityId,
  healthcare,
  alarm,
  propertyChange,
}) => {
  const [selectedDrivingCategories, setSelectedDrivingCategories] = useState<
    string[]
  >(drivingLicense?.split(',') || []);
  const [selectedOccupMedicineCategory, setSelectedOccupMedicineCategory] =
    useState<string[]>(occupMedicineCategory || []);

  useEffect(() => {
    setSelectedDrivingCategories(drivingLicense?.split(',') || []);
    setSelectedOccupMedicineCategory(occupMedicineCategory || []);
  }, [drivingLicense, occupMedicineCategory]);

  const handleOnNext = () => {
    nextStep && nextStep();
  };

  const handleOnPrev = () => {
    prevStep && prevStep();
  };

  const propertyDescriptionChange = (desc: string) => {
    handleFormData && handleFormData('description')(desc);
    propertyChange && propertyChange({ description: desc });
  };

  const propertyCompletedChange = (com: boolean) => {
    propertyChange && propertyChange({ completed: com });
  };

  const propertyAlarmChange = (al: boolean) => {
    propertyChange && propertyChange({ alarm: al });
  };

  const propertyNeurologistChange = (neu: boolean) => {
    handleFormData && handleFormData('neurologist')(neu);
    propertyChange && propertyChange({ neurologist: neu });
  };

  const propertyOphthalmologistChange = (opt: boolean) => {
    handleFormData && handleFormData('ophthalmologist')(opt);
    propertyChange && propertyChange({ ophthalmologist: opt });
  };

  const propertyLaryngologistChange = (lar: boolean) => {
    handleFormData && handleFormData('laryngologist')(lar);
    propertyChange && propertyChange({ laryngologist: lar });
  };

  const propertyOccupMedicineTextChange = (occupMedText: string) => {
    handleFormData && handleFormData('occupMedicineText')(occupMedText);
    propertyChange &&
      propertyChange({ occupationalMedicineText: occupMedText });
  };

  const propertyHealthcareFacilityIdChange = (healthcareFacilityId: string) => {
    handleFormData &&
      handleFormData('healthcareFacilityId')(healthcareFacilityId);
    propertyChange &&
      propertyChange({ healthcareFacilityId: healthcareFacilityId });
  };

  const onSelectDrivingCategoriesChange = (keys: string[]) => {
    setSelectedDrivingCategories(keys);
    handleFormData && handleFormData('drivingLicense')(keys.join(','));
    propertyChange && propertyChange({ drivingLicense: keys.join(',') });
  };

  const propertyChangeWithHandleFormData = (
    field: string,
    checked: boolean | string | Date,
  ) => {
    handleFormData && handleFormData(field)(checked);
    propertyChange && propertyChange({ [field]: checked });
  };

  const getAllDrivingCategories = () => {
    const arr = Object.keys(DrivingLicenseCategory).map(name => {
      return {
        value: name,
        label:
          DrivingLicenseCategory[name as keyof typeof DrivingLicenseCategory],
      };
    });
    return arr;
  };

  const getSelectedDrivingCategories = () => {
    return getAllDrivingCategories().filter(item =>
      selectedDrivingCategories?.includes(item.value),
    );
  };

  const onSelectOccupMedCategories = (keys: string[]) => {
    setSelectedOccupMedicineCategory(keys);
    handleFormData && handleFormData('occupMedicineCategory')(keys.join(','));
    propertyChange && propertyChange({ occupationalMedicineCategory: keys });
  };

  const getAllOccupMedCategories = () => {
    const arr = Object.keys(OccupationalMedicineCategory).map(name => {
      return {
        value: name,
        label:
          OccupationalMedicineCategory[
            name as keyof typeof OccupationalMedicineCategory
          ],
      };
    });
    return arr;
  };

  const getSelectedOccupMedCategories = () => {
    return getAllOccupMedCategories().filter(item =>
      selectedOccupMedicineCategory?.includes(item.value),
    );
  };

  return (
    <>
      <Card>
        <Card.Body>
          <Card.Subtitle className="mb-4">Dane ogólne</Card.Subtitle>

          <Form className="d-grid gap-3">
            {!nextStep && (
              <>
                <FormRow
                  controlId="exam_completed"
                  label="Wyłączenie prośby o pomoc"
                >
                  <Form.Check
                    type="checkbox"
                    checked={alarm ? true : false}
                    onChange={e => propertyAlarmChange(e.target.checked)}
                  />
                </FormRow>
                <FormRow
                  controlId="drivingCategories"
                  label="Dotyczy kategorii prawa jazdy"
                >
                  <Select
                    options={getAllDrivingCategories()}
                    value={getSelectedDrivingCategories()}
                    isMulti
                    className="test-multi-select"
                    classNamePrefix="driving-select"
                    onChange={options =>
                      onSelectDrivingCategoriesChange(options.map(o => o.value))
                    }
                  />
                </FormRow>
                <FormRow
                  controlId="occupMedCategories"
                  label="Dotyczy medycyny pracy"
                >
                  <Select
                    options={getAllOccupMedCategories()}
                    value={getSelectedOccupMedCategories()}
                    isMulti
                    className="test-multi-select"
                    classNamePrefix="driving-select"
                    onChange={options =>
                      onSelectOccupMedCategories(options.map(o => o.value))
                    }
                  />
                </FormRow>
                <FormRow controlId="occupMedText" label="Medycyna pracy - inne">
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={occupMedicineText || ''}
                    onChange={e =>
                      propertyOccupMedicineTextChange(e.target.value)
                    }
                  />
                </FormRow>
              </>
            )}
            <FormRow controlId="healthcareFacility" label="Placówka">
              <HealthcareFacilitySelector
                value={healthcareFacilityId || ''}
                onChange={option => {
                  propertyHealthcareFacilityIdChange(option.value);
                }}
              />
            </FormRow>
            <FormRow controlId="exam_description" label="Opis badania">
              <Form.Control
                as="textarea"
                rows={3}
                value={description || ''}
                onChange={e => propertyDescriptionChange(e.target.value)}
              />
            </FormRow>
            <FormRow controlId="healhcare" label="Medyk">
              <Form.Control
                as={Form.Select}
                value={healthcare || ''}
                onChange={e =>
                  propertyChangeWithHandleFormData('healthcare', e.target.value)
                }
              >
                <option value=""></option>
                <option value="Doctor">Lekarz/Lekarka</option>
                <option value="Dentist">Stomatolog</option>
                <option value="Midwife">Położna</option>
                <option value="Nurse">Pielęgniarz/Pielęgniarka</option>
              </Form.Control>
            </FormRow>
            <FormRow controlId="exam_description" label="Wymagany specjalista">
              <Form.Check
                label="Neurolog"
                type="checkbox"
                checked={neurologist ? true : false}
                onChange={e => propertyNeurologistChange(e.target.checked)}
              />
              <Form.Check
                label="Okulista"
                type="checkbox"
                checked={ophthalmologist ? true : false}
                onChange={e => propertyOphthalmologistChange(e.target.checked)}
              />
              <Form.Check
                label="Laryngolog"
                type="checkbox"
                checked={laryngologist ? true : false}
                onChange={e => propertyLaryngologistChange(e.target.checked)}
              />
            </FormRow>
            {!prevStep && (
              <FormRow
                controlId="exam_completed"
                label="Wystawiono orzeczenia poza systemem"
              >
                <Form.Check
                  type="checkbox"
                  checked={completed ? true : false}
                  onChange={e => propertyCompletedChange(e.target.checked)}
                />
              </FormRow>
            )}

            {prevStep && nextStep && (
              <div className="d-flex justify-content-end m-3">
                <Button
                  variant="outline-secondary"
                  className="ms-1"
                  onClick={handleOnPrev}
                >
                  Wstecz
                </Button>

                <Button
                  variant="outline-primary"
                  className="ms-1"
                  onClick={handleOnNext}
                >
                  Dalej
                </Button>
              </div>
            )}
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};
