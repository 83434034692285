import React from 'react';

import { useAuth } from '../../../common/hooks/useAuth';
import { useSpdCore } from '../../../common/hooks/useSpdCore';
import { ChangeEmail } from '../../components/ChangeEmail/ChangeEmail';

export const ChangeEmailView: React.FC = () => {
  const spdApi = useSpdCore();
  const auth = useAuth();

  const onChangeEmail = async (email: string) => {
    await spdApi.signIn.updateEmail(email).then(async () => {
      await spdApi.users.updateEmail(auth.userProfile?.id, email).then(() => {
        auth.updateEmailCurrentUser();
      });
    });
  };
  return (
    <ChangeEmail
      currentEmail={auth.currentUser?.email}
      onChangeEmail={onChangeEmail}
    />
  );
};
