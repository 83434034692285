import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

import { useSpdCore } from '../../../common/hooks/useSpdCore';

export const TestDownloadLink = (props: {
  testIdent: string | null | undefined;
}) => {
  const spd = useSpdCore();

  const handleClick = () => {
    if (!props.testIdent) {
      return;
    }
    spd.export
      .exportTest(props.testIdent)
      .catch(error =>
        console.log(`Wystąpił problem z pobraniem danych z testu. ${error}`),
      );
  };

  return (
    <>
      <OverlayTrigger overlay={<Tooltip>Pobierz</Tooltip>}>
        <Button
          onClick={e => {
            e.stopPropagation();
            handleClick();
          }}
          variant="btn-info"
        >
          <Icon.Download />
        </Button>
      </OverlayTrigger>
    </>
  );
};
