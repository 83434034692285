import React, { useState, useEffect } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

import { useNavigation } from '../../../common/navigation';
import {
  CardType,
  RegisterMode,
} from '../../../common/spdCore/validation/schemas';
import { useSpdCore } from '../../../common/hooks/useSpdCore';

export const CardWithJudgmentLink: React.FC<{
  examId: string;
  active: boolean;
  registerMode: RegisterMode;
}> = props => {
  const nav = useNavigation();
  const spd = useSpdCore();
  const [cardId, setCardId] = useState<string>('');
  const [labelEdit, setLabelEdit] = useState<string>('Karta badania');
  const [labelPrint, setLabelPrint] = useState<string>('Wydruk karty badania');

  useEffect(() => {
    let cardTp: CardType = CardType.Psychologist;
    switch (props.registerMode) {
      case RegisterMode.BR:
        cardTp = CardType.PsychologistWeapon;
        setLabelEdit('Karta psychologiczna na broń');
        setLabelPrint('Wydruk karty psychologicznej na broń');
        break;
      case RegisterMode.OK:
      case RegisterMode.KMP:
        cardTp = CardType.Psychologist;
        setLabelEdit('Karta psychologiczna');
        setLabelPrint('Wydruk karty psychologicznej');
        break;
      case RegisterMode.K75:
        cardTp = CardType.Driver;
        setLabelEdit('Karta kierowcy');
        setLabelPrint('Wydruk karty kierowcy');
        break;
      case RegisterMode.K43:
      case RegisterMode.KU:
        cardTp = CardType.Preventive;
        setLabelEdit('Karta profilaktyczna');
        setLabelPrint('Wydruk karty profilaktycznej');
        break;
      case RegisterMode.KS:
        cardTp = CardType.Sanitary;
        setLabelEdit('Karta sanitarno-epidemiologiczna');
        setLabelPrint('Wydruk karty sanitarno-epidemiologicznej');
        break;
      case RegisterMode.LBR:
        cardTp = CardType.Weapon;
        setLabelEdit('Karta broń');
        setLabelPrint('Wydruk karty na broń');
        break;
    }
    if (props.examId) {
      spd.cards
        .getCardByExam(props.examId, cardTp)
        .then(card => {
          setCardId(card.id);
        })
        .catch(error => {
          console.log(`Wystąpił problem z pobraniem karty. ${error}`);
        });
    }
  }, [spd.cards, props.registerMode, props.examId]);

  const handleOnClickEdit = (e: React.MouseEvent) => {
    switch (props.registerMode) {
      case RegisterMode.OK:
      case RegisterMode.KMP:
      case RegisterMode.BR:
        e.stopPropagation();
        nav.examCardsPsychologic(props.examId);
        break;
      case RegisterMode.K75:
        e.stopPropagation();
        nav.examCardsDriver(props.examId);
        break;
      case RegisterMode.K43:
      case RegisterMode.KU:
        e.stopPropagation();
        nav.examCardsPreventive(props.examId);
        break;
      case RegisterMode.KS:
        e.stopPropagation();
        nav.examCardsSanitary(props.examId);
        break;
      case RegisterMode.LBR:
        e.stopPropagation();
        nav.examCardsWeapon(props.examId);
        break;
    }
  };

  const handleClickPrint = () => {
    spd.pdfPrinter
      .loadAndPrintCard(cardId)
      .catch(error =>
        console.log(`Wystąpił problem z wydrukiem karty. ${error}`),
      );
  };

  return (
    <>
      <OverlayTrigger
        overlay={<Tooltip style={{ position: 'fixed' }}>{labelEdit}</Tooltip>}
      >
        <Button onClick={handleOnClickEdit} variant="btn-info">
          <Icon.CardChecklist />
        </Button>
      </OverlayTrigger>
      {cardId && props.active && (
        <OverlayTrigger
          overlay={
            <Tooltip style={{ position: 'fixed' }}>{labelPrint}</Tooltip>
          }
        >
          <Button
            onClick={e => {
              e.stopPropagation();
              handleClickPrint();
            }}
            variant="btn-info"
          >
            <Icon.Printer />
          </Button>
        </OverlayTrigger>
      )}
    </>
  );
};
