import React, { useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import * as XLSX from 'xlsx';

import ValidationAlert from '../../../app/components/ValidationAlert';
import LoadingSpinner from '../../../common/components/Waiting/LoadingSpinner';
import { responseErrors } from '../../../common/spdCore/validation/responseErrors';

import { KeyXls, validateKeys } from './ImportKeyValuesValidator';

interface ImportKeyValuesProps {
  show: boolean;
  handleClose: () => void;
  onImport: (keys: KeyXls[]) => Promise<{
    saved: boolean;
    error: string;
  }>;
}

export const ImportKeyValues: React.FC<ImportKeyValuesProps> = props => {
  const [showSaveErrors, setShowSaveErrors] = useState(false);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  const clearError = () => {
    setSaveErrors(['']);
    setShowSaveErrors(false);
  };

  const handleClose = () => {
    clearError();
    props.handleClose();
  };

  const setFile = (input: HTMLInputElement) => {
    setLoading(true);
    setShowSaveErrors(false);
    const fileList = input.files;

    if (!fileList) {
      setLoading(false);
      return;
    }
    const file = fileList[0];
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);
    fileReader.onload = async e => {
      const target = e.target;
      try {
        const data = target?.result;
        const workbook = XLSX.read(data);
        const ws = workbook.Sheets[workbook.SheetNames[0]];
        const keysJson = XLSX.utils.sheet_to_json(ws, {
          header: [
            'nazwa_klucza',
            'identyfikator_testu',
            'nr_zadania',
            'prawidlowa_odpowiedz',
            'waga',
          ],
        });
        const keys = keysJson as unknown as KeyXls[];
        const result = validateKeys(keys);
        if (!result.ok) {
          setSaveErrors(result.errors);
          setShowSaveErrors(true);
          setLoading(false);
          return;
        }
        props
          .onImport(keys)
          .then(status => {
            setLoading(false);
            if (status.saved) {
              clearError();
              return;
            }
          })
          .catch(async response => {
            const errors = await responseErrors(response as Response);
            setShowSaveErrors(true);
            setSaveErrors([
              'Błąd przy imporcie kluczy [' + errors.join(';') + ']',
            ]);
            setLoading(false);
          });
      } catch (response) {
        const errors = await responseErrors(response as Response);
        setShowSaveErrors(true);
        setSaveErrors(['Błąd przy imporcie kluczy [' + errors.join(';') + ']']);
        setLoading(false);
      }
    };
    input.value = '';
  };

  return (
    <>
      <Modal
        onHide={handleClose}
        onClose={handleClose}
        show={props.show}
        centered={true}
        keyboard={true}
        backdrop="static"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Import klucza</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>Wczytaj klucze z pliku Excel</Form.Label>
            <Form.Control
              type="file"
              accept=".xlsx, .xls"
              onChange={e => setFile(e.target as HTMLInputElement)}
              onClick={() => clearError()}
            />
            {loading && (
              <Form.Label className="mt-3">
                <LoadingSpinner />
                Trwa import kluczy...
              </Form.Label>
            )}
          </Form.Group>
          <ValidationAlert
            show={showSaveErrors}
            errors={saveErrors}
            className="mt-3"
          />
        </Modal.Body>
      </Modal>
    </>
  );
};
