import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

import { useSpdCore } from '../../../common/hooks/useSpdCore';

export const ExamTestPreviewLink = (props: {
  examId: string;
  testId: string;
}) => {
  const spd = useSpdCore();

  const handleClick = () => {
    spd.pdfPrinter
      .printAnswers(props.testId, props.examId)
      .catch(error =>
        console.log(`Wystąpił problem z wydrukiem wyników badania. ${error}`),
      );
  };

  return (
    <>
      <OverlayTrigger overlay={<Tooltip>Wydruk badania</Tooltip>}>
        <Button
          onClick={e => {
            e.stopPropagation();
            handleClick();
          }}
          variant="btn-info"
        >
          <Icon.Printer />
        </Button>
      </OverlayTrigger>
    </>
  );
};
