import React, { useState } from 'react';
import { Button, Card, Tab } from 'react-bootstrap';

import Tabs from '../../../app/components/Tabs';
import { useSpdCore } from '../../../common/hooks/useSpdCore';
import {
  CreateUpdateTestDto,
  GetTestDto,
} from '../../../common/spdCore/autogenerated/spdApiClient';
import {
  testData,
  validate,
} from '../../../common/spdCore/contexts/TestContext';
import { responseErrors } from '../../../common/spdCore/validation/responseErrors';
import { TestEditor } from '../../components/Tests/TestEditor';
import { Test } from '../../components/Tests/Tests';

export const TestView: React.FC = () => {
  const spd = useSpdCore();

  const [show, setShow] = useState(false);
  const [test, setTest] = useState<GetTestDto>();

  const [refresh, setRefresh] = useState<boolean>(false);

  const handleAdd = () => {
    setShow(true);
    setTest(undefined);
  };

  const addEditTest = async (testCU: CreateUpdateTestDto, id?: string) => {
    const status = await validate(testCU);
    //const status = await validate(testCU, id);
    if (status.valid) {
      try {
        if (id) {
          await spd.tests.update(id, testCU);
        } else {
          await spd.tests.create(testCU);
        }
        setShow(false);
        setRefresh(!refresh);
        return {
          saved: true,
          errors: [],
        };
      } catch (response) {
        return {
          saved: false,
          errors: await responseErrors(response as Response),
        };
      }
    } else {
      return {
        saved: false,
        errors: status.errors,
      };
    }
  };

  const handleClose = () => {
    setShow(false);
    setRefresh(!refresh);
  };

  const handleClick = async (id: string) => {
    const getTest = await spd.tests.getTest(id);
    setTest(getTest);
    setShow(true);
  };

  return (
    <Card>
      <Tabs defaultActiveKey="search" id="tests" className="mb-3">
        <Tab eventKey="search" title="Testy" className="m-3">
          <Test
            getData={spd.tests.getAllByPortion}
            getCount={spd.tests.getAllCount}
            onRowClick={handleClick}
            refresh={refresh}
          />
          <Button variant="outline-primary" onClick={handleAdd}>
            Nowy test
          </Button>
          <TestEditor
            id={test?.id}
            test={testData(test)}
            onCompleted={addEditTest}
            show={show}
            handleClose={handleClose}
          />
        </Tab>
      </Tabs>
    </Card>
  );
};
