import React from 'react';

import { Grid } from '../../../common/components/Grid/Grid';
import momencik from '../../../common/momencik';
import { GetSavedPdfExternalReferralDto } from '../../../common/spdCore/autogenerated/spdApiClient';

import { SavedPdfPreviewLink } from './SavedPdfPreviewLink';

interface SavedPdfProps {
  data?: GetSavedPdfExternalReferralDto[];
  onRowClick?: (key: string) => Promise<void> | void | undefined;
  refresh?: boolean;
  enableMultiSelect?: boolean;
  selectedKeys?: string[];
}

export const savedPdfForGrid = (savedPdf: GetSavedPdfExternalReferralDto) => {
  return {
    key: savedPdf.id,
    values: {
      'creator.name': savedPdf?.creator?.name,
      'savedPdf.creationDate': momencik(savedPdf.creationDate),
      'pdfTemplate.name': savedPdf?.pdfTemplate?.name,
      'exam.number': savedPdf?.examNumber,
      preview: (
        <SavedPdfPreviewLink
          savedPdfId={savedPdf.id}
          savedPdfName={savedPdf.examNumber || ''}
        />
      ),
    },
  };
};

export const SavedPdf: React.FC<SavedPdfProps> = props => {
  const columns = [
    { header: 'Badanie', property: 'exam.number' },
    { header: 'Data', property: 'savedPdf.creationDate' },
    { header: 'Autor', property: 'creator.name' },
    { header: 'Typ', property: 'pdfTemplate.name' },
    { header: '', property: 'preview', noSorting: true },
  ];

  return (
    <Grid
      data={props.data && props.data.map(d => savedPdfForGrid(d))}
      columns={columns}
      showFilter={true}
      initialOrderColumn="savedPdf.creationDate"
      initialOrderDirection="DESC"
      availablePageSizes={[10, 20, 50]}
      onRowClick={props.onRowClick}
      emptyGridMessage="Brak skierowań wygenerowanych przez użytkowników zewnętrznych w systemie"
      refresh={props.refresh}
      selectedKeys={props.selectedKeys}
    />
  );
};
