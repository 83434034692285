import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card';
import { Form } from 'react-bootstrap';

import ValidationAlert from '../../../app/components/ValidationAlert';
import FormRow from '../../../app/components/FormRow';
import { CreateUpdateTestDto } from '../../../common/spdCore/autogenerated/spdApiClient';

interface TestEditorProps {
  id?: string;
  test: CreateUpdateTestDto;
  handleClose?: () => void;
  show?: boolean;
  onCompleted: (
    examined: CreateUpdateTestDto,
    id?: string,
  ) => Promise<{
    saved: boolean;
    errors: string[];
  }>;
}

export const TestEditor: React.FC<TestEditorProps> = props => {
  const [test, setTest] = useState(props.test);
  const [showSaveErrors, setShowSaveErrors] = useState(false);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);

  useEffect(() => {
    if (props.show) {
      setSaveErrors([]);
      setShowSaveErrors(false);
      setTest(props.test);
    }
  }, [props.show, props.test]);

  const createTest = async () => {
    props
      .onCompleted(test, props.id)
      .then(saveStatus => {
        if (!saveStatus.saved) {
          setSaveErrors(saveStatus.errors);
          setShowSaveErrors(true);
          return;
        }
      })
      .catch(error => {
        console.error('Error during add test:', error);
      });
  };

  const propertyChange = (obj: Partial<CreateUpdateTestDto>) => {
    setTest({ ...test, ...obj });
  };

  return (
    <>
      <Modal
        onHide={props.handleClose}
        show={props.show}
        centered={true}
        keyboard={true}
        backdrop="static"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {props.id ? 'Edycja testu' : 'Dodawanie nowego testu'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card className="mb-2">
            <Card.Header>Test</Card.Header>
            <Card.Body>
              <Form className="d-grid gap-3">
                <FormRow controlId="test_name" label="Nazwa">
                  <Form.Control
                    type="text"
                    value={test?.name || ''}
                    onChange={e => propertyChange({ name: e.target.value })}
                  />
                </FormRow>
                <FormRow controlId="test_lp" label="Lp.">
                  <Form.Control
                    type="number"
                    value={test?.lp || 0}
                    onChange={e => propertyChange({ lp: +e.target.value })}
                  />
                </FormRow>
                <FormRow controlId="identifier" label="Identyfikator">
                  <Form.Control
                    type="text"
                    value={test?.identifier || ''}
                    onChange={e =>
                      propertyChange({ identifier: e.target.value })
                    }
                  />
                </FormRow>
                <FormRow controlId="test_name" label="Nazwa pliku">
                  <Form.Control
                    type="text"
                    value={test?.fileName || ''}
                    onChange={e => propertyChange({ fileName: e.target.value })}
                  />
                </FormRow>
                <FormRow controlId="test_description" label="Opis">
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={test?.description || ''}
                    onChange={e =>
                      propertyChange({ description: e.target.value })
                    }
                  />
                </FormRow>
              </Form>
            </Card.Body>
          </Card>
          <ValidationAlert
            show={showSaveErrors}
            errors={saveErrors}
            className="mt-3"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={props.handleClose}>
            Zamknij
          </Button>
          <Button variant="outline-primary" onClick={createTest}>
            Zapisz
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
