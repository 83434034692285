import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Card, Tab } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import Tabs from '../../../app/components/Tabs';
import { useSpdCore } from '../../../common/hooks/useSpdCore';
import {
  CreateUpdateAttachmentDto,
  GetAttachmentDto,
  GetExaminedDto,
  GetSavedPdfExternalReferralDto,
} from '../../../common/spdCore/autogenerated/spdApiClient';
import { Attachment } from '../../components/Attachment/Attachments';
import { AttachmentEditor } from '../../components/Attachment/AttachmentEditor';
import {
  attachmentData,
  validate,
} from '../../../common/spdCore/contexts/AttachmentContext';
import { SavedPdf } from '../../components/SavedPdf/SavedPdfs';

type ParamTypes = {
  examinedId: string;
};

export const AttachmentsView: React.FC = () => {
  const spd = useSpdCore();

  const { examinedId } = useParams<ParamTypes>() as {
    examinedId: string;
  };

  const [show, setShow] = useState(false);
  const [attachment, setAttachment] = useState<GetAttachmentDto>();
  const [examined, setExamined] = useState<GetExaminedDto>();

  const [attachments, setAttachments] = useState<GetAttachmentDto[]>([]);
  const [savedPdfs, setSavedPdfs] = useState<GetSavedPdfExternalReferralDto[]>(
    [],
  );

  const mountedRef = useRef(false);

  const refreshAttachments = useCallback(() => {
    if (!mountedRef.current) {
      return;
    }
    spd.attachments
      .getAllForExamined(examinedId)
      .then(att => setAttachments(att))
      .catch(error => {
        console.error('Wystąpił problem z pobraniem załączników:', error);
      });

    spd.savedPdfs
      .getExternalReferralForExamined(examinedId)
      .then(spdf => setSavedPdfs(spdf))
      .catch(error => {
        console.error('Wystąpił problem z pobraniem skierowań:', error);
      });

    spd.examineds
      .getExamined(examinedId)
      .then(ex => setExamined(ex))
      .catch(error => {
        console.error('Wystąpił problem z pobraniem badanego:', error);
      });
  }, [spd.attachments, examinedId, spd.examineds, spd.savedPdfs]);

  useEffect(() => {
    mountedRef.current = true;
    refreshAttachments();
    return () => {
      mountedRef.current = false;
    };
  }, [refreshAttachments]);

  const handleAdd = () => {
    setShow(true);
    setAttachment(undefined);
  };

  const addEditAttachment = async (
    attachmentCu: CreateUpdateAttachmentDto,
    id?: string,
  ) => {
    const status = await validate(attachmentCu);
    if (status.valid) {
      if (id) {
        await spd.attachments.update(id, attachmentCu);
      } else {
        await spd.attachments.create(attachmentCu);
      }
      setShow(false);
      refreshAttachments();
      return {
        saved: true,
        errors: [],
      };
    } else {
      return {
        saved: false,
        errors: status.errors,
      };
    }
  };

  const handleClose = () => {
    setShow(false);
    refreshAttachments();
  };

  const handleClick = async (id: string) => {
    const getAttachment = await spd.attachments.getById(id);
    setAttachment(getAttachment);
    setShow(true);
  };

  return (
    <Card>
      <Tabs defaultActiveKey="attachments" id="attachments" className="mb-3">
        <Tab
          eventKey="attachments"
          title={`Załączniki do badanego: ${examined?.firstname} ${examined?.surname}`}
          className="m-3"
        >
          <Attachment data={attachments} onRowClick={handleClick} />
          <Button variant="outline-primary" onClick={handleAdd}>
            Nowy załącznik
          </Button>
          <AttachmentEditor
            id={attachment?.id}
            attachment={attachmentData(attachment, examinedId)}
            onCompleted={addEditAttachment}
            show={show}
            handleClose={handleClose}
          />
        </Tab>
        <Tab
          eventKey="others"
          title={`Skierowania wygenerowane przez użytkowników zewnętrznych`}
          className="m-3"
        >
          <SavedPdf data={savedPdfs} onRowClick={handleClick} />
        </Tab>
      </Tabs>
    </Card>
  );
};
