import React, { useEffect } from 'react';
import { Card, Tab } from 'react-bootstrap';

import { useCookies } from '../../../common/hooks/useCookies';
import { useSpdCore } from '../../../common/hooks/useSpdCore';
import { useNavigation } from '../../../common/navigation';
import { ExamSearch } from '../../components/Exam/ExamSearch';
import { TabsHistory } from '../../../app/components/TabsHistory';
import { ArticleMode } from '../../../common/spdCore/validation/schemas';

import { ExamsActiveView } from './ExamsActiveView';

export const ExamsPsychologistView: React.FC = () => {
  const spd = useSpdCore();
  const nav = useNavigation();
  const cookies = useCookies();

  useEffect(() => {
    if (cookies.activeMonitor !== 'psychologist') {
      cookies.setActiveMonitor('psychologist');
    }
  }, [cookies]);

  const handleClick = async (id: string) => {
    nav.examTestsPsychologist(id);
  };

  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
  const handleSelect = (key: any) => {
    cookies.setActiveTabMonitor(key as string);
  };

  return (
    <Card>
      <TabsHistory
        activeKey={
          cookies.activeTabMonitor ? cookies.activeTabMonitor : 'activeExams'
        }
        id="exams"
        className="mb-3"
        onAdditionalSelect={handleSelect}
      >
        <Tab
          eventKey="withoutJudgmentExams"
          title="Badania psychologa z orzeczeniami do wydania"
          className="m-3"
        >
          <ExamSearch
            getData={(
              pageSize,
              pageNumber,
              orderBy,
              orderDirection,
              filterText,
            ) =>
              spd.exams.getWithoutJudgmentByPortion(
                pageSize,
                pageNumber,
                orderBy,
                orderDirection,
                filterText,
                ArticleMode.Psychologist,
              )
            }
            getCount={filterText =>
              spd.exams.getWithoutJudgmentCount(
                filterText,
                ArticleMode.Psychologist,
              )
            }
            onRowClick={handleClick}
          />
        </Tab>
        <Tab eventKey="activeExams" title="aktywne" className="m-3">
          <ExamsActiveView
            getData={(
              pageSize,
              pageNumber,
              orderBy,
              orderDirection,
              filterText,
            ) =>
              spd.exams.getActiveByPortion(
                pageSize,
                pageNumber,
                orderBy,
                orderDirection,
                filterText,
                ArticleMode.Psychologist,
              )
            }
            getCount={filterText =>
              spd.exams.getActiveCount(filterText, ArticleMode.Psychologist)
            }
            onRowClick={handleClick}
          />
        </Tab>

        <Tab eventKey="examsWithExamined" title="z badanym" className="m-3">
          <ExamSearch
            getData={(
              pageSize,
              pageNumber,
              orderBy,
              orderDirection,
              filterText,
            ) =>
              spd.exams.getWithExaminedByPortion(
                pageSize,
                pageNumber,
                orderBy,
                orderDirection,
                filterText,
                ArticleMode.Psychologist,
              )
            }
            getCount={filterText =>
              spd.exams.getWithExaminedCount(
                filterText,
                ArticleMode.Psychologist,
              )
            }
            onRowClick={handleClick}
          />
        </Tab>
        <Tab
          eventKey="exams"
          title="Wszystkie badania psychologa"
          className="m-3"
        >
          <ExamSearch
            getData={(
              pageSize,
              pageNumber,
              orderBy,
              orderDirection,
              filterText,
            ) =>
              spd.exams.getAllByPortion(
                pageSize,
                pageNumber,
                orderBy,
                orderDirection,
                filterText,
                ArticleMode.Psychologist,
              )
            }
            getCount={filterText =>
              spd.exams.getAllCount(filterText, ArticleMode.Psychologist)
            }
            onRowClick={handleClick}
          />
        </Tab>
      </TabsHistory>
    </Card>
  );
};
