import React from 'react';
import { Card, Tab } from 'react-bootstrap';

import Tabs from '../../../app/components/Tabs';
import { ConsultationsList } from '../../components/Consultations/ConsultationsList';

export const ConsultationsView: React.FC = () => {
  return (
    <Card>
      <Tabs defaultActiveKey="search" id="consultations" className="mb-3">
        <Tab eventKey="search" title="Konsultacje" className="m-3">
          <ConsultationsList />
        </Tab>
      </Tabs>
    </Card>
  );
};
