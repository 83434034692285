/* tslint:disable */
/* eslint-disable */
/**
 * SPD
 * Opis api SPD
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUpdateOtherPrintDto
 */
export interface CreateUpdateOtherPrintDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateOtherPrintDto
     */
    examId?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof CreateUpdateOtherPrintDto
     */
    date?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateOtherPrintDto
     */
    otherPrintDefId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateOtherPrintDto
     */
    userUID?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateOtherPrintDto
     */
    comments?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateOtherPrintDto
     */
    objective?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateOtherPrintDto
     */
    directsTo?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateOtherPrintDto
     */
    active?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateOtherPrintDto
     */
    withoutSign?: boolean | null;
}

export function CreateUpdateOtherPrintDtoFromJSON(json: any): CreateUpdateOtherPrintDto {
    return CreateUpdateOtherPrintDtoFromJSONTyped(json, false);
}

export function CreateUpdateOtherPrintDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateOtherPrintDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'examId': !exists(json, 'examId') ? undefined : json['examId'],
        'date': !exists(json, 'date') ? undefined : (json['date'] === null ? null : new Date(json['date'])),
        'otherPrintDefId': !exists(json, 'otherPrintDefId') ? undefined : json['otherPrintDefId'],
        'userUID': !exists(json, 'userUID') ? undefined : json['userUID'],
        'comments': !exists(json, 'comments') ? undefined : json['comments'],
        'objective': !exists(json, 'objective') ? undefined : json['objective'],
        'directsTo': !exists(json, 'directsTo') ? undefined : json['directsTo'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'withoutSign': !exists(json, 'withoutSign') ? undefined : json['withoutSign'],
    };
}

export function CreateUpdateOtherPrintDtoToJSON(value?: CreateUpdateOtherPrintDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'examId': value.examId,
        'date': value.date === undefined ? undefined : (value.date === null ? null : value.date.toISOString()),
        'otherPrintDefId': value.otherPrintDefId,
        'userUID': value.userUID,
        'comments': value.comments,
        'objective': value.objective,
        'directsTo': value.directsTo,
        'active': value.active,
        'withoutSign': value.withoutSign,
    };
}


