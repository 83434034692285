import React from 'react';

import { Grid } from '../../../common/components/Grid/Grid';
import {
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import { GetReportConsultationDto } from '../../../common/spdCore/autogenerated/spdApiClient/models/GetReportConsultationDto';
import { ConsultationTypeText } from '../../../common/spdCore/validation/schemas';

interface ReportConsultationsSearchProps {
  getData: GridGetterFunction;
  getCount: GridCountFunction;
  onRowClick?: (key: string) => Promise<void> | void | undefined;
  refresh?: boolean;
  enableSingleSelect?: boolean;
  selectedKeys?: string[];
  withHealthcareFacility?: boolean;
  withType?: boolean;
}

export const reportConsultationForGrid = (
  reportConsultation: GetReportConsultationDto,
) => {
  return {
    key: reportConsultation.id,
    values: {
      user_name: reportConsultation.userName,
      consultation_type: reportConsultation.type
        ? ConsultationTypeText[reportConsultation.type]
        : '',
      healthcare_facility_name: reportConsultation.healthcareFacilityName,
      consultationCount: reportConsultation.countConsultations,
    },
  };
};

export const ReportConsultationsSearch: React.FC<
  ReportConsultationsSearchProps
> = props => {
  const columns = [
    { header: 'Lekarz/psycholog', property: 'user_name' },
    ...(props.withType
      ? [{ header: 'Typ', property: 'consultation_type' }]
      : []),
    ...(props.withHealthcareFacility
      ? [{ header: 'Placówka', property: 'healthcare_facility_name' }]
      : []),

    {
      header: 'Liczba konsultacji',
      property: 'consultationCount',
      noSorting: true,
    },
  ];

  return (
    <Grid
      getData={props.getData}
      getDataCount={props.getCount}
      columns={columns}
      showFilter={true}
      initialOrderColumn="user_name, consultation_type, healthcare_facility_name"
      initialOrderDirection="ASC"
      availablePageSizes={[10, 20, 50]}
      pageSize={20}
      onRowClick={props.onRowClick}
      emptyGridMessage="Brak konsultacji w systemie"
      refresh={props.refresh}
      enableSingleSelect={props.enableSingleSelect}
      selectedKeys={props.selectedKeys}
    />
  );
};
