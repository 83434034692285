/* tslint:disable */
/* eslint-disable */
/**
 * SPD
 * Opis api SPD
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUpdateJudgmentNumberingDto
 */
export interface CreateUpdateJudgmentNumberingDto {
    /**
     * 
     * @type {number}
     * @memberof CreateUpdateJudgmentNumberingDto
     */
    year?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateUpdateJudgmentNumberingDto
     */
    actualNumber?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateUpdateJudgmentNumberingDto
     */
    month?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateJudgmentNumberingDto
     */
    registerId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateJudgmentNumberingDto
     */
    userUID?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateJudgmentNumberingDto
     */
    symbol?: string | null;
}

export function CreateUpdateJudgmentNumberingDtoFromJSON(json: any): CreateUpdateJudgmentNumberingDto {
    return CreateUpdateJudgmentNumberingDtoFromJSONTyped(json, false);
}

export function CreateUpdateJudgmentNumberingDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateJudgmentNumberingDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'year': !exists(json, 'year') ? undefined : json['year'],
        'actualNumber': !exists(json, 'actualNumber') ? undefined : json['actualNumber'],
        'month': !exists(json, 'month') ? undefined : json['month'],
        'registerId': !exists(json, 'registerId') ? undefined : json['registerId'],
        'userUID': !exists(json, 'userUID') ? undefined : json['userUID'],
        'symbol': !exists(json, 'symbol') ? undefined : json['symbol'],
    };
}

export function CreateUpdateJudgmentNumberingDtoToJSON(value?: CreateUpdateJudgmentNumberingDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'year': value.year,
        'actualNumber': value.actualNumber,
        'month': value.month,
        'registerId': value.registerId,
        'userUID': value.userUID,
        'symbol': value.symbol,
    };
}


