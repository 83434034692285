/* tslint:disable */
/* eslint-disable */
/**
 * SPD
 * Opis api SPD
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUpdateImportedIndicatorTestDto
 */
export interface CreateUpdateImportedIndicatorTestDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateImportedIndicatorTestDto
     */
    testIdentifier?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateImportedIndicatorTestDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateImportedIndicatorTestDto
     */
    mode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateImportedIndicatorTestDto
     */
    keyTestName?: string | null;
}

export function CreateUpdateImportedIndicatorTestDtoFromJSON(json: any): CreateUpdateImportedIndicatorTestDto {
    return CreateUpdateImportedIndicatorTestDtoFromJSONTyped(json, false);
}

export function CreateUpdateImportedIndicatorTestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateImportedIndicatorTestDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'testIdentifier': !exists(json, 'testIdentifier') ? undefined : json['testIdentifier'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'mode': !exists(json, 'mode') ? undefined : json['mode'],
        'keyTestName': !exists(json, 'keyTestName') ? undefined : json['keyTestName'],
    };
}

export function CreateUpdateImportedIndicatorTestDtoToJSON(value?: CreateUpdateImportedIndicatorTestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'testIdentifier': value.testIdentifier,
        'name': value.name,
        'mode': value.mode,
        'keyTestName': value.keyTestName,
    };
}


