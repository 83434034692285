/*eslint max-lines-per-function: ["error", 200]*/
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card';
import { Form } from 'react-bootstrap';

import ValidationAlert from '../../../app/components/ValidationAlert';
import FormRow from '../../../app/components/FormRow';
import {
  CreateUpdateArticleDto,
  CreateUpdateArticleDtoModeEnum,
  GetPdfTemplateDto,
  GetRegisterDto,
} from '../../../common/spdCore/autogenerated/spdApiClient';
import ConfirmationButton from '../../../app/components/ConfirmationButton';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { useSpdCore } from '../../../common/hooks/useSpdCore';

interface ArticleEditorProps {
  id?: string;
  article: CreateUpdateArticleDto;
  handleClose?: () => void;
  show?: boolean;
  pdfTemplates: GetPdfTemplateDto[];
  registers: GetRegisterDto[];
  onCompleted: (
    article: CreateUpdateArticleDto,
    id?: string,
  ) => Promise<{
    saved: boolean;
    errors: string[];
  }>;
}

export const ArticleEditor: React.FC<ArticleEditorProps> = props => {
  const [article, setArticle] = useState(props.article);
  const [showSaveErrors, setShowSaveErrors] = useState(false);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const notifications = useNotifications();
  const spd = useSpdCore();

  useEffect(() => {
    if (props.show) {
      setSaveErrors([]);
      setShowSaveErrors(false);
      setArticle(props.article);
    }
  }, [props.show, props.article]);

  const createUpdateArticle = async () => {
    props
      .onCompleted(article, props.id)
      .then(saveStatus => {
        if (!saveStatus.saved) {
          setSaveErrors(saveStatus.errors);
          setShowSaveErrors(true);
          return;
        }
      })
      .catch(error => {
        console.error('Error during add article:', error);
      });
  };

  const deleteArticle = async () => {
    if (props.id) {
      notifications.onPromise(
        spd.articles.changeToInactive(props.id),
        props.handleClose,
      );
    }
  };

  const getAllTemplateTypes = () => {
    const arr = props.pdfTemplates.map(pdf => {
      return {
        value: pdf.id,
        label: pdf.name,
      };
    });
    return arr;
  };

  const getSelectedTemplateTypes = () => {
    return getAllTemplateTypes().filter(
      item => item.value === props.article.pdfTemplateId,
    );
  };

  const getAllRegisters = () => {
    const arr = props.registers.map(reg => {
      return {
        value: reg.id,
        label: reg.name,
      };
    });
    return arr;
  };

  const getSelectedRegister = () => {
    return getAllRegisters().filter(
      item => item.value === props.article.registerId,
    );
  };

  const propertyChange = (obj: Partial<CreateUpdateArticleDto>) => {
    setArticle({ ...article, ...obj });
  };

  return (
    <Modal
      onHide={props.handleClose}
      show={props.show}
      centered={true}
      keyboard={true}
      backdrop="static"
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {props.id ? 'Edycja artykułu' : 'Dodawanie nowego artykułu'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Card className="mb-2">
          <Card.Header>Artykuł</Card.Header>
          <Card.Body>
            <Form className="d-grid gap-3">
              <FormRow controlId="article_lp" label="Lp">
                <Form.Control
                  type="number"
                  value={article?.lp || 0}
                  onChange={e => propertyChange({ lp: +e.target.value })}
                />
              </FormRow>
              <FormRow controlId="article_description" label="Opis">
                <Form.Control
                  as="textarea"
                  rows={2}
                  value={article?.description || ''}
                  onChange={e =>
                    propertyChange({ description: e.target.value })
                  }
                />
              </FormRow>
              <FormRow controlId="article_legalBasis" label="Podstawa prawna">
                <Form.Control
                  as="textarea"
                  rows={2}
                  value={article?.legalBasis || ''}
                  onChange={e => propertyChange({ legalBasis: e.target.value })}
                />
              </FormRow>
              <FormRow controlId="article_reason" label="Powód">
                <Form.Control
                  as="textarea"
                  rows={2}
                  value={article?.reason || ''}
                  onChange={e => propertyChange({ reason: e.target.value })}
                />
              </FormRow>
              <FormRow controlId="mode" label="Obsługiwany przez">
                <Form.Control
                  as={Form.Select}
                  value={article?.mode || ''}
                  onChange={e =>
                    propertyChange({
                      mode: e.target.value as CreateUpdateArticleDtoModeEnum,
                    })
                  }
                >
                  <option value="Doctor">Lekarz</option>
                  <option value="Psychologist">Psycholog</option>
                </Form.Control>
              </FormRow>
              <FormRow controlId="template" label="Szablon wydruku">
                <Select
                  options={getAllTemplateTypes()}
                  defaultValue={getSelectedTemplateTypes()}
                  onChange={options =>
                    propertyChange({ pdfTemplateId: options?.value })
                  }
                />
              </FormRow>
              <FormRow controlId="register" label="Rejestr">
                <Select
                  options={getAllRegisters()}
                  defaultValue={getSelectedRegister()}
                  onChange={options =>
                    propertyChange({ registerId: options?.value })
                  }
                />
              </FormRow>
            </Form>
          </Card.Body>
        </Card>
        <ValidationAlert
          show={showSaveErrors}
          errors={saveErrors}
          className="mt-3"
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={props.handleClose}>
          Zamknij
        </Button>
        {props.id && (
          <ConfirmationButton
            className="mx-1"
            confirmation="Czy na pewno usunąć artykuł?"
            variant="outline-danger"
            onOK={deleteArticle}
          >
            Usuń
          </ConfirmationButton>
        )}
        <Button variant="outline-primary" onClick={createUpdateArticle}>
          Zapisz
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
