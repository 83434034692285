import React, { useState } from 'react';

import { useSpdCore } from '../../../common/hooks/useSpdCore';

import { ReferralsSearch } from './ReferralsSearch';

interface ReferralsListProps {
  onRowClick?: (key: string) => Promise<void> | void | undefined;
  enableSingleSelect?: boolean;
}

export const ReferralsList: React.FC<ReferralsListProps> = ({
  onRowClick,
  enableSingleSelect,
}) => {
  const spd = useSpdCore();
  const [refresh, setRefresh] = useState<boolean>(false);

  const handleClick = async (id: string) => {
    await spd.consultations.get(id);
  };

  const onRefresh = () => {
    setRefresh(!refresh);
  };

  return (
    <>
      <ReferralsSearch
        getData={(pageSize, pageNumber, orderBy, orderDirection, filterText) =>
          spd.support.getWithPrintsByPortion(
            pageSize,
            pageNumber,
            orderBy,
            orderDirection,
            filterText,
            onRefresh,
          )
        }
        getCount={spd.support.getWithPrintsCount}
        onRowClick={onRowClick ? onRowClick : handleClick}
        enableSingleSelect={enableSingleSelect}
        refresh={refresh}
      />
    </>
  );
};
