import React, { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';

import { useSpdCore } from '../../../common/hooks/useSpdCore';
import { GetUserDto } from '../../../common/spdCore/autogenerated/spdApiClient';

import { Selector, Option } from './Selector';

export interface UserSelectorProps {
  readOnly?: boolean;
  roles: string[];
  value?: string;
  placeholder?: string;
  className?: string;
  onChange?: (option: Option) => void;
  clearable?: boolean;
}

export default (props: UserSelectorProps) => {
  const spd = useSpdCore();
  const [users, setUsers] = useState<GetUserDto[]>([]);
  const [displayName, setDisplayName] = useState<string>();
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (props.value) {
      spd.users
        .getByUserUID(props.value || '')
        .then(u => {
          setDisplayName(u?.name || '');
        })
        .catch(error =>
          console.log(`Wystąpił problem z pobraniem użytkowników. ${error}`),
        );
    } else {
      setDisplayName('');
    }
  }, [props.value, spd.users]);

  useEffect(() => {
    let mounted = true;
    spd.users
      .getAllByRoles(props.roles)
      .then(u => {
        if (mounted) {
          setUsers(u);
          setReady(true);
        }
      })
      .catch(error =>
        console.log(`Wystąpił problem z pobraniem placówek. ${error}`),
      );

    return () => {
      mounted = false;
    };
  }, [spd.users, props.roles]);

  const provider = async (text: string) => {
    const values = users.filter(v => {
      const name = v.name || '';
      return name.toLocaleLowerCase().includes(text.toLocaleLowerCase());
    });

    return values.map(v => {
      const userNames = v.name;
      return {
        label: userNames || '',
        value: v.uid || '',
      };
    });
  };

  return ready ? (
    <Selector
      uniqueKey={'users-' + JSON.stringify(users)}
      className={props.className}
      readOnly={props.readOnly}
      creatable={false}
      placeholder={props.placeholder || 'Wskaż...'}
      provider={provider}
      value={displayName}
      onChange={props.onChange}
      clearable={props.clearable}
    />
  ) : (
    <Skeleton />
  );
};
