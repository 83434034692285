/* tslint:disable */
/* eslint-disable */
/**
 * SPD
 * Opis api SPD
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CreateUpdateKeyValueDto,
    CreateUpdateKeyValueDtoFromJSON,
    CreateUpdateKeyValueDtoFromJSONTyped,
    CreateUpdateKeyValueDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreateUpdateKeyTestWithKeysDto
 */
export interface CreateUpdateKeyTestWithKeysDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateKeyTestWithKeysDto
     */
    testIdentifier?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateKeyTestWithKeysDto
     */
    name?: string | null;
    /**
     * 
     * @type {Array<CreateUpdateKeyValueDto>}
     * @memberof CreateUpdateKeyTestWithKeysDto
     */
    keyValues?: Array<CreateUpdateKeyValueDto>;
}

export function CreateUpdateKeyTestWithKeysDtoFromJSON(json: any): CreateUpdateKeyTestWithKeysDto {
    return CreateUpdateKeyTestWithKeysDtoFromJSONTyped(json, false);
}

export function CreateUpdateKeyTestWithKeysDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateKeyTestWithKeysDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'testIdentifier': !exists(json, 'testIdentifier') ? undefined : json['testIdentifier'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'keyValues': !exists(json, 'keyValues') ? undefined : ((json['keyValues'] as Array<any>).map(CreateUpdateKeyValueDtoFromJSON)),
    };
}

export function CreateUpdateKeyTestWithKeysDtoToJSON(value?: CreateUpdateKeyTestWithKeysDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'testIdentifier': value.testIdentifier,
        'name': value.name,
        'keyValues': value.keyValues === undefined ? undefined : ((value.keyValues as Array<any>).map(CreateUpdateKeyValueDtoToJSON)),
    };
}


