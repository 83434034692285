import React from 'react';

import { CreateUpdateCardDto } from '../spdCore/autogenerated/spdApiClient';

import { RadioButtonWithDesc } from './RadioButtonWithDesc';

export const RadioButtonNormPatologyWithDesc: React.FC<{
  name: string;
  value: string | undefined | null;
  field: Partial<CreateUpdateCardDto>;
  onChange: (obj: Partial<CreateUpdateCardDto>) => void;
}> = props => {
  return (
    <RadioButtonWithDesc
      name={props.name}
      value={props.value}
      field={props.field}
      onChange={props.onChange}
      onlyNormPatology={true}
    />
  );
};
