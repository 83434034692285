/* tslint:disable */
/* eslint-disable */
/**
 * SPD
 * Opis api SPD
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetReportCompanyDto
 */
export interface GetReportCompanyDto {
    /**
     * 
     * @type {string}
     * @memberof GetReportCompanyDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetReportCompanyDto
     */
    companyName: string;
    /**
     * 
     * @type {string}
     * @memberof GetReportCompanyDto
     */
    companyId: string;
    /**
     * 
     * @type {string}
     * @memberof GetReportCompanyDto
     */
    registerName: string;
    /**
     * 
     * @type {string}
     * @memberof GetReportCompanyDto
     */
    registerId: string;
    /**
     * 
     * @type {string}
     * @memberof GetReportCompanyDto
     */
    healthcareFacilityName: string;
    /**
     * 
     * @type {string}
     * @memberof GetReportCompanyDto
     */
    healthcareFacilityId: string;
    /**
     * 
     * @type {number}
     * @memberof GetReportCompanyDto
     */
    countJudgments: number;
}

export function GetReportCompanyDtoFromJSON(json: any): GetReportCompanyDto {
    return GetReportCompanyDtoFromJSONTyped(json, false);
}

export function GetReportCompanyDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetReportCompanyDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyName': json['companyName'],
        'companyId': json['companyId'],
        'registerName': json['registerName'],
        'registerId': json['registerId'],
        'healthcareFacilityName': json['healthcareFacilityName'],
        'healthcareFacilityId': json['healthcareFacilityId'],
        'countJudgments': json['countJudgments'],
    };
}

export function GetReportCompanyDtoToJSON(value?: GetReportCompanyDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'companyName': value.companyName,
        'companyId': value.companyId,
        'registerName': value.registerName,
        'registerId': value.registerId,
        'healthcareFacilityName': value.healthcareFacilityName,
        'healthcareFacilityId': value.healthcareFacilityId,
        'countJudgments': value.countJudgments,
    };
}


