/* tslint:disable */
/* eslint-disable */
/**
 * SPD
 * Opis api SPD
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetTestDto
 */
export interface GetTestDto {
    /**
     * 
     * @type {string}
     * @memberof GetTestDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetTestDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetTestDto
     */
    fileName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetTestDto
     */
    identifier?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetTestDto
     */
    description?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetTestDto
     */
    active: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetTestDto
     */
    lp?: number | null;
}

export function GetTestDtoFromJSON(json: any): GetTestDto {
    return GetTestDtoFromJSONTyped(json, false);
}

export function GetTestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetTestDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'fileName': !exists(json, 'fileName') ? undefined : json['fileName'],
        'identifier': !exists(json, 'identifier') ? undefined : json['identifier'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'active': json['active'],
        'lp': !exists(json, 'lp') ? undefined : json['lp'],
    };
}

export function GetTestDtoToJSON(value?: GetTestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'fileName': value.fileName,
        'identifier': value.identifier,
        'description': value.description,
        'active': value.active,
        'lp': value.lp,
    };
}


