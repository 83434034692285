/* tslint:disable */
/* eslint-disable */
/**
 * SPD
 * Opis api SPD
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetBatteryWithTestsDto
 */
export interface GetBatteryWithTestsDto {
    /**
     * 
     * @type {string}
     * @memberof GetBatteryWithTestsDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetBatteryWithTestsDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetBatteryWithTestsDto
     */
    description?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetBatteryWithTestsDto
     */
    tests?: Array<string> | null;
}

export function GetBatteryWithTestsDtoFromJSON(json: any): GetBatteryWithTestsDto {
    return GetBatteryWithTestsDtoFromJSONTyped(json, false);
}

export function GetBatteryWithTestsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetBatteryWithTestsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'tests': !exists(json, 'tests') ? undefined : json['tests'],
    };
}

export function GetBatteryWithTestsDtoToJSON(value?: GetBatteryWithTestsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'tests': value.tests,
    };
}


