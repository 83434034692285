import React from 'react';
import Form from 'react-bootstrap/Form';

type FormControlTextareaProps = React.PropsWithChildren<{
  defaultValue: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (e: any) => void;
}>;

export const FormControlTextarea16 = (props: FormControlTextareaProps) => (
  <Form.Control
    as="textarea"
    rows={16}
    defaultValue={props.defaultValue}
    onChange={props.onChange}
  />
);

export const FormControlTextarea10 = (props: FormControlTextareaProps) => (
  <Form.Control
    as="textarea"
    rows={10}
    defaultValue={props.defaultValue}
    onChange={props.onChange}
  />
);

export const FormControlTextarea3 = (props: FormControlTextareaProps) => (
  <Form.Control
    as="textarea"
    rows={3}
    defaultValue={props.defaultValue}
    onChange={props.onChange}
  />
);
