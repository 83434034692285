import React, { useState } from 'react';
import { Alert, Button, Card, Form } from 'react-bootstrap';

import FormRow from '../../../app/components/FormRow';
import {
  CreateUpdateConsultationDto,
  CreateUpdateConsultationDtoTypeEnum,
  CreateUpdateConsultationDtoPdfTemplateTypeEnum,
  GetExamDto,
} from '../../../common/spdCore/autogenerated/spdApiClient';
import { useSpdCore } from '../../../common/hooks/useSpdCore';
import {
  ConsultationType,
  TemplateType,
} from '../../../common/spdCore/validation/schemas';
import { FormControlTextarea3 } from '../../../app/components/FormControlTextarea';
import { Role } from '../../../common/spdCore/authorization/roles';
import { ChooseSigned } from '../Users/ChooseSigned';
import { responseErrors } from '../../../common/spdCore/validation/responseErrors';

interface ConsultationPsychiatricEditorProps {
  exam: GetExamDto;
  consultation: CreateUpdateConsultationDto;
  id: string | undefined;
}

export const ConsultationPsychiatricEditor: React.FC<
  ConsultationPsychiatricEditorProps
> = props => {
  const spd = useSpdCore();
  const [consultation, setConsultation] = useState<CreateUpdateConsultationDto>(
    props.consultation,
  );
  const [id, setId] = useState<string | undefined>(props.id);
  const [message, setMessage] = useState<string>('');

  const createConsultation = async (
    gConsultation: CreateUpdateConsultationDto,
  ) => {
    setMessage('');
    try {
      if (id) {
        await spd.consultations.update(id, gConsultation);
        setConsultation(gConsultation);

        await spd.pdfPrinter.saveAndPrintConsultation(
          id,
          TemplateType.Psychiatric_consultation,
        );
      } else {
        await spd.consultations
          .create({
            ...gConsultation,
            ...{
              pdfTemplateType:
                TemplateType.Psychiatric_consultation as unknown as CreateUpdateConsultationDtoPdfTemplateTypeEnum,
              examId: props?.exam?.id,
              type: ConsultationType.Psychiatric as unknown as CreateUpdateConsultationDtoTypeEnum,
              consultDate: new Date(),
            },
          })
          .then(async o => {
            setConsultation({
              ...o,
              ...{
                ...gConsultation,
                ...{
                  pdfTemplateType:
                    TemplateType.Psychiatric_consultation as unknown as CreateUpdateConsultationDtoPdfTemplateTypeEnum,
                  examId: props?.exam?.id,
                  type: ConsultationType.Psychiatric as unknown as CreateUpdateConsultationDtoTypeEnum,
                },
              },
            });
            setId(o.id);
            await spd.pdfPrinter.saveAndPrintConsultation(
              o.id,
              TemplateType.Psychiatric_consultation,
            );
          })
          .catch(err => {
            void responseErrors(err).then(errors => {
              setMessage(
                `Wystąpił problem z zapisem konsultacji psychiatrycznej:\n ${errors}`,
              );
            });
          });
      }
    } catch (response) {
      const error = await responseErrors(response as Response);
      setMessage(
        `Błąd podczas zapisywania konsultacji psychiatrycznej:\n ${error}`,
      );
    }
  };

  const propertyChange = (obj: Partial<CreateUpdateConsultationDto>) =>
    setConsultation({ ...consultation, ...obj });

  return (
    <Card className="mb-2">
      <Card.Header>{`Konsultacja psychiatryczna ${props.exam?.number}`}</Card.Header>
      <Card.Body>
        <Form className="d-grid gap-3">
          <div className="d-grid gap-3">
            <FormRow controlId="pass" label="Wynik pozytywny">
              <Form.Check
                type="checkbox"
                defaultChecked={
                  consultation?.pass ||
                  consultation?.pass === undefined ||
                  consultation?.pass === null
                    ? true
                    : false
                }
                onChange={e => propertyChange({ pass: e.target.checked })}
              />
            </FormRow>
            <FormRow controlId="staff" label="Lekarz">
              <ChooseSigned
                userUID={consultation?.authorUID}
                handleOnChange={user =>
                  setConsultation({
                    ...consultation,
                    authorUID: user?.uid,
                  })
                }
                roles={[Role.MedicalSpecialist]}
              />
            </FormRow>
            <FormRow controlId="number" label="Numer">
              <Form.Control
                type="text"
                defaultValue={consultation?.number || ''}
                onChange={e =>
                  propertyChange({
                    number: e.target.value,
                  })
                }
              />
            </FormRow>
            <FormRow controlId="descriptionExam" label="Rodzaj pozwolenia">
              <FormControlTextarea3
                defaultValue={consultation?.descriptionExam || ''}
                onChange={e =>
                  propertyChange({ descriptionExam: e.target.value })
                }
              />
            </FormRow>
            <FormRow controlId="interview" label="Wynik badania">
              <FormControlTextarea3
                defaultValue={consultation?.interview || ''}
                onChange={e => propertyChange({ interview: e.target.value })}
              />
            </FormRow>
            <FormRow controlId="recognition" label="Wynik konsultacji">
              <FormControlTextarea3
                defaultValue={consultation?.recognition || ''}
                onChange={e => propertyChange({ recognition: e.target.value })}
              />
            </FormRow>
          </div>
          <Button
            variant="outline-primary"
            className="ms-1"
            onClick={() => createConsultation(consultation)}
          >
            Zapisz i wydrukuj
          </Button>
          {message && <Alert variant={'warning'}>{message}</Alert>}
        </Form>
      </Card.Body>
    </Card>
  );
};
