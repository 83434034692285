import React, { useEffect, useState } from 'react';
import * as Icon from 'react-bootstrap-icons';
import { Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { useLocation } from '../../hooks/useLocation';

import { GridColumn, GridRecord } from './GridDataTypes';
import { GridLocationState } from './GridLocationState';

interface GridInnerTableProps {
  columns: GridColumn[];
  pageData: GridRecord[];
  orderBy?: string;
  orderDirection: 'ASC' | 'DESC';
  summaryData?: GridRecord;
  setOrderBy: (column: string) => void;
  setOrderDirection: (direction: 'ASC' | 'DESC') => void;
  onRowClick?: (key: string, newWindow?: boolean) => void;
  enableMultiSelect?: boolean;
  enableSingleSelect?: boolean;
  selectedKeys?: string[];
}

export const GridInnerTable: React.FC<GridInnerTableProps> = props => {
  const [multiSelected, setMultiSelected] = useState<readonly string[]>(
    props.selectedKeys &&
      props.enableMultiSelect &&
      props.selectedKeys.length > 0
      ? props.selectedKeys
      : [],
  );
  const navigate = useNavigate();
  const location = useLocation<GridLocationState & unknown>();

  useEffect(() => {
    setMultiSelected(
      props.selectedKeys &&
        props.enableMultiSelect &&
        props.selectedKeys.length > 0
        ? props.selectedKeys
        : [],
    );
  }, [props.selectedKeys, props.enableMultiSelect]);

  const [selected, setSelected] = useState<string>(
    props.selectedKeys && props.enableSingleSelect ? props.selectedKeys[0] : '',
  );
  useEffect(() => {
    if (props.selectedKeys && props.enableSingleSelect) {
      setSelected(props.selectedKeys[0]);
    }
  }, [props.selectedKeys, props.enableSingleSelect]);

  const isMultiSelected = (name: string) => multiSelected.indexOf(name) !== -1;

  const isSelected = (name: string) => selected === name;
  const row = (record: GridRecord) => {
    return props.columns.map(column => (
      <td key={record.key + column.property} className={column.className}>
        {record.values[column.property]}
      </td>
    ));
  };

  const sortColumn = (property: string, noSorting?: boolean) => {
    if (noSorting) {
      return;
    }

    props.setOrderBy(property);
    props.setOrderDirection(props.orderDirection === 'ASC' ? 'DESC' : 'ASC');
    navigate(location.pathname, {
      state: {
        ...location.state,
        sort: property,
        sortDirection: props.orderDirection === 'ASC' ? 'DESC' : 'ASC',
      },
    });
  };

  const handleMouseDown = (event: React.MouseEvent, key: string) => {
    if (event.button === 1) {
      event.preventDefault();
      event.stopPropagation();

      props.onRowClick && props.onRowClick(key, true);
    }
  };

  const rowClick = (key: string) => {
    if (props.enableMultiSelect) {
      const selectedIndex = multiSelected.indexOf(key);
      let newSelected: readonly string[] = [];
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(multiSelected, key);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(multiSelected.slice(1));
      } else if (selectedIndex === multiSelected.length - 1) {
        newSelected = newSelected.concat(multiSelected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          multiSelected.slice(0, selectedIndex),
          multiSelected.slice(selectedIndex + 1),
        );
      }
      setMultiSelected(newSelected);
    } else if (props.enableSingleSelect) {
      setSelected(key);
    }

    if (props.onRowClick) {
      props.onRowClick(key);
    }
  };

  const css = (key: string) => {
    if (!props.onRowClick) {
      return '';
    }
    if (isMultiSelected(key) || isSelected(key)) {
      return 'grid-highlight-row';
    }
    return 'grid-clickable-row';
  };

  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          {props.columns.map(column => (
            <th
              key={column.property}
              onClick={() => sortColumn(column.property, column.noSorting)}
              className={column.className}
            >
              {column.header}
              {column.property === props.orderBy &&
                (props.orderDirection === 'ASC' ? (
                  <Icon.ArrowUpShort />
                ) : (
                  <Icon.ArrowDownShort />
                ))}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {props.pageData.map(r => (
          <tr
            key={r.key}
            onClick={() => rowClick(r.key)}
            onMouseDown={event => handleMouseDown(event, r.key)}
            className={css(r.key)}
          >
            {row(r)}
          </tr>
        ))}
      </tbody>
      <tfoot>
        {props.summaryData && <tr key="summary">{row(props.summaryData)}</tr>}
      </tfoot>
    </Table>
  );
};
