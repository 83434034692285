import React, { useState, useEffect } from 'react';

import { useSpdCore } from '../../../common/hooks/useSpdCore';
import { GetCompanyDto } from '../../../common/spdCore/autogenerated/spdApiClient';
import { Selector, Option } from '../Selects/Selector';

export interface CompanyByNameSelectorProps {
  readOnly?: boolean;
  value?: string | null;
  placeholder?: string;
  className?: string;
  clearable?: boolean;
  onChange?: (option: Option) => void;
}

export default (props: CompanyByNameSelectorProps) => {
  const spd = useSpdCore();
  const [companies, setCompanies] = useState<GetCompanyDto[]>([]);
  const [displayName, setDisplayName] = useState<string>('');

  useEffect(() => {
    if (!props.value || props.value === '') {
      setDisplayName('');
      return;
    }

    spd.companies
      .get(props.value)
      .then(company => {
        setDisplayName(company.name || '');
      })
      .catch(error => {
        setDisplayName('');
        console.log(`Wystąpił problem z pobraniem firmy. ${error}`);
      });
  }, [props.value, spd.companies]);

  useEffect(() => {
    let mounted = true;
    spd.companies
      .getAll()
      .then(companies => {
        if (mounted) {
          setCompanies(
            companies.sort((a, b) =>
              (a.name || '').localeCompare(b.name || ''),
            ),
          );
        }
      })
      .catch(error => {
        setCompanies([]);
        console.log(`Wystąpił problem z pobraniem firm. ${error}`);
      });

    return () => {
      mounted = false;
    };
  }, [spd.companies, props.value]);

  const provider = async (text: string) => {
    const values = companies.filter(v => {
      const name = v.name || '';
      return name.toLocaleLowerCase().includes(text.toLocaleLowerCase());
    });

    if (!values) {
      return [];
    }

    return values.map(v => {
      return {
        label: v.name || '',
        value: v.id,
      };
    });
  };

  return (
    <Selector
      uniqueKey={
        'companies-' +
        displayName +
        JSON.stringify(companies) +
        JSON.stringify(props.value)
      }
      className={props.className}
      readOnly={props.readOnly}
      creatable={false}
      placeholder={props.placeholder || 'Wskaż...'}
      provider={provider}
      value={displayName}
      onChange={props.onChange}
      clearable={props.clearable}
    />
  );
};
