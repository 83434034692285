/* tslint:disable */
/* eslint-disable */
/**
 * SPD
 * Opis api SPD
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetAnswerDto,
    GetAnswerDtoFromJSON,
    GetAnswerDtoFromJSONTyped,
    GetAnswerDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetReactionDto
 */
export interface GetReactionDto {
    /**
     * 
     * @type {number}
     * @memberof GetReactionDto
     */
    latencyTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetReactionDto
     */
    reactionTime?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetReactionDto
     */
    coreFieldName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetReactionDto
     */
    modeSurvey?: string | null;
    /**
     * 
     * @type {Array<GetAnswerDto>}
     * @memberof GetReactionDto
     */
    answers?: Array<GetAnswerDto> | null;
}

export function GetReactionDtoFromJSON(json: any): GetReactionDto {
    return GetReactionDtoFromJSONTyped(json, false);
}

export function GetReactionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetReactionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'latencyTime': !exists(json, 'latencyTime') ? undefined : json['latencyTime'],
        'reactionTime': !exists(json, 'reactionTime') ? undefined : json['reactionTime'],
        'coreFieldName': !exists(json, 'coreFieldName') ? undefined : json['coreFieldName'],
        'modeSurvey': !exists(json, 'modeSurvey') ? undefined : json['modeSurvey'],
        'answers': !exists(json, 'answers') ? undefined : (json['answers'] === null ? null : (json['answers'] as Array<any>).map(GetAnswerDtoFromJSON)),
    };
}

export function GetReactionDtoToJSON(value?: GetReactionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'latencyTime': value.latencyTime,
        'reactionTime': value.reactionTime,
        'coreFieldName': value.coreFieldName,
        'modeSurvey': value.modeSurvey,
        'answers': value.answers === undefined ? undefined : (value.answers === null ? null : (value.answers as Array<any>).map(GetAnswerDtoToJSON)),
    };
}


