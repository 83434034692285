import React from 'react';

import { Grid } from '../../../common/components/Grid/Grid';
import {
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import { GetReportJudgmentDto } from '../../../common/spdCore/autogenerated/spdApiClient';

import { ReportCompaniesDetails } from './ReportCompaniesDetails';

interface ReportJudgmentsSearchProps {
  getData: GridGetterFunction;
  getCount: GridCountFunction;
  onRowClick?: (key: string) => Promise<void> | void | undefined;
  refresh?: boolean;
  enableSingleSelect?: boolean;
  selectedKeys?: string[];
  withHealthcareFacility?: boolean;
  withRegister?: boolean;
  initialOrderColumn?: string;
  withDetails?: boolean;
  psychologistName?: string;
}

export const reportJudgmentForGrid = (
  reportJudgment: GetReportJudgmentDto,
  companyId?: string,
  dateFrom?: Date,
  dateTo?: Date,
) => {
  return {
    key: reportJudgment.id,
    values: {
      psychologist_name: reportJudgment.psychologistName,
      register_name: reportJudgment.registerName || '',
      healthcare_facility_name: reportJudgment.healthcareFacilityName,
      judgmentCount: reportJudgment.countJudgments,
      details: (
        <ReportCompaniesDetails
          psychologistId={reportJudgment.psychologistId}
          companyId={companyId}
          registerId={reportJudgment.registerId}
          healthcareFacilityId={reportJudgment.healthcareFacilityId}
          dateFrom={dateFrom}
          dateTo={dateTo}
        />
      ),
    },
  };
};

export const ReportJudgmentsSearch: React.FC<
  ReportJudgmentsSearchProps
> = props => {
  const columns = [
    {
      header: props.psychologistName || 'Lekarz/psycholog',
      property: 'psychologist_name',
    },
    ...(props.withRegister
      ? [{ header: 'Rejestr', property: 'register_name' }]
      : []),
    ...(props.withHealthcareFacility
      ? [{ header: 'Placówka', property: 'healthcare_facility_name' }]
      : []),

    { header: 'Liczba orzeczeń', property: 'judgmentCount', noSorting: true },
    ...(props.withDetails
      ? [{ header: 'Szczegóły', property: 'details', noSorting: true }]
      : []),
  ];

  return (
    <Grid
      getData={props.getData}
      getDataCount={props.getCount}
      columns={columns}
      showFilter={true}
      initialOrderColumn={
        props.initialOrderColumn ||
        'psychologist_name, register_name, healthcare_facility_name'
      }
      initialOrderDirection="ASC"
      availablePageSizes={[10, 20, 50]}
      pageSize={20}
      onRowClick={props.onRowClick}
      emptyGridMessage="Brak orzeczeń w systemie"
      refresh={props.refresh}
      enableSingleSelect={props.enableSingleSelect}
      selectedKeys={props.selectedKeys}
    />
  );
};
