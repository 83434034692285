/*eslint max-lines-per-function: ["error", 250]*/
import { DefaultApi } from '../autogenerated/spdApiClient/apis/DefaultApi';
import { GetExamDto } from '../autogenerated/spdApiClient';
import { GridRecord } from '../../components/Grid/GridDataTypes';
import { reportJudgmentForGrid } from '../../../spd/components/Reports/ReportJudgmentsSearch';
import { reportConsultationForGrid } from '../../../spd/components/Reports/ReportConsultationsSearch';
import { reportCompanyForGrid } from '../../../spd/components/Reports/ReportCompaniesSearch';
import { judgmentForGrid } from '../../../spd/components/Judgments/JudgmentsSearch';

export type ExamEntity = GetExamDto;

export interface ReportContextInterface {
  getJudgmentsByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
    dateFrom?: Date,
    dateTo?: Date,
    userUid?: string,
    companyId?: string,
    healthcareFacilityId?: string,
    withHealthcareFacility?: boolean,
    withRegister?: boolean,
  ) => Promise<GridRecord[]>;
  getJudgmentsCount: (
    filterText?: string,
    dateFrom?: Date,
    dateTo?: Date,
    userUid?: string,
    companyId?: string,
    healthcareFacilityId?: string,
    withHealthcareFacility?: boolean,
    withRegister?: boolean,
  ) => Promise<number>;
  getConsultationsByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
    dateFrom?: Date,
    dateTo?: Date,
    userUid?: string,
    companyId?: string,
    healthcareFacilityId?: string,
    withHealthcareFacility?: boolean,
    withType?: boolean,
  ) => Promise<GridRecord[]>;
  getConsultationsCount: (
    filterText?: string,
    dateFrom?: Date,
    dateTo?: Date,
    userUid?: string,
    companyId?: string,
    healthcareFacilityId?: string,
    withHealthcareFacility?: boolean,
    withType?: boolean,
  ) => Promise<number>;
  getCompaniesByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
    dateFrom?: Date,
    dateTo?: Date,
    onlyMonthlySettlement?: boolean,
    companyId?: string,
    healthcareFacilityId?: string,
    withHealthcareFacility?: boolean,
    withRegister?: boolean,
  ) => Promise<GridRecord[]>;
  getCompaniesCount: (
    filterText?: string,
    dateFrom?: Date,
    dateTo?: Date,
    onlyMonthlySettlement?: boolean,
    companyId?: string,
    healthcareFacilityId?: string,
    withHealthcareFacility?: boolean,
    withRegister?: boolean,
  ) => Promise<number>;
  getCompaniesDetailsByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
    psychologistId?: string,
    companyId?: string,
    registerId?: string,
    healthcareFacilityId?: string,
    dateFrom?: Date,
    dateTo?: Date,
    onlyMonthlySettlement?: string,
  ) => Promise<GridRecord[]>;
  getCompaniesDetailsCount: (
    filterText?: string,
    psychologistId?: string,
    companyId?: string,
    registerId?: string,
    healthcareFacilityId?: string,
    dateFrom?: Date,
    dateTo?: Date,
    onlyMonthlySettlement?: string,
  ) => Promise<number>;
  getJudgmentsByCreatorByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
    dateFrom?: Date,
    dateTo?: Date,
  ) => Promise<GridRecord[]>;
  getJudgmentsByCreatorCount: (
    filterText?: string,
    dateFrom?: Date,
    dateTo?: Date,
  ) => Promise<number>;
}

export const ReportContext = (api: DefaultApi) => {
  return {
    getJudgmentsByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
      dateFrom?: Date,
      dateTo?: Date,
      userUid?: string,
      companyId?: string,
      healthcareFacilityId?: string,
      withHealthcareFacility?: boolean,
      withRegister?: boolean,
    ) => {
      const data = await api.reportControllerGetJudgmentsByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
        dateFrom,
        dateTo,
        userUid,
        companyId,
        healthcareFacilityId,
        withHealthcareFacility,
        withRegister,
      );
      return data.map(judgment =>
        reportJudgmentForGrid(judgment, companyId, dateFrom, dateTo),
      );
    },

    getJudgmentsCount: (
      filterText?: string,
      dateFrom?: Date,
      dateTo?: Date,
      userUid?: string,
      companyId?: string,
      healthcareFacilityId?: string,
      withHealthcareFacility?: boolean,
      withRegister?: boolean,
    ) =>
      api.reportControllerGetJudgmentsCount(
        filterText || '',
        dateFrom,
        dateTo,
        userUid,
        companyId,
        healthcareFacilityId,
        withHealthcareFacility,
        withRegister,
      ),
    getConsultationsByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
      dateFrom?: Date,
      dateTo?: Date,
      userUid?: string,
      companyId?: string,
      healthcareFacilityId?: string,
      withHealthcareFacility?: boolean,
      withType?: boolean,
    ) => {
      const data = await api.reportControllerGetConsultationsByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
        dateFrom,
        dateTo,
        userUid,
        companyId,
        healthcareFacilityId,
        withHealthcareFacility,
        withType,
      );
      return data.map(consultation => reportConsultationForGrid(consultation));
    },

    getConsultationsCount: (
      filterText?: string,
      dateFrom?: Date,
      dateTo?: Date,
      userUid?: string,
      companyId?: string,
      healthcareFacilityId?: string,
      withHealthcareFacility?: boolean,
      withType?: boolean,
    ) =>
      api.reportControllerGetConsultationsCount(
        filterText || '',
        dateFrom,
        dateTo,
        userUid,
        companyId,
        healthcareFacilityId,
        withHealthcareFacility,
        withType,
      ),

    getCompaniesByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
      dateFrom?: Date,
      dateTo?: Date,
      onlyMonthlySettlement?: boolean,
      companyId?: string,
      healthcareFacilityId?: string,
      withHealthcareFacility?: boolean,
      withRegister?: boolean,
    ) => {
      const data = await api.reportControllerGetCompaniesByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
        dateFrom,
        dateTo,
        onlyMonthlySettlement,
        companyId,
        healthcareFacilityId,
        withHealthcareFacility,
        withRegister,
      );
      return data.map(company =>
        reportCompanyForGrid(company, dateFrom, dateTo, onlyMonthlySettlement),
      );
    },

    getCompaniesCount: (
      filterText?: string,
      dateFrom?: Date,
      dateTo?: Date,
      onlyMonthlySettlement?: boolean,
      companyId?: string,
      healthcareFacilityId?: string,
      withHealthcareFacility?: boolean,
      withRegister?: boolean,
    ) =>
      api.reportControllerGetCompaniesCount(
        filterText || '',
        dateFrom,
        dateTo,
        onlyMonthlySettlement,
        companyId,
        healthcareFacilityId,
        withHealthcareFacility,
        withRegister,
      ),

    getCompaniesDetailsByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
      psychologistId?: string,
      companyId?: string,
      registerId?: string,
      healthcareFacilityId?: string,
      dateFrom?: Date,
      dateTo?: Date,
      onlyMonthlySettlement?: string,
    ) => {
      const data = await api.reportControllerGetCompaniesDetailsByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
        psychologistId,
        companyId,
        registerId,
        healthcareFacilityId,
        dateFrom,
        dateTo,
        onlyMonthlySettlement,
      );
      return data.map(det => judgmentForGrid(det));
    },
    getCompaniesDetailsCount: (
      filterText?: string,
      psychologistId?: string,
      companyId?: string,
      registerId?: string,
      healthcareFacilityId?: string,
      dateFrom?: Date,
      dateTo?: Date,
      onlyMonthlySettlement?: string,
    ) =>
      api.reportControllerGetCompaniesDetailsCount(
        filterText || '',
        psychologistId,
        companyId,
        registerId,
        healthcareFacilityId,
        dateFrom,
        dateTo,
        onlyMonthlySettlement,
      ),

    getJudgmentsByCreatorByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
      dateFrom?: Date,
      dateTo?: Date,
    ) => {
      const data = await api.reportControllerGetJudgmentsByCreatorByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
        dateFrom,
        dateTo,
      );
      return data.map(judgment =>
        reportJudgmentForGrid(judgment, undefined, dateFrom, dateTo),
      );
    },

    getJudgmentsByCreatorCount: (
      filterText?: string,
      dateFrom?: Date,
      dateTo?: Date,
    ) =>
      api.reportControllerGetJudgmentsByCreatorCount(
        filterText || '',
        dateFrom,
        dateTo,
      ),
  };
};
