import React from 'react';
import { Card, Tab } from 'react-bootstrap';

import Tabs from '../../../app/components/Tabs';
import { ReferralsList } from '../../components/Referrals/ReferralsList';

export const ReferralsView: React.FC = () => {
  return (
    <Card>
      <Tabs defaultActiveKey="search" id="referrals" className="mb-3">
        <Tab eventKey="search" title="Skierowania" className="m-3">
          <ReferralsList />
        </Tab>
      </Tabs>
    </Card>
  );
};
