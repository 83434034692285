import React, { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';

import { useSpdCore } from '../../../common/hooks/useSpdCore';
import { GetDutyDto } from '../../../common/spdCore/autogenerated/spdApiClient/models/GetDutyDto';

import { Selector, Option } from './Selector';

export interface DutySelectorProps {
  readOnly?: boolean;
  value?: string;
  placeholder?: string;
  className?: string;
  onChange?: (option: Option) => void;
}

export default (props: DutySelectorProps) => {
  const spd = useSpdCore();
  const [duties, setDuties] = useState<GetDutyDto[]>([]);
  const [displayName, setDisplayName] = useState<string>();
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (props.value) {
      spd.duties
        .get(props.value || '')
        .then(duty => {
          setDisplayName(duty.name || '');
        })
        .catch(error =>
          console.log(`Wystąpił problem z pobraniem grup dyżurów. ${error}`),
        );
    }
  }, [props.value, spd.duties]);

  useEffect(() => {
    let mounted = true;
    spd.duties
      .getAll()
      .then(dt => {
        if (mounted) {
          setDuties(dt);
          setReady(true);
        }
      })
      .catch(error =>
        console.log(`Wystąpił problem z pobraniem grup dyżurów. ${error}`),
      );

    return () => {
      mounted = false;
    };
  }, [spd.duties]);

  const provider = async (text: string) => {
    const values = duties.filter(v => {
      const name = v.name || '';
      return name.toLocaleLowerCase().includes(text.toLocaleLowerCase());
    });

    return values.map(v => {
      return {
        label: v.name || '',
        value: v.id,
      };
    });
  };

  return ready ? (
    <Selector
      uniqueKey={'duties-' + JSON.stringify(duties)}
      className={props.className}
      readOnly={props.readOnly}
      creatable={false}
      placeholder={props.placeholder || 'Wskaż...'}
      provider={provider}
      value={displayName}
      onChange={props.onChange}
    />
  ) : (
    <Skeleton />
  );
};
