import React from 'react';
import { Form } from 'react-bootstrap';

import FormRow from '../../app/components/FormRow';
import { CreateUpdateCardDto } from '../spdCore/autogenerated/spdApiClient';

export const RadioButtonWithDesc: React.FC<{
  name: string;
  value: string | undefined | null;
  field: Partial<CreateUpdateCardDto>;
  onChange: (obj: Partial<CreateUpdateCardDto>) => void;
  onlyNormPatology?: boolean;
}> = props => {
  return (
    <div key={`inline-${props.name}`} className="mb-3">
      <FormRow controlId={`postDate-${props.name}`} label={props.name}>
        <Form.Check
          inline
          label="Norma"
          name={`group-${props.name}`}
          type="radio"
          id={`norm-${props.name}`}
          checked={f(props.value === 'Norma')}
          onChange={() =>
            props.onChange({ [Object.keys(props.field)[0]]: 'Norma' })
          }
        />
        <Form.Check
          inline
          label="Patologia"
          name={`group-${props.name}`}
          type="radio"
          id={`pathology-${props.name}`}
          checked={f(props.value !== 'Norma' && props.value !== 'Nie badano')}
          onChange={() =>
            props.onChange({ [Object.keys(props.field)[0]]: 'Patologia' })
          }
        />
        {!props.onlyNormPatology && (
          <Form.Check
            inline
            label="Nie badano"
            name={`group-${props.name}`}
            type="radio"
            id={`not-tested-${props.name}`}
            checked={f(props.value === 'Nie badano')}
            onChange={() =>
              props.onChange({ [Object.keys(props.field)[0]]: 'Nie badano' })
            }
          />
        )}
        <Form.Control
          type="text"
          value={
            props.value &&
            props.value !== 'Norma' &&
            props.value !== 'Nie badano' &&
            props.value !== 'Patologia'
              ? props.value
              : ''
          }
          onChange={e =>
            props.onChange({ [Object.keys(props.field)[0]]: e.target.value })
          }
        />
      </FormRow>
    </div>
  );
};

const f = (b: boolean | undefined | null) => b || false;
