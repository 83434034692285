import React, { useEffect, useState } from 'react';
import { Form, Card, Button } from 'react-bootstrap';

import { useSpdCore } from '../../../common/hooks/useSpdCore';
import { GetArticleDto } from '../../../common/spdCore/autogenerated/spdApiClient';
import { ArticleMode } from '../../../common/spdCore/validation/schemas';
import { Article } from '../Articles/Articles';

interface Props {
  articlesKeys?: string[];
  nextStep?: () => void;
  handleFormData?: (input: string) => (e: string[]) => void;
  prevStep?: () => void;

  mode: ArticleMode;
  onChange?: (articlesKeys: string[]) => void;
}

export const ChooseArticle: React.FC<Props> = ({
  articlesKeys,
  nextStep,
  handleFormData,
  prevStep,
  mode,
  onChange,
}) => {
  const spd = useSpdCore();

  const [articles, setArticles] = useState('');
  const [dataArticles, setDataArticles] = useState<GetArticleDto[]>([]);
  const [selectedArticles, setSelectedArticles] = useState<
    string[] | undefined
  >(articlesKeys);

  useEffect(() => {
    spd.articles
      .getAll(mode)
      .then(o => setDataArticles(o))
      .catch(error => {
        console.error('Error during getAll articles.', error);
      });
  }, [spd.articles, mode]);

  useEffect(() => {
    const helpArticles = [];
    for (const articleId of articlesKeys || []) {
      helpArticles.push(...dataArticles.filter(o => o.id === articleId));
    }
    setSelectedArticles(articlesKeys);
    setArticles(helpArticles.map(o => o.description || '').join(', '));
  }, [dataArticles, articlesKeys]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const submitFormData = (e: any) => {
    e.preventDefault();
    nextStep && nextStep();
  };

  const handleOnRowClick = (key: string) => {
    let tk: string[] | undefined;
    if (!selectedArticles?.includes(key)) {
      tk = [...(selectedArticles || []), key];
    } else {
      tk = selectedArticles.filter(o => o !== key);
    }
    setSelectedArticles(tk);

    if (mode === ArticleMode.Psychologist) {
      handleFormData && handleFormData('articlePsychologistsKeys')(tk);
    }
    if (mode === ArticleMode.Doctor) {
      handleFormData && handleFormData('articleDoctorsKeys')(tk);
    }
    onChange && onChange(tk);
  };

  return (
    <>
      <Card>
        <Card.Body>
          <Form onSubmit={submitFormData}>
            <Form.Group className="mb-3">
              <Form.Label>
                {`Wybrane artykuły obsługiwane przez ${
                  mode === ArticleMode.Psychologist ? 'psychologa' : 'lekarza'
                }:`}
              </Form.Label>{' '}
              <Form.Label>
                <strong>{articles}</strong>
              </Form.Label>
              <Article
                data={dataArticles}
                onRowClick={handleOnRowClick}
                enableMultiSelect={true}
                selectedKeys={selectedArticles}
              />
            </Form.Group>

            {nextStep && prevStep && (
              <div className="d-flex justify-content-end m-3">
                <Button
                  variant="outline-secondary"
                  className="ms-1"
                  onClick={prevStep}
                >
                  Wstecz
                </Button>

                <Button
                  variant="outline-primary"
                  className="ms-1"
                  type="submit"
                >
                  Dalej
                </Button>
              </div>
            )}
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};
