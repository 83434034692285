import React, { useState, useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';

import {
  Company,
  companyData,
} from '../../../common/spdCore/contexts/CompanyContext';

import { CompanyCheckOrForm } from './CompanyCheckOrForm';

interface CompanySelectorProps {
  value: Company;
  readOnly?: boolean;
  onSelect: (company: Company) => void;
  onClear?: () => void;
}

export const CompanySelector: React.FC<CompanySelectorProps> = props => {
  const [showNewAlert, setShowNewAlert] = useState(false);
  const [selectedExisting, setSelectedExisting] = useState(false);
  const [value, setValue] = useState(props.value);
  const [checkByName, setCheckByName] = useState(0);

  const decision = (newCompany: boolean) => {
    if (newCompany) {
      setShowNewAlert(true);
    } else {
      setSelectedExisting(true);
      setShowNewAlert(false);
    }
  };

  const clear = () => {
    setSelectedExisting(false);
    setShowNewAlert(false);
    setValue(companyData());
    setCheckByName(Date.now());
    if (props.onClear) {
      props.onClear();
    }
  };

  useEffect(() => {
    setValue(props.value);
    setSelectedExisting('id' in props.value);
  }, [props.value]);

  return (
    <>
      <CompanyCheckOrForm
        onSelect={props.onSelect}
        onDecision={decision}
        readOnly={selectedExisting}
        value={value}
        checkNow={checkByName}
      />
      {showNewAlert && (
        <Alert variant="warning" className="mt-3">
          <Alert.Heading>Uwaga!</Alert.Heading>
          <p>
            Nie znaleziono firmy o takich danych, więc zostanie utworzona nowa
            firma
          </p>
        </Alert>
      )}
      {selectedExisting && (
        <div className="d-flex justify-content-end mt-3">
          <Button variant="outline-dark" onClick={clear}>
            Wyczyść
          </Button>
        </div>
      )}
    </>
  );
};
