import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

import { useNavigation } from '../../../common/navigation';

interface ReportCompaniesDetailsProps {
  companyId?: string;
  psychologistId?: string;
  registerId?: string;
  healthcareFacilityId?: string;
  dateFrom?: Date;
  dateTo?: Date;
  onlyMonthlySettlement?: boolean;
}

export const ReportCompaniesDetails: React.FC<
  ReportCompaniesDetailsProps
> = props => {
  const nav = useNavigation();

  const handleClick = () => {
    const nDateFrom = props.dateFrom ? new Date(props.dateFrom) : undefined;
    const nDateTo = props.dateTo ? new Date(props.dateTo) : undefined;

    nav.reportCompanyDetails(
      props.psychologistId,
      props.companyId,
      props.registerId,
      props.healthcareFacilityId,
      nDateFrom?.getFullYear().toString(),
      nDateFrom?.getMonth().toString(),
      nDateFrom?.getDate().toString(),
      nDateTo?.getFullYear().toString(),
      nDateTo?.getMonth().toString(),
      nDateTo?.getDate().toString(),
      props.onlyMonthlySettlement,
      true,
    );
  };

  return (
    <OverlayTrigger
      overlay={<Tooltip>Otwórz szczegóły w nowej karcie</Tooltip>}
    >
      <Button
        onClick={e => {
          e.stopPropagation();
          handleClick();
        }}
        variant="btn-info"
        className="px-2"
      >
        <Icon.BoxArrowUpRight className="icon-blue" size={16} />
      </Button>
    </OverlayTrigger>
  );
};
