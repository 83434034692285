import React, { useState } from 'react';
import { Card, Tab } from 'react-bootstrap';

import { useSpdCore } from '../../../common/hooks/useSpdCore';
import { Role } from '../../../common/spdCore/authorization/roles';
import { Filter } from '../RegistersView/Filter';
import { ReportJudgmentsSearch } from '../../components/Reports/ReportJudgmentsSearch';
import { TabsHistory } from '../../../app/components/TabsHistory';

export const ReportJudgmentsByCreatorView: React.FC = () => {
  const spd = useSpdCore();
  const startDate = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate(),
    0,
    0,
    0,
    0,
  );
  const [dateFrom, setDateFrom] = useState<Date | undefined>(
    new Date(startDate),
  );
  const [dateTo, setDateTo] = useState<Date | undefined>();

  return (
    <>
      <Card id="a">
        <Card.Header>{'Zestawienie wg twórców orzeczeń'}</Card.Header>
        <Card.Body>
          <Filter
            headerName={'Zestawienie wg twórców orzeczeń'}
            roles={[
              Role.MedicalAssessor,
              Role.MedicalSpecialist,
              Role.PsychologistAssessor,
              Role.PsychologistSpecialist,
            ]}
            dateFrom={startDate}
            withUser={false}
            withDate={true}
            withHealthcareFacility={false}
            withCompany={false}
            onChangeDateFrom={setDateFrom}
            onChangeDateTo={setDateTo}
            canClean={true}
            emptyOnStart={true}
            dateFromText="Data utworzenia od"
            dateToText="Data utworzenia do"
          />
          <TabsHistory
            activeKey="judgmentsByCreator"
            id="judgmentsByCreator"
            className="mb-3"
            mountOnEnter
            unmountOnExit
          >
            <Tab
              eventKey="judgmentsByCreator"
              title="Zestawienie orzeczeń wg daty utworzenia i osoby tworzącej"
              className="m-3"
            >
              <ReportJudgmentsSearch
                getData={(
                  pageSize,
                  pageNumber,
                  orderBy,
                  orderDirection,
                  filterText,
                ) =>
                  spd.reports.getJudgmentsByCreatorByPortion(
                    pageSize,
                    pageNumber,
                    orderBy,
                    orderDirection,
                    filterText,
                    dateFrom,
                    dateTo,
                  )
                }
                getCount={filterText =>
                  spd.reports.getJudgmentsByCreatorCount(
                    filterText,
                    dateFrom,
                    dateTo,
                  )
                }
                initialOrderColumn="psychologist_name"
                psychologistName="Użytkownik"
              />
            </Tab>
          </TabsHistory>
        </Card.Body>
      </Card>
    </>
  );
};
