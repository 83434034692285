import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

import { useSpdCore } from '../../../common/hooks/useSpdCore';
import ConfirmationButton from '../../../app/components/ConfirmationButton';

export const JudgmentBinLink = (props: {
  judgmentId: string;
  setRefresh?: () => void;
}) => {
  const spd = useSpdCore();

  const handleClick = () => {
    spd.judgments
      .changeToInactive(props.judgmentId)
      .then(() => {
        props.setRefresh && props.setRefresh();
      })
      .catch(error =>
        console.log(`Wystąpił problem z usunięciem orzeczenia. ${error}`),
      );
  };

  return (
    <>
      <OverlayTrigger
        overlay={
          <Tooltip style={{ position: 'fixed' }}>Usunięcie orzeczenia</Tooltip>
        }
      >
        <ConfirmationButton
          className="ms-1"
          confirmation="Czy na pewno usunąć orzeczenie?"
          variant="btn-info"
          onOK={handleClick}
        >
          <Icon.Trash />
        </ConfirmationButton>
      </OverlayTrigger>
    </>
  );
};
