import React from 'react';

import { Grid } from '../../../common/components/Grid/Grid';
import { GetPositionNormDto } from '../../../common/spdCore/autogenerated/spdApiClient';

interface PositionNormsProps {
  data?: GetPositionNormDto[];
}

export const positionNormsForGrid = (position: GetPositionNormDto) => {
  return {
    key: position.id,
    values: {
      'position.mode': position.mode,
      'position.resultTrue': position.resultTrue,
      'position.resultRawFrom': position.resultRawFrom,
      'position.resultRawTo': position.resultRawTo,
    },
  };
};

export const PositionNorms: React.FC<PositionNormsProps> = props => {
  const columns = [
    { header: 'Rodzaj', property: 'position.mode' },
    { header: 'Wynik przeliczony', property: 'position.resultTrue' },
    { header: 'Wynik surowy od', property: 'position.resultRawFrom' },
    { header: 'Wynik surowy do', property: 'position.resultRawTo' },
  ];

  return (
    <Grid
      data={props.data && props.data.map(d => positionNormsForGrid(d))}
      columns={columns}
      showFilter={false}
      initialOrderColumn="position.resultTrue"
      availablePageSizes={[10, 20, 50]}
      emptyGridMessage="Brak pozycji normy w systemie"
      enableMultiSelect={false}
    />
  );
};
