/* tslint:disable */
/* eslint-disable */
/**
 * SPD
 * Opis api SPD
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetBatteryDto,
    GetBatteryDtoFromJSON,
    GetBatteryDtoFromJSONTyped,
    GetBatteryDtoToJSON,
    GetExaminedDto,
    GetExaminedDtoFromJSON,
    GetExaminedDtoFromJSONTyped,
    GetExaminedDtoToJSON,
    GetExamsPackageDto,
    GetExamsPackageDtoFromJSON,
    GetExamsPackageDtoFromJSONTyped,
    GetExamsPackageDtoToJSON,
    GetUserDto,
    GetUserDtoFromJSON,
    GetUserDtoFromJSONTyped,
    GetUserDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetExamDto
 */
export interface GetExamDto {
    /**
     * 
     * @type {string}
     * @memberof GetExamDto
     */
    id: string;
    /**
     * 
     * @type {GetExaminedDto}
     * @memberof GetExamDto
     */
    examined?: GetExaminedDto | null;
    /**
     * 
     * @type {GetUserDto}
     * @memberof GetExamDto
     */
    psychologist?: GetUserDto | null;
    /**
     * 
     * @type {Date}
     * @memberof GetExamDto
     */
    registrationDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof GetExamDto
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetExamDto
     */
    number: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetExamDto
     */
    articlePsychologistsIds?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetExamDto
     */
    articleDoctorsIds?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof GetExamDto
     */
    pin?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetExamDto
     */
    alarm: boolean;
    /**
     * 
     * @type {Date}
     * @memberof GetExamDto
     */
    changeDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof GetExamDto
     */
    status?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof GetExamDto
     */
    startDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof GetExamDto
     */
    endDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof GetExamDto
     */
    drivingLicense?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetExamDto
     */
    occupationalMedicineCategory?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof GetExamDto
     */
    occupationalMedicineText?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetExamDto
     */
    type?: GetExamDtoTypeEnum;
    /**
     * 
     * @type {Date}
     * @memberof GetExamDto
     */
    referralDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof GetExamDto
     */
    employerName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetExamDto
     */
    employerAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetExamDto
     */
    employmentPosition?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetExamDto
     */
    employmentCandidate?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof GetExamDto
     */
    sportsClubName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetExamDto
     */
    sportsClubAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetExamDto
     */
    sportsDiscipline?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof GetExamDto
     */
    lossOfAbilityDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof GetExamDto
     */
    kindOfScience?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof GetExamDto
     */
    endScience?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof GetExamDto
     */
    schoolName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetExamDto
     */
    schoolAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetExamDto
     */
    fieldOfStudy?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetExamDto
     */
    schoolCandidate?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof GetExamDto
     */
    takenCareOf?: GetExamDtoTakenCareOfEnum;
    /**
     * 
     * @type {boolean}
     * @memberof GetExamDto
     */
    referralFromEmployer?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetExamDto
     */
    areHarmfulFactors?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetExamDto
     */
    areResultsHarmfulFactors?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof GetExamDto
     */
    harmfulFactors?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetExamDto
     */
    harmfulFactorsIds?: Array<string> | null;
    /**
     * 
     * @type {GetExamsPackageDto}
     * @memberof GetExamDto
     */
    examPackage?: GetExamsPackageDto | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetExamDto
     */
    completed?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetExamDto
     */
    neurologist?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetExamDto
     */
    ophthalmologist?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetExamDto
     */
    laryngologist?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetExamDto
     */
    ophthalmologistEnded?: boolean | null;
    /**
     * 
     * @type {GetBatteryDto}
     * @memberof GetExamDto
     */
    battery?: GetBatteryDto | null;
    /**
     * 
     * @type {string}
     * @memberof GetExamDto
     */
    healthcare?: GetExamDtoHealthcareEnum;
    /**
     * 
     * @type {string}
     * @memberof GetExamDto
     */
    criticalPoint?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetExamDto
     */
    whoExamined?: GetExamDtoWhoExaminedEnum;
}

export function GetExamDtoFromJSON(json: any): GetExamDto {
    return GetExamDtoFromJSONTyped(json, false);
}

export function GetExamDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetExamDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'examined': !exists(json, 'examined') ? undefined : GetExaminedDtoFromJSON(json['examined']),
        'psychologist': !exists(json, 'psychologist') ? undefined : GetUserDtoFromJSON(json['psychologist']),
        'registrationDate': !exists(json, 'registrationDate') ? undefined : (json['registrationDate'] === null ? null : new Date(json['registrationDate'])),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'number': json['number'],
        'articlePsychologistsIds': !exists(json, 'articlePsychologistsIds') ? undefined : json['articlePsychologistsIds'],
        'articleDoctorsIds': !exists(json, 'articleDoctorsIds') ? undefined : json['articleDoctorsIds'],
        'pin': !exists(json, 'pin') ? undefined : json['pin'],
        'alarm': json['alarm'],
        'changeDate': !exists(json, 'changeDate') ? undefined : (json['changeDate'] === null ? null : new Date(json['changeDate'])),
        'status': !exists(json, 'status') ? undefined : json['status'],
        'startDate': !exists(json, 'startDate') ? undefined : (json['startDate'] === null ? null : new Date(json['startDate'])),
        'endDate': !exists(json, 'endDate') ? undefined : (json['endDate'] === null ? null : new Date(json['endDate'])),
        'drivingLicense': !exists(json, 'drivingLicense') ? undefined : json['drivingLicense'],
        'occupationalMedicineCategory': !exists(json, 'occupationalMedicineCategory') ? undefined : json['occupationalMedicineCategory'],
        'occupationalMedicineText': !exists(json, 'occupationalMedicineText') ? undefined : json['occupationalMedicineText'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'referralDate': !exists(json, 'referralDate') ? undefined : (json['referralDate'] === null ? null : new Date(json['referralDate'])),
        'employerName': !exists(json, 'employerName') ? undefined : json['employerName'],
        'employerAddress': !exists(json, 'employerAddress') ? undefined : json['employerAddress'],
        'employmentPosition': !exists(json, 'employmentPosition') ? undefined : json['employmentPosition'],
        'employmentCandidate': !exists(json, 'employmentCandidate') ? undefined : json['employmentCandidate'],
        'sportsClubName': !exists(json, 'sportsClubName') ? undefined : json['sportsClubName'],
        'sportsClubAddress': !exists(json, 'sportsClubAddress') ? undefined : json['sportsClubAddress'],
        'sportsDiscipline': !exists(json, 'sportsDiscipline') ? undefined : json['sportsDiscipline'],
        'lossOfAbilityDate': !exists(json, 'lossOfAbilityDate') ? undefined : (json['lossOfAbilityDate'] === null ? null : new Date(json['lossOfAbilityDate'])),
        'kindOfScience': !exists(json, 'kindOfScience') ? undefined : json['kindOfScience'],
        'endScience': !exists(json, 'endScience') ? undefined : (json['endScience'] === null ? null : new Date(json['endScience'])),
        'schoolName': !exists(json, 'schoolName') ? undefined : json['schoolName'],
        'schoolAddress': !exists(json, 'schoolAddress') ? undefined : json['schoolAddress'],
        'fieldOfStudy': !exists(json, 'fieldOfStudy') ? undefined : json['fieldOfStudy'],
        'schoolCandidate': !exists(json, 'schoolCandidate') ? undefined : json['schoolCandidate'],
        'takenCareOf': !exists(json, 'takenCareOf') ? undefined : json['takenCareOf'],
        'referralFromEmployer': !exists(json, 'referralFromEmployer') ? undefined : json['referralFromEmployer'],
        'areHarmfulFactors': !exists(json, 'areHarmfulFactors') ? undefined : json['areHarmfulFactors'],
        'areResultsHarmfulFactors': !exists(json, 'areResultsHarmfulFactors') ? undefined : json['areResultsHarmfulFactors'],
        'harmfulFactors': !exists(json, 'harmfulFactors') ? undefined : json['harmfulFactors'],
        'harmfulFactorsIds': !exists(json, 'harmfulFactorsIds') ? undefined : json['harmfulFactorsIds'],
        'examPackage': !exists(json, 'examPackage') ? undefined : GetExamsPackageDtoFromJSON(json['examPackage']),
        'completed': !exists(json, 'completed') ? undefined : json['completed'],
        'neurologist': !exists(json, 'neurologist') ? undefined : json['neurologist'],
        'ophthalmologist': !exists(json, 'ophthalmologist') ? undefined : json['ophthalmologist'],
        'laryngologist': !exists(json, 'laryngologist') ? undefined : json['laryngologist'],
        'ophthalmologistEnded': !exists(json, 'ophthalmologistEnded') ? undefined : json['ophthalmologistEnded'],
        'battery': !exists(json, 'battery') ? undefined : GetBatteryDtoFromJSON(json['battery']),
        'healthcare': !exists(json, 'healthcare') ? undefined : json['healthcare'],
        'criticalPoint': !exists(json, 'criticalPoint') ? undefined : json['criticalPoint'],
        'whoExamined': !exists(json, 'whoExamined') ? undefined : json['whoExamined'],
    };
}

export function GetExamDtoToJSON(value?: GetExamDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'examined': GetExaminedDtoToJSON(value.examined),
        'psychologist': GetUserDtoToJSON(value.psychologist),
        'registrationDate': value.registrationDate === undefined ? undefined : (value.registrationDate === null ? null : value.registrationDate.toISOString()),
        'description': value.description,
        'number': value.number,
        'articlePsychologistsIds': value.articlePsychologistsIds,
        'articleDoctorsIds': value.articleDoctorsIds,
        'pin': value.pin,
        'alarm': value.alarm,
        'changeDate': value.changeDate === undefined ? undefined : (value.changeDate === null ? null : value.changeDate.toISOString()),
        'status': value.status,
        'startDate': value.startDate === undefined ? undefined : (value.startDate === null ? null : value.startDate.toISOString()),
        'endDate': value.endDate === undefined ? undefined : (value.endDate === null ? null : value.endDate.toISOString()),
        'drivingLicense': value.drivingLicense,
        'occupationalMedicineCategory': value.occupationalMedicineCategory,
        'occupationalMedicineText': value.occupationalMedicineText,
        'type': value.type,
        'referralDate': value.referralDate === undefined ? undefined : (value.referralDate === null ? null : value.referralDate.toISOString()),
        'employerName': value.employerName,
        'employerAddress': value.employerAddress,
        'employmentPosition': value.employmentPosition,
        'employmentCandidate': value.employmentCandidate,
        'sportsClubName': value.sportsClubName,
        'sportsClubAddress': value.sportsClubAddress,
        'sportsDiscipline': value.sportsDiscipline,
        'lossOfAbilityDate': value.lossOfAbilityDate === undefined ? undefined : (value.lossOfAbilityDate === null ? null : value.lossOfAbilityDate.toISOString()),
        'kindOfScience': value.kindOfScience,
        'endScience': value.endScience === undefined ? undefined : (value.endScience === null ? null : value.endScience.toISOString()),
        'schoolName': value.schoolName,
        'schoolAddress': value.schoolAddress,
        'fieldOfStudy': value.fieldOfStudy,
        'schoolCandidate': value.schoolCandidate,
        'takenCareOf': value.takenCareOf,
        'referralFromEmployer': value.referralFromEmployer,
        'areHarmfulFactors': value.areHarmfulFactors,
        'areResultsHarmfulFactors': value.areResultsHarmfulFactors,
        'harmfulFactors': value.harmfulFactors,
        'harmfulFactorsIds': value.harmfulFactorsIds,
        'examPackage': GetExamsPackageDtoToJSON(value.examPackage),
        'completed': value.completed,
        'neurologist': value.neurologist,
        'ophthalmologist': value.ophthalmologist,
        'laryngologist': value.laryngologist,
        'ophthalmologistEnded': value.ophthalmologistEnded,
        'battery': GetBatteryDtoToJSON(value.battery),
        'healthcare': value.healthcare,
        'criticalPoint': value.criticalPoint,
        'whoExamined': value.whoExamined,
    };
}

/**
* @export
* @enum {string}
*/
export enum GetExamDtoTypeEnum {
    Initial = 'Initial',
    Periodic = 'Periodic',
    Control = 'Control',
    PeriodicControl = 'PeriodicControl'
}
/**
* @export
* @enum {string}
*/
export enum GetExamDtoTakenCareOfEnum {
    Employee = 'Employee',
    Outwork = 'Outwork',
    Student = 'Student',
    OwnRequest = 'OwnRequest'
}
/**
* @export
* @enum {string}
*/
export enum GetExamDtoHealthcareEnum {
    Doctor = 'Doctor',
    Dentist = 'Dentist',
    Midwife = 'Midwife',
    Nurse = 'Nurse'
}
/**
* @export
* @enum {string}
*/
export enum GetExamDtoWhoExaminedEnum {
    Student = 'Student',
    Employee = 'Employee'
}


