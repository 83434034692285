import saveAs from 'file-saver';

import { DefaultApi } from '../autogenerated/spdApiClient/apis/DefaultApi';
import { GetExaminedDto } from '../autogenerated/spdApiClient/models/GetExaminedDto';
import { ApiResponse } from '../autogenerated/spdApiClient';

export type ExaminedEntity = GetExaminedDto;

export interface ExportContextInterface {
  exportTest: (id: string) => Promise<void>;
}

export const ExportContext = (api: DefaultApi) => {
  const handleResponse = async (response: ApiResponse<void>) => {
    const file = await response.raw.blob();
    saveAs(
      file,
      response.raw.url.slice(response.raw.url.lastIndexOf('/') + 1) ||
        'eksport.xlsx',
    );
  };

  return {
    exportTest: async (ident: string) =>
      api.exportControllerExportTestResultsRaw({ ident }).then(handleResponse),
  };
};
