import React from 'react';

export const Landing: React.FC = () => {
  const productBackground = '#539A61';
  const developBackground = '#4d7298';

  const backgroundColor =
    process.env.NODE_ENV === 'development'
      ? developBackground
      : productBackground;

  return (
    <div className="pb-5">
      <div className="main-banner" style={{ backgroundColor: backgroundColor }}>
        <h1 className="anime-title mb-5">
          Specjalistyczna Platforma Diagnostyczna
        </h1>
      </div>
    </div>
  );
};
