// eslint-disable-next-line import/named
import { UserCredential } from 'firebase/auth';
import React, { useEffect, useRef, useState } from 'react';

import { useAuth } from '../../../common/hooks/useAuth';
import { useSpdCore } from '../../../common/hooks/useSpdCore';
import { useNavigation } from '../../../common/navigation';
import { GetUserDto } from '../../../common/spdCore/autogenerated/spdApiClient';
import ValidationAlert from '../ValidationAlert';
import { userData } from '../../../common/spdCore/contexts/UserContext';

import { Register } from './Register';

export type Props = {
  onRegister: (usr: string, pwd: string) => Promise<UserCredential | void>;
};

export const RegisterDialog: React.FC<Props> = ({ onRegister }) => {
  const nav = useNavigation();
  const spdApi = useSpdCore();
  const auth = useAuth();

  const mountedRef = useRef(false);
  const [showError, setShowError] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [userCred, setUserCred] = useState<UserCredential | void>();
  const [user, setUser] = useState<GetUserDto | void>();

  const [needUpdate, setNeedUpdate] = useState<boolean>(false);

  useEffect(() => {
    mountedRef.current = true;
    if (needUpdate && userCred && user) {
      setNeedUpdate(false);
      user.uid = userCred.user.uid;
      auth.updateProfile(user);
      spdApi.users
        .updateGet(user?.id, userData(user))
        .then(() => {
          nav.startPage();
        })
        .catch(error => {
          void auth.logout();
          setErrorText(
            'Błąd podczas rejestracji: ' + (error as Response).statusText,
          );
          setShowError(true);
        });
    }
    return () => {
      mountedRef.current = false;
    };
  }, [needUpdate, auth, nav, spdApi.users, userCred, user]);

  const handleRegister = async (usr: string, pwd: string) => {
    try {
      const dbUser = await spdApi.users.getByEmail(usr);
      if (dbUser) {
        const firebaseUser = await onRegister(usr, pwd);
        await new Promise(f => setTimeout(f, 1000));
        if (mountedRef.current) {
          setUser(dbUser);
          setUserCred(firebaseUser);
          setNeedUpdate(true);
        }
      }
    } catch (err) {
      setErrorText('Błąd podczas rejestracji: ' + (err as Error).message);
      setShowError(true);
    }
  };

  return (
    <>
      <ValidationAlert
        heading={'REJESTRACJA'}
        variant={'warning'}
        show={showError}
        modal={true}
        onHide={() => {
          setShowError(false);
        }}
        errors={[errorText]}
      />

      <Register onRegister={handleRegister} />
    </>
  );
};
