/* tslint:disable */
/* eslint-disable */
/**
 * SPD
 * Opis api SPD
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetSavedPdfSignatureStatusDto
 */
export interface GetSavedPdfSignatureStatusDto {
    /**
     * 
     * @type {string}
     * @memberof GetSavedPdfSignatureStatusDto
     */
    pdfTemplateId: string;
    /**
     * 
     * @type {string}
     * @memberof GetSavedPdfSignatureStatusDto
     */
    status: string;
}

export function GetSavedPdfSignatureStatusDtoFromJSON(json: any): GetSavedPdfSignatureStatusDto {
    return GetSavedPdfSignatureStatusDtoFromJSONTyped(json, false);
}

export function GetSavedPdfSignatureStatusDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSavedPdfSignatureStatusDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pdfTemplateId': json['pdfTemplateId'],
        'status': json['status'],
    };
}

export function GetSavedPdfSignatureStatusDtoToJSON(value?: GetSavedPdfSignatureStatusDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pdfTemplateId': value.pdfTemplateId,
        'status': value.status,
    };
}


