import React from 'react';

import { Grid } from '../../../common/components/Grid/Grid';
import {
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import { GetReportCompanyDto } from '../../../common/spdCore/autogenerated/spdApiClient/models/GetReportCompanyDto';

import { ReportCompaniesDetails } from './ReportCompaniesDetails';

interface ReportCompaniesSearchProps {
  getData: GridGetterFunction;
  getCount: GridCountFunction;
  onRowClick?: (key: string) => Promise<void> | void | undefined;
  refresh?: boolean;
  enableSingleSelect?: boolean;
  selectedKeys?: string[];
  withHealthcareFacility?: boolean;
  withRegister?: boolean;
}

export const reportCompanyForGrid = (
  reportCompany: GetReportCompanyDto,
  dateFrom?: Date,
  dateTo?: Date,
  onlyMonthlySettlement?: boolean,
) => {
  return {
    key: reportCompany.id,
    values: {
      company_name: reportCompany.companyName,
      register_name: reportCompany.registerName || '',
      healthcare_facility_name: reportCompany.healthcareFacilityName,
      judgmentCount: reportCompany.countJudgments,
      details: (
        <ReportCompaniesDetails
          companyId={reportCompany.companyId}
          registerId={reportCompany.registerId}
          healthcareFacilityId={reportCompany.healthcareFacilityId}
          dateFrom={dateFrom}
          dateTo={dateTo}
          onlyMonthlySettlement={onlyMonthlySettlement}
        />
      ),
    },
  };
};

export const ReportCompaniesSearch: React.FC<
  ReportCompaniesSearchProps
> = props => {
  const columns = [
    { header: 'Firma', property: 'company_name' },
    ...(props.withRegister
      ? [{ header: 'Rejestr', property: 'register_name' }]
      : []),
    ...(props.withHealthcareFacility
      ? [{ header: 'Placówka', property: 'healthcare_facility_name' }]
      : []),

    { header: 'Liczba orzeczeń', property: 'judgmentCount', noSorting: true },
    { header: 'Szczegóły', property: 'details', noSorting: true },
  ];

  return (
    <Grid
      getData={props.getData}
      getDataCount={props.getCount}
      columns={columns}
      showFilter={true}
      initialOrderColumn="company_name, register_name, healthcare_facility_name"
      initialOrderDirection="ASC"
      availablePageSizes={[10, 20, 50]}
      pageSize={20}
      onRowClick={props.onRowClick}
      emptyGridMessage="Brak orzeczeń w systemie"
      refresh={props.refresh}
      enableSingleSelect={props.enableSingleSelect}
      selectedKeys={props.selectedKeys}
    />
  );
};
