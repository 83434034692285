/* tslint:disable */
/* eslint-disable */
/**
 * SPD
 * Opis api SPD
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUpdateKeyValueDto
 */
export interface CreateUpdateKeyValueDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateKeyValueDto
     */
    keyTestId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateKeyValueDto
     */
    taskNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateKeyValueDto
     */
    taskIdent?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateKeyValueDto
     */
    answer?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CreateUpdateKeyValueDto
     */
    weight?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateUpdateKeyValueDto
     */
    param1?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateUpdateKeyValueDto
     */
    param2?: number | null;
}

export function CreateUpdateKeyValueDtoFromJSON(json: any): CreateUpdateKeyValueDto {
    return CreateUpdateKeyValueDtoFromJSONTyped(json, false);
}

export function CreateUpdateKeyValueDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateKeyValueDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'keyTestId': !exists(json, 'keyTestId') ? undefined : json['keyTestId'],
        'taskNumber': !exists(json, 'taskNumber') ? undefined : json['taskNumber'],
        'taskIdent': !exists(json, 'taskIdent') ? undefined : json['taskIdent'],
        'answer': !exists(json, 'answer') ? undefined : json['answer'],
        'weight': !exists(json, 'weight') ? undefined : json['weight'],
        'param1': !exists(json, 'param1') ? undefined : json['param1'],
        'param2': !exists(json, 'param2') ? undefined : json['param2'],
    };
}

export function CreateUpdateKeyValueDtoToJSON(value?: CreateUpdateKeyValueDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'keyTestId': value.keyTestId,
        'taskNumber': value.taskNumber,
        'taskIdent': value.taskIdent,
        'answer': value.answer,
        'weight': value.weight,
        'param1': value.param1,
        'param2': value.param2,
    };
}


