import { companyForGrid } from '../../../spd/components/Company/CompanySearch';
import { GridRecord } from '../../components/Grid/GridDataTypes';
import { DefaultApi } from '../autogenerated/spdApiClient/apis/DefaultApi';
import {
  CreateUpdateCompanyDto,
  CreateUpdateCompanyDtoFromJSON,
} from '../autogenerated/spdApiClient/models/CreateUpdateCompanyDto';
import { GetCompanyDto } from '../autogenerated/spdApiClient/models/GetCompanyDto';
import {
  Option,
  defaultSelectorLimit,
} from '../../../spd/components/Selects/Selector';
import { validateAgainst } from '../validation/validateAgainst';
import { CompanySchema } from '../validation/schemas';

export type CompanyEntity = GetCompanyDto;
export type Company = GetCompanyDto | CreateUpdateCompanyDto;

export interface CompanyContextInterface {
  get: (id: string) => Promise<CompanyEntity>;
  getByUserUid: (userUid: string) => Promise<CompanyEntity>;
  getAll: () => Promise<CompanyEntity[]>;
  getAllByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
  ) => Promise<GridRecord[]>;
  getAllCount: (filterText?: string) => Promise<number>;
  getOptionsByName: (text: string) => Promise<Option[]>;
  getOptionsByNip: (text: string) => Promise<Option[]>;
  getOptionsByEmail: (text: string) => Promise<Option[]>;
  update: (id: string, dto: CreateUpdateCompanyDto) => Promise<void>;
  create: (dto: CreateUpdateCompanyDto) => Promise<GetCompanyDto>;
}

export const CompanyContext = (api: DefaultApi) => {
  return {
    get: async (id: string) => {
      return await api.companyControllerGet(id);
    },

    getByUserUid: async (userUid: string) => {
      return await api.companyControllerGetByUserUid(userUid);
    },

    getAll: async () => {
      return await api.companyControllerGetAll();
    },

    getAllByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
    ) => {
      const data = await api.companyControllerGetAllByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
      );
      return data.map(companyForGrid);
    },

    getAllCount: (filterText?: string) =>
      api.companyControllerGetAllCount(filterText || ''),

    getOptionsByName: async (text: string) => {
      const data = await api.companyControllerGetByPattern(
        text,
        defaultSelectorLimit,
      );

      return Array.from(data, company => {
        return {
          value: company.id,
          label: company.name + ' ' + (company.nip ? company.nip + ' ' : ''),
        };
      });
    },

    getOptionsByNip: async (text: string) => {
      const data = await api.companyControllerGetByNip(
        text,
        defaultSelectorLimit,
      );

      return Array.from(data, company => {
        return {
          value: company.id,
          label: company.nip + ' ' + company.name,
        };
      });
    },

    getOptionsByEmail: async (text: string) => {
      const data = await api.companyControllerGetByEmail(
        text,
        defaultSelectorLimit,
      );

      return Array.from(data, company => {
        return {
          value: company.id,
          label: company.email + ' (' + company.nip + ' ' + company.name + ')',
        };
      });
    },

    update: (id: string, dto: CreateUpdateCompanyDto) =>
      api.companyControllerUpdate(id, dto),

    create: (dto: CreateUpdateCompanyDto) => api.companyControllerCreate(dto),
  };
};

export const validate = (test: CreateUpdateCompanyDto) =>
  validateAgainst(CompanySchema, test);

export const companyData = (
  company?: GetCompanyDto,
): CreateUpdateCompanyDto => {
  return CreateUpdateCompanyDtoFromJSON({
    ...company,
    branchId: company?.branch?.id,
    healthcareFacilityId: company?.healthcareFacility?.id,
  });
};

export const partial = (property: 'name' | 'nip' | 'email', value: string) => {
  const obj: Partial<Company> = {};
  obj[property] = value;
  return obj;
};
