import { DefaultApi } from '../autogenerated/spdApiClient/apis/DefaultApi';
import {
  CreateUpdateIndicatorTestDto,
  CreateUpdateIndicatorTestDtoFromJSON,
  GetIndicatorTestDto,
  GetIndicatorTestRecalculatedDto,
} from '../autogenerated/spdApiClient';
import { validateAgainst } from '../validation/validateAgainst';
import { IndicatorTestSchema } from '../validation/schemas';
import { indicatorTestForGrid } from '../../../spd/components/IndicatorTests/IndicatorTests';
import { GridRecord } from '../../components/Grid/GridDataTypes';
import { CreateUpdateImportedIndicatorTestDto } from '../autogenerated/spdApiClient/models/CreateUpdateImportedIndicatorTestDto';

export type IndicatorTestEntity = GetIndicatorTestDto;

export interface IndicatorTestContextInterface {
  get: (id: string) => Promise<IndicatorTestEntity>;
  getByExamTest: (id: string) => Promise<GetIndicatorTestRecalculatedDto[]>;
  getAllCount: (filterText?: string) => Promise<number>;
  getAllByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
  ) => Promise<GridRecord[]>;
  update: (id: string, dto: CreateUpdateIndicatorTestDto) => Promise<void>;
  create: (dto: CreateUpdateIndicatorTestDto) => Promise<GetIndicatorTestDto>;
  createImported: (
    dto: CreateUpdateImportedIndicatorTestDto,
  ) => Promise<GetIndicatorTestDto>;
}

export const IndicatorTestContext = (api: DefaultApi) => {
  return {
    get: async (id: string) => {
      return await api.indicatorTestControllerGet(id);
    },

    getByExamTest: async (id: string) => {
      return await api.indicatorTestControllerGetByExamTest(id);
    },

    getAllByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
    ) => {
      const data = await api.indicatorTestControllerGetAllByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
      );
      return data.map(indicatorTestForGrid);
    },

    getAllCount: (filterText?: string) =>
      api.indicatorTestControllerGetAllCount(filterText || ''),

    update: (id: string, dto: CreateUpdateIndicatorTestDto) =>
      api.indicatorTestControllerUpdate(id, dto),

    create: (dto: CreateUpdateIndicatorTestDto) =>
      api.indicatorTestControllerCreate(dto),

    createImported: (dto: CreateUpdateImportedIndicatorTestDto) =>
      api.indicatorTestControllerCreateImported(dto),
  };
};

export const validate = (test: CreateUpdateIndicatorTestDto) =>
  validateAgainst(IndicatorTestSchema, test);

export const indicatorTestData = (
  test?: GetIndicatorTestDto,
): CreateUpdateIndicatorTestDto => {
  return CreateUpdateIndicatorTestDtoFromJSON({
    ...test,
  });
};
