import React, { useState, useEffect } from 'react';
import ToastContainer from 'react-bootstrap/ToastContainer';
import Toast from 'react-bootstrap/Toast';

export default (props: {
  message?: string;
  setMessage: (msg: string) => void;
}) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (!props.message) {
      return;
    }
    setShow(true);
  }, [props.message]);

  return (
    <>
      {show && (
        <ToastContainer position="top-center" className="p-3">
          <Toast
            onClose={() => {
              setShow(false);
              props.setMessage('');
            }}
            show={show}
            bg={'danger'}
          >
            <Toast.Header>
              <strong className="me-auto">Uwaga</strong>
            </Toast.Header>
            <Toast.Body className="text-white">{props.message}</Toast.Body>
          </Toast>
        </ToastContainer>
      )}
    </>
  );
};
