import { DefaultApi } from '../autogenerated/spdApiClient/apis/DefaultApi';
import { GetDutyDto } from '../autogenerated/spdApiClient';
import { GridRecord } from '../../components/Grid/GridDataTypes';
import { dutyForGrid } from '../../../spd/components/Duty/DutySearch';
import { validateAgainst } from '../validation/validateAgainst';
import {
  CreateUpdateDutyDto,
  CreateUpdateDutyDtoFromJSON,
} from '../autogenerated/spdApiClient/models/CreateUpdateDutyDto';
import { DutySchema } from '../validation/schemas';

export interface DutyContextInterface {
  get: (id: string) => Promise<GetDutyDto>;
  getAll: () => Promise<GetDutyDto[]>;
  getAllCount: (filterText?: string) => Promise<number>;
  getAllByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
  ) => Promise<GridRecord[]>;
  update: (id: string, dto: CreateUpdateDutyDto) => Promise<void>;
  create: (dto: CreateUpdateDutyDto) => Promise<GetDutyDto>;
}

export const DutyContext = (api: DefaultApi) => {
  return {
    get: async (id: string) => {
      return await api.dutyControllerGet(id);
    },

    getAll: () => api.dutyControllerGetAll(),

    getAllByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
    ) => {
      const data = await api.dutyControllerGetAllByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
      );
      return data.map(dutyForGrid);
    },

    getAllCount: (filterText?: string) =>
      api.dutyControllerGetAllCount(filterText || ''),

    update: (id: string, dto: CreateUpdateDutyDto) =>
      api.dutyControllerUpdate(id, dto),

    create: (dto: CreateUpdateDutyDto) => api.dutyControllerCreate(dto),
  };
};

export const validate = (duty: CreateUpdateDutyDto) => {
  return validateAgainst(DutySchema, duty);
};

export const dutyData = (duty?: GetDutyDto): CreateUpdateDutyDto => {
  return CreateUpdateDutyDtoFromJSON({
    ...duty,
    psychologistUID: duty?.psychologist?.uid,
    doctorUID: duty?.doctor?.uid,
  });
};
