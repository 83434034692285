/* eslint-disable complexity */
/*eslint max-lines-per-function: ["error", 230]*/
import React, { useState } from 'react';
import { Alert, Button, Card, Form, Tab, Tabs } from 'react-bootstrap';

import FormRow from '../../../app/components/FormRow';
import {
  CreateUpdateCardDto,
  CreateUpdateCardDtoTypeEnum,
  CreateUpdateCardDtoPdfTemplateTypeEnum,
  GetExamDto,
} from '../../../common/spdCore/autogenerated/spdApiClient';
import { useSpdCore } from '../../../common/hooks/useSpdCore';
import momencik from '../../../common/momencik';
import {
  FormControlTextarea10,
  FormControlTextarea16,
  FormControlTextarea3,
} from '../../../app/components/FormControlTextarea';
import { CardType } from '../../../common/spdCore/validation/schemas';
import { ChooseSigned } from '../Users/ChooseSigned';
import { Role } from '../../../common/spdCore/authorization/roles';
import ConfirmationButton from '../../../app/components/ConfirmationButton';
import { responseErrors } from '../../../common/spdCore/validation/responseErrors';

interface CardPsychologicalEditorProps {
  exam: GetExamDto;
  card: CreateUpdateCardDto;
  id: string | undefined;
  weapon: boolean;
}

export const CardPsychologicalEditor: React.FC<
  CardPsychologicalEditorProps
> = props => {
  const spd = useSpdCore();
  const [card, setCard] = useState<CreateUpdateCardDto>(props.card);
  const [id, setId] = useState<string | undefined>(props.id);
  const [message, setMessage] = useState<string>('');

  const createCard = async (gCard: CreateUpdateCardDto) => {
    setMessage('');
    try {
      if (id) {
        await spd.cards.update(id, gCard);
        setCard(gCard);
        await spd.pdfPrinter.saveAndPrintCard(id);
      } else {
        await spd.cards
          .create({
            ...gCard,
            ...{
              examId: props?.exam?.id,
            },
          })
          .then(async o => {
            setCard({
              ...o,
              ...{
                ...gCard,
                ...{
                  pdfTemplateType:
                    o.pdfTemplateType as unknown as CreateUpdateCardDtoPdfTemplateTypeEnum,
                  examId: props?.exam?.id,
                  type: (props.weapon
                    ? CardType.PsychologistWeapon
                    : CardType.Psychologist) as unknown as CreateUpdateCardDtoTypeEnum,
                },
              },
            });
            setId(o.id);
            await spd.pdfPrinter.saveAndPrintCard(o.id);
          })
          .catch(err => {
            void responseErrors(err).then(errors => {
              setMessage(`Błąd podczas zapisywania karty badania:\n ${errors}`);
            });
          });
      }
    } catch (response) {
      const error = await responseErrors(response as Response);
      setMessage(`Błąd podczas zapisywania karty badania:\n ${error}`);
    }
  };

  const sendEmail = async (id: string) => {
    spd.emails
      .sendPsychologicalCardSummary(id)
      .then(() => setMessage(`Wysłano wiadomość e-mail`))
      .catch(() =>
        setMessage(
          `Wystąpił błąd podczas wysyłania podsumowania badania psychologicznego do badanego.`,
        ),
      );
  };

  const propertyChange = (obj: Partial<CreateUpdateCardDto>) =>
    setCard({ ...card, ...obj });

  return (
    <Card className="mb-2">
      <Card.Header>{`Karta badania psychologicznego ${
        props.weapon ? 'na broń ' : ''
      }${props.exam?.number}`}</Card.Header>
      <Card.Body>
        <Form className="d-grid gap-3">
          <Tabs defaultActiveKey="justification" id="data" className="mb-2">
            <Tab eventKey="justification" title="Uzasadnienie" className="mb-2">
              <div className="d-grid gap-3">
                <FormRow controlId="pass" label="Wynik pozytywny">
                  <Form.Check
                    type="checkbox"
                    defaultChecked={
                      card?.pass ||
                      card?.pass === undefined ||
                      card?.pass === null
                        ? true
                        : false
                    }
                    onChange={e => propertyChange({ pass: e.target.checked })}
                  />
                </FormRow>
                <FormRow controlId="staff" label="Psycholog">
                  <ChooseSigned
                    userUID={card?.psychologistUID}
                    handleOnChange={user =>
                      setCard({
                        ...card,
                        psychologistUID: user?.uid,
                      })
                    }
                    roles={[
                      Role.PsychologistAssessor,
                      Role.PsychologistSpecialist,
                    ]}
                  />
                </FormRow>
                <FormRow controlId="number" label="Numer">
                  <Form.Control
                    type="text"
                    defaultValue={card?.number || ''}
                    onChange={e =>
                      propertyChange({
                        number: e.target.value,
                      })
                    }
                  />
                </FormRow>
                <FormRow controlId="justification" label="Uzasadnienie">
                  <FormControlTextarea10
                    defaultValue={card?.justification || ''}
                    onChange={e =>
                      propertyChange({ justification: e.target.value })
                    }
                  />
                </FormRow>
              </div>
            </Tab>
            <Tab eventKey="interview" title="Wywiad" className="mb-2">
              <FormRow controlId="interview" label="Wywiad">
                <FormControlTextarea16
                  defaultValue={card?.interview || ''}
                  onChange={e => propertyChange({ interview: e.target.value })}
                />
              </FormRow>
            </Tab>
            <Tab eventKey="physical" title="Wnioski">
              <div className="d-grid gap-3">
                <FormRow
                  controlId="intellectualAbility"
                  label="Sprawność intelektualna"
                >
                  <FormControlTextarea3
                    defaultValue={card?.intellectualAbility || ''}
                    onChange={e =>
                      propertyChange({ intellectualAbility: e.target.value })
                    }
                  />
                </FormRow>
                <FormRow controlId="personality" label="Osobowość">
                  <FormControlTextarea3
                    defaultValue={card?.personality || ''}
                    onChange={e =>
                      propertyChange({ personality: e.target.value })
                    }
                  />
                </FormRow>
                <FormRow
                  controlId="psychomotorEfficiency"
                  label="Sprawność psychomotoryczna"
                >
                  <FormControlTextarea3
                    defaultValue={card?.psychomotorEfficiency || ''}
                    onChange={e =>
                      propertyChange({ psychomotorEfficiency: e.target.value })
                    }
                  />
                </FormRow>
                <FormRow controlId="conclusions" label="Wnioski">
                  <FormControlTextarea3
                    defaultValue={card?.conclusions || ''}
                    onChange={e =>
                      propertyChange({ conclusions: e.target.value })
                    }
                  />
                </FormRow>
                <FormRow controlId="recommendations" label="Zalecenia">
                  <FormControlTextarea3
                    defaultValue={card?.recommendations || ''}
                    onChange={e =>
                      propertyChange({ recommendations: e.target.value })
                    }
                  />
                </FormRow>
                <FormRow controlId="postDate" label="Data wysłania">
                  <Form.Control
                    type="date"
                    name="datepic"
                    placeholder="DateRange"
                    value={momencik(card?.postDate, 'YYYY-MM-DD')}
                    onChange={e =>
                      propertyChange({ postDate: new Date(e.target.value) })
                    }
                  />
                </FormRow>
              </div>
            </Tab>
          </Tabs>
          <Button
            variant="outline-primary"
            className="ms-1"
            onClick={() => createCard(card)}
          >
            Zapisz i wydrukuj
          </Button>
          {id && (
            <ConfirmationButton
              className="mx-1"
              confirmation="Czy na pewno przesłać informację o wyniku przeprowadzonego badania psychologicznego do badanego?"
              variant="outline-primary"
              onOK={() => sendEmail(id)}
            >
              Wyślij wynik do badanego
            </ConfirmationButton>
          )}
          {message && <Alert variant={'warning'}>{message}</Alert>}
        </Form>
      </Card.Body>
    </Card>
  );
};
