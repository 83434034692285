import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Card, Form, Tab } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import { FormRow210 } from '../../../app/components/FormRow';
import Tabs from '../../../app/components/Tabs';
import { useSpdCore } from '../../../common/hooks/useSpdCore';
import {
  GetKeyTestDto,
  GetKeyValueDto,
} from '../../../common/spdCore/autogenerated/spdApiClient';
import { KeyValues } from '../../components/KeyTests/KeyValues';

type ParamTypes = {
  id: string;
};

export const KeyTestView: React.FC = () => {
  const spd = useSpdCore();
  const { id } = useParams<ParamTypes>();
  const mountedRef = useRef(false);
  const [keyValues, setKeyValues] = useState<GetKeyValueDto[]>([]);
  const [keyTest, setKeyTest] = useState<GetKeyTestDto>();

  const safeSet = (set: () => void) => {
    if (mountedRef.current) {
      set();
    }
  };

  const refresh = useCallback(() => {
    if (!mountedRef.current || !id) {
      return;
    }
    spd.keyValues
      .getByKey(id)
      .then(kv => {
        safeSet(() => setKeyValues(kv));
      })
      .catch(error => {
        console.error('Wystąpił problem z pobraniem wartości klucza:', error);
      });
  }, [spd, id]);

  useEffect(() => {
    mountedRef.current = true;
    refresh();
    return () => {
      mountedRef.current = false;
    };
  }, [id, spd.keyValues, refresh]);

  useEffect(() => {
    if (id) {
      spd.keyTests
        .get(id)
        .then(kT => setKeyTest(kT))
        .catch(error =>
          console.log(`Wystąpił problem z pobraniem klucza. ${error}`),
        );
    }
  }, [spd.keyTests, id]);

  return (
    <Card>
      <Card.Header>Klucz testu</Card.Header>
      <Card.Body>
        <Form className="d-grid mb-3 gap-3">
          <FormRow210 controlId="examined" label="Nazwa klucza">
            <Form.Control
              type="text"
              defaultValue={`${keyTest?.name || ''}`}
              readOnly
            />
          </FormRow210>
          <FormRow210 controlId="examined" label="Nazwa testu">
            <Form.Control
              type="text"
              defaultValue={`${keyTest?.test?.name || ''}`}
              readOnly
            />
          </FormRow210>

          <Card>
            <Tabs defaultActiveKey="search" id="keyValues" className="mb-3">
              <Tab eventKey="search" title="Wartości klucza" className="m-3">
                <KeyValues data={keyValues} />
              </Tab>
            </Tabs>
          </Card>
        </Form>
      </Card.Body>
    </Card>
  );
};
