import React, { useState } from 'react';

import { useSpdCore } from '../../../common/hooks/useSpdCore';

import { JudgmentsSearch } from './JudgmentsSearch';

export const JudgmentsList: React.FC = () => {
  const spd = useSpdCore();
  const [refresh, setRefresh] = useState<boolean>(false);

  const handleClick = async (id: string) => {
    await spd.judgments.get(id);
  };

  const onRefresh = () => {
    setRefresh(!refresh);
  };

  return (
    <>
      <JudgmentsSearch
        getData={(pageSize, pageNumber, orderBy, orderDirection, filterText) =>
          spd.judgments.getAllByPortion(
            pageSize,
            pageNumber,
            orderBy,
            orderDirection,
            filterText,
            onRefresh,
          )
        }
        getCount={spd.judgments.getAllCount}
        onRowClick={handleClick}
        refresh={refresh}
      />
    </>
  );
};
