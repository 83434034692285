import React from 'react';

import { GetArticleDto } from '../../../common/spdCore/autogenerated/spdApiClient/models/GetArticleDto';
import { Grid } from '../../../common/components/Grid/Grid';
import { ArticleModeText } from '../../../common/spdCore/validation/schemas';

interface ArticlesProps {
  data?: GetArticleDto[];
  onRowClick: (key: string) => Promise<void> | void | undefined;
  refresh?: boolean;
  enableMultiSelect?: boolean;
  selectedKeys?: string[];
}

export const articleForGrid = (article: GetArticleDto) => {
  return {
    key: article.id,
    values: {
      'article.lp': article.lp,
      'article.description': article.description,
      'article.legalBasis': article.legalBasis,
      'article.reason': article.reason,
      'article.mode':
        ArticleModeText[article.mode as keyof typeof ArticleModeText],
      'register.name': article.register?.name,
    },
    orderByValues: {
      'article.lp': String(article.lp).padStart(10, '0'),
    },
  };
};

export const Article: React.FC<ArticlesProps> = props => {
  const columns = [
    { header: 'Lp', property: 'article.lp' },
    { header: 'Opis', property: 'article.description' },
    { header: 'Podstawa prawna', property: 'article.legalBasis' },
    { header: 'Powód', property: 'article.reason' },
    { header: 'Obsługuje', property: 'article.mode' },
    { header: 'Rejestr', property: 'register.name' },
  ];

  return (
    <Grid
      data={props.data && props.data.map(d => articleForGrid(d))}
      columns={columns}
      showFilter={true}
      initialOrderColumn="article.lp"
      availablePageSizes={[10, 20, 50]}
      onRowClick={props.onRowClick}
      emptyGridMessage="Brak podstaw prawnych w systemie"
      refresh={props.refresh}
      enableMultiSelect={props.enableMultiSelect}
      selectedKeys={props.selectedKeys}
    />
  );
};
