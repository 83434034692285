import React, { useEffect, useState } from 'react';
import Select from 'react-select';

import { useAuth } from '../../../common/hooks/useAuth';
import { GetUserDto } from '../../../common/spdCore/autogenerated/spdApiClient';
import { Role } from '../../../common/spdCore/authorization/roles';
import { useSpdCore } from '../../../common/hooks/useSpdCore';

interface ChooseSignedProps {
  userUID?: string | null;
  handleOnChange: (user: GetUserDto | undefined) => void;
  roles: Role[];
  isDisabled?: boolean;
  withoutDefaultValue?: boolean;
}

export const ChooseSigned: React.FC<ChooseSignedProps> = props => {
  const auth = useAuth();
  const spd = useSpdCore();

  const [signed, setSigned] = useState<GetUserDto>();
  const [users, setUsers] = useState<GetUserDto[]>([]);
  const [allStaffs, setAllStaffs] = useState<
    { value: string | undefined; label: string }[]
  >([]);

  useEffect(() => {
    spd.users
      .getAllByRoles(props.roles)
      .then(u => {
        setAllStaffs(
          u.map(st => {
            return {
              value: st.uid || undefined,
              label: st.name || '',
            };
          }),
        );
        setUsers(u);
      })
      .catch(error =>
        console.log(`Wystąpił problem z pobraniem użytkowników. ${error}`),
      );
  }, [spd.users, props.roles]);

  useEffect(() => {
    let s = users.find(u => u.uid === props.userUID);
    if (!s && !props.withoutDefaultValue) {
      s = users.find(u => u.uid === auth.currentUser?.uid);
    }
    if (s && s !== signed) {
      setSigned(s);
      if (!props.userUID) {
        props.handleOnChange(s);
      }
    }
  }, [users, auth.currentUser?.uid, signed, props]);

  return (
    <Select
      options={allStaffs}
      value={{
        value: signed?.uid || undefined,
        label: signed?.name || 'Wybierz',
      }}
      onChange={options =>
        props.handleOnChange(users.find(u => u.uid === options?.value))
      }
      isDisabled={props.isDisabled}
    />
  );
};
