import {
  DefaultApi,
  GetDocumentationDto,
  GetVersionInfoDto,
} from '../autogenerated/spdApiClient';

export interface DocumentationContextInterface {
  getForFilename: (filename: string) => Promise<GetDocumentationDto>;
  getVersionInfo: () => Promise<GetVersionInfoDto>;
}

export const DocumentationContext = (api: DefaultApi) => {
  return {
    getForFilename: (filename: string) =>
      api.documentationControllerGetForFilename(filename),
    getVersionInfo: () => api.documentationControllerGetVersionInfo(),
  };
};
