import React from 'react';
import { Card, Tab } from 'react-bootstrap';

import Tabs from '../../../app/components/Tabs';
import { useSpdCore } from '../../../common/hooks/useSpdCore';
import { Access } from '../../components/Access/Access';

export const AccessView: React.FC = () => {
  const spd = useSpdCore();

  return (
    <Card>
      <Tabs defaultActiveKey="search" id="accesses" className="mb-3">
        <Tab eventKey="search" title="Dostępy" className="m-3">
          <Access
            getData={spd.accesses.getAllByPortion}
            getCount={spd.accesses.getAllCount}
          />
        </Tab>
      </Tabs>
    </Card>
  );
};
