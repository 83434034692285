import React from 'react';

import { Grid } from '../../../common/components/Grid/Grid';
import { GetIndicatorTestRecalculatedDto } from '../../../common/spdCore/autogenerated/spdApiClient';
import { IndicatorTestModeText } from '../../../common/spdCore/validation/schemas';

interface IndicatorTestsInExamProps {
  data: GetIndicatorTestRecalculatedDto[];
}

export const IndicatorTestsInExam: React.FC<
  IndicatorTestsInExamProps
> = props => {
  const columns = [
    { header: 'Nazwa', property: 'name' },
    { header: 'Typ wskaźnika', property: 'indicatorMode' },
    { header: 'Wartość', property: 'result' },
    { header: 'Zestaw norm', property: 'normGroup' },
    { header: 'Wynik przeliczony', property: 'recalculatedResult' },
    { header: 'Interpretacja wyniku', property: 'interpretedResult' },
  ];
  // eslint-disable-next-line complexity
  const data = props.data.map(indicatorTest => {
    return {
      key: indicatorTest.id,
      values: {
        name: indicatorTest.name,
        indicatorMode:
          IndicatorTestModeText[
            indicatorTest.mode as keyof typeof IndicatorTestModeText
          ],
        result:
          indicatorTest.result === undefined || indicatorTest.result === null
            ? ''
            : indicatorTest.result,
        normGroup: indicatorTest.normName || '',
        recalculatedResult:
          indicatorTest.recalculatedResult === undefined ||
          indicatorTest.recalculatedResult === null
            ? ''
            : indicatorTest.recalculatedResult,
        interpretedResult: indicatorTest.interpretedResult || '',
      },
      orderByValues: {
        taskNumber: indicatorTest.name || '',
      },
    };
  });

  return (
    <Grid
      data={data}
      columns={columns}
      showFilter={true}
      initialOrderColumn="indicatorTest.name"
      initialOrderDirection="ASC"
      availablePageSizes={[10, 20, 50]}
      emptyGridMessage="Brak wskaźników do testu"
    />
  );
};
