import React from 'react';

import { GetSupportingExaminationDto } from '../../../common/spdCore/autogenerated/spdApiClient/models/GetSupportingExaminationDto';
import { Grid } from '../../../common/components/Grid/Grid';
import momencik from '../../../common/momencik';
import { SupportingType } from '../../../common/spdCore/validation/schemas';

interface SupportingExaminationProps {
  data?: GetSupportingExaminationDto[];
  onRowClick: (key: string) => Promise<void> | void | undefined;
  refresh?: boolean;
  enableMultiSelect?: boolean;
  selectedKeys?: string[];
  type: SupportingType;
}

export const supportingExaminationForGrid = (
  supportingExamination: GetSupportingExaminationDto,
) => {
  return {
    key: supportingExamination.id,
    values: {
      'supportingExamination.lp': supportingExamination.lp,
      'examKindDict.name': supportingExamination.examKindDict?.name,
      'supportingExamination.examKind': supportingExamination.examKind,
      'supportingExamination.referralDate': momencik(
        supportingExamination.referralDate,
      ),
      'supportingExamination.consultationDate': momencik(
        supportingExamination.consultationDate,
      ),
      'supportingExamination.result': supportingExamination.result,
      'supportingExamination.printReferral': supportingExamination.printReferral
        ? 'TAK'
        : 'NIE',
    },
    orderByValues: {
      'supportingExamination.lp': String(supportingExamination.lp).padStart(
        10,
        '0',
      ),
    },
  };
};

export const SupportingExamination: React.FC<
  SupportingExaminationProps
> = props => {
  const columns = [
    { header: 'Lp', property: 'supportingExamination.lp' },
    {
      header: 'Skierowanie do specjalisty',
      property: 'examKindDict.name',
    },
    {
      header: 'Opis',
      property: 'supportingExamination.examKind',
    },
    {
      header: 'Data skierowania',
      property: 'supportingExamination.referralDate',
    },
    {
      header: 'Data konsultacji',
      property: 'supportingExamination.consultationDate',
    },
    { header: 'Wynik konsultacji', property: 'supportingExamination.result' },
  ];

  const columns2 = [
    { header: 'Lp', property: 'supportingExamination.lp' },
    {
      header: 'Skierowanie w systemie',
      property: 'supportingExamination.printReferral',
    },
    {
      header: 'Rodzaj badania',
      property: 'examKindDict.name',
    },
    {
      header: 'Opis',
      property: 'supportingExamination.examKind',
    },
    {
      header: 'Data skierowania',
      property: 'supportingExamination.referralDate',
    },
    {
      header: 'Data wykonania',
      property: 'supportingExamination.consultationDate',
    },
    {
      header: 'Wyniki badania istotne dla rodzaju czynnika narażenia',
      property: 'supportingExamination.result',
    },
  ];

  return (
    <Grid
      data={props.data && props.data.map(d => supportingExaminationForGrid(d))}
      columns={
        props.type === SupportingType.specialisedConsultation
          ? columns
          : columns2
      }
      initialOrderColumn="supportingExamination.lp"
      availablePageSizes={[10, 20, 50]}
      onRowClick={props.onRowClick}
      emptyGridMessage={
        props.type === SupportingType.helpExam
          ? 'Brak badań pomocniczych w badaniu'
          : 'Brak konsultacji specjalistycznych w badaniu'
      }
      refresh={props.refresh}
      enableMultiSelect={props.enableMultiSelect}
      selectedKeys={props.selectedKeys}
    />
  );
};
