import React from 'react';
import { Form, Card, Button } from 'react-bootstrap';
import Select from 'react-select';

import FormRow from '../../../app/components/FormRow';
import momencik from '../../../common/momencik';
import { CreateUpdateExamWithTestsDto } from '../../../common/spdCore/autogenerated/spdApiClient';
import {
  KindOfScience,
  KindOfScienceText,
} from '../../../common/spdCore/validation/schemas';

interface Props {
  prevStep?: () => void;
  nextStep?: () => void;
  handleFormData?: (input: string) => (e: string | boolean | Date) => void;

  schoolCandidate?: boolean;
  kindOfScience?: KindOfScience;
  schoolName?: string;
  schoolAddress?: string;
  fieldOfStudy?: string;
  endScience?: Date;
  propertyChange?: (obj: Partial<CreateUpdateExamWithTestsDto>) => void;
}

export const AddStudent: React.FC<Props> = ({
  prevStep,
  nextStep,
  handleFormData,
  schoolCandidate,
  kindOfScience,
  schoolName,
  schoolAddress,
  fieldOfStudy,
  endScience,
  propertyChange,
}) => {
  const handleOnNext = () => {
    nextStep && nextStep();
  };

  const handleOnPrev = () => {
    prevStep && prevStep();
  };

  const propertyChangeWithHandleFormData = (
    fieldName: string,
    value: boolean | string | Date,
  ) => {
    handleFormData && handleFormData(fieldName)(value);
    propertyChange && propertyChange({ [fieldName]: value });
  };

  const getSelectedKindOfScience = () => {
    return getAllKindOfScience().filter(item =>
      kindOfScience?.split(',').includes(item.value),
    );
  };

  const getAllKindOfScience = () => {
    const arr = Object.keys(KindOfScience).map(name => {
      return {
        value: name,
        label: KindOfScienceText[name as keyof typeof KindOfScience],
      };
    });
    return arr;
  };

  return (
    <>
      <Card>
        <Card.Body>
          <Card.Subtitle className="mb-4">Placówka dydaktyczna</Card.Subtitle>
          <Form className="d-grid gap-3">
            <FormRow controlId="school_candidate" label="Kandydat">
              <Form.Check
                type="checkbox"
                checked={schoolCandidate ? true : false}
                onChange={e =>
                  propertyChangeWithHandleFormData(
                    'schoolCandidate',
                    e.target.checked,
                  )
                }
              />
            </FormRow>
            <FormRow
              controlId="kindOfScience"
              label="Rodzaj nauki lub kształcenia"
            >
              <Select
                options={getAllKindOfScience()}
                value={getSelectedKindOfScience()}
                isMulti
                className="test-multi-select"
                classNamePrefix="driving-select"
                onChange={options =>
                  propertyChangeWithHandleFormData(
                    'kindOfScience',
                    options.map(o => o.value).join(','),
                  )
                }
              />
            </FormRow>
            <FormRow controlId="schoolName" label="Nazwa placówki dydaktycznej">
              <Form.Control
                as="textarea"
                rows={3}
                value={schoolName || ''}
                onChange={e =>
                  propertyChangeWithHandleFormData('schoolName', e.target.value)
                }
              />
            </FormRow>
            <FormRow
              controlId="exam_description"
              label="Adres placówki dydaktycznej"
            >
              <Form.Control
                as="textarea"
                rows={3}
                value={schoolAddress || ''}
                onChange={e =>
                  propertyChangeWithHandleFormData(
                    'schoolAddress',
                    e.target.value,
                  )
                }
              />
            </FormRow>
            <FormRow
              controlId="field_of_study"
              label="Kierunek praktycznej nauki zawodu lub kształcenia"
            >
              <Form.Control
                as="textarea"
                rows={3}
                value={fieldOfStudy || ''}
                onChange={e =>
                  propertyChangeWithHandleFormData(
                    'fieldOfStudy',
                    e.target.value,
                  )
                }
              />
            </FormRow>
            <FormRow controlId="endDateScience" label="Data końca nauki">
              <Form.Control
                type="date"
                name="endScience"
                placeholder="DateRange"
                value={momencik(endScience, 'YYYY-MM-DD')}
                onChange={e =>
                  propertyChangeWithHandleFormData(
                    'endScience',
                    new Date(e.target.value),
                  )
                }
              />
            </FormRow>

            {prevStep && nextStep && (
              <div className="d-flex justify-content-end m-3">
                <Button
                  variant="outline-secondary"
                  className="ms-1"
                  onClick={handleOnPrev}
                >
                  Wstecz
                </Button>

                <Button
                  variant="outline-primary"
                  className="ms-1"
                  onClick={handleOnNext}
                >
                  Dalej
                </Button>
              </div>
            )}
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};
