import React from 'react';
import { Card, Tab } from 'react-bootstrap';

import Tabs from '../../../app/components/Tabs';
import { ExaminedList } from '../../components/Examined/ExaminedList';

export const ExaminedsView: React.FC = () => {
  return (
    <Card>
      <Tabs defaultActiveKey="search" id="examineds" className="mb-3">
        <Tab eventKey="search" title="Badani" className="m-3">
          <ExaminedList withAttachments={true} />
        </Tab>
      </Tabs>
    </Card>
  );
};
