/*eslint max-lines-per-function: ["error", 200]*/
/* eslint-disable complexity */
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Card, Form } from 'react-bootstrap';

import ValidationAlert from '../../../app/components/ValidationAlert';
import { CreateUpdateCompanyDto } from '../../../common/spdCore/autogenerated/spdApiClient/models/CreateUpdateCompanyDto';
import FormRow from '../../../app/components/FormRow';
import { validate } from '../../../common/spdCore/contexts/CompanyContext';
import BatteryMultiSelector from '../Battery/BatteryMultiSelector';
import { ReactSelectOption } from '../Selects/SortableSelect';
import { GetBatteryDto } from '../../../common/spdCore/autogenerated/spdApiClient';
import HealthcareFacilitySelector from '../Selects/HealthcareFacilitySelector';

import CompanyByNameSelector from './CompanyByNameSelector';

interface CompanyEditorProps {
  id?: string;
  company: CreateUpdateCompanyDto;
  batteries?: GetBatteryDto[];
  handleClose?: () => void;
  show?: boolean;
  onCompleted?: (
    company: CreateUpdateCompanyDto,
    id?: string,
  ) => Promise<{
    saved: boolean;
    errors: string[];
  }>;
  readOnly: boolean;
}

export const CompanyEditor: React.FC<CompanyEditorProps> = props => {
  const [company, setCompany] = useState(props.company);
  const [showSaveErrors, setShowSaveErrors] = useState(false);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const [batteries, setBatteries] = useState<ReactSelectOption[]>([]);

  useEffect(() => {
    if (props.show) {
      setSaveErrors([]);
      setShowSaveErrors(false);
      const bats =
        props.batteries?.map(b => ({ value: b.id, label: b.name || '' })) || [];
      setBatteries(bats);
      setCompany({ ...props.company, batteries: bats.map(o => o.value) });
    }
  }, [props.show, props.company, props.batteries]);

  const createCompany = async () => {
    validate(company)
      .then(status => {
        if (status.valid && props.onCompleted) {
          props
            .onCompleted(company, props.id)
            .then(saveStatus => {
              if (!saveStatus.saved) {
                setSaveErrors(saveStatus.errors);
                setShowSaveErrors(true);
                return;
              }
            })
            .catch(error => {
              console.error('Error during add company:', error);
            });
        } else {
          setSaveErrors(status.errors);
          setShowSaveErrors(true);
        }
      })
      .catch(() => {
        console.log('Wystąpił błąd');
      });
  };

  const propertyChange = (obj: Partial<CreateUpdateCompanyDto>) => {
    setCompany({ ...company, ...obj });
  };

  const setTitle = () => {
    return props.id && props.onCompleted
      ? 'Edycja firmy'
      : props.id
      ? 'Podgląd firmy'
      : 'Dodawanie nowej firmy';
  };

  const handleClick = async (tempBatteries: ReactSelectOption[]) => {
    setCompany({ ...company, batteries: tempBatteries.map(o => o.value) });
    setBatteries(tempBatteries);
  };

  return (
    <>
      <Modal
        onHide={props.handleClose}
        show={props.show}
        centered={true}
        keyboard={true}
        backdrop="static"
        size="xl"
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onClick={(event: any) => event.stopPropagation()}
      >
        <Modal.Header closeButton>
          <Modal.Title>{setTitle()}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card className="mb-2">
            <Card.Header>Firma</Card.Header>
            <Card.Body>
              <Form className="d-grid gap-3">
                <FormRow controlId="companyName" label="Nazwa">
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={company?.name || ''}
                    onChange={e => propertyChange({ name: e.target.value })}
                    disabled={props.readOnly}
                  />
                </FormRow>
                <FormRow controlId="address" label="Adres">
                  <Form.Control
                    type="text"
                    value={company?.address || ''}
                    onChange={e => propertyChange({ address: e.target.value })}
                    disabled={props.readOnly}
                  />
                </FormRow>
                <FormRow controlId="companyNIP" label="NIP">
                  <Form.Control
                    type="text"
                    value={company?.nip || ''}
                    onChange={e => propertyChange({ nip: e.target.value })}
                    disabled={props.readOnly}
                  />
                </FormRow>
                <FormRow controlId="email" label="Email">
                  <Form.Control
                    type="text"
                    value={company?.email || ''}
                    onChange={e => propertyChange({ email: e.target.value })}
                    disabled={props.readOnly}
                  />
                </FormRow>
                <FormRow
                  controlId="monthlySettlement"
                  label="Rozliczana miesięcznie"
                >
                  <Form.Check
                    type="checkbox"
                    checked={company?.monthlySettlement ? true : false}
                    onChange={e =>
                      propertyChange({ monthlySettlement: e.target.checked })
                    }
                  />
                </FormRow>
                <FormRow
                  controlId="externalReferral"
                  label="Wystawiają skierowania w systemie"
                >
                  <Form.Check
                    type="checkbox"
                    checked={company?.externalReferral ? true : false}
                    onChange={e =>
                      propertyChange({ externalReferral: e.target.checked })
                    }
                  />
                </FormRow>
                <FormRow controlId="healthcareFacility" label="Placówka">
                  <HealthcareFacilitySelector
                    value={company?.healthcareFacilityId || ''}
                    onChange={e =>
                      propertyChange({ healthcareFacilityId: e.value })
                    }
                  />
                </FormRow>
                <FormRow controlId="branch" label="Filia firmy">
                  <CompanyByNameSelector
                    value={company?.branchId || ''}
                    onChange={e => propertyChange({ branchId: e.value })}
                  />
                </FormRow>
                <FormRow controlId="battery" label="Bateria testów">
                  <BatteryMultiSelector
                    selectedBattery={batteries}
                    onChange={options =>
                      handleClick((options || []).map(o => o))
                    }
                  />
                </FormRow>
                <FormRow controlId="active" label="Aktywna">
                  <Form.Check
                    type="checkbox"
                    checked={company?.active ? true : false}
                    onChange={e => propertyChange({ active: e.target.checked })}
                  />
                </FormRow>
              </Form>
            </Card.Body>
          </Card>
          <ValidationAlert
            show={showSaveErrors}
            errors={saveErrors}
            className="mt-3"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={props.handleClose}>
            Zamknij
          </Button>
          {props.onCompleted && (
            <Button variant="outline-primary" onClick={createCompany}>
              Zapisz
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};
