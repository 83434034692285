import React from 'react';
import { Card, Tab } from 'react-bootstrap';

import Tabs from '../../../app/components/Tabs';
import { useSpdCore } from '../../../common/hooks/useSpdCore';
import { RegisterSearch } from '../../components/Register/RegisterSearch';

export const RegistersView: React.FC = () => {
  const spd = useSpdCore();

  return (
    <Card>
      <Tabs defaultActiveKey="search" id="registers" className="mb-3">
        <Tab eventKey="search" title="Rejestry" className="m-3">
          <RegisterSearch
            getData={spd.registers.getAllByPortion}
            getCount={spd.registers.getAllCount}
          />
        </Tab>
      </Tabs>
    </Card>
  );
};
