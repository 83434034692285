import React, { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';

import { useSpdCore } from '../../../common/hooks/useSpdCore';
import { GetHealthcareFacilityDto } from '../../../common/spdCore/autogenerated/spdApiClient';

import { Selector, Option } from './Selector';

export interface HealthcareFacilitySelectorProps {
  readOnly?: boolean;
  value?: string;
  placeholder?: string;
  className?: string;
  onChange?: (option: Option) => void;
  clearable?: boolean;
}

export default (props: HealthcareFacilitySelectorProps) => {
  const spd = useSpdCore();
  const [healthcareFacilities, setHealthcareFacilities] = useState<
    GetHealthcareFacilityDto[]
  >([]);
  const [displayName, setDisplayName] = useState<string>();
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (props.value) {
      spd.healthCareFacilities
        .get(props.value || '')
        .then(healthcare => {
          setDisplayName(healthcare.displayName || '');
        })
        .catch(error =>
          console.log(`Wystąpił problem z pobraniem placówki. ${error}`),
        );
    } else {
      setDisplayName('');
    }
  }, [props.value, spd.healthCareFacilities]);

  useEffect(() => {
    let mounted = true;
    spd.healthCareFacilities
      .getAll()
      .then(healthcare => {
        if (mounted) {
          setHealthcareFacilities(
            healthcare.sort((a, b) =>
              (a.displayName || '').localeCompare(b.displayName || ''),
            ),
          );
          setReady(true);
        }
      })
      .catch(error =>
        console.log(`Wystąpił problem z pobraniem placówek. ${error}`),
      );

    return () => {
      mounted = false;
    };
  }, [spd.healthCareFacilities]);

  const provider = async (text: string) => {
    const values = healthcareFacilities.filter(v => {
      const name = v.displayName || '';
      return name.toLocaleLowerCase().includes(text.toLocaleLowerCase());
    });

    return values.map(v => {
      const userNames = v.displayName;
      return {
        label: userNames || '',
        value: v.id,
      };
    });
  };

  return ready ? (
    <Selector
      uniqueKey={'healthcareFacilities-' + JSON.stringify(healthcareFacilities)}
      className={props.className}
      readOnly={props.readOnly}
      creatable={false}
      placeholder={props.placeholder || 'Wskaż...'}
      provider={provider}
      value={displayName}
      onChange={props.onChange}
      clearable={props.clearable}
    />
  ) : (
    <Skeleton />
  );
};
