import React from 'react';
import { Button, Card } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import { useSpdCore } from '../../../common/hooks/useSpdCore';
import { JudgmentsSearch } from '../../components/Judgments/JudgmentsSearch';

type ParamTypes = {
  psychologistId?: string;
  companyId?: string;
  registerId?: string;
  healthcareFacilityId?: string;
  dateFromYear?: string;
  dateFromMonth?: string;
  dateFromDay?: string;
  dateToYear?: string;
  dateToMonth?: string;
  dateToDay?: string;
  onlyMonthlySettlement?: string;
};

export const ReportCompaniesDetailsView: React.FC = () => {
  const spd = useSpdCore();

  const {
    psychologistId,
    companyId,
    registerId,
    healthcareFacilityId,
    dateFromYear,
    dateFromMonth,
    dateFromDay,
    dateToYear,
    dateToMonth,
    dateToDay,
    onlyMonthlySettlement,
  } = useParams<ParamTypes>() as {
    psychologistId?: string;
    companyId?: string;
    registerId?: string;
    healthcareFacilityId?: string;
    dateFromYear?: string;
    dateFromMonth?: string;
    dateFromDay?: string;
    dateToYear?: string;
    dateToMonth?: string;
    dateToDay?: string;
    onlyMonthlySettlement?: string;
  };

  const handleOnExport = async () => {
    await spd.pdfPrinter.printReportJudgments(
      '',
      psychologistId !== 'undefined' ? psychologistId : undefined,
      companyId !== 'undefined' ? companyId : undefined,
      registerId !== 'undefined' ? registerId : undefined,
      healthcareFacilityId !== 'undefined' ? healthcareFacilityId : undefined,
      dateFromYear && dateFromYear !== 'undefined'
        ? new Date(
            Number(dateFromYear),
            Number(dateFromMonth),
            Number(dateFromDay),
          )
        : undefined,
      dateToYear && dateToYear !== 'undefined'
        ? new Date(Number(dateToYear), Number(dateToMonth), Number(dateToDay))
        : undefined,
      onlyMonthlySettlement,
    );
  };

  return (
    <>
      <Card>
        <Card.Header>{'Zestawienie firm'}</Card.Header>
        <Card.Body>
          <Button variant="outline-primary" onClick={handleOnExport}>
            Eksportuj
          </Button>
          <JudgmentsSearch
            getData={(
              pageSize,
              pageNumber,
              orderBy,
              orderDirection,
              filterText,
            ) =>
              spd.reports.getCompaniesDetailsByPortion(
                pageSize,
                pageNumber,
                orderBy,
                orderDirection,
                filterText,
                psychologistId !== 'undefined' ? psychologistId : undefined,
                companyId !== 'undefined' ? companyId : undefined,
                registerId !== 'undefined' ? registerId : undefined,
                healthcareFacilityId !== 'undefined'
                  ? healthcareFacilityId
                  : undefined,
                dateFromYear && dateFromYear !== 'undefined'
                  ? new Date(
                      Number(dateFromYear),
                      Number(dateFromMonth),
                      Number(dateFromDay),
                    )
                  : undefined,
                dateToYear && dateToYear !== 'undefined'
                  ? new Date(
                      Number(dateToYear),
                      Number(dateToMonth),
                      Number(dateToDay),
                    )
                  : undefined,
                onlyMonthlySettlement,
              )
            }
            getCount={filterText =>
              spd.reports.getCompaniesDetailsCount(
                filterText,
                psychologistId !== 'undefined' ? psychologistId : undefined,
                companyId !== 'undefined' ? companyId : undefined,
                registerId !== 'undefined' ? registerId : undefined,
                healthcareFacilityId !== 'undefined'
                  ? healthcareFacilityId
                  : undefined,
                dateFromYear && dateFromYear !== 'undefined'
                  ? new Date(
                      Number(dateFromYear),
                      Number(dateFromMonth),
                      Number(dateFromDay),
                    )
                  : undefined,
                dateToYear && dateToYear !== 'undefined'
                  ? new Date(
                      Number(dateToYear),
                      Number(dateToMonth),
                      Number(dateToDay),
                    )
                  : undefined,
                onlyMonthlySettlement,
              )
            }
          />
        </Card.Body>
      </Card>
    </>
  );
};
