import React, { useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import * as XLSX from 'xlsx';

import ValidationAlert from '../../../app/components/ValidationAlert';
import LoadingSpinner from '../../../common/components/Waiting/LoadingSpinner';
import { responseErrors } from '../../../common/spdCore/validation/responseErrors';

import {
  InterpretationXls,
  NormXls,
  validateInterpretations,
  validateNorms,
} from './ImportNormValidator';

interface ImportNormProps {
  show: boolean;
  handleClose: () => void;
  onImport: (
    norm: NormXls[],
    interpretations: InterpretationXls[],
  ) => Promise<{
    saved: boolean;
    error: string;
  }>;
}

export const ImportNorm: React.FC<ImportNormProps> = props => {
  const [loading, setLoading] = useState(false);
  const [showSaveErrors, setShowSaveErrors] = useState(false);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);

  const setFile = (input: HTMLInputElement) => {
    setLoading(true);
    setShowSaveErrors(false);
    const fileList = input.files;

    if (!fileList) {
      setLoading(false);
      return;
    }
    const file = fileList[0];
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);
    fileReader.onload = async e => {
      const target = e.target;
      try {
        const data = target?.result;
        const workbook = XLSX.read(data);
        const normsXls = workbook.Sheets[workbook.SheetNames[0]];
        const interpretationsXls = workbook.Sheets[workbook.SheetNames[1]];
        const normsJson = XLSX.utils.sheet_to_json(normsXls, {
          header: [
            'nazwa_normy',
            'grupa_norm',
            'prawdopodobienstwo',
            'liczba_osob',
            'opis',
            'nazwa_wskaznika',
            'typ_wskaznika',
            'nazwa_klucza',
            'rodzaj',
            'wynik_surowy_od',
            'wynik_surowy_do',
            'wynik_przeliczony',
          ],
        });
        const interpretationsJson = XLSX.utils.sheet_to_json(
          interpretationsXls,
          {
            header: [
              'interpretacja_wynik',
              'interpretacja_zakres_od',
              'interpretacja_zakres_do',
              'interpretacja_opis',
            ],
          },
        );
        const norms = normsJson as unknown as NormXls[];
        let result = validateNorms(norms);
        if (!result.ok) {
          setSaveErrors(result.errors);
          setShowSaveErrors(true);
          setLoading(false);
          return;
        }
        const interpretations =
          interpretationsJson as unknown as InterpretationXls[];
        result = validateInterpretations(interpretations);
        if (!result.ok) {
          setSaveErrors(result.errors);
          setShowSaveErrors(true);
          setLoading(false);
          return;
        }
        props
          .onImport(norms, interpretations)
          .then(status => {
            setLoading(false);
            if (status.saved) {
              clearError();
              return;
            }
          })
          .catch(async response => {
            const errors = await responseErrors(response as Response);
            setShowSaveErrors(true);
            setSaveErrors([
              'Błąd przy imporcie norm [' + errors.join(';') + ']',
            ]);
            setLoading(false);
          });
      } catch (response) {
        const errors = await responseErrors(response as Response);
        setShowSaveErrors(true);
        setSaveErrors(['Błąd przy imporcie norm [' + errors.join(';') + ']']);
        setLoading(false);
      }
    };
    input.value = '';
  };

  const clearError = () => {
    setSaveErrors(['']);
    setShowSaveErrors(false);
  };

  const handleClose = () => {
    clearError();
    props.handleClose();
  };

  return (
    <>
      <Modal
        onHide={handleClose}
        onClose={handleClose}
        show={props.show}
        centered={true}
        keyboard={true}
        backdrop="static"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Import normy</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>Wczytaj normy z pliku Excel</Form.Label>
            <Form.Control
              type="file"
              accept=".xlsx, .xls"
              onChange={e => setFile(e.target as HTMLInputElement)}
              onClick={() => clearError()}
            />
            {loading && (
              <Form.Label className="mt-3">
                <LoadingSpinner />
                Trwa import norm...
              </Form.Label>
            )}
          </Form.Group>
          <ValidationAlert
            show={showSaveErrors}
            errors={saveErrors}
            className="mt-3"
          />
        </Modal.Body>
      </Modal>
    </>
  );
};
