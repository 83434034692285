/* tslint:disable */
/* eslint-disable */
/**
 * SPD
 * Opis api SPD
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetExaminedDto
 */
export interface GetExaminedDto {
    /**
     * 
     * @type {string}
     * @memberof GetExaminedDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetExaminedDto
     */
    surname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetExaminedDto
     */
    firstname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetExaminedDto
     */
    pesel?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetExaminedDto
     */
    iDCardNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetExaminedDto
     */
    iDCardType?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof GetExaminedDto
     */
    dateOfBirth?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof GetExaminedDto
     */
    education?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetExaminedDto
     */
    drivingLicense?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetExaminedDto
     */
    sex?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetExaminedDto
     */
    street?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetExaminedDto
     */
    numbers?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetExaminedDto
     */
    city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetExaminedDto
     */
    postcode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetExaminedDto
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetExaminedDto
     */
    phone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetExaminedDto
     */
    postalAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetExaminedDto
     */
    learnedProfession?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetExaminedDto
     */
    drivingLicenseIssuer?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetExaminedDto
     */
    drivingLicenseYear?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetExaminedDto
     */
    nationality?: string | null;
}

export function GetExaminedDtoFromJSON(json: any): GetExaminedDto {
    return GetExaminedDtoFromJSONTyped(json, false);
}

export function GetExaminedDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetExaminedDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'surname': !exists(json, 'surname') ? undefined : json['surname'],
        'firstname': !exists(json, 'firstname') ? undefined : json['firstname'],
        'pesel': !exists(json, 'pesel') ? undefined : json['pesel'],
        'iDCardNumber': !exists(json, 'iDCardNumber') ? undefined : json['iDCardNumber'],
        'iDCardType': !exists(json, 'iDCardType') ? undefined : json['iDCardType'],
        'dateOfBirth': !exists(json, 'dateOfBirth') ? undefined : (json['dateOfBirth'] === null ? null : new Date(json['dateOfBirth'])),
        'education': !exists(json, 'education') ? undefined : json['education'],
        'drivingLicense': !exists(json, 'drivingLicense') ? undefined : json['drivingLicense'],
        'sex': !exists(json, 'sex') ? undefined : json['sex'],
        'street': !exists(json, 'street') ? undefined : json['street'],
        'numbers': !exists(json, 'numbers') ? undefined : json['numbers'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'postcode': !exists(json, 'postcode') ? undefined : json['postcode'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'postalAddress': !exists(json, 'postalAddress') ? undefined : json['postalAddress'],
        'learnedProfession': !exists(json, 'learnedProfession') ? undefined : json['learnedProfession'],
        'drivingLicenseIssuer': !exists(json, 'drivingLicenseIssuer') ? undefined : json['drivingLicenseIssuer'],
        'drivingLicenseYear': !exists(json, 'drivingLicenseYear') ? undefined : json['drivingLicenseYear'],
        'nationality': !exists(json, 'nationality') ? undefined : json['nationality'],
    };
}

export function GetExaminedDtoToJSON(value?: GetExaminedDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'surname': value.surname,
        'firstname': value.firstname,
        'pesel': value.pesel,
        'iDCardNumber': value.iDCardNumber,
        'iDCardType': value.iDCardType,
        'dateOfBirth': value.dateOfBirth === undefined ? undefined : (value.dateOfBirth === null ? null : value.dateOfBirth.toISOString()),
        'education': value.education,
        'drivingLicense': value.drivingLicense,
        'sex': value.sex,
        'street': value.street,
        'numbers': value.numbers,
        'city': value.city,
        'postcode': value.postcode,
        'email': value.email,
        'phone': value.phone,
        'postalAddress': value.postalAddress,
        'learnedProfession': value.learnedProfession,
        'drivingLicenseIssuer': value.drivingLicenseIssuer,
        'drivingLicenseYear': value.drivingLicenseYear,
        'nationality': value.nationality,
    };
}


