import { DefaultApi } from '../autogenerated/spdApiClient/apis/DefaultApi';
import {
  CreateUpdateExamTestDto,
  CreateUpdateExamTestDtoFromJSON,
  GetExamTestDto,
} from '../autogenerated/spdApiClient';
import { examTestsForGrid } from '../../../spd/components/ExamTests/ExamTests';
import { GridRecord } from '../../components/Grid/GridDataTypes';

export type ExamTestEntity = GetExamTestDto;

export interface ExamTestContextInterface {
  get: (id: string) => Promise<ExamTestEntity>;
  getByExam: (examId: string) => Promise<ExamTestEntity[]>;
  getForExamByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
    filterId?: string,
  ) => Promise<GridRecord[]>;
  getForExamCount: (filterText?: string, filterId?: string) => Promise<number>;
  update: (id: string, dto: CreateUpdateExamTestDto) => Promise<void>;
  create: (dto: CreateUpdateExamTestDto) => Promise<GetExamTestDto>;
}

export const ExamTestContext = (api: DefaultApi) => {
  return {
    get: async (id: string) => {
      return await api.examTestControllerGet(id);
    },

    getByExam: async (examId: string) => {
      return await api.examTestControllerGetByExam(examId);
    },

    getForExamByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
      filterId?: string,
    ) => {
      const data = await api.examTestControllerGetForExamByPortion(
        filterId || '',
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
      );
      return data.map(examTestsForGrid);
    },

    getForExamCount: (filterText?: string, filterId?: string) =>
      api.examTestControllerGetForExamCount(filterId || '', filterText || ''),

    update: (id: string, dto: CreateUpdateExamTestDto) =>
      api.examTestControllerUpdate(id, dto),

    create: (dto: CreateUpdateExamTestDto) => api.examTestControllerCreate(dto),
  };
};

export const examData = (exam?: GetExamTestDto): CreateUpdateExamTestDto => {
  return CreateUpdateExamTestDtoFromJSON({
    ...exam,
  });
};
