import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

import { useNavigation } from '../../../common/navigation';
import { ConsultationType } from '../../../common/spdCore/validation/schemas';

export const ConsultationsLink: React.FC<{
  examId: string;
  type: ConsultationType;
}> = props => {
  const nav = useNavigation();
  return (
    <OverlayTrigger overlay={<Tooltip>Edycja konsultacji</Tooltip>}>
      <>
        {props.type === ConsultationType.Neurological && (
          <Button
            onClick={e => {
              e.stopPropagation();
              nav.consultationNeurological(props.examId);
            }}
            variant="btn-info"
          >
            <Icon.CardText />
          </Button>
        )}
      </>
    </OverlayTrigger>
  );
};
