import { DefaultApi } from '../autogenerated/spdApiClient/apis/DefaultApi';
import {
  CreateUpdateExamsPackageDto,
  CreateUpdateExamsPackageDtoFromJSON,
  GetExamsPackageDto,
} from '../autogenerated/spdApiClient';
import { GridRecord } from '../../components/Grid/GridDataTypes';
import { ExamPackageType } from '../validation/schemas';
import { examsPackageForGrid } from '../../../spd/components/ExamsPackage/ExamsPackageSearch';

export interface ExamsPackageContextInterface {
  get: (id: string) => Promise<GetExamsPackageDto>;
  getAllMulitipleByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
    setRefresh?: () => void,
  ) => Promise<GridRecord[]>;
  getAllMulitipleCount: (filterText?: string) => Promise<number>;
  getAllSingleByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
    setRefresh?: () => void,
  ) => Promise<GridRecord[]>;
  getAllSingleCount: (filterText?: string) => Promise<number>;
  deleteExpectationExams: (id: string) => Promise<void>;
  getExpectationPins: (id: string) => Promise<string>;
  update: (id: string, dto: CreateUpdateExamsPackageDto) => Promise<void>;
}

export const ExamsPackageContext = (api: DefaultApi) => {
  return {
    get: async (id: string) => {
      return await api.examsPackageControllerGet(id);
    },

    getAllMulitipleByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
      setRefresh?: () => void,
    ) => {
      const data = await api.examsPackageControllerGetAllByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
        ExamPackageType.Mulitiple,
      );
      return data.map(exPac => examsPackageForGrid(exPac, setRefresh));
    },

    getAllMulitipleCount: (filterText?: string) =>
      api.examsPackageControllerGetAllCount(
        filterText || '',
        ExamPackageType.Mulitiple,
      ),

    getAllSingleByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
      setRefresh?: () => void,
    ) => {
      const data = await api.examsPackageControllerGetAllByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
        ExamPackageType.Single,
      );
      return data.map(exPac => examsPackageForGrid(exPac, setRefresh));
    },

    getAllSingleCount: (filterText?: string) =>
      api.examsPackageControllerGetAllCount(
        filterText || '',
        ExamPackageType.Single,
      ),

    deleteExpectationExams: (examPackageId: string) =>
      api.examsPackageControllerDeleteExpectationExams(examPackageId),

    getExpectationPins: (examPackageId: string) =>
      api.examsPackageControllerGetExpectationPins(examPackageId),

    update: (id: string, dto: CreateUpdateExamsPackageDto) =>
      api.examsPackageControllerUpdate(id, dto),
  };
};

export const examsPackageData = (
  exPa?: GetExamsPackageDto,
): CreateUpdateExamsPackageDto => {
  return CreateUpdateExamsPackageDtoFromJSON({
    ...exPa,
  });
};
