import { DefaultApi } from '../autogenerated/spdApiClient/apis/DefaultApi';
import { GetResultDto } from '../autogenerated/spdApiClient';

export type ResultEntity = GetResultDto;

export interface ResultContextInterface {
  getByExamTestId: (id: string) => Promise<GetResultDto[]>;
}

export const ResultContext = (api: DefaultApi) => {
  return {
    getByExamTestId: async (id: string) => {
      return await api.resultControllerGetByExamTestId(id);
    },
  };
};
