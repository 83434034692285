/*eslint max-lines-per-function: ["error", 300]*/
/* eslint-disable complexity */
import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

import { useSpdCore } from '../../../common/hooks/useSpdCore';
import { Role } from '../../../common/spdCore/authorization/roles';
import momencik from '../../../common/momencik';
import HealthcareFacilitySelector from '../../components/Selects/HealthcareFacilitySelector';
import UserSelector from '../../components/Selects/UserSelector';
import { useAuth } from '../../../common/hooks/useAuth';
import CompanyByNameSelector from '../../components/Company/CompanyByNameSelector';

export type FilterReportData = {
  dateFrom?: Date;
  dateTo?: Date;
  userUid?: string;
};

interface FilterProps {
  roles: Role[];
  withUser: boolean;
  withDate: boolean;
  withHealthcareFacility: boolean;
  withCompany?: boolean;
  withOnlyMonthlySettlement?: boolean;
  userUid?: string | undefined;
  headerName?: string;
  userName?: string;
  canClean?: boolean;
  emptyOnStart?: boolean;
  dateFromText?: string;
  dateToText?: string;
  dateFrom?: Date;
  onChangeDateFrom?: (dateFrom: Date | undefined) => void;
  onChangeDateTo?: (dateTo: Date | undefined) => void;
  onChangeUserUid?: (userUid: string | undefined) => void;
  onChangeCompanyId?: (userUid: string | undefined) => void;
  onChangeHealthcareFacilityId?: (
    healthcareFacilityId: string | undefined,
  ) => void;
  onChangeOnlyMonthlySettlement?: (onlyMonthlySettlement: boolean) => void;
  onExport?: (
    dateFrom: Date | undefined,
    dateTo: Date | undefined,
    userUid: string | undefined,
    healthcareFacilityId: string | undefined,
  ) => void;
}

export const Filter: React.FC<FilterProps> = props => {
  const spdApi = useSpdCore();
  const auth = useAuth();
  const [showNewAlert, setShowNewAlert] = useState(false);
  const startDate = new Date(
    (props.dateFrom || new Date()).getFullYear(),
    (props.dateFrom || new Date()).getMonth(),
    props.dateFrom ? props.dateFrom.getDate() : 1,
    0,
    0,
    0,
    0,
  );
  const [dateFrom, setDateFrom] = useState<Date | undefined>(
    new Date(startDate),
  );
  const [dateTo, setDateTo] = useState<Date | undefined>();
  const [userUid, setUserUid] = useState<string | undefined>(props.userUid);
  const [healthcareFacilityId, setHealthcareFacilityId] = useState<
    string | undefined
  >();
  const [onlyMonthlySettlement, setOnlyMonthlySettlement] = useState<
    boolean | undefined
  >(false);
  const [companyId, setCompanyId] = useState<string | undefined>();

  useEffect(() => {
    if (!healthcareFacilityId && !props.emptyOnStart) {
      spdApi.healthCareFacilities
        .getDefault()
        .then(hf => {
          setHealthcareFacilityId(hf.id);
          props.onChangeHealthcareFacilityId &&
            props.onChangeHealthcareFacilityId(hf.id);
        })
        .catch(error => {
          console.log('Nie udało się pobrać placówek: ', error);
        });
    }
  }, [spdApi.healthCareFacilities, props, healthcareFacilityId]);

  useEffect(() => {
    if (!props.emptyOnStart && props.onChangeUserUid) {
      spdApi.users
        .getAllByRoles(props.roles)
        .then(us => {
          const userAuth = us.find(u => u.uid === auth.currentUser?.uid);
          if (props.withUser && !props.userUid) {
            if (userAuth) {
              setUserUid(userAuth.uid ? userAuth.uid : undefined);
              props.onChangeUserUid &&
                props.onChangeUserUid(userAuth.uid || undefined);
            } else {
              setUserUid(us && us[0].uid ? us[0].uid : undefined);
              props.onChangeUserUid &&
                props.onChangeUserUid(us && us[0].uid ? us[0].uid : undefined);
            }
          }
        })
        .catch(error => {
          console.log('Nie udało się pobrać pracowników: ', error);
        });
    }
  }, [spdApi.users, auth.currentUser?.uid, props]);

  const handleClear = () => {
    setUserUid(undefined);

    setDateFrom(new Date(startDate));
    setDateTo(undefined);
    setHealthcareFacilityId(undefined);
    setCompanyId(undefined);
    setShowNewAlert(false);
    setOnlyMonthlySettlement(false);
  };

  const handleSearch = () => {
    if (dateFrom && dateTo && dateFrom > dateTo) {
      setShowNewAlert(true);
      return;
    }
    setShowNewAlert(false);

    props.onChangeDateFrom && props.onChangeDateFrom(dateFrom);
    props.onChangeDateTo && props.onChangeDateTo(dateTo);
    props.onChangeHealthcareFacilityId &&
      props.onChangeHealthcareFacilityId(healthcareFacilityId);
    props.withUser &&
      props.onChangeUserUid &&
      props.onChangeUserUid(
        userUid === '' || userUid === null ? undefined : userUid,
      );
    props.withCompany &&
      props.onChangeCompanyId &&
      props.onChangeCompanyId(companyId);

    props.withOnlyMonthlySettlement &&
      props.onChangeOnlyMonthlySettlement &&
      props.onChangeOnlyMonthlySettlement(onlyMonthlySettlement || false);
  };

  return (
    <Card className="mb-2">
      <Card.Body>
        <Form className="d-grid gap-3">
          {props.withUser && (
            <Row>
              <Form.Group as={Row} controlId="userName" readOnly={true}>
                <Form.Label column sm="3">
                  {props.userName}
                </Form.Label>
                <Col sm="9">
                  <UserSelector
                    readOnly={!auth?.roles?.includes(Role.Admin)}
                    value={userUid || ''}
                    roles={props.roles}
                    onChange={option => {
                      setUserUid(option.value || undefined);
                    }}
                    clearable={props.canClean}
                  />
                </Col>
              </Form.Group>
            </Row>
          )}
          {props.withOnlyMonthlySettlement && (
            <Row>
              <Form.Group as={Row} controlId="monthlySettlement">
                <Form.Label column sm="3">
                  Tylko firmy rozliczane miesięcznie
                </Form.Label>
                <Col sm="9">
                  <Form.Check
                    type="checkbox"
                    checked={onlyMonthlySettlement || false}
                    onChange={e => {
                      e.target.checked && setCompanyId(undefined);
                      setOnlyMonthlySettlement(e.target.checked);
                    }}
                  />
                </Col>
              </Form.Group>
            </Row>
          )}
          {props.withCompany && (
            <Row>
              <Form.Group as={Row} controlId="company">
                <Form.Label column sm="3">
                  Firma
                </Form.Label>
                <Col sm="9">
                  <CompanyByNameSelector
                    value={companyId}
                    onChange={e => setCompanyId(e.value || undefined)}
                    clearable={true}
                    readOnly={onlyMonthlySettlement || false}
                  />
                </Col>
              </Form.Group>
            </Row>
          )}
          {props.withHealthcareFacility && (
            <Row>
              <Form.Group as={Row} controlId="healthcareFacility">
                <Form.Label column sm="3">
                  Placówka
                </Form.Label>
                <Col sm="9">
                  <HealthcareFacilitySelector
                    value={healthcareFacilityId || ''}
                    onChange={option => {
                      setHealthcareFacilityId(option.value || undefined);
                    }}
                    clearable={props.canClean}
                  />
                </Col>
              </Form.Group>
            </Row>
          )}
          {props.withDate && (
            <>
              <Row>
                <Form.Group as={Row} controlId="dateFrom">
                  <Form.Label column sm="3">
                    {props.dateFromText || 'Data od'}
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      type="date"
                      name="datepic"
                      placeholder="DateRange"
                      value={momencik(dateFrom, 'YYYY-MM-DD')}
                      onChange={e =>
                        setDateFrom(
                          e.target.value ? new Date(e.target.value) : undefined,
                        )
                      }
                    />
                  </Col>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Row} controlId="dateTo">
                  <Form.Label column sm="3">
                    {props.dateToText || 'Data do'}
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      type="date"
                      name="datepic"
                      placeholder="DateRange"
                      value={momencik(dateTo, 'YYYY-MM-DD')}
                      onChange={e =>
                        setDateTo(
                          e.target.value ? new Date(e.target.value) : undefined,
                        )
                      }
                    />
                  </Col>
                </Form.Group>
              </Row>
            </>
          )}
        </Form>
        {showNewAlert && (
          <Alert variant="danger" className="mt-3">
            Zły zakres dat
          </Alert>
        )}
        <div className="d-flex justify-content-end mt-3">
          <Button className="me-2" variant="outline-dark" onClick={handleClear}>
            Wyczyść
          </Button>
          <Button
            className="me-2"
            variant="outline-primary"
            onClick={handleSearch}
          >
            Szukaj
          </Button>
          {props.onExport && (
            <Button
              variant="outline-primary"
              onClick={() =>
                props.onExport &&
                props.onExport(dateFrom, dateTo, userUid, healthcareFacilityId)
              }
            >
              Eksportuj
            </Button>
          )}
        </div>
      </Card.Body>
    </Card>
  );
};
