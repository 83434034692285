/* tslint:disable */
/* eslint-disable */
/**
 * SPD
 * Opis api SPD
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUpdatePositionNormDto
 */
export interface CreateUpdatePositionNormDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUpdatePositionNormDto
     */
    normId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdatePositionNormDto
     */
    taskNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdatePositionNormDto
     */
    mode?: CreateUpdatePositionNormDtoModeEnum;
    /**
     * 
     * @type {number}
     * @memberof CreateUpdatePositionNormDto
     */
    resultTrue?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateUpdatePositionNormDto
     */
    resultRawFrom?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateUpdatePositionNormDto
     */
    resultRawTo?: number | null;
}

export function CreateUpdatePositionNormDtoFromJSON(json: any): CreateUpdatePositionNormDto {
    return CreateUpdatePositionNormDtoFromJSONTyped(json, false);
}

export function CreateUpdatePositionNormDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdatePositionNormDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'normId': !exists(json, 'normId') ? undefined : json['normId'],
        'taskNumber': !exists(json, 'taskNumber') ? undefined : json['taskNumber'],
        'mode': !exists(json, 'mode') ? undefined : json['mode'],
        'resultTrue': !exists(json, 'resultTrue') ? undefined : json['resultTrue'],
        'resultRawFrom': !exists(json, 'resultRawFrom') ? undefined : json['resultRawFrom'],
        'resultRawTo': !exists(json, 'resultRawTo') ? undefined : json['resultRawTo'],
    };
}

export function CreateUpdatePositionNormDtoToJSON(value?: CreateUpdatePositionNormDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'normId': value.normId,
        'taskNumber': value.taskNumber,
        'mode': value.mode,
        'resultTrue': value.resultTrue,
        'resultRawFrom': value.resultRawFrom,
        'resultRawTo': value.resultRawTo,
    };
}

/**
* @export
* @enum {string}
*/
export enum CreateUpdatePositionNormDtoModeEnum {
    Centyl = 'centyl',
    Ten = 'ten',
    Sten = 'sten',
    Stanin = 'stanin',
    Przeliczony = 'przeliczony'
}


