import React from 'react';

import { Grid } from '../../../common/components/Grid/Grid';
import {
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import momencik from '../../../common/momencik';
import { GetExamsPackageDto } from '../../../common/spdCore/autogenerated/spdApiClient';
import { ExamPackageMode } from '../../../common/spdCore/validation/schemas';

import { ExamsPackagePinsLink } from './ExamsPackagePinsLink';
import { ExamsPackageEditLink } from './ExamsPackageEditLink';

interface ExamsPackageSearchProps {
  getData: GridGetterFunction;
  getCount: GridCountFunction;
  onRowClick: (key: string) => Promise<void> | void | undefined;
  refresh: boolean;
}

export const examsPackageForGrid = (
  examsPackage: GetExamsPackageDto,
  setRefresh?: () => void | undefined,
) => {
  return {
    key: examsPackage.id,
    values: {
      'examspackage.number': (
        <>
          {examsPackage.number}{' '}
          <ExamsPackageEditLink id={examsPackage.id} onRefresh={setRefresh} />
        </>
      ),
      'company.name': examsPackage.company?.name || '',
      'company.nip': examsPackage.company?.nip || '',
      'examspackage.sendCompanyEmail': examsPackage.sendCompanyEmail
        ? 'Tak'
        : '-',
      'examspackage.description': examsPackage.description,
      'examspackage.mode':
        (examsPackage.mode as unknown as ExamPackageMode) ===
        ExamPackageMode.Normalization
          ? 'Tak'
          : '-',
      'examspackage.registrationDate':
        momencik(examsPackage.registrationDate, 'YYYY.MM.DD HH:mm:ss') || '',
      examsCount: examsPackage.examsCount,
      examsFinishCount: examsPackage.examsFinishCount,
      examsExpectationCount: (
        <>
          {examsPackage.examsExpectationCount}{' '}
          <ExamsPackagePinsLink examsPackageId={examsPackage.id} />
        </>
      ),
    },
  };
};

export const ExamsPackageSearch: React.FC<ExamsPackageSearchProps> = props => {
  const columns = [
    { header: 'Numer', property: 'examspackage.number' },
    { header: 'Nazwa firmy', property: 'company.name' },
    { header: 'NIP firmy', property: 'company.nip' },
    { header: 'Opis', property: 'examspackage.description' },
    { header: 'Data rejestracji', property: 'examspackage.registrationDate' },
    { header: 'Wysyłka maili', property: 'examspackage.sendCompanyEmail' },
    { header: 'Liczba badań', property: 'examsCount', noSorting: true },
    { header: 'Zakończone', property: 'examsFinishCount', noSorting: true },
    {
      header: 'Oczekujące',
      property: 'examsExpectationCount',
      noSorting: true,
    },
    {
      header: 'Normalizacyjna',
      property: 'examspackage.mode',
    },
  ];

  return (
    <Grid
      getData={props.getData}
      getDataCount={props.getCount}
      columns={columns}
      //pageNumber={4}
      showFilter={true}
      initialOrderColumn="examspackage.number"
      initialOrderDirection="DESC"
      availablePageSizes={[10, 20, 50]}
      onRowClick={props.onRowClick}
      emptyGridMessage="Brak paczek badań w systemie"
      refresh={props.refresh}
    />
  );
};
