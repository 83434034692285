/*eslint max-lines-per-function: ["error", 250]*/
import React, { useState, useEffect, useCallback } from 'react';
import { Alert } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';

import { useLocation } from '../../hooks/useLocation';
import { useNavigation } from '../../../common/navigation';
import { useNotifications } from '../../hooks/useNotifications';
import { useAuth } from '../../hooks/useAuth';

import { GridFilter } from './GridFilter';
import { GridFooter } from './GridFooter';
import {
  GridColumn,
  GridRecord,
  GridGetterFunction,
  GridCountFunction,
} from './GridDataTypes';
import staticGetData from './GridStaticGetData';
import { nvl } from './nvl';
import { GridInnerTable } from './GridInnerTable';
import { GridLocationState } from './GridLocationState';

interface GridProps {
  columns: GridColumn[];
  availablePageSizes?: number[];
  pageSize?: number;
  showFilter?: boolean;
  data?: GridRecord[];
  summaryData?: GridRecord;
  emptyGridMessage?: string;
  hidePagination?: boolean;
  initialOrderColumn?: string;
  initialOrderDirection?: 'ASC' | 'DESC';
  additionalId?: string;
  getData?: GridGetterFunction;
  getDataCount?: GridCountFunction;
  onRowClick?: (key: string) => void;
  refresh?: boolean;
  enableMultiSelect?: boolean;
  enableSingleSelect?: boolean;
  selectedKeys?: string[];
}

export const Grid: React.FC<GridProps> = props => {
  const location = useLocation<GridLocationState & unknown>();
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState(nvl(props.pageSize, 10));
  const [pageNumber, setPageNumber] = useState(location.state?.page || 1);

  const [pageData, setPageData] = useState<GridRecord[]>();
  const [orderBy, setOrderBy] = useState(
    location.state?.sort || props.initialOrderColumn,
  );
  const [orderDirection, setOrderDirection] = useState<'ASC' | 'DESC'>(
    location.state?.sortDirection || nvl(props.initialOrderDirection, 'ASC'),
  );
  const [totalPages, setTotalPages] = useState<number>();
  const [filterText, setFilterText] = useState<string>(
    location.state?.filterText || '',
  );
  const [filtering, setFiltering] = useState(false);
  const [unauthorized, setUnauthorized] = useState(false);
  const [getData, getDataCount] = [props.getData, props.getDataCount];

  useEffect(() => {
    setPageNumber(location.state?.page || 1);
    setFilterText(location.state?.filterText || '');
    setOrderBy(location.state?.sort || props.initialOrderColumn);
    setOrderDirection(
      location.state?.sortDirection || nvl(props.initialOrderDirection, 'ASC'),
    );
  }, [location.state, props.initialOrderColumn, props.initialOrderDirection]);

  const filter = (text: string) => {
    setPageNumber(1);
    setFilterText(text);
    navigate(location.pathname, {
      state: {
        ...location.state,
        filterText: text || '',
        page: 1,
      },
    });
  };

  const nav = useNavigation();
  const notifications = useNotifications();
  const auth = useAuth();

  const countAllRows = useCallback(async () => {
    const pd = props.data;
    if (pd) {
      return staticGetData(pd, pd.length, 1, undefined, undefined, filterText)
        .length;
    }

    if (!getDataCount) {
      throw new Error('No count method!');
    }

    return await getDataCount(filterText);
  }, [filterText, getDataCount, props.data]);

  useEffect(() => {
    setUnauthorized(false);
    let mounted = true;
    const order = orderBy || props.initialOrderColumn;

    if (getData) {
      setFiltering(true);
      if (props.additionalId) {
        getData(
          pageSize,
          pageNumber,
          order,
          orderDirection,
          filterText,
          props.additionalId,
        )
          .then(rows => {
            if (mounted) {
              // getData should return pageSize + 1 records if they exists
              const reachedTheEnd = rows.length <= pageSize;
              setTotalPages(reachedTheEnd ? pageNumber : undefined);
              setPageData(rows.slice(0, pageSize));
              setFiltering(false);
            }
          })
          .catch(err => {
            console.log(err);
            if (err.message === 'Failed to fetch') {
              notifications.noBackend();
            } else if (err?.status === 401) {
              setUnauthorized(true);
            }
          });
      } else {
        getData(pageSize, pageNumber, order, orderDirection, filterText)
          .then(rows => {
            if (mounted) {
              // getData should return pageSize + 1 records if they exists
              const reachedTheEnd = rows.length <= pageSize;
              setTotalPages(reachedTheEnd ? pageNumber : undefined);
              setPageData(rows.slice(0, pageSize));
              setFiltering(false);
            }
          })
          .catch(err => {
            console.log(err);
            if (err.message === 'Failed to fetch') {
              notifications.noBackend();
            } else if (err?.status === 401) {
              setUnauthorized(true);
            }
          });
      }
    } else if (props.data) {
      const rows = staticGetData(
        props.data,
        pageSize,
        pageNumber,
        order,
        orderDirection,
        filterText,
      );

      setPageData(rows);
      countAllRows()
        .then(rowsCount => {
          if (mounted) {
            setTotalPages(Math.ceil(rowsCount / pageSize));
          }
        })
        .catch(err => console.log(err));
    } else {
      throw new Error('Either set data or getData method!');
    }

    return () => {
      mounted = false;
    };
  }, [
    getData,
    props.data,
    props.initialOrderColumn,
    props.additionalId,
    pageNumber,
    pageSize,
    orderBy,
    orderDirection,
    filterText,
    countAllRows,
    props.refresh,
    notifications,
    auth,
  ]);

  useEffect(() => {
    if (unauthorized) {
      void auth.logout().then(() => {
        return nav.login();
      });
    }
  }, [auth, nav, unauthorized]);

  return (
    <>
      {props.showFilter && (
        <div className="d-flex flex-row-reverse">
          <GridFilter
            defaultText={filterText}
            filter={filter}
            indicator={filtering}
          />
        </div>
      )}
      {!pageData ? (
        <Skeleton count={10} />
      ) : (
        <>
          <GridInnerTable
            columns={props.columns}
            pageData={pageData}
            orderBy={orderBy}
            orderDirection={orderDirection}
            summaryData={props.summaryData}
            setOrderBy={setOrderBy}
            setOrderDirection={setOrderDirection}
            onRowClick={props.onRowClick}
            enableMultiSelect={props.enableMultiSelect}
            enableSingleSelect={props.enableSingleSelect}
            selectedKeys={props.selectedKeys}
          />
          {pageData.length === 0 && (
            <Alert variant="warning">
              {nvl(props.emptyGridMessage, 'Brak danych do pokazania')}
            </Alert>
          )}
          <GridFooter
            availablePageSizes={props.availablePageSizes}
            hidePagination={props.hidePagination}
            pageData={pageData}
            pageNumber={pageNumber}
            totalPages={totalPages}
            pageSize={pageSize}
            setPageNumber={setPageNumber}
            countAllRows={countAllRows}
            setPageSize={setPageSize}
          />
        </>
      )}
    </>
  );
};
