/* tslint:disable */
/* eslint-disable */
/**
 * SPD
 * Opis api SPD
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetSupportingExaminationDictDto
 */
export interface GetSupportingExaminationDictDto {
    /**
     * 
     * @type {string}
     * @memberof GetSupportingExaminationDictDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetSupportingExaminationDictDto
     */
    name?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetSupportingExaminationDictDto
     */
    active: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetSupportingExaminationDictDto
     */
    type?: GetSupportingExaminationDictDtoTypeEnum;
}

export function GetSupportingExaminationDictDtoFromJSON(json: any): GetSupportingExaminationDictDto {
    return GetSupportingExaminationDictDtoFromJSONTyped(json, false);
}

export function GetSupportingExaminationDictDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSupportingExaminationDictDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'active': json['active'],
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function GetSupportingExaminationDictDtoToJSON(value?: GetSupportingExaminationDictDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'active': value.active,
        'type': value.type,
    };
}

/**
* @export
* @enum {string}
*/
export enum GetSupportingExaminationDictDtoTypeEnum {
    HelpExam = 'helpExam',
    SpecialisedConsultation = 'specialisedConsultation'
}


