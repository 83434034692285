/*eslint max-lines-per-function: ["error", 240]*/
/* eslint-disable complexity */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import FormRow from '../../../app/components/FormRow';
import { useNavigation } from '../../../common/navigation';
import AlertModal from '../../../app/components/AlertModal';
import ConfirmationButton from '../../../app/components/ConfirmationButton';
import { useSpdCore } from '../../../common/hooks/useSpdCore';
import { GetExaminedDto } from '../../../common/spdCore/autogenerated/spdApiClient';
import { ExaminedSelectFromModal } from '../../components/Examined/ExaminedSelectFromModal';
import { ExaminedPreviewButton } from '../../components/Examined/ExaminedPreviewButton';

type ParamTypes = {
  id: string;
};

export const MergeExaminedView: React.FC = () => {
  const spd = useSpdCore();
  const nav = useNavigation();
  const [showMerged, setShowMerged] = useState(false);
  const [showProblem, setShowProblem] = useState(false);
  const [message, setMessage] = useState<string>(
    'Wystąpił problem w trakcie sklejania badanych. Spróbuj ponownie.',
  );
  const { id } = useParams<ParamTypes>() as {
    id: string;
  };
  const mountedRef = useRef(false);
  const [examinedDeleteId, setExaminedDeleteId] = useState<string | undefined>(
    undefined,
  );
  const [examinedDelete, setExaminedDelete] = useState<
    GetExaminedDto | undefined
  >(undefined);
  const [examinedStay, setExaminedStay] = useState<GetExaminedDto | undefined>(
    undefined,
  );

  const safeSet = (set: () => void) => {
    if (mountedRef.current) {
      set();
    }
  };

  const refresh = useCallback(() => {
    if (!mountedRef.current) {
      return;
    }
    if (examinedDeleteId) {
      spd.examineds
        .getExamined(examinedDeleteId)
        .then(examined => {
          safeSet(() => setExaminedDelete(examined));
        })
        .catch(error => {
          setMessage(
            `Nie udało się pobrać badanego do sklejenia.  ${
              (error as Error)?.message || ''
            }`,
          );
          setShowProblem(true);
        });
    }
    spd.examineds
      .getExamined(id)
      .then(examined => {
        safeSet(() => setExaminedStay(examined));
      })
      .catch(error => {
        setMessage(
          `Nie udało się pobrać głównego badanego.  ${
            (error as Error)?.message || ''
          }`,
        );
        setShowProblem(true);
      });
  }, [spd.examineds, id, examinedDeleteId]);

  useEffect(() => {
    mountedRef.current = true;
    refresh();
    return () => {
      mountedRef.current = false;
    };
  }, [refresh]);

  const mergeExamineds = async () => {
    if (examinedDelete && examinedStay) {
      await spd.examineds
        .mergeExamined(examinedDelete.id, examinedStay.id)
        .then(() => {
          setExaminedDelete(undefined);
          setShowMerged(true);
        })
        .catch(error => {
          setMessage(
            `Sklejanie zakończone niepowodzeniem.  ${
              (error as Error)?.message || ''
            }`,
          );
          setShowProblem(true);
        });
    }
  };

  return (
    <Card>
      <Card.Header>Sklejanie badanych</Card.Header>
      <Card.Body>
        <>
          <Card>
            <Card.Header>Główny badany</Card.Header>
            <Card.Body>
              <Form className="d-grid gap-3">
                <FormRow controlId="examinedName" label="Imię">
                  <Form.Control
                    type="text"
                    value={examinedStay?.firstname || ''}
                    readOnly={true}
                  />
                </FormRow>
                <FormRow controlId="examinedSurname" label="Nazwisko">
                  <Form.Control
                    type="text"
                    value={examinedStay?.surname || ''}
                    readOnly={true}
                  />
                </FormRow>
                <FormRow controlId="examinedPesel" label="Pesel">
                  <Form.Control
                    type="text"
                    value={examinedStay?.pesel || ''}
                    readOnly={true}
                  />
                </FormRow>
                <FormRow controlId="idCard" label="Dokument">
                  <Form.Control
                    type="text"
                    value={`${examinedStay?.iDCardType || ''} ${
                      examinedStay?.iDCardNumber || ''
                    }`}
                    readOnly={true}
                  />
                </FormRow>
                <ExaminedPreviewButton examinedId={id} />
              </Form>
            </Card.Body>
          </Card>
          <br />
          <br />
          <Card>
            <Card.Header>Zastępowany badany</Card.Header>

            <Card.Body>
              <Form className="d-grid gap-3">
                <ExaminedSelectFromModal
                  examinedDeleteId={examinedDeleteId}
                  setExaminedDeleteId={setExaminedDeleteId}
                />
                {examinedDeleteId && (
                  <>
                    <FormRow controlId="delExaminedName" label="Imię">
                      <Form.Control
                        type="text"
                        value={examinedDelete?.firstname || ''}
                        readOnly={true}
                      />
                    </FormRow>
                    <FormRow controlId="delExaminedSurname" label="Nazwisko">
                      <Form.Control
                        type="text"
                        value={examinedDelete?.surname || ''}
                        readOnly={true}
                      />
                    </FormRow>
                    <FormRow controlId="delExaminedPesel" label="Pesel">
                      <Form.Control
                        type="text"
                        value={examinedDelete?.pesel || ''}
                        readOnly={true}
                      />
                    </FormRow>
                    <FormRow controlId="delIdCard" label="Dokument">
                      <Form.Control
                        type="text"
                        value={`${examinedDelete?.iDCardType || ''} ${
                          examinedDelete?.iDCardNumber || ''
                        }`}
                        readOnly={true}
                      />
                    </FormRow>
                    <ExaminedPreviewButton examinedId={examinedDeleteId} />
                  </>
                )}
              </Form>
            </Card.Body>
          </Card>
          <br />
          <br />
          {examinedStay?.id !== examinedDelete?.id && (
            <ConfirmationButton
              variant="outline-primary"
              onOK={mergeExamineds}
              className="mx-2"
              confirmation="Uwaga: operacja jest nieodwracalna. Czy na pewno wykonać sklejanie?"
            >
              Sklej badanych
            </ConfirmationButton>
          )}
          <Button
            variant="outline-secondary"
            onClick={() => examinedStay && nav.examined()}
          >
            Rezygnuj
          </Button>
          <AlertModal
            show={showMerged}
            onHide={() => {
              setShowMerged(false);
              examinedStay && nav.examined();
            }}
            variant="info"
          >
            Badani zostali sklejeni.
          </AlertModal>
          <AlertModal
            show={showProblem}
            onHide={() => {
              setShowProblem(false);
            }}
            variant="danger"
          >
            {message}
          </AlertModal>
        </>
      </Card.Body>
    </Card>
  );
};
