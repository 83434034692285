import React, { useState } from 'react';
import { Button, Form, Image } from 'react-bootstrap';

import { useNavigation } from '../../../common/navigation';

export type Props = {
  onSignIn: (usr: string, pwd: string) => void;
};

export const SignIn: React.FC<Props> = ({ onSignIn }) => {
  const nav = useNavigation();

  const [usr, setUsr] = useState('');
  const [pwd, setPwd] = useState('');
  const [userRequiredError, setUserRequiredError] = useState('');
  const [passwordRequiredError, setPasswordRequiredError] = useState('');

  const handleSignIn = async () => {
    setUserRequiredError('');
    setPasswordRequiredError('');
    if (!usr || !pwd) {
      if (!usr) {
        setUserRequiredError('Podaj adres e-mail.');
      }
      if (!pwd) {
        setPasswordRequiredError('Podaj hasło.');
      }
      return;
    }

    await onSignIn(usr, pwd);
  };

  return (
    <main className="form-signin">
      <Form>
        <Image className="img-logo-spd mb-3 mt-5" alt="Logo Spd" />
        <h3 className="fw-normal">Zaloguj się</h3>
        <Form.Group className="form-floating mt-3" controlId="email">
          <Form.Control
            type="email"
            placeholder="Adres e-mail"
            defaultValue={usr}
            onChange={e => setUsr(e.target.value)}
          />
          <Form.Label>Adres e-mail</Form.Label>
        </Form.Group>
        <span className="input-form-error">{userRequiredError}</span>
        <Form.Group className="form-floating mt-3" controlId="password">
          <Form.Control
            type="password"
            placeholder="Hasło"
            value={pwd}
            onChange={e => setPwd(e.target.value)}
          />
          <Form.Label>Hasło</Form.Label>
        </Form.Group>
        <span className="input-form-error">{passwordRequiredError}</span>
      </Form>
      <Button
        type="submit"
        className="w-100 btn btn-lg btn-login mt-3"
        onClick={handleSignIn}
      >
        Zaloguj się
      </Button>
      <Button className="btn-link-click" onClick={nav.passwordReset}>
        Nie pamiętasz hasła?
      </Button>
      <br />
      <br />
      <br />
      <br />
      <p className="h4"> Nie masz konta?</p>
      <Button className="w-100 btn btn-lg btn-register" onClick={nav.register}>
        Zarejestruj się
      </Button>
    </main>
  );
};
