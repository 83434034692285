/* tslint:disable */
/* eslint-disable */
/**
 * SPD
 * Opis api SPD
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CreateUpdateInterpretationNormDto,
    CreateUpdateInterpretationNormDtoFromJSON,
    CreateUpdateInterpretationNormDtoFromJSONTyped,
    CreateUpdateInterpretationNormDtoToJSON,
    CreateUpdatePositionNormDto,
    CreateUpdatePositionNormDtoFromJSON,
    CreateUpdatePositionNormDtoFromJSONTyped,
    CreateUpdatePositionNormDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreateUpdateNormWithValuesDto
 */
export interface CreateUpdateNormWithValuesDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateNormWithValuesDto
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CreateUpdateNormWithValuesDto
     */
    probability?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateNormWithValuesDto
     */
    group?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CreateUpdateNormWithValuesDto
     */
    examinedCount?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateNormWithValuesDto
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateNormWithValuesDto
     */
    keyTestName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateNormWithValuesDto
     */
    indicatorTestName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateNormWithValuesDto
     */
    indicatorTestMode?: string | null;
    /**
     * 
     * @type {Array<CreateUpdatePositionNormDto>}
     * @memberof CreateUpdateNormWithValuesDto
     */
    positionNorms?: Array<CreateUpdatePositionNormDto> | null;
    /**
     * 
     * @type {Array<CreateUpdateInterpretationNormDto>}
     * @memberof CreateUpdateNormWithValuesDto
     */
    interpretationNorms?: Array<CreateUpdateInterpretationNormDto> | null;
}

export function CreateUpdateNormWithValuesDtoFromJSON(json: any): CreateUpdateNormWithValuesDto {
    return CreateUpdateNormWithValuesDtoFromJSONTyped(json, false);
}

export function CreateUpdateNormWithValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateNormWithValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'probability': !exists(json, 'probability') ? undefined : json['probability'],
        'group': !exists(json, 'group') ? undefined : json['group'],
        'examinedCount': !exists(json, 'examinedCount') ? undefined : json['examinedCount'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'keyTestName': !exists(json, 'keyTestName') ? undefined : json['keyTestName'],
        'indicatorTestName': !exists(json, 'indicatorTestName') ? undefined : json['indicatorTestName'],
        'indicatorTestMode': !exists(json, 'indicatorTestMode') ? undefined : json['indicatorTestMode'],
        'positionNorms': !exists(json, 'positionNorms') ? undefined : (json['positionNorms'] === null ? null : (json['positionNorms'] as Array<any>).map(CreateUpdatePositionNormDtoFromJSON)),
        'interpretationNorms': !exists(json, 'interpretationNorms') ? undefined : (json['interpretationNorms'] === null ? null : (json['interpretationNorms'] as Array<any>).map(CreateUpdateInterpretationNormDtoFromJSON)),
    };
}

export function CreateUpdateNormWithValuesDtoToJSON(value?: CreateUpdateNormWithValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'probability': value.probability,
        'group': value.group,
        'examinedCount': value.examinedCount,
        'description': value.description,
        'keyTestName': value.keyTestName,
        'indicatorTestName': value.indicatorTestName,
        'indicatorTestMode': value.indicatorTestMode,
        'positionNorms': value.positionNorms === undefined ? undefined : (value.positionNorms === null ? null : (value.positionNorms as Array<any>).map(CreateUpdatePositionNormDtoToJSON)),
        'interpretationNorms': value.interpretationNorms === undefined ? undefined : (value.interpretationNorms === null ? null : (value.interpretationNorms as Array<any>).map(CreateUpdateInterpretationNormDtoToJSON)),
    };
}


