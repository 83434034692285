/* eslint-disable import/named */
import React, { MouseEventHandler } from 'react';
import Select, {
  components,
  MultiValue,
  MultiValueGenericProps,
  MultiValueProps,
  OnChangeValue,
  Props,
} from 'react-select';
import {
  SortableContainer,
  SortableContainerProps,
  SortableElement,
  SortEndHandler,
  SortableHandle,
} from 'react-sortable-hoc';

export interface ReactSelectOption {
  value: string;
  label: string;
  badge?: string;
}

function arrayMove<T>(array: readonly T[], from: number, to: number) {
  const slicedArray = array.slice();
  slicedArray.splice(
    to < 0 ? array.length + to : to,
    0,
    slicedArray.splice(from, 1)[0],
  );
  return slicedArray;
}

const SortableMultiValue = SortableElement(
  (props: MultiValueProps<ReactSelectOption>) => {
    // this prevents the menu from being opened/closed when the user clicks
    // on a value to begin dragging it. ideally, detecting a click (instead of
    // a drag) would still focus the control and toggle the menu, but that
    // requires some magic with refs that are out of scope for this example
    const onMouseDown: MouseEventHandler<HTMLDivElement> = e => {
      e.preventDefault();
      e.stopPropagation();
    };
    const innerProps = { ...props.innerProps, onMouseDown };
    return <components.MultiValue {...props} innerProps={innerProps} />;
  },
);

const SortableMultiValueLabel = SortableHandle(
  (props: MultiValueGenericProps) => <components.MultiValueLabel {...props} />,
);

const SortableSelect = SortableContainer(Select) as React.ComponentClass<
  Props<ReactSelectOption, true> & SortableContainerProps
>;

interface MultiSelectSortableProps {
  options: ReactSelectOption[];
  defaultValue: ReactSelectOption[];
  onChange: (options: MultiValue<ReactSelectOption>) => void;
}
export const MultiSelectSortable: React.FC<MultiSelectSortableProps> = ({
  options,
  defaultValue,
  onChange,
}: MultiSelectSortableProps) => {
  const [selected, setSelected] =
    React.useState<readonly ReactSelectOption[]>(defaultValue);

  const handleOnChange = (
    selectedOptions: OnChangeValue<ReactSelectOption, true>,
  ) => {
    setSelected(selectedOptions);
    onChange(selectedOptions);
  };

  const onSortEnd: SortEndHandler = ({ oldIndex, newIndex }) => {
    const newValue = arrayMove(selected, oldIndex, newIndex);
    setSelected(newValue);
    onChange(newValue);
  };

  return (
    <SortableSelect
      className="test-multi-select"
      classNamePrefix="test-select"
      styles={{
        multiValueLabel: provided => ({
          ...provided,
          width: '100%',
        }),
      }}
      useDragHandle
      // react-sortable-hoc props:
      axis="y"
      onSortEnd={onSortEnd}
      distance={4}
      // small fix for https://github.com/clauderic/react-sortable-hoc/pull/352:
      getHelperDimensions={({ node }) => node.getBoundingClientRect()}
      // react-select props:
      isMulti
      options={options}
      value={selected}
      onChange={handleOnChange}
      components={{
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore We're failing to provide a required index prop to SortableElement
        MultiValue: SortableMultiValue,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore We're failing to provide a required index prop to SortableElement
        MultiValueLabel: SortableMultiValueLabel,
      }}
      closeMenuOnSelect={false}
    />
  );
};

export default MultiSelectSortable;
