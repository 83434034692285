/* tslint:disable */
/* eslint-disable */
/**
 * SPD
 * Opis api SPD
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExamSexDto
 */
export interface ExamSexDto {
    /**
     * 
     * @type {string}
     * @memberof ExamSexDto
     */
    sex?: string | null;
}

export function ExamSexDtoFromJSON(json: any): ExamSexDto {
    return ExamSexDtoFromJSONTyped(json, false);
}

export function ExamSexDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExamSexDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sex': !exists(json, 'sex') ? undefined : json['sex'],
    };
}

export function ExamSexDtoToJSON(value?: ExamSexDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sex': value.sex,
    };
}


