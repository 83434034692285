/*eslint max-lines-per-function: ["error", 220]*/
import React from 'react';
import { Form, Card, Button } from 'react-bootstrap';

import FormRow from '../../../app/components/FormRow';
import { CreateUpdateExamWithTestsDto } from '../../../common/spdCore/autogenerated/spdApiClient';
import { TakenCareOf } from '../../../common/spdCore/validation/schemas';
import DynamicTextarea from '../../../app/components/DynamicTextarea';

interface Props {
  prevStep?: () => void;
  nextStep?: () => void;
  handleFormData?: (input: string) => (e: string | boolean | Date) => void;
  referralFromEmployer?: boolean;
  areHarmfulFactors?: boolean;
  areResultsHarmfulFactors?: boolean;
  takenCareOf?: TakenCareOf;
  harmfulFactors?: string;
  propertyChange?: (obj: Partial<CreateUpdateExamWithTestsDto>) => void;
}

export const AddHarmfulFactory: React.FC<Props> = ({
  prevStep,
  nextStep,
  handleFormData,
  referralFromEmployer,
  areHarmfulFactors,
  areResultsHarmfulFactors,
  takenCareOf,
  harmfulFactors,
  propertyChange,
}) => {
  const handleOnNext = () => {
    nextStep && nextStep();
  };

  const handleOnPrev = () => {
    prevStep && prevStep();
  };

  const propertyChangeWithHandleFormData = (
    field: string,
    checked: boolean | string | Date,
  ) => {
    handleFormData && handleFormData(field)(checked);
    propertyChange && propertyChange({ [field]: checked });
  };
  return (
    <>
      <Card>
        <Card.Body>
          <Card.Subtitle className="mb-4">Skierowanie</Card.Subtitle>
          <Form className="d-grid gap-3">
            <FormRow
              controlId="referral_from_employer"
              label="Skierowanie od pracodawcy/placówki"
            >
              <Form.Check
                type="checkbox"
                checked={referralFromEmployer ? true : false}
                onChange={e =>
                  propertyChangeWithHandleFormData(
                    'referralFromEmployer',
                    e.target.checked,
                  )
                }
              />
            </FormRow>
            <FormRow
              controlId="areHarmful_factors"
              label="Informacja o czynnikach szkodliwych"
            >
              <Form.Check
                type="checkbox"
                checked={areHarmfulFactors ? true : false}
                onChange={e =>
                  propertyChangeWithHandleFormData(
                    'areHarmfulFactors',
                    e.target.checked,
                  )
                }
              />
            </FormRow>
            <FormRow
              controlId="are_results_harmful_factors"
              label="Wyniki pomiarów czynników szkodliwych"
            >
              <Form.Check
                type="checkbox"
                checked={areResultsHarmfulFactors ? true : false}
                onChange={e =>
                  propertyChangeWithHandleFormData(
                    'areResultsHarmfulFactors',
                    e.target.checked,
                  )
                }
              />
            </FormRow>
            <FormRow controlId="taken_care_of" label="Objęty opieką">
              <Form.Control
                as={Form.Select}
                value={takenCareOf || ''}
                onChange={e =>
                  propertyChangeWithHandleFormData(
                    'takenCareOf',
                    e.target.value,
                  )
                }
              >
                <option value=""></option>
                <option value="Employee">Pracownik</option>
                <option value="Outwork">Wykonujący pracę nakładczą</option>
                <option value="Student">Pobierający naukę</option>
                <option value="OwnRequest">Na własny wniosek</option>
              </Form.Control>
            </FormRow>
            <FormRow
              controlId="harmfulFactors"
              label="Czynniki szkodliwe i uciążliwe"
            >
              <DynamicTextarea
                value={harmfulFactors || ''}
                onChange={e =>
                  propertyChangeWithHandleFormData(
                    'harmfulFactors',
                    e.target.value,
                  )
                }
              />
            </FormRow>
            {prevStep && nextStep && (
              <div className="d-flex justify-content-end m-3">
                <Button
                  variant="outline-secondary"
                  className="ms-1"
                  onClick={handleOnPrev}
                >
                  Wstecz
                </Button>

                <Button
                  variant="outline-primary"
                  className="ms-1"
                  onClick={handleOnNext}
                >
                  Dalej
                </Button>
              </div>
            )}
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};
