import { DefaultApi } from '../autogenerated/spdApiClient/apis/DefaultApi';
import { GetPdfTemplateDto } from '../autogenerated/spdApiClient';

export type PdfTemplateEntity = GetPdfTemplateDto;

export interface PdfTemplateContextInterface {
  getAll: () => Promise<GetPdfTemplateDto[]>;
  getStampPosition: (pdfTemplateId: string) => Promise<string>;
}

export const PdfTemplateContext = (api: DefaultApi) => {
  return {
    getAll: () => api.pdfTemplateControllerGetAll(),
    getStampPosition: (pdfTemplateId: string) =>
      api.pdfTemplateControllerGetStampPosition(pdfTemplateId),
  };
};
