import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

import { useNavigation } from '../../../common/navigation';

export const ExaminedLinkAttachments = (props: { examinedId: string }) => {
  const nav = useNavigation();

  return (
    <OverlayTrigger overlay={<Tooltip>Załączniki badanego</Tooltip>}>
      <Button
        onClick={e => {
          e.stopPropagation();
          nav.attachments(props.examinedId);
        }}
        variant="btn-info"
      >
        <Icon.Paperclip />
      </Button>
    </OverlayTrigger>
  );
};
