import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-quill/dist/quill.snow.css'; // rich-text-editor
import 'react-loading-skeleton/dist/skeleton.css';
import '../theme/theme.scss';
import 'moment/locale/pl';

import { AuthProvider } from '../common/auth';
import { FirebaseContextProvider } from '../common/firebase';
import { SpdCoreProvider } from '../common/spdCore';
import { CookiesProvider } from '../common/cookies';

import { MyRoutes } from './containers/Routes';

function App() {
  const createElement = (url: string) => {
    const element = document.createElement('link');
    element.rel = 'stylesheet';
    element.href = url;
    return element;
  };

  const url =
    'https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap';

  useEffect(() => {
    const element = createElement(url);
    document.head.appendChild(element);
  }, []);

  return (
    <FirebaseContextProvider>
      <AuthProvider>
        <SpdCoreProvider>
          <CookiesProvider>
            <MyRoutes />
          </CookiesProvider>
        </SpdCoreProvider>
      </AuthProvider>
    </FirebaseContextProvider>
  );
}

export default App;
