import React, { useState } from 'react';
import { Button, Card, Tab } from 'react-bootstrap';

import Tabs from '../../../app/components/Tabs';
import { useSpdCore } from '../../../common/hooks/useSpdCore';
import { useNavigation } from '../../../common/navigation';
import { CreateUpdatePositionNormDto } from '../../../common/spdCore/autogenerated/spdApiClient';
import { CreateUpdateInterpretationNormDto } from '../../../common/spdCore/autogenerated/spdApiClient';
import { ImportNorm } from '../../components/Norms/ImportNorm';
import {
  InterpretationXls,
  NormXls,
} from '../../components/Norms/ImportNormValidator';
import { Norm } from '../../components/Norms/Norms';

export const NormsView: React.FC = () => {
  const [show, setShow] = useState(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const spd = useSpdCore();
  const nav = useNavigation();

  const handleClose = () => {
    setShow(false);
    setRefresh(!refresh);
  };

  const addNorm = async (
    normsJson: NormXls[],
    interpretationsJson: InterpretationXls[],
  ) => {
    const norms = {
      name: normsJson[0].nazwa_normy,
      group: normsJson[0].grupa_norm,
      probability: Number(normsJson[0].prawdopodobienstwo),
      examinedCount: Number(normsJson[0].liczba_osob),
      description: normsJson[0].opis,
      keyTestName: normsJson[0].nazwa_klucza,
      indicatorTestName: normsJson[0].nazwa_wskaznika,
      indicatorTestMode: normsJson[0].typ_wskaznika,
      positionNorms: getPositionNorms(normsJson),
      interpretationNorms: getInterpretations(interpretationsJson),
    };

    await spd.norms.createWithValues(norms);
    setRefresh(!refresh);
    setShow(false);
    return {
      saved: true,
      error: '',
    };
  };

  const getPositionNorms = (norms: NormXls[]) => {
    const positionNorms: CreateUpdatePositionNormDto[] = [];
    for (const val of norms) {
      positionNorms.push({
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        mode: val.rodzaj as any,
        resultTrue: Number(val.wynik_przeliczony),
        resultRawFrom: Number(val.wynik_surowy_od),
        resultRawTo: Number(val.wynik_surowy_do),
      });
    }
    return positionNorms;
  };

  const getInterpretations = (interpretations: InterpretationXls[]) => {
    const interpretationNorms: CreateUpdateInterpretationNormDto[] = [];
    for (const val of interpretations) {
      interpretationNorms.push({
        result: val.interpretacja_wynik,
        rangeFrom: Number(val.interpretacja_zakres_od),
        rangeTo: Number(val.interpretacja_zakres_do),
        description: val.interpretacja_opis,
      });
    }
    return interpretationNorms;
  };

  return (
    <Card>
      <Tabs defaultActiveKey="search" id="norms" className="mb-3">
        <Tab eventKey="search" title="Normy" className="m-3">
          <Norm
            getData={spd.norms.getAllByPortion}
            getCount={spd.norms.getAllCount}
            onClick={nav.norm}
            refresh={refresh}
          />
          <Button variant="outline-primary" onClick={() => setShow(true)}>
            Importuj normę
          </Button>
          <ImportNorm
            onImport={addNorm}
            show={show}
            handleClose={handleClose}
          />
        </Tab>
      </Tabs>
    </Card>
  );
};
