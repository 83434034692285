/* tslint:disable */
/* eslint-disable */
/**
 * SPD
 * Opis api SPD
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CreateUpdateSupportingExaminationDto,
    CreateUpdateSupportingExaminationDtoFromJSON,
    CreateUpdateSupportingExaminationDtoFromJSONTyped,
    CreateUpdateSupportingExaminationDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreateUpdateExamWithTestsDto
 */
export interface CreateUpdateExamWithTestsDto {
    /**
     * 
     * @type {number}
     * @memberof CreateUpdateExamWithTestsDto
     */
    number?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateExamWithTestsDto
     */
    examinedId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateExamWithTestsDto
     */
    psychologistUID?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof CreateUpdateExamWithTestsDto
     */
    registrationDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateExamWithTestsDto
     */
    description?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateUpdateExamWithTestsDto
     */
    articlePsychologistsIds?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateUpdateExamWithTestsDto
     */
    articleDoctorsIds?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateExamWithTestsDto
     */
    drivingLicense?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateUpdateExamWithTestsDto
     */
    occupationalMedicineCategory?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateExamWithTestsDto
     */
    occupationalMedicineText?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateExamWithTestsDto
     */
    type?: CreateUpdateExamWithTestsDtoTypeEnum;
    /**
     * 
     * @type {Date}
     * @memberof CreateUpdateExamWithTestsDto
     */
    referralDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateExamWithTestsDto
     */
    employerName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateExamWithTestsDto
     */
    employerAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateExamWithTestsDto
     */
    employmentPosition?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateExamWithTestsDto
     */
    employmentCandidate?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateExamWithTestsDto
     */
    sportsClubName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateExamWithTestsDto
     */
    sportsClubAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateExamWithTestsDto
     */
    sportsDiscipline?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof CreateUpdateExamWithTestsDto
     */
    lossOfAbilityDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateExamWithTestsDto
     */
    kindOfScience?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof CreateUpdateExamWithTestsDto
     */
    endScience?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateExamWithTestsDto
     */
    schoolName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateExamWithTestsDto
     */
    schoolAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateExamWithTestsDto
     */
    fieldOfStudy?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateExamWithTestsDto
     */
    schoolCandidate?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateExamWithTestsDto
     */
    takenCareOf?: CreateUpdateExamWithTestsDtoTakenCareOfEnum;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateExamWithTestsDto
     */
    referralFromEmployer?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateExamWithTestsDto
     */
    areHarmfulFactors?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateExamWithTestsDto
     */
    areResultsHarmfulFactors?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateExamWithTestsDto
     */
    harmfulFactors?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateUpdateExamWithTestsDto
     */
    harmfulFactorsIds?: Array<string> | null;
    /**
     * 
     * @type {Date}
     * @memberof CreateUpdateExamWithTestsDto
     */
    endDate?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateExamWithTestsDto
     */
    completed?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateExamWithTestsDto
     */
    neurologist?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateExamWithTestsDto
     */
    ophthalmologist?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateExamWithTestsDto
     */
    laryngologist?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateExamWithTestsDto
     */
    ophthalmologistEnded?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateExamWithTestsDto
     */
    batteryId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateExamWithTestsDto
     */
    healthcare?: CreateUpdateExamWithTestsDtoHealthcareEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateExamWithTestsDto
     */
    examPackageId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateExamWithTestsDto
     */
    external?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateExamWithTestsDto
     */
    whoExamined?: CreateUpdateExamWithTestsDtoWhoExaminedEnum;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateExamWithTestsDto
     */
    alarm?: boolean | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateUpdateExamWithTestsDto
     */
    testsId?: Array<string> | null;
    /**
     * 
     * @type {number}
     * @memberof CreateUpdateExamWithTestsDto
     */
    countOfExams?: number | null;
    /**
     * 
     * @type {Array<CreateUpdateSupportingExaminationDto>}
     * @memberof CreateUpdateExamWithTestsDto
     */
    supportingExamination?: Array<CreateUpdateSupportingExaminationDto> | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateExamWithTestsDto
     */
    companyId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateExamWithTestsDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateExamWithTestsDto
     */
    nip?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateExamWithTestsDto
     */
    email?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateExamWithTestsDto
     */
    sendCompanyEmail?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateExamWithTestsDto
     */
    healthcareFacilityId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateExamWithTestsDto
     */
    ownerId?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof CreateUpdateExamWithTestsDto
     */
    ts?: Date | null;
}

export function CreateUpdateExamWithTestsDtoFromJSON(json: any): CreateUpdateExamWithTestsDto {
    return CreateUpdateExamWithTestsDtoFromJSONTyped(json, false);
}

export function CreateUpdateExamWithTestsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateExamWithTestsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'number': !exists(json, 'number') ? undefined : json['number'],
        'examinedId': !exists(json, 'examinedId') ? undefined : json['examinedId'],
        'psychologistUID': !exists(json, 'psychologistUID') ? undefined : json['psychologistUID'],
        'registrationDate': !exists(json, 'registrationDate') ? undefined : (json['registrationDate'] === null ? null : new Date(json['registrationDate'])),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'articlePsychologistsIds': !exists(json, 'articlePsychologistsIds') ? undefined : json['articlePsychologistsIds'],
        'articleDoctorsIds': !exists(json, 'articleDoctorsIds') ? undefined : json['articleDoctorsIds'],
        'drivingLicense': !exists(json, 'drivingLicense') ? undefined : json['drivingLicense'],
        'occupationalMedicineCategory': !exists(json, 'occupationalMedicineCategory') ? undefined : json['occupationalMedicineCategory'],
        'occupationalMedicineText': !exists(json, 'occupationalMedicineText') ? undefined : json['occupationalMedicineText'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'referralDate': !exists(json, 'referralDate') ? undefined : (json['referralDate'] === null ? null : new Date(json['referralDate'])),
        'employerName': !exists(json, 'employerName') ? undefined : json['employerName'],
        'employerAddress': !exists(json, 'employerAddress') ? undefined : json['employerAddress'],
        'employmentPosition': !exists(json, 'employmentPosition') ? undefined : json['employmentPosition'],
        'employmentCandidate': !exists(json, 'employmentCandidate') ? undefined : json['employmentCandidate'],
        'sportsClubName': !exists(json, 'sportsClubName') ? undefined : json['sportsClubName'],
        'sportsClubAddress': !exists(json, 'sportsClubAddress') ? undefined : json['sportsClubAddress'],
        'sportsDiscipline': !exists(json, 'sportsDiscipline') ? undefined : json['sportsDiscipline'],
        'lossOfAbilityDate': !exists(json, 'lossOfAbilityDate') ? undefined : (json['lossOfAbilityDate'] === null ? null : new Date(json['lossOfAbilityDate'])),
        'kindOfScience': !exists(json, 'kindOfScience') ? undefined : json['kindOfScience'],
        'endScience': !exists(json, 'endScience') ? undefined : (json['endScience'] === null ? null : new Date(json['endScience'])),
        'schoolName': !exists(json, 'schoolName') ? undefined : json['schoolName'],
        'schoolAddress': !exists(json, 'schoolAddress') ? undefined : json['schoolAddress'],
        'fieldOfStudy': !exists(json, 'fieldOfStudy') ? undefined : json['fieldOfStudy'],
        'schoolCandidate': !exists(json, 'schoolCandidate') ? undefined : json['schoolCandidate'],
        'takenCareOf': !exists(json, 'takenCareOf') ? undefined : json['takenCareOf'],
        'referralFromEmployer': !exists(json, 'referralFromEmployer') ? undefined : json['referralFromEmployer'],
        'areHarmfulFactors': !exists(json, 'areHarmfulFactors') ? undefined : json['areHarmfulFactors'],
        'areResultsHarmfulFactors': !exists(json, 'areResultsHarmfulFactors') ? undefined : json['areResultsHarmfulFactors'],
        'harmfulFactors': !exists(json, 'harmfulFactors') ? undefined : json['harmfulFactors'],
        'harmfulFactorsIds': !exists(json, 'harmfulFactorsIds') ? undefined : json['harmfulFactorsIds'],
        'endDate': !exists(json, 'endDate') ? undefined : (json['endDate'] === null ? null : new Date(json['endDate'])),
        'completed': !exists(json, 'completed') ? undefined : json['completed'],
        'neurologist': !exists(json, 'neurologist') ? undefined : json['neurologist'],
        'ophthalmologist': !exists(json, 'ophthalmologist') ? undefined : json['ophthalmologist'],
        'laryngologist': !exists(json, 'laryngologist') ? undefined : json['laryngologist'],
        'ophthalmologistEnded': !exists(json, 'ophthalmologistEnded') ? undefined : json['ophthalmologistEnded'],
        'batteryId': !exists(json, 'batteryId') ? undefined : json['batteryId'],
        'healthcare': !exists(json, 'healthcare') ? undefined : json['healthcare'],
        'examPackageId': !exists(json, 'examPackageId') ? undefined : json['examPackageId'],
        'external': !exists(json, 'external') ? undefined : json['external'],
        'whoExamined': !exists(json, 'whoExamined') ? undefined : json['whoExamined'],
        'alarm': !exists(json, 'alarm') ? undefined : json['alarm'],
        'testsId': !exists(json, 'testsId') ? undefined : json['testsId'],
        'countOfExams': !exists(json, 'countOfExams') ? undefined : json['countOfExams'],
        'supportingExamination': !exists(json, 'supportingExamination') ? undefined : (json['supportingExamination'] === null ? null : (json['supportingExamination'] as Array<any>).map(CreateUpdateSupportingExaminationDtoFromJSON)),
        'companyId': !exists(json, 'companyId') ? undefined : json['companyId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'nip': !exists(json, 'nip') ? undefined : json['nip'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'sendCompanyEmail': !exists(json, 'sendCompanyEmail') ? undefined : json['sendCompanyEmail'],
        'healthcareFacilityId': !exists(json, 'healthcareFacilityId') ? undefined : json['healthcareFacilityId'],
        'ownerId': !exists(json, 'ownerId') ? undefined : json['ownerId'],
        'ts': !exists(json, 'ts') ? undefined : (json['ts'] === null ? null : new Date(json['ts'])),
    };
}

export function CreateUpdateExamWithTestsDtoToJSON(value?: CreateUpdateExamWithTestsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'number': value.number,
        'examinedId': value.examinedId,
        'psychologistUID': value.psychologistUID,
        'registrationDate': value.registrationDate === undefined ? undefined : (value.registrationDate === null ? null : value.registrationDate.toISOString()),
        'description': value.description,
        'articlePsychologistsIds': value.articlePsychologistsIds,
        'articleDoctorsIds': value.articleDoctorsIds,
        'drivingLicense': value.drivingLicense,
        'occupationalMedicineCategory': value.occupationalMedicineCategory,
        'occupationalMedicineText': value.occupationalMedicineText,
        'type': value.type,
        'referralDate': value.referralDate === undefined ? undefined : (value.referralDate === null ? null : value.referralDate.toISOString()),
        'employerName': value.employerName,
        'employerAddress': value.employerAddress,
        'employmentPosition': value.employmentPosition,
        'employmentCandidate': value.employmentCandidate,
        'sportsClubName': value.sportsClubName,
        'sportsClubAddress': value.sportsClubAddress,
        'sportsDiscipline': value.sportsDiscipline,
        'lossOfAbilityDate': value.lossOfAbilityDate === undefined ? undefined : (value.lossOfAbilityDate === null ? null : value.lossOfAbilityDate.toISOString()),
        'kindOfScience': value.kindOfScience,
        'endScience': value.endScience === undefined ? undefined : (value.endScience === null ? null : value.endScience.toISOString()),
        'schoolName': value.schoolName,
        'schoolAddress': value.schoolAddress,
        'fieldOfStudy': value.fieldOfStudy,
        'schoolCandidate': value.schoolCandidate,
        'takenCareOf': value.takenCareOf,
        'referralFromEmployer': value.referralFromEmployer,
        'areHarmfulFactors': value.areHarmfulFactors,
        'areResultsHarmfulFactors': value.areResultsHarmfulFactors,
        'harmfulFactors': value.harmfulFactors,
        'harmfulFactorsIds': value.harmfulFactorsIds,
        'endDate': value.endDate === undefined ? undefined : (value.endDate === null ? null : value.endDate.toISOString()),
        'completed': value.completed,
        'neurologist': value.neurologist,
        'ophthalmologist': value.ophthalmologist,
        'laryngologist': value.laryngologist,
        'ophthalmologistEnded': value.ophthalmologistEnded,
        'batteryId': value.batteryId,
        'healthcare': value.healthcare,
        'examPackageId': value.examPackageId,
        'external': value.external,
        'whoExamined': value.whoExamined,
        'alarm': value.alarm,
        'testsId': value.testsId,
        'countOfExams': value.countOfExams,
        'supportingExamination': value.supportingExamination === undefined ? undefined : (value.supportingExamination === null ? null : (value.supportingExamination as Array<any>).map(CreateUpdateSupportingExaminationDtoToJSON)),
        'companyId': value.companyId,
        'name': value.name,
        'nip': value.nip,
        'email': value.email,
        'sendCompanyEmail': value.sendCompanyEmail,
        'healthcareFacilityId': value.healthcareFacilityId,
        'ownerId': value.ownerId,
        'ts': value.ts === undefined ? undefined : (value.ts === null ? null : value.ts.toISOString()),
    };
}

/**
* @export
* @enum {string}
*/
export enum CreateUpdateExamWithTestsDtoTypeEnum {
    Initial = 'Initial',
    Periodic = 'Periodic',
    Control = 'Control',
    PeriodicControl = 'PeriodicControl'
}
/**
* @export
* @enum {string}
*/
export enum CreateUpdateExamWithTestsDtoTakenCareOfEnum {
    Employee = 'Employee',
    Outwork = 'Outwork',
    Student = 'Student',
    OwnRequest = 'OwnRequest'
}
/**
* @export
* @enum {string}
*/
export enum CreateUpdateExamWithTestsDtoHealthcareEnum {
    Doctor = 'Doctor',
    Dentist = 'Dentist',
    Midwife = 'Midwife',
    Nurse = 'Nurse'
}
/**
* @export
* @enum {string}
*/
export enum CreateUpdateExamWithTestsDtoWhoExaminedEnum {
    Student = 'Student',
    Employee = 'Employee'
}


