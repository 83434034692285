/* tslint:disable */
/* eslint-disable */
/**
 * SPD
 * Opis api SPD
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUpdateTestDto
 */
export interface CreateUpdateTestDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateTestDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateTestDto
     */
    fileName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateTestDto
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateTestDto
     */
    identifier?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateTestDto
     */
    active?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof CreateUpdateTestDto
     */
    lp?: number | null;
}

export function CreateUpdateTestDtoFromJSON(json: any): CreateUpdateTestDto {
    return CreateUpdateTestDtoFromJSONTyped(json, false);
}

export function CreateUpdateTestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateTestDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'fileName': !exists(json, 'fileName') ? undefined : json['fileName'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'identifier': !exists(json, 'identifier') ? undefined : json['identifier'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'lp': !exists(json, 'lp') ? undefined : json['lp'],
    };
}

export function CreateUpdateTestDtoToJSON(value?: CreateUpdateTestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'fileName': value.fileName,
        'description': value.description,
        'identifier': value.identifier,
        'active': value.active,
        'lp': value.lp,
    };
}


