/* tslint:disable */
/* eslint-disable */
/**
 * SPD
 * Opis api SPD
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetNormDto,
    GetNormDtoFromJSON,
    GetNormDtoFromJSONTyped,
    GetNormDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetPositionNormDto
 */
export interface GetPositionNormDto {
    /**
     * 
     * @type {string}
     * @memberof GetPositionNormDto
     */
    id: string;
    /**
     * 
     * @type {GetNormDto}
     * @memberof GetPositionNormDto
     */
    norm?: GetNormDto | null;
    /**
     * 
     * @type {string}
     * @memberof GetPositionNormDto
     */
    taskNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetPositionNormDto
     */
    mode?: GetPositionNormDtoModeEnum;
    /**
     * 
     * @type {number}
     * @memberof GetPositionNormDto
     */
    resultTrue?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetPositionNormDto
     */
    resultRawFrom?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetPositionNormDto
     */
    resultRawTo?: number | null;
}

export function GetPositionNormDtoFromJSON(json: any): GetPositionNormDto {
    return GetPositionNormDtoFromJSONTyped(json, false);
}

export function GetPositionNormDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPositionNormDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'norm': !exists(json, 'norm') ? undefined : GetNormDtoFromJSON(json['norm']),
        'taskNumber': !exists(json, 'taskNumber') ? undefined : json['taskNumber'],
        'mode': !exists(json, 'mode') ? undefined : json['mode'],
        'resultTrue': !exists(json, 'resultTrue') ? undefined : json['resultTrue'],
        'resultRawFrom': !exists(json, 'resultRawFrom') ? undefined : json['resultRawFrom'],
        'resultRawTo': !exists(json, 'resultRawTo') ? undefined : json['resultRawTo'],
    };
}

export function GetPositionNormDtoToJSON(value?: GetPositionNormDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'norm': GetNormDtoToJSON(value.norm),
        'taskNumber': value.taskNumber,
        'mode': value.mode,
        'resultTrue': value.resultTrue,
        'resultRawFrom': value.resultRawFrom,
        'resultRawTo': value.resultRawTo,
    };
}

/**
* @export
* @enum {string}
*/
export enum GetPositionNormDtoModeEnum {
    Centyl = 'centyl',
    Ten = 'ten',
    Sten = 'sten',
    Stanin = 'stanin',
    Przeliczony = 'przeliczony'
}


