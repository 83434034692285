import React from 'react';

import { useSpdCore } from '../../common/hooks/useSpdCore';
import { RegisterDialog } from '../components/Register/RegisterDialog';

export const RegisterView: React.FC = () => {
  const spdApi = useSpdCore();

  const onRegister = async (username: string, password: string) => {
    return await spdApi.signIn.addUserWithEmailAndPassword(username, password);
  };
  return <RegisterDialog onRegister={onRegister} />;
};
