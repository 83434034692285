/* eslint-disable complexity */
import React from 'react';
import { Badge, Tooltip } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import { Grid } from '../../../common/components/Grid/Grid';
import {
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import momencik from '../../../common/momencik';
import { GetExamDto } from '../../../common/spdCore/autogenerated/spdApiClient';
import { ExaminedFromExamPreviewLink } from '../Examined/ExaminedFromExamPreviewLink';

import { ExamPreviewLink } from './ExamPreviewLink';
import { ExamEditLink } from './ExamEditLink';
import { ExamChatLink } from './ExamChatLink';

interface ExamSearchProps {
  getData: GridGetterFunction;
  getCount: GridCountFunction;
  onRowClick: (key: string) => Promise<void> | void | undefined;
  refresh?: boolean;
}

export const examForGrid = (exams: GetExamDto) => {
  const token = localStorage.getItem('mzt-token') || '';
  return {
    key: exams.id,
    values: {
      'exams.number': (
        <>
          <Badge bg="info" className="me-1">
            {exams.number}
          </Badge>
          <ExamEditLink examId={exams.id} />
          {exams?.alarm && !exams.endDate && (
            <>
              {' '}
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>Badany prosi o pomoc!</Tooltip>}
              >
                <Badge bg="danger" className="me-1">
                  !
                </Badge>
              </OverlayTrigger>
            </>
          )}
        </>
      ),
      'exams.examined': exams.examined && (
        <>
          <ExaminedFromExamPreviewLink examId={exams.id} />{' '}
          {exams.examined.surname} {exams.examined.firstname}
        </>
      ),
      'examined.pesel': exams?.examined?.pesel || '',
      'exams.description': exams.description || '',
      // last_change_date: momencik(exams.changeDate, 'YYYY.MM.DD HH:mm:ss') || '',
      'exams.startDate': momencik(exams.startDate, 'YYYY.MM.DD HH:mm:ss') || '',
      'exams.endDate': momencik(exams.endDate, 'YYYY.MM.DD HH:mm:ss') || '',
      'exams.status': exams?.status,
      'exams.pin': (
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip>{exams.pin}</Tooltip>}
        >
          <Badge
            bg="success"
            className="me-1"
            onClick={e => e.stopPropagation()}
          >
            #
          </Badge>
        </OverlayTrigger>
      ),
      critical_point: exams?.criticalPoint && (
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip>
              Zostały oznaczone punkty krytyczne: {exams.criticalPoint}
            </Tooltip>
          }
        >
          <Badge
            bg="danger"
            className="me-1"
            onClick={e => e.stopPropagation()}
          >
            !
          </Badge>
        </OverlayTrigger>
      ),
      preview: (
        <>
          {exams.endDate && <ExamPreviewLink examId={exams.id} />}
          {exams.startDate &&
            !exams.endDate &&
            process.env.REACT_APP_CHAT_APP_URL && (
              <ExamChatLink examId={exams.id} token={token} />
            )}
        </>
      ),
    },
  };
};

export const ExamSearch: React.FC<ExamSearchProps> = props => {
  const columns = [
    { header: '', property: 'critical_point' },
    { header: 'Numer', property: 'exams.number' },
    { header: 'Badany', property: 'exams.examined' },
    { header: 'PESEL', property: 'examined.pesel' },
    { header: 'Opis', property: 'exams.description' },
    { header: 'Pin', property: 'exams.pin' },
    { header: 'Początek', property: 'exams.startDate' },
    { header: 'Koniec', property: 'exams.endDate' },
    { header: 'Status', property: 'exams.status', noSorting: true },
    { header: '', property: 'preview', noSorting: true },
  ];

  return (
    <Grid
      getData={props.getData}
      getDataCount={props.getCount}
      columns={columns}
      //pageNumber={4}
      showFilter={true}
      initialOrderColumn="exams.number"
      initialOrderDirection="DESC"
      availablePageSizes={[10, 20, 50]}
      onRowClick={props.onRowClick}
      emptyGridMessage="Brak zleconych badań w systemie"
      refresh={props.refresh}
    />
  );
};
