import React, { useState } from 'react';
import { Button, Card, Tab } from 'react-bootstrap';

import Tabs from '../../../app/components/Tabs';
import { useSpdCore } from '../../../common/hooks/useSpdCore';
import { useNavigation } from '../../../common/navigation';
import { CreateUpdateKeyValueDto } from '../../../common/spdCore/autogenerated/spdApiClient';
import { ImportKeyValues } from '../../components/KeyTests/ImportKeyValues';
import { KeyXls } from '../../components/KeyTests/ImportKeyValuesValidator';
import { KeyTest } from '../../components/KeyTests/KeyTests';

export const KeyTestsView: React.FC = () => {
  const [show, setShow] = useState(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const spd = useSpdCore();
  const nav = useNavigation();

  const handleClose = () => {
    setShow(false);
    setRefresh(!refresh);
  };

  const addTestKeys = async (keysJson: KeyXls[]) => {
    const keyTest = {
      testIdentifier: keysJson[0].identyfikator_testu,
      name: keysJson[0].nazwa_klucza,
      keyValues: getKeyValues(keysJson),
    };
    await spd.keyTests.createWithValues(keyTest);
    setRefresh(!refresh);
    setShow(false);
    return {
      saved: true,
      error: '',
    };
  };

  const getKeyValues = (keys: KeyXls[]) => {
    const keyValues: CreateUpdateKeyValueDto[] = [];
    for (const val of keys) {
      keyValues.push({
        taskNumber: val.nr_zadania,
        answer: val.prawidlowa_odpowiedz,
        weight: Number(val.waga),
      });
    }
    return keyValues;
  };

  return (
    <Card>
      <Tabs defaultActiveKey="search" id="keyTests" className="mb-3">
        <Tab eventKey="search" title="Klucze" className="m-3">
          <KeyTest
            getData={spd.keyTests.getAllByPortion}
            getCount={spd.keyTests.getAllCount}
            onClick={nav.keyTest}
            refresh={refresh}
          />
          <Button variant="outline-primary" onClick={() => setShow(true)}>
            Importuj klucze
          </Button>
          <ImportKeyValues
            onImport={addTestKeys}
            show={show}
            handleClose={handleClose}
          />
        </Tab>
      </Tabs>
    </Card>
  );
};
