/*eslint max-lines-per-function: ["error", 200]*/
import { DefaultApi } from '../autogenerated/spdApiClient/apis/DefaultApi';
import { GetCardDto } from '../autogenerated/spdApiClient';
import {
  CreateUpdateCardDto,
  CreateUpdateCardDtoFromJSON,
} from '../autogenerated/spdApiClient/models/CreateUpdateCardDto';
import { GridRecord } from '../../components/Grid/GridDataTypes';
import { cardForGrid } from '../../../spd/components/Cards/CardsSearch';
import { CardType, TemplateType } from '../validation/schemas';

export type CardEntity = GetCardDto;

export interface CardContextInterface {
  get: (id: string) => Promise<CardEntity>;
  getCardByExam: (id: string, type: CardType) => Promise<CardEntity>;
  getAllByExam: (id: string) => Promise<CardEntity[]>;
  getAllByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
  ) => Promise<GridRecord[]>;
  getAllCount: (filterText?: string) => Promise<number>;
  create: (dto: CreateUpdateCardDto) => Promise<GetCardDto>;
  update: (id: string, dto: CreateUpdateCardDto) => Promise<void>;

  cardData: (
    examId: string,
    type: TemplateType,
    card?: GetCardDto,
  ) => Promise<CreateUpdateCardDto>;
}

export const CardContext = (api: DefaultApi) => {
  return {
    get: async (id: string) => {
      return await api.cardControllerGet(id);
    },

    getCardByExam: async (id: string, type: CardType) => {
      return await api.cardControllerGetByExam(id, type);
    },

    getAllByExam: async (id: string) => {
      return await api.cardControllerGetAllByExam(id);
    },

    getAllByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
    ) => {
      const data = await api.cardControllerGetAllByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
      );
      return data.map(cardForGrid);
    },

    getAllCount: (filterText?: string) =>
      api.cardControllerGetAllCount(filterText || ''),

    create: (dto: CreateUpdateCardDto) => api.cardControllerCreate(dto),

    update: (id: string, dto: CreateUpdateCardDto) =>
      api.cardControllerUpdate(id, dto),

    cardData: async (examId: string, type: TemplateType, card?: GetCardDto) => {
      if (card) {
        return CreateUpdateCardDtoFromJSON({
          ...card,
          ...{ psychologistUID: card.psychologist?.uid || '' },
        });
      }
      if (type === TemplateType.Psychological_Exam_card) {
        return CreateUpdateCardDtoFromJSON({
          pdfTemplateType: type,
          pass: true,
          cardDate: new Date(),
          interview: await api.answerControllerGetInterviewText(examId),
          intellectualAbility:
            await api.answerControllerGetIntellectualAbilityText(examId),
          personality: await api.answerControllerGetPersonalityText(examId),
          psychomotorEfficiency:
            await api.answerControllerGetIntellectualPsychomotorEfficiencyText(
              examId,
            ),
          justification: `W standardowo wykonanym badaniu nie stwierdzono wyników poniżej normy. Brak psychologicznych przeciwwskazań do wykonywania pracy na stanowisku kierowcy.`,
          conclusions: `Interpretacji wyników dokonano uwzględniając przedziały ufności i standardowy błąd pomiaru. Nie uzyskano wyników będących podstawą do orzeczenia o istnieniu przeciwwskazań psychologicznych.`,
          recommendations: `Wszystkie uzyskane wyniki w badaniu psychologicznym znajdują się w normie, lub są powyżej normy. Brak dodatkowych zaleceń.`,
          type: CardType.Psychologist,
        });
      }
      if (type === TemplateType.Weapon_psychological_exam_card) {
        return CreateUpdateCardDtoFromJSON({
          pdfTemplateType: type,
          pass: true,
          cardDate: new Date(),
          interview: await api.answerControllerGetInterviewText(examId),
          intellectualAbility:
            await api.answerControllerGetIntellectualAbilityText(examId),
          personality: await api.answerControllerGetPersonalityText(examId),
          psychomotorEfficiency:
            await api.answerControllerGetIntellectualPsychomotorEfficiencyText(
              examId,
            ),
          justification: `W standardowo wykonanym badaniu nie stwierdzono wyników poniżej normy. Brak psychologicznych przeciwwskazań do dysponowania bronią.`,
          conclusions: `Interpretacji wyników dokonano uwzględniając przedziały ufności i standardowy błąd pomiaru. Nie uzyskano wyników będących podstawą do orzeczenia o istnieniu przeciwwskazań psychologicznych.`,
          recommendations: `Wszystkie uzyskane wyniki w badaniu psychologicznym znajdują się w normie, lub są powyżej normy. Brak dodatkowych zaleceń.`,
          type: CardType.PsychologistWeapon,
        });
      }

      if (type === TemplateType.Preventive_Exam_card) {
        return CreateUpdateCardDtoFromJSON({
          type: CardType.Preventive,
          pass: true,
          pdfTemplateType: type,
          cardDate: new Date(),
          physicalSkin: 'Norma',
          physicalSkull: 'Norma',
          physicalLymphNodes: 'Norma',
          physicalNose: 'Norma',
          physicalOropharyngealCavity: 'Norma',
          physicalNeck: 'Norma',
          physicalChest: 'Norma',
          physicalLungs: 'Norma',
          physicalCardiovascularSystem: 'Norma',
          physicalAbdominalCavity: 'Norma',
          physicalGenitourinarySystem: 'Norma',
          physicalLocomotorSystem: 'Norma',
          physicalNervousSystem: 'Norma',
          physicalMentalState: 'Norma',
        });
      }

      if (type === TemplateType.Weapon_card) {
        return CreateUpdateCardDtoFromJSON({
          type: CardType.Weapon,
          pass: true,
          pdfTemplateType: type,
          cardDate: new Date(),
          physicalSkin: 'Norma',
          physicalSkull: 'Norma',
          physicalLymphNodes: 'Norma',
          physicalNose: 'Norma',
          physicalOropharyngealCavity: 'Norma',
          physicalNeck: 'Norma',
          physicalChest: 'Norma',
          physicalLungs: 'Norma',
          physicalCardiovascularSystem: 'Norma',
          physicalAbdominalCavity: 'Norma',
          physicalGenitourinarySystem: 'Norma',
          physicalLocomotorSystem: 'Norma',
          physicalNervousSystem: 'Norma',
          physicalMentalState: 'Norma',
        });
      }
      if (type === TemplateType.Sanitary_Exam_card) {
        return CreateUpdateCardDtoFromJSON({
          type: CardType.Sanitary,
          pass: true,
          pdfTemplateType: type,
          cardDate: new Date(),
          physicalSkin: 'Norma',
          physicalSkull: 'Norma',
          physicalLymphNodes: 'Norma',
          physicalNose: 'Norma',
          physicalOropharyngealCavity: 'Norma',
          physicalNeck: 'Norma',
          physicalChest: 'Norma',
          physicalLungs: 'Norma',
          physicalCardiovascularSystem: 'Norma',
          physicalAbdominalCavity: 'Norma',
          physicalGenitourinarySystem: 'Norma',
          physicalLocomotorSystem: 'Norma',
          physicalNervousSystem: 'Norma',
          physicalMentalState: 'Norma',
        });
      }
      if (type === TemplateType.Sport_card) {
        return CreateUpdateCardDtoFromJSON({
          type: CardType.Sport,
          pass: true,
          pdfTemplateType: type,
          cardDate: new Date(),
        });
      }

      return CreateUpdateCardDtoFromJSON({
        type: CardType.Driver,
        pass: true,
        pdfTemplateType: type,
        cardDate: new Date(),
        physicalSkull: 'Norma',
        physicalSkin: 'Norma',
        eyeball: 'Norma',
        facialAssymetry: 'Norma',
        physicalNeck: 'Norma',
        breathMurmur: 'Norma',
        regularity: 'Norma',
        heartSounds: 'Norma',
        heartRate: 'Norma',
        restRR: 'Norma',
        physicalAbdominalCavity: 'Norma',
        hernias: 'Norma',
        liver: 'Norma',
        muscleStrengthUpper: 'Norma',
        handGrip: 'Norma',
        limitationFingers: 'Norma',
        limitationElbows: 'Norma',
        limitationShoulder: 'Norma',
        limitationFeet: 'Norma',
        limitationKnee: 'Norma',
        movementCervical: 'Norma',
        movementLumbar: 'Norma',
        gaitTest: 'Norma',
        anomalies: 'Norma',
        upperLimbParalysis: 'Norma',
        lowerLimbParalysis: 'Norma',
        muscleAtrophy: 'Norma',
        physicalNervousSystem: 'Norma',
        physicalMentalState: 'Norma',
      });
    },
  };
};
