import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';

import ValidationAlert from '../../../app/components/ValidationAlert';

export type Props = {
  onChangeEmail: (newEmail: string) => Promise<void>;
  currentEmail: string | null | undefined;
};

export const ChangeEmail: React.FC<Props> = ({
  onChangeEmail,
  currentEmail,
}) => {
  const [newEmail, setNewEmail] = useState('');
  const [repeatNewEmail, setRepeatNewEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [repeatEmailError, setRepeatEmailError] = useState('');
  const [showError, setShowError] = useState(false);
  const [errorText, setErrorText] = useState('');

  const showMessage = (txt: string) => {
    setErrorText(txt);
    setShowError(true);
  };

  const handleChangeEmail = async () => {
    setEmailError('');
    setRepeatEmailError('');
    if (!newEmail) {
      setEmailError('Wprowadź nowy adres e-mail.');
      return;
    }
    if (!repeatNewEmail) {
      setRepeatEmailError('Wprowadź powtórzony adres e-mail.');
      return;
    }
    if (newEmail !== repeatNewEmail) {
      setRepeatEmailError('Adresy e-mail nie są identyczne.');
      return;
    }

    onChangeEmail(newEmail)
      .then(() => {
        showMessage('E-mail został zmieniony.');
      })
      .catch(err => {
        showMessage(
          `Nie udała się zmiana adresu e-mail. ${(err as Error).message}`,
        );
      });
  };

  return (
    <>
      <main className="form-signin">
        <Form>
          <h3 className="fw-normal mb-3">Zmień adres e-mail</h3>
          <h5 className="fw-normal">Aktualny adres e-mail:</h5>
          <Form.Group className="mt-3" controlId="Email">
            <Form.Control type="email" value={currentEmail || ''} readOnly />
          </Form.Group>
          <Form.Group className="form-floating mt-3" controlId="newEmail">
            <Form.Control
              type="email"
              placeholder="Nowy adres e-mail"
              onChange={e => setNewEmail(e.target.value)}
            />
            <Form.Label>Nowy adres e-mail</Form.Label>
          </Form.Group>
          <span className="input-form-error">{emailError}</span>
          <Form.Group className="form-floating mt-3" controlId="repeatNewEmail">
            <Form.Control
              type="email"
              placeholder="Powtórz nowy adres e-mail"
              onChange={e => setRepeatNewEmail(e.target.value)}
            />
            <Form.Label>Powtórz nowy adres e-mail</Form.Label>
          </Form.Group>
          <span className="input-form-error">{repeatEmailError}</span>
        </Form>
        <Button
          type="submit"
          className="w-100 btn btn-lg btn-login mt-3"
          onClick={handleChangeEmail}
        >
          Zmień e-mail
        </Button>
      </main>
      <ValidationAlert
        heading={'Zmiana adresu e-mail'}
        variant={'warning'}
        show={showError}
        modal={true}
        onHide={() => {
          setShowError(false);
        }}
        errors={[errorText]}
      />
    </>
  );
};
