import React, { useEffect, useState } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

import { useSpdCore } from '../../../common/hooks/useSpdCore';
import { GetExaminedDto } from '../../../common/spdCore/autogenerated/spdApiClient';
import { examinedData } from '../../../common/spdCore/contexts/ExaminedContext';

import { ExaminedEditor } from './ExaminedEditor';

export const ExaminedFromExamPreviewLink = (props: { examId: string }) => {
  const spd = useSpdCore();
  const [show, setShow] = useState(false);
  const [examined, setExamined] = useState<GetExaminedDto | null | undefined>();

  useEffect(() => {
    spd.exams
      .getExam(props.examId)
      .then(o => {
        setExamined(o?.examined);
      })
      .catch(() => {
        console.error('Error during get exam.');
      });
  }, [spd.exams, props.examId]);

  const handleClick = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  return (
    <>
      <OverlayTrigger overlay={<Tooltip>Podgląd badanego</Tooltip>}>
        <Button
          onClick={e => {
            e.stopPropagation();
            handleClick();
          }}
          variant="btn-info"
        >
          <Icon.Eye />
        </Button>
      </OverlayTrigger>
      {examined && (
        <ExaminedEditor
          id={examined?.id}
          examined={examinedData(examined)}
          show={show}
          handleClose={handleClose}
          readOnly={true}
        />
      )}
    </>
  );
};
