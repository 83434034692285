/* tslint:disable */
/* eslint-disable */
/**
 * SPD
 * Opis api SPD
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AttachmentFileContent,
    AttachmentFileContentFromJSON,
    AttachmentFileContentToJSON,
    CreateUpdateArticleDto,
    CreateUpdateArticleDtoFromJSON,
    CreateUpdateArticleDtoToJSON,
    CreateUpdateAttachmentDto,
    CreateUpdateAttachmentDtoFromJSON,
    CreateUpdateAttachmentDtoToJSON,
    CreateUpdateBatteryDto,
    CreateUpdateBatteryDtoFromJSON,
    CreateUpdateBatteryDtoToJSON,
    CreateUpdateCardDto,
    CreateUpdateCardDtoFromJSON,
    CreateUpdateCardDtoToJSON,
    CreateUpdateCompanyDto,
    CreateUpdateCompanyDtoFromJSON,
    CreateUpdateCompanyDtoToJSON,
    CreateUpdateConsultationDto,
    CreateUpdateConsultationDtoFromJSON,
    CreateUpdateConsultationDtoToJSON,
    CreateUpdateDutyDto,
    CreateUpdateDutyDtoFromJSON,
    CreateUpdateDutyDtoToJSON,
    CreateUpdateExamDto,
    CreateUpdateExamDtoFromJSON,
    CreateUpdateExamDtoToJSON,
    CreateUpdateExamTestDto,
    CreateUpdateExamTestDtoFromJSON,
    CreateUpdateExamTestDtoToJSON,
    CreateUpdateExamWithTestsDto,
    CreateUpdateExamWithTestsDtoFromJSON,
    CreateUpdateExamWithTestsDtoToJSON,
    CreateUpdateExaminedDto,
    CreateUpdateExaminedDtoFromJSON,
    CreateUpdateExaminedDtoToJSON,
    CreateUpdateExamsPackageDto,
    CreateUpdateExamsPackageDtoFromJSON,
    CreateUpdateExamsPackageDtoToJSON,
    CreateUpdateExternalReferralDto,
    CreateUpdateExternalReferralDtoFromJSON,
    CreateUpdateExternalReferralDtoToJSON,
    CreateUpdateHarmfulFactorDto,
    CreateUpdateHarmfulFactorDtoFromJSON,
    CreateUpdateHarmfulFactorDtoToJSON,
    CreateUpdateHealthcareFacilityDto,
    CreateUpdateHealthcareFacilityDtoFromJSON,
    CreateUpdateHealthcareFacilityDtoToJSON,
    CreateUpdateImportedIndicatorTestDto,
    CreateUpdateImportedIndicatorTestDtoFromJSON,
    CreateUpdateImportedIndicatorTestDtoToJSON,
    CreateUpdateIndicatorTestDto,
    CreateUpdateIndicatorTestDtoFromJSON,
    CreateUpdateIndicatorTestDtoToJSON,
    CreateUpdateJudgmentDto,
    CreateUpdateJudgmentDtoFromJSON,
    CreateUpdateJudgmentDtoToJSON,
    CreateUpdateJudgmentNumberingDto,
    CreateUpdateJudgmentNumberingDtoFromJSON,
    CreateUpdateJudgmentNumberingDtoToJSON,
    CreateUpdateKeyTestDto,
    CreateUpdateKeyTestDtoFromJSON,
    CreateUpdateKeyTestDtoToJSON,
    CreateUpdateKeyTestWithKeysDto,
    CreateUpdateKeyTestWithKeysDtoFromJSON,
    CreateUpdateKeyTestWithKeysDtoToJSON,
    CreateUpdateNormWithValuesDto,
    CreateUpdateNormWithValuesDtoFromJSON,
    CreateUpdateNormWithValuesDtoToJSON,
    CreateUpdateOtherPrintDefDto,
    CreateUpdateOtherPrintDefDtoFromJSON,
    CreateUpdateOtherPrintDefDtoToJSON,
    CreateUpdateOtherPrintDto,
    CreateUpdateOtherPrintDtoFromJSON,
    CreateUpdateOtherPrintDtoToJSON,
    CreateUpdatePdfTemplateDto,
    CreateUpdatePdfTemplateDtoFromJSON,
    CreateUpdatePdfTemplateDtoToJSON,
    CreateUpdateResultDto,
    CreateUpdateResultDtoFromJSON,
    CreateUpdateResultDtoToJSON,
    CreateUpdateResultsDto,
    CreateUpdateResultsDtoFromJSON,
    CreateUpdateResultsDtoToJSON,
    CreateUpdateSavedPdfDto,
    CreateUpdateSavedPdfDtoFromJSON,
    CreateUpdateSavedPdfDtoToJSON,
    CreateUpdateSupportingExaminationDictDto,
    CreateUpdateSupportingExaminationDictDtoFromJSON,
    CreateUpdateSupportingExaminationDictDtoToJSON,
    CreateUpdateSupportingExaminationDto,
    CreateUpdateSupportingExaminationDtoFromJSON,
    CreateUpdateSupportingExaminationDtoToJSON,
    CreateUpdateTestDto,
    CreateUpdateTestDtoFromJSON,
    CreateUpdateTestDtoToJSON,
    CreateUpdateUserDto,
    CreateUpdateUserDtoFromJSON,
    CreateUpdateUserDtoToJSON,
    ExamDateDto,
    ExamDateDtoFromJSON,
    ExamDateDtoToJSON,
    ExamSexDto,
    ExamSexDtoFromJSON,
    ExamSexDtoToJSON,
    ExamTestDateDto,
    ExamTestDateDtoFromJSON,
    ExamTestDateDtoToJSON,
    GetAccessDto,
    GetAccessDtoFromJSON,
    GetAccessDtoToJSON,
    GetAnswerDto,
    GetAnswerDtoFromJSON,
    GetAnswerDtoToJSON,
    GetArticleDto,
    GetArticleDtoFromJSON,
    GetArticleDtoToJSON,
    GetAttachmentDto,
    GetAttachmentDtoFromJSON,
    GetAttachmentDtoToJSON,
    GetBatteryDto,
    GetBatteryDtoFromJSON,
    GetBatteryDtoToJSON,
    GetBatteryWithTestsDto,
    GetBatteryWithTestsDtoFromJSON,
    GetBatteryWithTestsDtoToJSON,
    GetCardDto,
    GetCardDtoFromJSON,
    GetCardDtoToJSON,
    GetCompanyDto,
    GetCompanyDtoFromJSON,
    GetCompanyDtoToJSON,
    GetConsultationDto,
    GetConsultationDtoFromJSON,
    GetConsultationDtoToJSON,
    GetCriticalPointDto,
    GetCriticalPointDtoFromJSON,
    GetCriticalPointDtoToJSON,
    GetDocumentationDto,
    GetDocumentationDtoFromJSON,
    GetDocumentationDtoToJSON,
    GetDutyDto,
    GetDutyDtoFromJSON,
    GetDutyDtoToJSON,
    GetEmailDto,
    GetEmailDtoFromJSON,
    GetEmailDtoToJSON,
    GetExamDto,
    GetExamDtoFromJSON,
    GetExamDtoToJSON,
    GetExamTestDto,
    GetExamTestDtoFromJSON,
    GetExamTestDtoToJSON,
    GetExaminedDto,
    GetExaminedDtoFromJSON,
    GetExaminedDtoToJSON,
    GetExamsPackageDto,
    GetExamsPackageDtoFromJSON,
    GetExamsPackageDtoToJSON,
    GetHarmfulFactorDto,
    GetHarmfulFactorDtoFromJSON,
    GetHarmfulFactorDtoToJSON,
    GetHealthcareFacilityDto,
    GetHealthcareFacilityDtoFromJSON,
    GetHealthcareFacilityDtoToJSON,
    GetIndicatorTestDto,
    GetIndicatorTestDtoFromJSON,
    GetIndicatorTestDtoToJSON,
    GetIndicatorTestRecalculatedDto,
    GetIndicatorTestRecalculatedDtoFromJSON,
    GetIndicatorTestRecalculatedDtoToJSON,
    GetInterpretationNormDto,
    GetInterpretationNormDtoFromJSON,
    GetInterpretationNormDtoToJSON,
    GetJudgmentDto,
    GetJudgmentDtoFromJSON,
    GetJudgmentDtoToJSON,
    GetJudgmentExtendedDto,
    GetJudgmentExtendedDtoFromJSON,
    GetJudgmentExtendedDtoToJSON,
    GetJudgmentNumberingDto,
    GetJudgmentNumberingDtoFromJSON,
    GetJudgmentNumberingDtoToJSON,
    GetKeyTestDto,
    GetKeyTestDtoFromJSON,
    GetKeyTestDtoToJSON,
    GetKeyValueDto,
    GetKeyValueDtoFromJSON,
    GetKeyValueDtoToJSON,
    GetNormDto,
    GetNormDtoFromJSON,
    GetNormDtoToJSON,
    GetOtherPrintDefDto,
    GetOtherPrintDefDtoFromJSON,
    GetOtherPrintDefDtoToJSON,
    GetOtherPrintDto,
    GetOtherPrintDtoFromJSON,
    GetOtherPrintDtoToJSON,
    GetPdfTemplateDto,
    GetPdfTemplateDtoFromJSON,
    GetPdfTemplateDtoToJSON,
    GetPositionNormDto,
    GetPositionNormDtoFromJSON,
    GetPositionNormDtoToJSON,
    GetRegisterDto,
    GetRegisterDtoFromJSON,
    GetRegisterDtoToJSON,
    GetReportCompanyDto,
    GetReportCompanyDtoFromJSON,
    GetReportCompanyDtoToJSON,
    GetReportConsultationDto,
    GetReportConsultationDtoFromJSON,
    GetReportConsultationDtoToJSON,
    GetReportJudgmentDto,
    GetReportJudgmentDtoFromJSON,
    GetReportJudgmentDtoToJSON,
    GetResultDto,
    GetResultDtoFromJSON,
    GetResultDtoToJSON,
    GetResultForTestsDto,
    GetResultForTestsDtoFromJSON,
    GetResultForTestsDtoToJSON,
    GetSavedPdfExternalReferralDto,
    GetSavedPdfExternalReferralDtoFromJSON,
    GetSavedPdfExternalReferralDtoToJSON,
    GetSavedPdfSignatureStatusDto,
    GetSavedPdfSignatureStatusDtoFromJSON,
    GetSavedPdfSignatureStatusDtoToJSON,
    GetStampDto,
    GetStampDtoFromJSON,
    GetStampDtoToJSON,
    GetSupervisorsDto,
    GetSupervisorsDtoFromJSON,
    GetSupervisorsDtoToJSON,
    GetSupportingExaminationDictDto,
    GetSupportingExaminationDictDtoFromJSON,
    GetSupportingExaminationDictDtoToJSON,
    GetSupportingExaminationDto,
    GetSupportingExaminationDtoFromJSON,
    GetSupportingExaminationDtoToJSON,
    GetTestDto,
    GetTestDtoFromJSON,
    GetTestDtoToJSON,
    GetUserDto,
    GetUserDtoFromJSON,
    GetUserDtoToJSON,
    GetVersionInfoDto,
    GetVersionInfoDtoFromJSON,
    GetVersionInfoDtoToJSON,
} from '../models';

export interface AccessControllerCheckAccessRequest {
    device: string;
}

export interface AccessControllerGetRequest {
    id: string;
}

export interface AccessControllerGetAllByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
}

export interface AccessControllerGetAllCountRequest {
    filterText: string;
}

export interface AnswerControllerGetRequest {
    id: string;
}

export interface AnswerControllerGetIntellectualAbilityTextRequest {
    examId: string;
}

export interface AnswerControllerGetIntellectualPsychomotorEfficiencyTextRequest {
    examId: string;
}

export interface AnswerControllerGetInterviewTextRequest {
    examId: string;
}

export interface AnswerControllerGetPersonalityTextRequest {
    examId: string;
}

export interface ArticleControllerChangeToInactiveRequest {
    id: string;
}

export interface ArticleControllerCreateRequest {
    createUpdateArticleDto: CreateUpdateArticleDto;
}

export interface ArticleControllerGetRequest {
    id: string;
}

export interface ArticleControllerGetAllRequest {
    mode?: string;
}

export interface ArticleControllerUpdateRequest {
    id: string;
    createUpdateArticleDto: CreateUpdateArticleDto;
}

export interface AttachmentControllerCreateRequest {
    createUpdateAttachmentDto: CreateUpdateAttachmentDto;
}

export interface AttachmentControllerDeleteRequest {
    id: string;
}

export interface AttachmentControllerGetRequest {
    id: string;
}

export interface AttachmentControllerGetAllForExaminedRequest {
    examinedId: string;
}

export interface AttachmentControllerGetFileDownloadRequest {
    id: string;
}

export interface AttachmentControllerUpdateRequest {
    id: string;
    createUpdateAttachmentDto: CreateUpdateAttachmentDto;
}

export interface BatteryControllerCreateRequest {
    createUpdateBatteryDto: CreateUpdateBatteryDto;
}

export interface BatteryControllerGetRequest {
    id: string;
}

export interface BatteryControllerGetAllByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
}

export interface BatteryControllerGetAllCountRequest {
    filterText: string;
}

export interface BatteryControllerUpdateRequest {
    id: string;
    createUpdateBatteryDto: CreateUpdateBatteryDto;
}

export interface CardControllerCreateRequest {
    createUpdateCardDto: CreateUpdateCardDto;
}

export interface CardControllerGetRequest {
    id: string;
}

export interface CardControllerGetAllByExamRequest {
    examId: string;
}

export interface CardControllerGetAllByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
}

export interface CardControllerGetAllCountRequest {
    filterText: string;
}

export interface CardControllerGetByExamRequest {
    examId: string;
    type: string;
}

export interface CardControllerUpdateRequest {
    id: string;
    createUpdateCardDto: CreateUpdateCardDto;
}

export interface CompanyControllerCreateRequest {
    createUpdateCompanyDto: CreateUpdateCompanyDto;
}

export interface CompanyControllerGetRequest {
    id: string;
}

export interface CompanyControllerGetAllByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
}

export interface CompanyControllerGetAllCountRequest {
    filterText: string;
}

export interface CompanyControllerGetByEmailRequest {
    pattern: string;
    limit: number;
}

export interface CompanyControllerGetByNipRequest {
    pattern: string;
    limit: number;
}

export interface CompanyControllerGetByPatternRequest {
    pattern: string;
    limit: number;
}

export interface CompanyControllerGetByUserUidRequest {
    id: string;
}

export interface CompanyControllerUpdateRequest {
    id: string;
    createUpdateCompanyDto: CreateUpdateCompanyDto;
}

export interface ConsultationControllerCreateRequest {
    createUpdateConsultationDto: CreateUpdateConsultationDto;
}

export interface ConsultationControllerGetRequest {
    id: string;
}

export interface ConsultationControllerGetAllByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
}

export interface ConsultationControllerGetAllCountRequest {
    filterText: string;
}

export interface ConsultationControllerGetForExamRequest {
    id: string;
}

export interface ConsultationControllerUpdateRequest {
    id: string;
    createUpdateConsultationDto: CreateUpdateConsultationDto;
}

export interface DocumentationControllerGetForFilenameRequest {
    filename: string;
}

export interface DutyControllerCreateRequest {
    createUpdateDutyDto: CreateUpdateDutyDto;
}

export interface DutyControllerGetRequest {
    id: string;
}

export interface DutyControllerGetAllByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
}

export interface DutyControllerGetAllCountRequest {
    filterText: string;
}

export interface DutyControllerUpdateRequest {
    id: string;
    createUpdateDutyDto: CreateUpdateDutyDto;
}

export interface EmailControllerChangeToInactiveRequest {
    id: string;
}

export interface EmailControllerGetAllByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
    onlyNotSent: boolean;
}

export interface EmailControllerGetAllCountRequest {
    filterText: string;
    onlyNotSent: boolean;
}

export interface EmailControllerResendEmailRequest {
    id: string;
    userId: string;
}

export interface EmailControllerSendAllWaitingEmailsRequest {
    userId: string;
}

export interface EmailControllerSendJudgmentByEmailRequest {
    judgmentId: string;
    userId: string;
    english: boolean;
}

export interface EmailControllerSendPsychologicalCardSummaryRequest {
    cardId: string;
}

export interface EmailControllerSendReferralByEmailRequest {
    judgmentId: string;
    userId: string;
}

export interface ExamControllerAlarmRequest {
    id: string;
}

export interface ExamControllerChangeDatesRequest {
    id: string;
    examDateDto: ExamDateDto;
}

export interface ExamControllerChangeSexRequest {
    id: string;
    examSexDto: ExamSexDto;
}

export interface ExamControllerChangeToInactiveRequest {
    id: string;
}

export interface ExamControllerCreateWithTestsRequest {
    createUpdateExamWithTestsDto: CreateUpdateExamWithTestsDto;
}

export interface ExamControllerGetRequest {
    id: string;
}

export interface ExamControllerGetActiveByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
    type?: string;
}

export interface ExamControllerGetActiveCountRequest {
    filterText: string;
    type?: string;
}

export interface ExamControllerGetAllByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
    type?: string;
}

export interface ExamControllerGetAllCountRequest {
    filterText: string;
    type?: string;
}

export interface ExamControllerGetCriticalPointRequest {
    id: string;
}

export interface ExamControllerGetForExaminedByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
    examinedId?: string;
}

export interface ExamControllerGetForExaminedCountRequest {
    filterText: string;
    examinedId?: string;
}

export interface ExamControllerGetForPackageByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
    examPackageId?: string;
}

export interface ExamControllerGetForPackageCountRequest {
    filterText: string;
    examPackageId?: string;
}

export interface ExamControllerGetForSpecialistByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
    type?: string;
}

export interface ExamControllerGetForSpecialistCountRequest {
    filterText: string;
    type?: string;
}

export interface ExamControllerGetSupervisorsRequest {
    id: string;
}

export interface ExamControllerGetWithExaminedByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
    type?: string;
}

export interface ExamControllerGetWithExaminedCountRequest {
    filterText: string;
    type?: string;
}

export interface ExamControllerGetWithoutJudgmentByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
    type?: string;
}

export interface ExamControllerGetWithoutJudgmentCountRequest {
    filterText: string;
    type?: string;
}

export interface ExamControllerUpdateRequest {
    id: string;
    createUpdateExamDto: CreateUpdateExamDto;
}

export interface ExamControllerUpdateWithTestsRequest {
    examId: string;
    createUpdateExamWithTestsDto: CreateUpdateExamWithTestsDto;
}

export interface ExamTestControllerChangeDatesRequest {
    examId: string;
    testId: string;
    examTestDateDto: ExamTestDateDto;
}

export interface ExamTestControllerCreateRequest {
    createUpdateExamTestDto: CreateUpdateExamTestDto;
}

export interface ExamTestControllerGetRequest {
    id: string;
}

export interface ExamTestControllerGetByExamRequest {
    examId: string;
}

export interface ExamTestControllerGetForExamByPortionRequest {
    exam: string;
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
}

export interface ExamTestControllerGetForExamCountRequest {
    exam: string;
    filterText: string;
}

export interface ExamTestControllerUpdateRequest {
    id: string;
    createUpdateExamTestDto: CreateUpdateExamTestDto;
}

export interface ExaminedControllerChangeToInactiveRequest {
    id: string;
}

export interface ExaminedControllerCreateRequest {
    createUpdateExaminedDto: CreateUpdateExaminedDto;
}

export interface ExaminedControllerGetRequest {
    id: string;
}

export interface ExaminedControllerGetAllByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
}

export interface ExaminedControllerGetAllCountRequest {
    filterText: string;
}

export interface ExaminedControllerGetCitiesByPatternRequest {
    pattern: string;
    limit: number;
}

export interface ExaminedControllerGetCityByPostCodeRequest {
    postcode: string;
}

export interface ExaminedControllerGetStreetsByPatternRequest {
    pattern: string;
    limit: number;
}

export interface ExaminedControllerMergeExaminedRequest {
    examinedDeleteId: string;
    examinedStayId: string;
}

export interface ExaminedControllerUpdateRequest {
    id: string;
    createUpdateExaminedDto: CreateUpdateExaminedDto;
}

export interface ExamsPackageControllerDeleteExpectationExamsRequest {
    id: string;
}

export interface ExamsPackageControllerGetRequest {
    id: string;
}

export interface ExamsPackageControllerGetAllByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
    type?: string;
}

export interface ExamsPackageControllerGetAllCountRequest {
    filterText: string;
    type?: string;
}

export interface ExamsPackageControllerGetExpectationPinsRequest {
    id: string;
}

export interface ExamsPackageControllerUpdateRequest {
    id: string;
    createUpdateExamsPackageDto: CreateUpdateExamsPackageDto;
}

export interface ExportControllerExportTestResultsRequest {
    ident: string;
}

export interface ExternalReferralControllerCreateRequest {
    createUpdateExternalReferralDto: CreateUpdateExternalReferralDto;
}

export interface HarmfulFactorControllerCreateRequest {
    createUpdateHarmfulFactorDto: CreateUpdateHarmfulFactorDto;
}

export interface HarmfulFactorControllerGetRequest {
    id: string;
}

export interface HarmfulFactorControllerGetAllByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
}

export interface HarmfulFactorControllerGetAllCountRequest {
    filterText: string;
}

export interface HarmfulFactorControllerUpdateRequest {
    id: string;
    createUpdateHarmfulFactorDto: CreateUpdateHarmfulFactorDto;
}

export interface HealthcareFacilityControllerCreateRequest {
    createUpdateHealthcareFacilityDto: CreateUpdateHealthcareFacilityDto;
}

export interface HealthcareFacilityControllerGetRequest {
    id: string;
}

export interface HealthcareFacilityControllerGetAllByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
}

export interface HealthcareFacilityControllerGetAllCountRequest {
    filterText: string;
}

export interface HealthcareFacilityControllerUpdateRequest {
    id: string;
    createUpdateHealthcareFacilityDto: CreateUpdateHealthcareFacilityDto;
}

export interface IndicatorTestControllerCreateRequest {
    createUpdateIndicatorTestDto: CreateUpdateIndicatorTestDto;
}

export interface IndicatorTestControllerCreateImportedRequest {
    createUpdateImportedIndicatorTestDto: CreateUpdateImportedIndicatorTestDto;
}

export interface IndicatorTestControllerGetRequest {
    id: string;
}

export interface IndicatorTestControllerGetAllByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
}

export interface IndicatorTestControllerGetAllCountRequest {
    filterText: string;
}

export interface IndicatorTestControllerGetByExamTestRequest {
    examTestId: string;
}

export interface IndicatorTestControllerUpdateRequest {
    id: string;
    createUpdateIndicatorTestDto: CreateUpdateIndicatorTestDto;
}

export interface InterpretationNormControllerGetByNormRequest {
    normId: string;
}

export interface InterpretationNormControllerGetByNormCountRequest {
    normId: string;
}

export interface JudgmentControllerChangeToInactiveRequest {
    id: string;
}

export interface JudgmentControllerCreateRequest {
    createUpdateJudgmentDto: CreateUpdateJudgmentDto;
}

export interface JudgmentControllerGetRequest {
    id: string;
}

export interface JudgmentControllerGetAllByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
}

export interface JudgmentControllerGetAllCountRequest {
    filterText: string;
}

export interface JudgmentControllerGetAllForUserRegisterToSignRequest {
    userUid: string;
    registerId?: string;
}

export interface JudgmentControllerGetForExamRequest {
    id: string;
}

export interface JudgmentControllerGetForRegisterByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
    registerId: string;
    dateFrom?: Date;
    dateTo?: Date;
    userUid?: string;
    healthcareFacilityId?: string;
}

export interface JudgmentControllerGetForRegisterCountRequest {
    filterText: string;
    registerId: string;
    dateFrom?: Date;
    dateTo?: Date;
    userUid?: string;
    healthcareFacilityId?: string;
}

export interface JudgmentControllerGetForSigningByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
    userUid?: string;
}

export interface JudgmentControllerGetForSigningCountRequest {
    filterText: string;
    userUid?: string;
}

export interface JudgmentControllerGetWithoutElectronicSigningByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
    userUid?: string;
    dateFrom?: Date;
    dateTo?: Date;
}

export interface JudgmentControllerGetWithoutElectronicSigningCountRequest {
    filterText: string;
    userUid?: string;
    dateFrom?: Date;
    dateTo?: Date;
}

export interface JudgmentControllerUpdateRequest {
    id: string;
    createUpdateJudgmentDto: CreateUpdateJudgmentDto;
}

export interface JudgmentNumberingControllerCreateRequest {
    createUpdateJudgmentNumberingDto: CreateUpdateJudgmentNumberingDto;
}

export interface JudgmentNumberingControllerGetRequest {
    id: string;
}

export interface JudgmentNumberingControllerUpdateRequest {
    id: string;
    createUpdateJudgmentNumberingDto: CreateUpdateJudgmentNumberingDto;
}

export interface KeyTestControllerCreateRequest {
    createUpdateKeyTestDto: CreateUpdateKeyTestDto;
}

export interface KeyTestControllerCreateWithValuesRequest {
    createUpdateKeyTestWithKeysDto: CreateUpdateKeyTestWithKeysDto;
}

export interface KeyTestControllerGetRequest {
    id: string;
}

export interface KeyTestControllerGetAllByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
}

export interface KeyTestControllerGetAllCountRequest {
    filterText: string;
}

export interface KeyTestControllerUpdateRequest {
    id: string;
    createUpdateKeyTestDto: CreateUpdateKeyTestDto;
}

export interface KeyValueControllerGetByKeyRequest {
    keyTestId: string;
}

export interface KeyValueControllerGetByKeyCountRequest {
    keyTestId: string;
}

export interface NormControllerCreateWithValuesRequest {
    createUpdateNormWithValuesDto: CreateUpdateNormWithValuesDto;
}

export interface NormControllerGetRequest {
    id: string;
}

export interface NormControllerGetAllByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
}

export interface NormControllerGetAllCountRequest {
    filterText: string;
}

export interface OtherPrintControllerChangeToInactiveRequest {
    id: string;
}

export interface OtherPrintControllerCreateRequest {
    createUpdateOtherPrintDto: CreateUpdateOtherPrintDto;
}

export interface OtherPrintControllerGetRequest {
    id: string;
}

export interface OtherPrintControllerGetAllByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
}

export interface OtherPrintControllerGetAllCountRequest {
    filterText: string;
}

export interface OtherPrintControllerGetForExamRequest {
    id: string;
}

export interface OtherPrintControllerGetForSigningByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
    userUid?: string;
}

export interface OtherPrintControllerGetForSigningCountRequest {
    filterText: string;
    userUid?: string;
}

export interface OtherPrintControllerUpdateRequest {
    id: string;
    createUpdateOtherPrintDto: CreateUpdateOtherPrintDto;
}

export interface OtherPrintDefControllerChangeToInactiveRequest {
    id: string;
}

export interface OtherPrintDefControllerCreateRequest {
    createUpdateOtherPrintDefDto: CreateUpdateOtherPrintDefDto;
}

export interface OtherPrintDefControllerGetRequest {
    id: string;
}

export interface OtherPrintDefControllerGetAllRequest {
    mode?: string;
}

export interface OtherPrintDefControllerUpdateRequest {
    id: string;
    createUpdateOtherPrintDefDto: CreateUpdateOtherPrintDefDto;
}

export interface PdfPrinterControllerLoadAndPrintCardRequest {
    id: string;
}

export interface PdfPrinterControllerLoadAndPrintConsultationRequest {
    id: string;
}

export interface PdfPrinterControllerLoadAndPrintJudgmentRequest {
    id: string;
}

export interface PdfPrinterControllerLoadAndPrintJudgmentInEnglishRequest {
    id: string;
}

export interface PdfPrinterControllerLoadAndPrintSupportingExaminationRequest {
    id: string;
}

export interface PdfPrinterControllerPrintAnswersRequest {
    testId: string;
    examId: string;
}

export interface PdfPrinterControllerPrintCompaniesRequest {
    monthlySettlement: boolean;
}

export interface PdfPrinterControllerPrintMultiplePdfRequest {
    userUid?: string;
    dateFrom?: Date;
    dateTo?: Date;
}

export interface PdfPrinterControllerPrintRegisterJudgmentRequest {
    registerId: string;
    dateFrom: Date;
    dateTo?: Date;
    userUid?: string;
    healthcareFacilityId?: string;
}

export interface PdfPrinterControllerPrintReportJudgmentsRequest {
    filterText: string;
    psychologistId?: string;
    companyId?: string;
    registerId?: string;
    healthcareFacilityId?: string;
    dateFrom?: Date;
    dateTo?: Date;
    onlyMonthlySettlement?: string;
}

export interface PdfPrinterControllerSaveAndPrintCardRequest {
    id: string;
}

export interface PdfPrinterControllerSaveAndPrintConsultationRequest {
    type: string;
    id: string;
}

export interface PdfPrinterControllerSaveAndPrintJudgmentRequest {
    id: string;
}

export interface PdfPrinterControllerSaveAndPrintSupportingExaminationRequest {
    id: string;
}

export interface PdfTemplateControllerGetRequest {
    id: string;
}

export interface PdfTemplateControllerGetStampPositionRequest {
    judgmentId: string;
}

export interface PdfTemplateControllerUpdateRequest {
    id: string;
    createUpdatePdfTemplateDto: CreateUpdatePdfTemplateDto;
}

export interface PositionNormControllerGetByNormRequest {
    normId: string;
}

export interface PositionNormControllerGetByNormCountRequest {
    normId: string;
}

export interface RegisterControllerGetRequest {
    id: string;
}

export interface RegisterControllerGetAllByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
}

export interface RegisterControllerGetAllCountRequest {
    filterText: string;
}

export interface RegisterControllerGetByModeRequest {
    mode: string;
}

export interface ReportControllerGetCompaniesByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
    dateFrom?: Date;
    dateTo?: Date;
    onlyMonthlySettlement?: boolean;
    companyId?: string;
    healthcareFacilityId?: string;
    withHealthcareFacility?: boolean;
    withRegister?: boolean;
}

export interface ReportControllerGetCompaniesCountRequest {
    filterText: string;
    dateFrom?: Date;
    dateTo?: Date;
    onlyMonthlySettlement?: boolean;
    companyId?: string;
    healthcareFacilityId?: string;
    withHealthcareFacility?: boolean;
    withRegister?: boolean;
}

export interface ReportControllerGetCompaniesDetailsByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
    psychologistId?: string;
    companyId?: string;
    registerId?: string;
    healthcareFacilityId?: string;
    dateFrom?: Date;
    dateTo?: Date;
    onlyMonthlySettlement?: string;
}

export interface ReportControllerGetCompaniesDetailsCountRequest {
    filterText: string;
    psychologistId?: string;
    companyId?: string;
    registerId?: string;
    healthcareFacilityId?: string;
    dateFrom?: Date;
    dateTo?: Date;
    onlyMonthlySettlement?: string;
}

export interface ReportControllerGetConsultationsByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
    dateFrom?: Date;
    dateTo?: Date;
    userUid?: string;
    companyId?: string;
    healthcareFacilityId?: string;
    withHealthcareFacility?: boolean;
    withType?: boolean;
}

export interface ReportControllerGetConsultationsCountRequest {
    filterText: string;
    dateFrom?: Date;
    dateTo?: Date;
    userUid?: string;
    companyId?: string;
    healthcareFacilityId?: string;
    withHealthcareFacility?: boolean;
    withType?: boolean;
}

export interface ReportControllerGetJudgmentsByCreatorByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
    dateFrom?: Date;
    dateTo?: Date;
}

export interface ReportControllerGetJudgmentsByCreatorCountRequest {
    filterText: string;
    dateFrom?: Date;
    dateTo?: Date;
}

export interface ReportControllerGetJudgmentsByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
    dateFrom?: Date;
    dateTo?: Date;
    userUid?: string;
    companyId?: string;
    healthcareFacilityId?: string;
    withHealthcareFacility?: boolean;
    withRegister?: boolean;
}

export interface ReportControllerGetJudgmentsCountRequest {
    filterText: string;
    dateFrom?: Date;
    dateTo?: Date;
    userUid?: string;
    companyId?: string;
    healthcareFacilityId?: string;
    withHealthcareFacility?: boolean;
    withRegister?: boolean;
}

export interface ResultControllerGetByExamTestRequest {
    examId: string;
    testId: string;
}

export interface ResultControllerGetByExamTestIdRequest {
    id: string;
}

export interface ResultControllerSaveResultRequest {
    examId: string;
    testId: string;
    createUpdateResultDto: CreateUpdateResultDto;
}

export interface ResultControllerSaveResultsRequest {
    examId: string;
    testId: string;
    createUpdateResultsDto: CreateUpdateResultsDto;
}

export interface SavedPdfControllerGetExternalReferralForExaminedRequest {
    examinedId: string;
}

export interface SavedPdfControllerGetFileDownloadRequest {
    id: string;
}

export interface SavedPdfControllerGetJudgmentSignatureStatusRequest {
    id: string;
}

export interface SavedPdfControllerSavePdfFileRequest {
    createUpdateSavedPdfDto: CreateUpdateSavedPdfDto;
}

export interface SavedPdfControllerSavePdfFileForReferralRequest {
    createUpdateSavedPdfDto: CreateUpdateSavedPdfDto;
}

export interface StampControllerGetForJudgmentRequest {
    userId: string;
    judgmentId: string;
}

export interface SupportingExaminationControllerCreateRequest {
    createUpdateSupportingExaminationDto: CreateUpdateSupportingExaminationDto;
}

export interface SupportingExaminationControllerDeleteRequest {
    id: string;
}

export interface SupportingExaminationControllerGetRequest {
    id: string;
}

export interface SupportingExaminationControllerGetAllByExamRequest {
    examId: string;
}

export interface SupportingExaminationControllerGetAllForUserToSignRequest {
    userUid?: string;
}

export interface SupportingExaminationControllerGetWithPrintsByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
}

export interface SupportingExaminationControllerGetWithPrintsCountRequest {
    filterText: string;
}

export interface SupportingExaminationControllerGetWithPrintsForSigningByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
    userUid?: string;
}

export interface SupportingExaminationControllerGetWithPrintsForSigningCountRequest {
    filterText: string;
    userUid?: string;
}

export interface SupportingExaminationControllerUpdateRequest {
    id: string;
    createUpdateSupportingExaminationDto: CreateUpdateSupportingExaminationDto;
}

export interface SupportingExaminationDictControllerCreateRequest {
    createUpdateSupportingExaminationDictDto: CreateUpdateSupportingExaminationDictDto;
}

export interface SupportingExaminationDictControllerGetRequest {
    id: string;
}

export interface SupportingExaminationDictControllerGetAllByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
}

export interface SupportingExaminationDictControllerGetAllCountRequest {
    filterText: string;
}

export interface SupportingExaminationDictControllerUpdateRequest {
    id: string;
    createUpdateSupportingExaminationDictDto: CreateUpdateSupportingExaminationDictDto;
}

export interface SzafirControllerGetTaskListForJudgmentRequest {
    documentId: string;
    english: boolean;
    userId?: string;
}

export interface SzafirControllerGetTaskListForSupportingExaminationRequest {
    documentId: string;
    userId?: string;
}

export interface TestControllerCreateRequest {
    createUpdateTestDto: CreateUpdateTestDto;
}

export interface TestControllerGetRequest {
    id: string;
}

export interface TestControllerGetAllByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
}

export interface TestControllerGetAllCountRequest {
    filterText: string;
}

export interface TestControllerGetByBatteryRequest {
    batteryId: string;
}

export interface TestControllerGetByIdentifierRequest {
    identifier: string;
}

export interface TestControllerUpdateRequest {
    id: string;
    createUpdateTestDto: CreateUpdateTestDto;
}

export interface TestGivingControllerGetExamByIdRequest {
    id: string;
}

export interface TestGivingControllerGetExamByPinRequest {
    pin: string;
}

export interface TestGivingControllerGetExamByPinExamIdExternalRequest {
    pin: string;
    examId: string;
}

export interface UserControllerCreateRequest {
    createUpdateUserDto: CreateUpdateUserDto;
}

export interface UserControllerGetRequest {
    id: string;
}

export interface UserControllerGetAllByPortionRequest {
    pageSize: number;
    pageNumber: number;
    orderBy: string;
    orderDirection: string;
    filterText: string;
    mode?: string;
}

export interface UserControllerGetAllByRolesRequest {
    roles: Array<string>;
}

export interface UserControllerGetAllCountRequest {
    filterText: string;
    mode?: string;
}

export interface UserControllerGetByEmailRequest {
    email: string;
}

export interface UserControllerGetByUidRequest {
    uid: string;
}

export interface UserControllerUpdateRequest {
    id: string;
    createUpdateUserDto: CreateUpdateUserDto;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     */
    async accessControllerCheckAccessRaw(requestParameters: AccessControllerCheckAccessRequest): Promise<runtime.ApiResponse<Array<GetAccessDto>>> {
        if (requestParameters.device === null || requestParameters.device === undefined) {
            throw new runtime.RequiredError('device','Required parameter requestParameters.device was null or undefined when calling accessControllerCheckAccess.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.device !== undefined) {
            queryParameters['device'] = requestParameters.device;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/access/checkAccess/byDevice`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetAccessDtoFromJSON));
    }

    /**
     */
    async accessControllerCheckAccess(device: string): Promise<Array<GetAccessDto>> {
        const response = await this.accessControllerCheckAccessRaw({ device: device });
        return await response.value();
    }

    /**
     */
    async accessControllerGetRaw(requestParameters: AccessControllerGetRequest): Promise<runtime.ApiResponse<GetAccessDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling accessControllerGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/access/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetAccessDtoFromJSON(jsonValue));
    }

    /**
     */
    async accessControllerGet(id: string): Promise<GetAccessDto> {
        const response = await this.accessControllerGetRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async accessControllerGetAllByPortionRaw(requestParameters: AccessControllerGetAllByPortionRequest): Promise<runtime.ApiResponse<Array<GetAccessDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling accessControllerGetAllByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling accessControllerGetAllByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling accessControllerGetAllByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling accessControllerGetAllByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling accessControllerGetAllByPortion.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/access/search/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetAccessDtoFromJSON));
    }

    /**
     */
    async accessControllerGetAllByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string): Promise<Array<GetAccessDto>> {
        const response = await this.accessControllerGetAllByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText });
        return await response.value();
    }

    /**
     */
    async accessControllerGetAllCountRaw(requestParameters: AccessControllerGetAllCountRequest): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling accessControllerGetAllCount.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/access/search/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async accessControllerGetAllCount(filterText: string): Promise<number> {
        const response = await this.accessControllerGetAllCountRaw({ filterText: filterText });
        return await response.value();
    }

    /**
     */
    async answerControllerChangeAllCardsRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/answer/changeAllCards`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async answerControllerChangeAllCards(): Promise<void> {
        await this.answerControllerChangeAllCardsRaw();
    }

    /**
     */
    async answerControllerGetRaw(requestParameters: AnswerControllerGetRequest): Promise<runtime.ApiResponse<GetAnswerDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling answerControllerGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/answer/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetAnswerDtoFromJSON(jsonValue));
    }

    /**
     */
    async answerControllerGet(id: string): Promise<GetAnswerDto> {
        const response = await this.answerControllerGetRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async answerControllerGetIntellectualAbilityTextRaw(requestParameters: AnswerControllerGetIntellectualAbilityTextRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.examId === null || requestParameters.examId === undefined) {
            throw new runtime.RequiredError('examId','Required parameter requestParameters.examId was null or undefined when calling answerControllerGetIntellectualAbilityText.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.examId !== undefined) {
            queryParameters['examId'] = requestParameters.examId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/answer/get/intellectualAbility`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async answerControllerGetIntellectualAbilityText(examId: string): Promise<string> {
        const response = await this.answerControllerGetIntellectualAbilityTextRaw({ examId: examId });
        return await response.value();
    }

    /**
     */
    async answerControllerGetIntellectualPsychomotorEfficiencyTextRaw(requestParameters: AnswerControllerGetIntellectualPsychomotorEfficiencyTextRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.examId === null || requestParameters.examId === undefined) {
            throw new runtime.RequiredError('examId','Required parameter requestParameters.examId was null or undefined when calling answerControllerGetIntellectualPsychomotorEfficiencyText.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.examId !== undefined) {
            queryParameters['examId'] = requestParameters.examId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/answer/get/intellectualPsychomotorEfficiencyText`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async answerControllerGetIntellectualPsychomotorEfficiencyText(examId: string): Promise<string> {
        const response = await this.answerControllerGetIntellectualPsychomotorEfficiencyTextRaw({ examId: examId });
        return await response.value();
    }

    /**
     */
    async answerControllerGetInterviewTextRaw(requestParameters: AnswerControllerGetInterviewTextRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.examId === null || requestParameters.examId === undefined) {
            throw new runtime.RequiredError('examId','Required parameter requestParameters.examId was null or undefined when calling answerControllerGetInterviewText.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.examId !== undefined) {
            queryParameters['examId'] = requestParameters.examId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/answer/get/interviewText`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async answerControllerGetInterviewText(examId: string): Promise<string> {
        const response = await this.answerControllerGetInterviewTextRaw({ examId: examId });
        return await response.value();
    }

    /**
     */
    async answerControllerGetPersonalityTextRaw(requestParameters: AnswerControllerGetPersonalityTextRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.examId === null || requestParameters.examId === undefined) {
            throw new runtime.RequiredError('examId','Required parameter requestParameters.examId was null or undefined when calling answerControllerGetPersonalityText.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.examId !== undefined) {
            queryParameters['examId'] = requestParameters.examId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/answer/get/personalityText`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async answerControllerGetPersonalityText(examId: string): Promise<string> {
        const response = await this.answerControllerGetPersonalityTextRaw({ examId: examId });
        return await response.value();
    }

    /**
     */
    async articleControllerChangeToInactiveRaw(requestParameters: ArticleControllerChangeToInactiveRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling articleControllerChangeToInactive.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/article/inactivate/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async articleControllerChangeToInactive(id: string): Promise<void> {
        await this.articleControllerChangeToInactiveRaw({ id: id });
    }

    /**
     */
    async articleControllerCreateRaw(requestParameters: ArticleControllerCreateRequest): Promise<runtime.ApiResponse<GetArticleDto>> {
        if (requestParameters.createUpdateArticleDto === null || requestParameters.createUpdateArticleDto === undefined) {
            throw new runtime.RequiredError('createUpdateArticleDto','Required parameter requestParameters.createUpdateArticleDto was null or undefined when calling articleControllerCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/article`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateArticleDtoToJSON(requestParameters.createUpdateArticleDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetArticleDtoFromJSON(jsonValue));
    }

    /**
     */
    async articleControllerCreate(createUpdateArticleDto: CreateUpdateArticleDto): Promise<GetArticleDto> {
        const response = await this.articleControllerCreateRaw({ createUpdateArticleDto: createUpdateArticleDto });
        return await response.value();
    }

    /**
     */
    async articleControllerGetRaw(requestParameters: ArticleControllerGetRequest): Promise<runtime.ApiResponse<GetArticleDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling articleControllerGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/article/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetArticleDtoFromJSON(jsonValue));
    }

    /**
     */
    async articleControllerGet(id: string): Promise<GetArticleDto> {
        const response = await this.articleControllerGetRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async articleControllerGetAllRaw(requestParameters: ArticleControllerGetAllRequest): Promise<runtime.ApiResponse<Array<GetArticleDto>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.mode !== undefined) {
            queryParameters['mode'] = requestParameters.mode;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/article`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetArticleDtoFromJSON));
    }

    /**
     */
    async articleControllerGetAll(mode?: string): Promise<Array<GetArticleDto>> {
        const response = await this.articleControllerGetAllRaw({ mode: mode });
        return await response.value();
    }

    /**
     */
    async articleControllerUpdateRaw(requestParameters: ArticleControllerUpdateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling articleControllerUpdate.');
        }

        if (requestParameters.createUpdateArticleDto === null || requestParameters.createUpdateArticleDto === undefined) {
            throw new runtime.RequiredError('createUpdateArticleDto','Required parameter requestParameters.createUpdateArticleDto was null or undefined when calling articleControllerUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/article/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateArticleDtoToJSON(requestParameters.createUpdateArticleDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async articleControllerUpdate(id: string, createUpdateArticleDto: CreateUpdateArticleDto): Promise<void> {
        await this.articleControllerUpdateRaw({ id: id, createUpdateArticleDto: createUpdateArticleDto });
    }

    /**
     */
    async attachmentControllerCreateRaw(requestParameters: AttachmentControllerCreateRequest): Promise<runtime.ApiResponse<GetAttachmentDto>> {
        if (requestParameters.createUpdateAttachmentDto === null || requestParameters.createUpdateAttachmentDto === undefined) {
            throw new runtime.RequiredError('createUpdateAttachmentDto','Required parameter requestParameters.createUpdateAttachmentDto was null or undefined when calling attachmentControllerCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/attachment`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateAttachmentDtoToJSON(requestParameters.createUpdateAttachmentDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetAttachmentDtoFromJSON(jsonValue));
    }

    /**
     */
    async attachmentControllerCreate(createUpdateAttachmentDto: CreateUpdateAttachmentDto): Promise<GetAttachmentDto> {
        const response = await this.attachmentControllerCreateRaw({ createUpdateAttachmentDto: createUpdateAttachmentDto });
        return await response.value();
    }

    /**
     */
    async attachmentControllerDeleteRaw(requestParameters: AttachmentControllerDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling attachmentControllerDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/attachment/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async attachmentControllerDelete(id: string): Promise<void> {
        await this.attachmentControllerDeleteRaw({ id: id });
    }

    /**
     */
    async attachmentControllerGetRaw(requestParameters: AttachmentControllerGetRequest): Promise<runtime.ApiResponse<GetAttachmentDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling attachmentControllerGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/attachment/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetAttachmentDtoFromJSON(jsonValue));
    }

    /**
     */
    async attachmentControllerGet(id: string): Promise<GetAttachmentDto> {
        const response = await this.attachmentControllerGetRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async attachmentControllerGetAllForExaminedRaw(requestParameters: AttachmentControllerGetAllForExaminedRequest): Promise<runtime.ApiResponse<Array<GetAttachmentDto>>> {
        if (requestParameters.examinedId === null || requestParameters.examinedId === undefined) {
            throw new runtime.RequiredError('examinedId','Required parameter requestParameters.examinedId was null or undefined when calling attachmentControllerGetAllForExamined.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.examinedId !== undefined) {
            queryParameters['examinedId'] = requestParameters.examinedId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/attachment/forObject`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetAttachmentDtoFromJSON));
    }

    /**
     */
    async attachmentControllerGetAllForExamined(examinedId: string): Promise<Array<GetAttachmentDto>> {
        const response = await this.attachmentControllerGetAllForExaminedRaw({ examinedId: examinedId });
        return await response.value();
    }

    /**
     */
    async attachmentControllerGetFileDownloadRaw(requestParameters: AttachmentControllerGetFileDownloadRequest): Promise<runtime.ApiResponse<AttachmentFileContent>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling attachmentControllerGetFileDownload.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/attachment/{id}/fileDownload`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AttachmentFileContentFromJSON(jsonValue));
    }

    /**
     */
    async attachmentControllerGetFileDownload(id: string): Promise<AttachmentFileContent> {
        const response = await this.attachmentControllerGetFileDownloadRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async attachmentControllerUpdateRaw(requestParameters: AttachmentControllerUpdateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling attachmentControllerUpdate.');
        }

        if (requestParameters.createUpdateAttachmentDto === null || requestParameters.createUpdateAttachmentDto === undefined) {
            throw new runtime.RequiredError('createUpdateAttachmentDto','Required parameter requestParameters.createUpdateAttachmentDto was null or undefined when calling attachmentControllerUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/attachment/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateAttachmentDtoToJSON(requestParameters.createUpdateAttachmentDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async attachmentControllerUpdate(id: string, createUpdateAttachmentDto: CreateUpdateAttachmentDto): Promise<void> {
        await this.attachmentControllerUpdateRaw({ id: id, createUpdateAttachmentDto: createUpdateAttachmentDto });
    }

    /**
     */
    async batteryControllerCreateRaw(requestParameters: BatteryControllerCreateRequest): Promise<runtime.ApiResponse<GetBatteryDto>> {
        if (requestParameters.createUpdateBatteryDto === null || requestParameters.createUpdateBatteryDto === undefined) {
            throw new runtime.RequiredError('createUpdateBatteryDto','Required parameter requestParameters.createUpdateBatteryDto was null or undefined when calling batteryControllerCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/battery`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateBatteryDtoToJSON(requestParameters.createUpdateBatteryDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetBatteryDtoFromJSON(jsonValue));
    }

    /**
     */
    async batteryControllerCreate(createUpdateBatteryDto: CreateUpdateBatteryDto): Promise<GetBatteryDto> {
        const response = await this.batteryControllerCreateRaw({ createUpdateBatteryDto: createUpdateBatteryDto });
        return await response.value();
    }

    /**
     */
    async batteryControllerGetRaw(requestParameters: BatteryControllerGetRequest): Promise<runtime.ApiResponse<GetBatteryDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling batteryControllerGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/battery/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetBatteryDtoFromJSON(jsonValue));
    }

    /**
     */
    async batteryControllerGet(id: string): Promise<GetBatteryDto> {
        const response = await this.batteryControllerGetRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async batteryControllerGetAllRaw(): Promise<runtime.ApiResponse<Array<GetBatteryDto>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/battery`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetBatteryDtoFromJSON));
    }

    /**
     */
    async batteryControllerGetAll(): Promise<Array<GetBatteryDto>> {
        const response = await this.batteryControllerGetAllRaw();
        return await response.value();
    }

    /**
     */
    async batteryControllerGetAllByPortionRaw(requestParameters: BatteryControllerGetAllByPortionRequest): Promise<runtime.ApiResponse<Array<GetBatteryWithTestsDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling batteryControllerGetAllByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling batteryControllerGetAllByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling batteryControllerGetAllByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling batteryControllerGetAllByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling batteryControllerGetAllByPortion.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/battery/search/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetBatteryWithTestsDtoFromJSON));
    }

    /**
     */
    async batteryControllerGetAllByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string): Promise<Array<GetBatteryWithTestsDto>> {
        const response = await this.batteryControllerGetAllByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText });
        return await response.value();
    }

    /**
     */
    async batteryControllerGetAllCountRaw(requestParameters: BatteryControllerGetAllCountRequest): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling batteryControllerGetAllCount.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/battery/search/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async batteryControllerGetAllCount(filterText: string): Promise<number> {
        const response = await this.batteryControllerGetAllCountRaw({ filterText: filterText });
        return await response.value();
    }

    /**
     */
    async batteryControllerUpdateRaw(requestParameters: BatteryControllerUpdateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling batteryControllerUpdate.');
        }

        if (requestParameters.createUpdateBatteryDto === null || requestParameters.createUpdateBatteryDto === undefined) {
            throw new runtime.RequiredError('createUpdateBatteryDto','Required parameter requestParameters.createUpdateBatteryDto was null or undefined when calling batteryControllerUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/battery/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateBatteryDtoToJSON(requestParameters.createUpdateBatteryDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async batteryControllerUpdate(id: string, createUpdateBatteryDto: CreateUpdateBatteryDto): Promise<void> {
        await this.batteryControllerUpdateRaw({ id: id, createUpdateBatteryDto: createUpdateBatteryDto });
    }

    /**
     */
    async cardControllerCreateRaw(requestParameters: CardControllerCreateRequest): Promise<runtime.ApiResponse<GetCardDto>> {
        if (requestParameters.createUpdateCardDto === null || requestParameters.createUpdateCardDto === undefined) {
            throw new runtime.RequiredError('createUpdateCardDto','Required parameter requestParameters.createUpdateCardDto was null or undefined when calling cardControllerCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/card`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateCardDtoToJSON(requestParameters.createUpdateCardDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCardDtoFromJSON(jsonValue));
    }

    /**
     */
    async cardControllerCreate(createUpdateCardDto: CreateUpdateCardDto): Promise<GetCardDto> {
        const response = await this.cardControllerCreateRaw({ createUpdateCardDto: createUpdateCardDto });
        return await response.value();
    }

    /**
     */
    async cardControllerGetRaw(requestParameters: CardControllerGetRequest): Promise<runtime.ApiResponse<GetCardDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling cardControllerGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/card/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCardDtoFromJSON(jsonValue));
    }

    /**
     */
    async cardControllerGet(id: string): Promise<GetCardDto> {
        const response = await this.cardControllerGetRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async cardControllerGetAllByExamRaw(requestParameters: CardControllerGetAllByExamRequest): Promise<runtime.ApiResponse<Array<GetCardDto>>> {
        if (requestParameters.examId === null || requestParameters.examId === undefined) {
            throw new runtime.RequiredError('examId','Required parameter requestParameters.examId was null or undefined when calling cardControllerGetAllByExam.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.examId !== undefined) {
            queryParameters['examId'] = requestParameters.examId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/card/getAllByExam/byExam`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetCardDtoFromJSON));
    }

    /**
     */
    async cardControllerGetAllByExam(examId: string): Promise<Array<GetCardDto>> {
        const response = await this.cardControllerGetAllByExamRaw({ examId: examId });
        return await response.value();
    }

    /**
     */
    async cardControllerGetAllByPortionRaw(requestParameters: CardControllerGetAllByPortionRequest): Promise<runtime.ApiResponse<Array<GetCardDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling cardControllerGetAllByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling cardControllerGetAllByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling cardControllerGetAllByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling cardControllerGetAllByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling cardControllerGetAllByPortion.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/card/search/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetCardDtoFromJSON));
    }

    /**
     */
    async cardControllerGetAllByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string): Promise<Array<GetCardDto>> {
        const response = await this.cardControllerGetAllByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText });
        return await response.value();
    }

    /**
     */
    async cardControllerGetAllCountRaw(requestParameters: CardControllerGetAllCountRequest): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling cardControllerGetAllCount.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/card/search/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async cardControllerGetAllCount(filterText: string): Promise<number> {
        const response = await this.cardControllerGetAllCountRaw({ filterText: filterText });
        return await response.value();
    }

    /**
     */
    async cardControllerGetByExamRaw(requestParameters: CardControllerGetByExamRequest): Promise<runtime.ApiResponse<GetCardDto>> {
        if (requestParameters.examId === null || requestParameters.examId === undefined) {
            throw new runtime.RequiredError('examId','Required parameter requestParameters.examId was null or undefined when calling cardControllerGetByExam.');
        }

        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling cardControllerGetByExam.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.examId !== undefined) {
            queryParameters['examId'] = requestParameters.examId;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/card/get/byExam`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCardDtoFromJSON(jsonValue));
    }

    /**
     */
    async cardControllerGetByExam(examId: string, type: string): Promise<GetCardDto> {
        const response = await this.cardControllerGetByExamRaw({ examId: examId, type: type });
        return await response.value();
    }

    /**
     */
    async cardControllerUpdateRaw(requestParameters: CardControllerUpdateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling cardControllerUpdate.');
        }

        if (requestParameters.createUpdateCardDto === null || requestParameters.createUpdateCardDto === undefined) {
            throw new runtime.RequiredError('createUpdateCardDto','Required parameter requestParameters.createUpdateCardDto was null or undefined when calling cardControllerUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/card/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateCardDtoToJSON(requestParameters.createUpdateCardDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async cardControllerUpdate(id: string, createUpdateCardDto: CreateUpdateCardDto): Promise<void> {
        await this.cardControllerUpdateRaw({ id: id, createUpdateCardDto: createUpdateCardDto });
    }

    /**
     */
    async companyControllerCreateRaw(requestParameters: CompanyControllerCreateRequest): Promise<runtime.ApiResponse<GetCompanyDto>> {
        if (requestParameters.createUpdateCompanyDto === null || requestParameters.createUpdateCompanyDto === undefined) {
            throw new runtime.RequiredError('createUpdateCompanyDto','Required parameter requestParameters.createUpdateCompanyDto was null or undefined when calling companyControllerCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateCompanyDtoToJSON(requestParameters.createUpdateCompanyDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCompanyDtoFromJSON(jsonValue));
    }

    /**
     */
    async companyControllerCreate(createUpdateCompanyDto: CreateUpdateCompanyDto): Promise<GetCompanyDto> {
        const response = await this.companyControllerCreateRaw({ createUpdateCompanyDto: createUpdateCompanyDto });
        return await response.value();
    }

    /**
     */
    async companyControllerGetRaw(requestParameters: CompanyControllerGetRequest): Promise<runtime.ApiResponse<GetCompanyDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling companyControllerGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCompanyDtoFromJSON(jsonValue));
    }

    /**
     */
    async companyControllerGet(id: string): Promise<GetCompanyDto> {
        const response = await this.companyControllerGetRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async companyControllerGetAllRaw(): Promise<runtime.ApiResponse<Array<GetCompanyDto>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetCompanyDtoFromJSON));
    }

    /**
     */
    async companyControllerGetAll(): Promise<Array<GetCompanyDto>> {
        const response = await this.companyControllerGetAllRaw();
        return await response.value();
    }

    /**
     */
    async companyControllerGetAllByPortionRaw(requestParameters: CompanyControllerGetAllByPortionRequest): Promise<runtime.ApiResponse<Array<GetCompanyDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling companyControllerGetAllByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling companyControllerGetAllByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling companyControllerGetAllByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling companyControllerGetAllByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling companyControllerGetAllByPortion.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company/search/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetCompanyDtoFromJSON));
    }

    /**
     */
    async companyControllerGetAllByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string): Promise<Array<GetCompanyDto>> {
        const response = await this.companyControllerGetAllByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText });
        return await response.value();
    }

    /**
     */
    async companyControllerGetAllCountRaw(requestParameters: CompanyControllerGetAllCountRequest): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling companyControllerGetAllCount.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company/search/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async companyControllerGetAllCount(filterText: string): Promise<number> {
        const response = await this.companyControllerGetAllCountRaw({ filterText: filterText });
        return await response.value();
    }

    /**
     */
    async companyControllerGetByEmailRaw(requestParameters: CompanyControllerGetByEmailRequest): Promise<runtime.ApiResponse<Array<GetCompanyDto>>> {
        if (requestParameters.pattern === null || requestParameters.pattern === undefined) {
            throw new runtime.RequiredError('pattern','Required parameter requestParameters.pattern was null or undefined when calling companyControllerGetByEmail.');
        }

        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling companyControllerGetByEmail.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pattern !== undefined) {
            queryParameters['pattern'] = requestParameters.pattern;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company/search/byEmail`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetCompanyDtoFromJSON));
    }

    /**
     */
    async companyControllerGetByEmail(pattern: string, limit: number): Promise<Array<GetCompanyDto>> {
        const response = await this.companyControllerGetByEmailRaw({ pattern: pattern, limit: limit });
        return await response.value();
    }

    /**
     */
    async companyControllerGetByNipRaw(requestParameters: CompanyControllerGetByNipRequest): Promise<runtime.ApiResponse<Array<GetCompanyDto>>> {
        if (requestParameters.pattern === null || requestParameters.pattern === undefined) {
            throw new runtime.RequiredError('pattern','Required parameter requestParameters.pattern was null or undefined when calling companyControllerGetByNip.');
        }

        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling companyControllerGetByNip.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pattern !== undefined) {
            queryParameters['pattern'] = requestParameters.pattern;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company/search/byNip`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetCompanyDtoFromJSON));
    }

    /**
     */
    async companyControllerGetByNip(pattern: string, limit: number): Promise<Array<GetCompanyDto>> {
        const response = await this.companyControllerGetByNipRaw({ pattern: pattern, limit: limit });
        return await response.value();
    }

    /**
     */
    async companyControllerGetByPatternRaw(requestParameters: CompanyControllerGetByPatternRequest): Promise<runtime.ApiResponse<Array<GetCompanyDto>>> {
        if (requestParameters.pattern === null || requestParameters.pattern === undefined) {
            throw new runtime.RequiredError('pattern','Required parameter requestParameters.pattern was null or undefined when calling companyControllerGetByPattern.');
        }

        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling companyControllerGetByPattern.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pattern !== undefined) {
            queryParameters['pattern'] = requestParameters.pattern;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company/search/byName`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetCompanyDtoFromJSON));
    }

    /**
     */
    async companyControllerGetByPattern(pattern: string, limit: number): Promise<Array<GetCompanyDto>> {
        const response = await this.companyControllerGetByPatternRaw({ pattern: pattern, limit: limit });
        return await response.value();
    }

    /**
     */
    async companyControllerGetByUserUidRaw(requestParameters: CompanyControllerGetByUserUidRequest): Promise<runtime.ApiResponse<GetCompanyDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling companyControllerGetByUserUid.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company/byUserUid/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCompanyDtoFromJSON(jsonValue));
    }

    /**
     */
    async companyControllerGetByUserUid(id: string): Promise<GetCompanyDto> {
        const response = await this.companyControllerGetByUserUidRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async companyControllerUpdateRaw(requestParameters: CompanyControllerUpdateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling companyControllerUpdate.');
        }

        if (requestParameters.createUpdateCompanyDto === null || requestParameters.createUpdateCompanyDto === undefined) {
            throw new runtime.RequiredError('createUpdateCompanyDto','Required parameter requestParameters.createUpdateCompanyDto was null or undefined when calling companyControllerUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateCompanyDtoToJSON(requestParameters.createUpdateCompanyDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async companyControllerUpdate(id: string, createUpdateCompanyDto: CreateUpdateCompanyDto): Promise<void> {
        await this.companyControllerUpdateRaw({ id: id, createUpdateCompanyDto: createUpdateCompanyDto });
    }

    /**
     */
    async consultationControllerCreateRaw(requestParameters: ConsultationControllerCreateRequest): Promise<runtime.ApiResponse<GetConsultationDto>> {
        if (requestParameters.createUpdateConsultationDto === null || requestParameters.createUpdateConsultationDto === undefined) {
            throw new runtime.RequiredError('createUpdateConsultationDto','Required parameter requestParameters.createUpdateConsultationDto was null or undefined when calling consultationControllerCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/consultation`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateConsultationDtoToJSON(requestParameters.createUpdateConsultationDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetConsultationDtoFromJSON(jsonValue));
    }

    /**
     */
    async consultationControllerCreate(createUpdateConsultationDto: CreateUpdateConsultationDto): Promise<GetConsultationDto> {
        const response = await this.consultationControllerCreateRaw({ createUpdateConsultationDto: createUpdateConsultationDto });
        return await response.value();
    }

    /**
     */
    async consultationControllerGetRaw(requestParameters: ConsultationControllerGetRequest): Promise<runtime.ApiResponse<GetConsultationDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling consultationControllerGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/consultation/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetConsultationDtoFromJSON(jsonValue));
    }

    /**
     */
    async consultationControllerGet(id: string): Promise<GetConsultationDto> {
        const response = await this.consultationControllerGetRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async consultationControllerGetAllByPortionRaw(requestParameters: ConsultationControllerGetAllByPortionRequest): Promise<runtime.ApiResponse<Array<GetConsultationDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling consultationControllerGetAllByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling consultationControllerGetAllByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling consultationControllerGetAllByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling consultationControllerGetAllByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling consultationControllerGetAllByPortion.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/consultation/search/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetConsultationDtoFromJSON));
    }

    /**
     */
    async consultationControllerGetAllByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string): Promise<Array<GetConsultationDto>> {
        const response = await this.consultationControllerGetAllByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText });
        return await response.value();
    }

    /**
     */
    async consultationControllerGetAllCountRaw(requestParameters: ConsultationControllerGetAllCountRequest): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling consultationControllerGetAllCount.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/consultation/search/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async consultationControllerGetAllCount(filterText: string): Promise<number> {
        const response = await this.consultationControllerGetAllCountRaw({ filterText: filterText });
        return await response.value();
    }

    /**
     */
    async consultationControllerGetForExamRaw(requestParameters: ConsultationControllerGetForExamRequest): Promise<runtime.ApiResponse<Array<GetConsultationDto>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling consultationControllerGetForExam.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/consultation/forExam/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetConsultationDtoFromJSON));
    }

    /**
     */
    async consultationControllerGetForExam(id: string): Promise<Array<GetConsultationDto>> {
        const response = await this.consultationControllerGetForExamRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async consultationControllerUpdateRaw(requestParameters: ConsultationControllerUpdateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling consultationControllerUpdate.');
        }

        if (requestParameters.createUpdateConsultationDto === null || requestParameters.createUpdateConsultationDto === undefined) {
            throw new runtime.RequiredError('createUpdateConsultationDto','Required parameter requestParameters.createUpdateConsultationDto was null or undefined when calling consultationControllerUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/consultation/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateConsultationDtoToJSON(requestParameters.createUpdateConsultationDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async consultationControllerUpdate(id: string, createUpdateConsultationDto: CreateUpdateConsultationDto): Promise<void> {
        await this.consultationControllerUpdateRaw({ id: id, createUpdateConsultationDto: createUpdateConsultationDto });
    }

    /**
     */
    async documentationControllerGetForFilenameRaw(requestParameters: DocumentationControllerGetForFilenameRequest): Promise<runtime.ApiResponse<GetDocumentationDto>> {
        if (requestParameters.filename === null || requestParameters.filename === undefined) {
            throw new runtime.RequiredError('filename','Required parameter requestParameters.filename was null or undefined when calling documentationControllerGetForFilename.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.filename !== undefined) {
            queryParameters['filename'] = requestParameters.filename;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/documentation/byFilename`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetDocumentationDtoFromJSON(jsonValue));
    }

    /**
     */
    async documentationControllerGetForFilename(filename: string): Promise<GetDocumentationDto> {
        const response = await this.documentationControllerGetForFilenameRaw({ filename: filename });
        return await response.value();
    }

    /**
     */
    async documentationControllerGetVersionInfoRaw(): Promise<runtime.ApiResponse<GetVersionInfoDto>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/documentation/getVersionInfo`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetVersionInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async documentationControllerGetVersionInfo(): Promise<GetVersionInfoDto> {
        const response = await this.documentationControllerGetVersionInfoRaw();
        return await response.value();
    }

    /**
     */
    async dutyControllerCreateRaw(requestParameters: DutyControllerCreateRequest): Promise<runtime.ApiResponse<GetDutyDto>> {
        if (requestParameters.createUpdateDutyDto === null || requestParameters.createUpdateDutyDto === undefined) {
            throw new runtime.RequiredError('createUpdateDutyDto','Required parameter requestParameters.createUpdateDutyDto was null or undefined when calling dutyControllerCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/duty`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateDutyDtoToJSON(requestParameters.createUpdateDutyDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetDutyDtoFromJSON(jsonValue));
    }

    /**
     */
    async dutyControllerCreate(createUpdateDutyDto: CreateUpdateDutyDto): Promise<GetDutyDto> {
        const response = await this.dutyControllerCreateRaw({ createUpdateDutyDto: createUpdateDutyDto });
        return await response.value();
    }

    /**
     */
    async dutyControllerGetRaw(requestParameters: DutyControllerGetRequest): Promise<runtime.ApiResponse<GetDutyDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling dutyControllerGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/duty/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetDutyDtoFromJSON(jsonValue));
    }

    /**
     */
    async dutyControllerGet(id: string): Promise<GetDutyDto> {
        const response = await this.dutyControllerGetRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async dutyControllerGetAllRaw(): Promise<runtime.ApiResponse<Array<GetDutyDto>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/duty`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetDutyDtoFromJSON));
    }

    /**
     */
    async dutyControllerGetAll(): Promise<Array<GetDutyDto>> {
        const response = await this.dutyControllerGetAllRaw();
        return await response.value();
    }

    /**
     */
    async dutyControllerGetAllByPortionRaw(requestParameters: DutyControllerGetAllByPortionRequest): Promise<runtime.ApiResponse<Array<GetDutyDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling dutyControllerGetAllByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling dutyControllerGetAllByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling dutyControllerGetAllByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling dutyControllerGetAllByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling dutyControllerGetAllByPortion.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/duty/search/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetDutyDtoFromJSON));
    }

    /**
     */
    async dutyControllerGetAllByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string): Promise<Array<GetDutyDto>> {
        const response = await this.dutyControllerGetAllByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText });
        return await response.value();
    }

    /**
     */
    async dutyControllerGetAllCountRaw(requestParameters: DutyControllerGetAllCountRequest): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling dutyControllerGetAllCount.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/duty/search/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async dutyControllerGetAllCount(filterText: string): Promise<number> {
        const response = await this.dutyControllerGetAllCountRaw({ filterText: filterText });
        return await response.value();
    }

    /**
     */
    async dutyControllerUpdateRaw(requestParameters: DutyControllerUpdateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling dutyControllerUpdate.');
        }

        if (requestParameters.createUpdateDutyDto === null || requestParameters.createUpdateDutyDto === undefined) {
            throw new runtime.RequiredError('createUpdateDutyDto','Required parameter requestParameters.createUpdateDutyDto was null or undefined when calling dutyControllerUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/duty/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateDutyDtoToJSON(requestParameters.createUpdateDutyDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async dutyControllerUpdate(id: string, createUpdateDutyDto: CreateUpdateDutyDto): Promise<void> {
        await this.dutyControllerUpdateRaw({ id: id, createUpdateDutyDto: createUpdateDutyDto });
    }

    /**
     */
    async emailControllerChangeToInactiveRaw(requestParameters: EmailControllerChangeToInactiveRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling emailControllerChangeToInactive.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/email/inactivate/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async emailControllerChangeToInactive(id: string): Promise<void> {
        await this.emailControllerChangeToInactiveRaw({ id: id });
    }

    /**
     */
    async emailControllerGetAllByPortionRaw(requestParameters: EmailControllerGetAllByPortionRequest): Promise<runtime.ApiResponse<Array<GetEmailDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling emailControllerGetAllByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling emailControllerGetAllByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling emailControllerGetAllByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling emailControllerGetAllByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling emailControllerGetAllByPortion.');
        }

        if (requestParameters.onlyNotSent === null || requestParameters.onlyNotSent === undefined) {
            throw new runtime.RequiredError('onlyNotSent','Required parameter requestParameters.onlyNotSent was null or undefined when calling emailControllerGetAllByPortion.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.onlyNotSent !== undefined) {
            queryParameters['onlyNotSent'] = requestParameters.onlyNotSent;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/email/search/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetEmailDtoFromJSON));
    }

    /**
     */
    async emailControllerGetAllByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, onlyNotSent: boolean): Promise<Array<GetEmailDto>> {
        const response = await this.emailControllerGetAllByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText, onlyNotSent: onlyNotSent });
        return await response.value();
    }

    /**
     */
    async emailControllerGetAllCountRaw(requestParameters: EmailControllerGetAllCountRequest): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling emailControllerGetAllCount.');
        }

        if (requestParameters.onlyNotSent === null || requestParameters.onlyNotSent === undefined) {
            throw new runtime.RequiredError('onlyNotSent','Required parameter requestParameters.onlyNotSent was null or undefined when calling emailControllerGetAllCount.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.onlyNotSent !== undefined) {
            queryParameters['onlyNotSent'] = requestParameters.onlyNotSent;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/email/search/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async emailControllerGetAllCount(filterText: string, onlyNotSent: boolean): Promise<number> {
        const response = await this.emailControllerGetAllCountRaw({ filterText: filterText, onlyNotSent: onlyNotSent });
        return await response.value();
    }

    /**
     */
    async emailControllerResendEmailRaw(requestParameters: EmailControllerResendEmailRequest): Promise<runtime.ApiResponse<GetEmailDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling emailControllerResendEmail.');
        }

        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling emailControllerResendEmail.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/email/resendEmail`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetEmailDtoFromJSON(jsonValue));
    }

    /**
     */
    async emailControllerResendEmail(id: string, userId: string): Promise<GetEmailDto> {
        const response = await this.emailControllerResendEmailRaw({ id: id, userId: userId });
        return await response.value();
    }

    /**
     */
    async emailControllerSendAllWaitingEmailsRaw(requestParameters: EmailControllerSendAllWaitingEmailsRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling emailControllerSendAllWaitingEmails.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/email/sendAllWaitingEmails`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async emailControllerSendAllWaitingEmails(userId: string): Promise<string> {
        const response = await this.emailControllerSendAllWaitingEmailsRaw({ userId: userId });
        return await response.value();
    }

    /**
     */
    async emailControllerSendJudgmentByEmailRaw(requestParameters: EmailControllerSendJudgmentByEmailRequest): Promise<runtime.ApiResponse<GetEmailDto>> {
        if (requestParameters.judgmentId === null || requestParameters.judgmentId === undefined) {
            throw new runtime.RequiredError('judgmentId','Required parameter requestParameters.judgmentId was null or undefined when calling emailControllerSendJudgmentByEmail.');
        }

        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling emailControllerSendJudgmentByEmail.');
        }

        if (requestParameters.english === null || requestParameters.english === undefined) {
            throw new runtime.RequiredError('english','Required parameter requestParameters.english was null or undefined when calling emailControllerSendJudgmentByEmail.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.judgmentId !== undefined) {
            queryParameters['judgmentId'] = requestParameters.judgmentId;
        }

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        if (requestParameters.english !== undefined) {
            queryParameters['english'] = requestParameters.english;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/email/sendJudgmentByEmail`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetEmailDtoFromJSON(jsonValue));
    }

    /**
     */
    async emailControllerSendJudgmentByEmail(judgmentId: string, userId: string, english: boolean): Promise<GetEmailDto> {
        const response = await this.emailControllerSendJudgmentByEmailRaw({ judgmentId: judgmentId, userId: userId, english: english });
        return await response.value();
    }

    /**
     */
    async emailControllerSendPsychologicalCardSummaryRaw(requestParameters: EmailControllerSendPsychologicalCardSummaryRequest): Promise<runtime.ApiResponse<GetEmailDto>> {
        if (requestParameters.cardId === null || requestParameters.cardId === undefined) {
            throw new runtime.RequiredError('cardId','Required parameter requestParameters.cardId was null or undefined when calling emailControllerSendPsychologicalCardSummary.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.cardId !== undefined) {
            queryParameters['cardId'] = requestParameters.cardId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/email/sendPsychologicalCardSummary`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetEmailDtoFromJSON(jsonValue));
    }

    /**
     */
    async emailControllerSendPsychologicalCardSummary(cardId: string): Promise<GetEmailDto> {
        const response = await this.emailControllerSendPsychologicalCardSummaryRaw({ cardId: cardId });
        return await response.value();
    }

    /**
     */
    async emailControllerSendReferralByEmailRaw(requestParameters: EmailControllerSendReferralByEmailRequest): Promise<runtime.ApiResponse<GetEmailDto>> {
        if (requestParameters.judgmentId === null || requestParameters.judgmentId === undefined) {
            throw new runtime.RequiredError('judgmentId','Required parameter requestParameters.judgmentId was null or undefined when calling emailControllerSendReferralByEmail.');
        }

        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling emailControllerSendReferralByEmail.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.judgmentId !== undefined) {
            queryParameters['judgmentId'] = requestParameters.judgmentId;
        }

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/email/sendReferralByEmail`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetEmailDtoFromJSON(jsonValue));
    }

    /**
     */
    async emailControllerSendReferralByEmail(judgmentId: string, userId: string): Promise<GetEmailDto> {
        const response = await this.emailControllerSendReferralByEmailRaw({ judgmentId: judgmentId, userId: userId });
        return await response.value();
    }

    /**
     */
    async examControllerAlarmRaw(requestParameters: ExamControllerAlarmRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling examControllerAlarm.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/exam/alarm/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async examControllerAlarm(id: string): Promise<void> {
        await this.examControllerAlarmRaw({ id: id });
    }

    /**
     */
    async examControllerChangeDatesRaw(requestParameters: ExamControllerChangeDatesRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling examControllerChangeDates.');
        }

        if (requestParameters.examDateDto === null || requestParameters.examDateDto === undefined) {
            throw new runtime.RequiredError('examDateDto','Required parameter requestParameters.examDateDto was null or undefined when calling examControllerChangeDates.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/exam/exam/{id}/changeDates`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ExamDateDtoToJSON(requestParameters.examDateDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async examControllerChangeDates(id: string, examDateDto: ExamDateDto): Promise<void> {
        await this.examControllerChangeDatesRaw({ id: id, examDateDto: examDateDto });
    }

    /**
     */
    async examControllerChangeSexRaw(requestParameters: ExamControllerChangeSexRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling examControllerChangeSex.');
        }

        if (requestParameters.examSexDto === null || requestParameters.examSexDto === undefined) {
            throw new runtime.RequiredError('examSexDto','Required parameter requestParameters.examSexDto was null or undefined when calling examControllerChangeSex.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/exam/exam/{id}/changeSex`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ExamSexDtoToJSON(requestParameters.examSexDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async examControllerChangeSex(id: string, examSexDto: ExamSexDto): Promise<void> {
        await this.examControllerChangeSexRaw({ id: id, examSexDto: examSexDto });
    }

    /**
     */
    async examControllerChangeToInactiveRaw(requestParameters: ExamControllerChangeToInactiveRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling examControllerChangeToInactive.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/exam/inactivate/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async examControllerChangeToInactive(id: string): Promise<void> {
        await this.examControllerChangeToInactiveRaw({ id: id });
    }

    /**
     */
    async examControllerCreateWithTestsRaw(requestParameters: ExamControllerCreateWithTestsRequest): Promise<runtime.ApiResponse<Array<GetExamDto>>> {
        if (requestParameters.createUpdateExamWithTestsDto === null || requestParameters.createUpdateExamWithTestsDto === undefined) {
            throw new runtime.RequiredError('createUpdateExamWithTestsDto','Required parameter requestParameters.createUpdateExamWithTestsDto was null or undefined when calling examControllerCreateWithTests.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/exam/withTest`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateExamWithTestsDtoToJSON(requestParameters.createUpdateExamWithTestsDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetExamDtoFromJSON));
    }

    /**
     */
    async examControllerCreateWithTests(createUpdateExamWithTestsDto: CreateUpdateExamWithTestsDto): Promise<Array<GetExamDto>> {
        const response = await this.examControllerCreateWithTestsRaw({ createUpdateExamWithTestsDto: createUpdateExamWithTestsDto });
        return await response.value();
    }

    /**
     */
    async examControllerGetRaw(requestParameters: ExamControllerGetRequest): Promise<runtime.ApiResponse<GetExamDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling examControllerGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/exam/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetExamDtoFromJSON(jsonValue));
    }

    /**
     */
    async examControllerGet(id: string): Promise<GetExamDto> {
        const response = await this.examControllerGetRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async examControllerGetActiveByPortionRaw(requestParameters: ExamControllerGetActiveByPortionRequest): Promise<runtime.ApiResponse<Array<GetExamDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling examControllerGetActiveByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling examControllerGetActiveByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling examControllerGetActiveByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling examControllerGetActiveByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling examControllerGetActiveByPortion.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/exam/searchActive/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetExamDtoFromJSON));
    }

    /**
     */
    async examControllerGetActiveByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, type?: string): Promise<Array<GetExamDto>> {
        const response = await this.examControllerGetActiveByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText, type: type });
        return await response.value();
    }

    /**
     */
    async examControllerGetActiveCountRaw(requestParameters: ExamControllerGetActiveCountRequest): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling examControllerGetActiveCount.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/exam/searchActive/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async examControllerGetActiveCount(filterText: string, type?: string): Promise<number> {
        const response = await this.examControllerGetActiveCountRaw({ filterText: filterText, type: type });
        return await response.value();
    }

    /**
     */
    async examControllerGetAllByPortionRaw(requestParameters: ExamControllerGetAllByPortionRequest): Promise<runtime.ApiResponse<Array<GetExamDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling examControllerGetAllByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling examControllerGetAllByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling examControllerGetAllByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling examControllerGetAllByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling examControllerGetAllByPortion.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/exam/search/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetExamDtoFromJSON));
    }

    /**
     */
    async examControllerGetAllByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, type?: string): Promise<Array<GetExamDto>> {
        const response = await this.examControllerGetAllByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText, type: type });
        return await response.value();
    }

    /**
     */
    async examControllerGetAllCountRaw(requestParameters: ExamControllerGetAllCountRequest): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling examControllerGetAllCount.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/exam/search/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async examControllerGetAllCount(filterText: string, type?: string): Promise<number> {
        const response = await this.examControllerGetAllCountRaw({ filterText: filterText, type: type });
        return await response.value();
    }

    /**
     */
    async examControllerGetCriticalPointRaw(requestParameters: ExamControllerGetCriticalPointRequest): Promise<runtime.ApiResponse<GetCriticalPointDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling examControllerGetCriticalPoint.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/exam/criticalPoint/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCriticalPointDtoFromJSON(jsonValue));
    }

    /**
     */
    async examControllerGetCriticalPoint(id: string): Promise<GetCriticalPointDto> {
        const response = await this.examControllerGetCriticalPointRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async examControllerGetForExaminedByPortionRaw(requestParameters: ExamControllerGetForExaminedByPortionRequest): Promise<runtime.ApiResponse<Array<GetExamDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling examControllerGetForExaminedByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling examControllerGetForExaminedByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling examControllerGetForExaminedByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling examControllerGetForExaminedByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling examControllerGetForExaminedByPortion.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.examinedId !== undefined) {
            queryParameters['examinedId'] = requestParameters.examinedId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/exam/searchForExamined/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetExamDtoFromJSON));
    }

    /**
     */
    async examControllerGetForExaminedByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, examinedId?: string): Promise<Array<GetExamDto>> {
        const response = await this.examControllerGetForExaminedByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText, examinedId: examinedId });
        return await response.value();
    }

    /**
     */
    async examControllerGetForExaminedCountRaw(requestParameters: ExamControllerGetForExaminedCountRequest): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling examControllerGetForExaminedCount.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.examinedId !== undefined) {
            queryParameters['examinedId'] = requestParameters.examinedId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/exam/searchForExamined/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async examControllerGetForExaminedCount(filterText: string, examinedId?: string): Promise<number> {
        const response = await this.examControllerGetForExaminedCountRaw({ filterText: filterText, examinedId: examinedId });
        return await response.value();
    }

    /**
     */
    async examControllerGetForPackageByPortionRaw(requestParameters: ExamControllerGetForPackageByPortionRequest): Promise<runtime.ApiResponse<Array<GetExamDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling examControllerGetForPackageByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling examControllerGetForPackageByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling examControllerGetForPackageByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling examControllerGetForPackageByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling examControllerGetForPackageByPortion.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.examPackageId !== undefined) {
            queryParameters['examPackageId'] = requestParameters.examPackageId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/exam/searchForPackage/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetExamDtoFromJSON));
    }

    /**
     */
    async examControllerGetForPackageByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, examPackageId?: string): Promise<Array<GetExamDto>> {
        const response = await this.examControllerGetForPackageByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText, examPackageId: examPackageId });
        return await response.value();
    }

    /**
     */
    async examControllerGetForPackageCountRaw(requestParameters: ExamControllerGetForPackageCountRequest): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling examControllerGetForPackageCount.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.examPackageId !== undefined) {
            queryParameters['examPackageId'] = requestParameters.examPackageId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/exam/searchForPackage/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async examControllerGetForPackageCount(filterText: string, examPackageId?: string): Promise<number> {
        const response = await this.examControllerGetForPackageCountRaw({ filterText: filterText, examPackageId: examPackageId });
        return await response.value();
    }

    /**
     */
    async examControllerGetForSpecialistByPortionRaw(requestParameters: ExamControllerGetForSpecialistByPortionRequest): Promise<runtime.ApiResponse<Array<GetExamDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling examControllerGetForSpecialistByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling examControllerGetForSpecialistByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling examControllerGetForSpecialistByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling examControllerGetForSpecialistByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling examControllerGetForSpecialistByPortion.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/exam/searchForSpecialist/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetExamDtoFromJSON));
    }

    /**
     */
    async examControllerGetForSpecialistByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, type?: string): Promise<Array<GetExamDto>> {
        const response = await this.examControllerGetForSpecialistByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText, type: type });
        return await response.value();
    }

    /**
     */
    async examControllerGetForSpecialistCountRaw(requestParameters: ExamControllerGetForSpecialistCountRequest): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling examControllerGetForSpecialistCount.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/exam/searchForSpecialist/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async examControllerGetForSpecialistCount(filterText: string, type?: string): Promise<number> {
        const response = await this.examControllerGetForSpecialistCountRaw({ filterText: filterText, type: type });
        return await response.value();
    }

    /**
     */
    async examControllerGetSupervisorsRaw(requestParameters: ExamControllerGetSupervisorsRequest): Promise<runtime.ApiResponse<GetSupervisorsDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling examControllerGetSupervisors.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/exam/supervisors/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSupervisorsDtoFromJSON(jsonValue));
    }

    /**
     */
    async examControllerGetSupervisors(id: string): Promise<GetSupervisorsDto> {
        const response = await this.examControllerGetSupervisorsRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async examControllerGetWithExaminedByPortionRaw(requestParameters: ExamControllerGetWithExaminedByPortionRequest): Promise<runtime.ApiResponse<Array<GetExamDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling examControllerGetWithExaminedByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling examControllerGetWithExaminedByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling examControllerGetWithExaminedByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling examControllerGetWithExaminedByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling examControllerGetWithExaminedByPortion.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/exam/searchWithExamined/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetExamDtoFromJSON));
    }

    /**
     */
    async examControllerGetWithExaminedByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, type?: string): Promise<Array<GetExamDto>> {
        const response = await this.examControllerGetWithExaminedByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText, type: type });
        return await response.value();
    }

    /**
     */
    async examControllerGetWithExaminedCountRaw(requestParameters: ExamControllerGetWithExaminedCountRequest): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling examControllerGetWithExaminedCount.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/exam/searchWithExamined/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async examControllerGetWithExaminedCount(filterText: string, type?: string): Promise<number> {
        const response = await this.examControllerGetWithExaminedCountRaw({ filterText: filterText, type: type });
        return await response.value();
    }

    /**
     */
    async examControllerGetWithoutJudgmentByPortionRaw(requestParameters: ExamControllerGetWithoutJudgmentByPortionRequest): Promise<runtime.ApiResponse<Array<GetExamDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling examControllerGetWithoutJudgmentByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling examControllerGetWithoutJudgmentByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling examControllerGetWithoutJudgmentByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling examControllerGetWithoutJudgmentByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling examControllerGetWithoutJudgmentByPortion.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/exam/searchWithoutJudgment/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetExamDtoFromJSON));
    }

    /**
     */
    async examControllerGetWithoutJudgmentByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, type?: string): Promise<Array<GetExamDto>> {
        const response = await this.examControllerGetWithoutJudgmentByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText, type: type });
        return await response.value();
    }

    /**
     */
    async examControllerGetWithoutJudgmentCountRaw(requestParameters: ExamControllerGetWithoutJudgmentCountRequest): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling examControllerGetWithoutJudgmentCount.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/exam/searchWithoutJudgment/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async examControllerGetWithoutJudgmentCount(filterText: string, type?: string): Promise<number> {
        const response = await this.examControllerGetWithoutJudgmentCountRaw({ filterText: filterText, type: type });
        return await response.value();
    }

    /**
     */
    async examControllerUpdateRaw(requestParameters: ExamControllerUpdateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling examControllerUpdate.');
        }

        if (requestParameters.createUpdateExamDto === null || requestParameters.createUpdateExamDto === undefined) {
            throw new runtime.RequiredError('createUpdateExamDto','Required parameter requestParameters.createUpdateExamDto was null or undefined when calling examControllerUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/exam/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateExamDtoToJSON(requestParameters.createUpdateExamDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async examControllerUpdate(id: string, createUpdateExamDto: CreateUpdateExamDto): Promise<void> {
        await this.examControllerUpdateRaw({ id: id, createUpdateExamDto: createUpdateExamDto });
    }

    /**
     */
    async examControllerUpdateWithTestsRaw(requestParameters: ExamControllerUpdateWithTestsRequest): Promise<runtime.ApiResponse<GetExamDto>> {
        if (requestParameters.examId === null || requestParameters.examId === undefined) {
            throw new runtime.RequiredError('examId','Required parameter requestParameters.examId was null or undefined when calling examControllerUpdateWithTests.');
        }

        if (requestParameters.createUpdateExamWithTestsDto === null || requestParameters.createUpdateExamWithTestsDto === undefined) {
            throw new runtime.RequiredError('createUpdateExamWithTestsDto','Required parameter requestParameters.createUpdateExamWithTestsDto was null or undefined when calling examControllerUpdateWithTests.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.examId !== undefined) {
            queryParameters['examId'] = requestParameters.examId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/exam/withTest`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateExamWithTestsDtoToJSON(requestParameters.createUpdateExamWithTestsDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetExamDtoFromJSON(jsonValue));
    }

    /**
     */
    async examControllerUpdateWithTests(examId: string, createUpdateExamWithTestsDto: CreateUpdateExamWithTestsDto): Promise<GetExamDto> {
        const response = await this.examControllerUpdateWithTestsRaw({ examId: examId, createUpdateExamWithTestsDto: createUpdateExamWithTestsDto });
        return await response.value();
    }

    /**
     */
    async examTestControllerChangeDatesRaw(requestParameters: ExamTestControllerChangeDatesRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.examId === null || requestParameters.examId === undefined) {
            throw new runtime.RequiredError('examId','Required parameter requestParameters.examId was null or undefined when calling examTestControllerChangeDates.');
        }

        if (requestParameters.testId === null || requestParameters.testId === undefined) {
            throw new runtime.RequiredError('testId','Required parameter requestParameters.testId was null or undefined when calling examTestControllerChangeDates.');
        }

        if (requestParameters.examTestDateDto === null || requestParameters.examTestDateDto === undefined) {
            throw new runtime.RequiredError('examTestDateDto','Required parameter requestParameters.examTestDateDto was null or undefined when calling examTestControllerChangeDates.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/examTest/exam/{examId}/test/{testId}/changeDates`.replace(`{${"examId"}}`, encodeURIComponent(String(requestParameters.examId))).replace(`{${"testId"}}`, encodeURIComponent(String(requestParameters.testId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ExamTestDateDtoToJSON(requestParameters.examTestDateDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async examTestControllerChangeDates(examId: string, testId: string, examTestDateDto: ExamTestDateDto): Promise<void> {
        await this.examTestControllerChangeDatesRaw({ examId: examId, testId: testId, examTestDateDto: examTestDateDto });
    }

    /**
     */
    async examTestControllerCreateRaw(requestParameters: ExamTestControllerCreateRequest): Promise<runtime.ApiResponse<GetExamTestDto>> {
        if (requestParameters.createUpdateExamTestDto === null || requestParameters.createUpdateExamTestDto === undefined) {
            throw new runtime.RequiredError('createUpdateExamTestDto','Required parameter requestParameters.createUpdateExamTestDto was null or undefined when calling examTestControllerCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/examTest`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateExamTestDtoToJSON(requestParameters.createUpdateExamTestDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetExamTestDtoFromJSON(jsonValue));
    }

    /**
     */
    async examTestControllerCreate(createUpdateExamTestDto: CreateUpdateExamTestDto): Promise<GetExamTestDto> {
        const response = await this.examTestControllerCreateRaw({ createUpdateExamTestDto: createUpdateExamTestDto });
        return await response.value();
    }

    /**
     */
    async examTestControllerGetRaw(requestParameters: ExamTestControllerGetRequest): Promise<runtime.ApiResponse<GetExamTestDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling examTestControllerGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/examTest/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetExamTestDtoFromJSON(jsonValue));
    }

    /**
     */
    async examTestControllerGet(id: string): Promise<GetExamTestDto> {
        const response = await this.examTestControllerGetRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async examTestControllerGetByExamRaw(requestParameters: ExamTestControllerGetByExamRequest): Promise<runtime.ApiResponse<Array<GetExamTestDto>>> {
        if (requestParameters.examId === null || requestParameters.examId === undefined) {
            throw new runtime.RequiredError('examId','Required parameter requestParameters.examId was null or undefined when calling examTestControllerGetByExam.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.examId !== undefined) {
            queryParameters['examId'] = requestParameters.examId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/examTest/get/byExam`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetExamTestDtoFromJSON));
    }

    /**
     */
    async examTestControllerGetByExam(examId: string): Promise<Array<GetExamTestDto>> {
        const response = await this.examTestControllerGetByExamRaw({ examId: examId });
        return await response.value();
    }

    /**
     */
    async examTestControllerGetForExamByPortionRaw(requestParameters: ExamTestControllerGetForExamByPortionRequest): Promise<runtime.ApiResponse<Array<GetExamTestDto>>> {
        if (requestParameters.exam === null || requestParameters.exam === undefined) {
            throw new runtime.RequiredError('exam','Required parameter requestParameters.exam was null or undefined when calling examTestControllerGetForExamByPortion.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling examTestControllerGetForExamByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling examTestControllerGetForExamByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling examTestControllerGetForExamByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling examTestControllerGetForExamByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling examTestControllerGetForExamByPortion.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.exam !== undefined) {
            queryParameters['exam'] = requestParameters.exam;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/examTest/search/forExamByPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetExamTestDtoFromJSON));
    }

    /**
     */
    async examTestControllerGetForExamByPortion(exam: string, pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string): Promise<Array<GetExamTestDto>> {
        const response = await this.examTestControllerGetForExamByPortionRaw({ exam: exam, pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText });
        return await response.value();
    }

    /**
     */
    async examTestControllerGetForExamCountRaw(requestParameters: ExamTestControllerGetForExamCountRequest): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.exam === null || requestParameters.exam === undefined) {
            throw new runtime.RequiredError('exam','Required parameter requestParameters.exam was null or undefined when calling examTestControllerGetForExamCount.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling examTestControllerGetForExamCount.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.exam !== undefined) {
            queryParameters['exam'] = requestParameters.exam;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/examTest/search/forExamCount`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async examTestControllerGetForExamCount(exam: string, filterText: string): Promise<number> {
        const response = await this.examTestControllerGetForExamCountRaw({ exam: exam, filterText: filterText });
        return await response.value();
    }

    /**
     */
    async examTestControllerUpdateRaw(requestParameters: ExamTestControllerUpdateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling examTestControllerUpdate.');
        }

        if (requestParameters.createUpdateExamTestDto === null || requestParameters.createUpdateExamTestDto === undefined) {
            throw new runtime.RequiredError('createUpdateExamTestDto','Required parameter requestParameters.createUpdateExamTestDto was null or undefined when calling examTestControllerUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/examTest/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateExamTestDtoToJSON(requestParameters.createUpdateExamTestDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async examTestControllerUpdate(id: string, createUpdateExamTestDto: CreateUpdateExamTestDto): Promise<void> {
        await this.examTestControllerUpdateRaw({ id: id, createUpdateExamTestDto: createUpdateExamTestDto });
    }

    /**
     */
    async examinedControllerChangeToInactiveRaw(requestParameters: ExaminedControllerChangeToInactiveRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling examinedControllerChangeToInactive.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/examined/inactivate/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async examinedControllerChangeToInactive(id: string): Promise<void> {
        await this.examinedControllerChangeToInactiveRaw({ id: id });
    }

    /**
     */
    async examinedControllerCreateRaw(requestParameters: ExaminedControllerCreateRequest): Promise<runtime.ApiResponse<GetExaminedDto>> {
        if (requestParameters.createUpdateExaminedDto === null || requestParameters.createUpdateExaminedDto === undefined) {
            throw new runtime.RequiredError('createUpdateExaminedDto','Required parameter requestParameters.createUpdateExaminedDto was null or undefined when calling examinedControllerCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/examined`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateExaminedDtoToJSON(requestParameters.createUpdateExaminedDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetExaminedDtoFromJSON(jsonValue));
    }

    /**
     */
    async examinedControllerCreate(createUpdateExaminedDto: CreateUpdateExaminedDto): Promise<GetExaminedDto> {
        const response = await this.examinedControllerCreateRaw({ createUpdateExaminedDto: createUpdateExaminedDto });
        return await response.value();
    }

    /**
     */
    async examinedControllerGetRaw(requestParameters: ExaminedControllerGetRequest): Promise<runtime.ApiResponse<GetExaminedDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling examinedControllerGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/examined/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetExaminedDtoFromJSON(jsonValue));
    }

    /**
     */
    async examinedControllerGet(id: string): Promise<GetExaminedDto> {
        const response = await this.examinedControllerGetRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async examinedControllerGetAllByPortionRaw(requestParameters: ExaminedControllerGetAllByPortionRequest): Promise<runtime.ApiResponse<Array<GetExaminedDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling examinedControllerGetAllByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling examinedControllerGetAllByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling examinedControllerGetAllByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling examinedControllerGetAllByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling examinedControllerGetAllByPortion.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/examined/search/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetExaminedDtoFromJSON));
    }

    /**
     */
    async examinedControllerGetAllByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string): Promise<Array<GetExaminedDto>> {
        const response = await this.examinedControllerGetAllByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText });
        return await response.value();
    }

    /**
     */
    async examinedControllerGetAllCountRaw(requestParameters: ExaminedControllerGetAllCountRequest): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling examinedControllerGetAllCount.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/examined/search/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async examinedControllerGetAllCount(filterText: string): Promise<number> {
        const response = await this.examinedControllerGetAllCountRaw({ filterText: filterText });
        return await response.value();
    }

    /**
     */
    async examinedControllerGetCitiesByPatternRaw(requestParameters: ExaminedControllerGetCitiesByPatternRequest): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters.pattern === null || requestParameters.pattern === undefined) {
            throw new runtime.RequiredError('pattern','Required parameter requestParameters.pattern was null or undefined when calling examinedControllerGetCitiesByPattern.');
        }

        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling examinedControllerGetCitiesByPattern.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pattern !== undefined) {
            queryParameters['pattern'] = requestParameters.pattern;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/examined/cities/byName`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async examinedControllerGetCitiesByPattern(pattern: string, limit: number): Promise<Array<string>> {
        const response = await this.examinedControllerGetCitiesByPatternRaw({ pattern: pattern, limit: limit });
        return await response.value();
    }

    /**
     */
    async examinedControllerGetCityByPostCodeRaw(requestParameters: ExaminedControllerGetCityByPostCodeRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.postcode === null || requestParameters.postcode === undefined) {
            throw new runtime.RequiredError('postcode','Required parameter requestParameters.postcode was null or undefined when calling examinedControllerGetCityByPostCode.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.postcode !== undefined) {
            queryParameters['postcode'] = requestParameters.postcode;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/examined/city/byPostCode`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async examinedControllerGetCityByPostCode(postcode: string): Promise<string> {
        const response = await this.examinedControllerGetCityByPostCodeRaw({ postcode: postcode });
        return await response.value();
    }

    /**
     */
    async examinedControllerGetStreetsByPatternRaw(requestParameters: ExaminedControllerGetStreetsByPatternRequest): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters.pattern === null || requestParameters.pattern === undefined) {
            throw new runtime.RequiredError('pattern','Required parameter requestParameters.pattern was null or undefined when calling examinedControllerGetStreetsByPattern.');
        }

        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling examinedControllerGetStreetsByPattern.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pattern !== undefined) {
            queryParameters['pattern'] = requestParameters.pattern;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/examined/streets/byName`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async examinedControllerGetStreetsByPattern(pattern: string, limit: number): Promise<Array<string>> {
        const response = await this.examinedControllerGetStreetsByPatternRaw({ pattern: pattern, limit: limit });
        return await response.value();
    }

    /**
     */
    async examinedControllerMergeExaminedRaw(requestParameters: ExaminedControllerMergeExaminedRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.examinedDeleteId === null || requestParameters.examinedDeleteId === undefined) {
            throw new runtime.RequiredError('examinedDeleteId','Required parameter requestParameters.examinedDeleteId was null or undefined when calling examinedControllerMergeExamined.');
        }

        if (requestParameters.examinedStayId === null || requestParameters.examinedStayId === undefined) {
            throw new runtime.RequiredError('examinedStayId','Required parameter requestParameters.examinedStayId was null or undefined when calling examinedControllerMergeExamined.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.examinedDeleteId !== undefined) {
            queryParameters['examinedDeleteId'] = requestParameters.examinedDeleteId;
        }

        if (requestParameters.examinedStayId !== undefined) {
            queryParameters['examinedStayId'] = requestParameters.examinedStayId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/examined/merge/examined`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async examinedControllerMergeExamined(examinedDeleteId: string, examinedStayId: string): Promise<void> {
        await this.examinedControllerMergeExaminedRaw({ examinedDeleteId: examinedDeleteId, examinedStayId: examinedStayId });
    }

    /**
     */
    async examinedControllerUpdateRaw(requestParameters: ExaminedControllerUpdateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling examinedControllerUpdate.');
        }

        if (requestParameters.createUpdateExaminedDto === null || requestParameters.createUpdateExaminedDto === undefined) {
            throw new runtime.RequiredError('createUpdateExaminedDto','Required parameter requestParameters.createUpdateExaminedDto was null or undefined when calling examinedControllerUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/examined/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateExaminedDtoToJSON(requestParameters.createUpdateExaminedDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async examinedControllerUpdate(id: string, createUpdateExaminedDto: CreateUpdateExaminedDto): Promise<void> {
        await this.examinedControllerUpdateRaw({ id: id, createUpdateExaminedDto: createUpdateExaminedDto });
    }

    /**
     */
    async examsPackageControllerDeleteExpectationExamsRaw(requestParameters: ExamsPackageControllerDeleteExpectationExamsRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling examsPackageControllerDeleteExpectationExams.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/examsPackage/deleteExpectationExams/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async examsPackageControllerDeleteExpectationExams(id: string): Promise<void> {
        await this.examsPackageControllerDeleteExpectationExamsRaw({ id: id });
    }

    /**
     */
    async examsPackageControllerGetRaw(requestParameters: ExamsPackageControllerGetRequest): Promise<runtime.ApiResponse<GetExamsPackageDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling examsPackageControllerGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/examsPackage/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetExamsPackageDtoFromJSON(jsonValue));
    }

    /**
     */
    async examsPackageControllerGet(id: string): Promise<GetExamsPackageDto> {
        const response = await this.examsPackageControllerGetRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async examsPackageControllerGetAllByPortionRaw(requestParameters: ExamsPackageControllerGetAllByPortionRequest): Promise<runtime.ApiResponse<Array<GetExamsPackageDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling examsPackageControllerGetAllByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling examsPackageControllerGetAllByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling examsPackageControllerGetAllByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling examsPackageControllerGetAllByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling examsPackageControllerGetAllByPortion.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/examsPackage/search/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetExamsPackageDtoFromJSON));
    }

    /**
     */
    async examsPackageControllerGetAllByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, type?: string): Promise<Array<GetExamsPackageDto>> {
        const response = await this.examsPackageControllerGetAllByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText, type: type });
        return await response.value();
    }

    /**
     */
    async examsPackageControllerGetAllCountRaw(requestParameters: ExamsPackageControllerGetAllCountRequest): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling examsPackageControllerGetAllCount.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/examsPackage/search/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async examsPackageControllerGetAllCount(filterText: string, type?: string): Promise<number> {
        const response = await this.examsPackageControllerGetAllCountRaw({ filterText: filterText, type: type });
        return await response.value();
    }

    /**
     */
    async examsPackageControllerGetExpectationPinsRaw(requestParameters: ExamsPackageControllerGetExpectationPinsRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling examsPackageControllerGetExpectationPins.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/examsPackage/getExpectationPins/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async examsPackageControllerGetExpectationPins(id: string): Promise<string> {
        const response = await this.examsPackageControllerGetExpectationPinsRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async examsPackageControllerUpdateRaw(requestParameters: ExamsPackageControllerUpdateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling examsPackageControllerUpdate.');
        }

        if (requestParameters.createUpdateExamsPackageDto === null || requestParameters.createUpdateExamsPackageDto === undefined) {
            throw new runtime.RequiredError('createUpdateExamsPackageDto','Required parameter requestParameters.createUpdateExamsPackageDto was null or undefined when calling examsPackageControllerUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/examsPackage/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateExamsPackageDtoToJSON(requestParameters.createUpdateExamsPackageDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async examsPackageControllerUpdate(id: string, createUpdateExamsPackageDto: CreateUpdateExamsPackageDto): Promise<void> {
        await this.examsPackageControllerUpdateRaw({ id: id, createUpdateExamsPackageDto: createUpdateExamsPackageDto });
    }

    /**
     */
    async exportControllerExportTestResultsRaw(requestParameters: ExportControllerExportTestResultsRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.ident === null || requestParameters.ident === undefined) {
            throw new runtime.RequiredError('ident','Required parameter requestParameters.ident was null or undefined when calling exportControllerExportTestResults.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/export/exportTestResults/{ident}`.replace(`{${"ident"}}`, encodeURIComponent(String(requestParameters.ident))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async exportControllerExportTestResults(ident: string): Promise<void> {
        await this.exportControllerExportTestResultsRaw({ ident: ident });
    }

    /**
     */
    async externalReferralControllerCreateRaw(requestParameters: ExternalReferralControllerCreateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.createUpdateExternalReferralDto === null || requestParameters.createUpdateExternalReferralDto === undefined) {
            throw new runtime.RequiredError('createUpdateExternalReferralDto','Required parameter requestParameters.createUpdateExternalReferralDto was null or undefined when calling externalReferralControllerCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/externalReferral`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateExternalReferralDtoToJSON(requestParameters.createUpdateExternalReferralDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async externalReferralControllerCreate(createUpdateExternalReferralDto: CreateUpdateExternalReferralDto): Promise<void> {
        await this.externalReferralControllerCreateRaw({ createUpdateExternalReferralDto: createUpdateExternalReferralDto });
    }

    /**
     */
    async harmfulFactorControllerCreateRaw(requestParameters: HarmfulFactorControllerCreateRequest): Promise<runtime.ApiResponse<GetHarmfulFactorDto>> {
        if (requestParameters.createUpdateHarmfulFactorDto === null || requestParameters.createUpdateHarmfulFactorDto === undefined) {
            throw new runtime.RequiredError('createUpdateHarmfulFactorDto','Required parameter requestParameters.createUpdateHarmfulFactorDto was null or undefined when calling harmfulFactorControllerCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/harmfulFactor`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateHarmfulFactorDtoToJSON(requestParameters.createUpdateHarmfulFactorDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetHarmfulFactorDtoFromJSON(jsonValue));
    }

    /**
     */
    async harmfulFactorControllerCreate(createUpdateHarmfulFactorDto: CreateUpdateHarmfulFactorDto): Promise<GetHarmfulFactorDto> {
        const response = await this.harmfulFactorControllerCreateRaw({ createUpdateHarmfulFactorDto: createUpdateHarmfulFactorDto });
        return await response.value();
    }

    /**
     */
    async harmfulFactorControllerGetRaw(requestParameters: HarmfulFactorControllerGetRequest): Promise<runtime.ApiResponse<GetHarmfulFactorDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling harmfulFactorControllerGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/harmfulFactor/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetHarmfulFactorDtoFromJSON(jsonValue));
    }

    /**
     */
    async harmfulFactorControllerGet(id: string): Promise<GetHarmfulFactorDto> {
        const response = await this.harmfulFactorControllerGetRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async harmfulFactorControllerGetAllRaw(): Promise<runtime.ApiResponse<Array<GetHarmfulFactorDto>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/harmfulFactor`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetHarmfulFactorDtoFromJSON));
    }

    /**
     */
    async harmfulFactorControllerGetAll(): Promise<Array<GetHarmfulFactorDto>> {
        const response = await this.harmfulFactorControllerGetAllRaw();
        return await response.value();
    }

    /**
     */
    async harmfulFactorControllerGetAllByPortionRaw(requestParameters: HarmfulFactorControllerGetAllByPortionRequest): Promise<runtime.ApiResponse<Array<GetHarmfulFactorDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling harmfulFactorControllerGetAllByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling harmfulFactorControllerGetAllByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling harmfulFactorControllerGetAllByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling harmfulFactorControllerGetAllByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling harmfulFactorControllerGetAllByPortion.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/harmfulFactor/search/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetHarmfulFactorDtoFromJSON));
    }

    /**
     */
    async harmfulFactorControllerGetAllByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string): Promise<Array<GetHarmfulFactorDto>> {
        const response = await this.harmfulFactorControllerGetAllByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText });
        return await response.value();
    }

    /**
     */
    async harmfulFactorControllerGetAllCountRaw(requestParameters: HarmfulFactorControllerGetAllCountRequest): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling harmfulFactorControllerGetAllCount.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/harmfulFactor/search/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async harmfulFactorControllerGetAllCount(filterText: string): Promise<number> {
        const response = await this.harmfulFactorControllerGetAllCountRaw({ filterText: filterText });
        return await response.value();
    }

    /**
     */
    async harmfulFactorControllerUpdateRaw(requestParameters: HarmfulFactorControllerUpdateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling harmfulFactorControllerUpdate.');
        }

        if (requestParameters.createUpdateHarmfulFactorDto === null || requestParameters.createUpdateHarmfulFactorDto === undefined) {
            throw new runtime.RequiredError('createUpdateHarmfulFactorDto','Required parameter requestParameters.createUpdateHarmfulFactorDto was null or undefined when calling harmfulFactorControllerUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/harmfulFactor/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateHarmfulFactorDtoToJSON(requestParameters.createUpdateHarmfulFactorDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async harmfulFactorControllerUpdate(id: string, createUpdateHarmfulFactorDto: CreateUpdateHarmfulFactorDto): Promise<void> {
        await this.harmfulFactorControllerUpdateRaw({ id: id, createUpdateHarmfulFactorDto: createUpdateHarmfulFactorDto });
    }

    /**
     */
    async healthcareFacilityControllerCreateRaw(requestParameters: HealthcareFacilityControllerCreateRequest): Promise<runtime.ApiResponse<GetHealthcareFacilityDto>> {
        if (requestParameters.createUpdateHealthcareFacilityDto === null || requestParameters.createUpdateHealthcareFacilityDto === undefined) {
            throw new runtime.RequiredError('createUpdateHealthcareFacilityDto','Required parameter requestParameters.createUpdateHealthcareFacilityDto was null or undefined when calling healthcareFacilityControllerCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/healthcareFacility`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateHealthcareFacilityDtoToJSON(requestParameters.createUpdateHealthcareFacilityDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetHealthcareFacilityDtoFromJSON(jsonValue));
    }

    /**
     */
    async healthcareFacilityControllerCreate(createUpdateHealthcareFacilityDto: CreateUpdateHealthcareFacilityDto): Promise<GetHealthcareFacilityDto> {
        const response = await this.healthcareFacilityControllerCreateRaw({ createUpdateHealthcareFacilityDto: createUpdateHealthcareFacilityDto });
        return await response.value();
    }

    /**
     */
    async healthcareFacilityControllerGetRaw(requestParameters: HealthcareFacilityControllerGetRequest): Promise<runtime.ApiResponse<GetHealthcareFacilityDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling healthcareFacilityControllerGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/healthcareFacility/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetHealthcareFacilityDtoFromJSON(jsonValue));
    }

    /**
     */
    async healthcareFacilityControllerGet(id: string): Promise<GetHealthcareFacilityDto> {
        const response = await this.healthcareFacilityControllerGetRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async healthcareFacilityControllerGetAllRaw(): Promise<runtime.ApiResponse<Array<GetHealthcareFacilityDto>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/healthcareFacility/getAll`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetHealthcareFacilityDtoFromJSON));
    }

    /**
     */
    async healthcareFacilityControllerGetAll(): Promise<Array<GetHealthcareFacilityDto>> {
        const response = await this.healthcareFacilityControllerGetAllRaw();
        return await response.value();
    }

    /**
     */
    async healthcareFacilityControllerGetAllByPortionRaw(requestParameters: HealthcareFacilityControllerGetAllByPortionRequest): Promise<runtime.ApiResponse<Array<GetHealthcareFacilityDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling healthcareFacilityControllerGetAllByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling healthcareFacilityControllerGetAllByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling healthcareFacilityControllerGetAllByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling healthcareFacilityControllerGetAllByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling healthcareFacilityControllerGetAllByPortion.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/healthcareFacility/search/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetHealthcareFacilityDtoFromJSON));
    }

    /**
     */
    async healthcareFacilityControllerGetAllByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string): Promise<Array<GetHealthcareFacilityDto>> {
        const response = await this.healthcareFacilityControllerGetAllByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText });
        return await response.value();
    }

    /**
     */
    async healthcareFacilityControllerGetAllCountRaw(requestParameters: HealthcareFacilityControllerGetAllCountRequest): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling healthcareFacilityControllerGetAllCount.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/healthcareFacility/search/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async healthcareFacilityControllerGetAllCount(filterText: string): Promise<number> {
        const response = await this.healthcareFacilityControllerGetAllCountRaw({ filterText: filterText });
        return await response.value();
    }

    /**
     */
    async healthcareFacilityControllerGetDefaultRaw(): Promise<runtime.ApiResponse<GetHealthcareFacilityDto>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/healthcareFacility/get/default`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetHealthcareFacilityDtoFromJSON(jsonValue));
    }

    /**
     */
    async healthcareFacilityControllerGetDefault(): Promise<GetHealthcareFacilityDto> {
        const response = await this.healthcareFacilityControllerGetDefaultRaw();
        return await response.value();
    }

    /**
     */
    async healthcareFacilityControllerUpdateRaw(requestParameters: HealthcareFacilityControllerUpdateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling healthcareFacilityControllerUpdate.');
        }

        if (requestParameters.createUpdateHealthcareFacilityDto === null || requestParameters.createUpdateHealthcareFacilityDto === undefined) {
            throw new runtime.RequiredError('createUpdateHealthcareFacilityDto','Required parameter requestParameters.createUpdateHealthcareFacilityDto was null or undefined when calling healthcareFacilityControllerUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/healthcareFacility/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateHealthcareFacilityDtoToJSON(requestParameters.createUpdateHealthcareFacilityDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async healthcareFacilityControllerUpdate(id: string, createUpdateHealthcareFacilityDto: CreateUpdateHealthcareFacilityDto): Promise<void> {
        await this.healthcareFacilityControllerUpdateRaw({ id: id, createUpdateHealthcareFacilityDto: createUpdateHealthcareFacilityDto });
    }

    /**
     */
    async indicatorTestControllerCreateRaw(requestParameters: IndicatorTestControllerCreateRequest): Promise<runtime.ApiResponse<GetIndicatorTestDto>> {
        if (requestParameters.createUpdateIndicatorTestDto === null || requestParameters.createUpdateIndicatorTestDto === undefined) {
            throw new runtime.RequiredError('createUpdateIndicatorTestDto','Required parameter requestParameters.createUpdateIndicatorTestDto was null or undefined when calling indicatorTestControllerCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/indicatorTest`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateIndicatorTestDtoToJSON(requestParameters.createUpdateIndicatorTestDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetIndicatorTestDtoFromJSON(jsonValue));
    }

    /**
     */
    async indicatorTestControllerCreate(createUpdateIndicatorTestDto: CreateUpdateIndicatorTestDto): Promise<GetIndicatorTestDto> {
        const response = await this.indicatorTestControllerCreateRaw({ createUpdateIndicatorTestDto: createUpdateIndicatorTestDto });
        return await response.value();
    }

    /**
     */
    async indicatorTestControllerCreateImportedRaw(requestParameters: IndicatorTestControllerCreateImportedRequest): Promise<runtime.ApiResponse<GetIndicatorTestDto>> {
        if (requestParameters.createUpdateImportedIndicatorTestDto === null || requestParameters.createUpdateImportedIndicatorTestDto === undefined) {
            throw new runtime.RequiredError('createUpdateImportedIndicatorTestDto','Required parameter requestParameters.createUpdateImportedIndicatorTestDto was null or undefined when calling indicatorTestControllerCreateImported.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/indicatorTest/imported`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateImportedIndicatorTestDtoToJSON(requestParameters.createUpdateImportedIndicatorTestDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetIndicatorTestDtoFromJSON(jsonValue));
    }

    /**
     */
    async indicatorTestControllerCreateImported(createUpdateImportedIndicatorTestDto: CreateUpdateImportedIndicatorTestDto): Promise<GetIndicatorTestDto> {
        const response = await this.indicatorTestControllerCreateImportedRaw({ createUpdateImportedIndicatorTestDto: createUpdateImportedIndicatorTestDto });
        return await response.value();
    }

    /**
     */
    async indicatorTestControllerGetRaw(requestParameters: IndicatorTestControllerGetRequest): Promise<runtime.ApiResponse<GetIndicatorTestDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling indicatorTestControllerGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/indicatorTest/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetIndicatorTestDtoFromJSON(jsonValue));
    }

    /**
     */
    async indicatorTestControllerGet(id: string): Promise<GetIndicatorTestDto> {
        const response = await this.indicatorTestControllerGetRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async indicatorTestControllerGetAllByPortionRaw(requestParameters: IndicatorTestControllerGetAllByPortionRequest): Promise<runtime.ApiResponse<Array<GetIndicatorTestDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling indicatorTestControllerGetAllByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling indicatorTestControllerGetAllByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling indicatorTestControllerGetAllByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling indicatorTestControllerGetAllByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling indicatorTestControllerGetAllByPortion.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/indicatorTest/search/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetIndicatorTestDtoFromJSON));
    }

    /**
     */
    async indicatorTestControllerGetAllByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string): Promise<Array<GetIndicatorTestDto>> {
        const response = await this.indicatorTestControllerGetAllByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText });
        return await response.value();
    }

    /**
     */
    async indicatorTestControllerGetAllCountRaw(requestParameters: IndicatorTestControllerGetAllCountRequest): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling indicatorTestControllerGetAllCount.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/indicatorTest/search/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async indicatorTestControllerGetAllCount(filterText: string): Promise<number> {
        const response = await this.indicatorTestControllerGetAllCountRaw({ filterText: filterText });
        return await response.value();
    }

    /**
     */
    async indicatorTestControllerGetByExamTestRaw(requestParameters: IndicatorTestControllerGetByExamTestRequest): Promise<runtime.ApiResponse<Array<GetIndicatorTestRecalculatedDto>>> {
        if (requestParameters.examTestId === null || requestParameters.examTestId === undefined) {
            throw new runtime.RequiredError('examTestId','Required parameter requestParameters.examTestId was null or undefined when calling indicatorTestControllerGetByExamTest.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.examTestId !== undefined) {
            queryParameters['examTestId'] = requestParameters.examTestId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/indicatorTest/get/byExamTest`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetIndicatorTestRecalculatedDtoFromJSON));
    }

    /**
     */
    async indicatorTestControllerGetByExamTest(examTestId: string): Promise<Array<GetIndicatorTestRecalculatedDto>> {
        const response = await this.indicatorTestControllerGetByExamTestRaw({ examTestId: examTestId });
        return await response.value();
    }

    /**
     */
    async indicatorTestControllerUpdateRaw(requestParameters: IndicatorTestControllerUpdateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling indicatorTestControllerUpdate.');
        }

        if (requestParameters.createUpdateIndicatorTestDto === null || requestParameters.createUpdateIndicatorTestDto === undefined) {
            throw new runtime.RequiredError('createUpdateIndicatorTestDto','Required parameter requestParameters.createUpdateIndicatorTestDto was null or undefined when calling indicatorTestControllerUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/indicatorTest/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateIndicatorTestDtoToJSON(requestParameters.createUpdateIndicatorTestDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async indicatorTestControllerUpdate(id: string, createUpdateIndicatorTestDto: CreateUpdateIndicatorTestDto): Promise<void> {
        await this.indicatorTestControllerUpdateRaw({ id: id, createUpdateIndicatorTestDto: createUpdateIndicatorTestDto });
    }

    /**
     */
    async interpretationNormControllerGetByNormRaw(requestParameters: InterpretationNormControllerGetByNormRequest): Promise<runtime.ApiResponse<Array<GetInterpretationNormDto>>> {
        if (requestParameters.normId === null || requestParameters.normId === undefined) {
            throw new runtime.RequiredError('normId','Required parameter requestParameters.normId was null or undefined when calling interpretationNormControllerGetByNorm.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.normId !== undefined) {
            queryParameters['normId'] = requestParameters.normId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/interpretationNorm/get/byNorm`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetInterpretationNormDtoFromJSON));
    }

    /**
     */
    async interpretationNormControllerGetByNorm(normId: string): Promise<Array<GetInterpretationNormDto>> {
        const response = await this.interpretationNormControllerGetByNormRaw({ normId: normId });
        return await response.value();
    }

    /**
     */
    async interpretationNormControllerGetByNormCountRaw(requestParameters: InterpretationNormControllerGetByNormCountRequest): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.normId === null || requestParameters.normId === undefined) {
            throw new runtime.RequiredError('normId','Required parameter requestParameters.normId was null or undefined when calling interpretationNormControllerGetByNormCount.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.normId !== undefined) {
            queryParameters['normId'] = requestParameters.normId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/interpretationNorm/getByNorm/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async interpretationNormControllerGetByNormCount(normId: string): Promise<number> {
        const response = await this.interpretationNormControllerGetByNormCountRaw({ normId: normId });
        return await response.value();
    }

    /**
     */
    async judgmentControllerChangeToInactiveRaw(requestParameters: JudgmentControllerChangeToInactiveRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling judgmentControllerChangeToInactive.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/judgment/inactivate/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async judgmentControllerChangeToInactive(id: string): Promise<void> {
        await this.judgmentControllerChangeToInactiveRaw({ id: id });
    }

    /**
     */
    async judgmentControllerCreateRaw(requestParameters: JudgmentControllerCreateRequest): Promise<runtime.ApiResponse<GetJudgmentDto>> {
        if (requestParameters.createUpdateJudgmentDto === null || requestParameters.createUpdateJudgmentDto === undefined) {
            throw new runtime.RequiredError('createUpdateJudgmentDto','Required parameter requestParameters.createUpdateJudgmentDto was null or undefined when calling judgmentControllerCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/judgment`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateJudgmentDtoToJSON(requestParameters.createUpdateJudgmentDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetJudgmentDtoFromJSON(jsonValue));
    }

    /**
     */
    async judgmentControllerCreate(createUpdateJudgmentDto: CreateUpdateJudgmentDto): Promise<GetJudgmentDto> {
        const response = await this.judgmentControllerCreateRaw({ createUpdateJudgmentDto: createUpdateJudgmentDto });
        return await response.value();
    }

    /**
     */
    async judgmentControllerGetRaw(requestParameters: JudgmentControllerGetRequest): Promise<runtime.ApiResponse<GetJudgmentDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling judgmentControllerGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/judgment/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetJudgmentDtoFromJSON(jsonValue));
    }

    /**
     */
    async judgmentControllerGet(id: string): Promise<GetJudgmentDto> {
        const response = await this.judgmentControllerGetRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async judgmentControllerGetAllByPortionRaw(requestParameters: JudgmentControllerGetAllByPortionRequest): Promise<runtime.ApiResponse<Array<GetJudgmentExtendedDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling judgmentControllerGetAllByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling judgmentControllerGetAllByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling judgmentControllerGetAllByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling judgmentControllerGetAllByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling judgmentControllerGetAllByPortion.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/judgment/search/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetJudgmentExtendedDtoFromJSON));
    }

    /**
     */
    async judgmentControllerGetAllByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string): Promise<Array<GetJudgmentExtendedDto>> {
        const response = await this.judgmentControllerGetAllByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText });
        return await response.value();
    }

    /**
     */
    async judgmentControllerGetAllCountRaw(requestParameters: JudgmentControllerGetAllCountRequest): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling judgmentControllerGetAllCount.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/judgment/search/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async judgmentControllerGetAllCount(filterText: string): Promise<number> {
        const response = await this.judgmentControllerGetAllCountRaw({ filterText: filterText });
        return await response.value();
    }

    /**
     */
    async judgmentControllerGetAllForUserRegisterToSignRaw(requestParameters: JudgmentControllerGetAllForUserRegisterToSignRequest): Promise<runtime.ApiResponse<Array<GetJudgmentDto>>> {
        if (requestParameters.userUid === null || requestParameters.userUid === undefined) {
            throw new runtime.RequiredError('userUid','Required parameter requestParameters.userUid was null or undefined when calling judgmentControllerGetAllForUserRegisterToSign.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.userUid !== undefined) {
            queryParameters['userUid'] = requestParameters.userUid;
        }

        if (requestParameters.registerId !== undefined) {
            queryParameters['registerId'] = requestParameters.registerId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/judgment/search/byRegisterUserToSign`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetJudgmentDtoFromJSON));
    }

    /**
     */
    async judgmentControllerGetAllForUserRegisterToSign(userUid: string, registerId?: string): Promise<Array<GetJudgmentDto>> {
        const response = await this.judgmentControllerGetAllForUserRegisterToSignRaw({ userUid: userUid, registerId: registerId });
        return await response.value();
    }

    /**
     */
    async judgmentControllerGetForExamRaw(requestParameters: JudgmentControllerGetForExamRequest): Promise<runtime.ApiResponse<Array<GetJudgmentDto>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling judgmentControllerGetForExam.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/judgment/forExam/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetJudgmentDtoFromJSON));
    }

    /**
     */
    async judgmentControllerGetForExam(id: string): Promise<Array<GetJudgmentDto>> {
        const response = await this.judgmentControllerGetForExamRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async judgmentControllerGetForRegisterByPortionRaw(requestParameters: JudgmentControllerGetForRegisterByPortionRequest): Promise<runtime.ApiResponse<Array<GetJudgmentExtendedDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling judgmentControllerGetForRegisterByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling judgmentControllerGetForRegisterByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling judgmentControllerGetForRegisterByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling judgmentControllerGetForRegisterByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling judgmentControllerGetForRegisterByPortion.');
        }

        if (requestParameters.registerId === null || requestParameters.registerId === undefined) {
            throw new runtime.RequiredError('registerId','Required parameter requestParameters.registerId was null or undefined when calling judgmentControllerGetForRegisterByPortion.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.registerId !== undefined) {
            queryParameters['registerId'] = requestParameters.registerId;
        }

        if (requestParameters.dateFrom !== undefined) {
            queryParameters['dateFrom'] = (requestParameters.dateFrom as any).toISOString();
        }

        if (requestParameters.dateTo !== undefined) {
            queryParameters['dateTo'] = (requestParameters.dateTo as any).toISOString();
        }

        if (requestParameters.userUid !== undefined) {
            queryParameters['userUid'] = requestParameters.userUid;
        }

        if (requestParameters.healthcareFacilityId !== undefined) {
            queryParameters['healthcareFacilityId'] = requestParameters.healthcareFacilityId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/judgment/searchForRegister/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetJudgmentExtendedDtoFromJSON));
    }

    /**
     */
    async judgmentControllerGetForRegisterByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, registerId: string, dateFrom?: Date, dateTo?: Date, userUid?: string, healthcareFacilityId?: string): Promise<Array<GetJudgmentExtendedDto>> {
        const response = await this.judgmentControllerGetForRegisterByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText, registerId: registerId, dateFrom: dateFrom, dateTo: dateTo, userUid: userUid, healthcareFacilityId: healthcareFacilityId });
        return await response.value();
    }

    /**
     */
    async judgmentControllerGetForRegisterCountRaw(requestParameters: JudgmentControllerGetForRegisterCountRequest): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling judgmentControllerGetForRegisterCount.');
        }

        if (requestParameters.registerId === null || requestParameters.registerId === undefined) {
            throw new runtime.RequiredError('registerId','Required parameter requestParameters.registerId was null or undefined when calling judgmentControllerGetForRegisterCount.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.registerId !== undefined) {
            queryParameters['registerId'] = requestParameters.registerId;
        }

        if (requestParameters.dateFrom !== undefined) {
            queryParameters['dateFrom'] = (requestParameters.dateFrom as any).toISOString();
        }

        if (requestParameters.dateTo !== undefined) {
            queryParameters['dateTo'] = (requestParameters.dateTo as any).toISOString();
        }

        if (requestParameters.userUid !== undefined) {
            queryParameters['userUid'] = requestParameters.userUid;
        }

        if (requestParameters.healthcareFacilityId !== undefined) {
            queryParameters['healthcareFacilityId'] = requestParameters.healthcareFacilityId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/judgment/searchForRegister/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async judgmentControllerGetForRegisterCount(filterText: string, registerId: string, dateFrom?: Date, dateTo?: Date, userUid?: string, healthcareFacilityId?: string): Promise<number> {
        const response = await this.judgmentControllerGetForRegisterCountRaw({ filterText: filterText, registerId: registerId, dateFrom: dateFrom, dateTo: dateTo, userUid: userUid, healthcareFacilityId: healthcareFacilityId });
        return await response.value();
    }

    /**
     */
    async judgmentControllerGetForSigningByPortionRaw(requestParameters: JudgmentControllerGetForSigningByPortionRequest): Promise<runtime.ApiResponse<Array<GetJudgmentExtendedDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling judgmentControllerGetForSigningByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling judgmentControllerGetForSigningByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling judgmentControllerGetForSigningByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling judgmentControllerGetForSigningByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling judgmentControllerGetForSigningByPortion.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.userUid !== undefined) {
            queryParameters['userUid'] = requestParameters.userUid;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/judgment/searchForSigning/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetJudgmentExtendedDtoFromJSON));
    }

    /**
     */
    async judgmentControllerGetForSigningByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, userUid?: string): Promise<Array<GetJudgmentExtendedDto>> {
        const response = await this.judgmentControllerGetForSigningByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText, userUid: userUid });
        return await response.value();
    }

    /**
     */
    async judgmentControllerGetForSigningCountRaw(requestParameters: JudgmentControllerGetForSigningCountRequest): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling judgmentControllerGetForSigningCount.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.userUid !== undefined) {
            queryParameters['userUid'] = requestParameters.userUid;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/judgment/searchForSigning/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async judgmentControllerGetForSigningCount(filterText: string, userUid?: string): Promise<number> {
        const response = await this.judgmentControllerGetForSigningCountRaw({ filterText: filterText, userUid: userUid });
        return await response.value();
    }

    /**
     */
    async judgmentControllerGetWithoutElectronicSigningByPortionRaw(requestParameters: JudgmentControllerGetWithoutElectronicSigningByPortionRequest): Promise<runtime.ApiResponse<Array<GetJudgmentExtendedDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling judgmentControllerGetWithoutElectronicSigningByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling judgmentControllerGetWithoutElectronicSigningByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling judgmentControllerGetWithoutElectronicSigningByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling judgmentControllerGetWithoutElectronicSigningByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling judgmentControllerGetWithoutElectronicSigningByPortion.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.userUid !== undefined) {
            queryParameters['userUid'] = requestParameters.userUid;
        }

        if (requestParameters.dateFrom !== undefined) {
            queryParameters['dateFrom'] = (requestParameters.dateFrom as any).toISOString();
        }

        if (requestParameters.dateTo !== undefined) {
            queryParameters['dateTo'] = (requestParameters.dateTo as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/judgment/searchWithoutElectronicSigning/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetJudgmentExtendedDtoFromJSON));
    }

    /**
     */
    async judgmentControllerGetWithoutElectronicSigningByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, userUid?: string, dateFrom?: Date, dateTo?: Date): Promise<Array<GetJudgmentExtendedDto>> {
        const response = await this.judgmentControllerGetWithoutElectronicSigningByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText, userUid: userUid, dateFrom: dateFrom, dateTo: dateTo });
        return await response.value();
    }

    /**
     */
    async judgmentControllerGetWithoutElectronicSigningCountRaw(requestParameters: JudgmentControllerGetWithoutElectronicSigningCountRequest): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling judgmentControllerGetWithoutElectronicSigningCount.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.userUid !== undefined) {
            queryParameters['userUid'] = requestParameters.userUid;
        }

        if (requestParameters.dateFrom !== undefined) {
            queryParameters['dateFrom'] = (requestParameters.dateFrom as any).toISOString();
        }

        if (requestParameters.dateTo !== undefined) {
            queryParameters['dateTo'] = (requestParameters.dateTo as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/judgment/searchWithoutElectronicSigning/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async judgmentControllerGetWithoutElectronicSigningCount(filterText: string, userUid?: string, dateFrom?: Date, dateTo?: Date): Promise<number> {
        const response = await this.judgmentControllerGetWithoutElectronicSigningCountRaw({ filterText: filterText, userUid: userUid, dateFrom: dateFrom, dateTo: dateTo });
        return await response.value();
    }

    /**
     */
    async judgmentControllerUpdateRaw(requestParameters: JudgmentControllerUpdateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling judgmentControllerUpdate.');
        }

        if (requestParameters.createUpdateJudgmentDto === null || requestParameters.createUpdateJudgmentDto === undefined) {
            throw new runtime.RequiredError('createUpdateJudgmentDto','Required parameter requestParameters.createUpdateJudgmentDto was null or undefined when calling judgmentControllerUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/judgment/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateJudgmentDtoToJSON(requestParameters.createUpdateJudgmentDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async judgmentControllerUpdate(id: string, createUpdateJudgmentDto: CreateUpdateJudgmentDto): Promise<void> {
        await this.judgmentControllerUpdateRaw({ id: id, createUpdateJudgmentDto: createUpdateJudgmentDto });
    }

    /**
     */
    async judgmentNumberingControllerCreateRaw(requestParameters: JudgmentNumberingControllerCreateRequest): Promise<runtime.ApiResponse<GetJudgmentNumberingDto>> {
        if (requestParameters.createUpdateJudgmentNumberingDto === null || requestParameters.createUpdateJudgmentNumberingDto === undefined) {
            throw new runtime.RequiredError('createUpdateJudgmentNumberingDto','Required parameter requestParameters.createUpdateJudgmentNumberingDto was null or undefined when calling judgmentNumberingControllerCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/judgmentsNumbering`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateJudgmentNumberingDtoToJSON(requestParameters.createUpdateJudgmentNumberingDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetJudgmentNumberingDtoFromJSON(jsonValue));
    }

    /**
     */
    async judgmentNumberingControllerCreate(createUpdateJudgmentNumberingDto: CreateUpdateJudgmentNumberingDto): Promise<GetJudgmentNumberingDto> {
        const response = await this.judgmentNumberingControllerCreateRaw({ createUpdateJudgmentNumberingDto: createUpdateJudgmentNumberingDto });
        return await response.value();
    }

    /**
     */
    async judgmentNumberingControllerGetRaw(requestParameters: JudgmentNumberingControllerGetRequest): Promise<runtime.ApiResponse<GetJudgmentNumberingDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling judgmentNumberingControllerGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/judgmentsNumbering/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetJudgmentNumberingDtoFromJSON(jsonValue));
    }

    /**
     */
    async judgmentNumberingControllerGet(id: string): Promise<GetJudgmentNumberingDto> {
        const response = await this.judgmentNumberingControllerGetRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async judgmentNumberingControllerGetAllRaw(): Promise<runtime.ApiResponse<Array<GetJudgmentNumberingDto>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/judgmentsNumbering`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetJudgmentNumberingDtoFromJSON));
    }

    /**
     */
    async judgmentNumberingControllerGetAll(): Promise<Array<GetJudgmentNumberingDto>> {
        const response = await this.judgmentNumberingControllerGetAllRaw();
        return await response.value();
    }

    /**
     */
    async judgmentNumberingControllerUpdateRaw(requestParameters: JudgmentNumberingControllerUpdateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling judgmentNumberingControllerUpdate.');
        }

        if (requestParameters.createUpdateJudgmentNumberingDto === null || requestParameters.createUpdateJudgmentNumberingDto === undefined) {
            throw new runtime.RequiredError('createUpdateJudgmentNumberingDto','Required parameter requestParameters.createUpdateJudgmentNumberingDto was null or undefined when calling judgmentNumberingControllerUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/judgmentsNumbering/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateJudgmentNumberingDtoToJSON(requestParameters.createUpdateJudgmentNumberingDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async judgmentNumberingControllerUpdate(id: string, createUpdateJudgmentNumberingDto: CreateUpdateJudgmentNumberingDto): Promise<void> {
        await this.judgmentNumberingControllerUpdateRaw({ id: id, createUpdateJudgmentNumberingDto: createUpdateJudgmentNumberingDto });
    }

    /**
     */
    async keyTestControllerCreateRaw(requestParameters: KeyTestControllerCreateRequest): Promise<runtime.ApiResponse<GetKeyTestDto>> {
        if (requestParameters.createUpdateKeyTestDto === null || requestParameters.createUpdateKeyTestDto === undefined) {
            throw new runtime.RequiredError('createUpdateKeyTestDto','Required parameter requestParameters.createUpdateKeyTestDto was null or undefined when calling keyTestControllerCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/keyTest`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateKeyTestDtoToJSON(requestParameters.createUpdateKeyTestDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetKeyTestDtoFromJSON(jsonValue));
    }

    /**
     */
    async keyTestControllerCreate(createUpdateKeyTestDto: CreateUpdateKeyTestDto): Promise<GetKeyTestDto> {
        const response = await this.keyTestControllerCreateRaw({ createUpdateKeyTestDto: createUpdateKeyTestDto });
        return await response.value();
    }

    /**
     */
    async keyTestControllerCreateWithValuesRaw(requestParameters: KeyTestControllerCreateWithValuesRequest): Promise<runtime.ApiResponse<GetKeyTestDto>> {
        if (requestParameters.createUpdateKeyTestWithKeysDto === null || requestParameters.createUpdateKeyTestWithKeysDto === undefined) {
            throw new runtime.RequiredError('createUpdateKeyTestWithKeysDto','Required parameter requestParameters.createUpdateKeyTestWithKeysDto was null or undefined when calling keyTestControllerCreateWithValues.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/keyTest/withKeys`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateKeyTestWithKeysDtoToJSON(requestParameters.createUpdateKeyTestWithKeysDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetKeyTestDtoFromJSON(jsonValue));
    }

    /**
     */
    async keyTestControllerCreateWithValues(createUpdateKeyTestWithKeysDto: CreateUpdateKeyTestWithKeysDto): Promise<GetKeyTestDto> {
        const response = await this.keyTestControllerCreateWithValuesRaw({ createUpdateKeyTestWithKeysDto: createUpdateKeyTestWithKeysDto });
        return await response.value();
    }

    /**
     */
    async keyTestControllerGetRaw(requestParameters: KeyTestControllerGetRequest): Promise<runtime.ApiResponse<GetKeyTestDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling keyTestControllerGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/keyTest/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetKeyTestDtoFromJSON(jsonValue));
    }

    /**
     */
    async keyTestControllerGet(id: string): Promise<GetKeyTestDto> {
        const response = await this.keyTestControllerGetRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async keyTestControllerGetAllByPortionRaw(requestParameters: KeyTestControllerGetAllByPortionRequest): Promise<runtime.ApiResponse<Array<GetKeyTestDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling keyTestControllerGetAllByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling keyTestControllerGetAllByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling keyTestControllerGetAllByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling keyTestControllerGetAllByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling keyTestControllerGetAllByPortion.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/keyTest/search/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetKeyTestDtoFromJSON));
    }

    /**
     */
    async keyTestControllerGetAllByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string): Promise<Array<GetKeyTestDto>> {
        const response = await this.keyTestControllerGetAllByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText });
        return await response.value();
    }

    /**
     */
    async keyTestControllerGetAllCountRaw(requestParameters: KeyTestControllerGetAllCountRequest): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling keyTestControllerGetAllCount.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/keyTest/search/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async keyTestControllerGetAllCount(filterText: string): Promise<number> {
        const response = await this.keyTestControllerGetAllCountRaw({ filterText: filterText });
        return await response.value();
    }

    /**
     */
    async keyTestControllerUpdateRaw(requestParameters: KeyTestControllerUpdateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling keyTestControllerUpdate.');
        }

        if (requestParameters.createUpdateKeyTestDto === null || requestParameters.createUpdateKeyTestDto === undefined) {
            throw new runtime.RequiredError('createUpdateKeyTestDto','Required parameter requestParameters.createUpdateKeyTestDto was null or undefined when calling keyTestControllerUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/keyTest/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateKeyTestDtoToJSON(requestParameters.createUpdateKeyTestDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async keyTestControllerUpdate(id: string, createUpdateKeyTestDto: CreateUpdateKeyTestDto): Promise<void> {
        await this.keyTestControllerUpdateRaw({ id: id, createUpdateKeyTestDto: createUpdateKeyTestDto });
    }

    /**
     */
    async keyValueControllerGetByKeyRaw(requestParameters: KeyValueControllerGetByKeyRequest): Promise<runtime.ApiResponse<Array<GetKeyValueDto>>> {
        if (requestParameters.keyTestId === null || requestParameters.keyTestId === undefined) {
            throw new runtime.RequiredError('keyTestId','Required parameter requestParameters.keyTestId was null or undefined when calling keyValueControllerGetByKey.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.keyTestId !== undefined) {
            queryParameters['keyTestId'] = requestParameters.keyTestId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/keyValue/search/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetKeyValueDtoFromJSON));
    }

    /**
     */
    async keyValueControllerGetByKey(keyTestId: string): Promise<Array<GetKeyValueDto>> {
        const response = await this.keyValueControllerGetByKeyRaw({ keyTestId: keyTestId });
        return await response.value();
    }

    /**
     */
    async keyValueControllerGetByKeyCountRaw(requestParameters: KeyValueControllerGetByKeyCountRequest): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.keyTestId === null || requestParameters.keyTestId === undefined) {
            throw new runtime.RequiredError('keyTestId','Required parameter requestParameters.keyTestId was null or undefined when calling keyValueControllerGetByKeyCount.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.keyTestId !== undefined) {
            queryParameters['keyTestId'] = requestParameters.keyTestId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/keyValue/search/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async keyValueControllerGetByKeyCount(keyTestId: string): Promise<number> {
        const response = await this.keyValueControllerGetByKeyCountRaw({ keyTestId: keyTestId });
        return await response.value();
    }

    /**
     */
    async normControllerCreateWithValuesRaw(requestParameters: NormControllerCreateWithValuesRequest): Promise<runtime.ApiResponse<GetNormDto>> {
        if (requestParameters.createUpdateNormWithValuesDto === null || requestParameters.createUpdateNormWithValuesDto === undefined) {
            throw new runtime.RequiredError('createUpdateNormWithValuesDto','Required parameter requestParameters.createUpdateNormWithValuesDto was null or undefined when calling normControllerCreateWithValues.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/norm/withValues`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateNormWithValuesDtoToJSON(requestParameters.createUpdateNormWithValuesDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetNormDtoFromJSON(jsonValue));
    }

    /**
     */
    async normControllerCreateWithValues(createUpdateNormWithValuesDto: CreateUpdateNormWithValuesDto): Promise<GetNormDto> {
        const response = await this.normControllerCreateWithValuesRaw({ createUpdateNormWithValuesDto: createUpdateNormWithValuesDto });
        return await response.value();
    }

    /**
     */
    async normControllerGetRaw(requestParameters: NormControllerGetRequest): Promise<runtime.ApiResponse<GetNormDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling normControllerGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/norm/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetNormDtoFromJSON(jsonValue));
    }

    /**
     */
    async normControllerGet(id: string): Promise<GetNormDto> {
        const response = await this.normControllerGetRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async normControllerGetAllByPortionRaw(requestParameters: NormControllerGetAllByPortionRequest): Promise<runtime.ApiResponse<Array<GetNormDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling normControllerGetAllByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling normControllerGetAllByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling normControllerGetAllByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling normControllerGetAllByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling normControllerGetAllByPortion.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/norm/search/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetNormDtoFromJSON));
    }

    /**
     */
    async normControllerGetAllByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string): Promise<Array<GetNormDto>> {
        const response = await this.normControllerGetAllByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText });
        return await response.value();
    }

    /**
     */
    async normControllerGetAllCountRaw(requestParameters: NormControllerGetAllCountRequest): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling normControllerGetAllCount.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/norm/search/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async normControllerGetAllCount(filterText: string): Promise<number> {
        const response = await this.normControllerGetAllCountRaw({ filterText: filterText });
        return await response.value();
    }

    /**
     */
    async otherPrintControllerChangeToInactiveRaw(requestParameters: OtherPrintControllerChangeToInactiveRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling otherPrintControllerChangeToInactive.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/OtherPrint/inactivate/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async otherPrintControllerChangeToInactive(id: string): Promise<void> {
        await this.otherPrintControllerChangeToInactiveRaw({ id: id });
    }

    /**
     */
    async otherPrintControllerCreateRaw(requestParameters: OtherPrintControllerCreateRequest): Promise<runtime.ApiResponse<GetOtherPrintDto>> {
        if (requestParameters.createUpdateOtherPrintDto === null || requestParameters.createUpdateOtherPrintDto === undefined) {
            throw new runtime.RequiredError('createUpdateOtherPrintDto','Required parameter requestParameters.createUpdateOtherPrintDto was null or undefined when calling otherPrintControllerCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/OtherPrint`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateOtherPrintDtoToJSON(requestParameters.createUpdateOtherPrintDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetOtherPrintDtoFromJSON(jsonValue));
    }

    /**
     */
    async otherPrintControllerCreate(createUpdateOtherPrintDto: CreateUpdateOtherPrintDto): Promise<GetOtherPrintDto> {
        const response = await this.otherPrintControllerCreateRaw({ createUpdateOtherPrintDto: createUpdateOtherPrintDto });
        return await response.value();
    }

    /**
     */
    async otherPrintControllerGetRaw(requestParameters: OtherPrintControllerGetRequest): Promise<runtime.ApiResponse<GetOtherPrintDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling otherPrintControllerGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/OtherPrint/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetOtherPrintDtoFromJSON(jsonValue));
    }

    /**
     */
    async otherPrintControllerGet(id: string): Promise<GetOtherPrintDto> {
        const response = await this.otherPrintControllerGetRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async otherPrintControllerGetAllByPortionRaw(requestParameters: OtherPrintControllerGetAllByPortionRequest): Promise<runtime.ApiResponse<Array<GetOtherPrintDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling otherPrintControllerGetAllByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling otherPrintControllerGetAllByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling otherPrintControllerGetAllByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling otherPrintControllerGetAllByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling otherPrintControllerGetAllByPortion.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/OtherPrint/search/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetOtherPrintDtoFromJSON));
    }

    /**
     */
    async otherPrintControllerGetAllByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string): Promise<Array<GetOtherPrintDto>> {
        const response = await this.otherPrintControllerGetAllByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText });
        return await response.value();
    }

    /**
     */
    async otherPrintControllerGetAllCountRaw(requestParameters: OtherPrintControllerGetAllCountRequest): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling otherPrintControllerGetAllCount.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/OtherPrint/search/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async otherPrintControllerGetAllCount(filterText: string): Promise<number> {
        const response = await this.otherPrintControllerGetAllCountRaw({ filterText: filterText });
        return await response.value();
    }

    /**
     */
    async otherPrintControllerGetForExamRaw(requestParameters: OtherPrintControllerGetForExamRequest): Promise<runtime.ApiResponse<Array<GetOtherPrintDto>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling otherPrintControllerGetForExam.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/OtherPrint/forExam/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetOtherPrintDtoFromJSON));
    }

    /**
     */
    async otherPrintControllerGetForExam(id: string): Promise<Array<GetOtherPrintDto>> {
        const response = await this.otherPrintControllerGetForExamRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async otherPrintControllerGetForSigningByPortionRaw(requestParameters: OtherPrintControllerGetForSigningByPortionRequest): Promise<runtime.ApiResponse<Array<GetOtherPrintDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling otherPrintControllerGetForSigningByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling otherPrintControllerGetForSigningByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling otherPrintControllerGetForSigningByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling otherPrintControllerGetForSigningByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling otherPrintControllerGetForSigningByPortion.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.userUid !== undefined) {
            queryParameters['userUid'] = requestParameters.userUid;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/OtherPrint/searchForSigning/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetOtherPrintDtoFromJSON));
    }

    /**
     */
    async otherPrintControllerGetForSigningByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, userUid?: string): Promise<Array<GetOtherPrintDto>> {
        const response = await this.otherPrintControllerGetForSigningByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText, userUid: userUid });
        return await response.value();
    }

    /**
     */
    async otherPrintControllerGetForSigningCountRaw(requestParameters: OtherPrintControllerGetForSigningCountRequest): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling otherPrintControllerGetForSigningCount.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.userUid !== undefined) {
            queryParameters['userUid'] = requestParameters.userUid;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/OtherPrint/searchForSigning/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async otherPrintControllerGetForSigningCount(filterText: string, userUid?: string): Promise<number> {
        const response = await this.otherPrintControllerGetForSigningCountRaw({ filterText: filterText, userUid: userUid });
        return await response.value();
    }

    /**
     */
    async otherPrintControllerUpdateRaw(requestParameters: OtherPrintControllerUpdateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling otherPrintControllerUpdate.');
        }

        if (requestParameters.createUpdateOtherPrintDto === null || requestParameters.createUpdateOtherPrintDto === undefined) {
            throw new runtime.RequiredError('createUpdateOtherPrintDto','Required parameter requestParameters.createUpdateOtherPrintDto was null or undefined when calling otherPrintControllerUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/OtherPrint/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateOtherPrintDtoToJSON(requestParameters.createUpdateOtherPrintDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async otherPrintControllerUpdate(id: string, createUpdateOtherPrintDto: CreateUpdateOtherPrintDto): Promise<void> {
        await this.otherPrintControllerUpdateRaw({ id: id, createUpdateOtherPrintDto: createUpdateOtherPrintDto });
    }

    /**
     */
    async otherPrintDefControllerChangeToInactiveRaw(requestParameters: OtherPrintDefControllerChangeToInactiveRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling otherPrintDefControllerChangeToInactive.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/otherPrintDef/inactivate/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async otherPrintDefControllerChangeToInactive(id: string): Promise<void> {
        await this.otherPrintDefControllerChangeToInactiveRaw({ id: id });
    }

    /**
     */
    async otherPrintDefControllerCreateRaw(requestParameters: OtherPrintDefControllerCreateRequest): Promise<runtime.ApiResponse<GetOtherPrintDefDto>> {
        if (requestParameters.createUpdateOtherPrintDefDto === null || requestParameters.createUpdateOtherPrintDefDto === undefined) {
            throw new runtime.RequiredError('createUpdateOtherPrintDefDto','Required parameter requestParameters.createUpdateOtherPrintDefDto was null or undefined when calling otherPrintDefControllerCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/otherPrintDef`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateOtherPrintDefDtoToJSON(requestParameters.createUpdateOtherPrintDefDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetOtherPrintDefDtoFromJSON(jsonValue));
    }

    /**
     */
    async otherPrintDefControllerCreate(createUpdateOtherPrintDefDto: CreateUpdateOtherPrintDefDto): Promise<GetOtherPrintDefDto> {
        const response = await this.otherPrintDefControllerCreateRaw({ createUpdateOtherPrintDefDto: createUpdateOtherPrintDefDto });
        return await response.value();
    }

    /**
     */
    async otherPrintDefControllerGetRaw(requestParameters: OtherPrintDefControllerGetRequest): Promise<runtime.ApiResponse<GetOtherPrintDefDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling otherPrintDefControllerGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/otherPrintDef/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetOtherPrintDefDtoFromJSON(jsonValue));
    }

    /**
     */
    async otherPrintDefControllerGet(id: string): Promise<GetOtherPrintDefDto> {
        const response = await this.otherPrintDefControllerGetRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async otherPrintDefControllerGetAllRaw(requestParameters: OtherPrintDefControllerGetAllRequest): Promise<runtime.ApiResponse<Array<GetOtherPrintDefDto>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.mode !== undefined) {
            queryParameters['mode'] = requestParameters.mode;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/otherPrintDef`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetOtherPrintDefDtoFromJSON));
    }

    /**
     */
    async otherPrintDefControllerGetAll(mode?: string): Promise<Array<GetOtherPrintDefDto>> {
        const response = await this.otherPrintDefControllerGetAllRaw({ mode: mode });
        return await response.value();
    }

    /**
     */
    async otherPrintDefControllerUpdateRaw(requestParameters: OtherPrintDefControllerUpdateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling otherPrintDefControllerUpdate.');
        }

        if (requestParameters.createUpdateOtherPrintDefDto === null || requestParameters.createUpdateOtherPrintDefDto === undefined) {
            throw new runtime.RequiredError('createUpdateOtherPrintDefDto','Required parameter requestParameters.createUpdateOtherPrintDefDto was null or undefined when calling otherPrintDefControllerUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/otherPrintDef/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateOtherPrintDefDtoToJSON(requestParameters.createUpdateOtherPrintDefDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async otherPrintDefControllerUpdate(id: string, createUpdateOtherPrintDefDto: CreateUpdateOtherPrintDefDto): Promise<void> {
        await this.otherPrintDefControllerUpdateRaw({ id: id, createUpdateOtherPrintDefDto: createUpdateOtherPrintDefDto });
    }

    /**
     */
    async pdfPrinterControllerChangeAllCardsRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pdf/changeAllCards`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async pdfPrinterControllerChangeAllCards(): Promise<void> {
        await this.pdfPrinterControllerChangeAllCardsRaw();
    }

    /**
     */
    async pdfPrinterControllerLoadAndPrintCardRaw(requestParameters: PdfPrinterControllerLoadAndPrintCardRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling pdfPrinterControllerLoadAndPrintCard.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pdf/loadAndPrintCard/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async pdfPrinterControllerLoadAndPrintCard(id: string): Promise<void> {
        await this.pdfPrinterControllerLoadAndPrintCardRaw({ id: id });
    }

    /**
     */
    async pdfPrinterControllerLoadAndPrintConsultationRaw(requestParameters: PdfPrinterControllerLoadAndPrintConsultationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling pdfPrinterControllerLoadAndPrintConsultation.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pdf/loadAndPrintConsultation/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async pdfPrinterControllerLoadAndPrintConsultation(id: string): Promise<void> {
        await this.pdfPrinterControllerLoadAndPrintConsultationRaw({ id: id });
    }

    /**
     */
    async pdfPrinterControllerLoadAndPrintJudgmentRaw(requestParameters: PdfPrinterControllerLoadAndPrintJudgmentRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling pdfPrinterControllerLoadAndPrintJudgment.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pdf/loadAndPrintJudgment/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async pdfPrinterControllerLoadAndPrintJudgment(id: string): Promise<void> {
        await this.pdfPrinterControllerLoadAndPrintJudgmentRaw({ id: id });
    }

    /**
     */
    async pdfPrinterControllerLoadAndPrintJudgmentInEnglishRaw(requestParameters: PdfPrinterControllerLoadAndPrintJudgmentInEnglishRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling pdfPrinterControllerLoadAndPrintJudgmentInEnglish.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pdf/loadAndPrintJudgmentInEnglish/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async pdfPrinterControllerLoadAndPrintJudgmentInEnglish(id: string): Promise<void> {
        await this.pdfPrinterControllerLoadAndPrintJudgmentInEnglishRaw({ id: id });
    }

    /**
     */
    async pdfPrinterControllerLoadAndPrintSupportingExaminationRaw(requestParameters: PdfPrinterControllerLoadAndPrintSupportingExaminationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling pdfPrinterControllerLoadAndPrintSupportingExamination.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pdf/loadAndPrintSupportingExamination/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async pdfPrinterControllerLoadAndPrintSupportingExamination(id: string): Promise<void> {
        await this.pdfPrinterControllerLoadAndPrintSupportingExaminationRaw({ id: id });
    }

    /**
     */
    async pdfPrinterControllerPrintAnswersRaw(requestParameters: PdfPrinterControllerPrintAnswersRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.testId === null || requestParameters.testId === undefined) {
            throw new runtime.RequiredError('testId','Required parameter requestParameters.testId was null or undefined when calling pdfPrinterControllerPrintAnswers.');
        }

        if (requestParameters.examId === null || requestParameters.examId === undefined) {
            throw new runtime.RequiredError('examId','Required parameter requestParameters.examId was null or undefined when calling pdfPrinterControllerPrintAnswers.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pdf/printAnswers/{examId}/{testId}`.replace(`{${"testId"}}`, encodeURIComponent(String(requestParameters.testId))).replace(`{${"examId"}}`, encodeURIComponent(String(requestParameters.examId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async pdfPrinterControllerPrintAnswers(testId: string, examId: string): Promise<void> {
        await this.pdfPrinterControllerPrintAnswersRaw({ testId: testId, examId: examId });
    }

    /**
     */
    async pdfPrinterControllerPrintCompaniesRaw(requestParameters: PdfPrinterControllerPrintCompaniesRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.monthlySettlement === null || requestParameters.monthlySettlement === undefined) {
            throw new runtime.RequiredError('monthlySettlement','Required parameter requestParameters.monthlySettlement was null or undefined when calling pdfPrinterControllerPrintCompanies.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pdf/printCompanies/{monthlySettlement}`.replace(`{${"monthlySettlement"}}`, encodeURIComponent(String(requestParameters.monthlySettlement))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async pdfPrinterControllerPrintCompanies(monthlySettlement: boolean): Promise<void> {
        await this.pdfPrinterControllerPrintCompaniesRaw({ monthlySettlement: monthlySettlement });
    }

    /**
     */
    async pdfPrinterControllerPrintMultiplePdfRaw(requestParameters: PdfPrinterControllerPrintMultiplePdfRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.userUid !== undefined) {
            queryParameters['userUid'] = requestParameters.userUid;
        }

        if (requestParameters.dateFrom !== undefined) {
            queryParameters['dateFrom'] = (requestParameters.dateFrom as any).toISOString();
        }

        if (requestParameters.dateTo !== undefined) {
            queryParameters['dateTo'] = (requestParameters.dateTo as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pdf/printMultiple/pdf`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async pdfPrinterControllerPrintMultiplePdf(userUid?: string, dateFrom?: Date, dateTo?: Date): Promise<void> {
        await this.pdfPrinterControllerPrintMultiplePdfRaw({ userUid: userUid, dateFrom: dateFrom, dateTo: dateTo });
    }

    /**
     */
    async pdfPrinterControllerPrintRegisterJudgmentRaw(requestParameters: PdfPrinterControllerPrintRegisterJudgmentRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.registerId === null || requestParameters.registerId === undefined) {
            throw new runtime.RequiredError('registerId','Required parameter requestParameters.registerId was null or undefined when calling pdfPrinterControllerPrintRegisterJudgment.');
        }

        if (requestParameters.dateFrom === null || requestParameters.dateFrom === undefined) {
            throw new runtime.RequiredError('dateFrom','Required parameter requestParameters.dateFrom was null or undefined when calling pdfPrinterControllerPrintRegisterJudgment.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.registerId !== undefined) {
            queryParameters['registerId'] = requestParameters.registerId;
        }

        if (requestParameters.dateFrom !== undefined) {
            queryParameters['dateFrom'] = (requestParameters.dateFrom as any).toISOString();
        }

        if (requestParameters.dateTo !== undefined) {
            queryParameters['dateTo'] = (requestParameters.dateTo as any).toISOString();
        }

        if (requestParameters.userUid !== undefined) {
            queryParameters['userUid'] = requestParameters.userUid;
        }

        if (requestParameters.healthcareFacilityId !== undefined) {
            queryParameters['healthcareFacilityId'] = requestParameters.healthcareFacilityId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pdf/printRegisterJudgment/byParams`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async pdfPrinterControllerPrintRegisterJudgment(registerId: string, dateFrom: Date, dateTo?: Date, userUid?: string, healthcareFacilityId?: string): Promise<void> {
        await this.pdfPrinterControllerPrintRegisterJudgmentRaw({ registerId: registerId, dateFrom: dateFrom, dateTo: dateTo, userUid: userUid, healthcareFacilityId: healthcareFacilityId });
    }

    /**
     */
    async pdfPrinterControllerPrintReportJudgmentsRaw(requestParameters: PdfPrinterControllerPrintReportJudgmentsRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling pdfPrinterControllerPrintReportJudgments.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.psychologistId !== undefined) {
            queryParameters['psychologistId'] = requestParameters.psychologistId;
        }

        if (requestParameters.companyId !== undefined) {
            queryParameters['companyId'] = requestParameters.companyId;
        }

        if (requestParameters.registerId !== undefined) {
            queryParameters['registerId'] = requestParameters.registerId;
        }

        if (requestParameters.healthcareFacilityId !== undefined) {
            queryParameters['healthcareFacilityId'] = requestParameters.healthcareFacilityId;
        }

        if (requestParameters.dateFrom !== undefined) {
            queryParameters['dateFrom'] = (requestParameters.dateFrom as any).toISOString();
        }

        if (requestParameters.dateTo !== undefined) {
            queryParameters['dateTo'] = (requestParameters.dateTo as any).toISOString();
        }

        if (requestParameters.onlyMonthlySettlement !== undefined) {
            queryParameters['onlyMonthlySettlement'] = requestParameters.onlyMonthlySettlement;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pdf/printReportJudgments/byParams`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async pdfPrinterControllerPrintReportJudgments(filterText: string, psychologistId?: string, companyId?: string, registerId?: string, healthcareFacilityId?: string, dateFrom?: Date, dateTo?: Date, onlyMonthlySettlement?: string): Promise<void> {
        await this.pdfPrinterControllerPrintReportJudgmentsRaw({ filterText: filterText, psychologistId: psychologistId, companyId: companyId, registerId: registerId, healthcareFacilityId: healthcareFacilityId, dateFrom: dateFrom, dateTo: dateTo, onlyMonthlySettlement: onlyMonthlySettlement });
    }

    /**
     */
    async pdfPrinterControllerSaveAndPrintCardRaw(requestParameters: PdfPrinterControllerSaveAndPrintCardRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling pdfPrinterControllerSaveAndPrintCard.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pdf/saveAndPrintCard/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async pdfPrinterControllerSaveAndPrintCard(id: string): Promise<void> {
        await this.pdfPrinterControllerSaveAndPrintCardRaw({ id: id });
    }

    /**
     */
    async pdfPrinterControllerSaveAndPrintConsultationRaw(requestParameters: PdfPrinterControllerSaveAndPrintConsultationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling pdfPrinterControllerSaveAndPrintConsultation.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling pdfPrinterControllerSaveAndPrintConsultation.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pdf/saveAndPrintConsultation/{type}/{id}`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async pdfPrinterControllerSaveAndPrintConsultation(type: string, id: string): Promise<void> {
        await this.pdfPrinterControllerSaveAndPrintConsultationRaw({ type: type, id: id });
    }

    /**
     */
    async pdfPrinterControllerSaveAndPrintJudgmentRaw(requestParameters: PdfPrinterControllerSaveAndPrintJudgmentRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling pdfPrinterControllerSaveAndPrintJudgment.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pdf/saveAndPrintJudgment/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async pdfPrinterControllerSaveAndPrintJudgment(id: string): Promise<void> {
        await this.pdfPrinterControllerSaveAndPrintJudgmentRaw({ id: id });
    }

    /**
     */
    async pdfPrinterControllerSaveAndPrintSupportingExaminationRaw(requestParameters: PdfPrinterControllerSaveAndPrintSupportingExaminationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling pdfPrinterControllerSaveAndPrintSupportingExamination.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pdf/saveAndPrintSupportingExamination/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async pdfPrinterControllerSaveAndPrintSupportingExamination(id: string): Promise<void> {
        await this.pdfPrinterControllerSaveAndPrintSupportingExaminationRaw({ id: id });
    }

    /**
     */
    async pdfTemplateControllerGetRaw(requestParameters: PdfTemplateControllerGetRequest): Promise<runtime.ApiResponse<GetPdfTemplateDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling pdfTemplateControllerGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pdfTemplate/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPdfTemplateDtoFromJSON(jsonValue));
    }

    /**
     */
    async pdfTemplateControllerGet(id: string): Promise<GetPdfTemplateDto> {
        const response = await this.pdfTemplateControllerGetRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async pdfTemplateControllerGetAllRaw(): Promise<runtime.ApiResponse<Array<GetPdfTemplateDto>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pdfTemplate`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetPdfTemplateDtoFromJSON));
    }

    /**
     */
    async pdfTemplateControllerGetAll(): Promise<Array<GetPdfTemplateDto>> {
        const response = await this.pdfTemplateControllerGetAllRaw();
        return await response.value();
    }

    /**
     */
    async pdfTemplateControllerGetStampPositionRaw(requestParameters: PdfTemplateControllerGetStampPositionRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.judgmentId === null || requestParameters.judgmentId === undefined) {
            throw new runtime.RequiredError('judgmentId','Required parameter requestParameters.judgmentId was null or undefined when calling pdfTemplateControllerGetStampPosition.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.judgmentId !== undefined) {
            queryParameters['judgmentId'] = requestParameters.judgmentId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pdfTemplate/get/stampPosition`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async pdfTemplateControllerGetStampPosition(judgmentId: string): Promise<string> {
        const response = await this.pdfTemplateControllerGetStampPositionRaw({ judgmentId: judgmentId });
        return await response.value();
    }

    /**
     */
    async pdfTemplateControllerUpdateRaw(requestParameters: PdfTemplateControllerUpdateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling pdfTemplateControllerUpdate.');
        }

        if (requestParameters.createUpdatePdfTemplateDto === null || requestParameters.createUpdatePdfTemplateDto === undefined) {
            throw new runtime.RequiredError('createUpdatePdfTemplateDto','Required parameter requestParameters.createUpdatePdfTemplateDto was null or undefined when calling pdfTemplateControllerUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pdfTemplate/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdatePdfTemplateDtoToJSON(requestParameters.createUpdatePdfTemplateDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async pdfTemplateControllerUpdate(id: string, createUpdatePdfTemplateDto: CreateUpdatePdfTemplateDto): Promise<void> {
        await this.pdfTemplateControllerUpdateRaw({ id: id, createUpdatePdfTemplateDto: createUpdatePdfTemplateDto });
    }

    /**
     */
    async positionNormControllerGetByNormRaw(requestParameters: PositionNormControllerGetByNormRequest): Promise<runtime.ApiResponse<Array<GetPositionNormDto>>> {
        if (requestParameters.normId === null || requestParameters.normId === undefined) {
            throw new runtime.RequiredError('normId','Required parameter requestParameters.normId was null or undefined when calling positionNormControllerGetByNorm.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.normId !== undefined) {
            queryParameters['normId'] = requestParameters.normId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/positionNorm/get/byNorm`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetPositionNormDtoFromJSON));
    }

    /**
     */
    async positionNormControllerGetByNorm(normId: string): Promise<Array<GetPositionNormDto>> {
        const response = await this.positionNormControllerGetByNormRaw({ normId: normId });
        return await response.value();
    }

    /**
     */
    async positionNormControllerGetByNormCountRaw(requestParameters: PositionNormControllerGetByNormCountRequest): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.normId === null || requestParameters.normId === undefined) {
            throw new runtime.RequiredError('normId','Required parameter requestParameters.normId was null or undefined when calling positionNormControllerGetByNormCount.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.normId !== undefined) {
            queryParameters['normId'] = requestParameters.normId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/positionNorm/getByNorm/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async positionNormControllerGetByNormCount(normId: string): Promise<number> {
        const response = await this.positionNormControllerGetByNormCountRaw({ normId: normId });
        return await response.value();
    }

    /**
     */
    async registerControllerGetRaw(requestParameters: RegisterControllerGetRequest): Promise<runtime.ApiResponse<GetRegisterDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling registerControllerGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/register/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetRegisterDtoFromJSON(jsonValue));
    }

    /**
     */
    async registerControllerGet(id: string): Promise<GetRegisterDto> {
        const response = await this.registerControllerGetRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async registerControllerGetAllRaw(): Promise<runtime.ApiResponse<Array<GetRegisterDto>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/register`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetRegisterDtoFromJSON));
    }

    /**
     */
    async registerControllerGetAll(): Promise<Array<GetRegisterDto>> {
        const response = await this.registerControllerGetAllRaw();
        return await response.value();
    }

    /**
     */
    async registerControllerGetAllByPortionRaw(requestParameters: RegisterControllerGetAllByPortionRequest): Promise<runtime.ApiResponse<Array<GetRegisterDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling registerControllerGetAllByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling registerControllerGetAllByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling registerControllerGetAllByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling registerControllerGetAllByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling registerControllerGetAllByPortion.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/register/search/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetRegisterDtoFromJSON));
    }

    /**
     */
    async registerControllerGetAllByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string): Promise<Array<GetRegisterDto>> {
        const response = await this.registerControllerGetAllByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText });
        return await response.value();
    }

    /**
     */
    async registerControllerGetAllCountRaw(requestParameters: RegisterControllerGetAllCountRequest): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling registerControllerGetAllCount.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/register/search/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async registerControllerGetAllCount(filterText: string): Promise<number> {
        const response = await this.registerControllerGetAllCountRaw({ filterText: filterText });
        return await response.value();
    }

    /**
     */
    async registerControllerGetByModeRaw(requestParameters: RegisterControllerGetByModeRequest): Promise<runtime.ApiResponse<Array<GetRegisterDto>>> {
        if (requestParameters.mode === null || requestParameters.mode === undefined) {
            throw new runtime.RequiredError('mode','Required parameter requestParameters.mode was null or undefined when calling registerControllerGetByMode.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.mode !== undefined) {
            queryParameters['mode'] = requestParameters.mode;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/register/get/byMode`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetRegisterDtoFromJSON));
    }

    /**
     */
    async registerControllerGetByMode(mode: string): Promise<Array<GetRegisterDto>> {
        const response = await this.registerControllerGetByModeRaw({ mode: mode });
        return await response.value();
    }

    /**
     */
    async reportControllerGetCompaniesByPortionRaw(requestParameters: ReportControllerGetCompaniesByPortionRequest): Promise<runtime.ApiResponse<Array<GetReportCompanyDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling reportControllerGetCompaniesByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling reportControllerGetCompaniesByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling reportControllerGetCompaniesByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling reportControllerGetCompaniesByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling reportControllerGetCompaniesByPortion.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.dateFrom !== undefined) {
            queryParameters['dateFrom'] = (requestParameters.dateFrom as any).toISOString();
        }

        if (requestParameters.dateTo !== undefined) {
            queryParameters['dateTo'] = (requestParameters.dateTo as any).toISOString();
        }

        if (requestParameters.onlyMonthlySettlement !== undefined) {
            queryParameters['onlyMonthlySettlement'] = requestParameters.onlyMonthlySettlement;
        }

        if (requestParameters.companyId !== undefined) {
            queryParameters['companyId'] = requestParameters.companyId;
        }

        if (requestParameters.healthcareFacilityId !== undefined) {
            queryParameters['healthcareFacilityId'] = requestParameters.healthcareFacilityId;
        }

        if (requestParameters.withHealthcareFacility !== undefined) {
            queryParameters['withHealthcareFacility'] = requestParameters.withHealthcareFacility;
        }

        if (requestParameters.withRegister !== undefined) {
            queryParameters['withRegister'] = requestParameters.withRegister;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/report/searchCompanies/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetReportCompanyDtoFromJSON));
    }

    /**
     */
    async reportControllerGetCompaniesByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, dateFrom?: Date, dateTo?: Date, onlyMonthlySettlement?: boolean, companyId?: string, healthcareFacilityId?: string, withHealthcareFacility?: boolean, withRegister?: boolean): Promise<Array<GetReportCompanyDto>> {
        const response = await this.reportControllerGetCompaniesByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText, dateFrom: dateFrom, dateTo: dateTo, onlyMonthlySettlement: onlyMonthlySettlement, companyId: companyId, healthcareFacilityId: healthcareFacilityId, withHealthcareFacility: withHealthcareFacility, withRegister: withRegister });
        return await response.value();
    }

    /**
     */
    async reportControllerGetCompaniesCountRaw(requestParameters: ReportControllerGetCompaniesCountRequest): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling reportControllerGetCompaniesCount.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.dateFrom !== undefined) {
            queryParameters['dateFrom'] = (requestParameters.dateFrom as any).toISOString();
        }

        if (requestParameters.dateTo !== undefined) {
            queryParameters['dateTo'] = (requestParameters.dateTo as any).toISOString();
        }

        if (requestParameters.onlyMonthlySettlement !== undefined) {
            queryParameters['onlyMonthlySettlement'] = requestParameters.onlyMonthlySettlement;
        }

        if (requestParameters.companyId !== undefined) {
            queryParameters['companyId'] = requestParameters.companyId;
        }

        if (requestParameters.healthcareFacilityId !== undefined) {
            queryParameters['healthcareFacilityId'] = requestParameters.healthcareFacilityId;
        }

        if (requestParameters.withHealthcareFacility !== undefined) {
            queryParameters['withHealthcareFacility'] = requestParameters.withHealthcareFacility;
        }

        if (requestParameters.withRegister !== undefined) {
            queryParameters['withRegister'] = requestParameters.withRegister;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/report/searchCompanies/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async reportControllerGetCompaniesCount(filterText: string, dateFrom?: Date, dateTo?: Date, onlyMonthlySettlement?: boolean, companyId?: string, healthcareFacilityId?: string, withHealthcareFacility?: boolean, withRegister?: boolean): Promise<number> {
        const response = await this.reportControllerGetCompaniesCountRaw({ filterText: filterText, dateFrom: dateFrom, dateTo: dateTo, onlyMonthlySettlement: onlyMonthlySettlement, companyId: companyId, healthcareFacilityId: healthcareFacilityId, withHealthcareFacility: withHealthcareFacility, withRegister: withRegister });
        return await response.value();
    }

    /**
     */
    async reportControllerGetCompaniesDetailsByPortionRaw(requestParameters: ReportControllerGetCompaniesDetailsByPortionRequest): Promise<runtime.ApiResponse<Array<GetJudgmentDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling reportControllerGetCompaniesDetailsByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling reportControllerGetCompaniesDetailsByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling reportControllerGetCompaniesDetailsByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling reportControllerGetCompaniesDetailsByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling reportControllerGetCompaniesDetailsByPortion.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.psychologistId !== undefined) {
            queryParameters['psychologistId'] = requestParameters.psychologistId;
        }

        if (requestParameters.companyId !== undefined) {
            queryParameters['companyId'] = requestParameters.companyId;
        }

        if (requestParameters.registerId !== undefined) {
            queryParameters['registerId'] = requestParameters.registerId;
        }

        if (requestParameters.healthcareFacilityId !== undefined) {
            queryParameters['healthcareFacilityId'] = requestParameters.healthcareFacilityId;
        }

        if (requestParameters.dateFrom !== undefined) {
            queryParameters['dateFrom'] = (requestParameters.dateFrom as any).toISOString();
        }

        if (requestParameters.dateTo !== undefined) {
            queryParameters['dateTo'] = (requestParameters.dateTo as any).toISOString();
        }

        if (requestParameters.onlyMonthlySettlement !== undefined) {
            queryParameters['onlyMonthlySettlement'] = requestParameters.onlyMonthlySettlement;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/report/searchCompaniesDetails/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetJudgmentDtoFromJSON));
    }

    /**
     */
    async reportControllerGetCompaniesDetailsByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, psychologistId?: string, companyId?: string, registerId?: string, healthcareFacilityId?: string, dateFrom?: Date, dateTo?: Date, onlyMonthlySettlement?: string): Promise<Array<GetJudgmentDto>> {
        const response = await this.reportControllerGetCompaniesDetailsByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText, psychologistId: psychologistId, companyId: companyId, registerId: registerId, healthcareFacilityId: healthcareFacilityId, dateFrom: dateFrom, dateTo: dateTo, onlyMonthlySettlement: onlyMonthlySettlement });
        return await response.value();
    }

    /**
     */
    async reportControllerGetCompaniesDetailsCountRaw(requestParameters: ReportControllerGetCompaniesDetailsCountRequest): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling reportControllerGetCompaniesDetailsCount.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.psychologistId !== undefined) {
            queryParameters['psychologistId'] = requestParameters.psychologistId;
        }

        if (requestParameters.companyId !== undefined) {
            queryParameters['companyId'] = requestParameters.companyId;
        }

        if (requestParameters.registerId !== undefined) {
            queryParameters['registerId'] = requestParameters.registerId;
        }

        if (requestParameters.healthcareFacilityId !== undefined) {
            queryParameters['healthcareFacilityId'] = requestParameters.healthcareFacilityId;
        }

        if (requestParameters.dateFrom !== undefined) {
            queryParameters['dateFrom'] = (requestParameters.dateFrom as any).toISOString();
        }

        if (requestParameters.dateTo !== undefined) {
            queryParameters['dateTo'] = (requestParameters.dateTo as any).toISOString();
        }

        if (requestParameters.onlyMonthlySettlement !== undefined) {
            queryParameters['onlyMonthlySettlement'] = requestParameters.onlyMonthlySettlement;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/report/searchCompaniesDetails/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async reportControllerGetCompaniesDetailsCount(filterText: string, psychologistId?: string, companyId?: string, registerId?: string, healthcareFacilityId?: string, dateFrom?: Date, dateTo?: Date, onlyMonthlySettlement?: string): Promise<number> {
        const response = await this.reportControllerGetCompaniesDetailsCountRaw({ filterText: filterText, psychologistId: psychologistId, companyId: companyId, registerId: registerId, healthcareFacilityId: healthcareFacilityId, dateFrom: dateFrom, dateTo: dateTo, onlyMonthlySettlement: onlyMonthlySettlement });
        return await response.value();
    }

    /**
     */
    async reportControllerGetConsultationsByPortionRaw(requestParameters: ReportControllerGetConsultationsByPortionRequest): Promise<runtime.ApiResponse<Array<GetReportConsultationDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling reportControllerGetConsultationsByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling reportControllerGetConsultationsByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling reportControllerGetConsultationsByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling reportControllerGetConsultationsByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling reportControllerGetConsultationsByPortion.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.dateFrom !== undefined) {
            queryParameters['dateFrom'] = (requestParameters.dateFrom as any).toISOString();
        }

        if (requestParameters.dateTo !== undefined) {
            queryParameters['dateTo'] = (requestParameters.dateTo as any).toISOString();
        }

        if (requestParameters.userUid !== undefined) {
            queryParameters['userUid'] = requestParameters.userUid;
        }

        if (requestParameters.companyId !== undefined) {
            queryParameters['companyId'] = requestParameters.companyId;
        }

        if (requestParameters.healthcareFacilityId !== undefined) {
            queryParameters['healthcareFacilityId'] = requestParameters.healthcareFacilityId;
        }

        if (requestParameters.withHealthcareFacility !== undefined) {
            queryParameters['withHealthcareFacility'] = requestParameters.withHealthcareFacility;
        }

        if (requestParameters.withType !== undefined) {
            queryParameters['withType'] = requestParameters.withType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/report/searchConsultations/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetReportConsultationDtoFromJSON));
    }

    /**
     */
    async reportControllerGetConsultationsByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, dateFrom?: Date, dateTo?: Date, userUid?: string, companyId?: string, healthcareFacilityId?: string, withHealthcareFacility?: boolean, withType?: boolean): Promise<Array<GetReportConsultationDto>> {
        const response = await this.reportControllerGetConsultationsByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText, dateFrom: dateFrom, dateTo: dateTo, userUid: userUid, companyId: companyId, healthcareFacilityId: healthcareFacilityId, withHealthcareFacility: withHealthcareFacility, withType: withType });
        return await response.value();
    }

    /**
     */
    async reportControllerGetConsultationsCountRaw(requestParameters: ReportControllerGetConsultationsCountRequest): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling reportControllerGetConsultationsCount.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.dateFrom !== undefined) {
            queryParameters['dateFrom'] = (requestParameters.dateFrom as any).toISOString();
        }

        if (requestParameters.dateTo !== undefined) {
            queryParameters['dateTo'] = (requestParameters.dateTo as any).toISOString();
        }

        if (requestParameters.userUid !== undefined) {
            queryParameters['userUid'] = requestParameters.userUid;
        }

        if (requestParameters.companyId !== undefined) {
            queryParameters['companyId'] = requestParameters.companyId;
        }

        if (requestParameters.healthcareFacilityId !== undefined) {
            queryParameters['healthcareFacilityId'] = requestParameters.healthcareFacilityId;
        }

        if (requestParameters.withHealthcareFacility !== undefined) {
            queryParameters['withHealthcareFacility'] = requestParameters.withHealthcareFacility;
        }

        if (requestParameters.withType !== undefined) {
            queryParameters['withType'] = requestParameters.withType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/report/searchConsultations/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async reportControllerGetConsultationsCount(filterText: string, dateFrom?: Date, dateTo?: Date, userUid?: string, companyId?: string, healthcareFacilityId?: string, withHealthcareFacility?: boolean, withType?: boolean): Promise<number> {
        const response = await this.reportControllerGetConsultationsCountRaw({ filterText: filterText, dateFrom: dateFrom, dateTo: dateTo, userUid: userUid, companyId: companyId, healthcareFacilityId: healthcareFacilityId, withHealthcareFacility: withHealthcareFacility, withType: withType });
        return await response.value();
    }

    /**
     */
    async reportControllerGetJudgmentsByCreatorByPortionRaw(requestParameters: ReportControllerGetJudgmentsByCreatorByPortionRequest): Promise<runtime.ApiResponse<Array<GetReportJudgmentDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling reportControllerGetJudgmentsByCreatorByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling reportControllerGetJudgmentsByCreatorByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling reportControllerGetJudgmentsByCreatorByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling reportControllerGetJudgmentsByCreatorByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling reportControllerGetJudgmentsByCreatorByPortion.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.dateFrom !== undefined) {
            queryParameters['dateFrom'] = (requestParameters.dateFrom as any).toISOString();
        }

        if (requestParameters.dateTo !== undefined) {
            queryParameters['dateTo'] = (requestParameters.dateTo as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/report/searchJudgmentsByCreator/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetReportJudgmentDtoFromJSON));
    }

    /**
     */
    async reportControllerGetJudgmentsByCreatorByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, dateFrom?: Date, dateTo?: Date): Promise<Array<GetReportJudgmentDto>> {
        const response = await this.reportControllerGetJudgmentsByCreatorByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText, dateFrom: dateFrom, dateTo: dateTo });
        return await response.value();
    }

    /**
     */
    async reportControllerGetJudgmentsByCreatorCountRaw(requestParameters: ReportControllerGetJudgmentsByCreatorCountRequest): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling reportControllerGetJudgmentsByCreatorCount.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.dateFrom !== undefined) {
            queryParameters['dateFrom'] = (requestParameters.dateFrom as any).toISOString();
        }

        if (requestParameters.dateTo !== undefined) {
            queryParameters['dateTo'] = (requestParameters.dateTo as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/report/searchJudgmentsByCreator/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async reportControllerGetJudgmentsByCreatorCount(filterText: string, dateFrom?: Date, dateTo?: Date): Promise<number> {
        const response = await this.reportControllerGetJudgmentsByCreatorCountRaw({ filterText: filterText, dateFrom: dateFrom, dateTo: dateTo });
        return await response.value();
    }

    /**
     */
    async reportControllerGetJudgmentsByPortionRaw(requestParameters: ReportControllerGetJudgmentsByPortionRequest): Promise<runtime.ApiResponse<Array<GetReportJudgmentDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling reportControllerGetJudgmentsByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling reportControllerGetJudgmentsByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling reportControllerGetJudgmentsByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling reportControllerGetJudgmentsByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling reportControllerGetJudgmentsByPortion.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.dateFrom !== undefined) {
            queryParameters['dateFrom'] = (requestParameters.dateFrom as any).toISOString();
        }

        if (requestParameters.dateTo !== undefined) {
            queryParameters['dateTo'] = (requestParameters.dateTo as any).toISOString();
        }

        if (requestParameters.userUid !== undefined) {
            queryParameters['userUid'] = requestParameters.userUid;
        }

        if (requestParameters.companyId !== undefined) {
            queryParameters['companyId'] = requestParameters.companyId;
        }

        if (requestParameters.healthcareFacilityId !== undefined) {
            queryParameters['healthcareFacilityId'] = requestParameters.healthcareFacilityId;
        }

        if (requestParameters.withHealthcareFacility !== undefined) {
            queryParameters['withHealthcareFacility'] = requestParameters.withHealthcareFacility;
        }

        if (requestParameters.withRegister !== undefined) {
            queryParameters['withRegister'] = requestParameters.withRegister;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/report/searchJudgments/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetReportJudgmentDtoFromJSON));
    }

    /**
     */
    async reportControllerGetJudgmentsByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, dateFrom?: Date, dateTo?: Date, userUid?: string, companyId?: string, healthcareFacilityId?: string, withHealthcareFacility?: boolean, withRegister?: boolean): Promise<Array<GetReportJudgmentDto>> {
        const response = await this.reportControllerGetJudgmentsByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText, dateFrom: dateFrom, dateTo: dateTo, userUid: userUid, companyId: companyId, healthcareFacilityId: healthcareFacilityId, withHealthcareFacility: withHealthcareFacility, withRegister: withRegister });
        return await response.value();
    }

    /**
     */
    async reportControllerGetJudgmentsCountRaw(requestParameters: ReportControllerGetJudgmentsCountRequest): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling reportControllerGetJudgmentsCount.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.dateFrom !== undefined) {
            queryParameters['dateFrom'] = (requestParameters.dateFrom as any).toISOString();
        }

        if (requestParameters.dateTo !== undefined) {
            queryParameters['dateTo'] = (requestParameters.dateTo as any).toISOString();
        }

        if (requestParameters.userUid !== undefined) {
            queryParameters['userUid'] = requestParameters.userUid;
        }

        if (requestParameters.companyId !== undefined) {
            queryParameters['companyId'] = requestParameters.companyId;
        }

        if (requestParameters.healthcareFacilityId !== undefined) {
            queryParameters['healthcareFacilityId'] = requestParameters.healthcareFacilityId;
        }

        if (requestParameters.withHealthcareFacility !== undefined) {
            queryParameters['withHealthcareFacility'] = requestParameters.withHealthcareFacility;
        }

        if (requestParameters.withRegister !== undefined) {
            queryParameters['withRegister'] = requestParameters.withRegister;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/report/searchJudgments/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async reportControllerGetJudgmentsCount(filterText: string, dateFrom?: Date, dateTo?: Date, userUid?: string, companyId?: string, healthcareFacilityId?: string, withHealthcareFacility?: boolean, withRegister?: boolean): Promise<number> {
        const response = await this.reportControllerGetJudgmentsCountRaw({ filterText: filterText, dateFrom: dateFrom, dateTo: dateTo, userUid: userUid, companyId: companyId, healthcareFacilityId: healthcareFacilityId, withHealthcareFacility: withHealthcareFacility, withRegister: withRegister });
        return await response.value();
    }

    /**
     */
    async resultControllerGetByExamTestRaw(requestParameters: ResultControllerGetByExamTestRequest): Promise<runtime.ApiResponse<Array<GetResultForTestsDto>>> {
        if (requestParameters.examId === null || requestParameters.examId === undefined) {
            throw new runtime.RequiredError('examId','Required parameter requestParameters.examId was null or undefined when calling resultControllerGetByExamTest.');
        }

        if (requestParameters.testId === null || requestParameters.testId === undefined) {
            throw new runtime.RequiredError('testId','Required parameter requestParameters.testId was null or undefined when calling resultControllerGetByExamTest.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.examId !== undefined) {
            queryParameters['examId'] = requestParameters.examId;
        }

        if (requestParameters.testId !== undefined) {
            queryParameters['testId'] = requestParameters.testId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/result/get/byExamTest`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetResultForTestsDtoFromJSON));
    }

    /**
     */
    async resultControllerGetByExamTest(examId: string, testId: string): Promise<Array<GetResultForTestsDto>> {
        const response = await this.resultControllerGetByExamTestRaw({ examId: examId, testId: testId });
        return await response.value();
    }

    /**
     */
    async resultControllerGetByExamTestIdRaw(requestParameters: ResultControllerGetByExamTestIdRequest): Promise<runtime.ApiResponse<Array<GetResultDto>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling resultControllerGetByExamTestId.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/result/get/byExamTestId/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetResultDtoFromJSON));
    }

    /**
     */
    async resultControllerGetByExamTestId(id: string): Promise<Array<GetResultDto>> {
        const response = await this.resultControllerGetByExamTestIdRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async resultControllerSaveResultRaw(requestParameters: ResultControllerSaveResultRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.examId === null || requestParameters.examId === undefined) {
            throw new runtime.RequiredError('examId','Required parameter requestParameters.examId was null or undefined when calling resultControllerSaveResult.');
        }

        if (requestParameters.testId === null || requestParameters.testId === undefined) {
            throw new runtime.RequiredError('testId','Required parameter requestParameters.testId was null or undefined when calling resultControllerSaveResult.');
        }

        if (requestParameters.createUpdateResultDto === null || requestParameters.createUpdateResultDto === undefined) {
            throw new runtime.RequiredError('createUpdateResultDto','Required parameter requestParameters.createUpdateResultDto was null or undefined when calling resultControllerSaveResult.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/result/saveResult/{examId}/{testId}`.replace(`{${"examId"}}`, encodeURIComponent(String(requestParameters.examId))).replace(`{${"testId"}}`, encodeURIComponent(String(requestParameters.testId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateResultDtoToJSON(requestParameters.createUpdateResultDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async resultControllerSaveResult(examId: string, testId: string, createUpdateResultDto: CreateUpdateResultDto): Promise<void> {
        await this.resultControllerSaveResultRaw({ examId: examId, testId: testId, createUpdateResultDto: createUpdateResultDto });
    }

    /**
     */
    async resultControllerSaveResultsRaw(requestParameters: ResultControllerSaveResultsRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.examId === null || requestParameters.examId === undefined) {
            throw new runtime.RequiredError('examId','Required parameter requestParameters.examId was null or undefined when calling resultControllerSaveResults.');
        }

        if (requestParameters.testId === null || requestParameters.testId === undefined) {
            throw new runtime.RequiredError('testId','Required parameter requestParameters.testId was null or undefined when calling resultControllerSaveResults.');
        }

        if (requestParameters.createUpdateResultsDto === null || requestParameters.createUpdateResultsDto === undefined) {
            throw new runtime.RequiredError('createUpdateResultsDto','Required parameter requestParameters.createUpdateResultsDto was null or undefined when calling resultControllerSaveResults.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/result/saveResults/{examId}/{testId}`.replace(`{${"examId"}}`, encodeURIComponent(String(requestParameters.examId))).replace(`{${"testId"}}`, encodeURIComponent(String(requestParameters.testId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateResultsDtoToJSON(requestParameters.createUpdateResultsDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async resultControllerSaveResults(examId: string, testId: string, createUpdateResultsDto: CreateUpdateResultsDto): Promise<void> {
        await this.resultControllerSaveResultsRaw({ examId: examId, testId: testId, createUpdateResultsDto: createUpdateResultsDto });
    }

    /**
     */
    async savedPdfControllerGetExternalReferralForExaminedRaw(requestParameters: SavedPdfControllerGetExternalReferralForExaminedRequest): Promise<runtime.ApiResponse<Array<GetSavedPdfExternalReferralDto>>> {
        if (requestParameters.examinedId === null || requestParameters.examinedId === undefined) {
            throw new runtime.RequiredError('examinedId','Required parameter requestParameters.examinedId was null or undefined when calling savedPdfControllerGetExternalReferralForExamined.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.examinedId !== undefined) {
            queryParameters['examinedId'] = requestParameters.examinedId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/savedPdf/externalReferral/forObject`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetSavedPdfExternalReferralDtoFromJSON));
    }

    /**
     */
    async savedPdfControllerGetExternalReferralForExamined(examinedId: string): Promise<Array<GetSavedPdfExternalReferralDto>> {
        const response = await this.savedPdfControllerGetExternalReferralForExaminedRaw({ examinedId: examinedId });
        return await response.value();
    }

    /**
     */
    async savedPdfControllerGetFileDownloadRaw(requestParameters: SavedPdfControllerGetFileDownloadRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling savedPdfControllerGetFileDownload.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/savedPdf/fileDownloas/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async savedPdfControllerGetFileDownload(id: string): Promise<void> {
        await this.savedPdfControllerGetFileDownloadRaw({ id: id });
    }

    /**
     */
    async savedPdfControllerGetJudgmentSignatureStatusRaw(requestParameters: SavedPdfControllerGetJudgmentSignatureStatusRequest): Promise<runtime.ApiResponse<GetSavedPdfSignatureStatusDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling savedPdfControllerGetJudgmentSignatureStatus.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/savedPdf/judgmentSignatureStatus/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSavedPdfSignatureStatusDtoFromJSON(jsonValue));
    }

    /**
     */
    async savedPdfControllerGetJudgmentSignatureStatus(id: string): Promise<GetSavedPdfSignatureStatusDto> {
        const response = await this.savedPdfControllerGetJudgmentSignatureStatusRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async savedPdfControllerSavePdfFileRaw(requestParameters: SavedPdfControllerSavePdfFileRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.createUpdateSavedPdfDto === null || requestParameters.createUpdateSavedPdfDto === undefined) {
            throw new runtime.RequiredError('createUpdateSavedPdfDto','Required parameter requestParameters.createUpdateSavedPdfDto was null or undefined when calling savedPdfControllerSavePdfFile.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/savedPdf/savePdfFile`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateSavedPdfDtoToJSON(requestParameters.createUpdateSavedPdfDto),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async savedPdfControllerSavePdfFile(createUpdateSavedPdfDto: CreateUpdateSavedPdfDto): Promise<string> {
        const response = await this.savedPdfControllerSavePdfFileRaw({ createUpdateSavedPdfDto: createUpdateSavedPdfDto });
        return await response.value();
    }

    /**
     */
    async savedPdfControllerSavePdfFileForReferralRaw(requestParameters: SavedPdfControllerSavePdfFileForReferralRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.createUpdateSavedPdfDto === null || requestParameters.createUpdateSavedPdfDto === undefined) {
            throw new runtime.RequiredError('createUpdateSavedPdfDto','Required parameter requestParameters.createUpdateSavedPdfDto was null or undefined when calling savedPdfControllerSavePdfFileForReferral.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/savedPdf/savePdfFileForReferral`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateSavedPdfDtoToJSON(requestParameters.createUpdateSavedPdfDto),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async savedPdfControllerSavePdfFileForReferral(createUpdateSavedPdfDto: CreateUpdateSavedPdfDto): Promise<string> {
        const response = await this.savedPdfControllerSavePdfFileForReferralRaw({ createUpdateSavedPdfDto: createUpdateSavedPdfDto });
        return await response.value();
    }

    /**
     */
    async stampControllerGetForJudgmentRaw(requestParameters: StampControllerGetForJudgmentRequest): Promise<runtime.ApiResponse<GetStampDto>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling stampControllerGetForJudgment.');
        }

        if (requestParameters.judgmentId === null || requestParameters.judgmentId === undefined) {
            throw new runtime.RequiredError('judgmentId','Required parameter requestParameters.judgmentId was null or undefined when calling stampControllerGetForJudgment.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        if (requestParameters.judgmentId !== undefined) {
            queryParameters['judgmentId'] = requestParameters.judgmentId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/stamp/get/forJudgment`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetStampDtoFromJSON(jsonValue));
    }

    /**
     */
    async stampControllerGetForJudgment(userId: string, judgmentId: string): Promise<GetStampDto> {
        const response = await this.stampControllerGetForJudgmentRaw({ userId: userId, judgmentId: judgmentId });
        return await response.value();
    }

    /**
     */
    async supportingExaminationControllerCreateRaw(requestParameters: SupportingExaminationControllerCreateRequest): Promise<runtime.ApiResponse<GetSupportingExaminationDto>> {
        if (requestParameters.createUpdateSupportingExaminationDto === null || requestParameters.createUpdateSupportingExaminationDto === undefined) {
            throw new runtime.RequiredError('createUpdateSupportingExaminationDto','Required parameter requestParameters.createUpdateSupportingExaminationDto was null or undefined when calling supportingExaminationControllerCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/supportingExamination`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateSupportingExaminationDtoToJSON(requestParameters.createUpdateSupportingExaminationDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSupportingExaminationDtoFromJSON(jsonValue));
    }

    /**
     */
    async supportingExaminationControllerCreate(createUpdateSupportingExaminationDto: CreateUpdateSupportingExaminationDto): Promise<GetSupportingExaminationDto> {
        const response = await this.supportingExaminationControllerCreateRaw({ createUpdateSupportingExaminationDto: createUpdateSupportingExaminationDto });
        return await response.value();
    }

    /**
     */
    async supportingExaminationControllerDeleteRaw(requestParameters: SupportingExaminationControllerDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling supportingExaminationControllerDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/supportingExamination/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async supportingExaminationControllerDelete(id: string): Promise<void> {
        await this.supportingExaminationControllerDeleteRaw({ id: id });
    }

    /**
     */
    async supportingExaminationControllerGetRaw(requestParameters: SupportingExaminationControllerGetRequest): Promise<runtime.ApiResponse<GetSupportingExaminationDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling supportingExaminationControllerGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/supportingExamination/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSupportingExaminationDtoFromJSON(jsonValue));
    }

    /**
     */
    async supportingExaminationControllerGet(id: string): Promise<GetSupportingExaminationDto> {
        const response = await this.supportingExaminationControllerGetRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async supportingExaminationControllerGetAllByExamRaw(requestParameters: SupportingExaminationControllerGetAllByExamRequest): Promise<runtime.ApiResponse<Array<GetSupportingExaminationDto>>> {
        if (requestParameters.examId === null || requestParameters.examId === undefined) {
            throw new runtime.RequiredError('examId','Required parameter requestParameters.examId was null or undefined when calling supportingExaminationControllerGetAllByExam.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.examId !== undefined) {
            queryParameters['examId'] = requestParameters.examId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/supportingExamination/all/byExam`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetSupportingExaminationDtoFromJSON));
    }

    /**
     */
    async supportingExaminationControllerGetAllByExam(examId: string): Promise<Array<GetSupportingExaminationDto>> {
        const response = await this.supportingExaminationControllerGetAllByExamRaw({ examId: examId });
        return await response.value();
    }

    /**
     */
    async supportingExaminationControllerGetAllForUserToSignRaw(requestParameters: SupportingExaminationControllerGetAllForUserToSignRequest): Promise<runtime.ApiResponse<Array<GetSupportingExaminationDto>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.userUid !== undefined) {
            queryParameters['userUid'] = requestParameters.userUid;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/supportingExamination/getAll/forUserToSign`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetSupportingExaminationDtoFromJSON));
    }

    /**
     */
    async supportingExaminationControllerGetAllForUserToSign(userUid?: string): Promise<Array<GetSupportingExaminationDto>> {
        const response = await this.supportingExaminationControllerGetAllForUserToSignRaw({ userUid: userUid });
        return await response.value();
    }

    /**
     */
    async supportingExaminationControllerGetWithPrintsByPortionRaw(requestParameters: SupportingExaminationControllerGetWithPrintsByPortionRequest): Promise<runtime.ApiResponse<Array<GetSupportingExaminationDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling supportingExaminationControllerGetWithPrintsByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling supportingExaminationControllerGetWithPrintsByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling supportingExaminationControllerGetWithPrintsByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling supportingExaminationControllerGetWithPrintsByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling supportingExaminationControllerGetWithPrintsByPortion.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/supportingExamination/searchWithPrints/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetSupportingExaminationDtoFromJSON));
    }

    /**
     */
    async supportingExaminationControllerGetWithPrintsByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string): Promise<Array<GetSupportingExaminationDto>> {
        const response = await this.supportingExaminationControllerGetWithPrintsByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText });
        return await response.value();
    }

    /**
     */
    async supportingExaminationControllerGetWithPrintsCountRaw(requestParameters: SupportingExaminationControllerGetWithPrintsCountRequest): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling supportingExaminationControllerGetWithPrintsCount.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/supportingExamination/searchWithPrints/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async supportingExaminationControllerGetWithPrintsCount(filterText: string): Promise<number> {
        const response = await this.supportingExaminationControllerGetWithPrintsCountRaw({ filterText: filterText });
        return await response.value();
    }

    /**
     */
    async supportingExaminationControllerGetWithPrintsForSigningByPortionRaw(requestParameters: SupportingExaminationControllerGetWithPrintsForSigningByPortionRequest): Promise<runtime.ApiResponse<Array<GetSupportingExaminationDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling supportingExaminationControllerGetWithPrintsForSigningByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling supportingExaminationControllerGetWithPrintsForSigningByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling supportingExaminationControllerGetWithPrintsForSigningByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling supportingExaminationControllerGetWithPrintsForSigningByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling supportingExaminationControllerGetWithPrintsForSigningByPortion.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.userUid !== undefined) {
            queryParameters['userUid'] = requestParameters.userUid;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/supportingExamination/searchWithPrintsForSigning/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetSupportingExaminationDtoFromJSON));
    }

    /**
     */
    async supportingExaminationControllerGetWithPrintsForSigningByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, userUid?: string): Promise<Array<GetSupportingExaminationDto>> {
        const response = await this.supportingExaminationControllerGetWithPrintsForSigningByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText, userUid: userUid });
        return await response.value();
    }

    /**
     */
    async supportingExaminationControllerGetWithPrintsForSigningCountRaw(requestParameters: SupportingExaminationControllerGetWithPrintsForSigningCountRequest): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling supportingExaminationControllerGetWithPrintsForSigningCount.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.userUid !== undefined) {
            queryParameters['userUid'] = requestParameters.userUid;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/supportingExamination/searchWithPrintsForSigning/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async supportingExaminationControllerGetWithPrintsForSigningCount(filterText: string, userUid?: string): Promise<number> {
        const response = await this.supportingExaminationControllerGetWithPrintsForSigningCountRaw({ filterText: filterText, userUid: userUid });
        return await response.value();
    }

    /**
     */
    async supportingExaminationControllerUpdateRaw(requestParameters: SupportingExaminationControllerUpdateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling supportingExaminationControllerUpdate.');
        }

        if (requestParameters.createUpdateSupportingExaminationDto === null || requestParameters.createUpdateSupportingExaminationDto === undefined) {
            throw new runtime.RequiredError('createUpdateSupportingExaminationDto','Required parameter requestParameters.createUpdateSupportingExaminationDto was null or undefined when calling supportingExaminationControllerUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/supportingExamination/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateSupportingExaminationDtoToJSON(requestParameters.createUpdateSupportingExaminationDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async supportingExaminationControllerUpdate(id: string, createUpdateSupportingExaminationDto: CreateUpdateSupportingExaminationDto): Promise<void> {
        await this.supportingExaminationControllerUpdateRaw({ id: id, createUpdateSupportingExaminationDto: createUpdateSupportingExaminationDto });
    }

    /**
     */
    async supportingExaminationDictControllerCreateRaw(requestParameters: SupportingExaminationDictControllerCreateRequest): Promise<runtime.ApiResponse<GetSupportingExaminationDictDto>> {
        if (requestParameters.createUpdateSupportingExaminationDictDto === null || requestParameters.createUpdateSupportingExaminationDictDto === undefined) {
            throw new runtime.RequiredError('createUpdateSupportingExaminationDictDto','Required parameter requestParameters.createUpdateSupportingExaminationDictDto was null or undefined when calling supportingExaminationDictControllerCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/supportingExaminationDict`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateSupportingExaminationDictDtoToJSON(requestParameters.createUpdateSupportingExaminationDictDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSupportingExaminationDictDtoFromJSON(jsonValue));
    }

    /**
     */
    async supportingExaminationDictControllerCreate(createUpdateSupportingExaminationDictDto: CreateUpdateSupportingExaminationDictDto): Promise<GetSupportingExaminationDictDto> {
        const response = await this.supportingExaminationDictControllerCreateRaw({ createUpdateSupportingExaminationDictDto: createUpdateSupportingExaminationDictDto });
        return await response.value();
    }

    /**
     */
    async supportingExaminationDictControllerGetRaw(requestParameters: SupportingExaminationDictControllerGetRequest): Promise<runtime.ApiResponse<GetSupportingExaminationDictDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling supportingExaminationDictControllerGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/supportingExaminationDict/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSupportingExaminationDictDtoFromJSON(jsonValue));
    }

    /**
     */
    async supportingExaminationDictControllerGet(id: string): Promise<GetSupportingExaminationDictDto> {
        const response = await this.supportingExaminationDictControllerGetRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async supportingExaminationDictControllerGetAllRaw(): Promise<runtime.ApiResponse<Array<GetSupportingExaminationDictDto>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/supportingExaminationDict`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetSupportingExaminationDictDtoFromJSON));
    }

    /**
     */
    async supportingExaminationDictControllerGetAll(): Promise<Array<GetSupportingExaminationDictDto>> {
        const response = await this.supportingExaminationDictControllerGetAllRaw();
        return await response.value();
    }

    /**
     */
    async supportingExaminationDictControllerGetAllByPortionRaw(requestParameters: SupportingExaminationDictControllerGetAllByPortionRequest): Promise<runtime.ApiResponse<Array<GetSupportingExaminationDictDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling supportingExaminationDictControllerGetAllByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling supportingExaminationDictControllerGetAllByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling supportingExaminationDictControllerGetAllByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling supportingExaminationDictControllerGetAllByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling supportingExaminationDictControllerGetAllByPortion.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/supportingExaminationDict/search/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetSupportingExaminationDictDtoFromJSON));
    }

    /**
     */
    async supportingExaminationDictControllerGetAllByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string): Promise<Array<GetSupportingExaminationDictDto>> {
        const response = await this.supportingExaminationDictControllerGetAllByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText });
        return await response.value();
    }

    /**
     */
    async supportingExaminationDictControllerGetAllCountRaw(requestParameters: SupportingExaminationDictControllerGetAllCountRequest): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling supportingExaminationDictControllerGetAllCount.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/supportingExaminationDict/search/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async supportingExaminationDictControllerGetAllCount(filterText: string): Promise<number> {
        const response = await this.supportingExaminationDictControllerGetAllCountRaw({ filterText: filterText });
        return await response.value();
    }

    /**
     */
    async supportingExaminationDictControllerUpdateRaw(requestParameters: SupportingExaminationDictControllerUpdateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling supportingExaminationDictControllerUpdate.');
        }

        if (requestParameters.createUpdateSupportingExaminationDictDto === null || requestParameters.createUpdateSupportingExaminationDictDto === undefined) {
            throw new runtime.RequiredError('createUpdateSupportingExaminationDictDto','Required parameter requestParameters.createUpdateSupportingExaminationDictDto was null or undefined when calling supportingExaminationDictControllerUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/supportingExaminationDict/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateSupportingExaminationDictDtoToJSON(requestParameters.createUpdateSupportingExaminationDictDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async supportingExaminationDictControllerUpdate(id: string, createUpdateSupportingExaminationDictDto: CreateUpdateSupportingExaminationDictDto): Promise<void> {
        await this.supportingExaminationDictControllerUpdateRaw({ id: id, createUpdateSupportingExaminationDictDto: createUpdateSupportingExaminationDictDto });
    }

    /**
     */
    async szafirControllerGetTaskListForJudgmentRaw(requestParameters: SzafirControllerGetTaskListForJudgmentRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling szafirControllerGetTaskListForJudgment.');
        }

        if (requestParameters.english === null || requestParameters.english === undefined) {
            throw new runtime.RequiredError('english','Required parameter requestParameters.english was null or undefined when calling szafirControllerGetTaskListForJudgment.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.documentId !== undefined) {
            queryParameters['documentId'] = requestParameters.documentId;
        }

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        if (requestParameters.english !== undefined) {
            queryParameters['english'] = requestParameters.english;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/szafir/get/taskList`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async szafirControllerGetTaskListForJudgment(documentId: string, english: boolean, userId?: string): Promise<string> {
        const response = await this.szafirControllerGetTaskListForJudgmentRaw({ documentId: documentId, english: english, userId: userId });
        return await response.value();
    }

    /**
     */
    async szafirControllerGetTaskListForSupportingExaminationRaw(requestParameters: SzafirControllerGetTaskListForSupportingExaminationRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling szafirControllerGetTaskListForSupportingExamination.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.documentId !== undefined) {
            queryParameters['documentId'] = requestParameters.documentId;
        }

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/szafir/get/taskListForSupportingExamination`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async szafirControllerGetTaskListForSupportingExamination(documentId: string, userId?: string): Promise<string> {
        const response = await this.szafirControllerGetTaskListForSupportingExaminationRaw({ documentId: documentId, userId: userId });
        return await response.value();
    }

    /**
     */
    async testControllerCreateRaw(requestParameters: TestControllerCreateRequest): Promise<runtime.ApiResponse<GetTestDto>> {
        if (requestParameters.createUpdateTestDto === null || requestParameters.createUpdateTestDto === undefined) {
            throw new runtime.RequiredError('createUpdateTestDto','Required parameter requestParameters.createUpdateTestDto was null or undefined when calling testControllerCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/test`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateTestDtoToJSON(requestParameters.createUpdateTestDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetTestDtoFromJSON(jsonValue));
    }

    /**
     */
    async testControllerCreate(createUpdateTestDto: CreateUpdateTestDto): Promise<GetTestDto> {
        const response = await this.testControllerCreateRaw({ createUpdateTestDto: createUpdateTestDto });
        return await response.value();
    }

    /**
     */
    async testControllerGetRaw(requestParameters: TestControllerGetRequest): Promise<runtime.ApiResponse<GetTestDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling testControllerGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/test/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetTestDtoFromJSON(jsonValue));
    }

    /**
     */
    async testControllerGet(id: string): Promise<GetTestDto> {
        const response = await this.testControllerGetRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async testControllerGetAllRaw(): Promise<runtime.ApiResponse<Array<GetTestDto>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/test`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetTestDtoFromJSON));
    }

    /**
     */
    async testControllerGetAll(): Promise<Array<GetTestDto>> {
        const response = await this.testControllerGetAllRaw();
        return await response.value();
    }

    /**
     */
    async testControllerGetAllByPortionRaw(requestParameters: TestControllerGetAllByPortionRequest): Promise<runtime.ApiResponse<Array<GetTestDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling testControllerGetAllByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling testControllerGetAllByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling testControllerGetAllByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling testControllerGetAllByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling testControllerGetAllByPortion.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/test/search/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetTestDtoFromJSON));
    }

    /**
     */
    async testControllerGetAllByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string): Promise<Array<GetTestDto>> {
        const response = await this.testControllerGetAllByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText });
        return await response.value();
    }

    /**
     */
    async testControllerGetAllCountRaw(requestParameters: TestControllerGetAllCountRequest): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling testControllerGetAllCount.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/test/search/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async testControllerGetAllCount(filterText: string): Promise<number> {
        const response = await this.testControllerGetAllCountRaw({ filterText: filterText });
        return await response.value();
    }

    /**
     */
    async testControllerGetByBatteryRaw(requestParameters: TestControllerGetByBatteryRequest): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters.batteryId === null || requestParameters.batteryId === undefined) {
            throw new runtime.RequiredError('batteryId','Required parameter requestParameters.batteryId was null or undefined when calling testControllerGetByBattery.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.batteryId !== undefined) {
            queryParameters['batteryId'] = requestParameters.batteryId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/test/get/byBattery`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async testControllerGetByBattery(batteryId: string): Promise<Array<string>> {
        const response = await this.testControllerGetByBatteryRaw({ batteryId: batteryId });
        return await response.value();
    }

    /**
     */
    async testControllerGetByIdentifierRaw(requestParameters: TestControllerGetByIdentifierRequest): Promise<runtime.ApiResponse<GetTestDto>> {
        if (requestParameters.identifier === null || requestParameters.identifier === undefined) {
            throw new runtime.RequiredError('identifier','Required parameter requestParameters.identifier was null or undefined when calling testControllerGetByIdentifier.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.identifier !== undefined) {
            queryParameters['identifier'] = requestParameters.identifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/test/get/byIdentifier`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetTestDtoFromJSON(jsonValue));
    }

    /**
     */
    async testControllerGetByIdentifier(identifier: string): Promise<GetTestDto> {
        const response = await this.testControllerGetByIdentifierRaw({ identifier: identifier });
        return await response.value();
    }

    /**
     */
    async testControllerUpdateRaw(requestParameters: TestControllerUpdateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling testControllerUpdate.');
        }

        if (requestParameters.createUpdateTestDto === null || requestParameters.createUpdateTestDto === undefined) {
            throw new runtime.RequiredError('createUpdateTestDto','Required parameter requestParameters.createUpdateTestDto was null or undefined when calling testControllerUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/test/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateTestDtoToJSON(requestParameters.createUpdateTestDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async testControllerUpdate(id: string, createUpdateTestDto: CreateUpdateTestDto): Promise<void> {
        await this.testControllerUpdateRaw({ id: id, createUpdateTestDto: createUpdateTestDto });
    }

    /**
     */
    async testGivingControllerGetExamByIdRaw(requestParameters: TestGivingControllerGetExamByIdRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling testGivingControllerGetExamById.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/testGiving/examById/byId`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async testGivingControllerGetExamById(id: string): Promise<void> {
        await this.testGivingControllerGetExamByIdRaw({ id: id });
    }

    /**
     */
    async testGivingControllerGetExamByPinRaw(requestParameters: TestGivingControllerGetExamByPinRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.pin === null || requestParameters.pin === undefined) {
            throw new runtime.RequiredError('pin','Required parameter requestParameters.pin was null or undefined when calling testGivingControllerGetExamByPin.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pin !== undefined) {
            queryParameters['pin'] = requestParameters.pin;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/testGiving/examByPin/byPin`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async testGivingControllerGetExamByPin(pin: string): Promise<void> {
        await this.testGivingControllerGetExamByPinRaw({ pin: pin });
    }

    /**
     */
    async testGivingControllerGetExamByPinExamIdExternalRaw(requestParameters: TestGivingControllerGetExamByPinExamIdExternalRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.pin === null || requestParameters.pin === undefined) {
            throw new runtime.RequiredError('pin','Required parameter requestParameters.pin was null or undefined when calling testGivingControllerGetExamByPinExamIdExternal.');
        }

        if (requestParameters.examId === null || requestParameters.examId === undefined) {
            throw new runtime.RequiredError('examId','Required parameter requestParameters.examId was null or undefined when calling testGivingControllerGetExamByPinExamIdExternal.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pin !== undefined) {
            queryParameters['pin'] = requestParameters.pin;
        }

        if (requestParameters.examId !== undefined) {
            queryParameters['examId'] = requestParameters.examId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/testGiving/examByPinExternal/byPinExamId`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async testGivingControllerGetExamByPinExamIdExternal(pin: string, examId: string): Promise<void> {
        await this.testGivingControllerGetExamByPinExamIdExternalRaw({ pin: pin, examId: examId });
    }

    /**
     */
    async userControllerCreateRaw(requestParameters: UserControllerCreateRequest): Promise<runtime.ApiResponse<GetUserDto>> {
        if (requestParameters.createUpdateUserDto === null || requestParameters.createUpdateUserDto === undefined) {
            throw new runtime.RequiredError('createUpdateUserDto','Required parameter requestParameters.createUpdateUserDto was null or undefined when calling userControllerCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateUserDtoToJSON(requestParameters.createUpdateUserDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUserDtoFromJSON(jsonValue));
    }

    /**
     */
    async userControllerCreate(createUpdateUserDto: CreateUpdateUserDto): Promise<GetUserDto> {
        const response = await this.userControllerCreateRaw({ createUpdateUserDto: createUpdateUserDto });
        return await response.value();
    }

    /**
     */
    async userControllerGetRaw(requestParameters: UserControllerGetRequest): Promise<runtime.ApiResponse<GetUserDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userControllerGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUserDtoFromJSON(jsonValue));
    }

    /**
     */
    async userControllerGet(id: string): Promise<GetUserDto> {
        const response = await this.userControllerGetRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async userControllerGetAllByPortionRaw(requestParameters: UserControllerGetAllByPortionRequest): Promise<runtime.ApiResponse<Array<GetUserDto>>> {
        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling userControllerGetAllByPortion.');
        }

        if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
            throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling userControllerGetAllByPortion.');
        }

        if (requestParameters.orderBy === null || requestParameters.orderBy === undefined) {
            throw new runtime.RequiredError('orderBy','Required parameter requestParameters.orderBy was null or undefined when calling userControllerGetAllByPortion.');
        }

        if (requestParameters.orderDirection === null || requestParameters.orderDirection === undefined) {
            throw new runtime.RequiredError('orderDirection','Required parameter requestParameters.orderDirection was null or undefined when calling userControllerGetAllByPortion.');
        }

        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling userControllerGetAllByPortion.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['orderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.mode !== undefined) {
            queryParameters['mode'] = requestParameters.mode;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/search/byPage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetUserDtoFromJSON));
    }

    /**
     */
    async userControllerGetAllByPortion(pageSize: number, pageNumber: number, orderBy: string, orderDirection: string, filterText: string, mode?: string): Promise<Array<GetUserDto>> {
        const response = await this.userControllerGetAllByPortionRaw({ pageSize: pageSize, pageNumber: pageNumber, orderBy: orderBy, orderDirection: orderDirection, filterText: filterText, mode: mode });
        return await response.value();
    }

    /**
     */
    async userControllerGetAllByRolesRaw(requestParameters: UserControllerGetAllByRolesRequest): Promise<runtime.ApiResponse<Array<GetUserDto>>> {
        if (requestParameters.roles === null || requestParameters.roles === undefined) {
            throw new runtime.RequiredError('roles','Required parameter requestParameters.roles was null or undefined when calling userControllerGetAllByRoles.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.roles) {
            queryParameters['roles'] = requestParameters.roles;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/search/byRoles`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetUserDtoFromJSON));
    }

    /**
     */
    async userControllerGetAllByRoles(roles: Array<string>): Promise<Array<GetUserDto>> {
        const response = await this.userControllerGetAllByRolesRaw({ roles: roles });
        return await response.value();
    }

    /**
     */
    async userControllerGetAllCountRaw(requestParameters: UserControllerGetAllCountRequest): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.filterText === null || requestParameters.filterText === undefined) {
            throw new runtime.RequiredError('filterText','Required parameter requestParameters.filterText was null or undefined when calling userControllerGetAllCount.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.mode !== undefined) {
            queryParameters['mode'] = requestParameters.mode;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/search/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async userControllerGetAllCount(filterText: string, mode?: string): Promise<number> {
        const response = await this.userControllerGetAllCountRaw({ filterText: filterText, mode: mode });
        return await response.value();
    }

    /**
     */
    async userControllerGetByEmailRaw(requestParameters: UserControllerGetByEmailRequest): Promise<runtime.ApiResponse<Array<GetUserDto>>> {
        if (requestParameters.email === null || requestParameters.email === undefined) {
            throw new runtime.RequiredError('email','Required parameter requestParameters.email was null or undefined when calling userControllerGetByEmail.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.email !== undefined) {
            queryParameters['email'] = requestParameters.email;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/user/get/byEmail`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetUserDtoFromJSON));
    }

    /**
     */
    async userControllerGetByEmail(email: string): Promise<Array<GetUserDto>> {
        const response = await this.userControllerGetByEmailRaw({ email: email });
        return await response.value();
    }

    /**
     */
    async userControllerGetByUidRaw(requestParameters: UserControllerGetByUidRequest): Promise<runtime.ApiResponse<Array<GetUserDto>>> {
        if (requestParameters.uid === null || requestParameters.uid === undefined) {
            throw new runtime.RequiredError('uid','Required parameter requestParameters.uid was null or undefined when calling userControllerGetByUid.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.uid !== undefined) {
            queryParameters['uid'] = requestParameters.uid;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/user/get/byUid`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetUserDtoFromJSON));
    }

    /**
     */
    async userControllerGetByUid(uid: string): Promise<Array<GetUserDto>> {
        const response = await this.userControllerGetByUidRaw({ uid: uid });
        return await response.value();
    }

    /**
     */
    async userControllerUpdateRaw(requestParameters: UserControllerUpdateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userControllerUpdate.');
        }

        if (requestParameters.createUpdateUserDto === null || requestParameters.createUpdateUserDto === undefined) {
            throw new runtime.RequiredError('createUpdateUserDto','Required parameter requestParameters.createUpdateUserDto was null or undefined when calling userControllerUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateUserDtoToJSON(requestParameters.createUpdateUserDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async userControllerUpdate(id: string, createUpdateUserDto: CreateUpdateUserDto): Promise<void> {
        await this.userControllerUpdateRaw({ id: id, createUpdateUserDto: createUpdateUserDto });
    }

}
