/* tslint:disable */
/* eslint-disable */
/**
 * SPD
 * Opis api SPD
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUpdateExamsPackageDto
 */
export interface CreateUpdateExamsPackageDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateExamsPackageDto
     */
    description?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof CreateUpdateExamsPackageDto
     */
    registrationDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateExamsPackageDto
     */
    authorUID?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateExamsPackageDto
     */
    type?: CreateUpdateExamsPackageDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateExamsPackageDto
     */
    mode?: CreateUpdateExamsPackageDtoModeEnum;
    /**
     * 
     * @type {number}
     * @memberof CreateUpdateExamsPackageDto
     */
    number?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateExamsPackageDto
     */
    companyId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateExamsPackageDto
     */
    sendCompanyEmail?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateExamsPackageDto
     */
    healthcareFacilityId?: string | null;
}

export function CreateUpdateExamsPackageDtoFromJSON(json: any): CreateUpdateExamsPackageDto {
    return CreateUpdateExamsPackageDtoFromJSONTyped(json, false);
}

export function CreateUpdateExamsPackageDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateExamsPackageDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': !exists(json, 'description') ? undefined : json['description'],
        'registrationDate': !exists(json, 'registrationDate') ? undefined : (json['registrationDate'] === null ? null : new Date(json['registrationDate'])),
        'authorUID': !exists(json, 'authorUID') ? undefined : json['authorUID'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'mode': !exists(json, 'mode') ? undefined : json['mode'],
        'number': !exists(json, 'number') ? undefined : json['number'],
        'companyId': !exists(json, 'companyId') ? undefined : json['companyId'],
        'sendCompanyEmail': !exists(json, 'sendCompanyEmail') ? undefined : json['sendCompanyEmail'],
        'healthcareFacilityId': !exists(json, 'healthcareFacilityId') ? undefined : json['healthcareFacilityId'],
    };
}

export function CreateUpdateExamsPackageDtoToJSON(value?: CreateUpdateExamsPackageDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
        'registrationDate': value.registrationDate === undefined ? undefined : (value.registrationDate === null ? null : value.registrationDate.toISOString()),
        'authorUID': value.authorUID,
        'type': value.type,
        'mode': value.mode,
        'number': value.number,
        'companyId': value.companyId,
        'sendCompanyEmail': value.sendCompanyEmail,
        'healthcareFacilityId': value.healthcareFacilityId,
    };
}

/**
* @export
* @enum {string}
*/
export enum CreateUpdateExamsPackageDtoTypeEnum {
    Single = 'Single',
    Mulitiple = 'Mulitiple'
}
/**
* @export
* @enum {string}
*/
export enum CreateUpdateExamsPackageDtoModeEnum {
    Exams = 'Exams',
    Normalization = 'Normalization'
}


