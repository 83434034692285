import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Card, Tab } from 'react-bootstrap';

import Tabs from '../../../app/components/Tabs';
import { useSpdCore } from '../../../common/hooks/useSpdCore';
import { JudgmentsNumbering } from '../../components/JudgmentsNumbering/JudgmentsNumbering';
import {
  CreateUpdateJudgmentNumberingDto,
  GetJudgmentNumberingDto,
  GetRegisterDto,
  GetUserDto,
} from '../../../common/spdCore/autogenerated/spdApiClient';
import {
  judgmentNumberingData,
  validate,
} from '../../../common/spdCore/contexts/JudgmentNumberingContext';
import { JudgmentNumberingEditor } from '../../components/JudgmentsNumbering/JudgmentNumberingEditor';
import { responseErrors } from '../../../common/spdCore/validation/responseErrors';
import { Role } from '../../../common/spdCore/authorization/roles';

export const JudgmentsNumberingView: React.FC = () => {
  const spd = useSpdCore();
  const mountedRef = useRef(false);

  const [show, setShow] = useState(false);
  const [judgmentNumbering, setJudgmentNumbering] =
    useState<GetJudgmentNumberingDto>();
  const [judgmentsNumbering, setJudgmentsNumbering] = useState<
    GetJudgmentNumberingDto[]
  >([]);

  const [allRegisters, setAllRegisters] = useState<GetRegisterDto[]>([]);
  const [allUsers, setAllUsers] = useState<GetUserDto[]>([]);

  const refreshJudgNumb = useCallback(() => {
    if (!mountedRef.current) {
      return;
    }
    spd.judgmentNumbering
      .getAll()
      .then(judNum => setJudgmentsNumbering(judNum))
      .catch(error => {
        console.error(
          'Wystąpił problem z pobraniem numeracji orzeczeń:',
          error,
        );
      });
  }, [spd.judgmentNumbering]);

  useEffect(() => {
    mountedRef.current = true;
    refreshJudgNumb();
    return () => {
      mountedRef.current = false;
    };
  }, [refreshJudgNumb]);

  const handleAdd = () => {
    setShow(true);
    setJudgmentNumbering(undefined);
  };

  const addEditJudgmentNumbering = async (
    judNumCu: CreateUpdateJudgmentNumberingDto,
    id?: string,
  ) => {
    const status = await validate(judNumCu);
    if (status.valid) {
      try {
        if (id) {
          await spd.judgmentNumbering.update(id, judNumCu);
        } else {
          await spd.judgmentNumbering.create(judNumCu);
        }
        setShow(false);
        refreshJudgNumb();
        return {
          saved: true,
          errors: [],
        };
      } catch (response) {
        return {
          saved: false,
          errors: await responseErrors(response as Response),
        };
      }
    } else {
      return {
        saved: false,
        errors: status.errors,
      };
    }
  };

  const handleClose = () => {
    setShow(false);
    refreshJudgNumb();
  };

  const handleClick = async (id: string) => {
    const getJudgNum = await spd.judgmentNumbering.get(id);
    setJudgmentNumbering(getJudgNum);
    setShow(true);
  };

  useEffect(() => {
    spd.registers
      .getAll()
      .then(registers => setAllRegisters(registers))
      .catch(error =>
        console.log(`Wystąpił problem z pobraniem rejestrów. ${error}`),
      );
  }, [spd.registers]);

  useEffect(() => {
    spd.users
      .getAllByRoles([
        Role.MedicalAssessor,
        Role.MedicalSpecialist,
        Role.PsychologistAssessor,
        Role.PsychologistSpecialist,
      ])
      .then(users => setAllUsers(users))
      .catch(error =>
        console.log(`Wystąpił problem z pobraniem użytkowników. ${error}`),
      );
  }, [spd.users]);

  return (
    <Card>
      <Tabs defaultActiveKey="search" id="judgmentsNumbering" className="mb-3">
        <Tab eventKey="search" title="Numeracja rejestrów" className="m-3">
          <JudgmentsNumbering
            data={judgmentsNumbering}
            onRowClick={handleClick}
          />
          <Button variant="outline-primary" onClick={handleAdd}>
            Nowa numeracja
          </Button>
          <JudgmentNumberingEditor
            id={judgmentNumbering?.id}
            judgmentNumbering={judgmentNumberingData(judgmentNumbering)}
            onCompleted={addEditJudgmentNumbering}
            show={show}
            handleClose={handleClose}
            registers={allRegisters}
            users={allUsers}
          />
        </Tab>
      </Tabs>
    </Card>
  );
};
