import React, { useEffect, useState } from 'react';
import { Form, Card, Button } from 'react-bootstrap';

import { useSpdCore } from '../../../common/hooks/useSpdCore';
import { GetBatteryDto } from '../../../common/spdCore/autogenerated/spdApiClient';
import { Battery } from '../Battery/Battery';

import { TestGivingProps } from './utilsTestGiving';

interface Props {
  nextStep: () => void;
  handleFormObjData: (obj: Partial<TestGivingProps>) => void;
  prevStep: () => void;
  values: TestGivingProps;
}

export const ChooseBattery: React.FC<Props> = ({
  nextStep,
  handleFormObjData,
  prevStep,
  values,
}) => {
  const spd = useSpdCore();

  const [battery, setBattery] = useState('');
  const [dataBatteries, setDataBatteries] = useState<GetBatteryDto[]>([]);
  const [selectedBattery, setSelectedBattery] = useState<GetBatteryDto>();

  useEffect(() => {
    spd.batteries
      .getAll()
      .then(o => setDataBatteries(o))
      .catch(error => {
        console.error('Error during getAll batteries.', error);
      });
  }, [spd.batteries]);

  useEffect(() => {
    if (values.batteryKey) {
      setBattery(
        dataBatteries
          .filter(o => values.batteryKey === o.id)
          .map(o => o.name || '')
          .join(', '),
      );
      setSelectedBattery(
        dataBatteries.filter(o => values.batteryKey === o.id)[0],
      );
    }
  }, [dataBatteries, values]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const submitFormData = (e: any) => {
    e.preventDefault();
    nextStep();
  };

  const handleOnRowClick = (key: string) => {
    spd.batteries
      .getBattery(key)
      .then(battery => {
        setSelectedBattery(battery);
        handleFormObjData({
          batteryKey: key,
          testsKeys: battery.tests || [],
          articlePsychologistsKeys:
            battery.articlePsychologistsIds || undefined,
          articleDoctorsKeys: battery.articleDoctorsIds || undefined,
        });
      })
      .catch(error => {
        console.error('Error during getByBattery tests.', error);
      });
  };

  return (
    <>
      <Card>
        <Card.Body>
          <Form onSubmit={submitFormData}>
            <Form.Group className="mb-3">
              <Form.Label>Wybrana bateria testów:</Form.Label>{' '}
              <Form.Label>
                <strong>{battery}</strong>
              </Form.Label>
              <Battery
                getData={spd.batteries.getAllByPortion}
                getCount={spd.batteries.getAllCount}
                onRowClick={handleOnRowClick}
                enableSingleSelect={true}
                selectedKeys={selectedBattery?.id ? [selectedBattery.id] : []}
              />
            </Form.Group>

            <div className="d-flex justify-content-end m-3">
              <Button
                variant="outline-secondary"
                className="ms-1"
                onClick={prevStep}
              >
                Wstecz
              </Button>

              <Button
                variant="outline-primary"
                className="ms-1"
                onClick={nextStep}
              >
                Dalej
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};
