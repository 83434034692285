import React, { useState } from 'react';
import { Alert, Button, Card, Form, Tab, Tabs } from 'react-bootstrap';

import FormRow from '../../../app/components/FormRow';
import {
  CreateUpdateCardDto,
  CreateUpdateCardDtoTypeEnum,
  CreateUpdateCardDtoPdfTemplateTypeEnum,
  GetExamDto,
} from '../../../common/spdCore/autogenerated/spdApiClient';
import { useSpdCore } from '../../../common/hooks/useSpdCore';
import momencik from '../../../common/momencik';
import { CardType } from '../../../common/spdCore/validation/schemas';
import { ChooseSigned } from '../Users/ChooseSigned';
import { Role } from '../../../common/spdCore/authorization/roles';
import { responseErrors } from '../../../common/spdCore/validation/responseErrors';

interface CardSportEditorProps {
  exam: GetExamDto;
  card: CreateUpdateCardDto;
  id: string | undefined;
}

export const CardSportEditor: React.FC<CardSportEditorProps> = props => {
  const spd = useSpdCore();
  const [card, setCard] = useState<CreateUpdateCardDto>(props.card);
  const [id, setId] = useState<string | undefined>(props.id);
  const [message, setMessage] = useState<string>('');

  const createCard = async (gCard: CreateUpdateCardDto) => {
    setMessage('');
    try {
      if (id) {
        await spd.cards.update(id, gCard);
        setCard(gCard);

        await spd.pdfPrinter.saveAndPrintCard(id);
      } else {
        await spd.cards
          .create({
            ...gCard,
            ...{
              examId: props?.exam?.id,
            },
          })
          .then(async o => {
            setCard({
              ...o,
              ...{
                ...gCard,
                ...{
                  pdfTemplateType:
                    o.pdfTemplateType as unknown as CreateUpdateCardDtoPdfTemplateTypeEnum,
                  examId: props?.exam?.id,
                  type: CardType.Sport as unknown as CreateUpdateCardDtoTypeEnum,
                },
              },
            });
            setId(o.id);
            await spd.pdfPrinter.saveAndPrintCard(o.id);
          })
          .catch(err => {
            void responseErrors(err).then(errors => {
              setMessage(`Błąd podczas zapisywania karty badania:\n ${errors}`);
            });
          });
      }
    } catch (response) {
      const error = await responseErrors(response as Response);
      setMessage(`Błąd podczas zapisywania karty badania:\n ${error}`);
    }
  };

  const propertyChange = (obj: Partial<CreateUpdateCardDto>) => {
    setCard({ ...card, ...obj });
  };

  return (
    <Card className="mb-2">
      <Card.Header>{`Karta badania sportowca ${props.exam?.number}`}</Card.Header>
      <Card.Body>
        <Form className="d-grid gap-3">
          <Tabs defaultActiveKey="data" id="data" className="mb-2">
            <Tab eventKey="data" title="Dane podstawowe" className="mb-2">
              <div className="d-grid gap-3">
                <FormRow controlId="pass" label="Wynik pozytywny">
                  <Form.Check
                    type="checkbox"
                    defaultChecked={
                      card?.pass ||
                      card?.pass === undefined ||
                      card?.pass === null
                        ? true
                        : false
                    }
                    onChange={e => propertyChange({ pass: e.target.checked })}
                  />
                </FormRow>
                <FormRow controlId="staff" label="Lekarz">
                  <ChooseSigned
                    userUID={card?.psychologistUID}
                    handleOnChange={user =>
                      propertyChange({ psychologistUID: user?.uid })
                    }
                    roles={[Role.MedicalAssessor, Role.MedicalSpecialist]}
                  />
                </FormRow>
                <FormRow controlId="number" label="Numer">
                  <Form.Control
                    type="text"
                    defaultValue={card?.number || ''}
                    onChange={e =>
                      propertyChange({
                        number: e.target.value,
                      })
                    }
                  />
                </FormRow>
                <FormRow controlId="postDate" label="Data wysłania">
                  <Form.Control
                    type="date"
                    name="datepic"
                    placeholder="DateRange"
                    value={momencik(card?.postDate, 'YYYY-MM-DD')}
                    onChange={e =>
                      propertyChange({ postDate: new Date(e.target.value) })
                    }
                  />
                </FormRow>
                <FormRow controlId="nextDate" label="Data następnego badania">
                  <Form.Control
                    type="date"
                    name="datepic"
                    placeholder="DateRange"
                    value={momencik(card?.nextExamDate, 'YYYY-MM-DD')}
                    onChange={e =>
                      propertyChange({ nextExamDate: new Date(e.target.value) })
                    }
                  />
                </FormRow>
              </div>
            </Tab>
          </Tabs>
          <Button
            variant="outline-primary"
            className="ms-1"
            onClick={() => createCard(card)}
          >
            Zapisz i wydrukuj
          </Button>
          {message && <Alert variant={'warning'}>{message}</Alert>}
        </Form>
      </Card.Body>
    </Card>
  );
};
