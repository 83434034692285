import React, { useState } from 'react';
import { Button, Card, Tab } from 'react-bootstrap';

import Tabs from '../../../app/components/Tabs';
import { useSpdCore } from '../../../common/hooks/useSpdCore';
import { HarmfulFactorSearch } from '../../components/HarmfulFactor/HarmfulFactorSearch';
import { responseErrors } from '../../../common/spdCore/validation/responseErrors';
import { GetHarmfulFactorDto } from '../../../common/spdCore/autogenerated/spdApiClient';
import {
  harmfulFactorData,
  validate,
} from '../../../common/spdCore/contexts/HarmfulFactorContext';
import { CreateUpdateHarmfulFactorDto } from '../../../common/spdCore/autogenerated/spdApiClient/models/CreateUpdateHarmfulFactorDto';
import { HarmfulFactorEditor } from '../../components/HarmfulFactor/HarmfulFactorEditor';

export const HarmfulFactorsView: React.FC = () => {
  const spd = useSpdCore();
  const [show, setShow] = useState(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [harmfulFactor, setHarmfulFactor] = useState<GetHarmfulFactorDto>();

  const handleAdd = () => {
    setShow(true);
    setHarmfulFactor(undefined);
  };

  const handleClose = () => {
    setShow(false);
    setRefresh(!refresh);
  };

  const addEditHarmfulFactor = async (
    hfCu: CreateUpdateHarmfulFactorDto,
    id?: string,
  ) => {
    const status = await validate(hfCu);
    if (status.valid) {
      try {
        if (id) {
          await spd.harmfulFactors.update(id, hfCu);
        } else {
          await spd.harmfulFactors.create(hfCu);
        }
        setShow(false);
        setRefresh(!refresh);
        return {
          saved: true,
          errors: [],
        };
      } catch (response) {
        return {
          saved: false,
          errors: await responseErrors(response as Response),
        };
      }
    } else {
      return {
        saved: false,
        errors: status.errors,
      };
    }
  };

  const onRowClick = async (id: string) => {
    const getHF = await spd.harmfulFactors.get(id);
    setHarmfulFactor(getHF);
    setShow(true);
  };

  return (
    <Card>
      <Tabs defaultActiveKey="search" id="harmfulFactors" className="mb-3">
        <Tab eventKey="search" title="Czynniki szkodliwe" className="m-3">
          <HarmfulFactorSearch
            getData={spd.harmfulFactors.getAllByPortion}
            getCount={spd.harmfulFactors.getAllCount}
            refresh={refresh}
            onRowClick={onRowClick}
          />
          <Button variant="outline-primary" onClick={handleAdd}>
            Nowy czynnik szkodliwy
          </Button>
          <HarmfulFactorEditor
            id={harmfulFactor?.id}
            harmfulFactor={harmfulFactorData(harmfulFactor)}
            onCompleted={addEditHarmfulFactor}
            show={show}
            handleClose={handleClose}
          />
        </Tab>
      </Tabs>
    </Card>
  );
};
