import { DefaultApi } from '../autogenerated/spdApiClient/apis/DefaultApi';
import {
  CreateUpdateBatteryDto,
  CreateUpdateBatteryDtoFromJSON,
  GetBatteryDto,
} from '../autogenerated/spdApiClient';
import { batteryForGrid } from '../../../spd/components/Battery/Battery';
import { validateAgainst } from '../validation/validateAgainst';
import { BatterySchema } from '../validation/schemas';
import { GridRecord } from '../../components/Grid/GridDataTypes';

export type BatteryEntity = GetBatteryDto;

export interface BatteriesContextInterface {
  getBattery: (id: string) => Promise<BatteryEntity>;
  getAll: () => Promise<GetBatteryDto[]>;
  getAllByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
  ) => Promise<GridRecord[]>;
  getAllCount: (filterText?: string) => Promise<number>;
  update: (id: string, dto: CreateUpdateBatteryDto) => Promise<void>;
  create: (dto: CreateUpdateBatteryDto) => Promise<GetBatteryDto>;
}

export const BatteriesContext = (api: DefaultApi) => {
  return {
    getBattery: async (id: string) => {
      return await api.batteryControllerGet(id);
    },

    getAll: () => api.batteryControllerGetAll(),

    getAllByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
    ) => {
      const data = await api.batteryControllerGetAllByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
      );
      return data.map(batteryForGrid);
    },

    getAllCount: (filterText?: string) =>
      api.batteryControllerGetAllCount(filterText || ''),

    update: (id: string, dto: CreateUpdateBatteryDto) =>
      api.batteryControllerUpdate(id, dto),

    create: (dto: CreateUpdateBatteryDto) => api.batteryControllerCreate(dto),
  };
};

export const batteryData = (
  battery?: GetBatteryDto,
): CreateUpdateBatteryDto => {
  return CreateUpdateBatteryDtoFromJSON({
    ...battery,
  });
};

export const validate = (battery: CreateUpdateBatteryDto) =>
  validateAgainst(BatterySchema, battery);
