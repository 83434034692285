import React from 'react';

import { ResetPasswordDialog } from '../components/ResetPassword/ResetPasswordDialog';
import { useSpdCore } from '../../common/hooks/useSpdCore';

export const PasswordResetView: React.FC = () => {
  const spdApi = useSpdCore();
  const onPasswordReset = async (email: string) => {
    return await spdApi.signIn.resetPassword(email);
  };

  return <ResetPasswordDialog onResetPassword={onPasswordReset} />;
};
