/* tslint:disable */
/* eslint-disable */
/**
 * SPD
 * Opis api SPD
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUpdatePdfTemplateDto
 */
export interface CreateUpdatePdfTemplateDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUpdatePdfTemplateDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdatePdfTemplateDto
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdatePdfTemplateDto
     */
    template: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdatePdfTemplateDto
     */
    header: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdatePdfTemplateDto
     */
    headerFirstPage: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdatePdfTemplateDto
     */
    footer: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdatePdfTemplateDto
     */
    footerFirstPage: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdatePdfTemplateDto
     */
    margins: string;
}

export function CreateUpdatePdfTemplateDtoFromJSON(json: any): CreateUpdatePdfTemplateDto {
    return CreateUpdatePdfTemplateDtoFromJSONTyped(json, false);
}

export function CreateUpdatePdfTemplateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdatePdfTemplateDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'type': json['type'],
        'template': json['template'],
        'header': json['header'],
        'headerFirstPage': json['headerFirstPage'],
        'footer': json['footer'],
        'footerFirstPage': json['footerFirstPage'],
        'margins': json['margins'],
    };
}

export function CreateUpdatePdfTemplateDtoToJSON(value?: CreateUpdatePdfTemplateDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'type': value.type,
        'template': value.template,
        'header': value.header,
        'headerFirstPage': value.headerFirstPage,
        'footer': value.footer,
        'footerFirstPage': value.footerFirstPage,
        'margins': value.margins,
    };
}


