import { GetStampDto } from '../autogenerated/spdApiClient';
import { DefaultApi } from '../autogenerated/spdApiClient/apis/DefaultApi';

export type StampEntity = GetStampDto;

export interface StampContextInterface {
  getForJudgment: (userId: string, judgmentId: string) => Promise<GetStampDto>;
}

export const StampContext = (api: DefaultApi) => {
  return {
    getForJudgment: async (userId: string, judgmentId: string) => {
      return await api.stampControllerGetForJudgment(userId, judgmentId);
    },
  };
};
